// React
import React, { SyntheticEvent, useState } from 'react';

// React Query
import {
  QueryObserver,
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';

// API

// Interfaces

import AddCircleIcon from '@mui/icons-material/AddCircle';

// Material UI
import { Box } from '@material-ui/core';
import {
  Tab,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Input
} from '@mui/material';
import {
 TabContext, TabList, TabPanel, LoadingButton 
} from '@mui/lab';

import {
  ICouponProgramForm,
  ICouponProgram
} from '../../../../interfaces/coupon.interface';
import {
  GET_MICRO,
  POST_MICRO,
  PATCH_MICRO
} from '../../../../services/api/api';

// Components
import Rules from '../../ModalEditCoupon/EditCoupon/Rules/Rules';
import Coupons from '../../ModalEditCoupon/EditCoupon/Coupons/Coupons';
import Discounts from '../../ModalEditCoupon/EditCoupon/Discounts/Discount';

interface Props {
  currentCouponProgram: ICouponProgram;
  setCurrentProgram: any;
  refetch: () => {};
  onClose: () => void;
}

const CreateCoupon: React.FC<Props> = ({
  currentCouponProgram,
  setCurrentProgram,
  refetch
}) => {
  const [currentPosition, setCurrentPosition] = useState<string>('0');

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  const createCouponProgram = useMutation(
    (data: ICouponProgram) => POST_MICRO(`/program/`, data),
    { retry: false }
  );

  const queryClient = useQueryClient();
  const { refetch: refetchCouponProgram } = new QueryObserver(queryClient, {queryKey: 'programs-coupon'});

  const updateAllValue = useMutation(
    (data: ICouponProgram) =>
      PATCH_MICRO(`/program/${currentCouponProgram.id}`, data),
    { retry: false }
  );

  const {
    data: couponProgramForm,
    error,
    isError,
    isLoading
  } = useQuery(
    ['coupon-program'],
    () => GET_MICRO<ICouponProgramForm>(`/program/${currentCouponProgram.id}`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!currentCouponProgram.id
    }
  );
  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  const handleCreateCoupon = async () => {
    await createCouponProgram
      .mutateAsync(currentCouponProgram)
      .then((res: any) => {
        setCurrentProgram({
          ...currentCouponProgram,
          id: res.data.id,
          description: res.data.description
        });
      });

    refetchCouponProgram();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setCurrentProgram({
      ...currentCouponProgram,
      [name]: value
    });
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        {currentCouponProgram.id ? (
          <h2>{currentCouponProgram.name}</h2>
        ) : (
          <Stack direction="row" spacing={1.5} justifyContent="space-between">
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="amount-simple-select-label">Name</InputLabel>
                <Input
                  id="amount-simple-select"
                  value={currentCouponProgram.name}
                  onChange={handleChange}
                  name="name"
                  disabled={!!currentCouponProgram.id}
                />
              </FormControl>
            </Box>
            <LoadingButton
              sx={{ minWidth: 150 }}
              variant="contained"
              onClick={handleCreateCoupon}
              startIcon={<AddCircleIcon />}
            >
              Crear
            </LoadingButton>
          </Stack>
        )}
      </Grid>

      <TabContext value={currentPosition}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList onChange={handleChangePosition} aria-label="room actions">
            <Tab
              label="Condiciones"
              value="0"
              disabled={!currentCouponProgram.id}
            />
            <Tab
              label="Descuentos"
              value="1"
              disabled={!currentCouponProgram.id}
            />
            <Tab
              label="Cupones"
              value="2"
              disabled={!currentCouponProgram.id}
            />
          </TabList>
        </Box>
        {currentCouponProgram.id ? (
          <>
            <TabPanel value="0" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
              <Rules
                rules={couponProgramForm?.rules}
                all_course={couponProgramForm?.all_course}
                all_path={couponProgramForm?.all_path}
                description={couponProgramForm?.description}
                date_limit={couponProgramForm?.date_limit}
                name={couponProgramForm?.name}
                check_test_month={couponProgramForm?.check_test_month}
                couponProgramId={currentCouponProgram.id!}
                refetch={refetch}
                mutation={updateAllValue}
              />
            </TabPanel>
            <TabPanel value="1" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
              <Discounts
                discounts={couponProgramForm?.discounts}
                couponProgramId={currentCouponProgram.id!}
                refetch={refetch}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
              <Coupons
                coupons={couponProgramForm?.coupons}
                couponProgramId={currentCouponProgram.id!}
                refetch={refetch}
              />
            </TabPanel>
          </>
        ) : null}
      </TabContext>
    </Grid>
  );
};

export default CreateCoupon;
