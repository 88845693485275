import React from 'react';

import './tag.scss';

interface Props {
  children: any;
  className?: string;
  color?: string;
  customStyles?: any;
}

const Tag: React.FC<Props> = ({
  children,
  className,
  color = '',
  customStyles
}) => {
  return (
    <div
      className={`tag ${className}`}
      style={{ background: color, ...customStyles }}
    >
      {children}
    </div>
  );
};

export default Tag;
