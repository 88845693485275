// React Query
import {
  QueryObserver,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// API

// Hot Toast
import { toast } from 'react-hot-toast';

// Utils

// Interfaces

// Material UI
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  LinearProgress,
  TextField
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

import { ILearningPath } from '../../../interfaces/learningPaths.interface';
import { ICourse } from '../../../interfaces/course.interface';
import { sortAlphabetically } from '../../../utils/utils';
import { GET, POST } from '../../../services/api/api';

const SignupSchema = Yup.object().shape({
  model_recommended_id: Yup.number()
    .test('Is positive?', 'Seleccione un curso', value => value! > 0)
    .required('Requerido')
});

interface Props {
  originId: number;
  originType: 'course' | 'learning_path';
  recommendedType: 'course' | 'learning_path';
  refetch: () => {};
}

const CreateRecommendation: React.FC<Props> = ({
  originId,
  originType = 'course',
  recommendedType = 'course',
  refetch
}) => {
  // Query Client
  const queryClient = useQueryClient();
  const { refetch: refetchRecommendation } = new QueryObserver(queryClient, {queryKey: `recommended-${originType}`});

  const {
    data: courses,
    error: errorCourses,
    isError: isErrorCourses,
    isLoading: isLoadingCourses
  } = useQuery(
    ['courses'],
    () => GET<ICourse[]>(`/backoffice/v2/courses?sync_with_lms=true`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const {
    data: learningPaths,
    error: errorLearningPaths,
    isError: isErrorLearningPaths,
    isLoading: isLoadingLearningPaths
  } = useQuery(
    ['learning-paths-backoffice'],
    () => GET<ILearningPath[]>(`/learning_paths_backoffice/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const createRecommendation = useMutation(
    (recommended: any) => POST(`/recommended/backoffice/`, recommended),
    { retry: false }
  );

  if (isLoadingLearningPaths || isLoadingCourses || !learningPaths || !courses)
    return <LinearProgress />;
  if (isErrorLearningPaths)
    return <div>Error! {JSON.stringify(errorLearningPaths)}</div>;
  if (isErrorCourses) return <div>Error! {JSON.stringify(errorCourses)}</div>;

  const initialValues = {
    model_origin_id: originId,
    model_origin_type: originType,

    model_recommended_id: -1,
    model_recommended_type: recommendedType
  };

  const searcher = (
    recommendedType: 'course' | 'learning_path',
    values: any,
    setFieldValue: any
  ) => {
    switch (recommendedType) {
      case 'course':
        return (
          <Autocomplete
            fullWidth
            id="model_recommended_id"
            options={sortAlphabetically(courses, 'name')}
            autoHighlight
            value={
              courses.find(
                course => course.id === values.model_recommended_id
              ) || null
            }
            onChange={(_e, value: any) => {
              value
                ? setFieldValue('model_recommended_id', value.id)
                : setFieldValue('model_recommended_id', -1);
            }}
            getOptionLabel={option => `${option.name} (${option.id})`}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name} ({option.id})
              </Box>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Curso"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off' // disable autocomplete and autofill
                }}
              />
            )}
          />
        );

      case 'learning_path':
        return (
          <Autocomplete
            fullWidth
            id="model_recommended_id"
            options={sortAlphabetically(learningPaths, 'name')}
            // options={learningPaths}
            autoHighlight
            value={
              learningPaths.find(
                learningPath => learningPath.id === values.model_recommended_id
              ) || null
            }
            onChange={(_e, value: any) => {
              value
                ? setFieldValue('model_recommended_id', value.id)
                : setFieldValue('model_recommended_id', -1);
            }}
            getOptionLabel={option => `${option.name} (${option.id})`}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name} ({option.id})
              </Box>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Learning Path"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off' // disable autocomplete and autofill
                }}
              />
            )}
          />
        );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async (values, { resetForm }) => {
        const data = {
          model_origin_id: values.model_origin_id,
          model_origin_type: values.model_origin_type,

          model_recommended_id: values.model_recommended_id,
          model_recommended_type: values.model_recommended_type
        };

        await toast.promise(
          createRecommendation.mutateAsync(data!),
          {
            loading: 'Cargando...',
            success: 'Recomendación añadida',
            error: 'Error'
          },
          { success: { icon: <UpdateIcon color="primary" /> } }
        );

        refetchRecommendation();
        resetForm();
        refetch();
      }}
    >
      {({
 errors, touched, values, setFieldValue 
}) => (
        <Form>
          {/* Searcher */}
          <Grid item container columnSpacing={1} xs={12}>
            {/* Cursos || Learning Paths */}
            <Grid item xs={8}>
              {searcher(recommendedType, values, setFieldValue)}

              {errors.model_recommended_id && touched.model_recommended_id && (
                <Alert severity="error">{errors.model_recommended_id}</Alert>
              )}
            </Grid>

            {/* Create */}
            <Grid item xs={4}>
              <LoadingButton
                fullWidth
                loading={createRecommendation.isLoading}
                variant="contained"
                type="submit"
                startIcon={<UpdateIcon />}
                sx={{ height: '100%' }}
              >
                Crear
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateRecommendation;
