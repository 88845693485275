// React
import { useState } from 'react';

// Components
import EditTeacher from './EditTeacher/EditTeacher';
import OpenModal from '../../../components/Modal/OpenModal/OpenModal';
import Modal from '../../../components/Modal/Modal';

interface Props {
  teacherId: number;
  refetch: () => {};
}

const ModalEditTeacher: React.FC<Props> = ({ teacherId, refetch }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal
        option="edit"
        placement="left"
        handleOpenModal={handleOpenModal}
      />

      {/* Modal Edit Teacher */}
      <Modal
        id={teacherId}
        title="Editar Profesor"
        fullWidth
        maxWidth="sm"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <EditTeacher
          teacherId={teacherId}
          refetch={refetch}
          onClose={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default ModalEditTeacher;
