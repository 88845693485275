// React
import React, { SyntheticEvent, useState } from 'react';

// Material UI
import { Box } from '@material-ui/core';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// Components
import GeneralData from './GeneralData/GeneralData';
import TechnicalData from './TechnicalData/TechnicalData';
import Prices from './Prices/Prices';
import Multimedia from './Multimedia/Multimedia';
import PathFaqs from './PathFaqs/PathFaqs';
import Recommendations from './Recommendations/Recommendations';
import Project from './Project/Project';
import Learning from './Learning/Learning';
import Evaluations from './Evaluations/Evaluations';

interface Props {
  refetch: () => {};
  onClose: () => void;
}

const CreateCourse: React.FC<Props> = ({ refetch }) => {
  const [currentPosition, setCurrentPosition] = useState<string>('0');

  const [courseId, setCourseId] = useState<number>(0);

  const [isGeneralDataSent, setIsGeneralDataSent] = useState<boolean>(false);
  const [isTechnicalDataSent, setIsTechnicalDataSent] =
    useState<boolean>(false);
  const [isMultimediaSent, setIsMultimediaSent] = useState<boolean>(false);

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  return (
    <TabContext value={currentPosition}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <TabList onChange={handleChangePosition} aria-label="room actions">
          <Tab label="Datos Generales" value="0" />
          <Tab label="Datos Técnicos" value="1" disabled={!courseId} />
          <Tab label="Precios" value="2" disabled={!courseId} />
          <Tab label="Multimedia" value="3" disabled={!courseId} />
          <Tab label="Course Faqs" value="4" disabled={!courseId} />
          <Tab label="Recomendaciones" value="5" disabled={!courseId} />
          <Tab label="Información" value="6" disabled={!courseId} />
          <Tab label="Proyecto" value="7" disabled={!courseId} />
          <Tab label="Aprendizajes" value="8" disabled={!courseId} />
          <Tab label="Evaluación" value="9" disabled={!courseId} />
        </TabList>
      </Box>

      <TabPanel value="0" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <GeneralData
          refetch={refetch}
          courseId={courseId}
          setCourseId={setCourseId}
          isGeneralDataSent={isGeneralDataSent}
          setIsGeneralDataSent={setIsGeneralDataSent}
        />
      </TabPanel>
      <TabPanel value="1" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <TechnicalData
          refetch={refetch}
          courseId={courseId}
          isTechnicalDataSent={isTechnicalDataSent}
          setIsTechnicalDataSent={setIsTechnicalDataSent}
        />
      </TabPanel>
      <TabPanel value="2" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <Prices refetch={refetch} courseId={courseId} />
      </TabPanel>
      <TabPanel value="3" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <Multimedia
          refetch={refetch}
          courseId={courseId}
          isMultimediaSent={isMultimediaSent}
          setIsMultimediaSent={setIsMultimediaSent}
        />
      </TabPanel>
      <TabPanel value="4" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <PathFaqs refetch={refetch} courseId={courseId} />
      </TabPanel>
      <TabPanel value="5" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <Recommendations
          refetch={refetch}
          courseId={courseId}
          originType="course"
        />
      </TabPanel>
      <TabPanel value="6" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <Recommendations
          refetch={refetch}
          courseId={courseId}
          originType="course"
        />
      </TabPanel>
      <TabPanel value="7" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <Project refetch={refetch} courseId={courseId} />
      </TabPanel>
      <TabPanel value="8" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <Learning refetch={refetch} courseId={courseId} />
      </TabPanel>
      <TabPanel value="9" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
        <Evaluations refetch={refetch} courseId={courseId} />
      </TabPanel>
    </TabContext>
  );
};

export default CreateCourse;
