import React, { useState } from 'react';

import '../modalCreateProject.scss';

import { FormControlLabel, Checkbox, FormGroup } from '@mui/material';

import Input from '../../../../components/molecules/Input';
import Textarea from '../../../../components/molecules/Textarea/Textarea';
import SelectGeneral from '../../../../components/molecules/SelectGeneral';
import Typography from '../../../../components/atoms/Typography/Typography';

const SecondForm: React.FC<{
  data: any;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  setIsOpenSelectProjectType: any;
  isOpenSelectProjectType: any;
  optionSelectionProjectType: any;
  projectType: any;
  setProjectType: any;
  setIsOpenSelectProjectMode: any;
  isOpenSelectProjectMode: any;
  optionSelectionProjectMode: any;
  projectMode: any;
  setProjectMode: any;
  setIsOpenSelectProjectOperationType: any;
  isOpenSelectProjectOperationType: any;
  optionSelectionProjectOperationType: any;
  projectOperationType: any;
  setProjectOperationType: any;
  setIsOpenSelectProjectComplexity: any;
  isOpenSelectProjectComplexity: any;
  optionSelectionProjectComplexity: any;
  projectComplexity: any;
  setProjectComplexity: any;
  setIsOpenSelectProjectCountry: any;
  isOpenSelectProjectCountry: any;
  optionSelectionProjectCountry: any;
  projectCountry: any;
  setProjectCountry: any;
  projectCountryId: any;
  setProjectCountryId: any;
  setFieldValue: any;
  countries: any;
}> = ({
  data,
  values,
  errors,
  touched,
  handleChange,
  setIsOpenSelectProjectType,
  isOpenSelectProjectType,
  optionSelectionProjectType,
  projectType,
  setProjectType,
  setIsOpenSelectProjectMode,
  isOpenSelectProjectMode,
  optionSelectionProjectMode,
  projectMode,
  setProjectMode,
  setIsOpenSelectProjectOperationType,
  isOpenSelectProjectOperationType,
  optionSelectionProjectOperationType,
  projectOperationType,
  setProjectOperationType,
  setIsOpenSelectProjectComplexity,
  isOpenSelectProjectComplexity,
  optionSelectionProjectComplexity,
  projectComplexity,
  setProjectComplexity,
  setIsOpenSelectProjectCountry,
  isOpenSelectProjectCountry,
  optionSelectionProjectCountry,
  projectCountry,
  setProjectCountry,
  projectCountryId,
  setProjectCountryId,
  setFieldValue,
  countries
}) => {
  const [alertSelectFunctional, setAlertSelectFunctional] = useState(false);

  const [visibleInputs, setVisibleInputs] = useState(
    data?.countries.length > 1 ? data?.countries.length : 1
  );

  const projectTypeOptions = [
    {
      id: 'B2B',
      name: 'B2B'
    },
    {
      id: 'B2B2C',
      name: 'B2B2C'
    }
  ];

  const modeOptions = [
    {
      id: 'Virtual',
      name: 'Virtual'
    },
    {
      id: 'Presencial',
      name: 'Presencial'
    },
    {
      id: 'Híbrido',
      name: 'Híbrido'
    }
  ];

  const operationTypeOptions = [
    {
      id: 'Salones Privados',
      name: 'Salones Privados'
    },
    {
      id: 'Cursos B2C',
      name: 'Cursos B2C'
    }
  ];

  const complexityOptions = [
    {
      id: 'Alto',
      name: 'Alto'
    },
    {
      id: 'Medio',
      name: 'Medio'
    },
    {
      id: 'Bajo',
      name: 'Bajo'
    }
  ];

  const checkBoxes = [
    {
      key: 'has_bootcamp',
      value: 'Bootcamp',
      isChecked: data?.has_bootcamp
    },
    {
      key: 'has_course',
      value: 'Curso',
      isChecked: data?.has_course
    },

    {
      key: 'has_game_jam',
      value: 'Game Jam',
      isChecked: data?.has_game_jam
    },

    { key: 'has_hackaton', value: 'Hackaton', isChecked: data?.has_hackaton },
    {
      key: 'has_personalization',
      value: 'Personalizado',
      isChecked: data?.has_personalization
    },
    {
      key: 'has_other',
      value: 'Otro',
      isChecked: data?.has_other
    }
  ];

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
  };

  const renderCheckbox = ({
    label,
    name,
    defaultChecked,
    color = '#313131'
  }: {
    label: string;
    name: string;
    color?: string;
    defaultChecked?: boolean;
  }) => (
    <div className="modal-create-project__project-information--checkbox-container--item">
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            onChange={handleCheckboxChange}
            defaultChecked={defaultChecked}
            sx={{
              color: { color },
              '&.Mui-checked': { color: { color } }
            }}
          />
        }
        label={label}
      />
    </div>
  );

  const handleAddInput = () => {
    if (visibleInputs < countries.length) {
      setVisibleInputs(visibleInputs + 1);
    }
  };

  const [isOpenSelectProjectCountries, setIsOpenSelectProjectCountries] =
    useState([false, false, false]);

  const renderInputs = () => {
    const inputs = [];
    for (let i = 0; i < visibleInputs; i++) {
      inputs.push(
        <>
          <div className="modal-create-project__container-select">
            <SelectGeneral
              setIsOpen={isOpen => {
                const updatedIsOpen = [...isOpenSelectProjectCountries];
                updatedIsOpen[i] = isOpen;
                setIsOpenSelectProjectCountries(updatedIsOpen);
              }}
              isOpen={isOpenSelectProjectCountries[i]}
              size="default"
              onclickDefaultValue={optionSelectionProjectCountry}
              disabled={false}
              alert={alertSelectFunctional}
              setAlert={setAlertSelectFunctional}
              typeName={projectCountry[i]}
              setTypeName={e => {
                const updatedCountry = [...projectCountry];
                updatedCountry[i] = e;
                setProjectCountry(updatedCountry);
              }}
              label={'Selecciona un país'}
              options={countries}
              setTypeId={(e: any) => {
                const updatedCountryId = [...projectCountryId];
                updatedCountryId[i] = e;
                setProjectCountryId(updatedCountryId);
              }}
              index={i}
            />
          </div>
          <br />
        </>
      );
    }
    return inputs;
  };

  return (
    <div className="modal-create-project__project-information modal-create-project__second-form">
      <Typography bold type="paragraph3" variation="mobile">
        Productos <br />
        incluidos*
      </Typography>

      <FormGroup>
        <div className="modal-create-project__project-information--checkbox-container">
          {checkBoxes.map((item, index) => {
            return (
              <div key={index}>
                {renderCheckbox({
                  label: item.value,
                  name: item.key,
                  defaultChecked: item.isChecked
                })}
              </div>
            );
          })}
        </div>
      </FormGroup>
      <Typography bold type="paragraph3" variation="mobile">
        País/es del <br />
        proyecto*
      </Typography>
      <div className="modal-create-project__project-information--content-button">
        {renderInputs()}
        <button
          type="button"
          className="modal-create-project__project-information--button-underline"
          onClick={handleAddInput}
        >
          <Typography bold type="paragraph3" variation="mobile">
            Añadir otro país +
          </Typography>
        </button>
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Link a carpeta*
      </Typography>
      <Input
        placeholder="Pega el link de la carpeta Drive"
        name="folder_link"
        onChange={handleChange}
        value={values?.folder_link}
        required
        error={
          errors?.folder_link && touched?.folder_link ? errors?.folder_link : ''
        }
      />
      <Typography bold type="paragraph3" variation="mobile">
        Tipo de proyecto*
      </Typography>
      <div className="modal-create-project__container-select">
        <SelectGeneral
          setIsOpen={setIsOpenSelectProjectType}
          isOpen={isOpenSelectProjectType}
          size="default"
          onclickDefaultValue={optionSelectionProjectType}
          disabled={false}
          alert={alertSelectFunctional}
          setAlert={setAlertSelectFunctional}
          typeName={projectType}
          setTypeName={setProjectType}
          label={'Selecciona el tipo de proyecto'}
          options={projectTypeOptions}
        />
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Modalidad*
      </Typography>
      <div className="modal-create-project__container-select">
        <SelectGeneral
          setIsOpen={setIsOpenSelectProjectMode}
          isOpen={isOpenSelectProjectMode}
          size="default"
          onclickDefaultValue={optionSelectionProjectMode}
          disabled={false}
          alert={alertSelectFunctional}
          setAlert={setAlertSelectFunctional}
          typeName={projectMode}
          setTypeName={setProjectMode}
          label={'Selecciona la modalidad'}
          options={modeOptions}
        />
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Tipo de operación*
      </Typography>
      <div className="modal-create-project__container-select">
        <SelectGeneral
          setIsOpen={setIsOpenSelectProjectOperationType}
          isOpen={isOpenSelectProjectOperationType}
          size="default"
          onclickDefaultValue={optionSelectionProjectOperationType}
          disabled={false}
          alert={alertSelectFunctional}
          setAlert={setAlertSelectFunctional}
          typeName={projectOperationType}
          setTypeName={setProjectOperationType}
          label={'Selecciona el tipo de operación'}
          options={operationTypeOptions}
        />
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Nivel de <br />
        complejidad*
      </Typography>
      <div className="modal-create-project__container-select">
        <SelectGeneral
          setIsOpen={setIsOpenSelectProjectComplexity}
          isOpen={isOpenSelectProjectComplexity}
          size="default"
          onclickDefaultValue={optionSelectionProjectComplexity}
          disabled={false}
          alert={alertSelectFunctional}
          setAlert={setAlertSelectFunctional}
          typeName={projectComplexity}
          setTypeName={setProjectComplexity}
          label={'Selecciona el nivel'}
          options={complexityOptions}
        />
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Descripción <br />
        del proyecto*
      </Typography>
      <div className="modal-create-project__container-textarea">
        <Textarea
          placeholder="Añade una breve descripción..."
          defaultValue={''}
          hideOutline={true}
          name="project_description"
          onChange={handleChange}
          value={values?.project_description}
        />
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Comentarios <br />
        adicionales
      </Typography>
      <div className="modal-create-project__container-textarea">
        <Textarea
          placeholder="Añade comentarios..."
          defaultValue={''}
          hideOutline={true}
          name="project_comments"
          onChange={handleChange}
          value={values?.project_comments}
        />
      </div>
    </div>
  );
};

export default SecondForm;
