// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';
import {
  IGuardian,
  IGuardianDetails,
  UpdateInvoice
} from '../interfaces/guardian.interface';

// Constants
import { FILTERS_TO_QUERY_PARAMS_TRANSLATION } from '../constants/guardianList.constants';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

// GUARDIANS
export const getGuardians = async (
  currentPage: number,
  filters: object | null
) => {
  const params = new URLSearchParams();
  params.append('page', currentPage.toString());

  if (filters != null) {
    for (const [key, value] of Object.entries(filters)) {
      const keyToAdd = FILTERS_TO_QUERY_PARAMS_TRANSLATION.get(key) || key;
      params.append(keyToAdd, value.toString());
    }
  }

  const url = `${instanceBaseURL}/backoffice/v2/users?${params.toString()}`;

  const { data } = await axios.get<IGuardian[]>(url, {headers: { Authorization: `Bearer ${getAccessCookie()}` }});
  return data;
};

export const getGuardianForId = async (guardianId: any) => {
  const { data } = await axios.get<IGuardian>(
    `${instanceBaseURL}/users/${guardianId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getGuardianInvoices = async (guardianId: number) => {
  const { data } = await axios.get<IGuardianDetails>(
    `${instanceBaseURL}/users/${guardianId}/students/invoices/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const patchEditGuardian = async (guardian: any, guardianId: any) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/users/${guardianId}/`,
    guardian,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const patchEditGuardianInvoice = async (
  invoiceId: any,
  invoice: UpdateInvoice
) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/invoices/${invoiceId}/`,
    invoice,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postCreateGuardian = async (guardian: IGuardian) => {
  const { data } = await axios.post(
    `${instanceBaseURL}/account/register/`,
    guardian,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};
