//React
import { useEffect } from 'react';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// Moment
import moment from 'moment';

// Utils

// Interfaces

// Material UI
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker
} from '@mui/x-date-pickers';
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  LinearProgress,
  TextField
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';

import {
  getAllCourses,
  selectStateCourses
} from '../../../../slices/courseSlice/coursesSlice';
import { AppDispatch, useAppDispatch } from '../../../../store/store';
import { IGroupDetail } from '../../../../interfaces/group.interface';
import { getErrorMessage, sortAlphabetically } from '../../../../utils/utils';
import {
  listGroupById,
  selectStateGroupById
} from '../../../../slices/groupSlice/groupByIdSlice';
import {
  addGroup,
  getAllGroups
} from '../../../../slices/groupSlice/groupsSlice';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, 'Muy pequeño!')
    .max(500, 'Mucho texto!')
    .required('Requerido'),
  course: Yup.number()
    .test('Is positive?', 'Seleccione un curso', value => value! > 0)
    .required('Requerido'),

  start_date: Yup.date().nullable(true),
  end_date: Yup.date()
    .nullable(true)
    .min(
      Yup.ref('start_date'),
      'FIN del curso NO puede ser antes que el INICIO del curso'
    ),

  published_at: Yup.date().nullable(true),
  archived_at: Yup.date()
    .nullable(true)
    .min(Yup.ref('published_at'), 'ARCHIVADO NO puede ser antes que PUBLICADO'),

  time_monday: Yup.date().nullable(true),
  time_tuesday: Yup.date().nullable(true),
  time_wednesday: Yup.date().nullable(true),
  time_thursday: Yup.date().nullable(true),
  time_friday: Yup.date().nullable(true),
  time_saturday: Yup.date().nullable(true),

  duration_monday: Yup.number().test(
    'Is positive?',
    'Solo números positivos',
    value => value! >= 0
  ),
  duration_tuesday: Yup.number().test(
    'Is positive?',
    'Solo números positivos',
    value => value! >= 0
  ),
  duration_wednesday: Yup.number().test(
    'Is positive?',
    'Solo números positivos',
    value => value! >= 0
  ),
  duration_thursday: Yup.number().test(
    'Is positive?',
    'Solo números positivos',
    value => value! >= 0
  ),
  duration_friday: Yup.number().test(
    'Is positive?',
    'Solo números positivos',
    value => value! >= 0
  ),
  duration_saturday: Yup.number().test(
    'Is positive?',
    'Solo números positivos',
    value => value! >= 0
  )
});

interface Props {
  groupId: number;
  refetch?: () => {};
  onClose: () => void;
}

const DuplicateGroup: React.FC<Props> = ({ groupId, refetch, onClose }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { courses, isLoading: isLoadingCourses } =
    useSelector(selectStateCourses);
  const { group, isLoading: isLoadingGroup } =
    useSelector(selectStateGroupById);

  useEffect(() => {
    if (groupId) {
      dispatch(listGroupById(groupId));
    }
    dispatch(getAllCourses());
  }, [groupId]);

  const createGroup = async (newGroup: IGroupDetail) => {
    try {
      await dispatch(addGroup(newGroup));
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAllGroups({ currentPage: 1, size: 100 }));
    }
  };

  if (isLoadingGroup || isLoadingCourses || !group || !courses)
    return <LinearProgress />;
  /* if (isErrorGroup) return <div>Error! {JSON.stringify(errorGroup)}</div>;
  if (isErrorCourses) return <div>Error! {JSON.stringify(errorCourses)}</div>; */

  const initialValues = {
    name: group.name,
    course: group.course,

    start_date: group.start_date && moment(group.start_date),
    end_date: group.end_date && moment(group.end_date),

    published_at: group.published_at && moment(group.published_at).toDate(),
    archived_at: group.archived_at && moment(group.archived_at).toDate(),

    time_monday: group.time_monday && moment(`2002-08-01 ${group.time_monday}`),
    time_tuesday:
      group.time_tuesday && moment(`2002-08-01 ${group.time_tuesday}`),
    time_wednesday:
      group.time_wednesday && moment(`2002-08-01 ${group.time_wednesday}`),
    time_thursday:
      group.time_thursday && moment(`2002-08-01 ${group.time_thursday}`),
    time_friday: group.time_friday && moment(`2002-08-01 ${group.time_friday}`),
    time_saturday:
      group.time_saturday && moment(`2002-08-01 ${group.time_saturday}`),

    duration_monday: group.duration_monday,
    duration_tuesday: group.duration_tuesday,
    duration_wednesday: group.duration_wednesday,
    duration_thursday: group.duration_thursday,
    duration_friday: group.duration_friday,
    duration_saturday: group.duration_saturday
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async values => {
        const data = {
          name: values.name,
          course: values.course,

          start_date:
            values.start_date && moment(values.start_date).format('YYYY-MM-DD'),
          end_date:
            values.end_date && moment(values.end_date).format('YYYY-MM-DD'),

          published_at: values.published_at && values.published_at,
          archived_at: values.archived_at && values.archived_at,

          time_monday:
            values.time_monday && moment(values.time_monday).format('H:mm:ss'),
          time_tuesday:
            values.time_tuesday &&
            moment(values.time_tuesday).format('H:mm:ss'),
          time_wednesday:
            values.time_wednesday &&
            moment(values.time_wednesday).format('H:mm:ss'),
          time_thursday:
            values.time_thursday &&
            moment(values.time_thursday).format('H:mm:ss'),
          time_friday:
            values.time_friday && moment(values.time_friday).format('H:mm:ss'),
          time_saturday:
            values.time_saturday &&
            moment(values.time_saturday).format('H:mm:ss'),

          duration_monday: values.duration_monday ? values.duration_monday : 0,
          duration_tuesday: values.duration_tuesday
            ? values.duration_tuesday
            : 0,
          duration_wednesday: values.duration_wednesday
            ? values.duration_wednesday
            : 0,
          duration_thursday: values.duration_thursday
            ? values.duration_thursday
            : 0,
          duration_friday: values.duration_friday ? values.duration_friday : 0,
          duration_saturday: values.duration_saturday
            ? values.duration_saturday
            : 0
        };
        await createGroup(data!);
        onClose();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid
            container
            columnSpacing={6}
            rowSpacing={2}
            alignItems="flex-start"
          >
            {/* Group */}
            <Grid item container columnSpacing={2} rowSpacing={3} xs={12}>
              <Grid item xs={12}>
                <Divider textAlign="left">Grupo</Divider>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="name"
                  label="Nombre del grupo"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{getErrorMessage(errors.name)}</Alert>
                )}
              </Grid>

              {/* start_date | end_date */}
              <Grid item container spacing={1} xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {/* start_date */}
                  <Grid item xs={6}>
                    <DatePicker
                      label="Inicio del curso"
                      format="yyyy-MM-dd"
                      value={values.start_date}
                      onChange={(value: any) =>
                        setFieldValue('start_date', value)
                      }
                      slots={{
                        textField: (params: any) => (
                          <TextField fullWidth {...params} />
                        )
                      }}
                    />
                    {errors.start_date && touched.start_date && (
                      <Alert severity="error">
                        {getErrorMessage(errors.start_date)}
                      </Alert>
                    )}
                  </Grid>

                  {/* end_date */}
                  <Grid item xs={6}>
                    <DatePicker
                      label="Fin del curso"
                      format="yyyy-MM-dd"
                      value={values.end_date || values.start_date}
                      minDate={values.start_date}
                      onChange={(value: any) =>
                        setFieldValue('end_date', value)
                      }
                      slots={{
                        textField: (params: any) => (
                          <TextField fullWidth {...params} />
                        )
                      }}
                    />
                    {errors.end_date && touched.end_date && (
                      <Alert severity="error">
                        {getErrorMessage(errors.end_date)}
                      </Alert>
                    )}
                  </Grid>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="course"
                  options={sortAlphabetically(courses, 'name')}
                  autoHighlight
                  value={
                    courses.find(
                      (course: any) => course.id === values.course
                    ) || null
                  }
                  onChange={(_e, value: any) => {
                    value
                      ? setFieldValue('course', value.id)
                      : setFieldValue('course', -1);
                  }}
                  getOptionLabel={option => `${option.name} (${option.id})`}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name} ({option.id})
                    </Box>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Curso"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.course && touched.course && (
                  <Alert severity="error">
                    {getErrorMessage(errors.course)}
                  </Alert>
                )}
              </Grid>

              {/* published_at | archived_at */}
              <Grid item container spacing={1} xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {/* published_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Publicado"
                      format="yyyy-MM-dd"
                      value={values.published_at}
                      ampm={false}
                      onChange={(value: any) =>
                        setFieldValue('published_at', value)
                      }
                      slots={{
                        textField: (params: any) => (
                          <TextField fullWidth {...params} />
                        )
                      }}
                    />
                    {errors.published_at && touched.published_at && (
                      <Alert severity="error">
                        {getErrorMessage(errors.published_at)}
                      </Alert>
                    )}
                  </Grid>

                  {/* archived_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Archivado"
                      format="yyyy-MM-dd"
                      value={values.archived_at}
                      minDate={values.published_at}
                      ampm={false}
                      onChange={(value: any) =>
                        setFieldValue('archived_at', value)
                      }
                      slots={{
                        textField: (params: any) => (
                          <TextField fullWidth {...params} />
                        )
                      }}
                    />
                    {errors.archived_at && touched.archived_at && (
                      <Alert severity="error">
                        {getErrorMessage(errors.archived_at)}
                      </Alert>
                    )}
                  </Grid>
                </LocalizationProvider>
              </Grid>

              {values.published_at ? (
                <Grid item xs={12}>
                  <Alert variant="standard" severity="success">
                    El grupo esta publicado
                  </Alert>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Alert variant="standard" severity="warning">
                    El grupo esta privado
                  </Alert>
                </Grid>
              )}
            </Grid>

            {/* Schedule */}
            <Grid item container columnSpacing={0.5} rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider textAlign="left">Horario</Divider>
              </Grid>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={2}>
                  <TimePicker
                    label="Lunes"
                    value={values.time_monday}
                    ampm={false}
                    onChange={(value: any) =>
                      setFieldValue('time_monday', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField focused={!!values.time_monday} {...params} />
                      )
                    }}
                  />
                  {errors.time_monday && touched.time_monday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.time_monday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TimePicker
                    label="Martes"
                    value={values.time_tuesday}
                    ampm={false}
                    onChange={(value: any) =>
                      setFieldValue('time_tuesday', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField
                          focused={!!values.time_tuesday}
                          {...params}
                        />
                      )
                    }}
                  />
                  {errors.time_tuesday && touched.time_tuesday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.time_tuesday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TimePicker
                    label="Miercoles"
                    value={values.time_wednesday}
                    ampm={false}
                    onChange={(value: any) =>
                      setFieldValue('time_wednesday', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField
                          focused={!!values.time_wednesday}
                          {...params}
                        />
                      )
                    }}
                  />
                  {errors.time_wednesday && touched.time_wednesday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.time_wednesday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TimePicker
                    label="Jueves"
                    value={values.time_thursday}
                    ampm={false}
                    onChange={(value: any) =>
                      setFieldValue('time_thursday', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField
                          focused={!!values.time_thursday}
                          {...params}
                        />
                      )
                    }}
                  />
                  {errors.time_thursday && touched.time_thursday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.time_thursday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TimePicker
                    label="Viernes"
                    value={values.time_friday}
                    ampm={false}
                    onChange={(value: any) =>
                      setFieldValue('time_friday', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField focused={!!values.time_friday} {...params} />
                      )
                    }}
                  />
                  {errors.time_friday && touched.time_friday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.time_friday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TimePicker
                    label="Sabado"
                    value={values.time_saturday}
                    ampm={false}
                    onChange={(value: any) =>
                      setFieldValue('time_saturday', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField
                          focused={!!values.time_saturday}
                          {...params}
                        />
                      )
                    }}
                  />
                  {errors.time_saturday && touched.time_saturday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.time_saturday)}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="duration_monday"
                    type="number"
                    label="Duración"
                    variant="outlined"
                    focused={!!values.time_monday}
                    value={values.duration_monday}
                    onChange={handleChange}
                  />
                  {errors.duration_monday && touched.duration_monday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.duration_monday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="duration_tuesday"
                    type="number"
                    label="Duración"
                    variant="outlined"
                    focused={!!values.time_tuesday}
                    value={values.duration_tuesday}
                    onChange={handleChange}
                  />
                  {errors.duration_tuesday && touched.duration_tuesday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.duration_tuesday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="duration_wednesday"
                    type="number"
                    label="Duración"
                    variant="outlined"
                    focused={!!values.time_wednesday}
                    value={values.duration_wednesday}
                    onChange={handleChange}
                  />
                  {errors.duration_wednesday && touched.duration_wednesday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.duration_wednesday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="duration_thursday"
                    type="number"
                    label="Duración"
                    variant="outlined"
                    focused={!!values.time_thursday}
                    value={values.duration_thursday}
                    onChange={handleChange}
                  />
                  {errors.duration_thursday && touched.duration_thursday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.duration_thursday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="duration_friday"
                    type="number"
                    label="Duración"
                    variant="outlined"
                    focused={!!values.time_friday}
                    value={values.duration_friday}
                    onChange={handleChange}
                  />
                  {errors.duration_friday && touched.duration_friday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.duration_friday)}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="duration_saturday"
                    type="number"
                    label="Duración"
                    variant="outlined"
                    focused={!!values.time_saturday}
                    value={values.duration_saturday}
                    onChange={handleChange}
                  />
                  {errors.duration_saturday && touched.duration_saturday && (
                    <Alert severity="error">
                      {getErrorMessage(errors.duration_saturday)}
                    </Alert>
                  )}
                </Grid>
              </LocalizationProvider>
            </Grid>

            {/* Edit */}
            <Grid item container rowSpacing={3} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={isLoadingGroup}
                  variant="contained"
                  type="submit"
                  startIcon={<ContentCopyIcon />}
                >
                  Duplicar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default DuplicateGroup;
