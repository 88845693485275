import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './userDropdown.scss';
import { TEACHER_AUXILIARY } from '../../../constants/usersType.constants';

interface Props {
  user: any;
  img: any;
  isOpen: boolean;
  handleLogout: () => void;
}

const UserDropdown: React.FC<Props> = ({
 user, img, handleLogout, isOpen 
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const externalTeacher = user?.permission?.name === TEACHER_AUXILIARY;

  return (
    <>
      <div
        onClick={() => setIsVisible(!isVisible)}
        className={`user-dropdown ${
          isOpen ? 'user-dropdown--open' : 'user-dropdown--close'
        } ${isVisible ? 'user-dropdown--active' : 'user-dropdown--inactive'}`}
      >
        {!isOpen && (
          <div
            data-testid="modal-container"
            className="user-dropdown__pointer"
            onClick={() => setIsVisible(!isVisible)}
          >
            <img
              className="user-dropdown__img"
              src={img}
              alt={`${user?.first_name} ${user?.last_name}`}
            />
          </div>
        )}
        {isOpen && (
          <div>
            <img
              className="user-dropdown__img"
              src={img}
              alt={`${user?.first_name} ${user?.last_name}`}
            />
          </div>
        )}
        {isOpen && (
          <>
            <p
              className={`user-dropdown__name ${
                isVisible
                  ? 'user-dropdown__name--active'
                  : 'user-dropdown__name--inactive'
              }`}
            >
              {user ? `${user?.first_name.split(' ')[0]}` : ''}
            </p>
          </>
        )}
      </div>
      {isVisible && (
        <div
          className="user-dropdown__modal-container"
          onClick={() => setIsVisible(false)}
        >
          <ul
            className={`user-dropdown__modal ${
              isOpen
                ? 'user-dropdown__modal--open'
                : 'user-dropdown__modal--close'
            }`}
          >
            <li className="user-dropdown__modal-item-name">
              {user
                ? `${user?.first_name.split(' ')[0]} ${user?.last_name[0]}.`
                : ''}
            </li>
            <li
              className={`user-dropdown__modal-item-username ${
                user?.username.length > 25 &&
                'user-dropdown__modal-item-username--split'
              }`}
            >
              {user?.username}
            </li>
            {!externalTeacher && (
              <>
                <li className="user-dropdown__modal-item user-dropdown__modal-item--disabled">
                  Perfil
                </li>
                {user.permission.name === 'Teacher' ? (
                  <Link
                    className="user-dropdown__modal-link-item"
                    to={`teachers/teacher-availability/teacher-profile/${user.id}`}
                  >
                    Perfil de profesor
                  </Link>
                ) : null}
                <li className="user-dropdown__modal-item user-dropdown__modal-item--disabled">
                  Configuración
                </li>
              </>
            )}
            <li className="user-dropdown__modal-item" onClick={handleLogout}>
              Salir
            </li>
          </ul>
        </div>
      )}
      {isVisible && <></>}
    </>
  );
};

export default UserDropdown;
