import './survey.scss';
import ButtonFilled from '../ButtonFilled';
import Typography from '../../atoms/Typography/Typography';

interface Props {
  isOpen: boolean;
}

const Survey: React.FC<Props> = ({ isOpen }) => {
  return (
    <div className={`survey survey--${isOpen ? 'open' : 'close'}`}>
      <Typography type="paragraph2" variation="mobile">
        ¿Tienes algún comentario o propuesta de mejora?
      </Typography>
      <ButtonFilled
        size="small"
        fullWidth
        target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSdX9ya66T8wJEUO0A_v_KOZXx0c0TPiirPulCbmZJJMAGIniQ/viewform"
      >
        ¡Cuéntanos!
      </ButtonFilled>
    </div>
  );
};

export default Survey;
