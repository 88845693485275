import { ContractInfo, PaymentInfo } from './Forms/index';

export const CreateTeacherTab2: React.FC<{
  values: any;
  handleChange: any;
  setFieldValue: any;
  teacherId?: any;
  selectModality: any;
  selectPaymentCurrency: any;
  selectPaymentFrequency: any;
  errors: any;
  touched: any;
  isProfile?: boolean;
}> = ({
  values,
  handleChange,
  setFieldValue,
  teacherId,
  selectModality,
  selectPaymentCurrency,
  selectPaymentFrequency,
  errors,
  touched,
  isProfile
}) => {
  return (
    <>
      <ContractInfo
        isProfile={isProfile}
        errors={errors}
        touched={touched}
        selectModality={selectModality}
        teacherId={teacherId}
        values={values}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <br />
      <PaymentInfo
        isProfile={isProfile}
        errors={errors}
        touched={touched}
        selectPaymentCurrency={selectPaymentCurrency}
        selectPaymentFrequency={selectPaymentFrequency}
        teacherId={teacherId}
        values={values}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
    </>
  );
};
