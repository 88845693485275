// Moment
// Material UI
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridSortModel,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import 'moment/locale/es';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { Chip, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import VerifiedIcon from '@mui/icons-material/Verified';

import { GET } from '../../services/api/api';
import { objectsEqual } from '../../utils/utils';
import ModalEditInvoice from './ModalEditInvoice/ModalEditInvoice';
import {
  IInvoiceDetailPag,
  IInvoicesPag
} from '../../interfaces/invoice.interface';
import CustomLoadingOverlay from '../../components/CustomLoadingOverlay/CustomLoadingOverlay';
import CustomQuickSearchToolbar from '../../components/CustomQuickSearchToolbar/CustomQuickSearchToolbar';

interface Props {}

const Invoices: React.FC<Props> = () => {
  const size = 100;
  const count = 0;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const [rows, setRows] = useState<IInvoiceDetailPag[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc'
    }
  ]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const {
    data: invoices,
    error,
    isError,
    isFetching,
    refetch
  } = useQuery(
    ['invoices-backoffice', currentPage, searchText, sortModel[0]],
    () =>
      GET<IInvoicesPag>(
        `backoffice/v2/invoices-all/0?page=${
          currentPage === 0 ? 1 : currentPage
        }&size=${size}&sort=${sortModel[0].field}&direction=${sortModel[0].sort}${
          searchText ? `&search=${searchText}` : ''
        }`
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !searchText || searchText.length >= 3
    }
  );

  useEffect(() => {
    if (invoices?.results) {
      setRows(invoices.results);
    }
  }, [invoices]);

  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  const currentlySelected = (model: GridSortModel) => {
    !objectsEqual(model, sortModel) && setSortModel(model);
  };
  const requestSearch = (searchValue: string) => {
    setCurrentPage(1);
    setSearchText(searchValue);
  };

  return (
    <Grid container rowGap={2} style={{ overflowY: 'scroll', height: '100%' }}>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          style={{ fontSize: 12 }}
          loading={isFetching}
          sortModel={sortModel}
          onSortModelChange={model => currentlySelected(model)}
          rows={rows}
          columns={generateColumnsByInvoices()}
          pageSize={size}
          rowsPerPageOptions={[size]}
          components={{
            Toolbar: CustomQuickSearchToolbar,
            LoadingOverlay: CustomLoadingOverlay
          }}
          page={currentPage - 1}
          pagination
          paginationMode="server"
          rowCount={invoices?.count || count}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                requestSearch(event.target.value),
              clearSearch: () => requestSearch('')
            }
          }}
          onPageChange={newPage => handlePageChange(newPage + 1)}
        />
      </Grid>
    </Grid>
  );

  function generateColumnsByInvoices(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'edit',
        headerName: 'Edit',
        width: 85,
        minWidth: 85,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <ModalEditInvoice
                invoiceId={params.row.id}
                invoice={params.row}
                refetch={refetch}
              />
            </Stack>
          );
        }
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'username',
        headerName: 'cliente',
        minWidth: 150,
        editable: false,
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.username;
        }
      },
      {
        field: 'email',
        headerName: 'email',
        minWidth: 150,
        editable: false,
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.user_email;
        }
      },
      {
        field: 'invoiced_at',
        headerName: 'fecha de facturación',
        minWidth: 100,
        editable: false,
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.invoiced_at && moment(params.row.invoiced_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.invoiced_at &&
            moment(params.row.invoiced_at).format('YYYY MMM D')
          );
        }
      },
      {
        field: 'payment_gateway',
        headerName: 'Método de pago',
        minWidth: 100,
        editable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.payment_gateway;
        }
      },
      {
        field: 'coupon_code',
        headerName: 'Cupón',
        minWidth: 100,
        editable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.coupon_code;
        }
      },
      {
        field: 'source',
        headerName: 'Fuente de pago',
        minWidth: 100,
        editable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          if (params.row.source === 'payment_web') {
            return 'WEB';
          } else if (params.row.source === 'payment_inscription') {
            return 'Inscripción Interna';
          } else if (params.row.source === 'backoffice_form') {
            return 'BO';
          } else if (params.row.source === 'batch_load') {
            return 'Carga Lote';
          } else {
            return params.row.source;
          }
        }
      },
      {
        field: 'is_verified',
        headerName: 'Pago verificado',
        minWidth: 100,
        editable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.is_verified;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <>
              {params.row.is_verified ? (
                <Chip
                  icon={<VerifiedIcon />}
                  label="Verificado"
                  variant="outlined"
                  color="success"
                />
              ) : (
                <Chip
                  icon={<HelpIcon />}
                  label="Sin confirmar"
                  variant="outlined"
                  color="warning"
                />
              )}
            </>
          );
        }
      },
      {
        field: 'currency',
        headerName: 'Divisa',
        minWidth: 50,
        editable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.currency;
        }
      },
      {
        field: 'amount',
        headerName: 'Monto',
        minWidth: 50,
        editable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.amount;
        }
      }
    ];
    return columns;
  }
};

export default Invoices;
