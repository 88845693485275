import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Hot Toast

import { IStudent } from '../../interfaces/students.interface';
import { getStudentForId, patchEditStudent } from '../../services/students';

export interface IAuthError {
  msg: string;
}

export interface IStudentByIdState {
  students: IStudent[];
  student: IStudent | {};
  isLoadingEditStudent: boolean;
  isLoadingUpdateStudent: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
}

const initialState: IStudentByIdState = {
  students: [],
  student: {},
  isLoadingEditStudent: false,
  isLoadingUpdateStudent: false,
  isCompleted: false,
  error: null,
  rejected: false
};

export const getStudentById = createAsyncThunk(
  'studentsById/getStudentById',
  async (studentId: number, { rejectWithValue }: any) => {
    try {
      const data = await getStudentForId(studentId);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateStudentById = createAsyncThunk(
  'studentsById/updateStudentById',
  async (
    { studentId, student }: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await patchEditStudent(student, studentId);

      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const studentsByIdSlice = createSlice({
  name: 'studentsById',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getStudentById */
    builder.addCase(getStudentById.pending, (state, _) => {
      state.student = initialState.student;
      state.isLoadingEditStudent = true;
    });
    builder.addCase(getStudentById.fulfilled, (state, action) => {
      state.student = action.payload;
      state.isLoadingEditStudent = false;
      state.error = null;
    });
    builder.addCase(getStudentById.rejected, (state, _) => {
      state.isLoadingEditStudent = initialState.isLoadingEditStudent;
      state.rejected = true;
    });
    /** updateStudentById */
    builder.addCase(updateStudentById.pending, (state, _) => {
      state.isLoadingUpdateStudent = true;
      state.isCompleted = false;
    });
    builder.addCase(updateStudentById.fulfilled, (state, action) => {
      state.student = action.payload;
      state.isLoadingUpdateStudent = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateStudentById.rejected, (state, _) => {
      state.isLoadingUpdateStudent = initialState.isLoadingUpdateStudent;
      state.rejected = true;
      state.isCompleted = false;
    });
  }
});

export const selectStateStudentForId = (state: any) => state.studentsById;
