// React
import { useState } from 'react';
import { AxiosError } from 'axios';

// React Query
import toast from 'react-hot-toast';

// Material UI
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { ICourseProjectBenefit } from '../../../interfaces/course.interface';
import {
  deleteProjectBenefitsByCourse,
  patchProjectBenefitsByCourse,
  postProjectBenefitsByCourse
} from '../../../services/courses';
import { confirmDialog } from '../../ConfirmDialog/ConfirmDialog';

interface Props {
  benefits: ICourseProjectBenefit[];
  courseId: number;
  refetch: any;
  refetchCourse: any;
}

const CourseProjecsBenefits: React.FC<Props> = ({
  benefits,
  courseId,
  refetch,
  refetchCourse
}) => {
  const [benefitId, setBenefitId] = useState<number>();
  const [benefit, setBenefit] = useState<string>();

  const [isLoadingProjectBenefits, setIsLoadingProjectBenefits] =
    useState<boolean>(false);

  const handleCreateBenefitsProject = async (courseId: number) => {
    if (benefit && courseId) {
      setIsLoadingProjectBenefits(true);
      try {
        const response = await postProjectBenefitsByCourse(benefit, courseId);
        refetchCourse(courseId);
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo guardar la información - ${error}`);
          setIsLoadingProjectBenefits(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  const handleEditBenefitsProject = async (
    courseId: number,
    benefitId: number
  ) => {
    if (benefit && courseId) {
      setIsLoadingProjectBenefits(true);
      try {
        const response = await patchProjectBenefitsByCourse(
          benefit,
          courseId,
          benefitId
        );
        refetchCourse(courseId);
        toast.success('¡Se guardo la información correctamente!');
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo actualizar la información - ${error}`);
          setIsLoadingProjectBenefits(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };
  const handleDeleteBenefitsProject = async (
    courseId: number,
    benefitId: number
  ) => {
    if (benefitId && courseId) {
      setIsLoadingProjectBenefits(true);
      try {
        const response = await deleteProjectBenefitsByCourse(benefitId);
        refetchCourse(courseId);
        toast.success('¡Se eliminó correctamente!');
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo eliminar - ${error}`);
          setIsLoadingProjectBenefits(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  return (
    <form>
      <Grid container rowSpacing={1} columnSpacing={1} sx={{ padding: 3 }}>
        {/* Main */}
        {benefits?.length > 0 &&
          benefits
            ?.sort((a: any, b: any) => a.id - b.id)
            .map((item: any, idx: any) => (
              <Grid item xs={12} key={idx + 1}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={3}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="subtitle2">{`Beneficio ${idx + 1}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" columnGap={0}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setBenefit(item?.benefit);
                          setBenefitId(item.id);
                        }}
                        color="secondary"
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        aria-label="remove"
                        onClick={e => {
                          e.stopPropagation();
                          confirmDialog(
                            `¿Realmente quieres eliminar el beneficio:${idx + 1}?`,
                            () => handleDeleteBenefitsProject(courseId, item.id)
                          );
                        }}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{item?.benefit}</Typography>
                </Grid>
              </Grid>
            ))}

        <Grid item xs={12}>
          <Typography sx={{ margin: '15px 0 7px' }} variant="subtitle2">
            {benefitId
              ? 'Actualizar beneficios del Proyecto'
              : 'Agregar beneficios del Proyecto'}
          </Typography>
        </Grid>
        <Divider />
        {/* Btn Add ToDo */}
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="answer"
                label="Beneficio"
                placeholder="Beneficio"
                minRows={2}
                value={benefit}
                onChange={e => setBenefit(e.target.value)}
                style={{
                  width: '100%',
                  minWidth: '100%',
                  maxWidth: '100%'
                }}
                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                onClick={
                  benefitId
                    ? () => handleEditBenefitsProject(courseId, benefitId)
                    : () => handleCreateBenefitsProject(courseId)
                }
                startIcon={benefitId ? <UpdateIcon /> : <AddCircleIcon />}
                color={benefitId ? 'secondary' : 'primary'}
                sx={{
                  width: '100%',
                  minWidth: '100%',
                  maxWidth: '100%'
                }}
              >
                {benefitId ? 'Editar beneficio' : 'Agregar beneficio'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CourseProjecsBenefits;
