import { ReactNode } from 'react';
import {
 Dialog, useTheme, DialogContent, useMediaQuery 
} from '@mui/material';

import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';

interface Props {
  id?: number;
  copyUrl?: boolean;
  fullWidth?: boolean;
  title: string;
  children: ReactNode;
  openModal: boolean;
  dividers?: boolean;
  handleCloseModal: () => void;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const Modal: React.FC<Props> = ({
  id,
  copyUrl = false,
  title,
  fullWidth,
  maxWidth = 'md',
  openModal,
  dividers = true,
  handleCloseModal,
  children
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          '.MuiDialog-paperWidthLg': { maxWidth: '1370px' },
          fontFamily: 'Sofia Pro',
          fontStyle: 'normal'
        }}
      >
        <CustomDialogTitle
          onClose={handleCloseModal}
          options={{ id: id, copyUrl: copyUrl }}
        >
          {title}
        </CustomDialogTitle>
        <DialogContent dividers={dividers}>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
