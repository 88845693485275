import { PersonalInfo, CTCInfo } from './Forms/index';
import './CreateTeacherTab.scss';

export const CreateTeacherTab1: React.FC<{
  courseTrainedData: any;
  ageGroupData: any;
  knowledgesData: any;
  subknowledgesData: any;
  values: any;
  handleChange: any;
  setFieldValue: any;
  teacherId?: any;
  availability: any;
  activeToggle: any;
  selectDocType: any;
  selectStatus: any;
  errors: any;
  touched: any;
  setAgeGroups: any;
  ageGroups: any;
  setTeacherCourses: any;
  teacherCourses: any;
  setTeacherKnowledges: any;
  teacherKnowledges: any;
  setTeacherSubknowledges: any;
  teacherSubknowledges: any;
  defaultCountry?: any;
  isProfile?: boolean;
  teacherProfileById: any;
  teacherProfileV2: any;
}> = ({
  courseTrainedData,
  ageGroupData,
  knowledgesData,
  subknowledgesData,
  values,
  handleChange,
  setFieldValue,
  teacherId,
  availability,
  activeToggle,
  selectDocType,
  selectStatus,
  errors,
  touched,
  setAgeGroups,
  ageGroups,
  setTeacherCourses,
  teacherCourses,
  setTeacherKnowledges,
  teacherKnowledges,
  setTeacherSubknowledges,
  teacherSubknowledges,
  defaultCountry,
  isProfile,
  teacherProfileById,
  teacherProfileV2
}) => {
  return (
    <>
      <PersonalInfo
        isProfile={isProfile}
        defaultCountry={defaultCountry}
        errors={errors}
        touched={touched}
        selectDocType={selectDocType}
        teacherId={teacherId}
        setFieldValue={setFieldValue}
        values={values}
        handleChange={handleChange}
      />
      <br />
      <CTCInfo
        teacherProfileV2={teacherProfileV2}
        teacherProfileById={teacherProfileById}
        isProfile={isProfile}
        setAgeGroups={setAgeGroups}
        ageGroups={ageGroups}
        setTeacherCourses={setTeacherCourses}
        teacherCourses={teacherCourses}
        setTeacherKnowledges={setTeacherKnowledges}
        teacherKnowledges={teacherKnowledges}
        setTeacherSubknowledges={setTeacherSubknowledges}
        teacherSubknowledges={teacherSubknowledges}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        selectStatus={selectStatus}
        activeToggle={activeToggle}
        availability={availability}
        teacherId={teacherId}
        values={values}
        handleChange={handleChange}
        ageGroupData={ageGroupData}
        courseTrainedData={courseTrainedData}
        knowledgesData={knowledgesData}
        subknowledgesData={subknowledgesData}
      />
    </>
  );
};
