/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';

import IconArrowDown from '../../atoms/icons/IconArrowDown';
import Typography from '../../atoms/Typography/Typography';
import SelectOptions from '../SelectOptions/SelectOptions';
import ItemFloatingMenu from '../ItemFloatingMenu';
import IconArrowUp from '../../atoms/icons/IconArrowUp';

// Colors
import colors from '../../atoms/Colors';

import './select.scss';

interface Props {
  children: any;
  alert?: boolean;
  isOpen?: any;
  setIsOpen?: any;
  options?: any;
  className?: string;
  identifierSort?: string;
  sortBy?: string;
  size?: any;
  disabled?: any;
  defaultValue?: any;
  colorText?: string;
  dividerItems?: boolean;
  onclickDefaultValue: Function;
  itemsDropdown?: any;
  addIsScroll?: string;
  useSortBy?: boolean;
  error?: boolean;
}

const Select: React.FC<Props> = ({
  children,
  alert = false,
  isOpen = false,
  setIsOpen,
  size = 'default',
  identifierSort = 'name',
  sortBy = 'id',
  className,
  disabled,
  colorText,
  options,
  onclickDefaultValue,
  itemsDropdown,
  addIsScroll,
  useSortBy,
  dividerItems = false,
  error
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isOpenSelect, setIsOpenSelect] = useState(isOpen);
  const dataSort = Array.isArray(options) ? [...options] : options;

  const onClickHandler = (option: any): void => {
    onclickDefaultValue(option);
    setIsOpenSelect(!isOpenSelect);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpenSelect(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const dataOptionsSorted = dataSort
    ?.sort((a: any, b: any) =>
      a?.[identifierSort] > b?.[identifierSort] ? 1 : -1
    )
    .map((option: any) => (
      <ItemFloatingMenu
        dividerItems={dividerItems}
        key={option?.id}
        onClick={() => onClickHandler(option)}
      >
        {option?.[identifierSort]}
      </ItemFloatingMenu>
    ));

  const dataOptions = dataSort
    ?.sort((a: any, b: any) => (a?.[sortBy] > b?.[sortBy] ? 1 : -1))
    .map((option: any) => (
      <ItemFloatingMenu
        dividerItems={dividerItems}
        key={option?.id}
        onClick={() => onClickHandler(option)}
      >
        {option?.[identifierSort]}
      </ItemFloatingMenu>
    ));

  return (
    <>
      <div className="select__container">
        <div
          className={`select ${disabled ? 'select__disabled' : ''} ${
            className ? className : ''
          } ${error && 'select__error'}`}
          onClick={disabled ? () => {} : () => setIsOpenSelect(!isOpenSelect)}
        >
          <div className={`select__text`}>
            <Typography
              color={colorText}
              type="paragraph2"
              variation="mobile"
              className={`select__text--ellipsis ${
                disabled ? 'select__text-disabled' : ''
              } `}
            >
              {children}
            </Typography>
          </div>
          {disabled ? (
            <div
              className={`select__icon ${
                isOpenSelect ? 'select--active' : 'select--inactive'
              } select__icon--${size} ${disabled ? 'select__icon--disabled' : ''}`}
            >
              <IconArrowDown fill={error ? 'red' : '#BCBCBC'} />
            </div>
          ) : (
            <div
              className={`select__icon ${
                isOpenSelect ? 'select--active' : 'select--inactive'
              } select__icon--${size}`}
            >
              {isOpenSelect ? (
                <IconArrowUp fill={error ? 'red' : 'black'} />
              ) : (
                <IconArrowDown fill={error ? 'red' : 'black'} />
              )}
            </div>
          )}
          {isOpenSelect && (
            <div ref={ref} className="select__dropdown">
              <SelectOptions
                addIsScroll={addIsScroll}
                itemsDropdown={itemsDropdown}
                isScroll={dataSort?.length > 8 ? true : false}
                size={size}
                options={useSortBy ? dataOptions : dataOptionsSorted}
              />
            </div>
          )}
        </div>
        {alert && (
          <Typography
            type="paragraph2"
            variation="mobile"
            className="select__alert-message"
            color={colors.warningColor}
          >
            Seleccione un valor
          </Typography>
        )}
      </div>
    </>
  );
};

export default Select;
