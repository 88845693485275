// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';
import { IEnrollStudent, IEnrolments } from '../interfaces/enrolment.interface';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? ENROLMENTS
export const getEnrolments = async () => {
  const { data } = await axios.get<IEnrolments>(
    `${instanceBaseURL}/enrolments/form/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postEnrollStudent = async (enrollStudent: IEnrollStudent) => {
  const response = await axios.post(
    `${instanceBaseURL}/enrolments/form/`,
    enrollStudent,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchCancelEnrolment = async (enrollmentId: any) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/enrolments/${enrollmentId}/`,
    { state: 'cancel' },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};
