import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Stack, TextField } from '@mui/material';

interface Props {
  clearSearch: () => void;
  handleSearch: () => void;
  onChange: () => void;
  value: string;
}

const CustomSearchToolbar: React.FC<Props> = ({
  handleSearch,
  onChange,
  value
}) => {
  return (
    <Grid item xs={12}>
      <Stack direction="row" spacing={1.5} justifyContent="space-between">
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Código de Cupón..."
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <SearchIcon fontSize="small" sx={{ marginRight: '10px' }} />
            ),
            endAdornment: (
              <LoadingButton
                sx={{ width: '10%' }}
                variant="contained"
                onClick={handleSearch}
                endIcon={<ArrowForwardIcon />}
              >
                Buscar
              </LoadingButton>
            )
          }}
        />
      </Stack>
    </Grid>
  );
};

export default CustomSearchToolbar;
