import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { authSlice } from './../slices/authSlice';
import { roomsSlice } from '../slices/roomSlice/roomsSlice';
import { priceSlice } from '../slices/pricesSlice/priceSlice';
import { groupsSlice } from '../slices/groupSlice/groupsSlice';
import { coursesSlice } from '../slices/courseSlice/coursesSlice';
import { studentsSlice } from '../slices/studentSlice/studentSlice';
import { landingSlice } from '../slices/landingsSlice/landingSlice';
import { groupByIdSlice } from '../slices/groupSlice/groupByIdSlice';
import { guardiansSlice } from '../slices/guardianSlice/guardianSlice';
import { learningsSlice } from '../slices/learningsSlice/learningsSlice';
import { countrySlice } from '../slices/commons/countrySlice/contrySlice';
import { projectSlice } from '../slices/CTCB2B/projectSlice/projectSlice';
import { enrolmentsSlice } from '../slices/enrolmentSlice/enrolmentsSlice';
import { roomDetailSlice } from '../slices/roomDetailSlice/roomDetailSlice';
import { studentsByIdSlice } from '../slices/studentSlice/studentByIdSlice';
import { attendanceSlice } from '../slices/attendanceSlice/attendanceSlice';
import { roomsByGroupIdSlice } from '../slices/roomSlice/roomsByGroupIdSlice';
import { guardiansByIdSlice } from '../slices/guardianSlice/guardianByIdSlice';
import { guardiansInvoicesSlice } from '../slices/guardianSlice/guardianInvoicesSlice';
import { bulkEnrollmentSlice } from '../slices/bulkEnrollmentSlice/bulkEnrollmentSlice';
import { organizationSlice } from '../slices/CTCB2B/organizationSlice/organizationSlice';
import { overdueContentReportsSlice } from '../slices/CTCB2B/overdueContent/overdueContentSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    roomDetail: roomDetailSlice.reducer,
    rooms: roomsSlice.reducer,
    roomsByGroupId: roomsByGroupIdSlice.reducer,
    guardians: guardiansSlice.reducer,
    guardiansById: guardiansByIdSlice.reducer,
    guardiansInvoices: guardiansInvoicesSlice.reducer,
    students: studentsSlice.reducer,
    studentsById: studentsByIdSlice.reducer,
    groups: groupsSlice.reducer,
    groupById: groupByIdSlice.reducer,
    courses: coursesSlice.reducer,
    enrolments: enrolmentsSlice.reducer,
    learnings: learningsSlice.reducer,
    organizations: organizationSlice.reducer,
    projects: projectSlice.reducer,
    countries: countrySlice.reducer,
    prices: priceSlice.reducer,
    landings: landingSlice.reducer,
    bulk: bulkEnrollmentSlice.reducer,
    attendance: attendanceSlice.reducer,
    overdueContentReports: overdueContentReportsSlice.reducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
