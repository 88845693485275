import { useMemo, useState } from 'react';

import { useModal } from '../../../../hooks/useModal';
import Modal from '../../../../components/Modal/Modal';
import colors from '../../../../components/atoms/Colors';
import Table from '../../../../components/molecules/Table';
import Tooltip from '../../../../components/atoms/Tooltip/Tooltip';
import UpdateStudentRoom from './UpdateStudentRoom/UpdateStudentRoom';
import { AppDispatch, useAppDispatch } from '../../../../store/store';
import ButtonFilled from '../../../../components/molecules/ButtonFilled';
import ModalEditProjectId from './ModalEditProjectId/ModalEditProjectId';
import FloatingMenu from '../../../../components/molecules/FloatingMenu';
import { IRoom, IStudent } from '../../../../interfaces/group.interface';
import useComplementStudents from '../../../../hooks/useComplementStudents';
import ItemFloatingMenu from '../../../../components/molecules/ItemFloatingMenu';
import { deleteGroupStudent } from '../../../../slices/groupSlice/groupByIdSlice';
import { listRoomsByGroupId } from '../../../../slices/roomSlice/roomsByGroupIdSlice';
import AddStudentToGroup from './ModalAddStudentToGroup/AddStudentToGroup/AddStudentToGroup';
import ButtonLineal from '../../../../components/molecules/ButtonLineal';
import GuardianDetails from '../../../Guardians/ModalGuardianDetails/GuardianDetails/GuardianDetails';
import Typography from '../../../../components/atoms/Typography/Typography';
import ModalMessageAlert from '../../../../components/molecules/ModalMessageAlert';

interface Props {
  setRefetchListRoomsByGroupId: any;
  students: IStudent[];
  rooms: IRoom[];
  groupId: number;
  isFetching: boolean;
}

const Students: React.FC<Props> = ({
  students,
  rooms,
  groupId,
  isFetching,
  setRefetchListRoomsByGroupId
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [idTutor, setIdTutor] = useState<null | number>();
  const [idStudent, setIdStudent] = useState<null | number>();
  const [isLoadingDeleteGroup, setIsLoadingDeleteGroup] =
    useState<boolean>(false);

  const {
    openModal: openModalAddStudentToGroup,
    closeModal: closeModalAddStudentToGroup,
    isOpenModal: isOpenModalAddStudentToGroup
  } = useModal();

  const {
    openModal: openModalDetailTutor,
    closeModal: closeModalDetailTutor,
    isOpenModal: isOpenModalDetailTutor
  } = useModal();

  const {
    openModal: openModalDeleteStudent,
    closeModal: closeModalDeleteStudent,
    isOpenModal: isOpenModalDeleteStudent
  } = useModal();

  const { data: complementStudents } = useComplementStudents({
    groupId,
    openModal: isOpenModalAddStudentToGroup
  });

  const cellProjectId = {
    display: 'flex',
    gap: 40
  };

  const handleDeleteGroupStudent = async (
    groupId: number,
    studentId: number
  ) => {
    try {
      setIsLoadingDeleteGroup(true);
      await dispatch(deleteGroupStudent({ groupId, studentId }));
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingDeleteGroup(false);
      await dispatch(listRoomsByGroupId(Number(groupId)));
    }
  };

  const customCellDefault = ({ cell }: any) => <>{cell.value || '-'}</>;

  const customCellEmailTutor = (props: any) => {
    return (
      <>
        {props?.row?.original?.guardian?.id ? (
          <ButtonLineal
            hasPadding={false}
            disabled={props?.row?.original?.guardian?.id ? false : true}
            onClick={() => {
              setIdTutor(Number(props?.row?.original?.guardian?.id));
              openModalDetailTutor();
            }}
          >
            <Typography type="paragraph2" variation="mobile">
              {props?.row?.original?.guardian?.email}
            </Typography>
          </ButtonLineal>
        ) : (
          <>{'-'}</>
        )}
      </>
    );
  };

  const customCellProjectId = (props: any) => {
    return (
      <div style={{ width: props.column.width, ...cellProjectId }}>
        <span>{props.row.original.project_id || '-'}</span>
        <ModalEditProjectId
          setRefetchListRoomsByGroupId={setRefetchListRoomsByGroupId}
          groupId={groupId}
          student={props.row.original}
          projectId={props.row.original.project_id}
        />
      </div>
    );
  };

  const customCellRoom = (props: any) => (
    <>
      {rooms.length > 0 ? (
        <UpdateStudentRoom
          studentId={props.row.original.id}
          rooms={rooms}
          room={props.row.original.room}
          groupId={groupId}
        />
      ) : (
        <Tooltip left="3rem" bg="#595C5C" title="El grupo aún no tiene salones">
          <UpdateStudentRoom
            studentId={props.row.original.id}
            rooms={rooms}
            room={props.row.original.room}
            groupId={groupId}
          />
        </Tooltip>
      )}
    </>
  );

  const customCellGoTo = (props: any) => {
    return (
      <FloatingMenu
        openDirection="right"
        items={[
          <ItemFloatingMenu
            key="item-floating-menu-1"
            color={colors.colorAlertRed}
            onClick={() => {
              setIdStudent(props.row.original.id);
              openModalDeleteStudent();
            }}
          >
            Eliminar estudiante
          </ItemFloatingMenu>
        ]}
      />
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Acciones',
        accessor: 'action',
        actionWidth: 160,
        hasTooltip: true,
        tooltipValue: 'Ir a Zoom',
        tooltipValue1: 'Ir a Wiki',
        Cell: customCellGoTo,
        align: 'center',
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Nombre del estudiante',
        accessor: 'full_name',
        classColumn: 'name-group',
        Cell: customCellDefault,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Project ID',
        accessor: 'project_id',
        classColumn: 'name-teacher',
        widthColumn: 120,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellProjectId
      },
      {
        Header: 'Edad',
        accessor: 'age',
        classColumn: 'name-teacher',
        widthColumn: 142,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellDefault
      },
      {
        Header: 'Correo tutor/Madre/Padre',
        accessor: 'guardian.email',
        classColumn: 'status-evaluation',
        widthColumn: 280,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellEmailTutor
      },
      {
        Header: 'Salón',
        accessor: 'room',
        classColumn: 'end-date-evaluation',
        widthColumn: 300,
        disableSortBy: true,
        disableFilters: true,
        columnType: 'component',
        Cell: customCellRoom
      }
    ],
    []
  );

  return (
    <div>
      {/* Button Añadir estudiante al grupo */}
      <ButtonFilled fullWidth size="small" onClick={openModalAddStudentToGroup}>
        Agregar estudiante al grupo
      </ButtonFilled>
      <br />
      {/* Table Students */}
      <Table data={students} columns={columns} />
      {/* Modal Añadir estudiante al grupo */}
      <Modal
        dividers={false}
        title="Añadir estudiante al grupo"
        fullWidth
        maxWidth="sm"
        openModal={isOpenModalAddStudentToGroup}
        handleCloseModal={closeModalAddStudentToGroup}
      >
        {complementStudents && (
          <AddStudentToGroup
            complementStudents={complementStudents}
            groupId={groupId}
            onClose={closeModalAddStudentToGroup}
          />
        )}
      </Modal>
      {idTutor ? (
        <Modal
          id={idTutor}
          title="Tutor / Madre / Padre"
          copyUrl
          fullWidth
          maxWidth="xl"
          openModal={isOpenModalDetailTutor}
          handleCloseModal={closeModalDetailTutor}
        >
          <GuardianDetails guardianId={Number(idTutor)} />
        </Modal>
      ) : null}
      {idStudent ? (
        <ModalMessageAlert
          className="teacher-profile__modal-alert"
          width="sm"
          align="center"
          title={
            <Typography bold type="h1" variation="mobile" align="center">
              ¿Quieres eliminar el estudiante {idStudent}?
            </Typography>
          }
          isOpenModal={isOpenModalDeleteStudent}
          openModal={openModalDeleteStudent}
          closeModal={closeModalDeleteStudent}
          body={
            <Typography
              type="paragraph2"
              variation="mobile"
              color={colors.gris05}
              align="center"
            >
              Al eliminarlo no se podrá volver a recuperar la información del
              estudiante {idStudent}
            </Typography>
          }
          button1={
            <ButtonFilled
              fullWidth
              disabled={isLoadingDeleteGroup}
              onClick={() =>
                handleDeleteGroupStudent(Number(groupId), Number(idStudent))
              }
            >
              Eliminar
            </ButtonFilled>
          }
          button2={
            <ButtonLineal
              disabled={isLoadingDeleteGroup}
              onClick={closeModalDeleteStudent}
            >
              Cancelar
            </ButtonLineal>
          }
        />
      ) : null}
    </div>
  );
};

export default Students;
