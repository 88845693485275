import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IStudent } from '../../interfaces/students.interface';
import {
  getStudents,
  postCreateStudent,
  getInactiveStudentReasons,
  patchChangeEnrollmentState as changeEnrollmentState
} from '../../services/students';

export interface IAuthError {
  msg: string;
}

export interface IStudentState {
  students: IStudent[] | null | undefined;
  isLoading: boolean;
  completed: boolean;
  error: IAuthError | null;
  rejected: boolean;
  totalPages: number | null;
  totalCount: number | null;
}

const initialState: IStudentState = {
  students: [],
  totalPages: 0,
  totalCount: 0,
  isLoading: false,
  completed: false,
  error: null,
  rejected: false
};

export const getStudentsFromAPI = createAsyncThunk(
  'students/getAllStudents',
  async (studentInfo: any, { rejectWithValue }: any) => {
    const { currentPage, filters } = studentInfo;
    try {
      const data = await getStudents(currentPage, filters);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const postCreateStudents = createAsyncThunk(
  'students/postCreateStudents',
  async (
    newStudent: IStudent,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await postCreateStudent(newStudent);
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getInactiveStudentReasonsFromApi = createAsyncThunk(
  'students/getInactiveStudentReasons',
  async ({ rejectWithValue }: any) => {
    try {
      const data = await getInactiveStudentReasons();
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const patchChangeEnrollmentState = createAsyncThunk(
  'students/patchChangeEnrollmentState',
  async (studentInfo: any, { rejectWithValue }: any) => {
    try {
      const data = await changeEnrollmentState(studentInfo);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getAllStudents */
    builder.addCase(getStudentsFromAPI.pending, (state, _) => {
      state.students = initialState.students;
      state.isLoading = true;
    });
    builder.addCase(getStudentsFromAPI.fulfilled, (state, action) => {
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.count;
      state.students = action.payload.results;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getStudentsFromAPI.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** postStudent */
    builder.addCase(postCreateStudents.pending, (state, _) => {
      state.isLoading = true;
      state.completed = false;
    });
    builder.addCase(postCreateStudents.fulfilled, (state, action) => {
      state?.students?.push(action.payload);
      state.isLoading = false;
      state.completed = true;
      state.error = null;
    });
    builder.addCase(postCreateStudents.rejected, (state, action) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
      state.completed = false;
      state.error = { msg: (action.payload as any).message || 'Error Server' };
    });
    /** getInactiveStudentReasons */
    builder.addCase(getInactiveStudentReasonsFromApi.pending, (state, _) => {
      state.students = initialState.students;
      state.isLoading = true;
    });
    builder.addCase(
      getInactiveStudentReasonsFromApi.fulfilled,
      (state, action) => {
        state.students = initialState.students;
        state.isLoading = false;
        state.error = null;
      }
    );
    builder.addCase(getInactiveStudentReasonsFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** patchChangeEnrollmentState */
    builder.addCase(patchChangeEnrollmentState.pending, (state, _) => {
      state.isLoading = true;
      state.completed = false;
    });
    builder.addCase(patchChangeEnrollmentState.fulfilled, (state, action) => {
      state?.students?.push(action.payload.data);
      state.isLoading = false;
      state.completed = true;
      state.error = null;
    });
    builder.addCase(patchChangeEnrollmentState.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateStudents = (state: any) => state.students;
