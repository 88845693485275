import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getLandings,
  updateLanding as updateLandingById,
  postCreateLanding,
  getLandingById
} from '../../services/landings';

interface IAuthError {
  msg: string;
}

export const addLanding = createAsyncThunk(
  'landings/addLanding',
  async (
    landing: {},
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      return await postCreateLanding(landing);
    } catch (err) {
      toast.error('Ha ocurrido un error al crear la landing.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateLanding = createAsyncThunk(
  'landings/updateLanding',
  async (landing: { id: number; payload: any }, { rejectWithValue }: any) => {
    try {
      const { id, payload } = landing;
      return await updateLandingById(id, payload);
    } catch (err) {
      toast.error('Ha ocurrido un error al editar la landing.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getLandingFromApi = createAsyncThunk(
  'landings/getAllLandings',
  async (
    landingInfo: { page: number; size: number },
    { rejectWithValue }: any
  ) => {
    const { page, size } = landingInfo;
    try {
      const data = await getLandings(page, size);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getLandingByIdFromApi = createAsyncThunk(
  'projects/getLandingById',
  async (projectInfo: { id: number }, { rejectWithValue }: any) => {
    const { id } = projectInfo;
    try {
      return await getLandingById(id);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: {
  landings: any[];
  isLoading: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
  totalPages: number;
  totalCount: number;
} = {
  landings: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const landingSlice = createSlice({
  name: 'landings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** addLanding */
    builder.addCase(addLanding.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addLanding.fulfilled, (state, action) => {
      state?.landings?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addLanding.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getAllLandings */
    builder.addCase(getLandingFromApi.pending, (state, _) => {
      state.landings = initialState.landings;
      state.isLoading = true;
    });
    builder.addCase(getLandingFromApi.fulfilled, (state, action) => {
      state.totalPages = action.payload.data.pages;
      state.totalCount = action.payload.data.total;
      state.landings = action.payload.data.items;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getLandingFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getProjectById */
    builder.addCase(getLandingByIdFromApi.pending, (state, _) => {
      state.landings = initialState.landings;
      state.isLoading = true;
    });
    builder.addCase(getLandingByIdFromApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getLandingByIdFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** updateLanding */
    builder.addCase(updateLanding.pending, (state, _) => {
      state.landings = initialState.landings;
      state.isLoading = true;
    });
    builder.addCase(updateLanding.fulfilled, (state, action) => {
      const payload = action.payload;
      const landings = state.landings.filter(
        landing => landing.id !== payload.id
      );
      state.landings = [...landings, action.payload];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateLanding.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateLandings = (state: any) => state.landings;
