import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getAttendanceByStudent,
  getAttendanceByTeacher
} from '../../services/attendance';

export const getAttendanceByStudentFromApi = createAsyncThunk(
  'attendance/getAttendanceByStudent',
  async (
    attendanceStudentInfo: { id: number; getAllSessions?: boolean },
    { rejectWithValue }: any
  ) => {
    const { id, getAllSessions } = attendanceStudentInfo;
    try {
      const response = await getAttendanceByStudent(id, getAllSessions);
      return response.data; // Solo retorna los datos serializables.
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAttendanceByTeacherFromApi = createAsyncThunk(
  'attendance/getAttendanceByTeacher',
  async (
    attendanceStudentInfo: { id: number; type?: string },
    { rejectWithValue }: any
  ) => {
    const { id, type } = attendanceStudentInfo;
    try {
      return await getAttendanceByTeacher(id, type);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: any = {
  attendances: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const attendanceSlice = createSlice({
  name: 'attendances',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getAttendanceByStudent */
    builder.addCase(getAttendanceByStudentFromApi.pending, (state, _) => {
      state.attendances = initialState.attendances;
      state.isLoading = true;
    });
    builder.addCase(
      getAttendanceByStudentFromApi.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.error = null;
      }
    );
    builder.addCase(getAttendanceByStudentFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getAttendanceByTeacher */
    builder.addCase(getAttendanceByTeacherFromApi.pending, (state, _) => {
      state.attendances = initialState.attendances;
      state.isLoading = true;
    });
    builder.addCase(
      getAttendanceByTeacherFromApi.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.error = null;
      }
    );
    builder.addCase(getAttendanceByTeacherFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateAttendances = (state: any) => state.attendances;
