import moment from 'moment';
import React, { useState, useEffect } from 'react';

import {
  getReasonListDetail,
  postChangeTeacherStable,
  postChangeTeacherTemporal,
  postChangeTeacherTemporalRange
} from '../../../services/rooms';
import './modalChangeTeacher.scss';
import { useModal } from '../../../hooks/useModal';
import Modal from '../../../components/atoms/Modal';
import colors from '../../../components/atoms/Colors';
import Select from '../../../components/molecules/Select/Select';
import { ITeacher } from '../../../interfaces/teacher.interface';
import ButtonLineal from '../../../components/molecules/ButtonLineal';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import IconDiscord from '../../../components/atoms/icons/IconDiscord';
import { getReasonList, getTeacherList } from '../../../services/rooms';
import IconWhatsapp from '../../../components/atoms/icons/IconWhatsapp';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import Typography from '../../../components/atoms/Typography/Typography';
import { IRoomDetailSimplified } from '../../../interfaces/room.interface';
import SelectFunctional from '../../../components/molecules/SelectFunctional';
import ModalMessageAlert from '../../../components/molecules/ModalMessageAlert';
import IconAlertSuccess from '../../../components/atoms/icons/IconAlertSuccess';

interface Props {
  roomInfo: IRoomDetailSimplified;
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  refetchRoomDetails: () => void;
  refetchAttendanceDetails: () => void;
  setActiveTabMain: React.Dispatch<React.SetStateAction<number>>;
}

const ModalChangeTeacherV2: React.FC<Props> = ({
  roomInfo,
  isOpenModal,
  openModal,
  closeModal,
  setActiveTabMain,
  refetchRoomDetails,
  refetchAttendanceDetails
}) => {
  const today = moment();
  const {
    openModal: openModalAlertChangeTeacher,
    closeModal: closeModalAlertChangeTeacher,
    isOpenModal: isOpenModalAlertChangeTeacher
  } = useModal();

  const mainTeacher = roomInfo?.main_teacher?.id || null;

  const [reasonList, setReasonList] = useState<any>();
  const [changeTeacher, setChangeTeacher] = useState<any>();
  const [teacherList, setTeacherList] = useState<ITeacher[]>([]);

  const [teacherProfile, setTeacherProfile] = useState<any>();
  const [alertSelectFunctional, setAlertSelectFunctional] = useState(false);
  const [isLoadingChangeTeacher, setIsLoadingChangeTeacher] = useState(false);
  const [teacherName, setTeacherName] = useState('');

  const [isOpenSelectCambio, setIsOpenSelectCambio] = useState(false);
  const [isOpenSelectMotivo, setIsOpenSelectMotivo] = useState(false);
  const [isOpenSelectDuracion, setIsOpenSelectDuracion] = useState(false);
  const [isOpenSelectSesionFrom, setIsOpenSelectSesionFrom] = useState(false);
  const [isOpenSelectSesionUntil, setIsOpenSelectSesionUntil] = useState(false);
  const [isOpenSelectTeacher, setIsOpenSelectTeacher] = useState(false);

  const [selectOptionCambio, setSelectOptionCambio] = useState<any>('');
  const [selectOptionMotivo, setSelectOptionMotivo] = useState<any>('');
  const [selectOptionDuracion, setSelectOptionDuracion] = useState<any>('');
  const [selectOptionSesionFrom, setSelectOptionSesionFrom] = useState<any>({});
  const [selectOptionSesionUntil, setSelectOptionSesionUntil] = useState<any>(
    {}
  );
  const [selectOptionTeacher, setSelectOptionTeacher] = useState<any>('');

  const [objSesionFrom, setObjSesionFrom] = useState<any>([]);
  const [objSesionUntil, setObjSesionUntil] = useState<any>([]);

  const [reasonDetail, setReasonDetail] = useState<any>();

  const objDuracion = [
    { id: 1, name: 'Una sola sesión' },
    { id: 2, name: 'Varias sesiones' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getReasonList();
        setReasonList(data);
      } catch (e) {
        //Sentry?
        console.log(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async (reasonId: any) => {
      try {
        const data = await getReasonListDetail(reasonId);
        setReasonDetail(data);
      } catch (e) {
        //Sentry?
        console.log(e);
      }
    };

    if (selectOptionCambio?.id) {
      fetchData(selectOptionCambio.id);
    }
  }, [selectOptionCambio]);

  useEffect(() => {
    const fetchData = async (fields: {}) => {
      try {
        const data = await getTeacherList(fields);
        setTeacherList(data);
      } catch (e) {
        //Sentry?
        console.log(e);
      }
    };
    if (
      Object.keys(selectOptionSesionFrom).length > 0 ||
      Object.keys(selectOptionSesionUntil).length > 0
    ) {
      let fields = {};
      if (selectOptionCambio?.id === 2) {
        fields = {
          teacher_id: mainTeacher,
          session_start_id:
            roomInfo?.date_session[selectOptionSesionFrom?.id - 1]?.id,
          session_end_id:
            roomInfo?.date_session[roomInfo?.date_session.length - 1]?.id
        };
      } else {
        if (selectOptionDuracion?.id === 1) {
          fields = {
            teacher_id: mainTeacher,
            session_start_id:
              roomInfo?.date_session[selectOptionSesionFrom?.id - 1]?.id,
            session_end_id:
              roomInfo?.date_session[selectOptionSesionFrom?.id - 1]?.id
          };
        } else {
          if (
            Object.keys(selectOptionSesionFrom).length === 0 ||
            Object.keys(selectOptionSesionUntil).length === 0
          ) {
            return;
          }
          fields = {
            teacher_id: mainTeacher,
            session_start_id:
              roomInfo?.date_session[selectOptionSesionFrom?.id - 1]?.id,
            session_end_id:
              roomInfo?.date_session[selectOptionSesionUntil?.id - 1]?.id
          };
        }
      }
      fetchData(fields);
    }
  }, [selectOptionSesionFrom, selectOptionSesionUntil]);

  const optionSelectionCambio = async (option: any): Promise<void> => {
    handleCleanSelects();
    setSelectOptionCambio(option);
  };

  const optionSelectionMotivo = (option: any): void => {
    setSelectOptionMotivo(option);
    setSelectOptionSesionFrom({});
    setSelectOptionSesionUntil({});

    if (roomInfo.date_session.length > 0) {
      const all_valid_sessions = roomInfo.date_session
        .map((session: any, index: any) => {
          return {
            id: Number(session.session_number),
            name: String(session.session_number)
          };
        })
        .filter((session: any, index: any) => session !== undefined);

      setObjSesionFrom([...all_valid_sessions]);
      setObjSesionUntil([...all_valid_sessions]);
    }
  };
  const optionSelectionDuracion = (option: any): void => {
    setSelectOptionDuracion(option);
  };
  const optionSelectionSesionFrom = (option: any): void => {
    setSelectOptionSesionFrom(option);
  };
  const optionSelectionSesionUntil = (option: any): void => {
    setSelectOptionSesionUntil(option);
  };

  const optionSelectionTeacher = (option: any): void => {
    setSelectOptionTeacher(option);
    setTeacherName(option.full_name);
  };

  const handleCleanSelects = () => {
    setSelectOptionCambio({});
    setSelectOptionMotivo({});
    setSelectOptionDuracion({});
    setSelectOptionSesionFrom({});
    setSelectOptionSesionUntil({});
    setSelectOptionTeacher(null);
    setTeacherName('');
    setTeacherProfile('');
    setChangeTeacher('');
    setIsOpenSelectTeacher(false);
  };

  const handleChangeTeacher = async () => {
    setIsLoadingChangeTeacher(true);

    switch (Number(selectOptionCambio.id)) {
      case 1:
        if (selectOptionDuracion.id === 1) {
          const dataTemporal = {
            teacher_id: Number(selectOptionTeacher.id),
            category_id: 1,
            reason_id: Number(selectOptionCambio.id),
            reason_detail: String(selectOptionMotivo.name),
            session_start_id:
              roomInfo.date_session[Number(selectOptionSesionFrom.name) - 1].id
          };
          setChangeTeacher(dataTemporal);
          await postChangeTeacherTemporal(dataTemporal);
        } else {
          const dataTemporalRange = {
            teacher_id: Number(selectOptionTeacher.id),
            category_id: 1,
            reason_id: Number(selectOptionCambio.id),
            reason_detail: String(selectOptionMotivo.name),
            session_start_id:
              roomInfo.date_session[Number(selectOptionSesionFrom.name) - 1].id,
            session_end_id:
              roomInfo.date_session[Number(selectOptionSesionUntil.name) - 1].id
          };
          setChangeTeacher(dataTemporalRange);
          await postChangeTeacherTemporalRange(dataTemporalRange);
        }
        break;
      case 2:
        const dataStable = {
          teacher_id: Number(selectOptionTeacher.id),
          category_id: 1,
          reason_id: Number(selectOptionCambio.id),
          reason_detail: String(selectOptionMotivo.name),
          session_start_id:
            roomInfo.date_session[Number(selectOptionSesionFrom.name) - 1].id,
          session_end_id:
            roomInfo.date_session[Number(roomInfo.date_session.length) - 1].id
        };
        setChangeTeacher(dataStable);
        await postChangeTeacherStable(dataStable);
        break;
      default:
        return null;
    }

    if (!selectOptionTeacher?.id || !teacherName) {
      setAlertSelectFunctional(true);
    } else {
      setAlertSelectFunctional(false);
    }
    closeModal();
    openModalAlertChangeTeacher();
    refetchAttendanceDetails();
  };

  const handleConfirmModal = () => {
    handleCleanSelects();
    closeModalAlertChangeTeacher();
    setIsLoadingChangeTeacher(false);
    setActiveTabMain(1);

    if (selectOptionCambio !== 1) {
      refetchRoomDetails();
    }
  };

  const handleTeacherNameChange = (value: any) => {
    setSelectOptionTeacher(null);
    setTeacherProfile(null);
    setTeacherName(value);
  };

  return (
    <div>
      <Modal
        width="xxl"
        isOpen={isOpenModal}
        closeModal={closeModal}
        title="Cambiar profesor"
        className={`modal-change-teacher`}
        body={
          <div className="modal-change-teacher__content-body">
            <div className="modal-change-teacher__room-information">
              <Typography bold type="paragraph2" variation="mobile">
                Tipo de cambio
              </Typography>
              <Select
                className={
                  selectOptionCambio?.name
                    ? 'modal-change-teacher__select'
                    : 'modal-change-teacher__select-placeholder'
                }
                setIsOpen={setIsOpenSelectCambio}
                isOpen={isOpenSelectCambio}
                size="default"
                onclickDefaultValue={optionSelectionCambio}
                options={reasonList}
              >
                {selectOptionCambio?.name
                  ? selectOptionCambio?.name
                  : 'Elige el tipo de cambio de profesor'}
              </Select>
              <Typography
                bold
                type="paragraph2"
                variation="mobile"
                color={
                  !selectOptionCambio?.name ? colors.gris03 : colors.gris05
                }
              >
                Indica el motivo
              </Typography>
              <Select
                className={
                  !selectOptionCambio?.name
                    ? 'modal-change-teacher__select-disable'
                    : selectOptionMotivo?.name
                      ? 'modal-change-teacher__select'
                      : 'modal-change-teacher__select-placeholder'
                }
                setIsOpen={setIsOpenSelectMotivo}
                isOpen={isOpenSelectMotivo}
                size="default"
                onclickDefaultValue={optionSelectionMotivo}
                options={reasonDetail}
                disabled={!selectOptionCambio?.name}
              >
                {selectOptionMotivo.name
                  ? selectOptionMotivo?.name
                  : 'Elige una opción'}
              </Select>
              {selectOptionCambio?.id === 1 || !selectOptionCambio?.name ? (
                <>
                  <Typography
                    bold
                    type="paragraph2"
                    variation="mobile"
                    color={
                      !selectOptionMotivo?.name ? colors.gris03 : colors.gris05
                    }
                  >
                    Duración
                  </Typography>
                  <Select
                    className={
                      !selectOptionMotivo?.name || selectOptionCambio?.id === 2
                        ? 'modal-change-teacher__select-disable'
                        : selectOptionDuracion?.name
                          ? 'modal-change-teacher__select'
                          : 'modal-change-teacher__select-placeholder'
                    }
                    setIsOpen={setIsOpenSelectDuracion}
                    isOpen={isOpenSelectDuracion}
                    size="default"
                    onclickDefaultValue={optionSelectionDuracion}
                    options={objDuracion}
                    disabled={
                      !selectOptionMotivo?.name || selectOptionCambio?.id === 2
                    }
                  >
                    {selectOptionDuracion?.name
                      ? selectOptionDuracion?.name
                      : 'Elige por cuánto tiempo será el cambio'}
                  </Select>
                </>
              ) : (
                ''
              )}
              <Typography
                bold
                type="paragraph2"
                variation="mobile"
                color={
                  !selectOptionMotivo?.name ? colors.gris03 : colors.gris05
                }
              >
                {selectOptionDuracion?.id === 1
                  ? 'Número de sesión'
                  : 'Desde la sesión'}
              </Typography>
              <Select
                className={
                  !selectOptionMotivo?.name
                    ? 'modal-change-teacher__select-disable modal-change-teacher__select-number'
                    : selectOptionSesionFrom?.name
                      ? 'modal-change-teacher__select modal-change-teacher__select-number'
                      : 'modal-change-teacher__select-placeholder modal-change-teacher__select-number'
                }
                setIsOpen={setIsOpenSelectSesionFrom}
                isOpen={isOpenSelectSesionFrom}
                size="default"
                onclickDefaultValue={optionSelectionSesionFrom}
                options={objSesionFrom}
                identifierSort="id"
                disabled={!selectOptionMotivo?.name}
              >
                {selectOptionSesionFrom?.name
                  ? selectOptionSesionFrom?.name
                  : '-'}
              </Select>
              {selectOptionDuracion?.id === 2 && selectOptionDuracion?.name ? (
                <>
                  <Typography
                    bold
                    type="paragraph2"
                    variation="mobile"
                    color={
                      !selectOptionDuracion?.name
                        ? colors.gris03
                        : colors.gris05
                    }
                  >
                    Hasta la sesión
                  </Typography>

                  <Select
                    className={
                      !selectOptionCambio?.name ||
                      selectOptionCambio?.id === 2 ||
                      selectOptionDuracion?.id === 1
                        ? 'modal-change-teacher__select-disable modal-change-teacher__select-number'
                        : selectOptionSesionUntil?.name
                          ? 'modal-change-teacher__select modal-change-teacher__select-number'
                          : 'modal-change-teacher__select-placeholder modal-change-teacher__select-number'
                    }
                    setIsOpen={setIsOpenSelectSesionUntil}
                    isOpen={isOpenSelectSesionUntil}
                    size="default"
                    onclickDefaultValue={optionSelectionSesionUntil}
                    options={objSesionUntil}
                    identifierSort="id"
                    disabled={
                      !selectOptionCambio?.name ||
                      selectOptionCambio?.id === 2 ||
                      selectOptionDuracion?.id === 1
                    }
                  >
                    {selectOptionSesionUntil?.name
                      ? selectOptionSesionUntil?.name
                      : '-'}
                  </Select>
                </>
              ) : (
                ''
              )}
              <Typography
                bold
                type="paragraph2"
                variation="mobile"
                color={
                  !selectOptionSesionFrom?.name ? colors.gris03 : colors.gris05
                }
              >
                Profesor
              </Typography>
              <div className="modal-change-teacher__container-select">
                {teacherList.length === 0 ? (
                  <SelectFunctional
                    setIsOpen={setIsOpenSelectTeacher}
                    isOpen={isOpenSelectTeacher}
                    size="default"
                    onclickDefaultValue={optionSelectionTeacher}
                    identifierSort="full_name"
                    options={[]}
                    disabled={!selectOptionSesionFrom?.name}
                    alert={alertSelectFunctional}
                    setAlert={setAlertSelectFunctional}
                    teacherProfile={teacherProfile}
                    setTeacherProfile={setTeacherProfile}
                    teacherName={teacherName}
                    setTeacherName={handleTeacherNameChange}
                  />
                ) : (
                  <SelectFunctional
                    setIsOpen={setIsOpenSelectTeacher}
                    isOpen={isOpenSelectTeacher}
                    size="default"
                    onclickDefaultValue={optionSelectionTeacher}
                    identifierSort="full_name"
                    options={teacherList}
                    disabled={!selectOptionSesionFrom?.name}
                    alert={alertSelectFunctional}
                    setAlert={setAlertSelectFunctional}
                    teacherProfile={teacherProfile}
                    setTeacherProfile={setTeacherProfile}
                    teacherName={teacherName}
                    setTeacherName={handleTeacherNameChange}
                  />
                )}
              </div>
              <Typography
                bold
                type="paragraph2"
                variation="mobile"
                color={
                  !selectOptionTeacher?.id || !teacherName
                    ? colors.gris03
                    : colors.gris05
                }
              >
                Contactar
              </Typography>
              <section className="modal-change-teacher__section-contact">
                <ButtonLineal
                  className="modal-change-teacher__button-contact"
                  size="small"
                  external
                  underline={false}
                  disabled={
                    teacherProfile && teacherProfile.discord ? false : true
                  }
                  to={`https://discord.com/users/${teacherProfile?.discord}`}
                >
                  <section className="modal-change-teacher__container-discord">
                    <IconDiscord
                      fill={
                        teacherProfile && teacherProfile.discord
                          ? colors.discord
                          : colors.gris03
                      }
                    />
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold
                      color={
                        teacherProfile && teacherProfile.discord
                          ? colors.discord
                          : colors.gris03
                      }
                    >
                      Discord
                    </Typography>
                  </section>
                </ButtonLineal>
                <ButtonLineal
                  className="modal-change-teacher__button-contact"
                  size="small"
                  external
                  underline={false}
                  disabled={
                    teacherProfile && teacherProfile.phone_number ? false : true
                  }
                  to={`https://api.whatsapp.com/send/?phone=${teacherProfile?.phone_number}`}
                >
                  <section className="modal-change-teacher__container-whatsapp">
                    <IconWhatsapp
                      fill={
                        teacherProfile && teacherProfile.phone_number
                          ? colors.whatsapp
                          : colors.gris03
                      }
                    />
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold
                      color={
                        teacherProfile && teacherProfile.phone_number
                          ? colors.whatsapp
                          : colors.gris03
                      }
                    >
                      WhatsApp
                    </Typography>
                  </section>
                </ButtonLineal>
              </section>
            </div>

            <section className="modal-change-teacher__container-buttons">
              <ButtonOutline
                type="reset"
                size="default"
                //disabled={isLoadingChangeTeacher}
                onClick={() => {
                  handleCleanSelects();
                  closeModal();
                }}
              >
                Cancelar
              </ButtonOutline>
              <ButtonFilled
                type="submit"
                size="default"
                //disabled={isLoadingChangeTeacher}
                onClick={() => handleChangeTeacher()}
              >
                Cambiar
              </ButtonFilled>
            </section>
          </div>
        }
      />
      <ModalMessageAlert
        width="lg"
        iconAlert={<IconAlertSuccess />}
        title={
          <Typography bold type="paragraph1" variation="desktop">
            ¡Cambio exitoso!
          </Typography>
        }
        body={
          <div className="modal-change-teacher__modal-success-container">
            <div className="modal-change-teacher__modal-success">
              <Typography bold type="paragraph2" variation="mobile">
                Profesor
              </Typography>
              <Typography type="paragraph2" variation="mobile">
                {teacherName}
              </Typography>
              <Typography bold type="paragraph2" variation="mobile">
                Contactar a profesor
              </Typography>
              <section className="modal-change-teacher__section-contact">
                <ButtonLineal
                  className="modal-change-teacher__button-contact"
                  size="small"
                  external
                  underline={false}
                  disabled={
                    teacherProfile && teacherProfile.discord ? false : true
                  }
                  to={`https://discord.com/users/${teacherProfile?.discord}`}
                >
                  <section className="modal-change-teacher__container-discord">
                    <IconDiscord
                      fill={
                        teacherProfile && teacherProfile.discord
                          ? colors.discord
                          : colors.gris03
                      }
                    />
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold
                      color={
                        teacherProfile && teacherProfile.discord
                          ? colors.discord
                          : colors.gris03
                      }
                    >
                      Discord
                    </Typography>
                  </section>
                </ButtonLineal>
                <ButtonLineal
                  className="modal-change-teacher__button-contact"
                  size="small"
                  external
                  underline={false}
                  disabled={
                    teacherProfile && teacherProfile.phone_number ? false : true
                  }
                  to={`https://api.whatsapp.com/send/?phone=${teacherProfile?.phone_number}`}
                >
                  <section className="modal-change-teacher__container-whatsapp">
                    <IconWhatsapp
                      fill={
                        teacherProfile && teacherProfile.phone_number
                          ? colors.whatsapp
                          : colors.gris03
                      }
                    />
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold
                      color={
                        teacherProfile && teacherProfile.phone_number
                          ? colors.whatsapp
                          : colors.gris03
                      }
                    >
                      WhatsApp
                    </Typography>
                  </section>
                </ButtonLineal>
              </section>
            </div>
          </div>
        }
        message="Se agrego correctamente el nuevo profesor..."
        isOpenModal={isOpenModalAlertChangeTeacher}
        openModal={openModalAlertChangeTeacher}
        closeModal={closeModalAlertChangeTeacher}
        button1={
          <ButtonFilled size="default" onClick={() => handleConfirmModal()}>
            Terminar
          </ButtonFilled>
        }
      />
    </div>
  );
};

export default ModalChangeTeacherV2;
