import moment from 'moment';
import { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import './tableCTCB2BProjects.scss';
import Table from '../../../molecules/Table';
import TagCourses from '../../../molecules/TagCourses';
import useProjects from '../../../../hooks/useProjects';
import Link from '../../../../components/molecules/Link';
import ButtonLineal from '../../../molecules/ButtonLineal';
import Typography from '../../../atoms/Typography/Typography';
import ScreenLoader from '../../../ScreenLoader/ScreenLoader';
import IconArrowRight from '../../../atoms/icons/IconArrowRight';
import TagProjectsStatus from '../../../molecules/TagProjectsStatus';
import { FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS } from '../../../../constants/ctcb2b.constants';

const TableCTCB2BProjects = (
  shouldRefresh: any,
  generalFilter: string,
  setGeneralFilter: any
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const {
    size,
    projects,
    isLoading,
    totalCount,
    totalPages,
    hasFilters,
    currentPage,
    resetFilter,
    setCurrentPage,
    currentFilters,
    setCurrentFilters,
    setLocalCurrentFilters,
    localCurrentFilters,
    finalLocalCurrentFilters
  } = useProjects(shouldRefresh, generalFilter, setGeneralFilter);

  const handleClick = (projectId: string) => {
    params.set('projectId', projectId);
    navigate(`/dashboard/rooms?${params.toString()}`, { replace: true });
  };

  const customCellDates = ({ cell }: any) => (
    <>{cell.value ? moment(cell.value).format('DD-MM-YY') : '-'}</>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'ID del proyecto',
        accessor: 'id',
        widthColumn: 60,
        disableSortBy: true
      },
      {
        Header: (
          <span>
            Nombre del <br />
            proyecto
          </span>
        ),
        accessor: 'name',
        widthColumn: 180,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Nombre de organización',
        accessor: 'organization_name',
        disableSortBy: true,
        widthColumn: 150,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Inicio de clases',
        accessor: 'operative_start_date',
        widthColumn: 130,
        Cell: customCellDates
      },
      {
        Header: 'Fin de clases',
        accessor: 'operative_end_date',
        widthColumn: 120,
        Cell: customCellDates
      },
      {
        Header: 'Status operativo',
        accessor: 'status',
        widthColumn: 120,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <span>
            <TagProjectsStatus status={cell.value} />
          </span>
        )
      },
      {
        Header: (
          <span>
            Owner <br /> operativo
          </span>
        ),
        accessor: 'operative_owner',
        widthColumn: 145,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Owner académico',
        accessor: 'academic_owner',
        widthColumn: 145,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Estudiantes inscritos',
        accessor: 'students',
        disableFilters: true,
        widthColumn: 80,
        align: 'center'
      },
      {
        Header: 'Salones asociados',
        accessor: 'rooms',
        disableFilters: true,
        widthColumn: 80,
        align: 'center',
        Cell: ({ cell }: any) => {
          return (
            <ButtonLineal
              className="table-projects__button-room"
              onClick={() => handleClick(cell.row.values.id)}
            >
              {cell.value}
            </ButtonLineal>
          );
        }
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => {
          return (
            <span>
              {' '}
              <ButtonLineal
                size="small"
                className="table-projects__button_view_project"
                onClick={() => {
                  console.log('Ver proyecto');
                }}
              >
                <span className="table-projects__text-container">
                  <Link
                    className="table-projects__link"
                    to={`/dashboard/ctcb2b/${cell.row.values.id}/projects`}
                  >
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Ver proyecto
                    </Typography>

                    <IconArrowRight width="22" height="24" />
                  </Link>
                </span>
              </ButtonLineal>
            </span>
          );
        }
      }
    ],
    []
  );

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters,
    setLocalCurrentFilters: setLocalCurrentFilters,
    localCurrentFilters: localCurrentFilters,
    totalRows: totalCount,
    totalPages,
    pageSize: size
  };

  if (isLoading) return <ScreenLoader />;

  return (
    <>
      {hasFilters && (
        <section className="guardians_filter__container_courses">
          <p className="typography typography__paragraph2 typography--undefined typography__paragraph2--mobile typography__bold  teacher-profile__typography">
            Filtro Actual:{' '}
          </p>
          {Object.keys(finalLocalCurrentFilters).map((key: string, index) => (
            <TagCourses className="guardians_filter__tag" key={index}>
              <Typography type="paragraph2" variation="mobile" bold>
                {FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.get(key) +
                  ': ' +
                  finalLocalCurrentFilters[key]}
              </Typography>
              <ButtonLineal
                size="small"
                className="guardians_filter__button_removal"
                onClick={() => resetFilter(key)}
              >
                &#10005;
              </ButtonLineal>
            </TagCourses>
          ))}
        </section>
      )}
      <Table
        data={projects}
        columns={columns}
        paginationState={paginationState}
      />
    </>
  );
};

export default TableCTCB2BProjects;
