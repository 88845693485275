// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';
import {
  ICourse,
  ICourseEvaluationBox,
  ICourseLearningBox,
  IFullCourse
} from '../interfaces/course.interface';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? COURSES

export const getCourses = async () => {
  const response = await axios.get<ICourse[]>(
    `${instanceBaseURL}/backoffice/v2/courses?sync_with_lms=true`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// get information individual course by ID
export const getInfoByCourseId = async (idCourse: number) => {
  const response = await axios.get<IFullCourse>(
    `${instanceBaseURL}/courses_backoffice/${idCourse}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// Post project title and project description by course
export const postProjectInfoByCourse = async (
  name: string,
  description: string,
  courseId: number
) => {
  const response = await axios.post(
    `${instanceBaseURL}/course_final_project/`,
    { name: name, description: description, course: courseId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// patch project title and project description by course
export const patchProjectInfoByCourse = async (
  name: string,
  description: string,
  projectId: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/course_final_project/${projectId}/`,
    { name: name, description: description },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// post project benefits
export const postProjectBenefitsByCourse = async (
  benefit: string,
  courseId: number
) => {
  const response = await axios.post(
    `${instanceBaseURL}/course_final_project_benefits/`,
    { benefit: benefit, course: courseId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// patch project benefits
export const patchProjectBenefitsByCourse = async (
  benefit: string,
  courseId: number,
  benefitId: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/course_final_project_benefits/${benefitId}/`,
    { benefit: benefit, course: courseId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// delete project benefits
export const deleteProjectBenefitsByCourse = async (benefitId: number) => {
  const response = await axios.delete(
    `${instanceBaseURL}/course_final_project_benefits/${benefitId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postProjectImagesByCourse = async (data: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/course_final_project_sliders/`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchProjectImagesByCourse = async (
  data: any,
  sliderId: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/course_final_project_sliders/${sliderId}/`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const deleteProjectImagesByCourse = async (sliderId: number) => {
  const response = await axios.delete(
    `${instanceBaseURL}/course_final_project_sliders/${sliderId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// get information box learning course by ID
export const getLearningBoxByCourseId = async (idCourse: number) => {
  const response = await axios.get<IFullCourse>(
    `${instanceBaseURL}/course/box-sessions/${idCourse}`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// post information box learning course by ID
export const postLearningBoxByCourseId = async (data: any) => {
  const response = await axios.post<ICourseLearningBox>(
    `${instanceBaseURL}/course/box-sessions/`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// patch information box learning course by ID
export const patchLearningBoxByCourseId = async (
  data: any,
  idCourse: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/course/box-sessions/update/${idCourse}`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// get information box evaluation course by ID
export const getEvaluationBoxByCourseId = async (idCourse: number) => {
  const response = await axios.get<ICourseEvaluationBox>(
    `${instanceBaseURL}/backoffice/qualification/${idCourse}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// post information box evaluation course by ID
export const postEvaluationBoxByCourseId = async (
  idCourse: number,
  data: any
) => {
  const response = await axios.post<ICourseEvaluationBox>(
    `${instanceBaseURL}/backoffice/qualification/${idCourse}/`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// put information box evaluation course by ID
export const putLearningBoxByCourseId = async (idCourse: number, data: any) => {
  const response = await axios.put(
    `${instanceBaseURL}/backoffice/qualification/update/${idCourse}/`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
