import './groupsLayout.scss';

interface Props {
  buttonAdd: any;
  globalSearch?: any;
  table: any;
}

const GroupsLayout: React.FC<Props> = ({ buttonAdd, globalSearch, table }) => {
  return (
    <main className="groups-layout">
      <div className="groups-layout__button-add">{buttonAdd}</div>
      <div className="groups-layout__global-search">{globalSearch}</div>
      <div className="groups-layout__table">{table}</div>
    </main>
  );
};

export default GroupsLayout;
