const FILTERS_FIELD_NAMES_TRANSLATIONS = new Map<string, string>();
FILTERS_FIELD_NAMES_TRANSLATIONS.set('id', 'ID');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('name', 'Grupo');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('learning_path', 'Programa');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('course', 'Curso');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('b2b_project', 'Id proyecto B2B');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('published_at', 'Estado');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('business_type', 'Negocio');

export { FILTERS_FIELD_NAMES_TRANSLATIONS };
