// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? Discounts
export const getDiscounts = async () => {
  const response = await axios.get(`${instanceBaseURL}/discount-products/`, {headers: { Authorization: `Bearer ${getAccessCookie()}` }});
  return response;
};

export const postDiscounts = async (fields: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/discount-products/`,
    fields,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const deleteDiscounts = async (id: any) => {
  const response = await axios.delete(
    `${instanceBaseURL}/discount-products/${id}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchDiscounts = async (id: any, fields: any) => {
  const response = await axios.patch(
    `${instanceBaseURL}/discount/${id}/`,
    fields,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
