import TagStatus from '../../atoms/TagStatus/Tag';

import './tagProjectsStatus.scss';

type StatusType = 'En progreso' | 'Por empezar' | 'Completado';

interface Props {
  status: StatusType;
}

const TagProjectsStatus: React.FC<Props> = ({ status }) => {
  const getStatus: Record<string, StatusType> = {
    in_progress: 'En progreso',
    to_begin: 'Por empezar',
    completed: 'Completado'
  };

  const statusValue = getStatus[status];

  return (
    <TagStatus className={`tag-projects-status tag-projects-status__${status}`}>
      {statusValue}
    </TagStatus>
  );
};

export default TagProjectsStatus;
