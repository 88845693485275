// React
import { useState } from 'react';

// React Query
import { useMutation } from 'react-query';

// API

// Material UI
import {
  Alert,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextareaAutosize,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { DELETE, PATCH, POST } from '../../../services/api/api';

interface Props {
  faqs: Faq[];
  learningPathId: number;
  refetch: any;
  refetchLearningPath: any;
}

interface Faq {
  id?: number;
  question: string;
  answer: string;
  learning_path?: number;
}

const PathFaqs: React.FC<Props> = ({
  faqs,
  learningPathId,
  refetch,
  refetchLearningPath
}) => {
  const [faq, setFaq] = useState<Faq>({
    question: '',
    answer: ''
  });
  const [isError, setIsError] = useState<boolean>(false);

  const createLearningPathFaq = useMutation(
    (faq: Faq) => POST(`/learning_path_faq/`, faq),
    { retry: false }
  );

  const updateLearningPathFaq = useMutation(
    (faq: Faq) => PATCH(`/learning_path_faq/${faq.id}/`, faq),
    { retry: false }
  );

  const deleteCourseFaq = useMutation(
    (faq: Faq) => DELETE(`/learning_path_faq/${faq.id}/`),
    { retry: false }
  );

  const handleCreateFaq = async () => {
    if (faq.question.length > 0) {
      await createLearningPathFaq.mutateAsync({
        ...faq,
        learning_path: learningPathId
      });

      refetch();
      refetchLearningPath();
      setFaq({ question: '', answer: '' });
      setIsError(false);
    } else {
      setIsError(true);
    }
  };
  const handleEditFaq = async () => {
    await updateLearningPathFaq.mutateAsync(faq);

    refetch();
    refetchLearningPath();
    setFaq({ question: '', answer: '' });
  };
  const handleRemoveFaq = async (faq: Faq) => {
    await deleteCourseFaq.mutateAsync(faq);

    refetch();
    refetchLearningPath();
    setFaq({ question: '', answer: '' });
    setIsError(false);
  };

  return (
    <Grid container rowSpacing={1}>
      {/* Main */}
      <Grid item xs={12}>
        <List
          sx={{
            width: '100%',
            padding: 0,
            maxHeight: '200px',
            overflowY: 'scroll'
          }}
        >
          {faqs.map((faq: Faq, idx) => (
            <ListItem
              key={idx}
              secondaryAction={
                <Stack direction="row" columnGap={0}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => setFaq(faq)}
                    color="secondary"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    aria-label="remove"
                    onClick={() => handleRemoveFaq(faq)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              }
              dense
            >
              <ListItemText
                id={`checkbox-list-label-${faq.question}`}
                primary={faq.question}
                secondary={faq.answer}
              />
            </ListItem>
          ))}
        </List>
      </Grid>

      {isError && (
        <Grid item xs={12}>
          <Alert severity="error">Texto vacío...</Alert>
        </Grid>
      )}

      {/* Btn Add ToDo */}
      <Grid item xs={12}>
        <Stack direction="row" spacing={1.5} justifyContent="space-between">
          <Stack
            direction="column"
            spacing={1.5}
            sx={{ minWidth: '65%', maxWidth: '65%' }}
          >
            <TextField
              fullWidth
              id="question"
              label="Add question"
              placeholder="Add question"
              value={faq.question}
              onChange={e =>
                setFaq({
                  ...faq,
                  question: e.target.value
                })
              }
              variant="outlined"
              size="small"
              type="text"
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            />

            <TextareaAutosize
              id="answer"
              aria-label="minimum height"
              placeholder="Add answer"
              minRows={4}
              value={faq.answer}
              onChange={e =>
                setFaq({
                  ...faq,
                  answer: e.target.value
                })
              }
              style={{ width: '99%', minWidth: '99%', maxWidth: '99%' }}
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            />
          </Stack>

          <LoadingButton
            sx={{ width: '50%' }}
            variant="contained"
            onClick={
              faq.id && faq.id > 0
                ? () => handleEditFaq()
                : () => handleCreateFaq()
            }
            startIcon={<AddCircleIcon />}
            color={faq.id && faq.id > 0 ? 'secondary' : 'primary'}
          >
            {faq.id && faq.id > 0 ? 'Editar' : 'Agregar'}
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PathFaqs;
