// Redux
import { createSlice } from '@reduxjs/toolkit';

// Interface
import { IRoomDetail, IRoomDetailState } from '../../interfaces/room.interface';

const initialState: IRoomDetailState = { room: null };

export const roomDetailSlice = createSlice({
  name: 'roomDetail',
  initialState,
  reducers: {
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setStudentsRecords: (state, action) => {
      const room = { ...state.room } as IRoomDetail;
      room.students_records = action.payload;
      state.room = room;
    },
    setTeacherRecords: (state, action) => {
      const room = { ...state.room } as IRoomDetail;
      room.teacher_records = action.payload;
      state.room = room;
    },
    updateStudent: (state, action) => {
      const room = { ...state.room } as IRoomDetail;
      const studentIndex: number = room
        ? room?.students_records.findIndex(
            currentStudent => currentStudent.student.id === action.payload.id
          )
        : -1;

      if (studentIndex >= 0) {
        const updatedStudent = {
          ...room.students_records[studentIndex].student,
          ...action.payload
        };
        room.students_records[studentIndex].student = updatedStudent;
        state.room = room;
      }
    },
    addComment: (state, action) => {
      const room = { ...state.room } as IRoomDetail;
      const studentIndex: number = room
        ? room?.students_records.findIndex(
            record => record.student.id === action.payload.to_student
          )
        : -1;

      if (studentIndex >= 0) {
        room.students_records[studentIndex].comments.unshift(action.payload);
        state.room = room;
      }
    },
    addCommentBySession: (state, action) => {
      const room = { ...state.room } as IRoomDetail;
      const studentIndex: number = room
        ? room?.date_session.findIndex(
            record => record.session_number === action.payload.session_number
          )
        : -1;

      if (studentIndex >= 0) {
        room.date_session[studentIndex].comments.unshift(action.payload);
        state.room = room;
      }
    }
  }
});

export const {
  setRoom,
  setStudentsRecords,
  setTeacherRecords,
  updateStudent,
  addComment,
  addCommentBySession
} = roomDetailSlice.actions;
