import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getKnowledgesFromApi } from '../slices/teacherSlice/teacherSlice';

const useGetKnowledges = ({ shouldRefetch }: { shouldRefetch?: boolean }) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const getKnowledges = async () => {
    try {
      return await dispatch(getKnowledgesFromApi({}));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getKnowledges();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching Knowledges:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  return { data, isLoading };
};

export default useGetKnowledges;
