import { styled } from '@material-ui/core/styles';

export const LoginWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  height: '100vh',
  gridTemplateColumns: '60% 1fr',
  [theme.breakpoints.down(768)]: { gridTemplateColumns: '1fr' }
}));

export const ImgSection = styled('div')(({ theme }) => ({
  background:
    'url(https://ctc-web-statics-prod.s3.amazonaws.com/multimedia/images/courses/e5beb8b50ff44b23a052fdc8fc43df0d.webp) #1565C0',
  backgroundPosition: '0% center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  [theme.breakpoints.down(768)]: { display: 'none' }
}));

export const ContenSection = styled('div')(({ theme }) => ({
  maxWidth: '1200px',
  background: '#F8F9FB',
  padding: '2rem',
  [theme.breakpoints.down(768)]: {
    background:
      'linear-gradient(0deg, #04FFE1 0.01%, #02AED9 52.68%, #0076D4 117.36%)'
  }
}));
