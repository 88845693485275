import axios, { AxiosResponse } from 'axios';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

export const signInService = async <T>(
  email: string,
  password: string,
  platform: string
): Promise<AxiosResponse<T>> => {
  const response: AxiosResponse = await axios.post<T>(
    `${instanceBaseURL}/token/`,
    {
      username: email,
      password: password,
      platform: platform
    }
  );
  return response;
};

export const refreshTokenService = async <T>(
  refresh: string
): Promise<AxiosResponse<T>> => {
  const response: AxiosResponse = await axios.post<T>(
    `${instanceBaseURL}/token/refresh/`,
    { refresh }
  );
  return response;
};
