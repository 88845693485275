/* eslint-disable complexity */
import React from 'react';

import Input from '../../../../components/molecules/Input';
import Select from '../../../../components/molecules/Select/Select';
import Typography from '../../../../components/atoms/Typography/Typography';

export const StudentCTCInfo: React.FC<{
  isDetail?: boolean;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  isOpenSelectOS: any;
  setIsOpenSelectOS: any;
  selectOptionOS: any;
  setSelectOptionOS: any;
  setFieldValue: any;
  optionSelectionOS: any;
  operativeSystemOptions: any;
  isOpenSelectTechExperience: any;
  setIsOpenSelectTechExperience: any;
  selectOptionTechExperience: any;
  setSelectOptionTechExperience: any;
  deviceExperienceOptions: any;
  optionSelectionTechExperience: any;
  noSyncAlert?: boolean;
  isCreatePath?: boolean;
}> = ({
  isDetail,
  values,
  errors,
  touched,
  handleChange,
  isOpenSelectOS,
  setIsOpenSelectOS,
  selectOptionOS,
  setSelectOptionOS,
  setFieldValue,
  optionSelectionOS,
  operativeSystemOptions,
  isOpenSelectTechExperience,
  setIsOpenSelectTechExperience,
  selectOptionTechExperience,
  setSelectOptionTechExperience,
  deviceExperienceOptions,
  optionSelectionTechExperience,
  noSyncAlert,
  isCreatePath
}) => {
  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Información CTC
      </Typography>
      <br />
      <br />
      <div className="detail-edit-students__content-body">
        <div className="detail-edit-students__student-information">
          {!isCreatePath && (
            <>
              <Typography bold type="paragraph4" variation="mobile">
                ID Moodle
              </Typography>
              {isDetail ? (
                <Typography type="paragraph4" variation="mobile">
                  {noSyncAlert ? '-' : values?.user_mdl_id || '-'}
                </Typography>
              ) : (
                <Input
                  disabled={true}
                  value={values?.user_mdl_id}
                  onChange={handleChange}
                  placeholder="Ingresa ID Moodle"
                  name="user_mdl_id"
                  required
                  error={
                    errors?.user_mdl_id && touched?.user_mdl_id
                      ? errors?.user_mdl_id
                      : ''
                  }
                />
              )}
            </>
          )}
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Experiencia tecnológica
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.device_experience === 'notexperience'
                  ? 'Ninguna'
                  : values?.device_experience === 'basic'
                    ? 'Básica'
                    : values?.device_experience === 'intermediate'
                      ? 'Intermedia'
                      : values?.device_experience === 'advance'
                        ? 'Avanzada'
                        : 'Sin información'}
              </Typography>
            ) : (
              <>
                <Select
                  useSortBy={true}
                  sortBy="order"
                  setIsOpen={setIsOpenSelectTechExperience}
                  isOpen={isOpenSelectTechExperience}
                  size="default"
                  dividerItems
                  onclickDefaultValue={(event: any) => {
                    optionSelectionTechExperience(event);
                    setFieldValue('device_experience', event?.id);
                  }}
                  options={deviceExperienceOptions}
                  children={
                    values?.device_experience
                      ? values?.device_experience === 'notexperience'
                        ? 'Ninguna'
                        : values?.device_experience === 'basic'
                          ? 'Básica'
                          : values?.device_experience === 'intermediate'
                            ? 'Intermedia'
                            : values?.device_experience === 'advance'
                              ? 'Avanzada'
                              : 'Sin información'
                      : selectOptionTechExperience?.name
                        ? selectOptionTechExperience?.name
                        : 'Sin información'
                  }
                />
              </>
            )}
          </>
        </div>
        <div className="detail-edit-students__student-information">
          {!isCreatePath && (
            <>
              <Typography bold type="paragraph4" variation="mobile">
                Fecha de creación
              </Typography>
              {isDetail ? (
                <Typography type="paragraph4" variation="mobile">
                  {values?.created_at_student || '-'}
                </Typography>
              ) : (
                <Input
                  disabled={true}
                  value={values?.created_at_student}
                  onChange={handleChange}
                  placeholder="Fecha de creación"
                  name="created_at_student"
                  required
                  error={
                    errors?.created_at_student && touched?.created_at_student
                      ? errors?.created_at_student
                      : ''
                  }
                />
              )}
            </>
          )}
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Sistema operativo
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.operative_system === 'none'
                  ? 'Ninguno'
                  : values?.operative_system === 'windows'
                    ? 'Windows'
                    : values?.operative_system === 'macos'
                      ? 'macOS'
                      : values?.operative_system === 'linux'
                        ? 'Linux'
                        : values?.operative_system === 'other'
                          ? 'Otro'
                          : 'Sin información'}
              </Typography>
            ) : (
              <>
                <Select
                  useSortBy={true}
                  sortBy="order"
                  setIsOpen={setIsOpenSelectOS}
                  isOpen={isOpenSelectOS}
                  size="default"
                  dividerItems
                  onclickDefaultValue={(event: any) => {
                    optionSelectionOS(event);
                    setFieldValue('operative_system', event?.id);
                  }}
                  options={operativeSystemOptions}
                  children={
                    values?.operative_system
                      ? values?.operative_system === 'none'
                        ? 'Ninguno'
                        : values?.operative_system === 'windows'
                          ? 'Windows'
                          : values?.operative_system === 'macos'
                            ? 'macOS'
                            : values?.operative_system === 'linux'
                              ? 'Linux'
                              : values?.operative_system === 'other'
                                ? 'Otro'
                                : 'Sin información'
                      : selectOptionOS?.name
                        ? selectOptionOS?.name
                        : 'Sin información'
                  }
                />
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};
