import * as Yup from 'yup';

export const CreateRoomSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  topic: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  teacher: Yup.number()
});

export const CreateRoomWithManualLinkZoom = Yup.object().shape({
  name: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  topic: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  teacher: Yup.number(),

  zoom_join_url: Yup.string()
    .url('URL invalido!')
    .min(10, 'Muy corto!')
    .required('Requerido'),
  zoom_license: Yup.string()
    .email('Email invalido!')
    .min(2, 'Muy corto!')
    .required('Requerido'),
  zoom_password: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  meet_id: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  meet_password: Yup.string().min(2, 'Muy corto!').required('Requerido')
});
