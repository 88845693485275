// React Query
import { useMutation, useQuery } from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Form, Formik } from 'formik';

// API

// Hot Toast
import { toast } from 'react-hot-toast';

// Interfaces

// Utils

// Material UI
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  Grid,
  LinearProgress,
  TextareaAutosize,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { ILearningPaths } from '../../../../../interfaces/learningPaths.interface';
import {
  getErrorMessage,
  sortAlphabetically
} from '../../../../../utils/utils';
import { IFullCourse } from '../../../../../interfaces/course.interface';
import { GET, POST } from '../../../../../services/api/api';

const GeneralDataSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, 'Muy pequeño!')
    .max(500, 'Mucho texto!')
    .required('Requerido'),

  youtube_video_id: Yup.string(),
  call_out: Yup.string(),
  title: Yup.string().min(5, 'Muy pequeño!').max(500, 'Mucho texto!'),
  description: Yup.string().min(5, 'Muy pequeño!').max(500, 'Mucho texto!'),
  course_detail: Yup.string().min(5, 'Muy pequeño!'),
  seo_title: Yup.string(),
  seo_meta_description: Yup.string()
    .min(5, 'Muy pequeño!')
    .max(255, 'Mucho texto!'),

  published_at: Yup.date().nullable(true),
  archived_at: Yup.date()
    .nullable(true)
    .min(Yup.ref('published_at'), 'ARCHIVADO NO puede ser antes que PUBLICADO'),

  learning_paths: Yup.number()
});

interface Props {
  refetch: () => {};
  courseId: number;
  setCourseId: any;
  isGeneralDataSent: boolean;
  setIsGeneralDataSent: any;
}

const GeneralData: React.FC<Props> = ({
  refetch,
  courseId,
  setCourseId,
  isGeneralDataSent,
  setIsGeneralDataSent
}) => {
  const { data: course, remove } = useQuery(
    ['course-general-data', courseId],
    () => GET<IFullCourse>(`/courses_backoffice/${courseId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!courseId && isGeneralDataSent
    }
  );

  const { data: learningPaths, isLoading: isLoadingLearningPaths } = useQuery(
    ['learning-paths'],
    () => GET<ILearningPaths>(`/learning-paths/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const createCourseGeneralData = useMutation(
    (course: any) => POST(`/courses_backoffice/`, course),
    { retry: false }
  );

  const coursePrices = ['PEN', 'USD', 'MXN', 'COP', 'ARS', 'CLP', 'CRC', 'UYU'];

  const createCoursePrices = useMutation(
    (course: any) => POST(`/price/course/`, course),
    { retry: false }
  );

  if (!!courseId && isGeneralDataSent && !course) return <LinearProgress />;
  if (isLoadingLearningPaths && !learningPaths) return <LinearProgress />;

  let initialValues: any = {
    name: '',
    slug: '',

    youtube_video_id: '',
    call_out: '',

    title: '',
    description: '',
    course_detail: '',
    /* project_summary: "", */

    seo_title: '',
    seo_meta_description: '',

    published_at: null,
    archived_at: null,

    learning_paths: -1
  };

  if (isGeneralDataSent && course) {
    initialValues = {
      name: course.name,
      slug: course.slug,

      youtube_video_id: course.youtube_video_id,
      call_out: course.call_out,

      description: course.description,
      title: course.title,
      course_detail: course.course_detail,
      final_project_description: course.final_project_description,
      /* project_summary: course.project_summary, */

      seo_title: course.seo_title,
      seo_meta_description: course.seo_meta_description,

      published_at: course.published_at,
      archived_at: course.archived_at,

      learning_paths: course.learning_paths[0]
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={GeneralDataSchema}
      onSubmit={async values => {
        const data = {
          ...values,
          learning_paths:
            values.learning_paths > -1 ? [values.learning_paths] : []
        };

        await toast.promise(
          createCourseGeneralData.mutateAsync(data).then(res => {
            setIsGeneralDataSent(true);
            setCourseId(res.data.id);
            const coursePrice = coursePrices.map(currency => ({
              currency,
              amount: '00.00',
              has_discount: false,
              discount_percentage: null,
              amount_after_discount: null,
              available_to_purchase: null,
              course_id: res.data.id
            }));

            createCoursePrices
              .mutateAsync(coursePrice)
              .then(res => {
                console.log('******* createCoursePrices res', res);
              })
              .catch(error => {
                console.error('******* createCoursePrices error', error);
              });
          }),
          {
            loading: 'Cargando...',
            success: 'Datos Generales creado',
            error: 'Error'
          },
          { success: { icon: <AddCircleIcon color="primary" /> } }
        );

        remove();
        refetch();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              {/* Name */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="name"
                  label="Nombre de curso"
                  placeholder="Nombre del curso"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{getErrorMessage(errors.name)}</Alert>
                )}
              </Grid>

              {/* Slug */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="slug"
                  label="Slug"
                  placeholder="Slug"
                  variant="outlined"
                  value={values.slug}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.slug && touched.slug && (
                  <Alert severity="error">{getErrorMessage(errors.slug)}</Alert>
                )}
              </Grid>

              {/* Youtube_video_id */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="youtube_video_id"
                  label="Youtube Video Id"
                  placeholder="Youtube Video Id"
                  variant="outlined"
                  value={values.youtube_video_id}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.youtube_video_id && touched.youtube_video_id && (
                  <Alert severity="error">
                    {getErrorMessage(errors.youtube_video_id)}
                  </Alert>
                )}
              </Grid>

              {/* Call_out */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="call_out"
                  label="Call Out"
                  placeholder="Call Out"
                  variant="outlined"
                  value={values.call_out}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.call_out && touched.call_out && (
                  <Alert severity="error">
                    {getErrorMessage(errors.call_out)}
                  </Alert>
                )}
              </Grid>

              {/* Title */}
              <Grid item xs={12}>
                <TextareaAutosize
                  id="title"
                  aria-label="minimum height"
                  placeholder="Descripción - Título"
                  minRows={5}
                  value={values.title}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                  style={{ width: '99%', minWidth: '99%', maxWidth: '99%' }}
                />
                {errors.title && touched.title && (
                  <Alert severity="error">
                    {getErrorMessage(errors.title)}
                  </Alert>
                )}
              </Grid>
              {/* Description */}
              <Grid item xs={12}>
                <TextareaAutosize
                  id="description"
                  aria-label="minimum height"
                  placeholder="Descripción - Párrafo"
                  minRows={10}
                  value={values.description}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                  style={{ width: '99%', minWidth: '99%', maxWidth: '99%' }}
                />
                {errors.description && touched.description && (
                  <Alert severity="error">
                    {getErrorMessage(errors.description)}
                  </Alert>
                )}
              </Grid>

              {/* course_detail */}
              <Grid item xs={12}>
                <TextareaAutosize
                  id="course_detail"
                  aria-label="minimum height"
                  placeholder="Detalle Curso"
                  minRows={5}
                  value={values.course_detail}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                  style={{ width: '99%', minWidth: '99%', maxWidth: '99%' }}
                />
                {errors.course_detail && touched.course_detail && (
                  <Alert severity="error">
                    {getErrorMessage(errors.course_detail)}
                  </Alert>
                )}
              </Grid>

              {/* Final_project_description */}
              <Grid item xs={12}>
                <TextareaAutosize
                  id="final_project_description"
                  aria-label="minimum height"
                  placeholder="Proyecto Final - Párrafo"
                  minRows={5}
                  value={values.final_project_description}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                  style={{ width: '99%', minWidth: '99%', maxWidth: '99%' }}
                />
                {errors.final_project_description &&
                  touched.final_project_description && (
                    <Alert severity="error">
                      {getErrorMessage(errors.final_project_description)}
                    </Alert>
                  )}
              </Grid>

              {/* Project_summary */}
              {/* <Grid item xs={12}>
                <TextareaAutosize
                  id="project_summary"
                  aria-label="minimum height"
                  placeholder="Resumen del proyecto"
                  minRows={5}
                  value={values.project_summary}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                  style={{ width: "99%", minWidth: "99%", maxWidth: "99%" }}
                />
                {errors.project_summary && touched.project_summary && (
                  <Alert severity="error">{errors.project_summary}</Alert>
                )}
              </Grid> */}

              {/* Seo_title */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="seo_title"
                  label="Seo Title"
                  placeholder="Seo Title"
                  variant="outlined"
                  value={values.seo_title}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.seo_title && touched.seo_title && (
                  <Alert severity="error">
                    {getErrorMessage(errors.seo_title)}
                  </Alert>
                )}
              </Grid>

              {/* Seo_meta_description */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="seo_meta_description"
                  label="Seo Meta Description"
                  placeholder="Seo Meta Description"
                  variant="outlined"
                  value={values.seo_meta_description}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.seo_meta_description &&
                  touched.seo_meta_description && (
                    <Alert severity="error">
                      {getErrorMessage(errors.seo_meta_description)}
                    </Alert>
                  )}
              </Grid>

              {/* published_at | archived_at */}
              <Grid item container columnSpacing={2} rowSpacing={1} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {/* published_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Publicado"
                      format="yyyy-MM-dd"
                      value={values.published_at}
                      ampm={false}
                      onChange={(value: any) =>
                        setFieldValue('published_at', value)
                      }
                      slots={{
                        textField: (params: any) => (
                          <TextField fullWidth {...params} />
                        )
                      }}
                      disabled={isGeneralDataSent}
                    />
                    {errors.published_at && touched.published_at && (
                      <Alert severity="error">
                        {getErrorMessage(errors.published_at)}
                      </Alert>
                    )}
                  </Grid>

                  {/* archived_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Archivado"
                      format="yyyy-MM-dd"
                      value={values.archived_at}
                      minDate={values.published_at || new Date()}
                      ampm={false}
                      onChange={(value: any) =>
                        setFieldValue('archived_at', value)
                      }
                      slots={{
                        textField: (params: any) => (
                          <TextField fullWidth {...params} />
                        )
                      }}
                      disabled={isGeneralDataSent}
                    />
                    {errors.archived_at && touched.archived_at && (
                      <Alert severity="error">
                        {getErrorMessage(errors.archived_at)}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {values.published_at ? (
                      <Alert variant="standard" severity="success">
                        El grupo esta publicado
                      </Alert>
                    ) : (
                      <Alert variant="standard" severity="warning">
                        El grupo esta privado
                      </Alert>
                    )}
                  </Grid>
                </LocalizationProvider>
              </Grid>

              {/* Learning_paths */}
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="learning_paths"
                  options={sortAlphabetically(learningPaths!.all, 'name')}
                  autoHighlight
                  value={
                    learningPaths!.all.find(
                      learningPath => learningPath.id === values.learning_paths
                    ) || null
                  }
                  onChange={(_e, value: any) => {
                    value
                      ? setFieldValue('learning_paths', value.id)
                      : setFieldValue('learning_paths', -1);
                  }}
                  getOptionLabel={option => `${option.name} (${option.id})`}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name} ({option.id})
                    </Box>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Learning Paths"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                  disabled={isGeneralDataSent}
                />
                {errors.learning_paths && touched.learning_paths && (
                  <Alert severity="error">
                    {getErrorMessage(errors.learning_paths)}
                  </Alert>
                )}
              </Grid>
            </Grid>

            {/* Save */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={createCourseGeneralData.isLoading}
                  variant="contained"
                  type="submit"
                  disabled={isGeneralDataSent}
                  startIcon={<AddCircleIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default GeneralData;
