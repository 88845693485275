import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getListOverdueContentReports } from '../../../services/b2b/overdueContent';
import { OverdueContentReportState } from '../../../interfaces/overdueContentReports.interface';

export const getAllOverdueContentReports = createAsyncThunk(
  'overdueContentReports/getAllOverdueContentReports',
  async (
    reportInfo: { page: number; size: number; status: any; filters?: any },
    { rejectWithValue }: any
  ) => {
    const {
 page, size, status, filters 
} = reportInfo;
    try {
      const data = await getListOverdueContentReports(
        page,
        size,
        status,
        filters
      );
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: OverdueContentReportState = {
  overdueContentReports: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const overdueContentReportsSlice = createSlice({
  name: 'overdueContentReports',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllOverdueContentReports.pending, (state, _) => {
      state.overdueContentReports = initialState.overdueContentReports;
      state.isLoading = true;
    });
    builder.addCase(getAllOverdueContentReports.fulfilled, (state, action) => {
      state.totalPages = action.payload.data.total_pages;
      state.totalCount = action.payload.data.count;
      state.overdueContentReports = action.payload.data.results;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getAllOverdueContentReports.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    }); /*  */
  }
});

export const selectStateOverdueContentReports = (state: any) =>
  state.overdueContentReports;
