// React Query
import { useMutation, useQuery } from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Form, Formik } from 'formik';

// API

// Interfaces

// Hot Toast
import { toast } from 'react-hot-toast';

// Material UI
import {
  Alert,
  CardMedia,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UpdateIcon from '@mui/icons-material/Update';

import { ILearningPath } from '../../../../../interfaces/learningPaths.interface';
import { GET, PATCHFILES } from '../../../../../services/api/api';
import { getErrorMessage } from '../../../../../utils/utils';

const MultimediaSchema = Yup.object().shape({
  thumbnail: Yup.mixed(),
  cover: Yup.mixed(),

  curriculum: Yup.mixed()
});

interface Props {
  refetch: () => {};
  learningPathId: number;
}

const Multimedia: React.FC<Props> = ({ refetch, learningPathId }) => {
  const {
    data: learningPath,
    error: errorLearningPath,
    isError: isErrorLearningPath,
    isLoading: isLoadingLearningPath,
    remove
  } = useQuery(
    ['learningPath-multimedia', learningPathId],
    () => GET<ILearningPath>(`/learning_paths_backoffice/${learningPathId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const editCourseMultimedia = useMutation(
    (courseMultimedia: any) =>
      PATCHFILES(
        `/learning_paths_backoffice/media/${learningPathId}/`,
        courseMultimedia
      ),
    { retry: false }
  );

  if (isLoadingLearningPath || !learningPath) return <LinearProgress />;
  if (isErrorLearningPath)
    return <div>Error! {JSON.stringify(errorLearningPath)}</div>;

  const initialValues = {
    thumbnail: learningPath.thumbnail,
    cover: learningPath.cover,

    curriculum: learningPath.curriculum
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MultimediaSchema}
      onSubmit={async values => {
        const bodyFormData = new FormData();
        bodyFormData.append('thumbnail', values.thumbnail);
        bodyFormData.append('cover', values.cover);
        bodyFormData.append('curriculum', values.curriculum);

        await toast.promise(
          editCourseMultimedia.mutateAsync(bodyFormData),
          {
            loading: 'Cargando...',
            success: 'Multimedia editado',
            error: 'Error'
          },
          { success: { icon: <UpdateIcon color="primary" /> } }
        );

        remove();
        refetch();
      }}
    >
      {({
 errors, touched, values, setFieldValue 
}) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={10} rowSpacing={2} xs={12}>
              {/* Thumbnail */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Thumbnail"
                    variant="outlined"
                    color={values.thumbnail ? 'primary' : 'default'}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="thumbnail"
                      name="thumbnail"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue('thumbnail', e.target.files[0]);
                      }}
                    />
                    {values.thumbnail && (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={
                          typeof values.thumbnail === 'object'
                            ? URL.createObjectURL(values.thumbnail)
                            : values.thumbnail
                        }
                        sx={{
                          objectFit: 'contain',
                          borderRadius: '8px'
                        }}
                      />
                    )}
                    {errors.thumbnail && touched.thumbnail && (
                      <Alert severity="error">
                        {getErrorMessage(errors.thumbnail)}
                      </Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Cover */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Cover"
                    variant="outlined"
                    color={values.cover ? 'primary' : 'default'}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="cover"
                      name="cover"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue('cover', e.target.files[0]);
                      }}
                    />
                    {values.cover && (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={
                          typeof values.cover === 'object'
                            ? URL.createObjectURL(values.cover)
                            : values.cover
                        }
                        sx={{ objectFit: 'contain', borderRadius: '8px' }}
                      />
                    )}
                    {errors.cover && touched.cover && (
                      <Alert severity="error">
                        {getErrorMessage(errors.cover)}
                      </Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Curriculum */}
              <Grid item xs={12}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Curriculum"
                    variant="outlined"
                    color={values.curriculum ? 'primary' : 'default'}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="curriculum"
                      name="curriculum"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue('curriculum', e.target.files[0]);
                      }}
                    />
                    {values.curriculum && (
                      <object
                        data={
                          typeof values.curriculum === 'object'
                            ? URL.createObjectURL(values.curriculum)
                            : values.curriculum
                        }
                        type="application/pdf"
                        width="100%"
                        height="200px"
                        style={{ borderRadius: '8px' }}
                      >
                        <p>Alternative text - include a link</p>
                      </object>
                    )}
                    {errors.curriculum && touched.curriculum && (
                      <Alert severity="error">
                        {getErrorMessage(errors.curriculum)}
                      </Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            {/* Edit */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={editCourseMultimedia.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Multimedia;
