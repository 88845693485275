import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getInactiveStudentReasonsFromApi } from '../slices/studentSlice/studentSlice';

const useInactiveStudentReasons = ({shouldRefetch}: {
  shouldRefetch?: boolean;
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    try {
      return await dispatch(getInactiveStudentReasonsFromApi({}));
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  return { data, isLoading, isError };
};

export default useInactiveStudentReasons;
