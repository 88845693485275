import './noAccess.scss';
import ButtonLineal from '../../molecules/ButtonLineal';
import Typography from '../../atoms/Typography/Typography';
import IconAlertCircleOutlined from '../../atoms/icons/IconAlertCircleOutlined';

interface Props {
  button: any;
  className?: string;
  errorMessage?: string;
}

const NoAccess: React.FC<Props> = ({
  className,
  button: { to, message },
  errorMessage = 'No tienes acceso a esta sección'
}) => {
  return (
    <div className={`no-access ${className}`}>
      <IconAlertCircleOutlined />
      <Typography type="paragraph3" variation="desktop">
        {errorMessage}
      </Typography>
      <ButtonLineal to={to}>
        <Typography bold type="paragraph3" variation="desktop">
          {message}
        </Typography>
      </ButtonLineal>
    </div>
  );
};

export default NoAccess;
