import React from 'react';

import colors from '../Colors';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
}

const IconAlertSuccessNew: React.FC<Props> = ({
  className,
  fill = colors.colorAlertSuccess,
  dataTestid = 'icon-alert-success'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_5889)">
        <path
          d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24V24Z"
          fill={fill}
        />
        <path
          d="M16.455 7.45493C16.4443 7.46529 16.4343 7.47631 16.425 7.48793L11.2155 14.1254L8.07602 10.9844C7.86276 10.7857 7.58069 10.6775 7.28924 10.6827C6.99778 10.6878 6.71971 10.8059 6.51359 11.012C6.30747 11.2181 6.1894 11.4962 6.18426 11.7876C6.17911 12.0791 6.2873 12.3612 6.48602 12.5744L10.455 16.5449C10.5619 16.6517 10.6893 16.7358 10.8294 16.7922C10.9695 16.8487 11.1196 16.8763 11.2706 16.8735C11.4217 16.8707 11.5706 16.8375 11.7086 16.7759C11.8465 16.7143 11.9706 16.6255 12.0735 16.5149L18.0615 9.02993C18.2654 8.81593 18.3769 8.53028 18.3719 8.23473C18.3668 7.93919 18.2457 7.6575 18.0346 7.45057C17.8235 7.24364 17.5395 7.1281 17.2439 7.12891C16.9483 7.12973 16.6649 7.24684 16.455 7.45493V7.45493Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_12_5889">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAlertSuccessNew;
