import React from 'react';

import './textarea.scss';

interface Props {
  value?: any;
  defaultValue?: any;
  onChange?: any;
  onBlur?: any;
  placeholder?: any;
  label?: string;
  className?: string;
  size?: any;
  disabled?: any;
  hideOutline?: boolean;
  name?: string;
}

const Textarea: React.FC<Props> = ({
  value,
  defaultValue,
  hideOutline,
  size = 'default',
  className,
  onChange,
  onBlur,
  disabled,
  placeholder,
  label,
  name
}) => {
  const addHideOutline = hideOutline ? 'hide-outline' : '';

  return (
    <div className={`textarea ${className ? className : ''} `}>
      <label className="textarea__label">{label}</label>
      <textarea
        className={`textarea__input ${
          disabled ? 'textarea__input--disabled' : ''
        } ${addHideOutline}`}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        name={name}
      />
    </div>
  );
};

export default Textarea;
