import React from 'react';

import colors from '../Colors';

import './tooltip.scss';

interface Props {
  children: any;
  title: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  color?: string;
  bg?: string;
  testid?: string;
  className?: string;
}

const Tooltip: React.FC<Props> = ({
  children,
  title,
  left,
  right,
  bottom,
  testid,
  top = '-2rem',
  color = colors.gris01,
  bg = colors.black,
  className = ''
}) => {
  return (
    <div className={`tooltip ${className}`} data-testid={testid}>
      <div
        className="tooltip__title"
        style={{
          left: left,
          top: top,
          bottom: bottom,
          right: right,
          color: color,
          background: bg
        }}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

export default Tooltip;
