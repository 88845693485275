// Material UI
import { LinearProgress } from '@mui/material';

// Components
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import {
  getInfoByCourseId,
  getLearningBoxByCourseId
} from '../../../../../services/courses';
import CourseLearningDetails from '../../../../../components/Courses/CourseLearning/CourseLearningDetails';

interface Props {
  refetch: () => {} /*  list all courses*/;
  courseId: number;
}

const Learning: React.FC<Props> = ({ refetch, courseId }) => {
  const [data, setData] = useState<any>();
  const [dataLearning, setDataLearning] = useState<any>();

  const [isErrorIndividualCourse, setIsErrorIndividualCourse] =
    useState<boolean>(false);
  const [isLoadingIndividualCourse, setIsLoadingIndividualCourse] =
    useState<boolean>(false);
  const [errorIndividualCourse, setErrorIndividualCourse] =
    useState<AxiosError>();

  const [isErrorLearningCourse, setIsErrorLearningCourse] =
    useState<boolean>(false);
  const [isLoadingLearningCourse, setIsLoadingLearningCourse] =
    useState<boolean>(false);
  const [errorLearningCourse, setErrorLearningCourse] = useState<AxiosError>();

  useEffect(() => {
    infoIndividualCourseById(courseId);
    getLearningCourseById(courseId);
  }, []);

  const infoIndividualCourseById = async (courseId: number) => {
    setIsLoadingIndividualCourse(true);
    try {
      const { data } = await getInfoByCourseId(courseId);
      setData(data);
      setIsLoadingIndividualCourse(false);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorIndividualCourse(true);
        setIsLoadingIndividualCourse(false);
        setErrorIndividualCourse(error.response?.data);
      }
    }
  };

  const getLearningCourseById = async (courseId: number) => {
    setIsLoadingLearningCourse(true);
    try {
      const { data } = await getLearningBoxByCourseId(courseId);
      setDataLearning(data);
      setIsLoadingLearningCourse(false);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorLearningCourse(true);
        setIsLoadingLearningCourse(false);
        setErrorLearningCourse(error.response?.data);
      }
    }
  };

  if (isLoadingIndividualCourse || !data) return <LinearProgress />;
  if (isErrorIndividualCourse)
    return <div>Error! {JSON.stringify(errorIndividualCourse)}</div>;

  return (
    <CourseLearningDetails
      data={data}
      dataLearning={dataLearning}
      courseId={courseId}
      refetch={refetch}
      refetchCourse={infoIndividualCourseById}
    />
  );
};

export default Learning;
