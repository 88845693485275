/* eslint-disable complexity */
// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// Utils

//Services

//Redux
import { useSelector } from 'react-redux';

// Material UI
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Box } from '@material-ui/system';

import {
  getGuardiansFromAPI,
  postGuardian
} from '../../../../slices/guardianSlice/guardianSlice';
import {
  AppDispatch,
  RootState,
  useAppDispatch
} from '../../../../store/store';
import { selectStateGuardianForId } from '../../../../slices/guardianSlice/guardianByIdSlice';
import { countries } from '../../../../constants/countries';
import {
  IGuardian,
  IGuardianInitialValues,
  IRole,
  ICountry
} from '../../../../interfaces/guardian.interface';
import { getErrorMessage } from '../../../../utils/utils';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('Requerido'),
  last_name: Yup.string().required('Requerido'),
  email: Yup.string()
    .email('Email invalido!')
    .min(2, 'Muy corto!')
    .required('Requerido'),
  phone_number: Yup.number().required('Requerido'),

  role: Yup.string().required('Requerido'),
  country: Yup.string().min(1, 'Requerido').required('Requerido'),

  doc_type: Yup.string().required('Requerido'),
  doc_number: Yup.string().required('Requerido'),
  optional_email: Yup.string().email('Email invalido!').min(2, 'Muy corto!') /* 
      .required("Requerido") */
});

interface Props {
  guardianId?: number;
  //refetch: () => {};
  onClose: () => void;
}

const CreateGuardian: React.FC<Props> = ({ guardianId, onClose }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { guardian, isLoadingGuardianForId } = useSelector(
    selectStateGuardianForId
  );
  const { isCompleted } = useSelector(
    (state: RootState) => state.guardiansById
  );

  const funcCreateGuardian = async (guardian: IGuardian) => {
    try {
      await dispatch(postGuardian(guardian));
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getGuardiansFromAPI({ currentPage: 1, filters: null }));
    }
  };

  if (isLoadingGuardianForId || !guardian) return <LinearProgress />;
  /* if (isErrorGuardian) return <div>Error! {JSON.stringify(errorGuardian)}</div>; */

  const initialValues: IGuardianInitialValues = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: IRole.None,
    doc_type: '',
    doc_number: '',
    country: ICountry.PE,
    optional_email: '',
    password: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async values => {
        const data = {
          username: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone_number: values.phone_number,
          role: values.role,
          doc_type: values.doc_type,
          doc_number: values.doc_number,
          country: values.country,
          optional_email: values.optional_email,
          password: 'ctc2022'
        };
        await funcCreateGuardian(data!);
        onClose();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowGap={2}>
            {/* Details */}
            <Grid item container columnSpacing={2} rowGap={3} xs={12}>
              {/* First_name */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="first_name"
                  label="Nombre"
                  placeholder="Nombre"
                  variant="outlined"
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.first_name)}
                  </Alert>
                )}
              </Grid>

              {/* Last_name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="Apellido"
                  variant="outlined"
                  value={values.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && touched.last_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.last_name)}
                  </Alert>
                )}
              </Grid>

              {/* Email */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="email"
                  label="Correo"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Alert severity="error">
                    {getErrorMessage(errors.email)}
                  </Alert>
                )}
              </Grid>

              {/* Phone_number */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="phone_number"
                  label="Teléfono"
                  variant="outlined"
                  value={values.phone_number}
                  onChange={handleChange}
                />
                {errors.phone_number && touched.phone_number && (
                  <Alert severity="error">
                    {getErrorMessage(errors.phone_number)}
                  </Alert>
                )}
              </Grid>

              {/* Role */}
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="rol">Rol</InputLabel>
                  <Select
                    labelId="rol"
                    id="rol"
                    label="Rol"
                    value={values.role}
                    onChange={(value: any) =>
                      setFieldValue('role', value.target.value)
                    }
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="father">Father</MenuItem>
                    <MenuItem value="mother">Mother</MenuItem>
                    <MenuItem value="tutor">Tutor</MenuItem>
                  </Select>
                  {errors.role && touched.role && (
                    <Alert severity="error">
                      {getErrorMessage(errors.role)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Country */}
              <Grid item xs={4}>
                <Autocomplete
                  fullWidth
                  id="country"
                  options={countries}
                  autoHighlight
                  defaultValue={countries.find(
                    country => country.code === guardian.country
                  )}
                  onChange={(_e, value: any) =>
                    setFieldValue('country', value?.code || '')
                  }
                  getOptionLabel={option => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="País"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.country && touched.country && (
                  <Alert severity="error">
                    {getErrorMessage(errors.country)}
                  </Alert>
                )}
              </Grid>

              {/* Doc_type */}
              <Grid item xs={3}>
                {/* <TextField
                           fullWidth
                           id="doc_type"
                           label="DNI Tipo"
                           variant="outlined"
                           value={values.doc_type}
                           onChange={handleChange}
                        />
                        {errors.doc_type && touched.doc_type && (
                           <Alert severity="error">{getErrorMessage(errors.doc_type)}</Alert>
                        )} */}
                <FormControl fullWidth>
                  <InputLabel id="doc_type">DNI Tipo</InputLabel>
                  <Select
                    labelId="doc_type"
                    id="doc_type"
                    label="DNI Tipo"
                    value={values.doc_type}
                    onChange={(value: any) =>
                      setFieldValue('doc_type', value.target.value)
                    }
                  >
                    {/* <MenuItem value="none" disabled>ninguno</MenuItem> */}
                    <MenuItem value="DNI">DNI</MenuItem>
                    <MenuItem value="passport">Pasaporte</MenuItem>
                    <MenuItem value="cedula">Cédula</MenuItem>
                    <MenuItem value="ID">ID</MenuItem>
                    <MenuItem value="CURP">CURP</MenuItem>
                    <MenuItem value="alien-certificate">
                      Carnet de Extranjería
                    </MenuItem>
                    <MenuItem value="CE">Cédula de Extranjería</MenuItem>
                  </Select>
                  {errors.doc_type && touched.doc_type && (
                    <Alert severity="error">
                      {getErrorMessage(errors.doc_type)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Doc_number */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="doc_number"
                  label="DNI número"
                  variant="outlined"
                  value={values.doc_number}
                  onChange={handleChange}
                />
                {errors.doc_number && touched.doc_number && (
                  <Alert severity="error">
                    {getErrorMessage(errors.doc_number)}
                  </Alert>
                )}
              </Grid>

              {/* secondary email */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="optional_email"
                  label="Correo secundario"
                  variant="outlined"
                  value={values.optional_email}
                  onChange={handleChange}
                />
                {errors.optional_email && touched.optional_email && (
                  <Alert severity="error">
                    {getErrorMessage(errors.optional_email)}
                  </Alert>
                )}
              </Grid>
            </Grid>

            {/* Edit */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <LoadingButton fullWidth variant="contained" type="submit">
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateGuardian;
