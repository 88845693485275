/* eslint-disable max-lines */
/* eslint-disable complexity */
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState, useEffect } from 'react';
import { Alert, LinearProgress } from '@mui/material';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker
} from '@mui/x-date-pickers';

import './modalEditGroup.scss';
import {
  IGroupDetail,
  IEditGroupDetailInitialData
} from '../../../interfaces/group.interface';
import {
  updateGroup,
  getAllGroups,
  selectStateGroups
} from '../../../slices/groupSlice/groupsSlice';
import {
  getAllCourses,
  selectStateCourses
} from '../../../slices/courseSlice/coursesSlice';
import { useModal } from '../../../hooks/useModal';
import Modal from '../../../components/atoms/Modal';
import colors from '../../../components/atoms/Colors';
import Input from '../../../components/molecules/Input';
import { getErrorMessage, sortAlphabetically } from '../../../utils/utils';
import Tag from '../../../components/atoms/TagStatus/Tag';
import IconLock from '../../../components/atoms/icons/IconLock';
import IconCheck from '../../../components/atoms/icons/IconCheck';
import IconAlert from '../../../components/atoms/icons/IconAlert';
import IconClock from '../../../components/atoms/icons/IconClock';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import IconCalendar from '../../../components/atoms/icons/IconCalendar';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import Typography from '../../../components/atoms/Typography/Typography';
import SelectComp from '../../../components/molecules/SelectComp/SelectComp';
import ModalMessageAlert from '../../../components/molecules/ModalMessageAlert';
import SelectFilter from '../../../components/molecules/SelectFilter/SelectFilter';

type DayKey =
  | 'Lunes'
  | 'Martes'
  | 'Miércoles'
  | 'Jueves'
  | 'Viernes'
  | 'Sábado';

interface Props {
  type: 'create' | 'edit';
  infoGroupById: any;
  isOpenModal: boolean;
  closeModal: () => void;
  groupId?: number;
}

const ModalEditGroup: React.FC<Props> = ({
  groupId,
  type,
  isOpenModal,
  closeModal,
  infoGroupById
}) => {
  const today = moment().format('YYYY-MM-DD');
  const hasGroupStarted = infoGroupById?.start_date <= today;
  const daysMapping = {
    Lunes: {
      id: 1,
      name: 'Lunes',
      field_name: 'time_monday',
      isDisabled: false
    },
    Martes: {
      id: 2,
      name: 'Martes',
      field_name: 'time_tuesday',
      isDisabled: false
    },
    Miércoles: {
      id: 3,
      name: 'Miércoles',
      field_name: 'time_wednesday',
      isDisabled: false
    },
    Jueves: {
      id: 4,
      name: 'Jueves',
      field_name: 'time_thursday',
      isDisabled: false
    },
    Viernes: {
      id: 5,
      name: 'Viernes',
      field_name: 'time_friday',
      isDisabled: false
    },
    Sábado: {
      id: 6,
      name: 'Sábado',
      field_name: 'time_saturday',
      isDisabled: false
    }
  };

  const objFrecuency = [
    { id: 1, name: '1 día a la semana' },
    { id: 2, name: '2 días a la semana' },
    { id: 3, name: '3 días a la semana' },
    { id: 4, name: '4 días a la semana' },
    { id: 5, name: '5 días a la semana' },
    { id: 6, name: '6 días a la semana' }
  ];

  const dispatch: AppDispatch = useAppDispatch();
  const { courses, isLoading: isLoadingCourses } =
    useSelector(selectStateCourses);
  const { groups, error: errorCreateGroup } = useSelector(selectStateGroups);
  const [inputTime, setInputTime] = useState<any>();
  const [courseToFilter, setCourseToFilter] = useState('');
  const [selectedFrecuency, setSelectedFrecuency] = useState<any>();
  const [selectedFrecuencyDays, setSelectedFrecuencyDays] = useState<any>();
  const [isFrequencyCompleted, setIsFrequencyCompleted] = useState<boolean>();
  const [selectedDay, setSelectedDay] = useState<any>(['', '', '', '', '', '']);
  const [selectedHour, setSelectedHour] = useState<any>([
    null,
    null,
    null,
    null,
    null,
    null
  ]);
  const {
    openModal: openModalAlertCreateGroup,
    closeModal: closeModalAlertCreateGroup,
    isOpenModal: isOpenModalAlertCreateGroup
  } = useModal();

  const [alertCourse, setAlertCourse] = useState(false);
  const [isLoadingCreateGroup, setIsLoadingCreateGroup] = useState(false);

  const [objDays, setObjDays] = useState([
    {
      id: 1,
      name: 'Lunes',
      field_name: 'time_monday',
      isDisabled: false
    },
    {
      id: 2,
      name: 'Martes',
      field_name: 'time_tuesday',
      isDisabled: false
    },
    {
      id: 3,
      name: 'Miércoles',
      field_name: 'time_wednesday',
      isDisabled: false
    },
    {
      id: 4,
      name: 'Jueves',
      field_name: 'time_thursday',
      isDisabled: false
    },
    {
      id: 5,
      name: 'Viernes',
      field_name: 'time_friday',
      isDisabled: false
    },
    {
      id: 6,
      name: 'Sábado',
      field_name: 'time_saturday',
      isDisabled: false
    }
  ]);

  const [selectedDayFilter, setSelectedDayFilter] = useState<any>([]);
  const [duplicateDays, setDuplicateDays] = useState(false);

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  useEffect(() => {
    setCourseToFilter(infoGroupById?.course?.name);
    setSelectedFrecuency(objFrecuency[infoGroupById.available_days.length - 1]);
    setSelectedFrecuencyDays(infoGroupById.available_days.length);
  }, [groupId]);

  useEffect(() => {
    getDefaultData();
  }, []);

  useEffect(() => {
    const selectedFrequencyHoursGroup = selectedHour.filter(
      (hour: any) => hour !== null
    ).length;
    if (selectedFrequencyHoursGroup !== selectedFrecuencyDays) {
      setIsFrequencyCompleted(false);
    } else {
      setIsFrequencyCompleted(true);
    }
  }, [selectedHour, selectedFrecuencyDays]);

  const getDefaultData = async () => {
    const timeClasses = await infoGroupById.available_days.map((item: any) =>
      item.time
        ? `Mon Apr 08 2024 ${item.time} GMT-0500 (hora estándar de Perú)`
        : ''
    );

    const dayClasses = await infoGroupById.available_days.map(
      ({ name }: { name: string }) => daysMapping[name as DayKey]
    );

    setInputTime(
      infoGroupById?.duration_monday ||
        infoGroupById?.duration_tuesday ||
        infoGroupById?.duration_wednesday ||
        infoGroupById?.duration_thursday ||
        infoGroupById?.duration_friday ||
        infoGroupById?.duration_saturday
    );
    setSelectedHour([
      ...timeClasses,
      ...Array(6 - timeClasses.length).fill(null)
    ]);
    setSelectedDay([...dayClasses, ...Array(6 - dayClasses.length).fill('')]);
  };

  const IconCalendarDisabled = () => <IconCalendar fill="#e5e5e5" />;
  const IconCalendarEnabled = () => <IconCalendar />;

  const IconClockDisabled = () => <IconClock fill="#e5e5e5" />;
  const IconClockEnabled = () => <IconClock />;

  const editGroup = async (group: IGroupDetail) => {
    setIsLoadingCreateGroup(true);
    try {
      await dispatch(updateGroup({ groupId, group }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingCreateGroup(false);
      await dispatch(getAllGroups({ currentPage: 1, size: 100 }));
    }
  };

  const handleClickCourse = (option: any): void => {
    setCourseToFilter(option.name);
  };

  const handleClickTime = (option: any): void => {
    setInputTime(option.target.value);
  };

  const cleanSelects = () => {
    setSelectedFrecuency(null);
    setInputTime('');
    setCourseToFilter('');
    cleanSelectsDays();
  };

  const cleanSelectsDays = () => {
    setSelectedDay(['', '', '', '', '', '']);
    setSelectedDayFilter([]);
    setDuplicateDays(false);
    setObjDays(currentObjDays =>
      currentObjDays.map((obj, idx) =>
        obj.isDisabled === true ? { ...obj, isDisabled: false } : obj
      )
    );

    setSelectedHour([null, null, null, null, null, null]);
  };

  const handleClickDay = (
    option: any,
    index: any,
    setFieldTouched: any
  ): void => {
    if (selectedDay[index].isDisabled === option.isDisabled) {
      objDays[selectedDay[index].id - 1].isDisabled = false;
    }
    selectedDay[index] = option;
    selectedDayFilter[index] = option.id;
    setObjDays(currentObjDays =>
      currentObjDays.map((obj, idx) =>
        obj?.id === option?.id ? { ...obj, isDisabled: true } : obj
      )
    );
    setFieldTouched(option.field_name, true, true);
  };

  const handleChangeHours = (
    newValue: any,
    index: any,
    setFieldValue: any,
    setFieldTouched: any
  ): void => {
    setSelectedHour((prevValues: any) => {
      const newSelectedHours = [...prevValues];
      newSelectedHours[index] = newValue;
      return newSelectedHours;
    });

    setFieldTouched(selectedDay[index]?.field_name, true, true);
    setFieldValue(selectedDay[index]?.field_name, newValue);
  };

  const handleClickFrecuency = (
    option: any,
    setFieldTouched: any,
    setFieldValue: any
  ) => {
    cleanSelectsDays();
    setSelectedFrecuency(option);
    setSelectedFrecuencyDays(option.id);

    if (option?.id !== infoGroupById?.available_days.length) {
      setFieldTouched('duration', true, true);

      setFieldTouched('time_monday', true, true);
      setFieldTouched('time_tuesday', true, true);
      setFieldTouched('time_wednesday', true, true);
      setFieldTouched('time_thursday', true, true);
      setFieldTouched('time_friday', true, true);
      setFieldTouched('time_saturday', true, true);
    }

    setFieldValue('time_monday', null);
    setFieldValue('time_tuesday', null);
    setFieldValue('time_wednesday', null);
    setFieldValue('time_thursday', null);
    setFieldValue('time_friday', null);
    setFieldValue('time_saturday', null);
  };

  const initialValuesEditGroup: IEditGroupDetailInitialData = {
    name: infoGroupById?.name,
    course: infoGroupById?.course?.id,

    start_date: infoGroupById?.start_date && moment(infoGroupById?.start_date),
    end_date: infoGroupById?.end_date && moment(infoGroupById?.end_date),

    published_at:
      infoGroupById?.published_at &&
      moment(infoGroupById?.published_at).toDate(),
    archived_at:
      infoGroupById?.archived_at && moment(infoGroupById?.archived_at).toDate(),

    duration:
      infoGroupById?.time_monday ||
      infoGroupById?.time_tuesday ||
      infoGroupById?.time_wednesday ||
      infoGroupById?.time_thursday ||
      infoGroupById?.time_friday ||
      infoGroupById?.time_saturday,
    time_monday:
      infoGroupById?.time_monday &&
      moment(`2002-08-01 ${infoGroupById?.time_monday}`),
    time_tuesday:
      infoGroupById?.time_tuesday &&
      moment(`2002-08-01 ${infoGroupById?.time_tuesday}`),
    time_wednesday:
      infoGroupById?.time_wednesday &&
      moment(`2002-08-01 ${infoGroupById?.time_wednesday}`),
    time_thursday:
      infoGroupById?.time_thursday &&
      moment(`2002-08-01 ${infoGroupById?.time_thursday}`),
    time_friday:
      infoGroupById?.time_friday &&
      moment(`2002-08-01 ${infoGroupById?.time_friday}`),
    time_saturday:
      infoGroupById?.time_saturday &&
      moment(`2002-08-01 ${infoGroupById?.time_saturday}`)
  };

  const SignupSchema: any = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Muy pequeño!')
      .max(500, 'Mucho texto!')
      .required('Requerido'),
    course: Yup.number()
      .test('Is positive?', 'Seleccione un curso', value => value! > 0)
      .required('Requerido'),

    start_date: Yup.date().nullable(true).required('Requerido'),
    end_date: Yup.date()
      .nullable(true)
      .min(
        Yup.ref('start_date'),
        'FIN del curso NO puede ser antes que el INICIO del curso'
      )
      .required('Requerido'),

    published_at: Yup.date().nullable(true),
    archived_at: Yup.date()
      .nullable(true)
      .min(
        Yup.ref('published_at'),
        'ARCHIVADO NO puede ser antes que PUBLICADO'
      ),

    time_monday: Yup.date().nullable(true),
    time_tuesday: Yup.date().nullable(true),
    time_wednesday: Yup.date().nullable(true),
    time_thursday: Yup.date().nullable(true),
    time_friday: Yup.date().nullable(true),
    time_saturday: Yup.date().nullable(true)
  });

  if (
    isLoadingCourses ||
    !courses ||
    (type === 'edit' &&
      !groupId &&
      !infoGroupById &&
      !infoGroupById?.name &&
      !courseToFilter)
  )
    return <LinearProgress />;

  return (
    <div>
      <Formik
        initialValues={initialValuesEditGroup}
        validationSchema={SignupSchema}
        onSubmit={async (values, { resetForm }) => {
          const duplicados = selectedDayFilter.some(
            (value: any) =>
              selectedDayFilter.indexOf(value) !==
              selectedDayFilter.lastIndexOf(value)
          );
          setDuplicateDays(duplicados);
          const data = {
            name: values.name,
            course: values.course,

            start_date:
              values.start_date &&
              moment(values.start_date).format('YYYY-MM-DD'),
            end_date:
              values.end_date && moment(values.end_date).format('YYYY-MM-DD'),

            published_at: values.published_at && values.published_at,
            archived_at: values.archived_at && values.archived_at,

            time_monday:
              values.time_monday &&
              moment(values.time_monday).format('H:mm:ss'),
            time_tuesday:
              values.time_tuesday &&
              moment(values.time_tuesday).format('H:mm:ss'),
            time_wednesday:
              values.time_wednesday &&
              moment(values.time_wednesday).format('H:mm:ss'),
            time_thursday:
              values.time_thursday &&
              moment(values.time_thursday).format('H:mm:ss'),
            time_friday:
              values.time_friday &&
              moment(values.time_friday).format('H:mm:ss'),
            time_saturday:
              values.time_saturday &&
              moment(values.time_saturday).format('H:mm:ss'),

            duration_monday: values.time_monday ? Number(inputTime) : 0,
            duration_tuesday: values.time_tuesday ? Number(inputTime) : 0,
            duration_wednesday: values.time_wednesday ? Number(inputTime) : 0,
            duration_thursday: values.time_thursday ? Number(inputTime) : 0,
            duration_friday: values.time_friday ? Number(inputTime) : 0,
            duration_saturday: values.time_saturday ? Number(inputTime) : 0
          };

          if (!duplicados) {
            await editGroup(data!);
            closeModal();
            resetForm();
            cleanSelects();
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          setFieldValue,
          setFieldTouched,
          setFieldError
        }) => {
          return (
            <Form>
              <Modal
                width="xl"
                isOpen={isOpenModal}
                closeModal={closeModal}
                title={
                  <div className="modal-edit-group__header">
                    <Typography bold type="h2" variation="mobile">
                      Editar Grupo
                    </Typography>
                    <Tag className="modal-edit-group__tag" color="#D9D9D9">
                      ID: {groupId}
                    </Tag>
                  </div>
                }
                subtitle={
                  <Typography type="paragraph2" variation="mobile">
                    Algunos datos del grupo (*) solo pueden ser modificados
                    antes de que comience el curso. Al modificar los datos, se
                    actualizan automáticamente sin tener que hacerlo
                    manualmente.{' '}
                  </Typography>
                }
                body={
                  <div className="modal-edit-group__content-body">
                    <div className="modal-edit-group__group-information">
                      <Typography bold type="paragraph2" variation="mobile">
                        Nombre del grupo
                      </Typography>
                      <Input
                        placeholder="Dale un nombre al grupo nuevo"
                        name="name"
                        onChange={(value: string) => {
                          handleChange(value);
                          setFieldTouched(
                            'name',
                            value !== infoGroupById?.name ? true : false,
                            true
                          );
                        }}
                        value={values.name}
                        required
                        error={errors.name && touched.name ? errors.name : ''}
                      />
                      <Typography bold type="paragraph2" variation="mobile">
                        Curso*
                      </Typography>
                      <SelectFilter
                        sort
                        disabled
                        size="default"
                        placeholder="Elegir curso"
                        onclickDefaultValue={(event: any) => {
                          handleClickCourse(event);
                          setFieldValue('course', event?.id);
                          setFieldTouched(
                            'course',
                            event.target.value !== infoGroupById?.course?.id
                              ? true
                              : false,
                            true
                          );
                        }}
                        identifier="name"
                        options={sortAlphabetically(courses, 'name')}
                        valueToFilter={courseToFilter}
                        setValueToFilter={setCourseToFilter}
                        alert={errors.course && touched.course ? true : false}
                      />
                      <Typography bold type="paragraph2" variation="mobile">
                        Inicio del curso*
                      </Typography>
                      <div className="modal-edit-group__group-start-day">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <div className="modal-edit-group__group-error">
                            <DatePicker
                              disabled={hasGroupStarted ? true : false}
                              format="yyyy-MM-dd"
                              value={values.start_date}
                              onChange={(value: any) => {
                                setFieldValue('start_date', value);
                                setFieldTouched(
                                  'start_date',
                                  moment(value).format('YYYY-MM-DD') !==
                                    infoGroupById?.start_date
                                    ? true
                                    : false,
                                  true
                                );
                              }}
                              sx={{
                                input: {
                                  padding: 0,
                                  paddingLeft: '15px',
                                  height: '40px'
                                },
                                '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                fieldset: { border: '1px solid black' }
                              }}
                              slots={{
                                openPickerIcon: hasGroupStarted
                                  ? IconCalendarDisabled
                                  : IconCalendarEnabled
                              }}
                            />
                            {errors.start_date && touched.start_date && (
                              <Typography
                                type="paragraph2"
                                variation="mobile"
                                className="modal-edit-group__alert"
                                color={colors.warningColor}
                              >
                                {errors.start_date}
                              </Typography>
                            )}
                          </div>
                          <div className="modal-edit-group__group-end-day">
                            <Typography
                              bold
                              type="paragraph2"
                              variation="mobile"
                            >
                              Fin del curso*
                            </Typography>
                            <div className="modal-edit-group__group-error">
                              <DatePicker
                                disabled={true}
                                format="yyyy-MM-dd"
                                value={values.end_date}
                                minDate={values.start_date || new Date()}
                                onChange={(value: any) => {
                                  setFieldValue('end_date', value);
                                  setFieldTouched(
                                    'end_date',
                                    moment(value).format('YYYY-MM-DD') !==
                                      infoGroupById?.end_date
                                      ? true
                                      : false,
                                    true
                                  );
                                }}
                                sx={{
                                  input: {
                                    padding: 0,
                                    paddingLeft: '15px',
                                    height: '40px'
                                  },
                                  '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                  fieldset: { border: '1px solid black' }
                                }}
                                slots={{ openPickerIcon: IconCalendarDisabled }}
                              />
                              {errors.end_date && touched.end_date && (
                                <Typography
                                  type="paragraph2"
                                  variation="mobile"
                                  className="modal-edit-group__alert"
                                  color={colors.warningColor}
                                >
                                  {errors.end_date}
                                </Typography>
                              )}
                            </div>
                          </div>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="modal-edit-group__date-advice"
                        type="paragraph2"
                        variation="mobile"
                        color="#494D4B"
                      >
                        (Asegúrate que las fechas de inicio y fin estén
                        comprendidas dentro de los días de la semana que
                        selecciones para este grupo.)
                      </Typography>
                    </div>
                    <div className="modal-edit-group__group-information">
                      <Typography bold type="paragraph2" variation="mobile">
                        Publicado
                      </Typography>
                      <div className="modal-edit-group__group-start-day">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            disabled={false}
                            format="yyyy-MM-dd"
                            value={values.published_at}
                            ampm={false}
                            onChange={(value: any) => {
                              setFieldValue('published_at', value);
                              setFieldTouched(
                                'published_at',
                                moment(value).format('YYYY-MM-DD') !==
                                  infoGroupById?.published_at
                                  ? true
                                  : false,
                                true
                              );
                            }}
                            sx={{
                              input: {
                                padding: 0,
                                paddingLeft: '15px',
                                height: '40px'
                              },
                              '.MuiOutlinedInput-root': { borderRadius: '8px' },
                              fieldset: { border: '1px solid black' }
                            }}
                            slots={{
                              openPickerIcon: hasGroupStarted
                                ? IconCalendarDisabled
                                : IconCalendarEnabled
                            }}
                          />
                          {errors.published_at && touched.published_at && (
                            <Alert severity="error">
                              {getErrorMessage(errors.published_at)}
                            </Alert>
                          )}
                          <div className="modal-edit-group__group-end-day">
                            <Typography
                              bold
                              type="paragraph2"
                              variation="mobile"
                            >
                              Archivado
                            </Typography>
                            <DateTimePicker
                              format="yyyy-MM-dd"
                              value={values.archived_at}
                              minDate={values.published_at || new Date()}
                              ampm={false}
                              onChange={(value: any) => {
                                setFieldValue('archived_at', value);
                                setFieldTouched(
                                  'archived_at',
                                  moment(value).format('YYYY-MM-DD') !==
                                    infoGroupById?.archived_at
                                    ? true
                                    : false,
                                  true
                                );
                              }}
                              sx={{
                                input: {
                                  padding: 0,
                                  paddingLeft: '15px',
                                  height: '40px'
                                },
                                '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                fieldset: { border: '1px solid black' }
                              }}
                              slots={{ openPickerIcon: IconCalendar }}
                            />
                            {errors.archived_at && touched.archived_at && (
                              <Alert severity="error">
                                {getErrorMessage(errors.archived_at)}
                              </Alert>
                            )}
                          </div>
                        </LocalizationProvider>
                      </div>
                      <Typography bold type="paragraph2" variation="mobile">
                        Duración*
                      </Typography>
                      <div className="modal-edit-group__group-duration">
                        <Input
                          disabled={hasGroupStarted ? true : false}
                          placeholder="000"
                          name="duration"
                          value={inputTime}
                          isError={!inputTime ? true : false}
                          onChange={(e: any) => {
                            const compareTime =
                              inputTime !== infoGroupById?.duration_monday ||
                              inputTime !== infoGroupById?.duration_tuesday ||
                              inputTime !== infoGroupById?.duration_wednesday ||
                              inputTime !== infoGroupById?.duration_thursday ||
                              inputTime !== infoGroupById?.duration_friday ||
                              inputTime !== infoGroupById?.duration_saturday;

                            setFieldTouched(
                              'duration',
                              compareTime ? true : false,
                              true
                            );
                            setFieldValue('duration', Number(e.target?.value));
                            handleClickTime(e);
                          }}
                        />
                        <Typography bold type="paragraph2" variation="mobile">
                          minutos
                        </Typography>
                      </div>
                      <Typography bold type="paragraph2" variation="mobile">
                        Número de días*
                      </Typography>
                      <SelectComp
                        disabled={hasGroupStarted ? true : false}
                        size="small"
                        onclickDefaultValue={(event: any) => {
                          handleClickFrecuency(
                            event,
                            setFieldTouched,
                            setFieldValue
                          );
                        }}
                        options={objFrecuency}
                        dividerItems
                        value={selectedFrecuency}
                        placeholder="¿Cuántos días a la semana será la clase?"
                      />
                      <>
                        <Typography bold type="paragraph2" variation="mobile">
                          Elegir día*
                        </Typography>
                        <div className="modal-edit-group__group-frecuency">
                          {[...Array(Number(selectedFrecuencyDays) || 1)].map(
                            (_, index) => (
                              <>
                                <SelectComp
                                  className="modal-edit-group__group-day"
                                  disabled={hasGroupStarted ? true : false}
                                  size="small"
                                  onclickDefaultValue={(event: any) => {
                                    handleClickDay(
                                      event,
                                      index,
                                      setFieldTouched
                                    );
                                  }}
                                  options={objDays}
                                  dividerItems
                                  value={selectedDay[index]}
                                  placeholder="-"
                                />
                                {index === 0 ? (
                                  <Typography
                                    bold
                                    type="paragraph2"
                                    variation="mobile"
                                  >
                                    Hora*
                                  </Typography>
                                ) : (
                                  <p> </p>
                                )}
                                <div className="modal-edit-group__group-hour">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <TimePicker
                                      value={selectedHour[index]}
                                      disabled={
                                        hasGroupStarted || !selectedDay[index]
                                          ? true
                                          : false
                                      }
                                      ampm={false}
                                      onChange={(newValue: any) => {
                                        handleChangeHours(
                                          newValue,
                                          index,
                                          setFieldValue,
                                          setFieldTouched
                                        );
                                      }}
                                      sx={{
                                        input: {
                                          padding: 0,
                                          paddingLeft: '15px',
                                          height: '40px'
                                        },
                                        '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                        fieldset: { border: '1px solid black' }
                                      }}
                                      slots={{
                                        openPickerIcon: hasGroupStarted
                                          ? IconClockDisabled
                                          : IconClockEnabled
                                      }}
                                    />
                                    {errors.time_monday &&
                                      touched.time_monday && (
                                        <Alert severity="error">
                                          {getErrorMessage(errors.time_monday)}
                                        </Alert>
                                      )}
                                  </LocalizationProvider>
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </>
                    </div>

                    <div
                      className="modal-edit-group__group-private"
                      style={{background: values.published_at ? '#E5FFEA' : '#E5E5E5'}}
                    >
                      {values.published_at ? (
                        <IconCheck fill="#206614" />
                      ) : (
                        <IconLock fill="#555555" />
                      )}
                      <Typography
                        color={values.published_at ? '#206614' : '#555555'}
                        type="paragraph2"
                        variation="mobile"
                      >
                        {values.published_at
                          ? 'El grupo está publicado'
                          : 'El grupo es privado'}
                      </Typography>
                    </div>

                    {duplicateDays && (
                      <div className="modal-edit-group__group-alert-days">
                        <IconAlert />
                        <Typography
                          color={colors.colorAlertRed}
                          type="paragraph2"
                          variation="mobile"
                        >
                          {
                            'De los dias que elegiste, tienes algunos repetidos.'
                          }
                        </Typography>
                      </div>
                    )}

                    <section className="modal-edit-group__container-buttons">
                      <ButtonOutline
                        type="reset"
                        size="default"
                        disabled={isLoadingCreateGroup}
                        onClick={() => {
                          setSelectedFrecuencyDays(null);
                          setSelectedFrecuency(null);
                          closeModal();
                        }}
                      >
                        Cancelar
                      </ButtonOutline>
                      <ButtonFilled
                        type="submit"
                        size="default"
                        disabled={
                          !inputTime ||
                          !isFrequencyCompleted ||
                          isLoadingCreateGroup ||
                          Object.keys(touched).length === 0 ||
                          Object.keys(errors).length > 0
                        }
                      >
                        Editar
                      </ButtonFilled>
                    </section>
                  </div>
                }
              ></Modal>
            </Form>
          );
        }}
      </Formik>
      {errorCreateGroup && (
        <ModalMessageAlert
          width="xs"
          title={
            <Typography bold type="paragraph1" variation="desktop">
              Algo salió mal...
            </Typography>
          }
          message="Parece que has añadido un valor incorrecto o que ya expiró. Revisa y corrije el espacio marcado en rojo para continuar."
          isOpenModal={isOpenModalAlertCreateGroup}
          openModal={openModalAlertCreateGroup}
          closeModal={closeModalAlertCreateGroup}
          button1={
            <ButtonFilled size="default" onClick={closeModalAlertCreateGroup}>
              Aceptar
            </ButtonFilled>
          }
        />
      )}
    </div>
  );
};

export default ModalEditGroup;
