import { useState } from 'react';
import { toast } from 'react-hot-toast';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import { IconButton, Tooltip } from '@material-ui/core';

import { Mailto, Container } from './styles';
import IconShowText from '../../atoms/icons/IconShowText';
import IconHideText from '../../atoms/icons/IconHideText';
import IconCopyText from '../../atoms/icons/IconCopyText';
import IconCopyFilled from '../../atoms/icons/IconCopyFilled';

interface Props {
  title?: string;
  copyable?: boolean;
  mailable?: {
    email: string;
  };
  hideable?: boolean;
  externalLink?: boolean;
  hideText?: boolean;
  direction?: 'left' | 'right';
  text: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  colorIcon?: string;
  type?: string;
}

function ParagraphHideCopy({
  title = 'Copiar',
  copyable = false,
  mailable,
  hideable = false,
  externalLink = false,
  hideText,
  direction,
  text,
  type = 'text',
  placement = 'right'
}: Props) {
  const [copied, setCopied] = useState(false);
  const [hided, setHided] = useState(true);

  const handleCopyable = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);

    toast(`${text.slice(0, 20)} Copiado!`, { icon: '👏' });

    setTimeout(() => setCopied(false), 1200);
  };
  const handleHideable = () => setHided(!hided);

  const ellipsisClass: React.CSSProperties = {
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    overflow: 'hidden',
    color: '#000'
  };

  return (
    <Container>
      {/* Text */}
      {hideable ? (
        <span style={{ ...ellipsisClass }}>{hided ? '**********' : text}</span>
      ) : !hideText ? (
        <>
          {type === 'link' ? (
            <a
              href={text}
              target={externalLink ? '_blank' : '_self'}
              style={{ ...ellipsisClass }}
              rel="noreferrer"
            >
              {text}
            </a>
          ) : (
            <span style={{ ...ellipsisClass }}>{text}</span>
          )}
        </>
      ) : null}
      {/* Copyable */}
      {copyable &&
        (direction === 'left' ||
          (direction === undefined && (
            <Tooltip
              title={`${copied ? 'Copiado' : 'Copiar'}`}
              arrow
              placement="top"
            >
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleCopyable(text)}
              >
                {copied ? <CheckIcon /> : <IconCopyText />}
              </div>
            </Tooltip>
          )))}

      {/* Mailable */}
      {mailable && (
        <Mailto href={`mailto:${mailable.email}`}>
          <Tooltip title="Mailto" arrow placement="top">
            <IconButton component="span" size="small" color="primary">
              <EmailIcon />
            </IconButton>
          </Tooltip>
        </Mailto>
      )}

      {/* Hideable */}
      {hideable && (
        <Tooltip
          title={`${hided ? 'Mostrar' : 'Ocultar'}`}
          arrow
          placement="top"
        >
          <div style={{ cursor: 'pointer' }} onClick={() => handleHideable()}>
            {hided ? <IconShowText /> : <IconHideText />}
          </div>
        </Tooltip>
      )}

      {copyable && direction === 'right' && (
        <Tooltip
          title={`${copied ? 'Copiado' : title}`}
          arrow
          placement={placement}
        >
          <IconButton
            onClick={() => handleCopyable(text)}
            component="span"
            size="small"
            color="primary"
          >
            {copied ? <IconCopyFilled /> : <IconCopyFilled />}
          </IconButton>
        </Tooltip>
      )}
    </Container>
  );
}

export default ParagraphHideCopy;
