import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAllOverdueContentReports,
  selectStateOverdueContentReports
} from '../slices/CTCB2B/overdueContent/overdueContentSlice';

type FilterRoom = {
  [key: string]: string;
};

const useReportOverdueContent = (
  shouldRefresh: any,
  status: string,
  generalFilter: string | FilterRoom,
  setGeneralFilter: any
) => {
  const size = 100;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentFiltersInitialState: any =
    generalFilter &&
    typeof generalFilter === 'object' &&
    Object.keys('room.name').length > 0
      ? { room_name: Object.values(generalFilter)[0] }
      : typeof generalFilter === 'object' && Object.keys('id').length > 0
        ? { id: Object.values(generalFilter) }
        : {};
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );

  const {
 isLoading, totalCount, totalPages, overdueContentReports 
} =
    useSelector(selectStateOverdueContentReports);

  useEffect(() => {
    if (!hasFilters) {
      dispatch(
        getAllOverdueContentReports({ page: currentPage, size, status })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentFilters]);

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);
      setFilters(false);
      return;
    }

    if (hasFilters) {
      if (currentFilters?.id && currentFilters?.id?.length <= 0) {
        return;
      }

      if (currentFilters?.room?.name && currentFilters?.room?.name.length < 3) {
        return;
      }

      dispatch(
        getAllOverdueContentReports({
          page: currentPage,
          size,
          status,
          filters: currentFilters['room.name']
            ? { room_name: currentFilters['room.name'] }
            : currentFilters
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters, status]);

  useEffect(() => {
    if (!hasFilters) {
      setFilters(false);
    } else {
      setFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  useEffect(() => {
    if (shouldRefresh) {
      setCurrentPage(1);
      setCurrentFilters({});
      setFilters(false);
    }
  }, [shouldRefresh]);

  const hasFilters = Object.keys(currentFilters).length !== 0;

  const resetFilter = (value: string) => {
    const newState = { ...currentFilters };
    delete newState[value];

    setCurrentFilters(newState);
    setCurrentPage(1);
    setGeneralFilter('');
  };

  return {
    size,
    resetFilter,
    overdueContentReports,
    isLoading,
    totalPages,
    totalCount,
    currentPage,
    setCurrentPage,
    currentFilters,
    setCurrentFilters
  };
};

export default useReportOverdueContent;
