import { useState } from 'react';

export const useDebounce = (callback: Function, delay: number) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  return (...args: any[]) => {
    clearTimeout(timeoutId as NodeJS.Timeout);
    const id = setTimeout(() => {
      callback(...args);
    }, delay);
    setTimeoutId(id);
  };
};
