import React from 'react';

import Button from '../Button';

import './buttonOutline.scss';

export interface Props {
  children: any;
  onClick?: any;
  className?: string;
  size?: any;
  iconLeft?: any;
  iconRight?: any;
  hasHoverColor?: boolean;
  disabled?: boolean;
  type?: any;
  fullWidth?: boolean | string | number;
  width?: string | number;
  href?: string;
  target?: string;
}

const ButtonOutline: React.FC<Props> = ({
  children,
  onClick,
  className,
  hasHoverColor,
  disabled,
  type = 'button',
  fullWidth,
  width,
  href,
  target,
  ...otherProps
}) => {
  const hoverType = hasHoverColor ? 'hover-color' : 'hover-no-color';

  return (
    <>
      <Button
        {...otherProps}
        disabled={disabled}
        className={`button-outline button-outline--${hoverType} ${
          disabled ? 'button-outline__disabled' : ''
        } ${className ? className : ''}`}
        onClick={onClick}
        type={type}
        fullWidth={fullWidth}
        width={width}
        href={href}
        target={target}
      >
        {children}
      </Button>
    </>
  );
};

export default ButtonOutline;
