import React, { useState } from 'react';

import './textbox.scss';

interface Props {
  children: any;
  onChange: any;
  options?: any;
  name?: any;
  placeholder?: any;
  className?: string;
  size?: any;
  disabled?: any;
  onclickDefaultValue?: Function;
  iconRight?: any;
  iconLeft?: any;
  onClickIcon?: any;
  hasError?: boolean;
}

const Textbox: React.FC<Props> = ({
  children,
  size = 'default',
  className,
  onChange,
  disabled,
  name,
  placeholder,
  options,
  onclickDefaultValue,
  iconLeft,
  iconRight,
  onClickIcon,
  hasError = false
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const onClickHandler = (option: any): void => {
    //onclickDefaultValue(option);
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div
        className={`textbox ${hasError ? 'textbox__error' : ''} ${
          disabled ? 'textbox__disabled' : ''
        } textbox--${size} ${className ? className : ''}`}
      >
        {iconLeft ? (
          <div
            className={`textbox-icon ${
              isVisible ? 'textbox--active' : 'textbox--inactive'
            } textbox-icon--${size}`}
          >
            {iconLeft}
          </div>
        ) : (
          ''
        )}

        <div className={`textbox-text`}>
          <input
            className={`textbox-text textbox-text__text`}
            type="text"
            onChange={onChange}
            value={children}
            name={name}
            placeholder={placeholder}
          />
        </div>

        {iconRight ? (
          <div
            className={`textbox-icon ${
              isVisible ? 'textbox--active' : 'textbox--inactive'
            } textbox-icon--${size}`}
            onClick={onClickIcon}
          >
            {iconRight}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Textbox;
