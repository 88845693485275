import moment from 'moment';

import { CreateProject } from '../../interfaces/projects.interface';
import { CreateOrganization } from '../../interfaces/organizations.interface';

export const createOrganizationSerializer = (payload: CreateOrganization) => {
  const {
    name,
    companyName: business_name,
    tax_code_type,
    taxCode: tax_code,
    organization_type,
    address,
    country_id,
    website: web_site,
    comments: comment,
    commercialOwner: business_owner
  } = payload;

  return {
    name,
    business_name,
    tax_code_type,
    tax_code,
    organization_type,
    address,
    country_id: parseInt(country_id),
    web_site,
    comment,
    business_owner
  };
};

export const createProjectSerializer = (payload: CreateProject) => {
  const {
    project_country: countries,
    project_name: name,
    academic_owner,
    operative_owner,
    seat_sold,
    organization_id,
    start_date_operative: operative_start_date,
    end_date_operative: operative_end_date,
    start_date_alliance: alliance_start_date,
    end_date_alliance: alliance_end_date,
    start_date_agreement: winning_date,
    amount_usd,
    folder_link,
    has_hackaton,
    has_course,
    has_bootcamp,
    has_game_jam,
    has_personalization,
    has_other,
    project_description: description,
    project_comments: comment,
    project_mode,
    project_type,
    project_operation_type,
    project_complexity
  } = payload;

  const serializedProject: any = {
    name,
    academic_owner,
    operative_owner,
    seat_sold,
    organization_id,
    amount_usd,
    has_hackaton: has_hackaton ? has_hackaton : false,
    has_course: has_course ? has_course : false,
    has_bootcamp: has_bootcamp ? has_bootcamp : false,
    has_game_jam: has_game_jam ? has_game_jam : false,
    has_personalization: has_personalization ? has_personalization : false,
    has_other: has_other ? has_other : false,
    folder_link,
    description,
    comment,
    status: 'to_begin',
    countries,
    type: project_type,
    mode: project_mode,
    operation_type: project_operation_type,
    complex_level: project_complexity
  };

  if (operative_start_date !== null) {
    serializedProject.operative_start_date =
      moment(operative_start_date).format('YYYY-MM-DD');
  }
  if (operative_end_date !== null) {
    serializedProject.operative_end_date =
      moment(operative_end_date).format('YYYY-MM-DD');
  }
  if (alliance_start_date !== null) {
    serializedProject.alliance_start_date =
      moment(alliance_start_date).format('YYYY-MM-DD');
  }
  if (alliance_end_date !== null) {
    serializedProject.alliance_end_date =
      moment(alliance_end_date).format('YYYY-MM-DD');
  }
  if (winning_date !== null) {
    serializedProject.winning_date = moment(winning_date).format('YYYY-MM-DD');
  }

  return serializedProject;
};

export const getOrganizationsSerializer = (items: any) => {
  return items.map((item: any) => ({
    ...item,
    country_id: item.country.name
  }));
};
