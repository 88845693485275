// React

// Components
import CreateStudent from './CreateStudent/CreateStudent';
import OpenModal from '../../../components/Modal/OpenModal/OpenModal';
import Modal from '../../../components/Modal/Modal';

interface Props {
  studentId?: number;
  guardianSelected: any;
  isOpen: boolean;
  setIsOpen: any;
  refetch?: () => {};
}

const ModalCreateStudent: React.FC<Props> = ({
  studentId,
  refetch,
  guardianSelected,
  isOpen,
  setIsOpen
}) => {
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal
        option="edit"
        placement="top"
        handleOpenModal={handleOpenModal}
      />

      {/* Modal Edit Group */}
      {isOpen && (
        <Modal
          id={studentId}
          title="Crear Estudiante"
          fullWidth
          maxWidth="md"
          openModal={isOpen}
          handleCloseModal={handleCloseModal}
        >
          <CreateStudent
            studentId={studentId}
            guardianSelected={guardianSelected}
            //refetch={refetch}
            onClose={handleCloseModal}
          />
        </Modal>
      )}
    </>
  );
};

export default ModalCreateStudent;
