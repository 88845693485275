import axios from 'axios';

import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

export const postBulkEnrollment = async (payload: any) => {
  const response = await axios.post<any>(
    `${instanceBaseURL}/caught-users/loading-bulk-enrolments/`,
    payload?.file,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
