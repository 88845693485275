import React from 'react';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
  dataTestid?: string;
}

const IconArrowRight: React.FC<Props> = ({
  className,
  width = '16',
  height = '17',
  fill = '#313131',
  viewBox = '0 0 16 17',
  dataTestid = 'icon-arrow-right'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.27972 7.83631H11.2783L8.83018 4.93956C8.71571 4.80388 8.66063 4.62895 8.67708 4.45327C8.69352 4.27759 8.78013 4.11554 8.91786 4.00276C9.05558 3.88999 9.23315 3.83573 9.41148 3.85193C9.58981 3.86813 9.75431 3.95345 9.86879 4.08913L13.2409 8.07549C13.2636 8.1072 13.2839 8.14051 13.3016 8.17515C13.3016 8.20837 13.3016 8.2283 13.3488 8.26152C13.3794 8.3377 13.3954 8.41879 13.396 8.5007C13.3954 8.58261 13.3794 8.6637 13.3488 8.73988C13.3488 8.7731 13.3488 8.79303 13.3016 8.82625C13.2839 8.86089 13.2636 8.8942 13.2409 8.92591L9.86879 12.9123C9.80538 12.9873 9.72597 13.0476 9.63622 13.0889C9.54646 13.1303 9.44856 13.1516 9.34948 13.1514C9.19191 13.1517 9.03919 13.0977 8.91786 12.9986C8.84957 12.9429 8.79312 12.8744 8.75174 12.7971C8.71036 12.7198 8.68487 12.6352 8.67673 12.5482C8.66858 12.4611 8.67795 12.3734 8.70428 12.2899C8.73061 12.2065 8.77339 12.129 8.83018 12.0618L11.2783 9.16509H3.27972C3.10085 9.16509 2.92931 9.09509 2.80283 8.9705C2.67635 8.8459 2.6053 8.67691 2.6053 8.5007C2.6053 8.32449 2.67635 8.1555 2.80283 8.0309C2.92931 7.90631 3.10085 7.83631 3.27972 7.83631Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconArrowRight;
