import './landingsLayout.scss';

interface Props {
  table: any;
  buttonAdd: any;
  shouldRefresh: boolean;
}

const LandingsLayout: React.FC<Props> = ({
  table,
  buttonAdd,
  shouldRefresh
}) => {
  return (
    <main className="landings-layout">
      <div>{buttonAdd}</div>
      <div></div>
      <div className="landings-layout__table">{table({ shouldRefresh })}</div>
    </main>
  );
};

export default LandingsLayout;
