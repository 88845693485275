/* eslint-disable complexity */
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import './modalCreateOrganization.scss';
import Modal from '../../../components/atoms/Modal';
import {
  CreateOrganization,
  OrganizationSelectItem,
  ModalCreateOrganizationProps,
  CreateOrganizationInitialValues
} from '../../../interfaces/organizations.interface';
import Input from '../../../components/molecules/Input';
import {
  getCountries,
  selectStateCountries
} from '../../../slices/commons/countrySlice/contrySlice';
import { CreateOrganizationSchema } from './validation.schema';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import FormConfirm from '../../../components/organism/FormConfirm';
import {
  addOrganization,
  updateOrganization
} from '../../../slices/CTCB2B/organizationSlice/organizationSlice';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import SelectGeneral from '../../../components/molecules/SelectGeneral';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import Typography from '../../../components/atoms/Typography/Typography';

const ModalCreateOrganization: React.FC<ModalCreateOrganizationProps> = ({
  setShouldRefresh,
  isOpenModal,
  closeModal,
  openModal,
  isEdit,
  data,
  organizationId
}) => {
  const size = 100;
  const dispatch: AppDispatch = useAppDispatch();
  const { countries } = useSelector(selectStateCountries);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [selectTaxCodeType, setSelectOptionTaxCodeType] = useState<any>(
    isEdit ? { id: data.tax_code_type, name: data.tax_code_type } : ''
  );
  const [taxCodeTypeId, setTaxCodeTypeId] = useState(
    isEdit ? data.tax_code_type : ''
  );
  const [taxCodeType, setTaxCodeType] = useState(
    isEdit ? data.tax_code_type : ''
  );

  const getOrgTypeName =
    data?.organization_type === 'Educativa'
      ? 'Institución Educativa'
      : data?.organization_type;

  const [isOpenSelectOrganizationType, setIsOpenSelectOrganizationType] =
    useState(false);
  const [selectOptionOrganizationType, setSelectOptionOrganizationType] =
    useState<any>(
      isEdit ? { id: data.organization_type, name: data.organization_type } : ''
    );
  const [organizationType, setOrganizationType] = useState(
    isEdit ? getOrgTypeName : ''
  );
  const [organizationTypeId, setOrganizationTypeId] = useState(
    isEdit ? data.organization_type : ''
  );

  const [isOpenSelectCountry, setIsOpenSelectCountry] = useState(false);
  const [selectOptionCountry, setSelectOptionCountry] = useState<any>(
    isEdit ? { id: data.country.id, name: data.country.name } : ''
  );
  const [country, setCountry] = useState(isEdit ? data.country.name : '');
  const [countryId, setCountryId] = useState(isEdit ? data.country.id : '');

  const [isLoadingCreateOrganization, setIsLoadingCreateOrganization] =
    useState(false);
  const [alertSelectGeneral, setAlertSelectGeneral] = useState(false);
  const [alertSelectTaxCode, setAlertSelectTaxCode] = useState(false);
  const [alertSelectOrganization, setAlertSelectOrganization] = useState(false);
  const [alertSelectCountry, setAlertSelectCountry] = useState(false);

  const [organizationName, setOrganizationName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [taxCode, setTaxCode] = useState('');

  useEffect(() => {
    if (
      selectOptionCountry &&
      selectOptionOrganizationType &&
      selectTaxCodeType
    ) {
      setAlertSelectGeneral(false);
    }
  }, [selectOptionCountry, selectOptionOrganizationType, selectTaxCodeType]);

  const optionSelectionTaxCodeType = (option: any): void => {
    setSelectOptionTaxCodeType(option);
    setTaxCodeType(option.name);
    setTaxCodeTypeId(option.id);
  };

  const optionSelectionOrganizationType = (option: any): void => {
    setSelectOptionOrganizationType(option);
    setOrganizationType(option.name);
    setOrganizationTypeId(option.id);
  };

  const optionSelectionCountry = (option: any): void => {
    setSelectOptionCountry(option);
    setCountry(option.name);
    setCountryId(option.id);
  };

  useEffect(() => {
    if (isOpenModal) {
      dispatch(getCountries({ page: 1, size }));
    }
    setShowConfirm(false);
  }, [isOpenModal]);

  const createOrganization = async (payload: CreateOrganization) => {
    try {
      const response: any = await dispatch(addOrganization(payload));
      if (!response?.error?.message) {
        setHasError(false);
        setShowConfirm(true);
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const editOrganization = async (id: number, payload: CreateOrganization) => {
    try {
      const response: any = await dispatch(updateOrganization({ id, payload }));
      if (!response?.error?.message) {
        setHasError(false);
        setShowConfirm(true);
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const initialValues: CreateOrganizationInitialValues = {
    name: isEdit ? data.name : '',
    companyName: isEdit ? data.business_name : '',
    taxCode: isEdit ? data.tax_code : '',
    address: isEdit ? data.address : '',
    website: isEdit ? data.web_site : '',
    comments: isEdit ? data.comment : '',
    commercialOwner: isEdit ? data.business_owner : ''
  };

  const taxCodeTypes = [
    {
      id: 'RUC',
      name: 'RUC'
    },
    {
      id: 'NIT',
      name: 'NIT'
    },
    {
      id: 'CUIT',
      name: 'CUIT'
    }
  ];

  const orgTypes = [
    {
      id: 'Empresa',
      name: 'Empresa'
    },
    {
      id: 'Fundación',
      name: 'Fundación'
    },
    {
      id: 'Educativa',
      name: 'Institución Educativa'
    },
    {
      id: 'Gobierno',
      name: 'Gobierno'
    },
    {
      id: 'Grant',
      name: 'Grant'
    }
  ];

  const searchStringInArray = (
    searchString: string,
    array: OrganizationSelectItem[],
    validateById?: boolean
  ): boolean => {
    if (validateById) {
      return array.some(item => item.id === searchString);
    }
    return array.some(item => item.name === searchString);
  };

  const validateSelects = () => {
    const showTaxCodeTypeAlert =
      !taxCodeType || searchStringInArray(taxCodeType, taxCodeTypes);

    const showOrganizationTypeAlert =
      !organizationType || searchStringInArray(organizationType, orgTypes);

    const showCountryAlert =
      !country || searchStringInArray(countryId, countries, true);

    if (!showTaxCodeTypeAlert) {
      setAlertSelectTaxCode(true);
      setAlertSelectGeneral(true);
    }

    if (!showOrganizationTypeAlert) {
      setAlertSelectOrganization(true);
      setAlertSelectGeneral(true);
    }

    if (!showCountryAlert) {
      setAlertSelectCountry(true);
      setAlertSelectGeneral(true);
    }
  };

  const orgTitle = isEdit
    ? 'Edita los datos de la organización'
    : 'Ingresa los datos de la organización';

  const organizationFormTitle = !showConfirm ? orgTitle : '';

  const getButtonText = isEdit ? 'Guardar cambios' : 'Añadir empresa';

  const cleanSelectors = () => {
    setIsOpenSelect(false);
    setIsOpenSelectOrganizationType(false);
    setIsOpenSelectCountry(false);
    setSelectOptionTaxCodeType(null);
    setSelectOptionOrganizationType(null);
    setSelectOptionCountry(null);
    setTaxCodeType('');
    setOrganizationType('');
    setCountry('');
  };

  return (
    <Modal
      width="xl"
      isOpen={isOpenModal}
      closeModal={closeModal}
      title={isLoading ? '' : organizationFormTitle}
      body={
        isLoading ? (
          <ScreenLoader
            fullWidth={false}
            customStyles={{ flexDirection: 'column' }}
          />
        ) : !showConfirm ? (
          <Formik
            initialValues={initialValues}
            validationSchema={CreateOrganizationSchema}
            onSubmit={async (values, { resetForm }) => {
              setIsLoading(true);

              if (alertSelectGeneral) {
                return;
              }

              const payload = {
                ...values,
                tax_code_type: taxCodeTypeId,
                organization_type: organizationTypeId,
                country_id: countryId
              };

              setOrganizationName(values.name);
              setCompanyName(values.companyName);
              setTaxCode(values.taxCode);

              if (isEdit) {
                setShouldRefresh(false);
                await editOrganization(Number(organizationId), payload);
                setIsLoading(false);
                if (hasError) {
                  return;
                }
                resetForm();
                return;
              }

              await createOrganization(payload);
              setIsLoading(false);
              if (hasError) {
                return;
              }
              resetForm();
            }}
          >
            {({
 errors, touched, values, handleChange 
}) => (
              <Form>
                <div className="modal-create-organization__content-body">
                  <Typography type="paragraph2" variation="mobile">
                    Todos los campos son obligatorios
                  </Typography>
                  <div className="modal-create-organization__organization-information">
                    <Typography bold type="paragraph3" variation="mobile">
                      Nombre
                    </Typography>
                    <Input
                      placeholder="Añade el nombre de la empresa"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      required
                      error={errors.name && touched.name ? errors.name : ''}
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Razón social
                    </Typography>
                    <Input
                      placeholder="Añade la razón social de la empresa"
                      name="companyName"
                      onChange={handleChange}
                      value={values.companyName}
                      required
                      error={
                        errors.companyName && touched.companyName
                          ? errors.companyName
                          : ''
                      }
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Tipo de <br /> código tributario
                    </Typography>
                    <div className="modal-create-organization__container-select">
                      <SelectGeneral
                        setIsOpen={setIsOpenSelect}
                        isOpen={isOpenSelect}
                        size="default"
                        onclickDefaultValue={optionSelectionTaxCodeType}
                        disabled={false}
                        alert={alertSelectTaxCode}
                        setAlert={setAlertSelectTaxCode}
                        typeName={taxCodeType}
                        setTypeName={setTaxCodeType}
                        label={'Selecciona un tipo de código'}
                        options={taxCodeTypes}
                      />
                    </div>
                    <Typography bold type="paragraph3" variation="mobile">
                      Código <br /> único tributario
                    </Typography>
                    <Input
                      placeholder="Añade el código"
                      name="taxCode"
                      onChange={handleChange}
                      value={values.taxCode}
                      required
                      error={
                        errors.taxCode && touched.taxCode ? errors.taxCode : ''
                      }
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Tipo de <br />
                      organización
                    </Typography>
                    <div className="modal-create-organization__container-select">
                      <SelectGeneral
                        setIsOpen={setIsOpenSelectOrganizationType}
                        isOpen={isOpenSelectOrganizationType}
                        size="default"
                        onclickDefaultValue={optionSelectionOrganizationType}
                        options={orgTypes}
                        disabled={false}
                        alert={alertSelectOrganization}
                        setAlert={setAlertSelectOrganization}
                        typeName={organizationType}
                        setTypeName={setOrganizationType}
                        label={'Selecciona un tipo'}
                      />
                    </div>
                    <Typography bold type="paragraph3" variation="mobile">
                      Dirección
                    </Typography>
                    <Input
                      placeholder="Añade la dirección de la empresa"
                      name="address"
                      onChange={handleChange}
                      value={values.address}
                      required
                      error={
                        errors.address && touched.address ? errors.address : ''
                      }
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      País o región
                    </Typography>
                    <div className="modal-create-organization__container-select">
                      <SelectGeneral
                        setIsOpen={setIsOpenSelectCountry}
                        isOpen={isOpenSelectCountry}
                        size="default"
                        onclickDefaultValue={optionSelectionCountry}
                        options={countries}
                        disabled={false}
                        alert={alertSelectCountry}
                        setAlert={setAlertSelectCountry}
                        typeName={country}
                        setTypeName={setCountry}
                        label={'Selecciona un país'}
                      />
                    </div>
                    <Typography bold type="paragraph3" variation="mobile">
                      Sitio web
                    </Typography>
                    <Input
                      placeholder="Añade la dirección web"
                      name="website"
                      onChange={handleChange}
                      value={values.website}
                      required
                      error={
                        errors.website && touched.website ? errors.website : ''
                      }
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Comentarios
                    </Typography>
                    <Input
                      placeholder="Ingresa información adicional o comentarios"
                      name="comments"
                      onChange={handleChange}
                      value={values.comments}
                      required
                      error={
                        errors.comments && touched.comments
                          ? errors.comments
                          : ''
                      }
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Owner comercial
                    </Typography>
                    <Input
                      placeholder="Añade el owner"
                      name="commercialOwner"
                      onChange={handleChange}
                      value={values.commercialOwner}
                      required
                      error={
                        errors.commercialOwner && touched.commercialOwner
                          ? errors.commercialOwner
                          : ''
                      }
                    />
                  </div>
                  <section className="modal-create-organization__container-buttons">
                    <ButtonOutline
                      type="reset"
                      size="default"
                      disabled={isLoadingCreateOrganization}
                      onClick={() => {
                        cleanSelectors();
                        closeModal();
                      }}
                    >
                      Cancelar
                    </ButtonOutline>
                    <ButtonFilled
                      type="submit"
                      size="default"
                      disabled={isLoadingCreateOrganization}
                      onClick={validateSelects}
                    >
                      {getButtonText}
                    </ButtonFilled>
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div>
            <FormConfirm
              title={
                isEdit
                  ? '¡Cambios guardados'
                  : '¡Organización creada con éxito!'
              }
              body={{
                Nombre: organizationName,
                'Razón social': companyName,
                'Tipo de código tributario': taxCodeType,
                'Código único tributario': taxCode
              }}
              closeModal={() => {
                cleanSelectors();
                closeModal();
                setShouldRefresh(true);
              }}
            />
          </div>
        )
      }
    />
  );
};

export default ModalCreateOrganization;
