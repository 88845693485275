/* eslint-disable complexity */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getProjectFromApi,
  selectStateProjects
} from '../slices/CTCB2B/projectSlice/projectSlice';

type FilterState = {
  [key: string]: string;
};

const useProjects = (
  shouldRefresh: any,
  generalFilter: string,
  setGeneralFilter: any
) => {
  const size = 100;
  const location = useLocation();
  const navigate = useNavigate();
  const paramsURL = new URLSearchParams(location.search);
  const isParamFilter = !!(generalFilter.length > 0);
  const currentFiltersInitialState: FilterState = isParamFilter
    ? { organization_name: generalFilter }
    : {};
  const dispatch = useDispatch();
  const {
 projects, isLoading, totalCount, totalPages 
} =
    useSelector(selectStateProjects);
  const [filters, setFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );
  const [localCurrentFilters, setLocalCurrentFilters] = useState<any>(
    isParamFilter ? [currentFiltersInitialState] : []
  );

  const [finalLocalCurrentFilters, setFinalLocalCurrentFilters] = useState<any>(
    [isParamFilter ? [currentFiltersInitialState] : []]
  );

  const transformArray = (inputArray: any): any => {
    const finalArray: any = {};

    inputArray.forEach((item: any) => {
      const key = Object.keys(item)[0];
      const value = item[key];

      switch (key) {
        case 'id':
          finalArray['id'] = value;
          break;
        case 'name':
          finalArray['name'] = value;
          break;
        case 'organization_name':
          finalArray['organization_name'] = value;
          break;
        case 'operative_start_date':
          finalArray['operative_start_date'] = value;
          break;
        case 'operative_end_date':
          finalArray['operative_end_date'] = value;
          break;
        case 'operative_owner':
          finalArray['operative_owner'] = value;
          break;
        case 'academic_owner':
          finalArray['academic_owner'] = value;
          break;
        case 'status':
          finalArray['status'] = value;
          break;
        default:
          finalArray['id'] = value;
      }
    });

    return finalArray;
  };

  useEffect(() => {
    if (!hasFilters) {
      dispatch(getProjectFromApi({ page: currentPage, size }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentFilters]);

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);
      setFilters(false);
      return;
    }

    if (hasFilters) {
      if (currentFilters?.id && currentFilters?.id?.length <= 0) {
        return;
      }

      if (currentFilters?.name && currentFilters?.name?.length < 3) {
        return;
      }

      if (
        currentFilters?.organization_name &&
        currentFilters?.organization_name?.length < 3
      ) {
        return;
      }

      if (
        currentFilters?.operative_start_date &&
        currentFilters?.operative_start_date?.length < 8
      ) {
        return;
      }

      if (
        currentFilters?.operative_end_date &&
        currentFilters?.operative_end_date?.length < 8
      ) {
        return;
      }

      if (
        currentFilters?.operative_owner &&
        currentFilters?.operative_owner?.length < 3
      ) {
        return;
      }

      if (
        currentFilters?.academic_owner &&
        currentFilters?.academic_owner?.length < 3
      ) {
        return;
      }

      const transformedCurrentFilters = transformArray(localCurrentFilters);
      setFinalLocalCurrentFilters(transformedCurrentFilters);
      dispatch(
        getProjectFromApi({
          page: currentPage,
          size,
          filters: transformedCurrentFilters
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  useEffect(() => {
    if (!hasFilters) {
      setFilters(false);
    } else {
      setFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  useEffect(() => {
    if (shouldRefresh) {
      setCurrentPage(1);
      setCurrentFilters({});
      setLocalCurrentFilters({});
      setFilters(false);
    }
  }, [shouldRefresh]);

  const hasFilters = localCurrentFilters.length > 0;

  const resetFilter = (value: string) => {
    const newStateCurrent = { ...currentFilters };
    delete newStateCurrent[value];
    setCurrentFilters(newStateCurrent);

    const newStateLocal = localCurrentFilters.filter(
      (filter: any) => !filter.hasOwnProperty(value)
    );
    setLocalCurrentFilters(newStateLocal);

    if (newStateLocal.length === 0) {
      setFinalLocalCurrentFilters([]);
    }

    setCurrentPage(1);
    setGeneralFilter('');

    if (paramsURL.has('organization')) {
      paramsURL.delete('organization');
      const newURL = `${location.pathname}?${paramsURL.toString()}`;
      navigate(newURL);
    }
  };

  return {
    size,
    filters,
    projects,
    isLoading,
    totalPages,
    totalCount,
    setFilters,
    hasFilters,
    currentPage,
    resetFilter,
    setCurrentPage,
    currentFilters,
    setCurrentFilters,
    setLocalCurrentFilters,
    localCurrentFilters,
    finalLocalCurrentFilters
  };
};

export default useProjects;
