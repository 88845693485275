import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCountry } from '../../../services/country';
import { GetCountriesState } from '../../../interfaces/countries.interface';

export const getCountries = createAsyncThunk(
  'countries/getCountries',
  async ({
 getState, rejectWithValue, dispatch, ...others 
}: any) => {
    try {
      const response = await getCountry();
      return response;
    } catch (err) {
      toast.error('Ha ocurrido un error al obtener los paises.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: GetCountriesState = {
  countries: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  count: 0
};

export const countrySlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getCountries */
    builder.addCase(getCountries.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data;
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(getCountries.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateCountries = (state: any) => state.countries;
