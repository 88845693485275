import moment from 'moment';

export const inputHourRange = [
  '06:00:00',
  '06:30:00',
  '07:00:00',
  '07:30:00',
  '08:00:00',
  '08:30:00',
  '09:00:00',
  '09:30:00',
  '10:00:00',
  '10:30:00',
  '11:00:00',
  '11:30:00',
  '12:00:00',
  '12:30:00',
  '13:00:00',
  '13:30:00',
  '14:00:00',
  '14:30:00',
  '15:00:00',
  '15:30:00',
  '16:00:00',
  '16:30:00',
  '17:00:00',
  '17:30:00',
  '18:00:00',
  '18:30:00',
  '19:00:00',
  '19:30:00',
  '20:00:00',
  '20:30:00',
  '21:00:00',
  '21:30:00',
  '22:00:00'
];

export const hourFormatter = (hour: string) => {
  return moment(`2000/01/01 ${hour}`).format('HH:mm');
};

export const hourFormatterSecond = (hour: string) => {
  return moment(`2000/01/01 ${hour}`).format('HH:mm:ss');
};

// GET error in validation
// default validation hour range
export const defHourRange = (hour: string) => {
  const errorDefHourRange =
    'El rango de hora está fuera del rango aceptado que es de 9:00 am - 20:00 pm';
  const hourSelected = moment(`2000/01/01 ${hour}`).format('HH:mm');

  const result =
    hourSelected < moment(`2000/01/01 06:00`).format('HH:mm') ||
    hourSelected > moment(`2000/01/01 22:00`).format('HH:mm');

  return result;
};

// validation range between 2 hours (validation for hourFrom)
export const hourFromRangeBetween = (
  hourSelected: string,
  hourFrom: string,
  hourTo: string
) => {
  const errorHourFromRangeBetween = `la hora seleccionada ${hourSelected} es igual o mayor a ${hourFrom} (desde) y menor a ${hourTo} (hasta)`;

  const selectedTimeFormatted = moment(`2000/01/01 ${hourSelected}`).format(
    'HH:mm'
  );
  const hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format('HH:mm');
  const hourToFormatted = moment(`2000/01/01 ${hourTo}`).format('HH:mm');

  const result =
    selectedTimeFormatted >= hourFromFormatted &&
    selectedTimeFormatted < hourToFormatted;

  return result;
};

// validation range between 2 hours (validation for hourTo)
export const hourToRangeBetween = (
  hourSelected: string,
  hourFrom: string,
  hourTo: string
) => {
  const errorHourToRangeBetween = `la hora seleccionada ${hourSelected} debe ser mayor a ${hourFrom} (desde) y menor o igual a ${hourTo} (hasta)`;

  const selectedTimeFormatted = moment(`2000/01/01 ${hourSelected}`).format(
    'HH:mm'
  );
  const hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format('HH:mm');
  const hourToFormatted = moment(`2000/01/01 ${hourTo}`).format('HH:mm');

  const result =
    selectedTimeFormatted > hourFromFormatted &&
    selectedTimeFormatted <= hourToFormatted;

  return result;
};

// validation valHourTo  -> selectedHourTo <= hourFromFormatted --> error == true
export const valHourTo = (selectedHourTo: string, hourFrom: string) => {
  const errorValHourTo = `la hora seleccionada ${selectedHourTo} (hasta) es menor o igual a ${hourFrom} (desde)`;

  const hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format('HH:mm');
  const selectedHourToFormatted = moment(`2000/01/01 ${selectedHourTo}`).format(
    'HH:mm'
  );

  const result = selectedHourToFormatted <= hourFromFormatted;

  return result;
};

// validation valOtherRangesTime  -> if first range is into the actual range --> error
export const valOtherRangesTime = (
  hourFrom: string,
  hourTo: string,
  hourFrom1: string,
  selectedHourTo1: string
) => {
  const errorValOtherRangesTime = `el actual rango ${hourFrom1} - ${selectedHourTo1} incluye parte o todo el horario anterior ${hourFrom} - ${hourTo}`;

  const hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format('HH:mm');
  const hourToFormatted = moment(`2000/01/01 ${hourTo}`).format('HH:mm');
  const hourFromFormatted1 = moment(`2000/01/01 ${hourFrom1}`).format('HH:mm');
  const selectedHourToFormatted1 = moment(
    `2000/01/01 ${selectedHourTo1}`
  ).format('HH:mm');

  const result =
    hourFromFormatted1 <= hourFromFormatted &&
    selectedHourToFormatted1 >= hourToFormatted;

  return result;
};
