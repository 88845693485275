// React Query
import { useMutation, useQuery } from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Form, Formik } from 'formik';

// API

// Hot Toast
import { toast } from 'react-hot-toast';

// Interfaces

// Utils

// Material UI
import {
  Alert,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import UpdateIcon from '@mui/icons-material/Update';

import {
  initialValueByCurrencyCourse,
  submitByCurrencyCourse
} from '../../../../utils/currency';
import { currencies } from '../../../../interfaces/currency.interface';
import { IFullCourse } from '../../../../interfaces/course.interface';
import { GET, PATCH } from '../../../../services/api/api';
import { getErrorMessage } from '../../../../utils/utils';

const PricesSchema = Yup.object().shape({
  // Show_as_free_course
  show_as_free_course: Yup.boolean(),

  // Standar amount
  amount: Yup.number().min(0, 'Muy pequeño!'),

  // Discount ------------------------------------------
  has_discount: Yup.boolean(),
  discount_percentage: Yup.number().min(0, 'Muy pequeño!'),

  // Standar amount + discount
  amount_after_discount: Yup.number().min(0, 'Muy pequeño!'),

  // Has_test_month
  has_test_month: Yup.boolean(),

  // Test_month_amount
  test_month_amount: Yup.number().min(0, 'Muy pequeño!'),

  // Test_month_duration
  test_month_duration: Yup.number().min(0, 'Muy pequeño!'),

  // Test_month_classes
  test_month_classes: Yup.number().min(0, 'Muy pequeño!')
});

interface Props {
  courseId: number;
  currency: currencies;
  prices: any;
  pricesError?: any;
  pricesLoading?: any;
  refetch: () => {};
}

const Currency: React.FC<Props> = ({
  prices: pricesInfo,
  pricesError,
  pricesLoading,
  courseId,
  currency,
  refetch
}) => {
  const {
    data: prices,
    error: errorPrices,
    isError: isErrorPrices,
    isLoading: isLoadingPrices,
    remove
  } = useQuery(
    ['course-PRICES', courseId],
    // () => GET<any>(`/courses_backoffice/${pricesId}/prices/${currency}`),
    () => GET<IFullCourse>(`/courses_backoffice/${courseId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const editPrices = useMutation(
    (prices: any) => PATCH(`/price/course/${courseId}/`, prices),
    { retry: false }
  );

  if (pricesLoading || !pricesInfo) return <LinearProgress />;
  if (pricesError) return <div>Error! {JSON.stringify(pricesError)}</div>;

  return (
    <Formik
      initialValues={initialValueByCurrencyCourse(currency, pricesInfo)}
      validationSchema={PricesSchema}
      onSubmit={async values => {
        await toast.promise(
          editPrices.mutateAsync(submitByCurrencyCourse(currency, values)),
          {
            loading: 'Cargando...',
            success: 'Precios editado',
            error: err => `Error: ${err.response?.data?.test_month_amount_mxn}`
          },
          { success: { icon: <UpdateIcon color="primary" /> } }
        );

        remove();
        refetch();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              {/* Amount */}
              <Grid item container columnSpacing={1} rowSpacing={2} xs={6}>
                {/* Show_as_free_course */}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Switch
                      checked={values.show_as_free_course}
                      color="success"
                      onChange={e =>
                        setFieldValue('show_as_free_course', e.target.checked)
                      }
                    />
                    {values.show_as_free_course ? (
                      <Chip
                        icon={<VisibilityIcon />}
                        color="success"
                        label="Curso Gratis"
                      />
                    ) : (
                      <Chip
                        icon={<VisibilityOffIcon />}
                        color="warning"
                        label="Curso de Paga"
                      />
                    )}
                  </Stack>
                </Grid>

                {/* Alert */}
                <Grid item xs={12}>
                  {values.show_as_free_course ? (
                    <Alert variant="standard" severity="success">
                      Curso Gratis
                    </Alert>
                  ) : (
                    <Alert variant="standard" severity="warning">
                      Curso de Paga
                    </Alert>
                  )}
                </Grid>

                {/* Amount */}
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    fullWidth
                    id="amount"
                    label="Amount"
                    placeholder="Amount"
                    variant="outlined"
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                  />
                  {errors.amount && touched.amount && (
                    <Alert severity="error">
                      {getErrorMessage(errors.amount)}
                    </Alert>
                  )}
                </Grid>
              </Grid>

              {/* Discount */}
              <Grid item container columnSpacing={1} rowSpacing={2} xs={6}>
                {/* Has_discount */}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Switch
                      checked={values.has_discount}
                      color="success"
                      onChange={e =>
                        setFieldValue('has_discount', e.target.checked)
                      }
                    />
                    {values.has_discount ? (
                      <Chip
                        icon={<VisibilityIcon />}
                        color="success"
                        label="Has Discount"
                      />
                    ) : (
                      <Chip
                        icon={<VisibilityOffIcon />}
                        color="warning"
                        label="Has NOT Discount"
                      />
                    )}
                  </Stack>
                </Grid>

                {/* Discount_percentage */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="discount_percentage"
                    label="Discount percentage"
                    placeholder="Discount percentage"
                    variant="outlined"
                    type="number"
                    disabled={!values.has_discount}
                    value={values.discount_percentage}
                    onChange={handleChange}
                  />
                  {errors.discount_percentage &&
                    touched.discount_percentage && (
                      <Alert severity="error">
                        {getErrorMessage(errors.discount_percentage)}
                      </Alert>
                    )}
                </Grid>

                {/* Amount_after_discount */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="amount_after_discount"
                    label="Amount AFTER DISCOUNT"
                    placeholder="Amount AFTER DISCOUNT"
                    variant="outlined"
                    type="number"
                    disabled={!values.has_discount}
                    value={values.amount_after_discount}
                    onChange={handleChange}
                  />
                  {errors.amount_after_discount &&
                    touched.amount_after_discount && (
                      <Alert severity="error">
                        {getErrorMessage(errors.amount_after_discount)}
                      </Alert>
                    )}
                </Grid>
              </Grid>

              {/* Test month */}
              <Grid item container columnSpacing={1} rowSpacing={2} xs={12}>
                {/* Header */}
                <Grid item xs={12}>
                  <Divider textAlign="left">
                    <Chip
                      label="Test Month"
                      variant="outlined"
                      sx={{ borderRadius: '10px' }}
                    />
                  </Divider>
                </Grid>

                {/* Has_test_month */}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Switch
                      checked={values.has_test_month}
                      color="success"
                      onChange={e =>
                        setFieldValue('has_test_month', e.target.checked)
                      }
                    />
                    {values.has_test_month ? (
                      <Chip
                        icon={<VisibilityIcon />}
                        color="success"
                        label="Mes de prueba ACTIVADO"
                      />
                    ) : (
                      <Chip
                        icon={<VisibilityOffIcon />}
                        color="warning"
                        label="Mes de prueba DESACTIVADO"
                      />
                    )}
                  </Stack>
                </Grid>

                {/* Alert */}
                <Grid item xs={12}>
                  {values.has_test_month ? (
                    <Alert variant="standard" severity="success">
                      Mes de prueba ACTIVADO
                    </Alert>
                  ) : (
                    <Alert variant="standard" severity="warning">
                      Mes de prueba DESACTIVADO
                    </Alert>
                  )}
                </Grid>

                {/* Test_month_amount */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="test_month_amount"
                    label="Test Amount"
                    placeholder="Test Amount"
                    variant="outlined"
                    type="number"
                    value={values.test_month_amount}
                    onChange={handleChange}
                  />
                  {errors.test_month_amount && touched.test_month_amount && (
                    <Alert severity="error">
                      {getErrorMessage(errors.test_month_amount)}
                    </Alert>
                  )}
                </Grid>

                {/* Test_month_duration */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="test_month_duration"
                    label="Duración X meses"
                    placeholder="Duración X meses"
                    variant="outlined"
                    type="number"
                    value={values.test_month_duration}
                    onChange={handleChange}
                  />
                  {errors.test_month_duration &&
                    touched.test_month_duration && (
                      <Alert severity="error">
                        {getErrorMessage(errors.test_month_duration)}
                      </Alert>
                    )}
                </Grid>

                {/* Test_month_classes */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="test_month_classes"
                    label="Número de clases"
                    placeholder="Número de clases"
                    variant="outlined"
                    type="number"
                    value={values.test_month_classes}
                    onChange={handleChange}
                  />
                  {errors.test_month_classes && touched.test_month_classes && (
                    <Alert severity="error">
                      {getErrorMessage(errors.test_month_classes)}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* Save */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={editPrices.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Currency;
