import React from 'react';
import moment from 'moment';

import Modal from '../../atoms/Modal';
import colors from '../../atoms/Colors';
import './modalDetailReportOverdueContent.scss';
import TagStatusReport from '../TagStatusReport';
import IconAlert from '../../atoms/icons/IconAlert';
import Typography from '../../atoms/Typography/Typography';

interface Props {
  information: any;
  isOpenModal: boolean;
  isAdmin: boolean;
  isStatusVisible: boolean;
  openModal: () => void;
  closeModal: () => void;
  button1?: any;
  button2?: any;
  message?: string;
  className?: string;
  iconAlert?: any;
  align?: 'center' | 'left';
  dataTestid?: string;
}

const ModalDetailReportOverdueContent: React.FC<Props> = ({
  information,
  isOpenModal,
  isAdmin,
  isStatusVisible,
  openModal,
  closeModal,
  className = '',
  message,
  button1,
  button2,
  iconAlert,
  align = 'left',
  dataTestid = 'modal-detail-report-overdue-content-test-id'
}) => {
  return (
    <div data-testid={dataTestid}>
      <Modal
        className={`modal-detail-report-overdue-content ${className}`}
        width="lg"
        isOpen={isOpenModal}
        closeModal={closeModal}
        icon={
          iconAlert === 'alert' ? <IconAlert /> : !iconAlert ? null : iconAlert
        }
        title={
          <Typography
            align={align}
            type="paragraph1"
            color={colors.gris05}
            variation="desktop"
            bold
          >
            Detalle de reporte
          </Typography>
        }
        subtitle={
          isStatusVisible ? (
            <TagStatusReport status={information?.status}>
              {information?.status}
            </TagStatusReport>
          ) : null
        }
        align={align}
        body={
          <form>
            <div className="modal-detail-report-overdue-content__container-detail">
              <div>
                <Typography bold type="paragraph2" variation="mobile">
                  Fecha de reporte
                </Typography>
                <Typography type="paragraph2" variation="mobile">
                  {moment(information?.created_at).format('DD/MM/YY')}
                </Typography>
              </div>
              <div>
                <Typography bold type="paragraph2" variation="mobile">
                  Profesor/a
                </Typography>
                <Typography type="paragraph2" variation="mobile">
                  {information?.teacher}
                </Typography>
              </div>
              <div>
                <Typography bold type="paragraph2" variation="mobile">
                  ID del salón
                </Typography>
                <Typography type="paragraph2" variation="mobile">
                  {information?.room?.id}
                </Typography>
              </div>
              <div>
                <Typography bold type="paragraph2" variation="mobile">
                  Salón
                </Typography>
                <Typography type="paragraph2" variation="mobile">
                  {information?.room?.name}
                </Typography>
              </div>
              {information?.status === 'done' ? (
                <>
                  <div>
                    <Typography bold type="paragraph2" variation="mobile">
                      Fecha de resolución
                    </Typography>
                    <Typography type="paragraph2" variation="mobile">
                      {moment(information?.resolution_at).format('DD/MM/YY')}
                    </Typography>
                  </div>
                  <div>
                    <Typography bold type="paragraph2" variation="mobile">
                      Resuelto por
                    </Typography>
                    <Typography type="paragraph2" variation="mobile">
                      {information?.user}
                    </Typography>
                  </div>
                </>
              ) : null}
              <div>
                <Typography bold type="paragraph2" variation="mobile">
                  Motivo principal
                </Typography>
                <Typography type="paragraph2" variation="mobile">
                  {information?.reason}
                </Typography>
              </div>
            </div>
          </form>
        }
        buttons={
          <section
            className={`modal-detail-report-overdue-content__container-buttons modal-detail-report-overdue-content__container-buttons--align-right`}
          >
            {button1} {information?.status === 'pending' ? button2 : null}
          </section>
        }
      />
    </div>
  );
};

export default ModalDetailReportOverdueContent;
