import './chip.scss';

interface Props {
  label?: any;
  color?: any;
  size?: any;
  variant?: any;
}

const Chip: React.FC<Props> = ({
  label,
  color = 'default',
  size = 'medium',
  variant = 'filled'
}) => {
  return (
    <div
      className={`chip ${
        variant === 'outlined'
          ? `chip--outline-${color}`
          : `chip--filled-${color}`
      } ${`chip--${size}`} ${`chip--${variant}`}`}
    >
      <span className="chip__text">{label}</span>
    </div>
  );
};

export default Chip;
