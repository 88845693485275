/* eslint-disable max-lines */
/* eslint-disable complexity */
import moment from 'moment';
import toast from 'react-hot-toast';
import React, { ChangeEvent, useEffect, useState } from 'react';

import './certificateDesign.scss';
import {
  patchLinksForCetificate,
  postCreateLinksForCetificate
} from '../../../services/groups';
import colors from '../../atoms/Colors';
import Input from '../../molecules/Input';
import Textbox from '../../molecules/Textbox';
import FileInput from '../../molecules/FileInput';
import { useModal } from '../../../hooks/useModal';
import ColorFrame from '../../molecules/ColorFrame';
import IconDelete from '../../atoms/icons/IconDelete';
import ModalPreview from '../../molecules/ModalPreview';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonOutline from '../../molecules/ButtonOutline';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import IconUploadFile from '../../atoms/icons/IconUploadFile';
import ModalMessageAlert from '../../molecules/ModalMessageAlert';

interface Props {
  rooms: any;
  setCheckedStudents: React.Dispatch<React.SetStateAction<any[]>>;
  setHasSavedCertificate: React.Dispatch<React.SetStateAction<boolean>>;
  hasChangedInitialValues: boolean;
  setHasChangedInitialValues: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: any;
  selectedBackground: any;
  setSelectedBackground: React.Dispatch<React.SetStateAction<any>>;
  getGroupCertificates: any;
  startDate: string;
  endDate: string;
  courseName: string;
  colorName: string;
  setColorName: React.Dispatch<React.SetStateAction<string>>;
  setCourseName: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  hasCreatedCertificate: boolean;
}

const CertificateDesign: React.FC<Props> = ({
  rooms,
  //setCertificateDesign,
  setCheckedStudents,
  setHasSavedCertificate,
  hasChangedInitialValues,
  setHasChangedInitialValues,
  initialValues,
  selectedBackground,
  setSelectedBackground,
  getGroupCertificates,
  startDate,
  setCourseName,
  endDate,
  courseName,
  colorName,
  setColorName,
  setStartDate,
  setEndDate,
  hasCreatedCertificate
}) => {
  const formData = new FormData();
  const [isErrorBackground, setIsErrorBackground] = useState<boolean>(false);

  const {
    openModal: openModalPreview,
    closeModal: closeModalPreview,
    isOpenModal: isOpenModalPreview
  } = useModal();

  const {
    openModal: openModalAlertLogo,
    closeModal: closeModalAlertLogo,
    isOpenModal: isOpenModalAlertLogo
  } = useModal();

  useEffect(() => {
    if (
      hasValueChanged(initialValues?.backgroundUrl, selectedBackground?.url) ||
      hasValueChanged(initialValues?.courseName, courseName) ||
      hasValueChanged(initialValues?.startDate, startDate) ||
      hasValueChanged(initialValues?.endDate, endDate) ||
      hasValueChanged(initialValues?.colorName, colorName)
    ) {
      setHasChangedInitialValues(true);
      setCheckedStudents([]);
    }
  }, [
    initialValues,
    selectedBackground?.url,
    courseName,
    startDate,
    endDate,
    colorName
  ]);

  useEffect(() => {
    if (
      hasChangedInitialValues === false &&
      isErrorBackground === false &&
      selectedBackground &&
      selectedBackground.url
    ) {
      setHasSavedCertificate(true);
    } else {
      setHasSavedCertificate(false);
    }
  }, [hasChangedInitialValues, isErrorBackground, selectedBackground?.url]);

  const hasValueChanged = (initialValue: any, newValue: any) => {
    if (initialValue === undefined || newValue === undefined) {
      return false;
    }
    return initialValue !== newValue;
  };

  /* const getGroupCertificates = async () => {
    try {
      const { data } = await getCetificateLinks(rooms.id);
      setSelectedBackground({
        file: null,
        name: data?.background?.split(baseUrlBackground)[1] || '',
        url: data.background || '',
        base64: data.background_base64 ? data.background_base64 : '',
        type: data.background_type ? data.background_type : ''
      });
      setCertificateId(data.id);
      setCourseName(data.name ? data.name : rooms.course.name);
      setStartDate(data.start_date ? data.start_date : rooms.start_date);
      setEndDate(data.end_date ? data.end_date : rooms.end_date);
      setColorName(data.color ? data.color : '#000000');
      setHasCreatedCertificate(data.background ? true : false);
      setInitialValues({
        backgroundName: data?.background?.split(baseUrlBackground)[1] || '',
        backgroundUrl: data.background || '',
        courseName: data.name ? data.name : rooms.course.name,
        startDate: data.start_date ? data.start_date : rooms.start_date,
        endDate: data.end_date ? data.end_date : rooms.end_date,
        colorName: data.color ? `${data.color}` : '#000000',
        backgroundBase64: data.background_base64 ? data.background_base64 : '',
        backgroundType: data.background_type ? data.background_type : ''
      });
      setHasChangedInitialValues(false);
    } catch (error) {
      console.log('error: ', error);
      setInitialValues({
        backgroundName: '',
        backgroundUrl: '',
        colorName: '#000000',
        ...initialValues
      });
      setHasChangedInitialValues(false);
    }
  }; */

  const patchInformationCertificate = async (groupId: any, data: any) => {
    try {
      const response = await patchLinksForCetificate(groupId, data);
      if (response?.status === 200) {
        toast.success('¡Se guardo el diseño correctamente!');
        getGroupCertificates();
        setHasSavedCertificate(true);
        setHasChangedInitialValues(false);
      } else {
        toast.error('No se pudo guardar el diseño');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al guardar el diseño');
    }
  };

  const postInformationCertificate = async (links: any) => {
    try {
      const response = await postCreateLinksForCetificate(links);
      if (response?.status === 201) {
        toast.success('¡Se guardo el diseño correctamente!');
        setHasSavedCertificate(true);
        setHasChangedInitialValues(false);
        getGroupCertificates();
      } else {
        toast.error('No se pudo guardar el diseño');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al guardar el diseño');
    }
  };

  const handleColor = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      setColorName(e.target.value);
    }
  };

  const handleFileBackgroundChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      // Show a preview of the image
      const reader = new FileReader();

      reader.onload = e => {
        const img: HTMLImageElement = new Image();

        img.onload = () => {
          if (img.width === 2480 && img.height === 1756) {
            setIsErrorBackground(false);
          } else {
            setIsErrorBackground(true);
          }
          setSelectedBackground({
            file: file,
            name: file.name,
            url: e.target?.result
          });
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteLogo = async () => {
    setIsErrorBackground(false);
    setSelectedBackground({
      file: null,
      name: '',
      url: '',
      base64: initialValues?.backgroundBase64 || '',
      type: initialValues?.backgroundType || ''
    });
  };

  const handleClickPreview = () => openModalPreview();

  const handleClickSave = async () => {
    if (
      !isErrorBackground &&
      hasChangedInitialValues &&
      selectedBackground?.url
    ) {
      if (selectedBackground && selectedBackground.file) {
        formData.append('background', selectedBackground.file);
      } else if (selectedBackground && selectedBackground.url) {
        formData.append('background', selectedBackground.url);
      } else;
      if (courseName) {
        formData.append('name', courseName);
      }
      if (startDate) {
        formData.append('start_date', startDate);
      }
      if (endDate) {
        formData.append('end_date', endDate);
      }
      if (colorName) {
        formData.append('color', colorName);
      }
      if (!hasCreatedCertificate && rooms?.id) {
        formData.append('group_id', rooms?.id.toString());
      }

      hasCreatedCertificate
        ? patchInformationCertificate(rooms?.id.toString(), formData)
        : postInformationCertificate(formData);
    }
  };

  if (!rooms) return <ScreenLoader />;
  return (
    <>
      <div className="certificate-design">
        <section className="certificate-design__container-information">
          <div className="certificate-design__container-title">
            <Typography
              type="h2"
              variation="mobile"
              bold
              className="certificate-design__title"
            >
              Diseño
            </Typography>
            <Typography
              type="paragraph2"
              variation="mobile"
              className="certificate-design__sub-title"
            >
              Completa los datos necesarios, añade más logos y firmas si es el
              caso.
            </Typography>
          </div>
          <div className="certificate-design__container-form">
            <Typography
              type="paragraph2"
              variation="mobile"
              bold
              className="certificate-design__typography"
            >
              Nombre de curso
            </Typography>
            <Input
              className="certificate-design__inputs"
              placeholder="Nombre"
              name="course_name"
              value={courseName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCourseName(e.target.value);
              }}
            />
            <Typography
              type="paragraph2"
              variation="mobile"
              bold
              className="certificate-design__typography"
            >
              Duración
            </Typography>
            <section className="certificate-design__duration">
              <Typography
                type="paragraph2"
                variation="mobile"
                className="certificate-design__typography"
              >
                De
              </Typography>
              <Input
                className="certificate-design__inputs"
                placeholder="date"
                name="course_start_date"
                type="date"
                value={startDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStartDate(e.target.value);
                }}
              />
              <Typography
                type="paragraph2"
                variation="mobile"
                className="certificate-design__typography"
              >
                al
              </Typography>
              <Input
                className="certificate-design__inputs"
                placeholder="date"
                name="course_end_date"
                type="date"
                value={endDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEndDate(e.target.value);
                }}
              />
            </section>
            <section className="certificate-design__limits">
              <Typography
                type="paragraph2"
                variation="mobile"
                bold
                className="certificate-design__typography"
              >
                Fondo
              </Typography>
              <Typography
                type="paragraph2"
                variation="mobile"
                className="certificate-design__typography-limits"
              >
                Debe medir 2480px x 1756px
              </Typography>
            </section>
            <section className="certificate-design__button">
              {selectedBackground?.name ? (
                <Textbox
                  className={`certificate-design__input-logo`}
                  iconRight={
                    <IconDelete
                      fill={
                        isErrorBackground ? colors.warningColor : colors.gris05
                      }
                    />
                  }
                  onClickIcon={() => handleDeleteLogo()}
                  hasError={isErrorBackground}
                  onChange={() => {}}
                >
                  {selectedBackground?.name}
                </Textbox>
              ) : null}
              {!selectedBackground?.url ? (
                <FileInput
                  id="file-input-logo"
                  className={`certificate-design__file`}
                  onClick={(
                    event: React.MouseEvent<HTMLInputElement, MouseEvent>
                  ) => {
                    const element = event.target as HTMLInputElement;
                    element.value = ''; //reset
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileBackgroundChange(e)
                  }
                >
                  <Typography
                    type="small-12"
                    variation="mobile"
                    color={colors.gris05}
                  >
                    Añadir fondo
                  </Typography>
                  <IconUploadFile fill={colors.gris05} />
                </FileInput>
              ) : (
                ''
              )}
              {isErrorBackground ? (
                <Typography
                  type="paragraph2"
                  variation="mobile"
                  color={colors.warningColor}
                >
                  Ocurrió un error al cargar el archivo. Vuelve a intentar
                  siguiendo el formato permitido.
                </Typography>
              ) : null}
            </section>
            <Typography
              type="paragraph2"
              variation="mobile"
              bold
              className="certificate-design__typography"
            >
              Color tipografía
            </Typography>
            <ColorFrame
              className="certificate-design__color-frame"
              value={colorName || '#000000'}
              onChange={handleColor}
            />
          </div>
          <section className="certificate-design__container-buttons">
            <ButtonFilled
              size="default"
              width="260px"
              disabled={
                !hasChangedInitialValues ||
                isErrorBackground ||
                !selectedBackground?.url
              }
              onClick={() => handleClickSave()}
            >
              Guardar diseño
            </ButtonFilled>
            <ButtonOutline
              size="default"
              disabled={!initialValues?.backgroundUrl}
              onClick={() => handleClickPreview()}
            >
              Ver diseño guardado
            </ButtonOutline>
          </section>
        </section>
      </div>
      {/*  */}
      <ModalMessageAlert
        width="xs"
        title={
          <Typography bold type="paragraph1" variation="desktop">
            Error al cargar logo
          </Typography>
        }
        message="El tamaño de la imagen es mayor a 800 x 600px"
        isOpenModal={isOpenModalAlertLogo}
        openModal={openModalAlertLogo}
        closeModal={closeModalAlertLogo}
        button1={
          <ButtonFilled size="default" onClick={closeModalAlertLogo}>
            Volver a intentar
          </ButtonFilled>
        }
      />
      <ModalPreview
        width="xxxl"
        className="preview-container-modal"
        title={
          <Typography bold type="paragraph1" variation="desktop" align="left">
            Vista previa de certificado
          </Typography>
        }
        body={
          <div className="preview-container" id="certificate-to-print">
            <head>
              <meta charSet="utf-8" />
              <link
                href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap"
                rel="stylesheet"
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                rel="stylesheet"
              />
              <style>
                {`
                  @font-face {
                    font-family: 'Lora';
                  }
                  @font-face {
                    font-family: 'Poppins';
                  }
                `}
              </style>
            </head>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <div
                id="content-certificate"
                style={{
                  backgroundImage: `url("data:image/${selectedBackground?.type};base64,${selectedBackground?.base64}")`,
                  backgroundSize: 'contain',
                  backgroundRepeat: ' no-repeat',
                  backgroundPosition: 'center',
                  borderRadius: '6px',
                  width: isOpenModalPreview ? '840px' : '1500px',
                  height: isOpenModalPreview ? '595px' : '960px',
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '18px',
                  justifyContent: 'space-between'
                }}
              >
                <section
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    margin: '145px auto 176px auto',
                    width: isOpenModalPreview ? '600px' : '1000px'
                  }}
                >
                  <div className="certificate__details-certificate-text">
                    <p
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        color: colorName,
                        margin: isOpenModalPreview
                          ? '0 0 10px 0'
                          : '100px 0 20px 0',
                        fontWeight: 400,
                        fontSize: isOpenModalPreview ? '16px' : '28px',
                        lineHeight: '24px'
                      }}
                    >
                      Certifica que:
                    </p>
                    <h1
                      style={{
                        fontSize: isOpenModalPreview ? '28px' : '50px',
                        lineHeight: isOpenModalPreview ? '42px' : '65px',
                        fontFamily: 'Lora',
                        fontStyle: 'normal',
                        color: colorName,
                        margin: '0 0 10px 0',
                        fontWeight: 400
                      }}
                    >
                      {isOpenModalPreview
                        ? 'Nombre del estudiante'
                        : 'param_student'}
                    </h1>
                    <p
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        color: colorName,
                        margin: isOpenModalPreview
                          ? '0 0 10px 0'
                          : '50px 0 20px 0',
                        fontWeight: 400,
                        fontSize: isOpenModalPreview ? '16px' : '28px',
                        lineHeight: '24px'
                      }}
                    >
                      Culminó satisfactoriamente el curso de
                    </p>
                    <h2
                      style={{
                        fontFamily: 'Lora',
                        fontStyle: 'normal',
                        color: colorName,
                        margin: isOpenModalPreview
                          ? '0 0 10px 0'
                          : '0 0 35px 0',
                        fontWeight: 400,
                        fontSize: isOpenModalPreview ? '28px' : '50px',
                        lineHeight: isOpenModalPreview ? '42px' : '65px'
                      }}
                    >
                      {courseName}
                    </h2>
                    <p
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        color: colorName,
                        margin: 0,
                        fontWeight: 400,
                        fontSize: isOpenModalPreview ? '16px' : '28px',
                        lineHeight: '24px'
                      }}
                    >
                      {startDate === endDate
                        ? `realizado el ${moment(startDate).format('D MMMM')}`
                        : `realizado el ${moment(startDate).format('D MMMM')} al ${moment(
                            endDate
                          ).format(
                            'D MMMM'
                          )} del ${moment(endDate).format('YYYY')}`}
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        }
        button1={
          <ButtonFilled size="default" onClick={closeModalPreview}>
            Cerrar
          </ButtonFilled>
        }
        isOpenModal={isOpenModalPreview}
        openModal={openModalPreview}
        closeModal={closeModalPreview}
      />
    </>
  );
};

export default CertificateDesign;
