import React from 'react';

interface Props {
  fillCircle?: string;
  fillCheck?: string;
  className?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  style?: any;
  dataTestid?: string;
}

const IconCheckRounded: React.FC<Props> = ({
  style,
  className,
  width = '49',
  height = '49',
  fillCheck = 'white',
  viewBox = '0 0 49 49',
  fillCircle = '#0BC79B',
  dataTestid = 'icon-check-rounded'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5 47.4688C37.1853 47.4688 47.4688 37.1853 47.4688 24.5C47.4688 11.8147 37.1853 1.53125 24.5 1.53125C11.8147 1.53125 1.53125 11.8147 1.53125 24.5C1.53125 37.1853 11.8147 47.4688 24.5 47.4688Z"
        fill={fillCircle}
      />
      <path
        d="M35.2188 10.7188L19.1406 27.2562L13.7812 21.7437L8.42188 27.2562L19.1406 38.2812L40.5781 16.2313L35.2188 10.7188Z"
        fill={fillCheck}
      />
    </svg>
  );
};

export default IconCheckRounded;
