import React from 'react';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
}

const IconCopy: React.FC<Props> = ({
  className,
  fill = '#313131',
  dataTestid = 'icon-copy'
}) => {
  return (
    <div style={{ width: 'auto' }}>
      <svg
        data-testid={dataTestid}
        className={className}
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.64706 15.6472C5.12941 15.6472 4.68643 15.463 4.31812 15.0947C3.94918 14.7258 3.76471 14.2825 3.76471 13.7649V2.47073C3.76471 1.95308 3.94918 1.50979 4.31812 1.14085C4.68643 0.772536 5.12941 0.588379 5.64706 0.588379H14.1176C14.6353 0.588379 15.0786 0.772536 15.4475 1.14085C15.8158 1.50979 16 1.95308 16 2.47073V13.7649C16 14.2825 15.8158 14.7258 15.4475 15.0947C15.0786 15.463 14.6353 15.6472 14.1176 15.6472H5.64706ZM5.64706 13.7649H14.1176V2.47073H5.64706V13.7649ZM1.88235 19.4119C1.36471 19.4119 0.921412 19.2278 0.55247 18.8594C0.184157 18.4905 0 18.0472 0 17.5296V5.29426C0 5.02759 0.090353 4.80391 0.271059 4.6232C0.451137 4.44312 0.67451 4.35309 0.941176 4.35309C1.20784 4.35309 1.43153 4.44312 1.61224 4.6232C1.79231 4.80391 1.88235 5.02759 1.88235 5.29426V17.5296H11.2941C11.5608 17.5296 11.7845 17.6199 11.9652 17.8006C12.1453 17.9807 12.2353 18.2041 12.2353 18.4707C12.2353 18.7374 12.1453 18.9608 11.9652 19.1409C11.7845 19.3216 11.5608 19.4119 11.2941 19.4119H1.88235Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default IconCopy;
