// React
import { useState } from 'react';

// Router
import { useSearchParams } from 'react-router-dom';

// Helpers
import { addParams, deleteParams } from '../../../helpers/router.helpers';

// Components
import EditCourse from './EditCourse/EditCourse';
import OpenModal from '../../../components/Modal/OpenModal/OpenModal';
import Modal from '../../../components/Modal/Modal';

interface Props {
  courseId: number;
  refetch: () => {};
  autoOpen?: boolean;
}

const ModalEditCourse: React.FC<Props> = ({
  courseId,
  refetch,
  autoOpen = false
}) => {
  const [params, setParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(autoOpen);

  const handleOpenModal = () => {
    addParams(params, setParams, 'id', courseId.toString());
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    deleteParams(params, setParams, 'id');
    setOpenModal(false);
  };

  return (
    <>
      {/* Open Modal */}
      {!autoOpen && (
        <OpenModal
          option="edit"
          placement="left"
          handleOpenModal={handleOpenModal}
        />
      )}

      {/* Modal Edit Course */}
      <Modal
        id={courseId}
        title="Editar Curso"
        copyUrl
        fullWidth
        maxWidth="lg"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <EditCourse
          courseId={courseId}
          refetch={refetch}
          onClose={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default ModalEditCourse;
