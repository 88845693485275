import TagStatus from '../../atoms/TagStatus/Tag';

import './tagStatusEvaluation.scss';

interface Props {
  status?: 'none' | 'pending' | 'done' | 'complete_by_admin' | 'incomplete';
  children: any;
}

const TagStatusEvaluation: React.FC<Props> = ({ children, status }) => {
  return (
    <TagStatus
      className={`tag-status-evaluation tag-status-evaluation--${status}`}
    >
      {children === 'none'
        ? '-'
        : children === 'pending'
          ? 'Pendiente'
          : children === 'done'
            ? 'Completado'
            : children === 'complete_by_admin'
              ? 'Completado por ADMIN'
              : children === 'incomplete'
                ? 'Incompleto'
                : children === 'expired_evaluation'
                  ? 'Vencida'
                  : ''}
    </TagStatus>
  );
};

export default TagStatusEvaluation;
