export const renderDay = (day: string) => {
  switch (day?.toLowerCase()) {
    case 'lunes':
      return 'monday';
    case 'martes':
      return 'tuesday';
    case 'miércoles':
      return 'wednesday';
    case 'jueves':
      return 'thursday';
    case 'viernes':
      return 'friday';
    case 'sábado':
      return 'saturday';
    case 'domingo':
      return 'sunday';
    default:
      return;
  }
};
