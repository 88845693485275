import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import './alertSnackbar.scss';
import { AlertSnackbarProps } from './interfaces';
import Typography from '../../atoms/Typography/Typography';
import IconAlertError from '../../atoms/icons/IconAlertError';
import IconAlertSuccessNew from '../../atoms/icons/IconAlertSuccessNew';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

const AlertSnackbar = ({
  autoHideDuration = 5000,
  message,
  severity = 'info',
  onClose = () => {},
  children
}: AlertSnackbarProps) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (
    event?: Event | React.SyntheticEvent<any>,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      key={'top' + 'right'}
    >
      {severity === 'error' ? (
        <div className="alert-snackbar alert-snackbar--error">
          <IconAlertError />
          {children ? (
            children
          ) : (
            <Typography type="paragraph2" variation="mobile">
              {message}
            </Typography>
          )}
          {/* <Typography type="paragraph2" variation="mobile">
            {message}
          </Typography> */}
        </div>
      ) : severity === 'success' ? (
        <div className="alert-snackbar alert-snackbar--success">
          <IconAlertSuccessNew />
          {children ? (
            children
          ) : (
            <Typography type="paragraph2" variation="mobile">
              {message}
            </Typography>
          )}
        </div>
      ) : (
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      )}
    </Snackbar>
  );
};

export { AlertSnackbar };
