import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateOrganization,
  CreateOrganizationState
} from '../../../interfaces/organizations.interface';
import {
  getOrganization,
  getOrganizationById,
  postCreateOrganization,
  updateOrganization as updateOrganizationById
} from '../../../services/ctcb2b';
import { countriesB2B } from '../../../constants/countries';

export const addOrganization = createAsyncThunk(
  'organizations/addOrganization',
  async (
    organization: CreateOrganization,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      return await postCreateOrganization(organization);
    } catch (err) {
      toast.error('Ha ocurrido un error al crear la organización.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'organizations/updateOrganization',
  async (
    organizationInfo: { id: number; payload: CreateOrganization },
    { rejectWithValue }: any
  ) => {
    try {
      const { id, payload } = organizationInfo;
      return await updateOrganizationById(id, payload);
    } catch (err) {
      toast.error('Ha ocurrido un error al editar la organización.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getOrganizationFromApi = createAsyncThunk(
  'organizations/getAllOrganizations',
  async (
    organizationInfo: { page: number; size: number; filters?: any },
    { rejectWithValue }: any
  ) => {
    const { page, size, filters } = organizationInfo;
    try {
      const data = await getOrganization(page, size, filters);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getOrganizationByIdFromApi = createAsyncThunk(
  'organizations/getOrganizationById',
  async (organizationInfo: { id: number }, { rejectWithValue }: any) => {
    const { id } = organizationInfo;
    try {
      return await getOrganizationById(id);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: CreateOrganizationState = {
  organizations: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** addOrganization */
    builder.addCase(addOrganization.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addOrganization.fulfilled, (state, action) => {
      const country = countriesB2B.find(
        (country: { id: number; name: string }) =>
          country.id === action.payload.data.country_id
      );
      action.payload.data.country = country;

      state?.organizations?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addOrganization.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getAllOrganizations */
    builder.addCase(getOrganizationFromApi.pending, (state, _) => {
      state.organizations = initialState.organizations;
      state.isLoading = true;
    });
    builder.addCase(getOrganizationFromApi.fulfilled, (state, action) => {
      state.totalPages = action.payload.data.pages;
      state.totalCount = action.payload.data.total;
      state.organizations = action.payload.data.items;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getOrganizationFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getOrganizationById */
    builder.addCase(getOrganizationByIdFromApi.pending, (state, _) => {
      state.organizations = initialState.organizations;
      state.isLoading = true;
    });
    builder.addCase(getOrganizationByIdFromApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getOrganizationByIdFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** updateOrganization */
    builder.addCase(updateOrganization.pending, (state, _) => {
      state.organizations = initialState.organizations;
      state.isLoading = true;
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      const payload = action.payload;
      const organizations = state.organizations.filter(
        organization => organization.id !== payload.id
      );
      state.organizations = [...organizations, action.payload];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateOrganization.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateOrganizations = (state: any) => state.organizations;
