/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Button, Alert, AlertTitle } from '@material-ui/core';
import { Tab, TabButton } from 'ctc-component-library';

import './roomDetail.scss';
import {
  RootState,
  AppDispatch,
  useAppDispatch
} from '../../../../store/store';
import { Students } from './Students/Students';
import { Teachers } from './Teachers/Teachers';
import NavRoomDetail from './Nav/NavRoomDetail';
import {
  TEACHER_CTC,
  TEACHER_AUXILIARY
} from '../../../../constants/usersType.constants';
import Evaluations from './Evaluations/Evaluations';
import CommentsSection from './Comments/CommentsSection';
import RoomDetailHeader from './Header/RoomDetailHeader';
import useRoomDetail from '../../../../hooks/useRoomDetail';
import Link from '../../../../components/molecules/Link/Link';
import IconArrow from '../../../../components/atoms//icons/IconArrow';
import NoAccess from '../../../../components/organism/NoAccess/NoAccess';
import useRoomHeaderDetails from '../../../../hooks/useRoomHeaderDetails';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { setRoom } from '../../../../slices/roomDetailSlice/roomDetailSlice';
import { START_DATE_EVALUATIONS } from '../../../../constants/evaluationsDate.constants';

interface ITabsContent {
  label: string[];
  content: any;
}

function RoomDetailV2() {
  const { roomId } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const userRole = user?.permission?.name;
  const qtySessions = useRef({ value: 'partial', label: '± 3 sesiones' });
  const [hasAuxTeachers, setHasAuxTeachers] = useState<boolean>(false);
  const [activeTabMain, setActiveTabMain] = useState<number>(0);
  const [activeTabTeachers, setActiveTabTeachers] = useState<number>(0);
  const [activeTabStudents, setActiveTabStudents] = useState<number>(0);
  const [activeTabComments, setActiveTabComments] = useState<number>(0);
  const [tabsContentStudents, setTabsContentStudents] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [tabsContentTeachers, setTabsContentTeachers] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [tabsContentComments, setTabsContentComments] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [shouldRefetchDetailHeader, setShouldRefetchDetailHeader] =
    useState<boolean>(false);
  const [dateEvaluations, setDateEvaluations] = useState({});

  const refetchAllDetailRoom = () => {
    setShouldRefetch(!shouldRefetch);
    setShouldRefetchDetailHeader(!shouldRefetchDetailHeader);
  };

  const refetchDetailHeader = () => {
    setShouldRefetchDetailHeader(!shouldRefetchDetailHeader);
  };

  const {
    data: dataRoomHeader,
    isLoading: isLoadingHeader,
    isError: isErrorHeader,
    error: errorHeader
  } = useRoomHeaderDetails({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetchDetailHeader
  });

  const {
    data: dataRoom,
    isLoading,
    isError,
    error
  } = useRoomDetail({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetch
  });

  useEffect(() => {
    if (dataRoom) {
      dispatch(setRoom(dataRoom));
      if (START_DATE_EVALUATIONS !== '') {
        setDateEvaluations(
          moment(dataRoom?.date_session[0]?.start_date).format('YYYY-MM-DD') >=
            moment(START_DATE_EVALUATIONS).format('YYYY-MM-DD')
        );
      }

      setHasAuxTeachers(!!(dataRoom?.auxiliar_teacher.length > 0));

      const studentsLabels = ['Asistencias'];
      const studentsContents = [
        <Students
          key="tab-content-students"
          refetch={refetchAllDetailRoom}
          room={dataRoom}
          refetchDetailHeader={refetchDetailHeader}
          qtySessions={qtySessions}
        />
      ];

      let teachersLabels = ['Asistencia Crack the Code'];
      let teachersContents = [
        <Teachers
          setActiveTabMain={setActiveTabMain}
          setActiveTabTeachers={setActiveTabTeachers}
          key="tab-content-teachers-ctc"
          refetch={refetchAllDetailRoom}
          room={dataRoom}
          type="teacherCTC"
        />
      ];

      const commentsLabels = ['Apuntes'];
      const commentsContents = [
        <CommentsSection
          key="tab-content-comments"
          refetchRoomDetails={refetchAllDetailRoom}
          roomInfo={dataRoom}
          setActiveTabMain={setActiveTabMain}
        />
      ];

      if (dataRoom?.business_type !== 'B2B') {
        studentsLabels.push('Evaluaciones');
        studentsContents.push(
          <Evaluations
            key="tab-content-evaluations"
            refetch={refetchAllDetailRoom}
            room={dataRoom}
          />
        );
      } else {
        if (userRole !== TEACHER_AUXILIARY) {
          teachersLabels.push('Asistencia externos');
          teachersContents.push(
            <Teachers
              setActiveTabMain={setActiveTabMain}
              setActiveTabTeachers={setActiveTabTeachers}
              key="tab-content-teachers-aux"
              refetch={refetchAllDetailRoom}
              room={dataRoom}
              type="AuxiliaryTeacher"
            />
          );

          if (userRole === TEACHER_CTC && hasAuxTeachers) {
            setActiveTabTeachers(1);
          }
        } else {
          teachersLabels = ['Asistencia externos'];
          teachersContents = [
            <Teachers
              setActiveTabMain={setActiveTabMain}
              setActiveTabTeachers={setActiveTabTeachers}
              key="tab-content-teachers-aux"
              refetch={refetchAllDetailRoom}
              room={dataRoom}
              type="AuxiliaryTeacher"
            />
          ];
        }
      }

      setTabsContentStudents({
        label: studentsLabels,
        content: studentsContents
      });

      setTabsContentTeachers({
        label: teachersLabels,
        content: teachersContents
      });

      setTabsContentComments({
        label: commentsLabels,
        content: commentsContents
      });
    }
  }, [dataRoom]);

  if (isLoadingHeader) return <ScreenLoader />;
  if (
    isError &&
    error?.code === 403 &&
    (userRole === TEACHER_CTC || userRole === TEACHER_AUXILIARY)
  ) {
    return (
      <NoAccess
        button={{
          to: '/dashboard/rooms',
          message: 'Volver a salones'
        }}
        errorMessage="No tienes acceso a esta sección"
      />
    );
  }
  if (isError || !dataRoom) {
    return (
      <>
        <Link
          className="room-detail-header-v2__button-back"
          iconLeft={<IconArrow className="room-detail-header-v2__icon-back" />}
          to="/dashboard/rooms"
        >
          Regresar a Salones
        </Link>

        <Alert
          severity="error"
          variant="outlined"
          action={
            <Button color="inherit" size="small" onClick={refetchAllDetailRoom}>
              Reintentar
            </Button>
          }
        >
          <AlertTitle>Error</AlertTitle>
          No se pudo obtener la información del salón. Por favor reintente.
        </Alert>
      </>
    );
  }

  const onChangeTabButton = (tabIndex: number) => {
    if (tabIndex === 0) {
      setActiveTabStudents(0);
    } else if (tabIndex === 1) {
      if (userRole === TEACHER_CTC && hasAuxTeachers) {
        setActiveTabTeachers(1);
      } else {
        setActiveTabTeachers(0);
      }
    } else if (tabIndex === 2) {
      setActiveTabComments(0);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <NavRoomDetail dataRoomHeader={dataRoomHeader} />
      <div className="room-detail-v2__container">
        <RoomDetailHeader
          dataRoomHeader={dataRoomHeader}
          room={dataRoom}
          refetch={refetchAllDetailRoom}
          setActiveTabMain={setActiveTabMain}
          setActiveTabTeachers={setActiveTabTeachers}
        />
        <div className="room-detail-v2__body">
          <TabButton
            key={`main-tab-${activeTabMain}`}
            defaultActiveKey={activeTabMain}
            setDefaultActiveKey={setActiveTabMain}
            tabs={[
              {
                label: 'Estudiantes',
                content: (
                  <div style={{ width: '100%' }}>
                    {/* <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="body1">
                        <strong>Estudiantes</strong>
                      </Typography>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <Typography variant="body3">
                              Estudiantes Activos: <strong>-</strong>
                            </Typography>
                          </div>
                          <div
                            style={{ marginLeft: '5px', marginRight: '5px' }}
                          >
                            <Typography variant="body3">|</Typography>
                          </div>
                          <div>
                            <Typography variant="body3">
                              Riesgo: <strong>-</strong>
                            </Typography>
                          </div>
                          <div
                            style={{ marginLeft: '5px', marginRight: '5px' }}
                          >
                            <Typography variant="body3">|</Typography>
                          </div>
                          <div>
                            <Typography variant="body3">
                              Abandono: <strong>-</strong>
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br /> */}
                    <Tab
                      key={`students-tab-${activeTabStudents}`}
                      defaultActiveKey={activeTabStudents}
                      setDefaultActiveKey={setActiveTabStudents}
                      tabs={tabsContentStudents?.label.map((_, index) => ({
                        label: tabsContentStudents?.label[index],
                        content: tabsContentStudents?.content[index]
                      }))}
                    />
                  </div>
                )
              },
              {
                label: 'Profesores',
                content: (
                  <Tab
                    key={`teachers-tab-${activeTabTeachers}`}
                    defaultActiveKey={activeTabTeachers}
                    setDefaultActiveKey={setActiveTabTeachers}
                    tabs={tabsContentTeachers?.label.map((_, index) => ({
                      label: tabsContentTeachers?.label[index],
                      content: tabsContentTeachers?.content[index]
                    }))}
                  />
                )
              },
              {
                label: 'Comentarios',
                content: (
                  <Tab
                    key={`comments-tab-${activeTabComments}`}
                    defaultActiveKey={activeTabComments}
                    setDefaultActiveKey={setActiveTabComments}
                    tabs={tabsContentComments.label.map((_, index) => ({
                      label: tabsContentComments?.label[index],
                      content: tabsContentComments?.content[index]
                    }))}
                  />
                )
              }
            ]}
            onChange={onChangeTabButton}
          />
        </div>
      </div>
    </div>
  );
}

export default RoomDetailV2;
