import React, { useEffect, useRef, useState } from 'react';

import './selectFunctional.scss';
import colors from '../../atoms/Colors';
import IconArrowUp from '../../atoms/icons/IconArrowUp';
import DropdownListTeacher from '../DropdownListTeacher';
import Typography from '../../atoms/Typography/Typography';
import IconArrowDown from '../../atoms/icons/IconArrowDown';
import DropdownDetailTeacher from '../DropdownDetailTeacher';
import { getTeacherProfileByIdV2 } from '../../../services/teachers';

interface Props {
  isOpen: any;
  onClickOutside?: any;
  setIsOpen: any;
  options?: any;
  className?: string;
  size?: any;
  disabled?: any;
  defaultValue?: any;
  onclickDefaultValue: Function;
  identifierSort: string;
  alert: boolean;
  setAlert: any;
  teacherProfile: any;
  setTeacherProfile: any;
  teacherName?: string;
  setTeacherName: any;
}

const SelectFunctional: React.FC<Props> = ({
  isOpen,
  onClickOutside,
  setIsOpen,
  size = 'default',
  className,
  disabled,
  options,
  onclickDefaultValue,
  identifierSort,
  alert = false,
  setAlert,
  teacherProfile,
  setTeacherProfile,
  teacherName,
  setTeacherName
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [dropdownComponent, setDropdownComponent] = useState<any>(null);
  const [value, setValue] = useState();
  const dataSort = Array.isArray(options) ? [...options] : options;
  const [teacherFiltered, setTeacherFiltered] = useState<Array<any>>(dataSort);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  const onClickHandler = (option: any): void => {
    onclickDefaultValue(option);
    setValue(option);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (value) {
      setAlert(false);
    }
  }, [value]);

  useEffect(() => {
    setDropdownComponent('listTeacher');
  }, []);

  const onClickDropdown = async (id: number) => {
    const response = await getTeacherProfileByIdV2(Number(id));
    if (response) {
      setTeacherProfile(response);
    }
    setDropdownComponent('detailTeacher');
  };

  useEffect(() => {
    if (teacherName) {
      const listFiltered = dataSort.filter(
        (teacher: any) =>
          teacher?.full_name.toLowerCase().match(teacherName.toLowerCase()) !==
          null
      );
      setTeacherFiltered(listFiltered);
    } else {
      setTeacherFiltered(dataSort);
    }
  }, [teacherName, options]);

  return (
    <div className="select-functional">
      <div
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
            setDropdownComponent('listTeacher');
          }
        }}
        className={`select-functional__container ${
          disabled ? 'select-functional__disabled' : 'select-functional__active'
        } ${alert ? 'select-functional__alert' : ''} ${className ? className : ''}`}
      >
        <input
          placeholder="Elige al profesor ideal"
          className="select-functional__input"
          type="text"
          value={teacherName}
          disabled={disabled}
          onChange={e => setTeacherName(e.target.value)}
        />
        {disabled ? (
          <div
            className={`select-functional__icon ${
              isOpen
                ? 'select-functional--active'
                : 'select-functional--inactive'
            } select-functional__icon--${size} ${
              disabled ? 'select-functional__icon--disabled' : ''
            }`}
          >
            <IconArrowDown fill={colors.gris03} />
          </div>
        ) : (
          <div
            className={`select-functional__icon ${
              isOpen
                ? 'select-functional--active'
                : 'select-functional--inactive'
            } select-functional__icon--${size}`}
          >
            {isOpen ? <IconArrowUp /> : <IconArrowDown />}
          </div>
        )}
      </div>
      {isOpen && (
        <div ref={ref} className="select-functional__dropdown">
          {dropdownComponent === 'listTeacher' ? (
            <DropdownListTeacher
              dataSort={teacherFiltered}
              identifierSort={identifierSort}
              onClickHandler={onClickHandler}
              onClickDropdown={onClickDropdown}
              teacherInfo={teacherProfile}
            />
          ) : (
            <DropdownDetailTeacher
              setDropdownComponent={setDropdownComponent}
              setIsOpen={setIsOpen}
              teacherInfo={teacherProfile}
              onClickHandler={onClickHandler}
            />
          )}
        </div>
      )}
      {alert && (
        <Typography
          type="paragraph2"
          variation="mobile"
          className="select-functional__alert-message"
          color={colors.warningColor}
        >
          Seleccione un profesor
        </Typography>
      )}
    </div>
  );
};

export default SelectFunctional;
