import { useState } from 'react';

import './modalEditProjectId.scss';
import Modal from '../../../../../components/Modal/Modal';
import Input from '../../../../../components/molecules/Input';
import { AppDispatch, useAppDispatch } from '../../../../../store/store';
import ButtonFilled from '../../../../../components/molecules/ButtonFilled';
import ButtonOutline from '../../../../../components/molecules/ButtonOutline';
import Typography from '../../../../../components/atoms/Typography/Typography';
import IconEditPencil from '../../../../../components/atoms/icons/IconEditPencil';
import { updateStudentProjectId } from '../../../../../slices/groupSlice/groupsSlice';

interface Props {
  projectId?: number;
  student: any;
  autoOpen?: boolean;
  groupId: number;
  setRefetchListRoomsByGroupId: any;
}

const ModalEditProjectId: React.FC<Props> = ({
  projectId,
  student,
  groupId,
  setRefetchListRoomsByGroupId,
  autoOpen = false
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [newProjectId, setNewProjectId] = useState(projectId);
  const [openModal, setOpenModal] = useState(autoOpen);
  const shouldDisable = !projectId && !newProjectId;

  const handleOpenModal = () => {
    setNewProjectId(projectId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setNewProjectId(projectId);
    setOpenModal(false);
  };

  const handleChange = (e: any) => {
    setNewProjectId(e.target.value);
  };

  const handleConfirm = async (e: any) => {
    try {
      await dispatch(
        updateStudentProjectId({
          groupId,
          studentId: student?.id,
          projectId: newProjectId || null
        })
      );
      setRefetchListRoomsByGroupId((prev: boolean) => !prev);
      handleCloseModal();
    } catch (error) {
      handleCloseModal();
      console.log(error);
    }
  };

  return (
    <>
      {!autoOpen && (
        <>
          <button
            style={{
              border: '0px',
              backgroundColor: 'inherit',
              cursor: 'pointer'
            }}
            onClick={handleOpenModal}
          >
            <IconEditPencil width="14" height="16" fill={'#313131'} />
          </button>
        </>
      )}

      <Modal
        title={`Agregar project ID a ${student?.full_name}`}
        dividers={false}
        fullWidth
        maxWidth="sm"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <>
          <div className="modal-add-edit-project-id__container-body">
            <section>
              <Typography
                className="modal-add-edit-project-id__label-habilities"
                bold
                type="paragraph2"
                variation="mobile"
              >
                Project ID
              </Typography>
            </section>
            <section className="modal-add-edit-project-id__habilities">
              <Input
                placeholder="Agregar project ID del estudiante (si aplica)"
                name="projectId"
                onChange={handleChange}
                value={newProjectId}
                required
              />
            </section>
          </div>
          <div className="modal-add-edit-project-id__container-buttons">
            <ButtonOutline
              type="reset"
              size="default"
              width="8rem"
              onClick={handleCloseModal}
            >
              Cancelar
            </ButtonOutline>
            <ButtonFilled
              disabled={shouldDisable}
              size="default"
              width="8rem"
              onClick={handleConfirm}
            >
              Confirmar
            </ButtonFilled>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ModalEditProjectId;
