import React, { useEffect, useRef, useState } from 'react';

// Colors
import colors from '../../atoms/Colors';

// Components
import IconArrowDown from '../../atoms/icons/IconArrowDown';
import Typography from '../../atoms/Typography/Typography';
import IconArrowUp from '../../atoms/icons/IconArrowUp';
import SelectOptions from '../SelectOptions';
import ItemFloatingMenu from '../ItemFloatingMenu';

import './selectFilter.scss';

interface Props {
  isOpen?: any;
  onClickOutside?: any;
  setIsOpen?: any;
  options?: any;
  className?: string;
  size?: any;
  disabled?: any;
  defaultValue?: any;
  onclickDefaultValue: Function;
  identifier?: string;
  alert?: boolean;
  valueToFilter?: string;
  placeholder?: string;
  setValueToFilter: any;
  itemsDropdown?: number;
  dividerItems?: boolean;
  sort?: boolean;
  onClickItem?: any;
}

const SelectFilter: React.FC<Props> = ({
  isOpen = false,
  onClickOutside,
  setIsOpen,
  size = 'default',
  placeholder = 'Ingrese nombre',
  className,
  disabled,
  options,
  onclickDefaultValue,
  identifier = 'name',
  alert = false,
  valueToFilter,
  setValueToFilter,
  itemsDropdown = 8,
  dividerItems = false,
  sort = false,
  onClickItem
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isOpenSelect, setIsOpenSelect] = useState(isOpen);
  const [value, setValue] = useState();
  const [alertMessage, setAlertMessage] = useState(alert);

  const dataSort = Array.isArray(options) ? [...options] : options;
  const [dataFiltered, setDataFiltered] = useState<Array<any>>(dataSort);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpenSelect(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onClickHandler = (option: any, index: number): void => {
    onclickDefaultValue(option, index);
    setValue(option);
    setIsOpenSelect(!isOpenSelect);
    if (onClickItem) {
      onClickItem(option, index);
    }
  };

  useEffect(() => {
    if (dataSort) {
      setDataFiltered(dataSort);
    }
  }, [options]);

  useEffect(() => {
    if (value) {
      setAlertMessage(false);
    }
  }, [value]);

  useEffect(() => {
    if (valueToFilter) {
      const listFiltered = dataSort.filter(
        (list: any) =>
          list?.[identifier]
            .toLowerCase()
            .match(valueToFilter.toLowerCase()) !== null
      );
      setDataFiltered(listFiltered);
    } else {
      setDataFiltered(dataSort);
    }
  }, [valueToFilter]);

  return (
    <div className="select-filter">
      <div
        onClick={() => {
          if (!disabled) {
            setIsOpenSelect(!isOpenSelect);
          }
        }}
        className={`select-filter__container ${
          disabled ? 'select-filter__disabled' : 'select-filter__active'
        } ${alertMessage ? 'select-filter__alert' : ''} ${className ? className : ''}`}
      >
        <input
          placeholder={placeholder}
          className={`select-filter__input select-filter__input--${
            disabled ? 'disabled' : 'active'
          }`}
          disabled={disabled}
          type="text"
          value={valueToFilter}
          onChange={e => setValueToFilter(e.target.value)}
        />
        {disabled ? (
          <div
            className={`select-filter__icon ${
              isOpenSelect ? 'select-filter--active' : 'select-filter--inactive'
            } select-filter__icon--${size} ${
              disabled ? 'select-filter__icon--disabled' : ''
            }`}
          >
            <IconArrowDown fill={colors.gris03} />
          </div>
        ) : (
          <div
            className={`select-filter__icon ${
              isOpenSelect ? 'select-filter--active' : 'select-filter--inactive'
            } select-filter__icon--${size}`}
          >
            {isOpenSelect ? <IconArrowUp /> : <IconArrowDown />}
          </div>
        )}
      </div>
      {isOpenSelect && (
        <div ref={ref} className="select-filter__dropdown">
          <SelectOptions
            isScroll={dataFiltered?.length > itemsDropdown ? true : false}
            size={size}
            itemsDropdown={itemsDropdown}
            options={
              sort
                ? dataFiltered
                    ?.sort((a: any, b: any) =>
                      a?.[identifier] > b?.[identifier] ? 1 : -1
                    )
                    .map((option: any, index: number) => (
                      <ItemFloatingMenu
                        dividerItems={dividerItems}
                        key={option?.id}
                        onClick={() => onClickHandler(option, index)}
                      >
                        {option?.[identifier]}
                      </ItemFloatingMenu>
                    ))
                : dataFiltered?.map((option: any, index: number) => (
                    <ItemFloatingMenu
                      dividerItems={dividerItems}
                      key={option?.id}
                      onClick={() => onClickHandler(option, index)}
                    >
                      {option?.[identifier]}
                    </ItemFloatingMenu>
                  ))
            }
          />
        </div>
      )}
      {alertMessage && (
        <Typography
          type="paragraph2"
          variation="mobile"
          className="select-filter__alert-message"
          color={colors.warningColor}
        >
          Añadir nombre o ID
        </Typography>
      )}
    </div>
  );
};

export default SelectFilter;
