import React, { useState } from 'react';

import './footerPagination.scss';
import IconArrowPagination from '../../atoms/icons/IconArrowPagination';

interface Props {
  children?: any;
  currentPage?: any;
  totalRows?: any;
  totalPages?: any;
  paginate: Function | undefined;
  rowsInPage?: any;
  pageSize: number;
  dataTestid?: string;
}

const FooterPagination: React.FC<Props> = ({
  children,
  currentPage,
  rowsInPage,
  totalRows,
  totalPages,
  paginate,
  pageSize,
  dataTestid = 'footer-pagination-test-id'
}) => {
  const [originalValue, setOriginalValue] = useState(currentPage);
  const [userDefinedValue, setUserDefinedValue] = useState(currentPage);

  const updateNumber = (value: number): void => {
    if (paginate && 0 < value && value <= totalPages) {
      value = Number(value);
      setOriginalValue(value);
      setUserDefinedValue(value);
      paginate(value);
    } else {
      setUserDefinedValue(originalValue);
    }
  };

  const handleChange = (event: any) => {
    setUserDefinedValue(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      updateNumber(userDefinedValue);
    }
  };

  const handleBlur = (event: any) => {
    updateNumber(userDefinedValue);
  };

  const fromPage = currentPage > 1 ? (currentPage - 1) * pageSize + 1 : 1;
  const toPage = fromPage + rowsInPage - 1;

  return (
    <div className="footer-pagination">
      <div className="footer-pagination__icon-container">
        <button
          data-testid={`${dataTestid}-button-left`}
          onClick={() => {
            updateNumber(currentPage - 1);
          }}
          className={`footer-pagination__icon ${
            currentPage === 1 ? 'footer-pagination__icon--disabled' : ''
          } ${
            currentPage > 0
              ? 'footer-pagination__icon--hover-color'
              : 'footer-pagination__icon--disabled'
          }`}
        >
          <IconArrowPagination
            className="footer-pagination__icon-left"
            fill={`${currentPage > 1 ? '#313131' : '#BCBCBC'}`}
          />
        </button>

        <span className="footer-pagination__number-page">
          <input
            data-testid={`${dataTestid}-input`}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onChange={handleChange}
            className="footer-pagination__textbox"
            type="number"
            min={1}
            max={totalPages}
            value={userDefinedValue}
            name="page"
          />
          <p className="footer-pagination__text-number">/</p>
          <p
            className="footer-pagination__text-number"
            data-testid={`${dataTestid}-text-number`}
          >
            {totalPages}
          </p>
        </span>
        <button
          data-testid={`${dataTestid}-button-rigth`}
          onClick={() => {
            updateNumber(currentPage + 1);
          }}
          className={`footer-pagination__icon ${
            currentPage === totalPages
              ? 'footer-pagination__icon--disabled'
              : 'footer-pagination__icon--hover-color'
          }`}
        >
          <IconArrowPagination
            className="footer-pagination__icon-right"
            fill={`${currentPage === totalPages ? '#BCBCBC' : '#313131'}`}
          />
        </button>
      </div>
      <div
        className="footer-pagination__text"
        data-testid={`${dataTestid}-text-result`}
      >
        {`Resultados: ${fromPage} - ${toPage} de ${totalRows}`}
      </div>
    </div>
  );
};

export default FooterPagination;
