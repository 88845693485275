/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// Moment
import moment from 'moment';

// Components
import { toast } from 'react-hot-toast';
import { LinearProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import Modal from '../../../components/atoms/Modal';
import Typography from '../../../components/atoms/Typography/Typography';
import Input from '../../../components/molecules/Input';
import ModalMessageAlert from '../../../components/molecules/ModalMessageAlert';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import IconCalendar from '../../../components/atoms/icons/IconCalendar';
import colors from '../../../components/atoms/Colors';

// Hot Toast

// Hooks
import { useModal } from '../../../hooks/useModal';
import {
  IDiscountDetailInitialData,
  IDiscountPostData
} from '../../../interfaces/discounts.interface';
import { patchDiscounts, postDiscounts } from '../../../services/discounts';

import './modalCreateDiscount.scss';

interface Props {
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  courses: any;
  paths: any;
  discountEdit?: any;
  flagCreate: boolean;
  setFlagCreate: Function;
  refetch: () => {};
}

const ModalCreateDiscount: React.FC<Props> = ({
  isOpenModal,
  openModal,
  closeModal,
  courses,
  paths,
  discountEdit,
  flagCreate,
  setFlagCreate,
  refetch
}) => {
  const today = moment();
  const {
    openModal: openModalAlertCreateDiscount,
    closeModal: closeModalAlertCreateDiscount,
    isOpenModal: isOpenModalAlertCreateDiscount
  } = useModal();
  const [alertCourse, setAlertCourse] = useState(false);
  const [errorCreateDiscount, setErrorCreateDiscount] = useState(false);

  const [checkedCountries, setCheckedCountries] = useState<any[]>([]);
  const [checkedCourses, setCheckedCourses] = useState<any[]>([]);
  const [checkedPaths, setCheckedPaths] = useState<any[]>([]);

  const [newCountries, setNewCountries] = useState<any[]>([]);
  const [newProducts, setNewProducts] = useState<any[]>([]);

  const [currencyList, setCurrencyList] = useState<any[]>([]);
  const [courseList, setCourseList] = useState<any[]>([]);
  const [pathList, setPathList] = useState<any[]>([]);

  const [isPath, setIsPath] = useState(false);
  const [isCourse, setIsCourse] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const countries = [
    { id: 1, name: 'Perú', currency: 'PEN' },
    { id: 2, name: 'Colombia', currency: 'COP' },
    { id: 3, name: 'México', currency: 'MXN' },
    { id: 4, name: 'Resto de países', currency: 'USD' }
  ];

  const handleOpcionChangeCountry = (idx: number, country: any) => {
    const isChecked = checkedCountries.some(s => s.id === country.id);
    setCurrencyList([]);
    if (isChecked) {
      setCheckedCountries(checkedCountries.filter(s => s.id !== country.id));
    } else {
      setCheckedCountries([...checkedCountries, country]);
    }
  };

  const handleOpcionChangeCourses = (idx: number, course: any) => {
    const isChecked = checkedCourses.some(s => s.id === course.id);
    setCourseList([]);
    if (isChecked) {
      setCheckedCourses(checkedCourses.filter(s => s.id !== course.id));
    } else {
      setCheckedCourses([...checkedCourses, course]);
    }
  };

  const handleCheckAllCourses = () => {
    const isChecked = courses.length === checkedCourses.length;
    if (isChecked) {
      setCheckedCourses([]);
    } else {
      setCheckedCourses([...courses]);
    }
  };

  const handleOpcionChangePaths = (idx: number, path: any) => {
    const isChecked = checkedPaths.some(s => s.id === path.id);
    setPathList([]);
    if (isChecked) {
      setCheckedPaths(checkedPaths.filter(s => s.id !== path.id));
    } else {
      setCheckedPaths([...checkedPaths, path]);
    }
  };

  const handleCheckAllPaths = () => {
    const isChecked = paths.length === checkedPaths.length;
    if (isChecked) {
      setCheckedPaths([]);
    } else {
      setCheckedPaths([...paths]);
    }
  };

  const handleClear = () => {
    setCheckedCountries([]);
    setCheckedCourses([]);
    setCheckedPaths([]);
    setCourseList([]);
    setPathList([]);
    setNewCountries([]);
    setNewProducts([]);
    setFlagCreate(false);
    setIsEdit(false);
  };

  const readDiscount = () => {
    const productType = discountEdit.product_type;
    setIsEdit(true);

    if (productType === 'learning_path') {
      setIsPath(true);
      setIsCourse(false);
    }
    if (productType === 'course') {
      setIsCourse(true);
      setIsPath(false);
    }

    let products = discountEdit?.products_discount;
    const hashProducts: any = {};
    products = products.filter((o: any) =>
      hashProducts[o.product] ? false : (hashProducts[o.product] = true)
    );

    let countries = discountEdit?.products_discount;
    const hashCountries: any = {};
    countries = countries.filter((o: any) =>
      hashCountries[o.currency] ? false : (hashCountries[o.currency] = true)
    );

    countries.map((country: any, idx: any) => {
      switch (country.currency) {
        case 'PEN':
          newCountries[0] = { id: 1, name: 'Perú', currency: country.currency };
          break;
        case 'COP':
          newCountries[1] = {
            id: 2,
            name: 'Colombia',
            currency: country.currency
          };
          break;
        case 'MXN':
          newCountries[2] = {
            id: 3,
            name: 'México',
            currency: country.currency
          };
          break;
        case 'USD':
          newCountries[3] = {
            id: 4,
            name: 'Resto de países',
            currency: country.currency
          };
          break;
        default:
          break;
      }
    });

    products.map((product: any, idx: any) => {
      newProducts[idx] = { id: product.product, name: product.name_product };
    });

    setCheckedCountries(newCountries);

    switch (productType) {
      case 'course':
        setCheckedCourses(newProducts);
        break;
      case 'learning_path':
        setCheckedPaths(newProducts);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (checkedCountries.length > 0) {
      checkedCountries.map((country, idx) => {
        currencyList.splice(idx, 1, country.currency);
      });
    }
  }, [checkedCountries]);

  useEffect(() => {
    if (checkedPaths.length > 0) {
      setIsPath(true);
      checkedPaths.map((path, idx) => {
        pathList.splice(idx, 1, path.id);
      });
    } else {
      setIsPath(false);
    }
  }, [checkedPaths]);

  useEffect(() => {
    if (checkedCourses.length > 0) {
      setIsCourse(true);
      checkedCourses.map((course, idx) => {
        courseList.splice(idx, 1, course.id);
      });
    } else {
      setIsCourse(false);
    }
  }, [checkedCourses]);

  useEffect(() => {
    if (!flagCreate) {
      if (isOpenModal && discountEdit !== undefined) {
        readDiscount();
      }
    }
  }, [isOpenModal]);

  const createDiscount = async (newDiscount: IDiscountPostData) => {
    try {
      const response = await postDiscounts(newDiscount);
      if (response?.status === 200) {
        toast.success('¡Se creo correctamente es descuento!');
        setFlagCreate(false);
        setErrorCreateDiscount(false);
        refetch();
      } else {
        toast.error('No se pudo crear el descuento');
      }
    } catch (error) {
      toast.error('No se pudo crear el descuento');
      setErrorCreateDiscount(true);
      setFlagCreate(false);
    }
  };

  const updateDiscount = async (id: any, editDiscount: IDiscountPostData) => {
    try {
      const response = await patchDiscounts(id, editDiscount);
      if (response?.status === 200) {
        toast.success('¡Se actualizo correctamente es descuento!');
        setFlagCreate(false);
        setErrorCreateDiscount(false);
        refetch();
      } else {
        toast.error('No se pudo actualizar el descuento');
      }
    } catch (error) {
      toast.error('No se pudo actualizar el descuento');
      setErrorCreateDiscount(true);
      setFlagCreate(false);
    }
  };

  const DiscountSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Muy pequeño!')
      .max(500, 'Mucho texto!')
      .required('Requerido'),
    discount_percentage: Yup.string().required('Requerido'),
    start_date: Yup.string().required('Requerido'),
    end_date: Yup.string().required('Requerido'),
    product_list: Yup.array().required().min(1),
    currency_list: Yup.array().required().min(1)
  });

  const initialValues: IDiscountDetailInitialData = {
    name: flagCreate ? '' : discountEdit?.name,
    product_type: flagCreate ? '' : discountEdit?.product_type,
    discount_percentage: flagCreate ? '' : discountEdit?.discount_percentage,
    start_date: flagCreate
      ? ''
      : discountEdit?.start_date && moment(discountEdit?.start_date),
    end_date: flagCreate
      ? ''
      : discountEdit?.end_date && moment(discountEdit?.end_date),
    product_list: discountEdit?.product_list,
    currency_list: discountEdit?.currency_list
  };

  if (!courses) return <LinearProgress />;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={DiscountSchema}
        onSubmit={async (values, { resetForm }) => {
          const data = {
            name: values.name,
            product_type: isPath ? 'learning_path' : 'course',
            discount_percentage: Number(values.discount_percentage),
            start_date: new Date(
              moment(values.start_date).format('YYYY-MM-DD')
            ),
            end_date: new Date(moment(values.end_date).format('YYYY-MM-DD')),
            product_list: values.product_list,
            currency_list: values.currency_list
          };

          if (flagCreate) {
            await createDiscount(data!);
          } else {
            await updateDiscount(discountEdit.id, data!);
          }

          closeModal();
          resetForm();
          handleClear();
        }}
      >
        {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
          <Form>
            <Modal
              width="xxxl"
              isOpen={isOpenModal}
              closeModal={closeModal}
              title="Crear Descuento"
              body={
                <div className="modal-create-discount__content-body">
                  <div className="modal-create-discount__information">
                    <Typography bold type="paragraph2" variation="mobile">
                      Nombre del descuento
                    </Typography>
                    <Input
                      placeholder="Dale un nombre al nuevo descuento"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      required
                      error={errors.name && touched.name ? errors.name : ''}
                    />
                    <Typography bold type="paragraph2" variation="mobile">
                      Desde
                    </Typography>
                    <div className="modal-create-discount__start-day">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="modal-create-discount__discount-group">
                          <DatePicker
                            format="dd-MM-yyyy"
                            value={values.start_date}
                            onChange={(value: any) =>
                              setFieldValue('start_date', value)
                            }
                            sx={{
                              input: {
                                padding: 0,
                                paddingLeft: '15px',
                                height: '40px'
                              },
                              '.MuiOutlinedInput-root': { borderRadius: '8px' },
                              fieldset: { border: '1px solid black' }
                            }}
                            slots={{ openPickerIcon: IconCalendar }}
                          />
                          {errors.start_date && touched.start_date && (
                            <Typography
                              type="paragraph2"
                              variation="mobile"
                              className="modal-create-discount__alert"
                              color={colors.warningColor}
                            >
                              {errors.start_date}
                            </Typography>
                          )}
                        </div>
                        <div className="modal-create-discount__end-day">
                          <Typography bold type="paragraph2" variation="mobile">
                            Hasta
                          </Typography>
                          <div className="modal-create-discount__discount-group">
                            <DatePicker
                              format="dd-MM-yyyy"
                              value={values.end_date}
                              minDate={values.start_date || new Date()}
                              onChange={(value: any) =>
                                setFieldValue('end_date', value)
                              }
                              sx={{
                                input: {
                                  padding: 0,
                                  paddingLeft: '15px',
                                  height: '40px'
                                },
                                '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                fieldset: { border: '1px solid black' }
                              }}
                              slots={{ openPickerIcon: IconCalendar }}
                            />
                            {errors.end_date && touched.end_date && (
                              <Typography
                                type="paragraph2"
                                variation="mobile"
                                className="modal-create-group__alert"
                                color={colors.warningColor}
                              >
                                {errors.end_date}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </LocalizationProvider>
                    </div>
                    <Typography bold type="paragraph2" variation="mobile">
                      Descuento
                    </Typography>
                    <div className="modal-create-discount__start-day">
                      <div className="modal-create-discount__discount-group">
                        <Input
                          placeholder="Ingresa el porcentaje..."
                          name="discount_percentage"
                          onChange={handleChange}
                          value={values.discount_percentage}
                          required
                          error={
                            errors.discount_percentage &&
                            touched.discount_percentage
                              ? errors.discount_percentage
                              : ''
                          }
                        />
                      </div>
                      <div className="modal-create-discount__end-day">
                        <Typography bold type="paragraph2" variation="mobile">
                          País
                        </Typography>
                        <div className="modal-create-discount__discount-group">
                          {countries?.map((country: any, idx: any) => (
                            <div
                              className="modal-create-discount__item"
                              key={idx}
                            >
                              <input
                                type="checkbox"
                                disabled={isEdit && !flagCreate}
                                className="modal-create-discount__input-check"
                                checked={checkedCountries.some(
                                  s => s.id === country.id
                                )}
                                onChange={() =>
                                  handleOpcionChangeCountry(idx, country)
                                }
                              />
                              <label
                                style={{
                                  color:
                                    isEdit && !flagCreate
                                      ? colors.gris03
                                      : colors.black
                                }}
                              >
                                {' '}
                                {country.name}{' '}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <Typography bold type="paragraph2" variation="mobile">
                      Cursos Sueltos
                    </Typography>
                    <section
                      className={`
                                 modal-create-discount__container-courses 
                                 ${
                                   isEdit && !flagCreate
                                     ? 'modal-create-discount__container-disabled'
                                     : isPath
                                       ? 'modal-create-discount__container-disabled'
                                       : ''
                                 }`}
                    >
                      <>
                        <div className="modal-create-discount__item">
                          <input
                            type="checkbox"
                            disabled={
                              isEdit && !flagCreate
                                ? true
                                : isPath
                                  ? true
                                  : false
                            }
                            className="modal-create-discount__input-check"
                            checked={courses.length === checkedCourses.length}
                            onChange={() => handleCheckAllCourses()}
                          />
                          <label
                            style={{
                              color:
                                isEdit && !flagCreate
                                  ? colors.gris03
                                  : isPath
                                    ? colors.gris03
                                    : colors.black
                            }}
                          >
                            Enviar a todos los cursos
                          </label>
                        </div>
                        {courses?.length > 0
                          ? courses?.map((course: any, idx: any) => (
                              <div
                                className="modal-create-discount__item"
                                key={idx}
                              >
                                <input
                                  type="checkbox"
                                  disabled={
                                    isEdit && !flagCreate
                                      ? true
                                      : isPath
                                        ? true
                                        : false
                                  }
                                  className="modal-create-discount__input-check"
                                  checked={checkedCourses.some(
                                    s => s.id === course.id
                                  )}
                                  onChange={() =>
                                    handleOpcionChangeCourses(idx, course)
                                  }
                                />
                                <label
                                  style={{
                                    color:
                                      isEdit && !flagCreate
                                        ? colors.gris03
                                        : isPath
                                          ? colors.gris03
                                          : colors.black
                                  }}
                                >
                                  {' '}
                                  {course.name}{' '}
                                </label>
                              </div>
                            ))
                          : ''}
                      </>
                    </section>
                    <Typography bold type="paragraph2" variation="mobile">
                      Rutas de aprendizaje
                    </Typography>
                    <section
                      className={`
                                 modal-create-discount__container-courses 
                                 ${
                                   isEdit && !flagCreate
                                     ? 'modal-create-discount__container-disabled'
                                     : isCourse
                                       ? 'modal-create-discount__container-disabled'
                                       : ''
                                 }`}
                    >
                      <>
                        <div className="modal-create-discount__item">
                          <input
                            type="checkbox"
                            disabled={
                              isEdit && !flagCreate
                                ? true
                                : isCourse
                                  ? true
                                  : false
                            }
                            className="modal-create-discount__input-check"
                            checked={paths.length === checkedPaths.length}
                            onChange={() => handleCheckAllPaths()}
                          />
                          <label
                            style={{
                              color:
                                isEdit && !flagCreate
                                  ? colors.gris03
                                  : isCourse
                                    ? colors.gris03
                                    : colors.black
                            }}
                          >
                            Enviar a todos las rutas
                          </label>
                        </div>
                        {paths?.length > 0
                          ? paths?.map((path: any, idx: any) => (
                              <div
                                className="modal-create-discount__item"
                                key={idx}
                              >
                                <input
                                  type="checkbox"
                                  disabled={
                                    isEdit && !flagCreate
                                      ? true
                                      : isCourse
                                        ? true
                                        : false
                                  }
                                  className="modal-create-discount__input-check"
                                  checked={checkedPaths.some(
                                    s => s.id === path.id
                                  )}
                                  onChange={() =>
                                    handleOpcionChangePaths(idx, path)
                                  }
                                />
                                <label
                                  style={{
                                    color:
                                      isEdit && !flagCreate
                                        ? colors.gris03
                                        : isCourse
                                          ? colors.gris03
                                          : colors.black
                                  }}
                                >
                                  {' '}
                                  {path.name}{' '}
                                </label>
                              </div>
                            ))
                          : ''}
                      </>
                    </section>
                  </div>

                  <section className="modal-create-discount__container-buttons">
                    <ButtonOutline
                      type="reset"
                      size="default"
                      //disabled={isLoadingCreateGroup}
                      onClick={() => {
                        handleClear();
                        closeModal();
                        //refetch();
                        //window.location.reload();
                      }}
                    >
                      Cancelar
                    </ButtonOutline>
                    <ButtonFilled
                      type="submit"
                      size="default"
                      //disabled={isLoadingCreateGroup}
                      onClick={() => {
                        setFieldValue(
                          'product_list',
                          isPath ? pathList : courseList
                        );
                        setFieldValue('currency_list', currencyList);
                      }}
                    >
                      {!flagCreate ? 'Guardar' : 'Crear'}
                    </ButtonFilled>
                  </section>
                </div>
              }
            ></Modal>
          </Form>
        )}
      </Formik>
      {errorCreateDiscount && (
        <ModalMessageAlert
          width="xs"
          title={
            <Typography bold type="paragraph1" variation="desktop">
              Algo salió mal...
            </Typography>
          }
          message="Parece que has añadido un valor incorrecto o que ya expiró. Revisa y corrije el espacio marcado en rojo para continuar."
          isOpenModal={isOpenModalAlertCreateDiscount}
          openModal={openModalAlertCreateDiscount}
          closeModal={closeModalAlertCreateDiscount}
          button1={
            <ButtonFilled
              size="default"
              onClick={closeModalAlertCreateDiscount}
            >
              Aceptar
            </ButtonFilled>
          }
        />
      )}
    </div>
  );
};

export default ModalCreateDiscount;
