// Material UI
import { Box } from '@mui/system';
import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

const CustomQuickSearchToolbar: React.FC<Props> = ({
  value,
  onChange,
  clearSearch
}) => {
  return (
    <Box sx={{ padding: '8px 12px' }}>
      <TextField
        fullWidth
        variant="outlined"
        value={value}
        onChange={onChange}
        placeholder="Buscar..."
        InputProps={{
          startAdornment: (
            <SearchIcon fontSize="small" sx={{ marginRight: '10px' }} />
          ),
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }}
      />
    </Box>
  );
};

export default CustomQuickSearchToolbar;
