import './roomsLayout.scss';

interface Props {
  nextClass: any;
  table: any;
}

const RoomsLayout: React.FC<Props> = ({ nextClass, table }) => {
  return (
    <main
      className={`rooms-layout rooms-layout--${
        nextClass ? 'partial-height' : 'full-height'
      }`}
    >
      {nextClass && <div className="rooms-layout__next-class">{nextClass}</div>}
      <div className="rooms-layout__table">{table}</div>
    </main>
  );
};

export default RoomsLayout;
