import { Avatar, Typography } from '@material-ui/core';

import colors from '../../atoms/Colors';

// Interfaces
interface EvaluationIndicatorProps {
  message: string;
  status: string;
}

const EvaluationIndicator = ({ message, status }: EvaluationIndicatorProps) => {
  return (
    <Avatar
      variant="square"
      sx={{
        bgcolor: 'transparent',
        color:
          status === 'Logrado'
            ? colors.colorStatusStarted
            : status === 'En Proceso'
              ? colors.colorStatusToStart
              : colors.colorStatusFinished,
        height: 24,
        width: '100%'
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'Sofia Pro',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 14
        }}
      >
        {message}
      </Typography>
    </Avatar>
  );
};

export default EvaluationIndicator;
