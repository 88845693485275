import { LinearProgress } from '@mui/material';

import Typography from '../Typography/Typography';

import './modal.scss';
interface Props {
  title: any;
  subtitle?: any;
  body: any;
  buttons?: any;
  icon?: any;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxl' | 'xxxl';
  isOpen: boolean;
  closeModal: () => void;
  className?: string;
  align?: 'center' | 'left';
  isLoading?: boolean;
}

const Modal: React.FC<Props> = ({
  title,
  subtitle,
  body,
  buttons,
  isOpen = false,
  width,
  icon,
  closeModal,
  className = '',
  align = 'left',
  isLoading = false
}) => {
  return (
    <div className={`modal modal--${isOpen ? 'open' : 'close'}`}>
      <section
        className={`modal__content modal__content--${width} ${className}`}
      >
        {isLoading && (
          <div>
            <LinearProgress />
          </div>
        )}
        <div className={`modal__container`}>
          <div
            className={`modal__section-title modal__section-title--align-${align} modal__section-title${
              icon ? '--center' : '--left'
            }`}
          >
            {icon && <div className="modal__container-icon">{icon}</div>}
            {typeof title === 'string' ? (
              <Typography align={align} type="h2" variation="mobile" bold>
                {title}
              </Typography>
            ) : (
              title
            )}
            {subtitle ? (
              <Typography align={align} type="h2" variation="mobile" bold>
                {subtitle}
              </Typography>
            ) : null}
          </div>
          {body && <div className="modal__body">{body}</div>}
          <div className="modal__buttons">{buttons}</div>
        </div>
      </section>
    </div>
  );
};

export default Modal;
