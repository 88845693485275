// Material UI
import { Grid } from '@mui/material';

// Interfaces
import { ICourseProjectInfo } from '../../../interfaces/course.interface';
import CourseProjectDetails from './CourseProjectDetails';
import CourseProjectImages from './CourseProjectImages';
import CourseProjecsBenefits from './CourseProjecsBenefits';

interface Props {
  projectInfo: ICourseProjectInfo;
  courseId: number;
  refetch: any;
  refetchCourse: any;
}

const CourseProject: React.FC<Props> = ({
  projectInfo,
  courseId,
  refetch,
  refetchCourse
}) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={2} sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <CourseProjectDetails
          projectId={projectInfo.project_id}
          projectTitle={projectInfo.name}
          projectDescription={projectInfo.description}
          courseId={courseId}
          refetch={refetch}
          refetchCourse={refetchCourse}
        />
      </Grid>

      <Grid item xs={12}>
        <CourseProjecsBenefits
          benefits={projectInfo.benefits}
          courseId={courseId}
          refetch={refetch}
          refetchCourse={refetchCourse}
        />
      </Grid>

      <Grid item xs={12}>
        <CourseProjectImages
          sliders={projectInfo.sliders}
          courseId={courseId}
          refetch={refetch}
          refetchCourse={refetchCourse}
        />
      </Grid>
    </Grid>
  );
};

export default CourseProject;
