import axios from 'axios';

import { getAccessCookie } from '../../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

export const getListOverdueReasons = async () => {
  const token = getAccessCookie();
  return await axios.get(
    `${instanceBaseURL}/catalog/reasons-overdue-content/`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const getListOverdueContentReports = async (
  page: number,
  size: number,
  status: 'all' | 'pending' | 'done',
  filters?: any
) => {
  const token = getAccessCookie();
  const params = new URLSearchParams();

  if (filters?.id !== undefined && filters?.id !== '') {
    params.append('id', filters.id.toString());
  }

  if (filters?.room_name !== undefined && filters?.room_name !== '') {
    params.append('room_name', filters.room_name.toString());
  }

  return await axios.get(
    `${instanceBaseURL}/overdue-content/report-list/?page=${page}&size=${size}${
      status === 'all' ? '' : `&status=${status}`
    }${params.toString() ? `&${params.toString()}` : ''}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const getHasPendingReport = async (roomId: number) => {
  const token = getAccessCookie();
  return await axios.get(
    `${instanceBaseURL}/overdue-content/has-pending-report/${roomId}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const postOverdueContentReport = async ({
  roomId,
  count,
  reasonId
}: {
  roomId: number;
  count: string;
  reasonId: string | number;
}) => {
  const token = getAccessCookie();
  const response = await axios.post(
    `${instanceBaseURL}/overdue-content/report/${roomId}/`,
    { count, reason_id: reasonId },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};

export const patchStatusOverdueContentReport = async (
  reportId: number,
  status: string
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/overdue-content/report/${reportId}/update-status/`,
    { status: status },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
