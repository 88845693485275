// React
import React from 'react';
import ReactDOM from 'react-dom';

// Redux
import { Provider } from 'react-redux';

// React Query
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';

// Toasts
import { Toaster } from 'react-hot-toast';

// Styles
import './scss/globals.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import ThemeStyle from './config/ThemeProvider';

// Components
import App from './App';
import ConfirmDialog from './components/ConfirmDialog/ConfirmDialog';

//Sentry

import store from './store/store';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const SENTRY_DNS = process.env.REACT_APP_SENTRY_DSN;

if (SENTRY_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [new BrowserTracing()],
    environment: SENTRY_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeStyle>
          <App />
          <ConfirmDialog />
          <ReactQueryDevtools />
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 5000,
              error: {
                duration: 8000,
                icon: '🚨'
              }
            }}
          />
        </ThemeStyle>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
