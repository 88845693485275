// React Query

// API

// Interfaces

// Material UI
import { Grid, LinearProgress } from '@mui/material';

// Components
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { getInfoByLearningPathId } from '../../../../../services/learnings';
import { ILearningPathProjectInfo } from '../../../../../interfaces/learningPaths.interface';
import LearningPathProject from '../../../../../components/LearningPaths/LearningPathProject/LearningPathProject';

interface Props {
  refetch: () => {} /*  list all learnings*/;
  learningPathId: number;
}

const Project: React.FC<Props> = ({ refetch, learningPathId }) => {
  const [projectInfo, setProjectInfo] = useState<ILearningPathProjectInfo>();
  const [isErrorProjectInfo, setIsErrorProjectInfo] = useState<boolean>(false);
  const [isLoadingProjectInfo, setIsLoadingProjectInfo] =
    useState<boolean>(false);
  const [errorProjectInfo, setErrorProjectInfo] = useState<AxiosError>();

  useEffect(() => {
    infoIndividualLearningPathById(learningPathId);
  }, []);

  const infoIndividualLearningPathById = async (learningPathId: number) => {
    setIsLoadingProjectInfo(true);
    try {
      const { data } = await getInfoByLearningPathId(learningPathId);
      setProjectInfo(data.learning_path_final_project_detail);
      setIsLoadingProjectInfo(false);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorProjectInfo(true);
        setIsLoadingProjectInfo(false);
        setErrorProjectInfo(error.response?.data);
      }
    }
  };

  if (isLoadingProjectInfo || !projectInfo) return <LinearProgress />;
  if (isErrorProjectInfo)
    return <div>Error! {JSON.stringify(errorProjectInfo)}</div>;

  return (
    <Grid container rowSpacing={2} alignItems="flex-start">
      {/* Main */}
      <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
        <LearningPathProject
          projectInfo={projectInfo}
          learningPathId={learningPathId}
          refetch={refetch}
          refetchLearningPath={infoIndividualLearningPathById}
        />
      </Grid>
    </Grid>
  );
};

export default Project;
