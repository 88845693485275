import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import './ProjectDetail.scss';
import { useModal } from '../../../hooks/useModal';
import Link from '../../../components/molecules/Link';
import useProjectById from '../../../hooks/useProjectById';
import IconArrowUp from '../../../components/atoms/icons/IconArrowUp';
import ButtonLineal from '../../../components/molecules/ButtonLineal';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import Typography from '../../../components/atoms/Typography/Typography';
import IconArrowDown from '../../../components/atoms/icons/IconArrowDown';
import IconArrowLeft from '../../../components/atoms/icons/IconArrowLeft';
import IconEditPencil from '../../../components/atoms/icons/IconEditPencil';
import TagProjectsStatus from '../../../components/molecules/TagProjectsStatus';
import ModalCreateProject from '../../../pages/CTCB2B/Projects/ModalCreateProject';

const ProjectDetail: React.FC<{}> = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const [showData, setShowData] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const { data, isLoading } = useProjectById({
    projectId: Number(projectId),
    shouldRefetch: shouldRefresh
  });
  const {
    openModal: openModalCreateProject,
    closeModal: closeModalCreateProject,
    isOpenModal: isOpenModalCreateProject
  } = useModal();

  const handleClick = (projectId: string) => {
    params.set('projectId', projectId);
    navigate(`/dashboard/rooms?${params.toString()}`, { replace: true });
  };

  if (isLoading) return <ScreenLoader />;

  const getShowDataLabel = !showData ? 'Ver más' : 'Ver menos';

  return (
    <>
      <div className="projects-detail">
        <Link className="" to={`/dashboard/ctcb2b/`}>
          <IconArrowLeft width="20" height="22" />

          <Typography type="paragraph2" variation="mobile" bold={true}>
            Regresar a Proyectos
          </Typography>
        </Link>
        <h1>{data?.name}</h1>
        <div className="projects-detail__tag">
          <TagProjectsStatus status={data?.status} />
        </div>
        <div className="projects-detail__body">
          <div style={{ display: 'flex' }}>
            <div className="projects-detail__body--column">
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    ID del proyecto
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {projectId}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Organización
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.organization_name}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Owner operativo
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.operative_owner}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Owner académico
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.academic_owner}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Vacantes vendidas
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.seat_sold}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Número de salones
                  </Typography>
                </div>
                <div>
                  <ButtonLineal
                    className="projects-detail__button-room"
                    onClick={
                      projectId
                        ? () => handleClick(projectId?.toString())
                        : () => {}
                    }
                  >
                    {data?.rooms}
                  </ButtonLineal>
                </div>
              </div>
            </div>
            <div className="projects-detail__body--column">
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Acuerdo ganado
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.winning_date}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Inicio operativo
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.operative_start_date}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Inicio de alianza
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.alliance_start_date}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Fin operativo
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.operative_end_date}
                  </Typography>
                </div>
              </div>
              <div className="projects-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Fin de alianza
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.alliance_end_date}
                  </Typography>
                </div>
              </div>
              {showData && (
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Link a carpeta
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.folder_link}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
          {showData && (
            <div style={{ display: 'flex' }}>
              <div className="projects-detail__body--column">
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Número de estudiantes
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.students}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Monto USD
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.amount_usd}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Productos
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.has_bootcamp ? 'Bootcamp ' : ' '}
                      {data?.has_course ? 'Curso ' : ' '}
                      {data?.has_game_jam ? 'Game jam ' : ' '}
                      {data?.has_hackaton ? 'Hackaton ' : ' '}
                      {data?.has_other ? 'Otro ' : ' '}
                      {data?.has_personalization ? 'Perzonalizado ' : ' '}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      País/es
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.countries?.map((country: any) => {
                        return `${country.name} `;
                      })}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Tipo de proyecto
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.type}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Tipo de operación
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.operation_type}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Modalidad
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.mode}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Nivel de complejidad
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.complex_level}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="projects-detail__body--column">
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Descripción del proyecto
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.description}
                    </Typography>
                  </div>
                </div>
                <div className="projects-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Comentarios adicionales
                    </Typography>
                  </div>
                  <div>
                    <Typography type="paragraph2" variation="mobile">
                      {data?.comment}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="projects-detail__body--buttons">
            <ButtonOutline
              className="projects-detail__body--buttons--outline"
              type="button"
              size="small"
              onClick={openModalCreateProject}
            >
              <Typography type="paragraph2" variation="mobile" bold={true}>
                Editar
              </Typography>

              <IconEditPencil width="14" height="16" />
            </ButtonOutline>
            <ButtonLineal
              size="small"
              className="projects-detail__body--buttons--lineal"
              onClick={() => {
                setShowData((prev: boolean) => !prev);
              }}
            >
              <Typography type="paragraph2" variation="mobile" bold={true}>
                {getShowDataLabel}
              </Typography>
              {showData ? (
                <IconArrowUp
                  dataTestid={`icon-up-project-detail`}
                  width="18"
                  height="16"
                />
              ) : (
                <IconArrowDown
                  dataTestid={`icon-down-project-detail`}
                  width="18"
                  height="16"
                />
              )}
            </ButtonLineal>
          </div>
        </div>
      </div>
      {isOpenModalCreateProject && (
        <ModalCreateProject
          setShouldRefresh={setShouldRefresh}
          isOpenModal={isOpenModalCreateProject}
          openModal={closeModalCreateProject}
          closeModal={closeModalCreateProject}
          isEdit={true}
          data={data}
          projectId={Number(projectId)}
        />
      )}
    </>
  );
};

export default ProjectDetail;
