import React from 'react';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

const IconInfo: React.FC<Props> = ({
  className,
  fill = '#FF2C2C',
  dataTestid = 'icon-info',
  width = '13',
  height = '13',
  viewBox = '0 0 13 13'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.85 9.75H7.15V5.85H5.85V9.75ZM6.5 4.55C6.68417 4.55 6.83854 4.48771 6.96313 4.36312C7.08771 4.23854 7.15 4.08417 7.15 3.9C7.15 3.71583 7.08771 3.56146 6.96313 3.43687C6.83854 3.31229 6.68417 3.25 6.5 3.25C6.31583 3.25 6.16146 3.31229 6.03688 3.43687C5.91229 3.56146 5.85 3.71583 5.85 3.9C5.85 4.08417 5.91229 4.23854 6.03688 4.36312C6.16146 4.48771 6.31583 4.55 6.5 4.55ZM6.5 13C5.60083 13 4.75583 12.8294 3.965 12.4881C3.17417 12.1469 2.48625 11.6838 1.90125 11.0988C1.31625 10.5138 0.853125 9.82583 0.511875 9.035C0.170625 8.24417 0 7.39917 0 6.5C0 5.60083 0.170625 4.75583 0.511875 3.965C0.853125 3.17417 1.31625 2.48625 1.90125 1.90125C2.48625 1.31625 3.17417 0.853125 3.965 0.511875C4.75583 0.170625 5.60083 0 6.5 0C7.39917 0 8.24417 0.170625 9.035 0.511875C9.82583 0.853125 10.5138 1.31625 11.0988 1.90125C11.6838 2.48625 12.1469 3.17417 12.4881 3.965C12.8294 4.75583 13 5.60083 13 6.5C13 7.39917 12.8294 8.24417 12.4881 9.035C12.1469 9.82583 11.6838 10.5138 11.0988 11.0988C10.5138 11.6838 9.82583 12.1469 9.035 12.4881C8.24417 12.8294 7.39917 13 6.5 13ZM6.5 11.7C7.95167 11.7 9.18125 11.1962 10.1887 10.1887C11.1962 9.18125 11.7 7.95167 11.7 6.5C11.7 5.04833 11.1962 3.81875 10.1887 2.81125C9.18125 1.80375 7.95167 1.3 6.5 1.3C5.04833 1.3 3.81875 1.80375 2.81125 2.81125C1.80375 3.81875 1.3 5.04833 1.3 6.5C1.3 7.95167 1.80375 9.18125 2.81125 10.1887C3.81875 11.1962 5.04833 11.7 6.5 11.7Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconInfo;
