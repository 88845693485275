import React, { useState, useEffect } from 'react';

// Hot Toast
import { toast } from 'react-hot-toast';

// Axios
import { AxiosError } from 'axios';

// Formik
import { Formik, Form } from 'formik';

import Modal from '../../atoms/Modal';
import Typography from '../../atoms/Typography/Typography';
import Input from '../../molecules/Input';
import ButtonOutline from '../../molecules/ButtonOutline';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonLineal from '../../molecules/ButtonLineal';
import SelectComp from '../../molecules/SelectComp';
import SelectFilter from '../../molecules/SelectFilter';
import ModalMessageAlert from '../../molecules/ModalMessageAlert';
import IconCopy from '../../atoms/icons/IconCopy';
import IconAlertSuccess from '../../atoms/icons/IconAlertSuccess';

// Hooks
import { useModal } from '../../../hooks/useModal';

// Colors
import colors from '../../atoms/Colors';

// Services
import { postResaleStudentCourses } from '../../../services/resale';

// Interfaces
import {
  ICoursesResale,
  IResaleStudentCourse,
  IStudentDetailResale,
  IStudentResale
} from '../../../interfaces/resale.interface';

import './modalCustomUrl.scss';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  program: string;
  groupId: number;
  listStudent?: IStudentResale[];
  resaleDetails?: IStudentDetailResale[];
  setListStudent: any;
}

const ModalCustomUrl: React.FC<Props> = ({
  isOpen,
  closeModal,
  program,
  groupId,
  resaleDetails,
  listStudent,
  setListStudent
}) => {
  const {
    openModal: openModalAlertUpdResale,
    closeModal: closeModalAlertUpdResale,
    isOpenModal: isOpenModalAlertUpdResale
  } = useModal();
  const [valueProgram, setValueProgram] = useState<string>(program);

  const [listCoursesByStudent1, setListCoursesByStudent1] =
    useState<ICoursesResale[]>();
  const [listCoursesByStudent2, setListCoursesByStudent2] =
    useState<ICoursesResale[]>();
  const [listCoursesByStudent3, setListCoursesByStudent3] =
    useState<ICoursesResale[]>();
  const [listCoursesByStudent4, setListCoursesByStudent4] =
    useState<ICoursesResale[]>();
  const [listCoursesByStudent5, setListCoursesByStudent5] =
    useState<ICoursesResale[]>();
  const [listCoursesByStudent6, setListCoursesByStudent6] =
    useState<ICoursesResale[]>();
  const [listCoursesByStudent7, setListCoursesByStudent7] =
    useState<ICoursesResale[]>();

  const [idStudent, setIdStudent] = useState<number | null>(null);
  const [studentToFilter, setStudentToFilter] = useState('');
  const [counter, setCounter] = useState(0);

  const [selectedStudent, setSelectedStudent] = useState<any>();
  const [selectedCourse1, setSelectedCourse1] = useState<any>();
  const [selectedCourse2, setSelectedCourse2] = useState<any>();
  const [selectedCourse3, setSelectedCourse3] = useState<any>();
  const [selectedCourse4, setSelectedCourse4] = useState<any>();
  const [selectedCourse5, setSelectedCourse5] = useState<any>();
  const [selectedCourse6, setSelectedCourse6] = useState<any>();
  const [tokenResale, setTokenResale] = useState<string>('');

  useEffect(() => {
    /* setListStudent([]); */
    handleClearForm();
  }, [groupId]);

  useEffect(() => {
    const courses = resaleDetails
      ? resaleDetails.find((resale: any) => resale.student.id === idStudent)
          ?.courses_to_re_sale
      : [];
    setListCoursesByStudent1(courses);
  }, [idStudent, groupId]);

  const updateResale = async (resaleDetails: IResaleStudentCourse) => {
    if (resaleDetails.student) {
      if (resaleDetails.courses.length > 0) {
        try {
          const { data, status } =
            await postResaleStudentCourses(resaleDetails);
          if (status === 200 && data) {
            setTokenResale(data.code);
            openModalAlertUpdResale();
            closeModal();
          }
        } catch (err) {
          const error: AxiosError = err as AxiosError;
          if (error.response?.data?.message) {
            toast.error(error.response?.data?.message); //2202 - 2186
          } else {
            toast.error('Error interno');
          }
        }
      } else {
        toast.error('debes seleccionar un curso');
      }
    } else {
      toast.error('debes seleccionar un estudiante');
    }
  };

  const handleClickStudent = (option: any, index: number): void => {
    setSelectedStudent(option);

    setIdStudent(option.id);
    setStudentToFilter(option.full_name);
    setSelectedCourse1(null);
    setSelectedCourse2(null);
    setSelectedCourse3(null);
    setSelectedCourse4(null);
    setSelectedCourse5(null);
    setSelectedCourse6(null);
  };
  const handleClickCourse1 = (option: any): void => {
    setSelectedCourse1(option);

    const listCourses = listCoursesByStudent1
      ? listCoursesByStudent1
          .filter((courses: any) => courses.id !== option.id)
          .map((courses: any) => courses)
      : [];
    setListCoursesByStudent2(listCourses);

    setSelectedCourse2(null);
    setSelectedCourse3(null);
    setSelectedCourse4(null);
    setSelectedCourse5(null);
    setSelectedCourse6(null);
  };
  const handleClickCourse2 = (option: any): void => {
    setSelectedCourse2(option);

    const listCourses = listCoursesByStudent2
      ? listCoursesByStudent2
          .filter((courses: any) => courses.id !== option.id)
          .map((courses: any) => courses)
      : [];
    setListCoursesByStudent3(listCourses);

    setSelectedCourse3(null);
    setSelectedCourse4(null);
    setSelectedCourse5(null);
    setSelectedCourse6(null);
  };
  const handleClickCourse3 = (option: any): void => {
    setSelectedCourse3(option);

    const listCourses = listCoursesByStudent3
      ? listCoursesByStudent3
          .filter((courses: any) => courses.id !== option.id)
          .map((courses: any) => courses)
      : [];
    setListCoursesByStudent4(listCourses);

    setSelectedCourse4(null);
    setSelectedCourse5(null);
    setSelectedCourse6(null);
  };
  const handleClickCourse4 = (option: any): void => {
    setSelectedCourse4(option);

    const listCourses = listCoursesByStudent4
      ? listCoursesByStudent4
          .filter((courses: any) => courses.id !== option.id)
          .map((courses: any) => courses)
      : [];
    setListCoursesByStudent5(listCourses);

    setSelectedCourse5(null);
    setSelectedCourse6(null);
  };
  const handleClickCourse5 = (option: any): void => {
    setSelectedCourse5(option);

    const listCourses = listCoursesByStudent5
      ? listCoursesByStudent5
          .filter((courses: any) => courses.id !== option.id)
          .map((courses: any) => courses)
      : [];
    setListCoursesByStudent6(listCourses);

    setSelectedCourse6(null);
  };
  const handleClickCourse6 = (option: any): void => {
    setSelectedCourse6(option);

    const listCourses = listCoursesByStudent6
      ? listCoursesByStudent6
          .filter((courses: any) => courses.id !== option.id)
          .map((courses: any) => courses)
      : [];
    setListCoursesByStudent7(listCourses);
  };

  const handleClearForm = (): void => {
    setStudentToFilter('');
    setSelectedStudent(null);
    setSelectedCourse1(null);
    setSelectedCourse2(null);
    setSelectedCourse3(null);
    setSelectedCourse4(null);
    setSelectedCourse5(null);
    setSelectedCourse6(null);
    setValueProgram('');
    setCounter(0);
  };

  return (
    <>
      <Formik
        initialValues={{
          program: valueProgram,
          courses: []
        }}
        /* validationSchema={TeacherProfileSchema} */
        onSubmit={async (values, { resetForm }) => {
          /* openModalAlertUpdResale(); */
          const listCourses = [];
          if (counter >= 0 && selectedCourse1.id) {
            listCourses.push(selectedCourse1.id);
            if (counter >= 1 && selectedCourse2.id) {
              listCourses.push(selectedCourse2.id);
              if (counter >= 2 && selectedCourse3.id) {
                listCourses.push(selectedCourse3.id);
                if (counter >= 3 && selectedCourse4.id) {
                  listCourses.push(selectedCourse4.id);
                  if (counter >= 4 && selectedCourse5.id) {
                    listCourses.push(selectedCourse5.id);
                    if (counter >= 5 && selectedCourse6.id) {
                      listCourses.push(selectedCourse6.id);
                    }
                  }
                }
              }
            }
          }
          const data = {
            student: selectedStudent.id || 0,
            courses: listCourses
          };

          await updateResale(data);
        }}
      >
        {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
          <Form>
            <Modal
              className="modal-custom-url"
              width="xl"
              isOpen={isOpen}
              closeModal={closeModal}
              title="Link de reventa personalizado"
              isLoading={!listStudent ? true : false}
              body={
                <div className="modal-custom-url__container-body">
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="teacher-profile__typography"
                  >
                    Ruta
                  </Typography>
                  <Input
                    placeholder="Correo"
                    name="program"
                    value={program}
                    disabled
                  />
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="teacher-profile__typography"
                  >
                    Estudiante
                  </Typography>
                  <SelectFilter
                    sort
                    size="default"
                    placeholder="Añadir nombre o ID"
                    onclickDefaultValue={handleClickStudent}
                    identifier="full_name"
                    options={listStudent}
                    disabled={!listStudent || listStudent.length < 1}
                    valueToFilter={studentToFilter}
                    setValueToFilter={setStudentToFilter}
                  />
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="teacher-profile__typography"
                  >
                    Cursos
                  </Typography>
                  <div className="modal-custom-url__container-courses">
                    <SelectComp
                      size="small"
                      onclickDefaultValue={handleClickCourse1}
                      options={listCoursesByStudent1}
                      disabled={
                        !listStudent ||
                        listStudent.length < 1 ||
                        !listCoursesByStudent1 ||
                        listCoursesByStudent1.length < 1 ||
                        !studentToFilter
                      }
                      dividerItems
                      value={selectedCourse1}
                      placeholder="Curso"
                    />
                    {counter >= 1 && (
                      <SelectComp
                        size="small"
                        onclickDefaultValue={handleClickCourse2}
                        options={listCoursesByStudent2}
                        disabled={
                          !listCoursesByStudent2 ||
                          listCoursesByStudent2.length < 1
                        }
                        dividerItems
                        value={selectedCourse2}
                        placeholder="Curso"
                      />
                    )}
                    {counter >= 2 && (
                      <SelectComp
                        size="small"
                        onclickDefaultValue={handleClickCourse3}
                        options={listCoursesByStudent3}
                        disabled={
                          !listCoursesByStudent3 ||
                          listCoursesByStudent3.length < 1
                        }
                        dividerItems
                        value={selectedCourse3}
                        placeholder="Curso"
                      />
                    )}
                    {counter >= 3 && (
                      <SelectComp
                        size="small"
                        onclickDefaultValue={handleClickCourse4}
                        options={listCoursesByStudent4}
                        disabled={
                          !listCoursesByStudent4 ||
                          listCoursesByStudent4.length < 1
                        }
                        dividerItems
                        value={selectedCourse4}
                        placeholder="Curso"
                      />
                    )}
                    {counter >= 4 && (
                      <SelectComp
                        size="small"
                        onclickDefaultValue={handleClickCourse5}
                        options={listCoursesByStudent5}
                        disabled={
                          !listCoursesByStudent5 ||
                          listCoursesByStudent5.length < 1
                        }
                        dividerItems
                        value={selectedCourse5}
                        placeholder="Curso"
                      />
                    )}
                    {counter >= 5 && (
                      <SelectComp
                        size="small"
                        onclickDefaultValue={handleClickCourse6}
                        options={listCoursesByStudent6}
                        disabled={
                          !listCoursesByStudent6 ||
                          listCoursesByStudent6.length < 1
                        }
                        dividerItems
                        value={selectedCourse6}
                        placeholder="Curso"
                      />
                    )}
                    <ButtonOutline
                      size="small"
                      fullWidth
                      disabled={
                        !listCoursesByStudent1 ||
                        counter + 1 >= listCoursesByStudent1.length
                      }
                      onClick={() => {
                        if (
                          listCoursesByStudent1 &&
                          listCoursesByStudent1?.length - 1 > counter
                        ) {
                          setCounter(counter + 1);
                        }
                      }}
                    >
                      + Añadir curso
                    </ButtonOutline>
                  </div>
                </div>
              }
              buttons={
                <>
                  <ButtonOutline
                    type="reset"
                    size="default"
                    onClick={() => {
                      setListStudent([]);
                      handleClearForm();
                      closeModal();
                    }}
                  >
                    Cancelar
                  </ButtonOutline>
                  <ButtonFilled type="submit" size="default">
                    Generar link
                  </ButtonFilled>
                </>
              }
            />
          </Form>
        )}
      </Formik>

      <ModalMessageAlert
        className="modal-custom-url-alert"
        width="sm"
        iconAlert={<IconAlertSuccess />}
        title={
          <Typography bold type="paragraph1" variation="desktop">
            ¡Link generado!
          </Typography>
        }
        body={
          <div className="modal-custom-url modal-custom-url__section-alert-body">
            <Typography bold type="paragraph2" variation="mobile">
              Copia el siguiente link:
            </Typography>
            <section className="modal-custom-url modal-custom-url__section-link">
              <div className="modal-custom-url modal-custom-url__token">
                <Typography bold type="paragraph2" variation="mobile">
                  {`crackthecode.la/checkout?hash=${tokenResale}`}
                </Typography>
              </div>
              <ButtonLineal
                className="modal-custom-url modal-custom-url__button-padding"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `crackthecode.la/checkout?hash=${tokenResale}`
                  );
                  toast.success('Link copiado');
                }}
                iconLeft={<IconCopy fill={colors.gris05} />}
              />
            </section>
          </div>
        }
        message="Parece que has añadido un valor incorrecto o que ya expiró. Revisa y corrije el espacio marcado en rojo para continuar."
        isOpenModal={isOpenModalAlertUpdResale}
        openModal={openModalAlertUpdResale}
        closeModal={closeModalAlertUpdResale}
        button1={
          <ButtonOutline
            type="reset"
            size="default"
            width="8rem"
            onClick={() => {
              closeModalAlertUpdResale();
              setListStudent([]);
              handleClearForm();
            }}
          >
            Cancelar
          </ButtonOutline>
        }
        button2={
          <ButtonFilled
            size="default"
            width="8rem"
            onClick={() => {
              closeModalAlertUpdResale();
              navigator.clipboard.writeText(
                `crackthecode.la/checkout?hash=${tokenResale}`
              );
              toast.success('Link copiado');
              setListStudent([]);
              handleClearForm();
            }}
          >
            Copiar
          </ButtonFilled>
        }
      />
    </>
  );
};

export default ModalCustomUrl;
