// React Query

// API

// Interfaces
import { Grid, LinearProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { ICourseProjectInfo } from '../../../../../interfaces/course.interface';

// Material UI

// Components
import CourseProject from '../../../../../components/Courses/CourseProject/CourseProject';
import { getInfoByCourseId } from '../../../../../services/courses';

interface Props {
  refetch: () => {} /*  list all courses*/;
  courseId: number;
}

const Project: React.FC<Props> = ({ refetch, courseId }) => {
  const [projectInfo, setProjectInfo] = useState<ICourseProjectInfo>();
  const [isErrorProjectInfo, setIsErrorProjectInfo] = useState<boolean>(false);
  const [isLoadingProjectInfo, setIsLoadingProjectInfo] =
    useState<boolean>(false);
  const [errorProjectInfo, setErrorProjectInfo] = useState<AxiosError>();

  useEffect(() => {
    infoIndividualCourseById(courseId);
  }, []);

  const infoIndividualCourseById = async (courseId: number) => {
    setIsLoadingProjectInfo(true);
    try {
      const { data } = await getInfoByCourseId(courseId);
      setProjectInfo(data.course_final_project_detail);
      setIsLoadingProjectInfo(false);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorProjectInfo(true);
        setIsLoadingProjectInfo(false);
        setErrorProjectInfo(error.response?.data);
      }
    }
  };

  if (isLoadingProjectInfo || !projectInfo) return <LinearProgress />;
  if (isErrorProjectInfo)
    return <div>Error! {JSON.stringify(errorProjectInfo)}</div>;

  return (
    <Grid container rowSpacing={2} alignItems="flex-start">
      {/* Main */}
      <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
        <CourseProject
          projectInfo={projectInfo}
          courseId={courseId}
          refetch={refetch}
          refetchCourse={infoIndividualCourseById}
        />
      </Grid>
    </Grid>
  );
};

export default Project;
