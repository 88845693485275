import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getCoursesTrainedFromApi } from '../slices/teacherSlice/teacherSlice';

const useGetCoursesTrained = ({shouldRefetch}: {
  shouldRefetch?: boolean;
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getCoursesTrained = async () => {
    try {
      return await dispatch(getCoursesTrainedFromApi({}));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCoursesTrained();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching courses trained:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  return { data, isLoading };
};

export default useGetCoursesTrained;
