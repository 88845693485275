import './discountsLayout.scss';

interface Props {
  table: any;
  buttonAdd: any;
}

const DiscountsLayout: React.FC<Props> = ({ table, buttonAdd }) => {
  return (
    <main className={`discounts-layout rooms-layout`}>
      <div className="discounts-layout__button-add">{buttonAdd}</div>
      <div className="discounts-layout__table">{table}</div>
    </main>
  );
};

export default DiscountsLayout;
