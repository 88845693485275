import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateProject,
  CreateProjectState
} from '../../../interfaces/projects.interface';
import {
  getProject,
  getProjectById,
  updateProject as updateProjectById,
  postCreateProject
} from '../../../services/ctcb2b';

export const addProject = createAsyncThunk(
  'projects/addProject',
  async (
    project: CreateProject,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      return await postCreateProject(project);
    } catch (err) {
      toast.error('Ha ocurrido un error al crear el proyecto.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (
    project: { id: number; payload: CreateProject },
    { rejectWithValue }: any
  ) => {
    try {
      const { id, payload } = project;
      return await updateProjectById(id, payload);
    } catch (err) {
      toast.error('Ha ocurrido un error al editar el proyecto.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getProjectFromApi = createAsyncThunk(
  'projects/getAllProjects',
  async (
    projectInfo: { page: number; size: number; filters?: any },
    { rejectWithValue }: any
  ) => {
    const { page, size, filters } = projectInfo;
    try {
      const data = await getProject(page, size, filters);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getProjectByIdFromApi = createAsyncThunk(
  'projects/getProjectById',
  async (projectInfo: { id: number }, { rejectWithValue }: any) => {
    const { id } = projectInfo;
    try {
      return await getProjectById(id);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: CreateProjectState = {
  projects: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** addProject */
    builder.addCase(addProject.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addProject.fulfilled, (state, action) => {
      state?.projects?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addProject.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getAllProjects */
    builder.addCase(getProjectFromApi.pending, (state, _) => {
      state.projects = initialState.projects;
      state.isLoading = true;
    });
    builder.addCase(getProjectFromApi.fulfilled, (state, action) => {
      state.totalPages = action.payload.data.pages;
      state.totalCount = action.payload.data.total;
      state.projects = action.payload.data.items;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getProjectFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getProjectById */
    builder.addCase(getProjectByIdFromApi.pending, (state, _) => {
      state.projects = initialState.projects;
      state.isLoading = true;
    });
    builder.addCase(getProjectByIdFromApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getProjectByIdFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** updateProject */
    builder.addCase(updateProject.pending, (state, _) => {
      state.projects = initialState.projects;
      state.isLoading = true;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      const payload = action.payload;
      const projects = state.projects.filter(
        project => project.id !== payload.id
      );
      state.projects = [...projects, action.payload];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateProject.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateProjects = (state: any) => state.projects;
