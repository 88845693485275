import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
 useState, useEffect, forwardRef, useImperativeHandle 
} from 'react';

import {
  addExternalTeacher,
  getExternalTeacherByEmailFromApi
} from '../../../slices/teacherSlice/teacherSlice';
import { useModal } from '../../../hooks/useModal';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonLineal from '../../molecules/ButtonLineal';
import { useDebounce } from '../../../hooks/useDebounce';
import ModalAddAssistantTeacher from '../ModalAddAssistantTeacher';
import { patchDeleteAuxiliarTeacher } from '../../../services/teachers';
import { IRoomDetailSimplified } from '../../../interfaces/room.interface';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import ModalDeleteAssistantTeacher from '../../../components/molecules/ModalInactiveStudent/ModalInactiveStudent';

interface Props {
  roomInfo: IRoomDetailSimplified;
  refetchDetailRoom: () => void;
  externalTeachers: any;
  setExternalTeachers: any;
  setActiveTabMain: React.Dispatch<React.SetStateAction<number>>;
  setActiveTabTeachers: React.Dispatch<React.SetStateAction<number>>;
}

interface ICreateExternalTeacherInitialValues {
  name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface AuxTeacherActionsModalHandle {
  getExternalTeacher: (params: { email: string }) => void;
  setInitialValuesExternalTeacher: () => void;
}

const AuxTeacherActionsModal = forwardRef<any, Props>((props, ref) => {
  const {
    roomInfo,
    refetchDetailRoom,
    externalTeachers,
    setExternalTeachers,
    setActiveTabMain,
    setActiveTabTeachers
  } = props;
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [disabledInputs, setDisabledInputs] = useState<boolean>(false);
  const externalTeacherInitialValues: ICreateExternalTeacherInitialValues = {
    name: '',
    last_name: '',
    email: '',
    password: ''
  };
  const [externalTeacher, setExternalTeacher] = useState<any>(
    externalTeacherInitialValues
  );
  const [externalTeacherFromApi, setExternalTeacherFromApi] = useState<any>();

  const {
    openModal: openModalAddAuxiliaryTeacher,
    closeModal: closeModalAddAuxiliaryTeacher,
    isOpenModal: isOpenModalAddAuxiliaryTeacher
  } = useModal();

  const {
    openModal: openModalDeleteAuxiliaryTeacher,
    closeModal: closeModalDeleteAuxiliaryTeacher,
    isOpenModal: isOpenModalDeleteAuxiliaryTeacher
  } = useModal();

  const {
    openModal: openModalConfirmDeleteAssistantTeacher,
    closeModal: closeModalConfirmDeleteAssistantTeacher,
    isOpenModal: isOpenModalConfirmDeleteAssistantTeacher
  } = useModal();

  useEffect(() => {
    setExternalTeachers(roomInfo?.auxiliar_teacher);
  }, [roomInfo]);

  useImperativeHandle(ref, () => ({
    getExternalTeacher({ email }: { email: any }) {
      const res = externalTeachers.find((e: any) => e.email === email);
      setExternalTeacher(res);
      openModalDeleteAuxiliaryTeacher();
    },
    setInitialValuesExternalTeacher() {
      setExternalTeacher(externalTeacherInitialValues);
      openModalAddAuxiliaryTeacher();
    }
  }));

  const getExternalTeacherByEmail = async (email: any) => {
    try {
      const response = await dispatch(
        getExternalTeacherByEmailFromApi({ email })
      );
      if (response?.payload?.data) {
        return response?.payload?.data;
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const debouncedCallback = useDebounce(async (email: string) => {
    const data = await getExternalTeacherByEmail(email);
    if (data?.name) {
      setExternalTeacherFromApi(data);
      setExternalTeacher(data);
      setDisabledInputs(true);
    }
  }, 1500);

  const createAuxiliarTeacher = async (payload: any) => {
    try {
      const response: any = await dispatch(addExternalTeacher(payload));
      setActiveTabMain(1);
      setActiveTabTeachers(1);

      if (!response?.error?.message) {
        refetchDetailRoom();
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnclickExternalTeacher = (values: any) => {
    let payload = {
      ...values,
      room_id: roomInfo.id
    };

    if (externalTeacherFromApi) {
      delete payload.name;
      delete payload.last_name;
      delete payload.email;
      delete payload.password;
      payload = {
        ...externalTeacherFromApi,
        room_id: roomInfo.id
      };
    }

    setExternalTeacherFromApi(null);
    const isInTeachersArray = externalTeachers.find(
      (teacher: any) => teacher.email === payload.email
    );

    if (!isInTeachersArray) {
      createAuxiliarTeacher(payload);
      setExternalTeachers((prev: any) => [...prev, values]);
    }
  };

  const handleDeleteExternalTeacher = async () => {
    if (externalTeacher.id) {
      try {
        await patchDeleteAuxiliarTeacher(
          roomInfo?.id.toString(),
          externalTeacher.id
        );
        refetchDetailRoom();
        toast.success('¡Se eliminó el profesor correctamente!');
      } catch (err) {
        if (err) {
          toast.error('Ha ocurrido un error al eliminar el profesor.');
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  return (
    <>
      <ModalAddAssistantTeacher
        debouncedCallback={debouncedCallback}
        externalTeacher={externalTeacher}
        isOpen={isOpenModalAddAuxiliaryTeacher}
        closeModal={closeModalAddAuxiliaryTeacher}
        handleOnclick={handleOnclickExternalTeacher}
        disabledInputs={disabledInputs}
        setDisabledInputs={setDisabledInputs}
      />
      <ModalAddAssistantTeacher
        isDelete={true}
        externalTeacher={externalTeacher}
        isOpen={isOpenModalDeleteAuxiliaryTeacher}
        closeModal={closeModalDeleteAuxiliaryTeacher}
        handleOnclick={openModalConfirmDeleteAssistantTeacher}
        disabledInputs={disabledInputs}
        setDisabledInputs={setDisabledInputs}
      />
      <ModalDeleteAssistantTeacher
        width="xs"
        title={`¿Quieres eliminar al profesor externo ${externalTeacher.name} ${externalTeacher.last_name}?`}
        message={
          'Al eliminarlo perderá el acceso a Moodle, pero puedes volver a inscribirlo cuando quieras'
        }
        isOpenModal={isOpenModalConfirmDeleteAssistantTeacher}
        openModal={openModalConfirmDeleteAssistantTeacher}
        closeModal={closeModalConfirmDeleteAssistantTeacher}
        button1={
          <ButtonFilled
            fullWidth={true}
            size="default"
            onClick={() => {
              handleDeleteExternalTeacher();
              closeModalConfirmDeleteAssistantTeacher();
            }}
          >
            Eliminar profesor
          </ButtonFilled>
        }
        button2={
          user?.permission.name !== 'Teacher' ? (
            <ButtonLineal
              size="default"
              onClick={closeModalConfirmDeleteAssistantTeacher}
            >
              Cancelar
            </ButtonLineal>
          ) : null
        }
      />
    </>
  );
});

export default AuxTeacherActionsModal;
