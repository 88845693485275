import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getAttendanceByTeacherFromApi } from '../slices/attendanceSlice/attendanceSlice';

const useAttendanceByTeacher = ({
  roomId,
  teacherType,
  shouldRefetch
}: {
  roomId: number;
  teacherType?: 'teacher' | 'auxiliar_teacher';
  shouldRefetch?: boolean;
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getAttendance = async () => {
    try {
      return await dispatch(
        getAttendanceByTeacherFromApi({ id: Number(roomId), type: teacherType })
      );
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAttendance();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching project:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, teacherType, shouldRefetch]);

  return { data, isLoading, isError };
};

export default useAttendanceByTeacher;
