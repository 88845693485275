import Typography from '../../atoms/Typography/Typography';
import './switch.scss';

interface Props {
  children?: any;
  onClick?: any;
  isActive?: any;
  setIsActive?: any;
  name: string;
  className?: string;
  textLeft?: any;
  textRight?: any;
  disabled?: any;
}

const Switch: React.FC<Props> = ({
  children,
  onClick,
  isActive,
  setIsActive,
  name,
  textLeft,
  textRight,
  className,
  disabled,
  ...otherProps
}) => {
  return (
    <>
      <div className={`switch`}>
        {textLeft ? (
          <div className={`switch__text`}>
            <Typography
              type="paragraph2"
              variation="mobile"
              className={`
                            switch__text 
                            switch__text--${isActive ? 'active' : ''} 
                        `}
            >
              {textLeft}
            </Typography>
          </div>
        ) : (
          <div></div>
        )}
        <div className={`switch__icon`}>
          <input
            className="switch__icon--checkbox"
            id={`switch-${name}`}
            type="checkbox"
            checked={isActive}
            name={`switch-${name}`}
            disabled={disabled}
            onClick={onClick}
          />
          <label
            className={`
                        switch__icon--label 
                        switch__icon--${isActive ? 'active' : ''}
                    `}
            htmlFor={`switch-${name}`}
          >
            <span className={`switch__icon--label__button`} />
          </label>
        </div>
        {textRight ? (
          <div className={`switch__text`}>
            <Typography
              type="paragraph2"
              variation="mobile"
              className={`
                            switch__text 
                            switch__text--${isActive ? 'active' : ''} 
                        `}
            >
              {textRight}
            </Typography>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default Switch;
