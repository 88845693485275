import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './LandingDetail.scss';
import { useModal } from '../../../hooks/useModal';
import useLandings from '../../../hooks/useLandings';
import Link from '../../../components/molecules/Link';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import Typography from '../../../components/atoms/Typography/Typography';
import IconArrowLeft from '../../../components/atoms/icons/IconArrowLeft';
import IconEditPencil from '../../../components/atoms/icons/IconEditPencil';
import ModalCreateLanding from '../../../pages/Landings/ModalCreateLanding';

const LandingDetail: React.FC<{}> = () => {
  const { landingId } = useParams();
  const [data, setData] = useState<any>(false);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  const { data: dataFromApi, isLoading } = useLandings(shouldRefresh);

  const {
    openModal: openModalCreateProject,
    closeModal: closeModalCreateProject,
    isOpenModal: isOpenModalCreateProject
  } = useModal();

  useEffect(() => {
    if (dataFromApi) {
      const finalData = dataFromApi.find(
        (obj: any) => obj.id === Number(landingId)
      );
      setData(finalData);
    }
  }, [dataFromApi, landingId]);

  if (isLoading) return <ScreenLoader />;

  return (
    <>
      <div className="landings-detail">
        <Link className="" to={`/dashboard/landings/`}>
          <IconArrowLeft width="20" height="22" />

          <Typography type="paragraph2" variation="mobile" bold={true}>
            Regresar a Landings
          </Typography>
        </Link>
        <h1>{data?.name}</h1>
        <div className="landings-detail__body">
          <div style={{ display: 'flex' }}>
            <div className="landings-detail__body--column">
              <div className="landings-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    ID de la alianza
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {landingId}
                  </Typography>
                </div>
              </div>
              <div className="landings-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Nombre
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.name}
                  </Typography>
                </div>
              </div>
              <div className="landings-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Title
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.title}
                  </Typography>
                </div>
              </div>
              <div className="landings-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Subtitle
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.subtitle}
                  </Typography>
                </div>
              </div>
              <div className="landings-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Color
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.color}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="landings-detail__body--column">
              <div className="landings-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Image
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.image}
                  </Typography>
                </div>
              </div>
              <div className="landings-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Precios
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.prices[0].currency}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="landings-detail__body--buttons">
            <ButtonOutline
              className="landings-detail__body--buttons--outline"
              type="button"
              size="small"
              onClick={openModalCreateProject}
            >
              <Typography type="paragraph2" variation="mobile" bold={true}>
                Editar
              </Typography>

              <IconEditPencil width="14" height="16" />
            </ButtonOutline>
          </div>
        </div>
      </div>
      {isOpenModalCreateProject && (
        <ModalCreateLanding
          setShouldRefresh={setShouldRefresh}
          isOpenModal={isOpenModalCreateProject}
          openModal={closeModalCreateProject}
          closeModal={closeModalCreateProject}
          isEdit={true}
          data={data}
          landingId={Number(landingId)}
        />
      )}
    </>
  );
};

export default LandingDetail;
