// React
import { useEffect, useState } from 'react';

// Router
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import Router from './routes/routes';

// Redux
import {
  getCurrentUser,
  logout,
  refreshToken,
  setToken
} from './slices/authSlice';
import { AppDispatch, RootState, useAppDispatch } from './store/store';
import { getAllEnrolments } from './slices/enrolmentSlice/enrolmentsSlice';

// Helpers
import { getAccessCookie, getRefreshCookie } from './helpers/cookies.helpers';

// Hooks
import useFirstRender from './hooks/useIsFirstRender';

//Sentry

function App() {
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const rejected = useSelector((state: RootState) => state.auth.rejected);
  const lastRejected = useSelector(
    (state: RootState) => state.auth.lastRejected
  );

  const isFirstRender = useFirstRender();
  const accessCookie = getAccessCookie();
  const refreshCookie = getRefreshCookie();
  const [isAuthenticated, setIsAuthenticated] =
    useState<boolean>(!!accessCookie);

  useEffect(() => {
    if (user) {
      dispatch(getAllEnrolments());
    }
  }, [dispatch, user]);

  useEffect(() => {
    rejected && refreshCookie && refresh(refreshCookie);
    // eslint-disable-next-line
  }, [rejected]);

  useEffect(() => {
    if (lastRejected) {
      dispatch(logout());
      setIsAuthenticated(false);
    }
    // eslint-disable-next-line
  }, [lastRejected]);

  useEffect(() => {
    !isFirstRender && setIsAuthenticated(!!user);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setToken(accessCookie));
      dispatch(getCurrentUser());
    }
    // eslint-disable-next-line
  }, []);

  const refresh = async (refresh: string) => {
    const result = await dispatch(refreshToken(refresh));

    if (refreshToken.fulfilled.match(result)) {
      const resultUser = await dispatch(getCurrentUser());
      if (getCurrentUser.fulfilled.match(resultUser)) {
        setIsAuthenticated(true);
      }
    }
  };

  return <Router isAuthenticated={isAuthenticated} />;
}

//export default App;
export default Sentry.withProfiler(App);
