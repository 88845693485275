import React from 'react';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
}

const IconDiscount: React.FC<Props> = ({
  className,
  fill = '#000000',
  dataTestid = 'icon-discount'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      fill={fill}
    >
      <path d="M1.707,23.707l-1.414-1.414L22.293,.293l1.414,1.414L1.707,23.707ZM9,5c0-2.206-1.794-4-4-4S1,2.794,1,5s1.794,4,4,4,4-1.794,4-4Zm-2,0c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2,2,.897,2,2Zm16,14c0-2.206-1.794-4-4-4s-4,1.794-4,4,1.794,4,4,4,4-1.794,4-4Zm-2,0c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2,2,.897,2,2Z" />
    </svg>
  );
};

export default IconDiscount;
