import './formConfirm.scss';

import Typography from '../../atoms/Typography/Typography';
import ButtonFilled from '../../molecules/ButtonFilled';
import IconCheckRounded from '../../atoms/icons/IconCheckRounded';

interface Props {
  display?: 'flex' | 'grid';
  title: string;
  body: any;
  closeModal: (e: boolean) => void;
}

const FormConfirm: React.FC<Props> = ({
  display = 'flex',
  title,
  body,
  closeModal
}) => {
  return (
    <div className="form-confirm">
      <IconCheckRounded />

      <div className="form-confirm__title">
        <Typography bold type="h1" variation="mobile">
          {title}
        </Typography>
      </div>

      <div className="form-confirm__body">
        {Object.keys(body).map(key => (
          <div className={`form-confirm__body-${display}`} key={key}>
            <Typography bold type="paragraph3" variation="mobile">
              {key}
            </Typography>
            <Typography type="paragraph3" variation="mobile">
              {body[key]}
            </Typography>
          </div>
        ))}
      </div>

      <ButtonFilled type="button" size="default" onClick={closeModal}>
        Cerrar
      </ButtonFilled>
    </div>
  );
};

export default FormConfirm;
