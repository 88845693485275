// React
import { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Router
import { Link } from 'react-router-dom';

// React Query
import { useQuery } from 'react-query';

// Moment
import moment from 'moment';
import 'moment/locale/es';

// API

// Utils

// Interfaces

// Material UI
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColumns,
  GridValueGetterParams,
  GridColDef,
  GridSortModel,
  GridRenderCellParams
} from '@mui/x-data-grid';
import { IconButton } from '@material-ui/core';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

import { IStudentAbsences } from '../../interfaces/room.interface';
import { objectsEqual } from '../../utils/utils';
import { GET } from '../../services/api/api';
import { RootState } from '../../store/store';

// Components
import AttendanceIndicator from '../../components/common/AttendanceIndicator/AttendanceIndicator';
import CustomLoadingOverlay from '../../components/CustomLoadingOverlay/CustomLoadingOverlay';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';

function StudentAbsences() {
  const user = useSelector((state: RootState) => state.auth.user);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'last_update',
      sort: 'desc'
    }
  ]);

  const {
    data: studentsAbsences,
    error,
    isError,
    isLoading,
    isFetching
  } = useQuery(
    ['students_absences'],
    () => GET<IStudentAbsences[]>(`/students/absences`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!user
    }
  );

  function currentlySelected(model: GridSortModel) {
    if (!objectsEqual(model, sortModel)) setSortModel(model);
  }

  if (isLoading || !studentsAbsences || !user) return <ScreenLoader />;
  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  return (
    <DataGrid
      autoHeight
      style={{ fontSize: 12 }}
      loading={isFetching}
      sortModel={sortModel}
      onSortModelChange={model => currentlySelected(model)}
      rows={studentsAbsences}
      columns={generateColumnsByStudentsAbsences()}
      pageSize={20}
      rowsPerPageOptions={[20]}
      components={{ LoadingOverlay: CustomLoadingOverlay }}
    />
  );

  function generateColumnsByStudentsAbsences(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'room_detail',
        headerName: 'Acción',
        width: 85,
        minWidth: 85,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        hide: !user!.permission.absences_list,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Tooltip title="Mas Información" arrow placement="left">
              <Link to={`/dashboard/rooms/${params.row.room.id}`}>
                <IconButton aria-label="delete" size="small">
                  <VisibilityRoundedIcon fontSize="inherit" color="primary" />
                </IconButton>
              </Link>
            </Tooltip>
          );
        }
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'full_name',
        headerName: 'Nombre del estudiante',
        minWidth: 300,
        flex: 1,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.student.full_name;
        }
      },
      {
        field: 'course',
        headerName: 'Curso',
        minWidth: 300,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.room.course;
        }
      },
      {
        field: 'teacher',
        headerName: 'Profesor',
        minWidth: 250,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.room.teacher.full_name;
        }
      },
      {
        field: 'status',
        headerName: 'Estatus',
        minWidth: 120,
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <>
              {params.row.status === 1 ? (
                <AttendanceIndicator message="A" status="attended" />
              ) : params.row.status === 2 ? (
                <AttendanceIndicator message="T" status="delay" />
              ) : (
                <AttendanceIndicator message="F" status="absence" />
              )}
            </>
          );
        }
      },
      {
        field: 'created',
        headerName: 'Creación',
        minWidth: 140,
        editable: false,
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.created_at && moment(params.row.created_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.created_at &&
            moment(params.row.created_at).format('YYYY MMM D')
          );
        }
      },
      {
        field: 'last_update',
        headerName: 'Ultima actualización',
        minWidth: 200,
        editable: false,
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.updated_at && moment(params.row.updated_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.updated_at &&
            moment(params.row.updated_at).format('YYYY MMM D')
          );
        }
      },
      {
        field: 'number_of_session',
        headerName: 'Sesión',
        minWidth: 90,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.number_of_session;
        }
      }
    ];

    return columns;
  }
}

export default StudentAbsences;
