import React from 'react';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
  dataTestid?: string;
}

const IconArrowleft: React.FC<Props> = ({
  className,
  width = '20',
  height = '20',
  fill = '#313131',
  viewBox = '0 0 20 20',
  dataTestid = 'icon-arrow-left'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9006 10.8295L5.90231 10.8295L8.96249 14.4504C9.10558 14.62 9.17442 14.8387 9.15387 15.0583C9.13332 15.2779 9.02505 15.4805 8.85289 15.6214C8.68073 15.7624 8.45878 15.8302 8.23586 15.81C8.01295 15.7897 7.80732 15.6831 7.66423 15.5135L3.44911 10.5305C3.42076 10.4909 3.3954 10.4492 3.37324 10.4059C3.37324 10.3644 3.37324 10.3395 3.31423 10.298C3.27602 10.2028 3.25601 10.1014 3.25522 9.999C3.25601 9.89661 3.27602 9.79525 3.31423 9.70003C3.31423 9.6585 3.31423 9.63359 3.37324 9.59206C3.3954 9.54876 3.42076 9.50712 3.44911 9.46749L7.66423 4.48455C7.7435 4.3908 7.84275 4.31541 7.95495 4.26373C8.06714 4.21206 8.18951 4.18537 8.31336 4.18557C8.51033 4.18519 8.70123 4.25277 8.8529 4.37658C8.93826 4.4463 9.00882 4.53193 9.06054 4.62855C9.11226 4.72518 9.14413 4.8309 9.15431 4.93968C9.16449 5.04845 9.15278 5.15814 9.11987 5.26245C9.08695 5.36677 9.03347 5.46366 8.96249 5.54758L5.90231 9.16851L15.9006 9.16852C16.1242 9.16852 16.3386 9.25601 16.4967 9.41176C16.6548 9.56751 16.7436 9.77875 16.7436 9.99901C16.7436 10.2193 16.6548 10.4305 16.4967 10.5863C16.3386 10.742 16.1242 10.8295 15.9006 10.8295Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconArrowleft;
