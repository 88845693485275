// Material UI
import Box from '@mui/material/Box';

import './../../../rooms.scss';
import { AttendanceTeacher } from './AttendanceTeacher';
import { IRoomDetailSimplified } from '../../../../../interfaces/room.interface';

interface Props {
  room: IRoomDetailSimplified;
  refetch: () => void;
  type?: 'teacherCTC' | 'AuxiliaryTeacher';
  setActiveTabMain: React.Dispatch<React.SetStateAction<number>>;
  setActiveTabTeachers: React.Dispatch<React.SetStateAction<number>>;
}

export const Teachers: React.FC<Props> = ({
  refetch,
  room,
  type = 'teacherCTC',
  setActiveTabMain,
  setActiveTabTeachers
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: '1em',
        marginTop: '1em',
        paddingBottom: '1em',
        boxShadow: 1
      }}
    >
      <AttendanceTeacher
        roomInfo={room}
        refetch={refetch}
        teacherType={type}
        setActiveTabMain={setActiveTabMain}
        setActiveTabTeachers={setActiveTabTeachers}
      />
    </Box>
  );
};
