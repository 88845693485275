import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';

import { getRoomHeaderDetailByRoomId } from '../services/rooms';
import { IRoomDetailHeaderSimplified } from '../interfaces/room.interface';

interface IError {
  code?: number;
  message?: string;
}

const useRoomHeaderDetails = ({
  roomId,
  shouldRefetch
}: {
  roomId: number;
  shouldRefetch?: boolean;
}) => {
  const [data, setData] = useState<IRoomDetailHeaderSimplified>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<IError>();

  const getRoomHeaderDetail = async () => {
    try {
      setIsError(false);
      return await getRoomHeaderDetailByRoomId(Number(roomId));
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsError(true);
        setError({
          code: error?.response?.status,
          message: error?.response?.data?.detail
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getRoomHeaderDetail();
        setData(response?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, shouldRefetch]);

  return {
    data,
    isLoading,
    isError,
    error
  };
};

export default useRoomHeaderDetails;
