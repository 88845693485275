import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getCoursePriceFromApi } from '../slices/pricesSlice/priceSlice';

const useCoursePricesById = ({
  courseId,
  shouldRefetch
}: {
  courseId: number;
  shouldRefetch?: boolean;
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [data, setData] = useState();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const getCourse = async () => {
    try {
      return await dispatch(getCoursePriceFromApi({ id: Number(courseId) }));
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCourse();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching price:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, shouldRefetch]);

  return { data, error, isLoading };
};

export default useCoursePricesById;
