import IconArrow from '../../atoms/icons/IconArrow';
import Link from '../../molecules/Link';
import './expiredContentReportLayout.scss';

interface Props {
  table: any;
}

const ExpiredContentReportLayout: React.FC<Props> = ({ table }) => {
  return (
    <main
      className={`expired-content-report-layout expired-content-report-layout--full-height`}
    >
      <Link
        className="expired-content-report-layout__button-back"
        children="Regresar a Salones"
        to="/dashboard/rooms"
        iconLeft={
          <IconArrow className="expired-content-report-layout__icon-back" />
        }
      />
      <div className="expired-content-report-layout__table">{table}</div>
    </main>
  );
};

export default ExpiredContentReportLayout;
