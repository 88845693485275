import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 6,
  alignItems: 'center'
}));

export const Mailto = styled('a')(() => ({
  TextDecoder: 'none',
  Color: 'inherit'
}));
