import React from 'react';

import Modal from '../../atoms/Modal';
import colors from '../../atoms/Colors';
import './modalReportOverdueContent.scss';
import IconAlert from '../../atoms/icons/IconAlert';
import Typography from '../../atoms/Typography/Typography';

interface Props {
  title: any;
  subtitle: any;
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  button1?: any;
  button2?: any;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  body?: any;
  message?: string;
  className?: string;
  iconAlert?: any;
  align?: 'center' | 'left';
  dataTestid?: string;
}

const ModalReportOverdueContent: React.FC<Props> = ({
  title,
  subtitle,
  isOpenModal,
  openModal,
  closeModal,
  className = '',
  width,
  body,
  message,
  button1,
  button2,
  iconAlert,
  align = 'left',
  dataTestid = 'modal-report-overdue-content-test-id'
}) => {
  return (
    <div data-testid={dataTestid}>
      <Modal
        className={`modal-report-overdue-content ${className}`}
        width={width}
        isOpen={isOpenModal}
        closeModal={closeModal}
        icon={
          iconAlert === 'alert' ? <IconAlert /> : !iconAlert ? null : iconAlert
        }
        title={
          <Typography
            align={align}
            type="paragraph1"
            color={colors.gris05}
            variation="desktop"
            bold
          >
            {title}
          </Typography>
        }
        subtitle={
          <Typography
            align={align}
            type="paragraph1"
            color={colors.gris06}
            variation="mobile"
          >
            {subtitle}
          </Typography>
        }
        align={align}
        body={
          body ||
          (message && (
            <div className="modal-report-overdue-content__content-body">
              <Typography type="paragraph2" variation="mobile" align={align}>
                {message}
              </Typography>
            </div>
          ))
        }
        buttons={
          <section
            className={`modal-report-overdue-content__container-buttons modal-report-overdue-content__container-buttons--align-right`}
          >
            {button1} {button2}
          </section>
        }
      />
    </div>
  );
};

export default ModalReportOverdueContent;
