import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import './OrganizationDetail.scss';

import Link from '../../../components/molecules/Link';
import useOrganizationById from '../../../hooks/useOrganizationById';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import Typography from '../../../components/atoms/Typography/Typography';
import IconArrowLeft from '../../../components/atoms/icons/IconArrowLeft';
import IconArrowRight from '../../../components/atoms/icons/IconArrowRight';
import IconEditPencil from '../../../components/atoms/icons/IconEditPencil';
import { useModal } from '../../../hooks/useModal';
import ModalCreateOrganization from '../../../pages/CTCB2B/Organizations/ModalCreateOrganization';
import IconCopyFilled from '../../../components/atoms/icons/IconCopyFilled';

const DASHBOARD_LINK = process.env.REACT_APP_CTC_B2B_DASHBOARD_LINK || '';
const DASHBOARD_PASSWORD =
  process.env.REACT_APP_CTC_B2B_DASHBOARD_PASSWORD || '';

const OrganizationDetail: React.FC<{}> = () => {
  const params = new URLSearchParams();
  const { organizationId } = useParams();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [userEmail, setUserEmail] = useState([{ username: '' }]);
  const { data, isLoading } = useOrganizationById({
    organizationId: Number(organizationId),
    shouldRefetch: shouldRefresh
  });
  const {
    openModal: openModalCreateOrganization,
    closeModal: closeModalCreateOrganization,
    isOpenModal: isOpenModalCreateOrganization
  } = useModal();

  params.append('organization', data?.name.toString());

  const copyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data).then(() => {
      toast.success('Copiado al portapapeles');
    });
  };

  useEffect(() => {
    if (data) {
      setUserEmail(data.users);
    }
  }, [data]);

  const truncateStringWithEllipsis = ({
    str,
    maxLength = 33
  }: {
    str: string;
    maxLength?: number;
  }) => {
    if (str.length <= maxLength) {
      return str;
    }

    return `${str.slice(0, maxLength)}...`;
  };

  if (isLoading) return <ScreenLoader />;

  return (
    <>
      <div className="organizations-detail">
        <Link className="" to={`/dashboard/ctcb2b?tab=1`}>
          <IconArrowLeft width="20" height="22" />

          <Typography type="paragraph2" variation="mobile" bold={true}>
            Regresar a Organizaciones
          </Typography>
        </Link>
        <h1>{data?.name}</h1>
        <div className="organizations-detail__body">
          <div style={{ display: 'flex' }}>
            <div className="organizations-detail__body--column">
              <div className="organizations-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    ID
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {organizationId}
                  </Typography>
                </div>
              </div>
              <div className="organizations-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Razón social
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.business_name}
                  </Typography>
                </div>
              </div>
              <div className="organizations-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Tipo de organización
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.organization_type}
                  </Typography>
                </div>
              </div>
              <div className="organizations-detail__body--row">
                <div>
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    País
                  </Typography>
                </div>
                <div>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.country?.name}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="organizations-detail__body--column">
              <div>
                <div className="organizations-detail__body--row">
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Owner comercial
                  </Typography>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.business_owner}
                  </Typography>
                </div>
                <div className="organizations-detail__body--row">
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Código único tributario
                  </Typography>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.tax_code}
                  </Typography>
                </div>
                <div className="organizations-detail__body--row">
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Sitio web
                  </Typography>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.web_site}
                  </Typography>
                </div>
                <div className="organizations-detail__body--row">
                  <Typography type="paragraph2" variation="mobile" bold={true}>
                    Dirección
                  </Typography>
                  <Typography type="paragraph2" variation="mobile">
                    {data?.address}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="organizations-detail__body--buttons">
            <Link className="" to={`/dashboard/ctcb2b?${params.toString()}`}>
              <ButtonOutline
                className="organizations-detail__body--buttons--outline"
                type="button"
                size="small"
              >
                <Typography type="paragraph2" variation="mobile" bold={true}>
                  Ver proyectos
                </Typography>

                <IconArrowRight width="22" height="24" />
              </ButtonOutline>
            </Link>
            <ButtonOutline
              size="small"
              className="organizations-detail__body--buttons--outline"
              onClick={openModalCreateOrganization}
            >
              <Typography type="paragraph2" variation="mobile" bold={true}>
                Editar
              </Typography>
              <IconEditPencil width="14" height="16" />
            </ButtonOutline>
          </div>
        </div>
        <br />
        {userEmail.length > 0 && (
          <div className="organizations-detail__body">
            <h1>Dashboard - Datos de acceso</h1>
            <div style={{ display: 'flex' }}>
              <div className="organizations-detail__body--column">
                <div className="organizations-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Link al Dashboard
                    </Typography>
                  </div>
                  <div className="organizations-detail__body--row--clipboard">
                    <Typography type="paragraph2" variation="mobile">
                      {truncateStringWithEllipsis({ str: DASHBOARD_LINK })}
                    </Typography>
                    <IconCopyFilled
                      fill="black"
                      onClick={() => {
                        copyToClipboard(DASHBOARD_LINK);
                      }}
                    />
                  </div>
                </div>
                <div className="organizations-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Usuario
                    </Typography>
                  </div>
                  <div className="organizations-detail__body--row--clipboard">
                    <div className="organizations-detail__body--row--users">
                      {userEmail.map((user, i) => (
                        <div
                          className="organizations-detail__body--row--username"
                          key={`${user}-${i}`}
                        >
                          <Typography type="paragraph2" variation="mobile">
                            {truncateStringWithEllipsis({str: user?.username})}
                          </Typography>
                          <IconCopyFilled
                            fill="black"
                            onClick={() => {
                              copyToClipboard(user?.username);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="organizations-detail__body--row">
                  <div>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Contraseña
                    </Typography>
                  </div>
                  <div className="organizations-detail__body--row--clipboard">
                    <Typography type="paragraph2" variation="mobile">
                      {truncateStringWithEllipsis({ str: DASHBOARD_PASSWORD })}
                    </Typography>
                    <IconCopyFilled
                      fill="black"
                      onClick={() => {
                        copyToClipboard(DASHBOARD_PASSWORD);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isOpenModalCreateOrganization && (
        <ModalCreateOrganization
          setShouldRefresh={setShouldRefresh}
          isOpenModal={isOpenModalCreateOrganization}
          openModal={closeModalCreateOrganization}
          closeModal={closeModalCreateOrganization}
          isEdit={true}
          data={data}
          organizationId={Number(organizationId)}
        />
      )}
    </>
  );
};

export default OrganizationDetail;
