import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCoursePrice, getLearningPathPrice } from '../../services/prices';

export const getCoursePriceFromApi = createAsyncThunk(
  'prices/getCoursePrice',
  async (
    data: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      return await getCoursePrice(data);
    } catch (err) {
      toast.error('Ha ocurrido un error al obtener la información.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error);
    }
  }
);

export const getLearningPathPriceFromApi = createAsyncThunk(
  'prices/getLearningPathPrice',
  async (
    data: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      return await getLearningPathPrice(data);
    } catch (err) {
      toast.error('Ha ocurrido un error al obtener la información.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error);
    }
  }
);

const initialState: any = {
  prices: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const priceSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getCoursePrice */
    builder.addCase(getCoursePriceFromApi.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(getCoursePriceFromApi.fulfilled, (state, action) => {
      state?.prices?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(getCoursePriceFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** getLearningPathPrice */
    builder.addCase(getLearningPathPriceFromApi.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(getLearningPathPriceFromApi.fulfilled, (state, action) => {
      state?.prices?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(getLearningPathPriceFromApi.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateProjects = (state: any) => state.projects;
