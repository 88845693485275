import {
 Form, Field, Formik, ErrorMessage 
} from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Title,
  Image,
  Wrapper,
  CustomAlert,
  CustomButton,
  LoginFormTile,
  CustomTextField
} from './styled';
import ctcLogo from '../../assets/ctclogo.png';
import { signIn, getCurrentUser } from './../../slices/authSlice';
import { TEACHER_AUXILIARY } from '../../constants/usersType.constants';
import { AppDispatch, RootState, useAppDispatch } from '../../store/store';

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required')
});

interface Props {}

const SignInForm: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const error = useSelector((state: RootState) => state.auth.error);

  async function handleSubmit(
    email: string,
    password: string,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const result = await dispatch(
      signIn({ email, password, platform: 'backoffice' })
    );
    if (signIn.fulfilled.match(result)) {
      const resultUser = await dispatch(getCurrentUser());
      if (
        getCurrentUser.fulfilled.match(resultUser) &&
        resultUser?.payload?.permission?.name === TEACHER_AUXILIARY
      ) {
        navigate('/dashboard/rooms', { replace: true });
      } else if (getCurrentUser.fulfilled.match(resultUser)) {
        navigate('/');
      }
    }
    setSubmitting(false);
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={SignInSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { email, password } = values;
          handleSubmit(email, password, setSubmitting);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <LoginFormTile>
              <Image src={ctcLogo} alt="CTC Logo" />
              <Title>Bienvenido de nuevo</Title>
              <Field
                as={CustomTextField}
                label="Email"
                name="email"
                type="email"
                variant="outlined"
              />
              <ErrorMessage
                name="email"
                render={() => (
                  <CustomAlert severity="error">
                    Este campo es requerido
                  </CustomAlert>
                )}
              />
              <Field
                autoComplete="current-password"
                id="outlined-password-input"
                label="Contraseña"
                name="password"
                type="password"
                variant="outlined"
                as={CustomTextField}
              />
              <ErrorMessage
                name="password"
                render={() => (
                  <CustomAlert severity="error">
                    Este campo es requerido
                  </CustomAlert>
                )}
              />
              {error && <CustomAlert severity="error">{error.msg}</CustomAlert>}
              <CustomButton
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Cargando...' : 'Ingresar'}
              </CustomButton>
            </LoginFormTile>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default SignInForm;
