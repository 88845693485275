import './defaultLayout.scss';

interface Props {
  children: any;
  mainClassName?: any;
  contentClassName?: any;
}

const DefaultLayout: React.FC<Props> = ({
  children,
  mainClassName,
  contentClassName
}) => {
  return (
    <main className={`default-layout ${mainClassName}`}>
      <div className={`default-layout__content ${contentClassName}`}>
        {children}
      </div>
    </main>
  );
};

export default DefaultLayout;
