import {
  Dialog,
  Alert,
  Button,
  AlertTitle,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { updateAttendanceDate } from '../../../../../services/rooms';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import {
  IRoomDetailSimplified,
  ISessionSelect
} from '../../../../../interfaces/room.interface';
import useAttendanceByStudent from '../../../../../hooks/useAttendanceByStudent';
import TableAttendanceStudentV3 from '../../../../../components/organism/TableAttendanceStudent/TableAttendanceStudentV3';

interface Props {
  roomInfo: IRoomDetailSimplified;
  refetch: () => void;
  qtySessions: ISessionSelect;
  refetchDetailHeader: () => void;
}

export const AttendanceStudent: React.FC<Props> = ({
  roomInfo,
  refetch,
  refetchDetailHeader,
  qtySessions
}) => {
  const { roomId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [valueDate, setValueDate] = useState('');
  const [valueDateId, setValueDateId] = useState<number | null>(null);

  const {
    data: studentRecords,
    isLoading,
    isError,
    fetchAttendance: refetchDetailRoom
  } = useAttendanceByStudent({roomId: Number(roomId)});

  const handleUpdateAttendanceDate = async (
    sessionId: number,
    startDate: string
  ) => {
    const date = moment(startDate).format('YYYY-MM-DD');
    try {
      const response = await updateAttendanceDate(sessionId, date);
      if (response?.status === 200) {
        toast.success('La fecha se actualizó correctamente!');
        refetchDetailRoom();
      } else {
        toast.error('No se pudo actualizar');
      }
    } catch (error) {
      toast.error(
        'Ha ocurrido un error. Inicie la clase con la cuenta correcta'
      );
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  if (isLoading || !roomInfo) return <ScreenLoader />;

  if (isError) {
    return (
      <Alert
        severity="error"
        variant="outlined"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => refetchDetailRoom()}
          >
            Reintentar
          </Button>
        }
      >
        <AlertTitle>Error</AlertTitle>
        No se pudo obtener la información de los estudiantes. Por favor
        reintente.
      </Alert>
    );
  }

  return (
    <>
      <TableAttendanceStudentV3
        roomInfo={roomInfo}
        studentRecords={studentRecords}
        refetch={refetch}
        refetchAttendanceDetails={refetchDetailRoom}
        setOpenModal={setOpenModal}
        setValueDateId={setValueDateId}
        setValueDate={setValueDate}
        refetchDetailHeader={refetchDetailHeader}
        qtySessions={qtySessions}
      />
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Editar Fecha</DialogTitle>
        <DialogContent>
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Fecha sesión"
              format="yyyy-MM-dd"
              value={moment(valueDate).add(1, 'days').toDate()}
              onChange={(newValue: any) => {
                if (newValue) {
                  setValueDate(newValue);
                }
              }}
              slots={{ textField: (params: any) => <TextField {...params} /> }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (valueDate && valueDateId) {
                handleUpdateAttendanceDate(valueDateId, valueDate);
              }
              handleClose();
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
