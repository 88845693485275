// Axios
import axios, { AxiosResponse } from 'axios';

// Helpers
import { getAccessCookie } from '../../helpers/cookies.helpers';

// Types
import { dict } from '../../types/generics/generics';

const instance = axios.create({baseURL: process.env.REACT_APP_CTC_BACKEND_URL});

const instance_micro = axios.create({baseURL: process.env.REACT_APP_CTC_MICRO_URL});

const instance_serverless = axios.create({baseURL: process.env.REACT_APP_CTC_SERVERLESS_URL});

const token_micro = process.env.REACT_APP_CTC_MICRO_TOKEN;

export const GET_MICRO = async <T>(url: string): Promise<T> => {
  instance_micro.defaults.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token_micro}`
  };
  const { data }: AxiosResponse<T> = await instance_micro.get<T>(url);
  return data;
};

export const GET_SERVERLESS = async <T>(url: string): Promise<T> => {
  instance_serverless.defaults.headers = { 'Content-Type': 'application/json' };
  const { data }: AxiosResponse<T> = await instance_serverless.get<T>(url);
  return data;
};

export const GET = async <T>(url: string): Promise<T> => {
  instance.defaults.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessCookie()}`
  };
  const { data }: AxiosResponse<T> = await instance.get<T>(url);
  return data;
};

const instance_meeting = axios.create({baseURL: process.env.REACT_APP_CTC_EVENTS_API_URL});

export const GET_MEETING = async <T>(url: string): Promise<T> => {
  instance_meeting.defaults.headers = { 'Content-Type': 'application/json' };
  const { data }: AxiosResponse<T> = await instance_meeting.get<T>(url);
  return data;
};

export const POST_MICRO = async (url: string, payload: dict) => {
  instance.defaults.headers = { 'Content-Type': 'application/json' };
  const response: AxiosResponse = await instance_micro.post(url, payload);
  return response;
};

export const POST = async (url: string, payload: dict) => {
  instance.defaults.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessCookie()}`
  };
  const response: AxiosResponse = await instance.post(url, payload);
  return response;
};

export const PUT = async (url: string, payload: dict) => {
  instance.defaults.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessCookie()}`
  };
  const response: AxiosResponse = await instance.put(url, payload);
  return response;
};

export const PATCH_SERVERLESS = async <T>(url: string, payload: dict = {}) => {
  instance_serverless.defaults.headers = { 'Content-Type': 'application/json' };
  const response: AxiosResponse = await instance_serverless.patch(url, payload);
  return response;
};

export const PATCH_MICRO = async (url: string, payload: dict = {}) => {
  instance.defaults.headers = { 'Content-Type': 'application/json' };
  const response: AxiosResponse = await instance_micro.patch(url, payload);
  return response;
};

export const PATCH = async (url: string, payload: dict) => {
  instance.defaults.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessCookie()}`
  };
  const response: AxiosResponse = await instance.patch(url, payload);
  return response;
};

export const PATCHFILES = async (url: string, payload: dict) => {
  instance.defaults.headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${getAccessCookie()}`
  };
  const response: AxiosResponse = await instance.patch(url, payload);
  return response;
};

export const DELETE_MICRO = async (url: string) => {
  instance.defaults.headers = { 'Content-Type': 'application/json' };
  const response: AxiosResponse = await instance_micro.delete(url);
  return response;
};

export const DELETE = async (url: string) => {
  instance.defaults.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessCookie()}`
  };
  const response: AxiosResponse = await instance.delete(url);
  return response;
};
