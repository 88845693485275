import './projectsLayout.scss';

interface Props {
  table: any;
  buttonAdd: any;
  shouldRefresh: boolean;
  generalFilter: string;
  setGeneralFilter: any;
}

const ProjectsLayout: React.FC<Props> = ({
  table,
  buttonAdd,
  generalFilter,
  shouldRefresh,
  setGeneralFilter
}) => {
  return (
    <main className="projects-layout">
      <div>{buttonAdd}</div>
      <div></div>
      <div className="projects-layout__table">
        {table(shouldRefresh, generalFilter, setGeneralFilter)}
      </div>
    </main>
  );
};

export default ProjectsLayout;
