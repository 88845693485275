// React Query
import { useQuery } from 'react-query';

// API
import { Grid, LinearProgress } from '@mui/material';

import { GET } from '../../../../../services/api/api';

// Interfaces
import { ICourseSession } from '../../../../../interfaces/course.interface';

// Material UI

// Components
import CourseInfoSession from '../../../../../components/Courses/CourseInfoSession/CourseInfoSession';

interface Props {
  refetch: () => {} /*  list all courses*/;
  courseId: number;
}

const InfoSession: React.FC<Props> = ({ refetch, courseId }) => {
  const {
    data: course,
    error: errorCourse,
    isError: isErrorCourse,
    isLoading: isLoadingCourse,
    refetch: refetchCourse /*  list individual courses*/
  } = useQuery(
    ['course-info-session', courseId],
    () => GET<ICourseSession>(`/course-sessions-list/${courseId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  if (isLoadingCourse || !course) return <LinearProgress />;
  if (isErrorCourse) return <div>Error! {JSON.stringify(errorCourse)}</div>;

  return (
    <Grid container rowSpacing={2} alignItems="flex-start">
      {/* Main */}
      <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
        <CourseInfoSession
          infoSessions={course}
          courseId={courseId}
          refetch={refetch}
          refetchCourse={refetchCourse}
        />
      </Grid>
    </Grid>
  );
};

export default InfoSession;
