// React
import { useEffect, useState } from 'react';

// React Query
import toast from 'react-hot-toast';

import {
  getEvaluationBoxByCourseId,
  postEvaluationBoxByCourseId,
  putLearningBoxByCourseId
} from '../../../services/courses';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import Switch from '../../molecules/Switch';
import Textarea from '../../molecules/Textarea/Textarea';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';

import './courseEvaluationsDetails.scss';

interface Props {
  dataEvaluation: any;
  setDataEvaluation: any;
  courseId: number;
  refetch: any;
}

const CourseEvaluationsDetails: React.FC<Props> = ({
  dataEvaluation,
  setDataEvaluation,
  courseId,
  refetch
}) => {
  const [isActive, setIsActive] = useState(false);
  const [evaluationSession, setEvaluationSession] = useState<any>([]);
  const [updateSession, setUpdateSession] = useState<any>([]);
  const [sortEvaluation, setSortEvaluation] = useState<any>(
    dataEvaluation?.qualifications.sort(
      (a: any, b: any) => a.course_qualification_id - b.course_qualification_id
    )
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeSwitchMail = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setIsActive(dataEvaluation?.has_qualifications_email);

    const evaluationSessionAux: {
      category_id: any;
      scale_id: any;
      comment: any;
    }[] = [];
    const updateSessionAux: { qualification_id: any; comment: any }[] = [];

    if (dataEvaluation.qualifications.length > 0) {
      [...Array(18)].map((_, indx: number) => {
        updateSessionAux[indx] = {
          qualification_id:
            dataEvaluation?.qualifications[indx]?.course_qualification_id,
          comment: dataEvaluation?.qualifications[indx]?.comment
        };
      });

      setUpdateSession(updateSessionAux);
      setEvaluationSession(sortEvaluation);
    } else {
      [...Array(18)].map((_, indx: number) => {
        evaluationSessionAux[indx] = {
          category_id: dataEvaluation?.qualifications[indx]?.category_id
            ? dataEvaluation?.qualifications[indx]?.category_id
            : Number(((indx + 2) / 3).toFixed()),
          scale_id: dataEvaluation?.qualifications[indx]?.scale_id
            ? dataEvaluation?.qualifications[indx]?.scale_id
            : (indx + 1) % 3 === 0
              ? 3
              : Number(((indx + 1) % 3).toFixed()),
          comment: dataEvaluation?.qualifications[indx]?.comment
            ? dataEvaluation?.qualifications[indx]?.comment
            : ''
        };
      });

      setEvaluationSession(evaluationSessionAux);
    }
  }, [dataEvaluation]);

  const handleChangeLearningBox = async (
    category: number,
    scala: number,
    index: number,
    box: any,
    value: string
  ) => {
    const evaluationSessionAux = JSON.parse(JSON.stringify(box));

    if (dataEvaluation.qualifications.length > 0) {
      updateSession[index] = {
        qualification_id:
          dataEvaluation.qualifications[index]?.course_qualification_id,
        comment: value
      };
    } else {
      evaluationSessionAux[index] = {
        category_id: category,
        scale_id: scala,
        comment: value
      };
      setEvaluationSession(evaluationSessionAux);
    }
  };

  const handleSave = async () => {
    try {
      if (dataEvaluation.qualifications.length > 0) {
        await putLearningBoxByCourseId(courseId, {
          has_qualification_email: isActive,
          qualifications: updateSession
        });
      } else {
        const response = await postEvaluationBoxByCourseId(courseId, {
          has_qualification_email: isActive,
          qualifications: evaluationSession
        });
        if (response.status === 200) {
          const { data } = await getEvaluationBoxByCourseId(courseId);
          setDataEvaluation(data);
        }
      }
      refetch();
      toast.success('Se guardo correctamente las evaluaciones');
    } catch (err) {
      toast.error('No se pudo guardar las evaluaciones');
    }
  };

  if (isLoading || !dataEvaluation) return <ScreenLoader />;

  return (
    <>
      <main className="course-evaluations">
        <div className="course-evaluations__content">
          <div className="course-evaluations__switch">
            <Switch
              isActive={isActive}
              setIsActive={setIsActive}
              textRight={'Enviar feedback a padres'}
              name="mail"
              onClick={() => handleChangeSwitchMail()}
            ></Switch>
          </div>
          <div className="course-evaluations__feedback">
            <div className="course-evaluations__feedback-section">
              <Typography type="paragraph2" variation="mobile" bold>
                Participación en clase
              </Typography>
              <div className="course-evaluations__feedback-description">
                <Typography type="paragraph2" variation="mobile">
                  Logrado
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      1,
                      1,
                      0,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[0]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Proceso
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      1,
                      2,
                      1,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[1]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Inicio
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      1,
                      3,
                      2,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[2]?.comment
                      : ''
                  }
                ></Textarea>
              </div>
              <Typography type="paragraph2" variation="mobile" bold>
                Resolución de problemas
              </Typography>
              <div className="course-evaluations__feedback-description">
                <Typography type="paragraph2" variation="mobile">
                  Logrado
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      3,
                      1,
                      6,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[6]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Proceso
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      3,
                      2,
                      7,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[7]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Inicio
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      3,
                      3,
                      8,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[8]?.comment
                      : ''
                  }
                ></Textarea>
              </div>
              <Typography type="paragraph2" variation="mobile" bold>
                Comunicación
              </Typography>
              <div className="course-evaluations__feedback-description">
                <Typography type="paragraph2" variation="mobile">
                  Logrado
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      5,
                      1,
                      12,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[12]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Proceso
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      5,
                      2,
                      13,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[13]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Inicio
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      5,
                      3,
                      14,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[14]?.comment
                      : ''
                  }
                ></Textarea>
              </div>
            </div>
            <div className="course-evaluations__feedback-section">
              <Typography type="paragraph2" variation="mobile" bold>
                Creatividad
              </Typography>
              <div className="course-evaluations__feedback-description">
                <Typography type="paragraph2" variation="mobile">
                  Logrado
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      2,
                      1,
                      3,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[3]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Proceso
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      2,
                      2,
                      4,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[4]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Inicio
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      2,
                      3,
                      5,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[5]?.comment
                      : ''
                  }
                ></Textarea>
              </div>
              <Typography type="paragraph2" variation="mobile" bold>
                Pensamiento lógico
              </Typography>
              <div className="course-evaluations__feedback-description">
                <Typography type="paragraph2" variation="mobile">
                  Logrado
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      4,
                      1,
                      9,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[9]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Proceso
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      4,
                      2,
                      10,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[10]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Inicio
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      4,
                      3,
                      11,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[11]?.comment
                      : ''
                  }
                ></Textarea>
              </div>
              <Typography type="paragraph2" variation="mobile" bold>
                Proyecto
              </Typography>
              <div className="course-evaluations__feedback-description">
                <Typography type="paragraph2" variation="mobile">
                  Logrado
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      6,
                      1,
                      15,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[15]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Proceso
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      6,
                      2,
                      16,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[16]?.comment
                      : ''
                  }
                ></Textarea>
                <Typography type="paragraph2" variation="mobile">
                  En Inicio
                </Typography>
                <Textarea
                  placeholder="Inserta detalles de la calificación"
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      6,
                      3,
                      17,
                      evaluationSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataEvaluation.qualifications.length > 0
                      ? sortEvaluation[17]?.comment
                      : ''
                  }
                ></Textarea>
              </div>
            </div>
          </div>
          <div className="course-evaluations__button">
            <ButtonFilled size="default" onClick={() => handleSave()}>
              Guardar
            </ButtonFilled>
          </div>
        </div>
      </main>
    </>
  );
};

export default CourseEvaluationsDetails;
