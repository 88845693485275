// React
import React, { SyntheticEvent, useState } from 'react';

// React Query
import { useQuery, useMutation } from 'react-query';

// API

// Interfaces

// Material UI
import { Box } from '@material-ui/core';
import { Tab, Grid, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import {
  ICouponProgram,
  ICouponProgramForm
} from '../../../../interfaces/coupon.interface';
import { GET_MICRO, PATCH_MICRO } from '../../../../services/api/api';

// Components
import Rules from './Rules/Rules';
import Coupons from './Coupons/Coupons';
import Discounts from './Discounts/Discount';

interface Props {
  couponProgramId: number;
  refetch: () => {};
  onClose: () => void;
}

const EditCoupon: React.FC<Props> = ({ couponProgramId, refetch }) => {
  const [currentPosition, setCurrentPosition] = useState<string>('0');
  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  const updateAllValue = useMutation(
    (data: ICouponProgram) => PATCH_MICRO(`/program/${couponProgramId}`, data),
    { retry: false }
  );

  const {
    data: couponProgramForm,
    error,
    isError,
    isLoading
  } = useQuery(
    ['coupon-program'],
    () => GET_MICRO<ICouponProgramForm>(`/program/${couponProgramId}`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1.5} justifyContent="space-between">
          <h2>{couponProgramForm?.name}</h2>
        </Stack>
      </Grid>
      <TabContext value={currentPosition}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList onChange={handleChangePosition} aria-label="room actions">
            <Tab label="Condiciones" value="0" />
            <Tab label="Descuentos" value="1" />
            <Tab label="Cupones" value="2" />
          </TabList>
        </Box>

        <TabPanel value="0" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
          <Rules
            rules={couponProgramForm?.rules}
            all_course={couponProgramForm?.all_course}
            all_path={couponProgramForm?.all_path}
            description={couponProgramForm?.description}
            date_limit={couponProgramForm?.date_limit}
            name={couponProgramForm?.name}
            check_test_month={couponProgramForm?.check_test_month}
            couponProgramId={couponProgramId}
            refetch={refetch}
            mutation={updateAllValue}
          />
        </TabPanel>
        <TabPanel value="1" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
          <Discounts
            discounts={couponProgramForm?.discounts}
            couponProgramId={couponProgramId}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0, width: '100%', marginTop: '20px' }}>
          <Coupons
            coupons={couponProgramForm?.coupons}
            couponProgramId={couponProgramId}
            refetch={refetch}
          />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default EditCoupon;
