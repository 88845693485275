import * as Yup from 'yup';

export const CreateExternalTeacherSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Muy corto!').required('Campo obligatorio'),
  last_name: Yup.string().min(3, 'Muy corto!').required('Campo obligatorio'),
  email: Yup.string().email('Formato no válido').required('Campo obligatorio'),
  password: Yup.string()
    .min(8, 'Muy corto!')
    .required('Debe generar una contraseña')
});
