import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../store/store';
import HomeLayout from '../../components/templates/HomeLayout';
import { TEACHER_AUXILIARY } from '../../constants/usersType.constants';

function Dashboard() {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const externalTeacher = user?.permission?.name === TEACHER_AUXILIARY;

  if (externalTeacher) navigate('/dashboard/rooms', { replace: true });

  return (
    <>
      <HomeLayout>Hola, coder 👋</HomeLayout>
    </>
  );
}

export default Dashboard;
