/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable complexity */
import React from 'react';

import Button from '../Button';

import './buttonLineal.scss';

export interface Props {
  children?: any;
  onClick?: any;
  className?: string;
  size?: any;
  iconLeft?: any;
  iconRight?: any;
  hasHoverColor?: boolean;
  disabled?: boolean;
  to?: string;
  underline?: boolean;
  external?: boolean;
  fullWidth?: boolean | string | number;
  width?: string | number;
  hasPadding?: boolean;
  dataTestid?: string;
  customStyles?: React.CSSProperties;
}

const ButtonLineal: React.FC<Props> = ({
  children,
  onClick,
  className = '',
  size = 'default',
  hasHoverColor,
  disabled,
  to,
  underline = true,
  external = false,
  fullWidth,
  width,
  hasPadding = true,
  dataTestid = 'button-lineal-test-id',
  customStyles,
  ...otherProps
}) => {
  const hoverType = hasHoverColor ? 'hover-color' : 'hover-no-color';

  return (
    <>
      {to ? (
        <a
          data-testid={`${dataTestid}-link`}
          {...otherProps}
          className={`button-lineal button-lineal--${size} button-lineal--${hoverType} ${
            hasPadding ? '' : 'button-lineal--no-padding'
          } ${
            disabled
              ? 'button-lineal__disabled button-lineal__disabled-link'
              : ''
          } button-lineal--${underline ? '' : 'no'}underline ${
            typeof fullWidth === 'boolean' && fullWidth
              ? 'button-lineal--fullwidth'
              : undefined
          } ${className}`}
          href={disabled ? '' : to}
          style={{
            ...customStyles,
            width:
              typeof fullWidth === 'string' || typeof fullWidth === 'number'
                ? width
                : fullWidth === true
                  ? '100%'
                  : 'auto'
          }}
          target={external ? '_blank' : '_self'}
        >
          {children}
        </a>
      ) : (
        <Button
          customStyles={customStyles}
          dataTestid={`${dataTestid}-button`}
          {...otherProps}
          className={`button-lineal button-lineal--${hoverType} ${
            hasPadding ? '' : 'button-lineal--no-padding'
          } ${disabled ? 'button-lineal__disabled' : ''} button-lineal--${
            underline ? '' : 'no'
          }underline ${className}`}
          onClick={disabled ? () => {} : onClick}
          fullWidth={fullWidth}
          width={width}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default ButtonLineal;
