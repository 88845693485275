// React
import { useState } from 'react';

// React Query
import { useQuery } from 'react-query';

// API

// Utils

// Interfaces

// Material UI
import {
  DataGrid,
  GridColumns,
  GridValueGetterParams,
  GridSortModel,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid';
import { Chip, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';

import { ISlider } from '../../interfaces/slider.interface';
import { objectsEqual } from '../../utils/utils';
import { GET } from '../../services/api/api';

// Components
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import CustomLoadingOverlay from '../../components/CustomLoadingOverlay/CustomLoadingOverlay';
import ModalCreateSlider from './ModalCreateSlider/ModalCreateSlider';
import ModalEditSlider from './ModalEditSlider/ModalEditSlider';
import DeleteSlider from './DeleteSlider/DeleteSlider';
import ModalDetailsSlider from './ModalDetailsSlider/ModalDetailsSlider';

interface Props {}

const Sliders: React.FC<Props> = () => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc'
    }
  ]);

  const {
    data: sliders,
    error,
    isError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(['sliders'], () => GET<ISlider[]>(`/sliders`), {
    retry: false,
    refetchOnWindowFocus: false
  });

  function currentlySelected(model: GridSortModel) {
    if (!objectsEqual(model, sortModel)) setSortModel(model);
  }

  if (isLoading || !sliders) return <ScreenLoader />;
  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  return (
    <Grid container rowGap={2} style={{ overflowY: 'scroll', height: '100%' }}>
      {/* Create New Slider */}
      <Grid item xs={12}>
        <ModalCreateSlider refetch={refetch} />
      </Grid>

      {/* Sliders */}
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          style={{ fontSize: 12 }}
          loading={isFetching}
          sortModel={sortModel}
          onSortModelChange={model => currentlySelected(model)}
          rows={sliders}
          columns={generateColumnsBySliders()}
          pageSize={20}
          rowsPerPageOptions={[20]}
          checkboxSelection={false}
          components={{ LoadingOverlay: CustomLoadingOverlay }}
        />
      </Grid>
    </Grid>
  );

  function generateColumnsBySliders(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'actions',
        headerName: 'Acciones',
        minWidth: 140,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <ModalDetailsSlider sliderId={params.row.id} />
              <ModalEditSlider sliderId={params.row.id} refetch={refetch} />
              <DeleteSlider sliderId={params.row.id} refetch={refetch} />
            </Stack>
          );
        }
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 80,
        minWidth: 80,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'title',
        headerName: 'Título',
        minWidth: 150,
        editable: false,
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.title;
        }
      },
      {
        field: 'subtitle',
        headerName: 'Subtítulo',
        minWidth: 300,
        flex: 1,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.subtitle;
        }
      },
      {
        field: 'is_published',
        headerName: 'Estatus',
        minWidth: 130,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.is_published;
        },
        renderCell: (params: GridRenderCellParams) => {
          switch (params.row.is_published) {
            case false:
              return (
                <Chip
                  icon={<VpnLockIcon />}
                  label="Privado"
                  variant="outlined"
                  color="warning"
                  sx={{ paddingLeft: '5px' }}
                />
              );
            case true:
              return (
                <Chip
                  icon={<PublicIcon />}
                  label="Publico"
                  variant="outlined"
                  color="success"
                  sx={{ paddingLeft: '5px' }}
                />
              );
          }
        }
      }
    ];

    return columns;
  }
};

export default Sliders;
