const FILTERS_FIELD_NAMES_TRANSLATIONS = new Map<string, string>();
FILTERS_FIELD_NAMES_TRANSLATIONS.set('full_name', 'Nombre de tutor');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('id', 'ID');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('username', 'Correo');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('doc_number', 'DNI');

const FILTERS_TO_QUERY_PARAMS_TRANSLATION = new Map<string, string>();
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set('id', 'id');
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set('full_name', 'guardian_full_name');
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set('username', 'email');
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set('doc_number', 'doc_number');

export {
  FILTERS_FIELD_NAMES_TRANSLATIONS,
  FILTERS_TO_QUERY_PARAMS_TRANSLATION
};
