// React
import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

import { AppDispatch, useAppDispatch } from '../../store/store';
import {
  getAllLearnings,
  selectStateLearnings
} from '../../slices/learningsSlice/learningsSlice';

// Components
import GroupsLayout from '../../components/templates/GroupsLayout';
import TableGroups from '../../components/organism/TableGroups';
import ButtonFilled from '../../components/molecules/ButtonFilled';

function Groups() {
  const dispatch: AppDispatch = useAppDispatch();
  const { learnings } = useSelector(selectStateLearnings);

  const [openModalCreate, setOpenModalCreate] = useState(false);

  useEffect(() => {
    if (learnings.length < 1) {
      dispatch(getAllLearnings());
    }
  }, [learnings]);

  return (
    <>
      {/* List groups */}
      <GroupsLayout
        buttonAdd={
          <ButtonFilled
            fullWidth
            onClick={() => {
              setOpenModalCreate(true);
            }}
            className={'groups-layout__button-add-group'}
          >
            {'+ Crear nuevo grupo'}
          </ButtonFilled>
        }
        table={
          <TableGroups
            openModalCreate={openModalCreate}
            setOpenModalCreate={setOpenModalCreate}
          />
        }
      />
    </>
  );
}

export default Groups;
