/* eslint-disable react-hooks/exhaustive-deps */
import 'moment/locale/es';
import moment from 'moment';
import { AxiosError } from 'axios';
import {
 useMemo, useState, useEffect, CSSProperties 
} from 'react';

import './tableExpiredContent.scss';
import colors from '../../atoms/Colors';
import Table from '../../molecules/Table';
import TagFilter from '../../molecules/TagFilter';
import { useModal } from '../../../hooks/useModal';
import SelectComp from '../../molecules/SelectComp';
import IconAlert from '../../atoms/icons/IconAlert';
import ButtonLineal from '../../molecules/ButtonLineal';
import ButtonOutline from '../../molecules/ButtonOutline';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import TagStatusReport from '../../molecules/TagStatusReport';
import PERMISSIONS from '../../../constants/permission.constants';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';
import { AlertSnackbar } from '../../common/AlertSnackbar/AlertSnackbar';
import { getQualificationForTeacher } from '../../../services/qualifications';
import useReportOverdueContent from '../../../hooks/useReportOverdueContent';
import ModalMessageAlert from '../../molecules/ModalMessageAlert/ModalMessageAlert';
import { patchStatusOverdueContentReport } from '../../../services/b2b/overdueContent';
import { IOverdueContentReport } from '../../../interfaces/overdueContentReports.interface';
import ModalDetailReportOverdueContent from '../../molecules/ModalDetailReportOverdueContent';
import { FILTERS_FIELD_NAMES_TRANSLATIONS } from '../../../constants/reportOverdue.constants';

interface Props {
  user: any;
}

const TableExpiredContent: React.FC<Props> = ({ user }) => {
  const {
    openModal: openModalAlert,
    closeModal: closeModalAlert,
    isOpenModal: isOpenModalAlert
  } = useModal();
  const {
    openModal: openModalDetailReport,
    closeModal: closeModalDetailReport,
    isOpenModal: isOpenModalDetailReport
  } = useModal();
  const {
    openModal: openModalEvaluation,
    closeModal: closeModalEvaluation,
    isOpenModal: isOpenModalEvaluation
  } = useModal();

  const listStatus = [
    { status: 'pending', name: 'Pendiente' },
    { status: 'done', name: 'Resuelto' },
    { status: 'all', name: 'Todos' }
  ];
  const userId = user?.permission[PERMISSIONS.roomListAll] ? 0 : user?.id;
  const userAdmin = user?.permission[PERMISSIONS.roomListAll];
  const isAdmin = user?.permission.name === 'Manager';

  const [isSuccessAlert, setIsSuccessAlert] = useState<boolean>(false);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [generalFilter, setGeneralFilter] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState({
    status: 'pending',
    name: 'Pendiente'
  });
  const [stateQualifications, setStateQualifications] = useState(false);
  const [detailReportByRoom, setDetailReportByRoom] =
    useState<IOverdueContentReport>();

  const {
    size,
    resetFilter,
    overdueContentReports,
    isLoading,
    totalPages,
    totalCount,
    currentPage,
    setCurrentPage,
    currentFilters,
    setCurrentFilters
  } = useReportOverdueContent(
    shouldRefresh,
    selectedStatus?.status,
    generalFilter,
    setGeneralFilter
  );

  useEffect(() => {
    if (listStatus.length > 0) {
      setSelectedStatus(listStatus[0]);
    }
  }, []);

  const handleViewDetailReport = (reportDetail: any) => {
    setDetailReportByRoom(reportDetail);
    openModalDetailReport();
  };

  const getCellStyle = (cell: any, userAdmin: boolean) => {
    const isPending = cell.row.original.status_evaluation === 'pending';
    const hasEndDate = cell.row.original.end_date_evaluation !== null;
    const isAccepted = isPending && hasEndDate && !userAdmin;
    const cellStyle: CSSProperties = {color: isAccepted ? colors.statusAbsenceText : 'black'};
    return cellStyle;
  };

  const customCellGroupNameOrId = ({ cell }: any) => {
    const cellStyle = getCellStyle(cell, userAdmin);
    return <div style={cellStyle}>{cell.value}</div>;
  };

  const customCellSolvedBy = ({ cell }: any) => {
    return (
      <>
        {cell.value ? (
          cell.value
        ) : (
          <span style={{ textAlign: 'center' }}>-</span>
        )}
      </>
    );
  };

  const customCellEvaluationDate = ({ cell }: any) => {
    const cellStyle = getCellStyle(cell, userAdmin);
    return (
      <div style={cellStyle}>
        {cell.value != null ? moment(cell.value).format('DD-MM-YY') : ''}
      </div>
    );
  };

  const customCellRoomName = ({ cell }: any) => <>{cell.value}</>;
  const customCellStatusTag = ({ cell }: any) => (
    <TagStatusReport status={cell.value}>{cell.value}</TagStatusReport>
  );

  const customCellShowReport = (props: any) => {
    return (
      <ButtonLineal
        size="medium"
        onClick={() => handleViewDetailReport(props.row.original)}
      >
        Ver reporte
      </ButtonLineal>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Reportado el',
        accessor: 'created_at',
        classColumn: 'end-date-evaluation',
        widthColumn: 132,
        disableSortBy: false,
        disableFilters: true,
        Cell: customCellEvaluationDate
      },
      {
        Header: 'ID',
        accessor: 'id',
        minWidthColumn: 42,
        disableSortBy: true,
        Cell: customCellGroupNameOrId
      },
      {
        Header: 'Salón',
        accessor: 'room.name',
        classColumn: 'name-group',
        widthColumn: 300,
        Cell: customCellRoomName,
        hasTooltip: true,
        disableSortBy: true
      },
      {
        Header: 'Salón',
        accessor: 'room.id',
        hideColumn: true
      },
      {
        Header: 'Profesor',
        accessor: 'teacher',
        classColumn: 'name-teacher',
        widthColumn: 152,
        hasTooltip: true,
        disableSortBy: false,
        disableFilters: true,
        Cell: customCellSolvedBy
      },
      {
        Header: 'Clases atrasadas',
        accessor: 'count',
        align: 'center',
        disableSortBy: false,
        disableFilters: true,
        widthColumn: 81
      },
      {
        Header: 'Estado',
        accessor: 'status',
        align: 'center',
        Cell: customCellStatusTag,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Solucionado por',
        accessor: 'user',
        classColumn: 'name-teacher',
        widthColumn: 152,
        align: 'center',
        hasTooltip: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellSolvedBy
      },
      {
        Header: '',
        accessor: 'action',
        actionWidth: 160,
        hasTooltip: true,
        tooltipValue: 'Ir a Zoom',
        tooltipValue1: 'Ir a Wiki',
        Cell: customCellShowReport,
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      }
    ],
    []
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (stateQualifications && !userAdmin) {
        openModalEvaluation();
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [stateQualifications]);

  useEffect(() => {
    if (Number(userId) !== 0) {
      getPendingQualification(Number(userId));
    }
  }, []);

  const getPendingQualification = async (userId: number) => {
    try {
      const { data } = await getQualificationForTeacher(userId);
      setStateQualifications(data);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        console.log(error.response?.data);
      }
    }
  };

  const handleModal = () => {
    closeModalEvaluation();
  };

  const handleClickCourse1 = (option: any): void => {
    setSelectedStatus(option);
    setShouldRefresh(true);
    setTimeout(() => {
      setShouldRefresh(false);
    }, 500);
  };

  const handleOpenModalAlert = (): void => {
    closeModalDetailReport();
    openModalAlert();
  };

  const handleResolveReport = async (reportId: number) => {
    try {
      await patchStatusOverdueContentReport(reportId, 'done');
      setShouldRefresh(true);
      setIsSuccessAlert(true);
      setTimeout(() => {
        setShouldRefresh(false);
      }, 500);
      setTimeout(() => {
        setIsSuccessAlert(false);
      }, 5000);
      closeModalAlert();
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    }
  };

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters,
    totalRows: totalCount,
    totalPages,
    pageSize: size
  };

  if (isLoading) return <ScreenLoader />;

  return (
    <>
      <Table
        title="Reportes de atraso de contenido"
        data={overdueContentReports}
        columns={columns}
        paginationState={paginationState}
        filterTag={
          Object.keys(currentFilters).length > 0
            ? Object.keys(currentFilters).map((key: string) => {
                return (
                  <TagFilter
                    keyFilter={FILTERS_FIELD_NAMES_TRANSLATIONS.get(key)}
                    valueFilter={currentFilters[key]}
                    onClickResetFilter={() => resetFilter(key)}
                  />
                );
              })
            : null
        }
        childSectionHeader={
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <Typography type="paragraph2" variation="mobile" bold>
              Estado
            </Typography>
            <SelectComp
              size="small"
              width="139px"
              onclickDefaultValue={handleClickCourse1}
              options={listStatus}
              dividerItems
              value={selectedStatus}
              placeholder="Estado"
            />
          </div>
        }
      />
      <ModalMessageAlert
        className="table-evaluation-student__modal-alert"
        width="sm"
        iconAlert={<IconAlert fill={colors.warningColor} />}
        title={
          <Typography bold type="paragraph1" variation="desktop" align="center">
            Tienes salones pendientes de evaluación
          </Typography>
        }
        isOpenModal={isOpenModalEvaluation}
        openModal={openModalEvaluation}
        closeModal={closeModalEvaluation}
        body={
          <Typography type="paragraph2" variation="mobile" align="center">
            Por favor, asegúrate completarlos antes de la fecha de vencimiento.
          </Typography>
        }
        button1={<ButtonFilled onClick={handleModal}>Entendido</ButtonFilled>}
      />
      <ModalDetailReportOverdueContent
        information={detailReportByRoom}
        isAdmin={isAdmin}
        isStatusVisible={
          isAdmin && detailReportByRoom?.status === 'pending' ? false : true
        }
        isOpenModal={isOpenModalDetailReport}
        openModal={openModalDetailReport}
        closeModal={closeModalDetailReport}
        button1={
          detailReportByRoom?.status === 'pending' ? (
            <ButtonOutline size="default" onClick={closeModalDetailReport}>
              Volver
            </ButtonOutline>
          ) : (
            <ButtonFilled size="default" onClick={closeModalDetailReport}>
              Aceptar
            </ButtonFilled>
          )
        }
        button2={
          <ButtonFilled size="default" onClick={handleOpenModalAlert}>
            Resolver reporte
          </ButtonFilled>
        }
      />
      <ModalMessageAlert
        width="sm"
        title={
          <Typography bold type="h1" align="center" variation="mobile">
            Al confirmar, no se podrá modificar el estado del reporte
          </Typography>
        }
        isOpenModal={isOpenModalAlert}
        openModal={openModalAlert}
        closeModal={closeModalAlert}
        buttonColumns
        body={
          <Typography align="center" type="paragraph1" variation="mobile">
            Asegúrate de resolver el reporte solo si el mismo ya fue revisado
          </Typography>
        }
        button1={
          <ButtonFilled
            fullWidth
            onClick={() =>
              detailReportByRoom && detailReportByRoom?.id
                ? handleResolveReport(detailReportByRoom?.id)
                : {}
            }
          >
            Marcar como resuelto
          </ButtonFilled>
        }
        button2={
          <ButtonLineal size="default" onClick={closeModalAlert}>
            Volver
          </ButtonLineal>
        }
      />
      {isSuccessAlert ? (
        <AlertSnackbar
          message="Resolviste un reporte correctamente"
          severity="success"
        />
      ) : null}
      {isErrorAlert ? (
        <AlertSnackbar
          message="Ocurrió un error al enviar la notificación de atraso de contenido. Vuelve a intentar"
          severity="error"
        />
      ) : null}
    </>
  );
};

export default TableExpiredContent;
