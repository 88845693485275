import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Rooms from './Rooms/Rooms';
import Students from './Students/Students';
import Certificates from './Certificates/Certificates';
import Tabs from '../../../components/molecules/Tabs';
import {
  listRoomsByGroupId,
  selectStateRoomsByGroupId
} from '../../../slices/roomSlice/roomsByGroupIdSlice';
import { GroupRoomsHeader } from './Header/GroupRoomsHeader';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';

const GroupRooms = () => {
  const { groupId } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const { rooms, isLoading } = useSelector(selectStateRoomsByGroupId);
  const [refetchListRoomsByGroupId, setRefetchListRoomsByGroupId] =
    useState(false);

  useEffect(() => {
    if (groupId) {
      dispatch(listRoomsByGroupId(Number(groupId)));
    }
  }, [groupId, refetchListRoomsByGroupId]);

  if (isLoading || !rooms) return <ScreenLoader />;

  return (
    <Grid
      style={{
        overflowY: 'scroll',
        height: '100%',
        padding: '2.8rem 0.75rem 2.2rem',
        boxSizing: 'border-box'
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        rowGap={2}
      >
        <GroupRoomsHeader rooms={rooms} />

        {/* Sections */}
        <Grid item container rowGap={1.5} xs={12}>
          <div style={{ width: 'calc(100% - 3.5rem)' }}>
            <Tabs
              width={300}
              tabHeaders={
                rooms?.business_type === 'B2B'
                  ? ['Salones', 'Estudiantes', 'Certificados']
                  : ['Salones', 'Estudiantes']
              }
              tabContents={[
                <Rooms
                  rooms={rooms.rooms}
                  teachers={rooms.teachers}
                  groupId={rooms.id}
                  isFetching={!rooms}
                />,
                <Students
                  setRefetchListRoomsByGroupId={setRefetchListRoomsByGroupId}
                  students={rooms.students}
                  rooms={rooms.rooms}
                  groupId={rooms.id}
                  isFetching={!rooms}
                />,
                <Certificates rooms={rooms} students={rooms.students} />
              ]}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupRooms;
