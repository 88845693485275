import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOrganizationFromApi,
  selectStateOrganizations
} from '../slices/CTCB2B/organizationSlice/organizationSlice';

type FilterState = {
  [key: string]: string;
};

const useOrganizations = (shouldRefresh: any) => {
  const size = 100;
  const currentFiltersInitialState: FilterState = {};

  const dispatch = useDispatch();
  const {
 organizations, isLoading, totalCount, totalPages 
} = useSelector(
    selectStateOrganizations
  );
  const [filters, setFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );

  useEffect(() => {
    if (!hasFilters) {
      dispatch(getOrganizationFromApi({ page: currentPage, size }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentFilters]);

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);
      setFilters(false);
      return;
    }

    if (hasFilters) {
      if (currentFilters?.id && currentFilters?.id?.length <= 0) {
        return;
      }

      if (currentFilters?.name && currentFilters?.name?.length < 3) {
        return;
      }

      if (
        currentFilters?.organization_type &&
        currentFilters?.organization_type?.length < 3
      ) {
        return;
      }

      if (
        currentFilters?.country_id &&
        currentFilters?.country_id?.length < 3
      ) {
        return;
      }

      if (
        currentFilters?.business_name &&
        currentFilters?.business_name?.length < 3
      ) {
        return;
      }

      if (
        currentFilters?.business_owner &&
        currentFilters?.business_owner?.length < 3
      ) {
        return;
      }

      dispatch(
        getOrganizationFromApi({
          page: currentPage,
          size,
          filters: currentFilters
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  useEffect(() => {
    if (!hasFilters) {
      setFilters(false);
    } else {
      setFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  useEffect(() => {
    if (shouldRefresh) {
      setCurrentPage(1);
      setCurrentFilters({});
      setFilters(false);
    }
  }, [shouldRefresh]);

  const hasFilters = Object.keys(currentFilters).length !== 0;

  const resetFilter = (value: string) => {
    const newState = { ...currentFilters };
    delete newState[value];

    setCurrentFilters(newState);
    setCurrentPage(1);
  };

  return {
    size,
    filters,
    isLoading,
    totalPages,
    totalCount,
    setFilters,
    hasFilters,
    currentPage,
    resetFilter,
    organizations,
    setCurrentPage,
    currentFilters,
    setCurrentFilters
  };
};

export default useOrganizations;
