/* eslint-disable complexity */
/* eslint-disable react/jsx-key */
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { Button, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import {
  syncTeacherWithMoodle,
  getTeachersByAvailabilityV2
} from '../../services/teachers';
import './teacherAvailability.scss';
import colors from '../../components/atoms/Colors';
import Table from '../../components/molecules/Table';
import TagCourses from '../../components/molecules/TagCourses';
import TagModality from '../../components/molecules/TagModality';
import ButtonLineal from '../../components/molecules/ButtonLineal';
import FloatingMenu from '../../components/molecules/FloatingMenu';
import DefaultLayout from '../../components/templates/DefaultLayout';
import Typography from '../../components/atoms/Typography/Typography';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import ItemFloatingMenu from '../../components/molecules/ItemFloatingMenu';
import { FILTERS_FIELD_NAMES_TRANSLATIONS } from '../../constants/teacherList.constants';

const TeacherAvailability = () => {
  const size = 100;
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const [filters, setFilters] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  type FilterState = {
    [key: string]: string;
  };
  const currentFiltersInitialState: FilterState = {};
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );

  const resetFilter = (value: string) => {
    const newState = { ...currentFilters };
    delete newState[value];
    setCurrentFilters(newState);
    setCurrentPage(1);
  };

  const hasFilters = Object.keys(currentFilters).length !== 0;

  const listTeacher = async ({
    currentPage,
    size,
    id,
    name,
    last_name,
    status,
    modality,
    email
  }: {
    currentPage: number;
    size: number;
    id?: string;
    name?: string;
    last_name?: string;
    status?: string;
    modality?: string;
    email?: string;
  }) => {
    const response = await getTeachersByAvailabilityV2(
      currentPage,
      size,
      id,
      name,
      last_name,
      status,
      modality,
      email
    );
    if (response) {
      setCount(response.count);
      setTotalPages(response.total_pages);
      setTeachers(response.results);
    }
  };

  const syncTeacherAccountMoodle = async (teacherId: number) => {
    try {
      await syncTeacherWithMoodle(teacherId);
      setCurrentFilters({});
      toast.success('Se sincronizó correctamente el profesor');
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error.response?.status === 417) {
        toast.error(error.response?.data?.error);
      } else {
        toast.error(error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    if (!hasFilters) {
      listTeacher({ currentPage, size });
    }
  }, [currentPage, currentFilters]);

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);
      setFilters(false);
      return;
    }

    if (hasFilters) {
      if (currentFilters?.id && currentFilters?.id?.length < 3) {
        return;
      }

      if (
        currentFilters?.first_name &&
        currentFilters?.first_name?.length < 3
      ) {
        return;
      }

      if (currentFilters?.last_name && currentFilters?.last_name?.length < 3) {
        return;
      }

      if (currentFilters?.status && currentFilters?.status?.length < 3) {
        return;
      }

      if (
        currentFilters?.modality_CTC &&
        currentFilters?.modality_CTC?.length < 3
      ) {
        return;
      }

      if (currentFilters?.username && currentFilters?.username?.length < 3) {
        return;
      }

      listTeacher({
        currentPage,
        size,
        id: currentFilters?.id,
        name: currentFilters?.first_name,
        last_name: currentFilters?.last_name,
        status: currentFilters?.status,
        modality: currentFilters?.modality_CTC,
        email: currentFilters?.username
      });
    }
  }, [currentPage, filters]);

  useEffect(() => {
    if (!hasFilters) {
      setFilters(false);
    } else {
      setFilters(true);
    }
  }, [currentFilters]);

  const capitalizeWords = (str: any) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        widthColumn: 40,
        disableSortBy: true
      },
      {
        Header: 'Nombres',
        accessor: 'first_name',
        minWidthColumn: 140,
        classColumn: 'name-group',
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Apellidos',
        accessor: 'last_name',
        classColumn: 'name-teacher',
        widthColumn: 190,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Estado',
        accessor: 'status',
        classColumn: 'status-teacher',
        widthColumn: 110,
        Cell: ({ cell }: any) => (
          <>
            <span
              style={{
                color:
                  cell.value === 'activo'
                    ? colors.statusAttendedText
                    : cell.value === 'desactivando'
                      ? colors.statusDelayText
                      : cell.value === 'inactivo'
                        ? colors.statusAbsenceText
                        : colors.gris05
              }}
            >
              {capitalizeWords(cell.value)}
            </span>
          </>
        ),
        style: { color: 'red' },
        disableSortBy: true,
        filter: (rows: any, id: any, filterValue: any) =>
          rows.filter((row: any) => filterValue === row.values[id])
      },
      {
        Header: 'Moodle Id',
        accessor: 'user_mdl_id',
        widthColumn: 110,
        align: 'center',
        Cell: ({ cell }: any) => <>{cell.value}</>,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Modalidad',
        accessor: 'modality_CTC',
        hasSort: true,
        widthColumn: 150,
        Cell: ({ cell }: any) => (
          <span style={{ textTransform: 'capitalize' }}>
            <TagModality>
              {cell.value === 'locator'
                ? 'Locador'
                : cell.value === 'self'
                  ? 'Por Horas'
                  : 'Part-Time'}
            </TagModality>
          </span>
        ),
        disableSortBy: true
      },
      {
        Header: 'Correo',
        accessor: 'username',
        widthColumn: 225,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        ),
        disableSortBy: true
      },
      {
        Header: 'Discord',
        accessor: 'discord',
        widthColumn: 190,
        align: 'center',
        Cell: ({ cell }: any) => <>{cell.value}</>,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Teléfono',
        accessor: 'phone_number',
        widthColumn: 120,
        align: 'center',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: '',
        accessor: 'action',
        actionWidth: 58,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <>
              <ButtonLineal
                size="small"
                to={`/dashboard/teachers/edit/${props.row.original.id}`}
              >
                Ver más
              </ButtonLineal>
              <FloatingMenu
                items={[
                  <ItemFloatingMenu
                    onClick={() => {
                      navigate(
                        `/dashboard/teachers/edit/${props.row.original.id}`
                      );
                    }}
                  >
                    Editar
                  </ItemFloatingMenu>,
                  <ItemFloatingMenu
                    onClick={async () => {
                      await syncTeacherAccountMoodle(props.row.values.id);
                    }}
                  >
                    Sincronizar plataformas
                  </ItemFloatingMenu>
                ]}
              />
            </>
          );
        }
      }
    ],
    []
  );

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage: currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters: currentFilters,
    totalRows: count,
    totalPages,
    pageSize: size
  };

  if (!teachers) return <ScreenLoader />;

  return (
    <DefaultLayout>
      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate('/dashboard/teachers/create')}
        startIcon={<AddCircleIcon />}
      >
        {'Crear nuevo profesor'}
      </Button>
      <Typography
        type="h2"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Profesores y su disponibilidad
      </Typography>
      <Typography
        type="paragraph2"
        variation="mobile"
        className="teacher-availability teacher-availability__subtitle"
      >
        Ingresa a Editar para cambiar la disponibilidad y más datos de cada
        profesor.
      </Typography>
      {Object.keys(currentFilters).length > 0 ? (
        <>
          <section className="guardians_filter__container_courses">
            <p className="typography typography__paragraph2 typography--undefined typography__paragraph2--mobile typography__bold  teacher-profile__typography">
              Filtro Actual:{' '}
            </p>
            {Object.keys(currentFilters).map((key: string, index, test) => {
              return (
                <>
                  <TagCourses className="guardians_filter__tag">
                    <Typography type="paragraph2" variation="mobile" bold>
                      {FILTERS_FIELD_NAMES_TRANSLATIONS.get(key) +
                        ': ' +
                        currentFilters[key]}
                    </Typography>
                    <ButtonLineal
                      size="small"
                      className="guardians_filter__button_removal"
                      onClick={() => resetFilter(key)}
                    >
                      &#10005;
                    </ButtonLineal>
                  </TagCourses>
                </>
              );
            })}
          </section>
        </>
      ) : null}
      <Table
        data={teachers}
        columns={columns}
        paginationState={paginationState}
      />
    </DefaultLayout>
  );
};

export default TeacherAvailability;
