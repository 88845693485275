// React
import { memo, useState } from 'react';

// Interfaces
import { Invoice } from '../../../../../../interfaces/guardian.interface';

// Components
import EditInvoice from './EditInvoice/EditInvoice';
import OpenModal from '../../../../../../components/Modal/OpenModal/OpenModal';
import Modal from '../../../../../../components/Modal/Modal';

interface Props {
  guardianId: number;
  invoiceId: number;
  invoice: any | Invoice;
  //refetch: () => {};
}

const ModalEditInvoice: React.FC<Props> = ({
  guardianId,
  invoiceId,
  invoice
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal
        option="edit"
        placement="top"
        handleOpenModal={handleOpenModal}
      />

      {/* Modal Edit Invoice */}
      <Modal
        id={invoiceId}
        title="Editar Factura"
        fullWidth
        maxWidth="sm"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <EditInvoice
          guardianId={guardianId}
          invoiceId={invoiceId}
          invoice={invoice}
          //refetch={refetch}
          onClose={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default memo(ModalEditInvoice);
