/* eslint-disable max-lines */
/* eslint-disable complexity */
import moment from 'moment';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useParams, useNavigate } from 'react-router-dom';
import React, { SyntheticEvent, useState, useEffect } from 'react';

import { useModal } from '../../../hooks/useModal';
import { countries } from '../../../constants/countries';
import { CreateTeacherSchema } from './validation.schema';
import useGetAgeGroup from '../../../hooks/useGetAgeGroup';
import useGetKnowledges from '../../../hooks/useGetKnowledges';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import { getTeacherProfileByIdV2 } from '../../../services/teachers';
import useGetSubknowledges from '../../../hooks/useGetSubknowledges';
import ButtonLineal from '../../../components/molecules/ButtonLineal';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import useGetCoursesTrained from '../../../hooks/useGetCoursesTrained';
import Typography from '../../../components/atoms/Typography/Typography';
import DefaultLayout from '../../../components/templates/DefaultLayout';
import IconAvatarProfile from '../../../components/atoms/icons/IconAvatarProfile';
import {
  CreateTeacherTab1,
  CreateTeacherTab2
} from '../../../pages/Teachers/index';
import {
  addTeacher,
  updateTeacher,
  addTeacherAvailability,
  updateTeacherAvailability
} from '../../../slices/teacherSlice/teacherSlice';
import ModalInactiveStudent from '../../../components/molecules/ModalInactiveStudent/ModalInactiveStudent';

export const CreateTeacher: React.FC<{ isProfile?: boolean }> = ({isProfile}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { teacherId } = useParams();
  const paramsURL = new URLSearchParams(location.search);
  const tabParam = paramsURL.get('tab');
  const [currentPosition, setCurrentPosition] = useState<string>(
    tabParam ? tabParam : '0'
  );

  const {
    openModal: openModalCancel,
    closeModal: closeModalCancel,
    isOpenModal: isOpenModalCancel
  } = useModal();

  const {
    openModal: openModalCancelEdit,
    closeModal: closeModalCancelEdit,
    isOpenModal: isOpenModalCancelEdit
  } = useModal();

  const initialValues = {
    first_name: '',
    last_name: '',
    modality_CTC: '',
    email: '',
    phone_number: '',
    discord: '',
    country: '',
    city: '',
    password: '',
    profession: '',
    grade: '',
    has_pedagogical: false,
    has_operational: false,
    has_experience_big_groups: false,
    has_experience_with_adults: false,
    has_experience_vulnerable_groups: false,
    on_site_availability: false,
    status: '',
    payment_country: '',
    payment_currency: '',
    payment_frequency: '',
    end_date_first_payment_period: '',
    date_start: '',
    date_end: null,
    doc_type: '',
    doc_number: '',
    username: '',
    optional_email: '',
    teacher_schedules: { day_available: [] },
    teacher_age_groups: [],
    teacher_courses: [],
    teacher_knowledges: [],
    teacher_subknowledges: []
  };

  const [shouldRefreshAgeGroup, setShouldRefreshAgeGroup] = useState(false);
  const [shouldRefreshCourseTrained, setShouldRefreshCourseTrained] =
    useState(false);
  const [shouldRefreshKnowledges, setShouldRefreshKnowledges] = useState(false);
  const [shouldRefreshSubknowledges, setShouldRefreshSubknowledges] =
    useState(false);
  const [teacherData, setTeacherData] = useState<any>(initialValues);
  const [ageGroups, setAgeGroups] = useState<any>([]);
  const [teacherCourses, setTeacherCourses] = useState<any>([]);
  const [teacherKnowledges, setTeacherKnowledges] = useState<any>([]);
  const [teacherSubknowledges, setTeacherSubknowledges] = useState<any>([]);
  const [defaultCountry, setDefaultCountry] = useState<any>();

  const findScheduleByDay = (day: any, values: any) => {
    const lowerCaseDay = day.toLowerCase();

    const foundSchedule = values?.teacher_schedules?.day_available.find(
      (schedule: any) => schedule.day === lowerCaseDay
    );

    return foundSchedule || false;
  };

  const [isActive1, setIsActive1] = useState(false);
  const [schedule1, setSchedule1] = useState([]);
  const [isError1, setIsError1] = useState(false);

  const [isActive2, setIsActive2] = useState(false);
  const [schedule2, setSchedule2] = useState([]);
  const [isError2, setIsError2] = useState(false);

  const [isActive3, setIsActive3] = useState(false);
  const [schedule3, setSchedule3] = useState([]);
  const [isError3, setIsError3] = useState(false);

  const [isActive4, setIsActive4] = useState(false);
  const [schedule4, setSchedule4] = useState([]);
  const [isError4, setIsError4] = useState(false);

  const [isActive5, setIsActive5] = useState(false);
  const [schedule5, setSchedule5] = useState([]);
  const [isError5, setIsError5] = useState(false);

  const [isActive6, setIsActive6] = useState(false);
  const [schedule6, setSchedule6] = useState([]);
  const [isError6, setIsError6] = useState(false);

  const [teacherProfileV2, setTeacherProfileV2] = useState<any>();

  const [isActiveSwPedagogica, setIsActiveSwPedagogica] = useState(false);
  const [isActiveSwOperativa, setIsActiveSwOperativa] = useState(false);
  const [isActiveSwExperienceLargeGroups, setIsActiveSwExperienceLargeGroups] =
    useState(false);
  const [isActiveSwExperience25Years, setIsActiveSwExperience25Years] =
    useState(false);
  const [
    isActiveSwExperienceVulnerableGroups,
    setIsActiveSwExperienceVulnerableGroups
  ] = useState(false);
  const [isActiveSwPresencial, setIsActiveSwPresencials] = useState(false);

  const [selectOptionDocumentType, setSelectOptionDocumentType] =
    useState<any>('');
  const [isOpenSelectDocumentType, setIsOpenSelectDocumentType] =
    useState(false);

  const [selectOptionStatus, setSelectOptionStatus] = useState<any>('');
  const [isOpenSelectStatus, setIsOpenSelectStatus] = useState(false);

  const [isOpenSelectModality, setIsOpenSelectModality] = useState(false);
  const [selectOptionModality, setSelectOptionModality] = useState<any>('');

  const [isOpenSelectPaymentCurrency, setIsOpenSelectPaymentCurrency] =
    useState(false);
  const [selectOptionPaymentCurrency, setSelectOptionPaymentCurrency] =
    useState<any>('');

  const [isOpenSelectPaymentFrequency, setIsOpenSelectPaymentFrequency] =
    useState(false);
  const [selectOptionPaymentFrequency, setSelectOptionPaymentFrequency] =
    useState<any>('');

  const { data: ageGroupData, isLoading: isLoadingAgeGroup } = useGetAgeGroup({shouldRefetch: shouldRefreshAgeGroup});

  const { data: courseTrainedData, isLoading: isLoadingCourseTrained } =
    useGetCoursesTrained({ shouldRefetch: shouldRefreshCourseTrained });

  const { data: knowledgesData, isLoading: isLoadingKnowledges } =
    useGetKnowledges({ shouldRefetch: shouldRefreshKnowledges });

  const teacherProfileById = async () => {
    const responseV2: any = await getTeacherProfileByIdV2(Number(teacherId));
    if (responseV2) {
      setTeacherProfileV2(responseV2);
      const getDefaultCountry = countries.find(
        country => country.code === responseV2?.country
      );
      setDefaultCountry(getDefaultCountry);
      setTeacherData(responseV2);
      setAgeGroups(responseV2.teacher_age_groups);
      setTeacherCourses(responseV2.teacher_courses);
      setTeacherKnowledges(responseV2.teacher_knowledges);
      setTeacherSubknowledges(responseV2.teacher_subknowledges);
    }
  };

  const createTeacher = async (payload: any) => {
    try {
      const response: any = await dispatch(addTeacher(payload));
      if (!response?.error?.message) {
        setHasError(false);
        navigate('/dashboard/teachers/teacher-availability');
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const addOrUpdateTeacherProfile = async (
    payload: any,
    isUpdate?: boolean
  ) => {
    if (isUpdate) {
      await dispatch(updateTeacherAvailability({ id: teacherId, payload }));
      teacherProfileById();
      return;
    }

    await dispatch(addTeacherAvailability({ id: teacherId, payload }));
    teacherProfileById();
  };

  const goTo = () => {
    if (!isProfile) {
      navigate('/dashboard/teachers/teacher-availability');
    }
  };

  const editTeacher = async (payload: any) => {
    try {
      const response: any = await dispatch(
        updateTeacher({ id: teacherId, payload })
      );
      if (!response?.error?.message) {
        setHasError(false);
        goTo();
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  useEffect(() => {
    setIsActiveSwPedagogica(teacherData?.has_pedagogical);
    setIsActiveSwOperativa(teacherData?.has_operational);
    setIsActiveSwExperienceLargeGroups(teacherData?.has_experience_big_groups);
    setIsActiveSwExperience25Years(teacherData?.has_experience_with_adults);
    setIsActiveSwExperienceVulnerableGroups(
      teacherData?.has_experience_vulnerable_groups
    );
    setIsActiveSwPresencials(teacherData?.on_site_availability);
    setIsActive1(findScheduleByDay('monday', teacherData).available);
    setIsActive2(findScheduleByDay('tuesday', teacherData).available);
    setIsActive3(findScheduleByDay('wednesday', teacherData).available);
    setIsActive4(findScheduleByDay('thursday', teacherData).available);
    setIsActive5(findScheduleByDay('friday', teacherData).available);
    setIsActive6(findScheduleByDay('saturday', teacherData).available);
  }, [teacherData]);

  useEffect(() => {
    teacherProfileById();
  }, []);

  const { data: subknowledgesData, isLoading: isLoadingSubknowledges } =
    useGetSubknowledges({ shouldRefetch: shouldRefreshSubknowledges });

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) => {
    setCurrentPosition(newValue);
  };

  const [hasError, setHasError] = useState(false);

  const optionSelectionDocumentType = (option: any): void => {
    setSelectOptionDocumentType(option);
    // setAlertSelectDocType(false);
  };

  const optionSelectionStatus = (option: any): void => {
    setSelectOptionStatus(option);
    // setAlertSelectStatus(false);
  };

  const optionSelectionModality = (option: any): void => {
    setSelectOptionModality(option);
    // setAlertSelectModality(false);
  };

  const optionSelectionPaymentCurrency = (option: any): void => {
    setSelectOptionPaymentCurrency(option);
    // setAlertSelectPaymentCurrency(false);
  };

  const optionSelectionPaymentFrequency = (option: any): void => {
    setSelectOptionPaymentFrequency(option);
    // setAlertSelectaymentFrequency(false);
  };

  const handleClickCancel = () => {
    navigate('/dashboard/teachers/teacher-availability');
  };

  const handleClickCancelProfile = () => {
    navigate('/dashboard');
  };

  const RenderHeaderCreate = () => {
    return (
      <>
        <Typography
          type="h2"
          variation="mobile"
          bold
          className="teacher-availability teacher-availability__title"
        >
          Crear profesor/a
        </Typography>
        <Typography
          type="paragraph2"
          variation="mobile"
          className="teacher-availability teacher-availability__subtitle"
        >
          Es necesario completar todos los campos obligatorios (*) para crear el
          perfil del profesor/a correctamente
        </Typography>
      </>
    );
  };

  const RenderHeaderEdit = () => {
    return (
      <div style={{ display: 'flex', marginTop: '30px', marginBottom: '30px' }}>
        <IconAvatarProfile />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '15px'
          }}
        >
          <Typography type="h2" variation="mobile" bold>
            {teacherData.full_name}
          </Typography>
          <Typography type="paragraph1" variation="mobile">
            ID: {teacherData.id}
          </Typography>
          {/* <Typography type="paragraph1" variation="mobile">
            ID moodle: 000
          </Typography> */}
        </div>
      </div>
    );
  };

  return (
    <DefaultLayout>
      <div>
        {teacherId ? <RenderHeaderEdit /> : <RenderHeaderCreate />}
        <>
          <Grid item container rowGap={1.5} xs={12}>
            <Formik
              enableReinitialize={true}
              validationSchema={!isProfile && CreateTeacherSchema}
              initialValues={teacherData}
              onSubmit={async (values, { resetForm }) => {
                const teacherSchedules = [
                  ...schedule1,
                  ...schedule2,
                  ...schedule3,
                  ...schedule4,
                  ...schedule5,
                  ...schedule6
                ];

                let payload = {
                  ...values,
                  username: values.email,
                  date_start: moment(values.date_start).format('YYYY-MM-DD'),
                  end_date_first_payment_period: moment(
                    values.end_date_first_payment_period
                  ).format('YYYY-MM-DD'),
                  teacher_schedules: { day_available: teacherSchedules },
                  has_pedagogical: isActiveSwPedagogica,
                  has_operational: isActiveSwOperativa,
                  has_experience_big_groups: isActiveSwExperienceLargeGroups,
                  has_experience_with_adults: isActiveSwExperience25Years,
                  has_experience_vulnerable_groups:
                    isActiveSwExperienceVulnerableGroups,
                  on_site_availability: isActiveSwPresencial
                };

                if (values.date_end) {
                  payload = {
                    ...payload,
                    date_end: moment(values.date_end).format('YYYY-MM-DD')
                  };
                }

                if (isProfile) {
                  addOrUpdateTeacherProfile(
                    payload.teacher_schedules.day_available,
                    teacherProfileV2.teacher_schedules.day_available.length > 0
                  );
                  return;
                }

                if (teacherId) {
                  delete payload.password;
                  editTeacher(payload);
                  return;
                }

                createTeacher(payload);
              }}
            >
              {({
                dirty,
                isValid,
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                submitForm
              }) => {
                const cancelButton = () => {
                  const hasExperiencesChanges =
                    teacherData?.has_pedagogical !== isActiveSwPedagogica ||
                    teacherData?.has_operational !== isActiveSwOperativa ||
                    teacherData?.has_experience_big_groups !==
                      isActiveSwExperienceLargeGroups ||
                    teacherData?.has_experience_with_adults !==
                      isActiveSwExperience25Years ||
                    teacherData?.has_experience_vulnerable_groups !==
                      isActiveSwExperienceVulnerableGroups ||
                    teacherData?.on_site_availability !== isActiveSwPresencial;

                  const teacherSchedules = [
                    ...schedule1,
                    ...schedule2,
                    ...schedule3,
                    ...schedule4,
                    ...schedule5,
                    ...schedule6
                  ];

                  const hasTeacherSchedulesChanges =
                    JSON.stringify(
                      teacherData?.teacher_schedules?.day_available
                    ) !== JSON.stringify(teacherSchedules);

                  if (isProfile && hasTeacherSchedulesChanges) {
                    return openModalCancelEdit();
                  }

                  if (isProfile && !hasTeacherSchedulesChanges) {
                    return handleClickCancelProfile();
                  }

                  if (
                    teacherId &&
                    !dirty &&
                    !hasExperiencesChanges &&
                    !hasTeacherSchedulesChanges
                  ) {
                    return handleClickCancel();
                  }

                  if (
                    teacherId &&
                    (dirty ||
                      hasExperiencesChanges ||
                      hasTeacherSchedulesChanges)
                  ) {
                    return openModalCancelEdit();
                  }

                  if (
                    !teacherId &&
                    !dirty &&
                    !hasExperiencesChanges &&
                    !hasTeacherSchedulesChanges
                  ) {
                    return handleClickCancel();
                  }

                  if (
                    !teacherId &&
                    (dirty ||
                      hasExperiencesChanges ||
                      hasTeacherSchedulesChanges)
                  ) {
                    return openModalCancel();
                  }

                  return handleClickCancelProfile();
                };

                return (
                  <Form>
                    <TabContext value={currentPosition}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: 'divider',
                          width: '100%'
                        }}
                      >
                        <TabList
                          onChange={handleChangePosition}
                          aria-label="teachers actions"
                        >
                          <Tab
                            label="Información general"
                            value="0"
                            sx={{ textTransform: 'none' }}
                          />
                          <Tab
                            label="Contrato & Pagos"
                            value="1"
                            sx={{ textTransform: 'none' }}
                          />
                        </TabList>
                      </Box>

                      <TabPanel value="0" sx={{ p: '16px', width: '100%' }}>
                        <CreateTeacherTab1
                          teacherProfileV2={teacherProfileV2}
                          teacherProfileById={teacherProfileById}
                          isProfile={isProfile}
                          defaultCountry={defaultCountry}
                          setAgeGroups={setAgeGroups}
                          setTeacherCourses={setTeacherCourses}
                          setTeacherKnowledges={setTeacherKnowledges}
                          setTeacherSubknowledges={setTeacherSubknowledges}
                          ageGroups={ageGroups}
                          teacherCourses={teacherCourses}
                          teacherKnowledges={teacherKnowledges}
                          teacherSubknowledges={teacherSubknowledges}
                          errors={errors}
                          touched={touched}
                          teacherId={teacherId}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          values={values}
                          ageGroupData={ageGroupData}
                          courseTrainedData={courseTrainedData}
                          knowledgesData={knowledgesData}
                          subknowledgesData={subknowledgesData}
                          selectDocType={{
                            selectOptionDocumentType,
                            setSelectOptionDocumentType,
                            isOpenSelectDocumentType,
                            setIsOpenSelectDocumentType,
                            optionSelectionDocumentType
                          }}
                          selectStatus={{
                            isOpenSelectStatus,
                            setIsOpenSelectStatus,
                            selectOptionStatus,
                            setSelectOptionStatus,
                            optionSelectionStatus
                          }}
                          activeToggle={{
                            isActiveSwPedagogica,
                            setIsActiveSwPedagogica,
                            isActiveSwOperativa,
                            setIsActiveSwOperativa,
                            isActiveSwExperienceLargeGroups,
                            setIsActiveSwExperienceLargeGroups,
                            isActiveSwExperience25Years,
                            setIsActiveSwExperience25Years,
                            isActiveSwExperienceVulnerableGroups,
                            setIsActiveSwExperienceVulnerableGroups,
                            isActiveSwPresencial,
                            setIsActiveSwPresencials
                          }}
                          availability={{
                            isActive1,
                            setIsActive1,
                            schedule1,
                            setSchedule1,
                            isError1,
                            setIsError1,
                            isActive2,
                            setIsActive2,
                            schedule2,
                            setSchedule2,
                            isError2,
                            setIsError2,
                            isActive3,
                            setIsActive3,
                            schedule3,
                            setSchedule3,
                            isError3,
                            setIsError3,
                            isActive4,
                            setIsActive4,
                            schedule4,
                            setSchedule4,
                            isError4,
                            setIsError4,
                            isActive5,
                            setIsActive5,
                            schedule5,
                            setSchedule5,
                            isError5,
                            setIsError5,
                            isActive6,
                            setIsActive6,
                            schedule6,
                            setSchedule6,
                            isError6,
                            setIsError6
                          }}
                        />
                      </TabPanel>

                      <TabPanel value="1" sx={{ p: '16px', width: '100%' }}>
                        <CreateTeacherTab2
                          isProfile={isProfile}
                          errors={errors}
                          touched={touched}
                          selectModality={{
                            optionSelectionModality,
                            isOpenSelectModality,
                            setIsOpenSelectModality,
                            selectOptionModality,
                            setSelectOptionModality
                          }}
                          selectPaymentCurrency={{
                            optionSelectionPaymentCurrency,
                            isOpenSelectPaymentCurrency,
                            setIsOpenSelectPaymentCurrency,
                            selectOptionPaymentCurrency,
                            setSelectOptionPaymentCurrency
                          }}
                          selectPaymentFrequency={{
                            optionSelectionPaymentFrequency,
                            isOpenSelectPaymentFrequency,
                            setIsOpenSelectPaymentFrequency,
                            selectOptionPaymentFrequency,
                            setSelectOptionPaymentFrequency
                          }}
                          teacherId={teacherId}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          values={values}
                        />
                      </TabPanel>
                    </TabContext>
                    <div style={{ height: '80px' }}></div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        bottom: 0,
                        // right: '40px',
                        left: 0,
                        backgroundColor: 'white',
                        width: '100%',
                        boxShadow:
                          '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
                        padding: '20px'
                      }}
                    >
                      <ButtonLineal size="large" onClick={cancelButton}>
                        Cancelar
                      </ButtonLineal>
                      <span />
                      <ButtonFilled
                        type="button"
                        size="large"
                        onClick={() => {
                          if (!isValid) {
                            toast.error(
                              'Hay campos obligatorios incompletos en una o ambas secciones.'
                            );
                          }
                          submitForm();
                        }}
                      >
                        Guardar
                      </ButtonFilled>
                      <div style={{ marginRight: '50px' }} />
                    </div>
                    <ModalInactiveStudent
                      width="xs"
                      title={'¿Quieres cancelar la creación del profesor?'}
                      message={
                        'Al salir perderás todos los campos completados hasta el momento'
                      }
                      isOpenModal={isOpenModalCancel}
                      openModal={openModalCancel}
                      closeModal={closeModalCancel}
                      button1={
                        <ButtonFilled
                          fullWidth={true}
                          size="default"
                          onClick={closeModalCancel}
                        >
                          Volver a editar
                        </ButtonFilled>
                      }
                      button2={
                        <ButtonLineal
                          size="default"
                          onClick={handleClickCancel}
                        >
                          Salir
                        </ButtonLineal>
                      }
                    />
                    <ModalInactiveStudent
                      width="xs"
                      title={'Tienes cambios sin guardar'}
                      message={
                        'Es importante completar los campos obligatorios que falten. Asimismo, si has realizado cambios, asegúrate de guardarlos para que se reflejen correctamente.'
                      }
                      isOpenModal={isOpenModalCancelEdit}
                      openModal={openModalCancelEdit}
                      closeModal={closeModalCancelEdit}
                      button1={
                        <ButtonFilled
                          fullWidth={true}
                          size="default"
                          onClick={closeModalCancelEdit}
                        >
                          Guardar los cambios
                        </ButtonFilled>
                      }
                      button2={
                        <ButtonLineal
                          size="default"
                          onClick={
                            isProfile
                              ? handleClickCancelProfile
                              : handleClickCancel
                          }
                        >
                          No guardar los cambios
                        </ButtonLineal>
                      }
                    />
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </>
      </div>
    </DefaultLayout>
  );
};
