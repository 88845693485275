import React from 'react';
import './colorFrame.scss';

interface Props {
  value?: string;
  onChange?: any;
  className?: any;
  dataTestId?: string;
}

const ColorFrame: React.FC<Props> = ({
  value,
  onChange,
  className,
  dataTestId = 'color-frame-test-id'
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={`color-frame ${className ? className : ''}`}
    >
      <input
        data-testid={`${dataTestId}-input`}
        id="color"
        type="color"
        value={value}
        onChange={onChange}
      />
      <label htmlFor="color">{value?.slice(1, 7).toUpperCase()}</label>
    </div>
  );
};

export default ColorFrame;
