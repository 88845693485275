// Material UI
import Box from '@mui/material/Box';

import './../../../rooms.scss';
import { AttendanceStudent } from './AttendanceStudent';
import { IRoomDetailSimplified } from '../../../../../interfaces/room.interface';

interface Props {
  refetch: () => void;
  room: IRoomDetailSimplified;
  refetchDetailHeader: () => void;
  qtySessions: any;
}

export const Students: React.FC<Props> = ({
  refetch,
  room,
  refetchDetailHeader,
  qtySessions
}) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginBottom: '1em',
          paddingBottom: '1em'
        }}
      >
        <AttendanceStudent
          roomInfo={room}
          refetch={refetch}
          refetchDetailHeader={refetchDetailHeader}
          qtySessions={qtySessions}
        />
      </Box>
    </>
  );
};
