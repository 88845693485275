// Interfaces

// Material UI
import { ListItemButton, ListItemText } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { IGroup } from '../../../../../../interfaces/enrolment.interface';

interface ListGroupItemProps {
  group: IGroup;
  groupIds: number[];
  handleGroup: (groupId: number) => () => void;
}

const ListGroupItem: React.FC<ListGroupItemProps> = ({
  group,
  groupIds,
  handleGroup
}) => {
  return (
    <ListItemButton
      sx={{
        width: '100%',
        border: '1px solid #E0E0E0',
        borderRadius: '6px',
        marginBottom: '5px'
      }}
      selected={groupIds.indexOf(group.id) !== -1}
      onClick={handleGroup(group.id)}
    >
      <ListItemText primary={group.name} secondary={group.id} />
      {groupIds.indexOf(group.id) !== -1 && (
        <AddBoxIcon fontSize="medium" color="primary" />
      )}
    </ListItemButton>
  );
};

export default ListGroupItem;
