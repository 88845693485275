import { useState, useEffect } from 'react';

import { getComplementStudentsByGroupId } from '../services/groups';

const useComplementStudents = ({
  groupId,
  openModal,
  shouldRefetch
}: {
  groupId: number;
  openModal: boolean;
  shouldRefetch?: boolean;
}) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const getComplementStudents = async () => {
    try {
      return await getComplementStudentsByGroupId(groupId);
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getComplementStudents();
      setData(response?.data?.complement_students);
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, shouldRefetch, openModal]);

  return { data, isLoading, isError };
};

export default useComplementStudents;
