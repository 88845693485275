/* eslint-disable max-lines */
/* eslint-disable complexity */
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import {
 Typography, colors, Button, Icon 
} from 'ctc-component-library';

import './roomDetailHeader.scss';
import { RootState } from '../../../../../store/store';
import { PATCH } from '../../../../../services/api/api';
import { clearString } from '../../../../../utils/utils';
import { IRoomDetailHeaderSimplified } from '../../../../../interfaces/room.interface';
import {
  ADMIN,
  TEACHER_AUXILIARY
} from '../../../../../constants/usersType.constants';
import AuxTeacherActionsModal from '../../../../../components/organism/AuxTeacherActionsModal';

interface Props {
  dataRoomHeader?: IRoomDetailHeaderSimplified;
  room?: any;
  refetch: () => void;
  setActiveTabMain: React.Dispatch<React.SetStateAction<number>>;
  setActiveTabTeachers: React.Dispatch<React.SetStateAction<number>>;
}

type RoomDetail = {
  label: string;
  value: string | number | boolean | null | undefined;
  icon?: boolean;
  iconType?: string;
  valueIcon?: string;
};

const RoomDetailHeader: React.FC<Props> = ({
  dataRoomHeader,
  room,
  refetch,
  setActiveTabMain,
  setActiveTabTeachers
}) => {
  const modalRef = useRef<any>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const teacherAuxiliary = user?.permission?.name === TEACHER_AUXILIARY;
  const isAdmin = user?.permission?.name === ADMIN;
  const [isVisibleMoreInfo, setIsVisibleMoreInfo] = useState(false);
  const [externalTeachers, setExternalTeachers] = useState<any>([]);

  const handleZoom = () => {
    if (!dataRoomHeader?.online_meeting) {
      toast.error('No existe información de Zoom asociada');
      return;
    } else {
      teacherAuxiliary
        ? window.open(dataRoomHeader?.online_meeting?.student_url, '_blank')
        : handleRefreshMeetingLink(); //We need to update Zoom Teacher URL because the Zoom Access Key (ZAK) has a validity of 2 hours since generation. More info here: https://developers.zoom.us/docs/meeting-sdk/auth/
    }
  };

  const handleRecordings = () => {
    if (dataRoomHeader?.google_drive_url_share) {
      toast.success('URL de grabaciones copiado...');
      navigator.clipboard.writeText(dataRoomHeader?.google_drive_url_share);
    } else toast.error('No existe URL de grabaciones');
  };

  const refreshMeetingLink = useMutation(
    (zoom_meeting_id: string) =>
      PATCH(`/meeting/${zoom_meeting_id}/refresh-start/`, { zoom_meeting_id }),
    { retry: false }
  );

  const handleRefreshMeetingLink = async () => {
    if (dataRoomHeader?.online_meeting.external_id) {
      await toast.promise(
        refreshMeetingLink
          .mutateAsync(clearString(dataRoomHeader?.online_meeting.external_id))
          .then(({ data: { start_url } }) => {
            setTimeout(() => {
              window.open(start_url, '_blank');
              refetch();
            }, 1000);
          }),
        {
          loading: 'Cargando...',
          success: 'Vamos a Zoom!',
          error: `Ha ocurrido un error. Inicie la clase con la cuenta <${dataRoomHeader?.online_meeting.licensed_user_email}>`
        },
        { success: { icon: <InsertLinkIcon color="primary" /> } }
      );
    }
  };

  const showMoreDetails = () => {
    setIsVisibleMoreInfo(!isVisibleMoreInfo);
  };

  const firstColumn: RoomDetail[] = [
    {
      label: 'Grupo',
      value: dataRoomHeader?.group.name,
      icon: isAdmin ? true : false,
      iconType: 'eye',
      valueIcon: `/dashboard/groups/${dataRoomHeader?.group?.id}/rooms`
    },
    {
      label: 'Horario',
      value: dataRoomHeader?.schedule
        .map(
          (s: any) =>
            `${s.day} ${s.start_time_formatted} a ${s.end_time_formatted}`
        )
        .join('\n')
    },
    {
      label: 'Zoom ID',
      value: dataRoomHeader?.online_meeting.external_id,
      icon: true,
      iconType: 'copy',
      valueIcon: ''
    },
    { label: 'Profesor CTC', value: dataRoomHeader?.main_teacher.full_name },
    { label: 'Fecha de inicio', value: dataRoomHeader?.group.start_date },
    { label: 'Fecha de fin', value: dataRoomHeader?.group.end_date }
  ];

  const secondColumn: RoomDetail[] = [
    {
      label: 'Acceso a Moodle',
      value: dataRoomHeader?.course_mdl_id
        ? `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        : '',
      icon: true,
      iconType: 'copy',
      valueIcon: ''
    },
    {
      label: 'Licencia',
      value: dataRoomHeader?.online_meeting.licensed_user_email
    },
    {
      label: 'Contraseña licencia',
      value: dataRoomHeader?.online_meeting.licensed_user_password,
      icon: true,
      iconType: 'copy',
      valueIcon: ''
    },
    {
      label: 'URL de estudiante',
      value: dataRoomHeader?.online_meeting.student_url,
      icon: true,
      iconType: 'copy',
      valueIcon: ''
    },
    {
      label: 'Contraseña meet',
      value: dataRoomHeader?.online_meeting.password,
      icon: true,
      iconType: 'copy',
      valueIcon: ''
    }
  ];

  const firstColumnAuxTeacher: RoomDetail[] = [
    {
      label: 'Grupo',
      value: dataRoomHeader?.group.name
    },
    {
      label: 'Horario',
      value: dataRoomHeader?.schedule
        .map(
          (s: any) =>
            `${s.day} ${s.start_time_formatted} a ${s.end_time_formatted}`
        )
        .join('\n')
    },
    {
      label: 'Profesor CTC',
      value: dataRoomHeader?.main_teacher.full_name,
      icon: true,
      iconType: 'copy',
      valueIcon: ''
    },
    {
      label: 'Acceso a Zoom',
      value: dataRoomHeader?.online_meeting.student_url
    }
  ];

  const secondColumnAuxTeacher: RoomDetail[] = [
    {
      label: 'Acceso a Moodle',
      value: dataRoomHeader?.course_mdl_id
        ? `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        : '',
      icon: true,
      iconType: 'copy',
      valueIcon: ''
    },
    { label: 'Fecha de inicio', value: dataRoomHeader?.group.start_date },
    { label: 'Fecha de fin', value: dataRoomHeader?.group.end_date }
  ];

  const renderRoomDetails = (roomDetails: RoomDetail[]) => {
    return (
      <React.Fragment>
        {roomDetails.map((detail, index) => (
          <React.Fragment key={index}>
            <Typography
              variant="body5"
              className="room-detail-header-v2__label"
            >
              {detail.label}
            </Typography>
            <span className="room-detail-header-v2__content-value">
              <Typography
                variant="body5"
                className="room-detail-header-v2__value"
              >
                {detail.value}{' '}
              </Typography>
              {detail.icon ? (
                <>
                  {detail.iconType === 'copy' ? (
                    <div
                      className="room-detail-header-v2__container-icon"
                      onClick={() => {
                        if (detail.value) {
                          toast.success(`${detail.label} copiado...`);
                          navigator.clipboard.writeText(`${detail.value}`);
                        } else toast.error(`No existe ${detail.label}`);
                      }}
                    >
                      <Icon iconName="content_copy" />
                    </div>
                  ) : detail.iconType === 'eye' ? (
                    <a
                      style={{ color: colors.neutrals.Gray100 }}
                      href={detail.valueIcon}
                    >
                      <Icon iconName="visibility" />
                    </a>
                  ) : null}
                </>
              ) : null}
            </span>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="room-detail-header-v2__content">
        <section
          className="room-detail-header-v2__container-details"
          style={{ background: colors.neutrals.Gray20 }}
        >
          <div className="room-detail-header-v2__details">
            <div
              className="room-detail-header-v2__column"
              style={{ height: isVisibleMoreInfo ? 'auto' : '136px' }}
            >
              {renderRoomDetails(
                teacherAuxiliary ? firstColumnAuxTeacher : firstColumn
              )}
            </div>
            <div
              className="room-detail-header-v2__column"
              style={{
                height: teacherAuxiliary
                  ? '200px'
                  : isVisibleMoreInfo
                    ? 'auto'
                    : '136px'
              }}
            >
              {renderRoomDetails(
                teacherAuxiliary ? secondColumnAuxTeacher : secondColumn
              )}
            </div>
          </div>
          <div className="room-detail-header-v2__container-buttons">
            {teacherAuxiliary ? (
              <div />
            ) : (
              <Button
                type="tertiary"
                customBtnClassName="room-detail-header-v2__button-link-records"
                text={
                  isVisibleMoreInfo ? 'Menos información' : 'Más información'
                }
                icon={
                  isVisibleMoreInfo
                    ? 'keyboard_arrow_up'
                    : 'keyboard_arrow_down'
                }
                iconType="outlined"
                iconPosition="right"
                customStyles={{ justifyContent: 'flex-start', padding: 0 }}
                onClick={showMoreDetails}
              />
            )}
            <div className="room-detail-header-v2__actions">
              <Button
                type="secondary"
                text="Ir a Zoom"
                onClick={() => handleZoom()}
                customStyles={{ width: 160 }}
              />
              <Button
                customBtnClassName="room-detail-header-v2__button-link-records"
                icon="content_copy"
                iconPosition="left"
                type="tertiary"
                text="Copiar link a grabaciones"
                onClick={() => handleRecordings()}
                customStyles={{ width: 250 }}
              />
            </div>
          </div>
        </section>
        <AuxTeacherActionsModal
          ref={modalRef}
          roomInfo={room}
          refetchDetailRoom={refetch}
          externalTeachers={externalTeachers}
          setExternalTeachers={setExternalTeachers}
          setActiveTabMain={setActiveTabMain}
          setActiveTabTeachers={setActiveTabTeachers}
        />
      </div>
    </>
  );
};

export default RoomDetailHeader;
