import React from 'react';

import colors from '../../atoms/Colors';
import Typography from '../../atoms/Typography/Typography';

import './input.scss';

interface Props {
  size?: any;
  value?: string | number | undefined;
  type?: string;
  onChange?: any;
  variant?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  error?: string;
  isError?: boolean;
  hideErrorText?: boolean;
  onBlur?: any;
  disabled?: boolean;
  className?: string;
  dataTestid?: string;
}

const Input: React.FC<Props> = ({
  variant = 'reg-14',
  placeholder,
  value,
  type = 'string',
  onChange,
  name,
  error,
  isError = false,
  required = false,
  disabled = false,
  hideErrorText = false,
  onBlur,
  className = '',
  dataTestid = 'input-test-id',
  ...rest
}: Props) => {
  const showError = (!hideErrorText && error) || isError;

  return (
    <div className="input">
      <input
        data-testid={dataTestid}
        className={`input__content input__content--${variant} input--${
          disabled ? 'disabled' : 'active'
        } ${className}`}
        style={{border: isError || error ? `1px solid ${colors.warningColor}` : ''}}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        {...rest}
      />

      {showError && (
        <Typography
          dataTestid={`${dataTestid}-typography`}
          type="paragraph4"
          variation="mobile"
          className="input__alert"
          color={colors.warningColor}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default Input;
