// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';
import { IResale, IResaleStudentCourse } from '../interfaces/resale.interface';

// Instance
const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? RESALE DETAILS
export const getResaleDetails = async (groupId: number) => {
  const response = await axios.get<IResale>(
    `${instanceBaseURL}/groups/link-re-sale/${groupId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

//? RESALE DETAILS
export const postResaleStudentCourses = async (
  resaledetail: IResaleStudentCourse
) => {
  const response = await axios.post(
    `${instanceBaseURL}/resale_detail/`,
    resaledetail,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
