export const addParams = (
  params: any,
  setParams: any,
  name: string,
  value: any
) => {
  params.set(name, value);
  setParams(params);
};
export const deleteParams = (params: any, setParams: any, name: string) => {
  params.delete(name);
  setParams(params);
};
