import {
 useEffect, useMemo, useState, useRef 
} from 'react';
import { Tooltip } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './tableGuardian.scss';
import Table from '../../molecules/Table';
import IconAges from '../../atoms/icons/IconAges';
import Modal from '../../../components/Modal/Modal';
import TagCourses from '../../molecules/TagCourses';
import {
  selectStateGuardians,
  getGuardiansFromAPI
} from '../../../slices/guardianSlice/guardianSlice';
import ButtonLineal from '../../molecules/ButtonLineal';
import { countries } from '../../../constants/countries';
import FloatingMenu from '../../molecules/FloatingMenu';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import { setGenericPassword } from '../../../services/account';
import ItemFloatingMenu from '../../molecules/ItemFloatingMenu';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import GroupsLayout from '../../../components/templates/GroupsLayout';
import { addParams, deleteParams } from '../../../helpers/router.helpers';
import { getGuardianById } from '../../../slices/guardianSlice/guardianByIdSlice';
import { FILTERS_FIELD_NAMES_TRANSLATIONS } from '../../../constants/guardianList.constants';
import { getGuardianDetails } from '../../../slices/guardianSlice/guardianInvoicesSlice';
import ModalCreateStudent from '../../../views/Students/ModalCreateStudent/ModalCreateStudent';
import EditGuardian from '../../../views/Guardians/ModalEditGuardian/EditGuardian/EditGuardian';
import ModalCreateTeacher from '../../../views/Guardians/ModalCreateGuardian/ModalCreateGuardian';
import GuardianDetails from '../../../views/Guardians/ModalGuardianDetails/GuardianDetails/GuardianDetails';

const TableGuardianV2 = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const [idGuardian, setIdGuardian] = useState<number | null>(null);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalStudentCreate, setOpenModalStudentCreate] = useState(false);
  const [selectGuardian, setSelectGuardian] = useState<any>();

  const {
 guardians, isLoading, totalCount, totalPages 
} =
    useSelector(selectStateGuardians);

  const [currentPage, setCurrentPage] = useState(1);

  type FilterState = {
    [key: string]: string;
  };

  const currentFiltersInitialState: FilterState = {};
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );
  const prevFilterStateRef = useRef<FilterState>(currentFiltersInitialState);

  useEffect(() => {
    if (idGuardian) {
      dispatch(getGuardianById(idGuardian));
      dispatch(getGuardianDetails(idGuardian));
    }
  }, [dispatch, idGuardian]);

  useEffect(() => {
    if (parseInt(location.search.split('?id=')[1])) {
      setIdGuardian(parseInt(location.search.split('?id=')[1]));
      setOpenModalDetails(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (currentPage != 1) {
      setCurrentPage(1);
    } else if (prevFilterStateRef.current != currentFiltersInitialState) {
      dispatch(
        getGuardiansFromAPI({
          currentPage: currentPage,
          filters: currentFilters
        })
      );
    }
    prevFilterStateRef.current = currentFilters;
  }, [currentFilters]);

  useEffect(() => {
    dispatch(
      getGuardiansFromAPI({
        currentPage: currentPage,
        filters: currentFilters
      })
    );
  }, [currentPage]);

  const resetFilter = (value: string) => {
    const newState = { ...currentFilters };
    delete newState[value];
    setCurrentFilters(newState);
  };

  const handleSetGenericPassword = async (sessionId: number) => {
    try {
      const response = await setGenericPassword(sessionId);
      if (response?.status === 200) {
        toast.success('Se reestablecio la contraseña correctamente!');
      } else {
        toast.error('No se pudo actualizar la contraseña');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error.');
    }
  };

  const goToCreateStudent = (idGuardian: any) => {
    navigate(`/dashboard/students/${idGuardian}/create`);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        widthColumn: 65,
        disableSortBy: true
      },
      {
        Header: 'Nombre de tutor',
        accessor: 'full_name',
        classColumn: 'tb-guardian-tutor',
        widthColumn: 240,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Correo',
        accessor: 'username',
        classColumn: 'tb-guardian-username',
        widthColumn: 240,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Teléfono',
        accessor: 'phone_number',
        hasSort: true,
        disableSortBy: true,
        disableFilters: true,
        minWidthColumn: 140,
        Cell: ({ cell }: any) => <>{cell.value}</>
      },
      {
        Header: 'País',
        accessor: 'country',
        minWidthColumn: 160,
        Cell: ({ cell }: any) => {
          let country;
          if (cell.value) {
            country = countries.find(country => country.code === cell.value);
          }
          return <>{country ? country.label : cell.label}</>;
        },
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: 'DNI',
        accessor: 'doc_number',
        minWidthColumn: 134,
        disableSortBy: true,
        Cell: ({ cell }: any) => <>{cell.value}</>
      },
      {
        //hideColumn: true,
        Header: '',
        accessor: 'action',
        actionWidth: 156,
        hasTooltip: true,
        tooltipValue: 'Editar',
        Cell: (props: any) => {
          return (
            <>
              <div
                className="table-guardian__link table-guardian__link--hover-lineal"
                onClick={() => {
                  addParams(
                    params,
                    setParams,
                    'id',
                    props.row.values.id.toString()
                  );
                  setIdGuardian(props.row.values.id);
                  setOpenModalDetails(true);
                }}
              >
                Ver más
              </div>

              {props.row.values.id ? (
                <div
                  className="table-guardian__container-tooltip"
                  onClick={() => {
                    setOpenModalEdit(true);
                    setIdGuardian(props.row.values.id);
                  }}
                >
                  <IconAges
                    className={`table-guardian__button table-guardian__button--enabled`}
                  />
                  <section className="table-guardian__tooltip">
                    {props.column.tooltipValue}
                  </section>
                </div>
              ) : (
                <div className="table-guardian__container-tooltip">
                  <IconAges
                    className={`table-guardian__button table-guardian__button--disabled`}
                    fill="#bcbcbc"
                  />
                  <section className="table-guardian__tooltip">
                    {props.column.tooltipValue}
                  </section>
                </div>
              )}
              <FloatingMenu
                items={[
                  <ItemFloatingMenu
                    onClick={() => {
                      setIdGuardian(props.row.values.id);
                      // setOpenModalStudentCreate(true);
                      goToCreateStudent(props.row.values.id);
                    }}
                  >
                    Registrar Estudiante
                  </ItemFloatingMenu>,
                  <ItemFloatingMenu
                    onClick={() => {
                      if (props.row.values.id) {
                        toast.success('URL copiado...');
                        navigator.clipboard.writeText(
                          `https://backoffice.crackthecode.la/dashboard/guardians?id=${props.row.values.id}`
                        );
                      } else toast.error('No se pudo copiar la URL');
                    }}
                  >
                    Copiar URL
                  </ItemFloatingMenu>,
                  <ItemFloatingMenu
                    onClick={() => {
                      handleSetGenericPassword(props.row.values.id);
                    }}
                  >
                    Reestablecer Contraseña
                  </ItemFloatingMenu>
                ]}
              />
            </>
          );
        },
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      }
    ],
    []
  );

  if (isLoading) return <ScreenLoader />;

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage: currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters: currentFilters,
    totalRows: totalCount,
    totalPages: totalPages,
    pageSize: 50
  };

  return (
    <>
      {Object.keys(currentFilters).length > 0 ? (
        <>
          <section className="guardians_filter__container_courses">
            <p className="typography typography__paragraph2 typography--undefined typography__paragraph2--mobile typography__bold  teacher-profile__typography">
              Filtro Actual:{' '}
            </p>
            {Object.keys(currentFilters).map((key: string, index, test) => {
              return (
                <>
                  <TagCourses className="guardians_filter__tag">
                    <Typography type="paragraph2" variation="mobile" bold>
                      {FILTERS_FIELD_NAMES_TRANSLATIONS.get(key) +
                        ': ' +
                        currentFilters[key]}
                    </Typography>
                    <ButtonLineal
                      size="small"
                      className="guardians_filter__button_removal"
                      onClick={() => resetFilter(key)}
                    >
                      &#10005;
                    </ButtonLineal>
                  </TagCourses>
                </>
              );
            })}
          </section>
        </>
      ) : null}
      <GroupsLayout
        buttonAdd={<ModalCreateTeacher />}
        table={
          <Table
            data={guardians}
            columns={columns}
            paginationState={paginationState}
          />
        }
      />
      <>
        {idGuardian ? (
          <>
            {openModalDetails && (
              <Modal
                id={idGuardian}
                title="Tutor / Madre / Padre"
                fullWidth
                maxWidth="xl"
                openModal={openModalDetails}
                handleCloseModal={() => {
                  setOpenModalDetails(false);
                  deleteParams(params, setParams, 'id');
                }}
              >
                <GuardianDetails guardianId={idGuardian} />
              </Modal>
            )}
            <Modal
              id={idGuardian}
              title="Editar Tutor / Madre / Padre"
              fullWidth
              maxWidth="md"
              openModal={openModalEdit}
              handleCloseModal={() => setOpenModalEdit(false)}
            >
              <EditGuardian
                guardianId={idGuardian}
                onClose={() => setOpenModalEdit(false)}
              />
            </Modal>

            <ModalCreateStudent
              guardianSelected={guardians.find(
                (guardian: any) => guardian.id === idGuardian
              )}
              isOpen={openModalStudentCreate}
              setIsOpen={setOpenModalStudentCreate}
            />
          </>
        ) : (
          ''
        )}
      </>
    </>
  );
};

export default TableGuardianV2;
