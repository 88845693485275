import * as Yup from 'yup';

export const CreateProjectSchema = Yup.object().shape({
  project_name: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  academic_owner: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  operative_owner: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  seat_sold: Yup.string().min(1, 'Muy corto!'),
  amount_usd: Yup.string().min(1, 'Muy corto!').required('Requerido'),
  start_date_agreement: Yup.date().min(2, 'Muy corto!').required('Requerido'),
  start_date_operative: Yup.date()
    .min(2, 'Muy corto!')
    .nullable()
    .default(undefined),
  start_date_alliance: Yup.date()
    .min(2, 'Muy corto!')
    .nullable()
    .default(undefined),
  end_date_operative: Yup.date()
    .min(2, 'Muy corto!')
    .nullable()
    .default(undefined),
  end_date_alliance: Yup.date()
    .min(2, 'Muy corto!')
    .nullable()
    .default(undefined)
});
