import Box from '@mui/material/Box';
import Loader from 'react-loader-spinner';
import Typography from '@mui/material/Typography';

interface Props {
  fullWidth?: boolean;
  text?: boolean;
  className?: string;
  customStyles?: any;
}

const ScreenLoader: React.FC<Props> = ({
  fullWidth = true,
  text = true,
  className = '',
  customStyles
}) => {
  if (fullWidth) {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
      >
        <Loader type="RevolvingDot" color="#9C56E0" height={100} width={100} />
        {text ? <Typography variant="h6">Cargando...</Typography> : null}
      </Box>
    );
  } else {
    return (
      <div
        className={className}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          ...customStyles
        }}
      >
        <Loader type="RevolvingDot" color="#9C56E0" height={100} width={100} />
        {text ? <Typography variant="h6">Cargando...</Typography> : null}
      </div>
    );
  }
};

export default ScreenLoader;
