import React, { useState } from 'react';

import './tabs.scss';

interface Props {
  children?: any;
  onClick?: any;
  className?: string;
  size?: any;
  iconLeft?: any;
  iconRight?: any;
  disabled?: any;
  type?: any;
  fullWidth?: boolean | string | number;
  width?: string | number;
  tabHeaders?: any;
  tabContents?: any;
  activeTab?: number;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
}

const Tabs: React.FC<Props> = ({
  children,
  onClick,
  size = 'default',
  iconLeft,
  iconRight,
  className,
  disabled,
  type = 'button',
  fullWidth = false,
  width,
  tabHeaders,
  tabContents,
  activeTab = 0,
  setActiveTab,
  ...otherProps
}) => {
  const [activeIndex, setActiveIndex] = useState(activeTab);
  const hasIcon = iconLeft || iconRight ? 'icon' : 'no-icon';
  return (
    <>
      <article
        className={`
               tabs
               ${className ? className : ''}
               ${width || fullWidth ? ' tabs--justify-center' : ''}
         `} /* style={{height: "100%"}} */
      >
        <header className="tabs__header">
          {tabHeaders.map((tab: any, index: any) => (
            <button
              disabled={disabled}
              className={`
                        tabs__button tabs__${hasIcon} 
                        tabs__${hasIcon}--${size} 
                        ${className ? className : ''} 
                        ${width || fullWidth ? 'tabs--justify-center' : ''}
                        ${activeIndex === index ? 'tabs--active' : ''}
                     `}
              {...otherProps}
              onClick={disabled ? () => {} : () => setActiveIndex(index)}
              key={index}
              type={type}
            >
              {iconLeft} {tab} {iconRight}
            </button>
          ))}
        </header>
        <div className="tabs__content">
          <div className="tabs__content-inner">
            {tabContents.map((content: any, index: any) => (
              <div
                key={index}
                className={`
                           tabs__content-child 
                           ${className ? className : ''} 
                           ${activeIndex === index ? 'tabs__content-child--active' : ''}
                        `}
              >
                {content}
              </div>
            ))}
          </div>
        </div>
      </article>
    </>
  );
};

export default Tabs;
