import Grid from '@mui/material/Grid';
import { styled } from '@material-ui/core/styles';
import { List } from '@material-ui/core';

export const CardStudentsComments = styled(Grid)(({ theme }) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: 4,
  boxShadow:
    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
  color: 'rgba(0, 0, 0, 0.87)',
  minHeight: 500,
  padding: 16
}));

export const ListStudents = styled(List)(({ theme }) => ({
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  height: '520px',
  overflowY: 'scroll'
}));

export const ListComments = styled(List)(({ theme }) => ({
  position: 'absolute',
  width: '100%'
}));
