import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import './tableStudentRooms.scss';
import Link from '../../molecules/Link';
import Table from '../../molecules/Table';
import IconArrow from '../../atoms/icons/IconArrow';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import TagStatusGroups from '../../../components/molecules/TagStatusGroups';
import { selectStateStudents } from '../../../slices/studentSlice/studentSlice';
import { selectStateStudentForId } from '../../../slices/studentSlice/studentByIdSlice';

const TableStudentRooms = () => {
  const { students, isLoading } = useSelector(selectStateStudents);
  const { student, isLoadingEditStudent } = useSelector(
    selectStateStudentForId
  );
  const [isNotEmpty, setIsNotEmpty] = useState(false);

  const customCellStatusTag = ({ cell }: any) => (
    <TagStatusGroups status={cell.value}>{cell.value}</TagStatusGroups>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        widthColumn: 70
      },
      {
        Header: 'Nombre de salón',
        widthColumn: 201,
        accessor: 'name',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => <span>{cell?.value}</span>
      },
      {
        Header: 'Estado',
        widthColumn: 201,
        accessor: 'status',
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellStatusTag
      },
      {
        Header: 'Acción',
        accessor: 'action',
        disableSortBy: true,
        disableFilters: true,
        tooltipValue: 'inscribir',
        Cell: (props: any) => {
          return (
            <>
              <div className="table-student-rooms__button table-student-rooms__button--hover-lineal">
                <Link
                  className="table-student-rooms__link-room"
                  to={`/dashboard/rooms/${props?.row?.values?.id}`}
                  iconRight={<IconArrow fill="#000000" />}
                >
                  <u>Ver salón</u>
                </Link>
              </div>
            </>
          );
        }
      }
    ],
    []
  );

  if (isLoading || !students) return <ScreenLoader />;

  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Historico de salones
      </Typography>
      {student?.rooms_by_student?.length > 0 ? (
        <Table
          hidePagination={true}
          data={student?.rooms_by_student}
          columns={columns}
        />
      ) : (
        <Typography
          className="table-student-rooms__message"
          type="paragraph"
          variation="paragraph2"
        >
          El alumno no cuenta con salones disponibles
        </Typography>
      )}
    </>
  );
};

export default TableStudentRooms;
