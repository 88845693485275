import { Formik, Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { ModelCustomCheck } from './ModelCustomCheck';

interface Props {
  idParent: number;
  options: ModelCustomCheck[];
  submitAction: any;
  refetch: any;
}

const CustomCheck: React.FC<Props> = ({
  options,
  submitAction,
  refetch,
  idParent = -1
}) => {
  const [checkEnable, setCheckEnable] = useState(true);

  const handleClickEnable = () => {
    setCheckEnable(false);
  };

  const handleClickDisable = () => {
    setCheckEnable(true);
  };

  const initialValues = {
    id: idParent,
    customChecks: options
  };

  const CustomCheckSchema = Yup.object().shape({
    customChecks: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.boolean().required()
        })
      )
      .min(1, 'At least one option is required')
      .required()
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CustomCheckSchema}
      onSubmit={async values => {
        if (checkEnable) {
          handleClickEnable();
        } else {
          const v = values.customChecks.find(obj => {
            return obj.label == 'TestMonth';
          });
          const data = {
            id: values.id,
            check_test_month: v ? v.value : false
          };
          await toast.promise(
            submitAction.mutateAsync(data),
            {
              loading: 'Cargando...',
              success: 'Check Mes de prueba actualizado',
              error: 'Error'
            },
            { success: { icon: <UpdateIcon color="primary" /> } }
          );
          refetch();
          handleClickDisable();
        }
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid item xs={12}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                {values.customChecks.map(option => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={option.value}
                        name={option.label}
                        disabled={checkEnable}
                        onChange={e => {
                          const { checked, name } = e.target;
                          const v = values.customChecks.find(obj => {
                            return obj.label == name;
                          });
                          if (v) {
                            v.value = checked;
                            setFieldValue('customChecks', [v]);
                          }
                        }}
                      />
                    }
                    label={`on ${option.label}`}
                  />
                ))}
              </FormGroup>
            </FormControl>
            {checkEnable ? (
              <LoadingButton
                sx={{ minWidth: 150 }}
                variant="contained"
                type="submit"
                startIcon={<AddCircleIcon />}
              >
                Edit
              </LoadingButton>
            ) : (
              <LoadingButton
                sx={{ minWidth: 150 }}
                variant="contained"
                type="submit"
                startIcon={<AddCircleIcon />}
              >
                Apply
              </LoadingButton>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CustomCheck;
