// Material UI
import { LinearProgress } from '@mui/material';

// Components
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { getEvaluationBoxByCourseId } from '../../../../../services/courses';
import CourseEvaluationsDetails from '../../../../../components/Courses/CourseEvaluations/CourseEvaluationsDetails';

interface Props {
  refetch: () => {} /*  list all courses*/;
  courseId: number;
}

const Evaluations: React.FC<Props> = ({ refetch, courseId }) => {
  const [dataEvaluation, setDataEvaluation] = useState<any>();
  const [isErrorEvaluationCourse, setIsErrorEvaluationCourse] =
    useState<boolean>(false);
  const [isLoadingEvaluationCourse, setIsLoadingEvaluationCourse] =
    useState<boolean>(false);
  const [errorEvaluationCourse, setErrorEvaluationCourse] =
    useState<AxiosError>();

  useEffect(() => {
    getEvaluationCourseById(courseId);
  }, []);

  const getEvaluationCourseById = async (courseId: number) => {
    setIsLoadingEvaluationCourse(true);
    try {
      const { data } = await getEvaluationBoxByCourseId(courseId);
      setDataEvaluation(data);
      setIsLoadingEvaluationCourse(false);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorEvaluationCourse(true);
        setIsLoadingEvaluationCourse(false);
        setErrorEvaluationCourse(error.response?.data);
      }
    }
  };

  if (isLoadingEvaluationCourse || !dataEvaluation) return <LinearProgress />;
  if (isErrorEvaluationCourse)
    return <div>Error! {JSON.stringify(errorEvaluationCourse)}</div>;

  return (
    <CourseEvaluationsDetails
      dataEvaluation={dataEvaluation}
      setDataEvaluation={setDataEvaluation}
      courseId={courseId}
      refetch={refetch}
    />
  );
};

export default Evaluations;
