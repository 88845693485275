// Redux
import { createSlice } from '@reduxjs/toolkit';

// Interfaces
import { IRoomDetail } from '../../interfaces/room.interface';

export interface IAuthError {
  msg: string;
}

export interface IGroupState {
  roomDetail: IRoomDetail | null;
  isLoading: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
}

const initialState: IGroupState = {
  roomDetail: null,
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false
};

export const roomDetailByGroupSlice = createSlice({
  name: 'roomDetailByGroup',
  initialState,
  reducers: {
    setRoomDetail: (state, action) => {
      state.roomDetail = action.payload;
    },
    setTeacherToRoom: (state, action) => {
      state.roomDetail = action.payload;
    }
  },
  extraReducers: builder => {}
});

export const selectStateRoomDetailByGroup = (state: any) =>
  state.roomDetailByGroup;

export const { setRoomDetail, setTeacherToRoom } =
  roomDetailByGroupSlice.actions;
