import React from 'react';

import { getCellStyle } from './getCellStyle';
import { getCellClassName, getContentTdClassName } from './getClassNames';

interface IProps {
  cell: any;
}

export const RenderTableCell: React.FC<IProps> = ({ cell }) => {
  if (cell.column.hideColumn) {
    return <></>;
  }

  return (
    <td
      className={`table__td ${getCellClassName({ cell })}`}
      style={{
        ...getCellStyle({ cell }),
        minWidth: cell.column.minWidthColumn || cell.column.widthColumn || '',
        maxWidth:
          cell.column.id == 'action'
            ? `${cell.column.actionWidth}px !important`
            : ''
      }}
      {...cell.getCellProps()}
    >
      {cell.value !== undefined && (
        <>
          {cell.column.hasTooltip && (
            <span className="table__tooltip">
              {cell.column.tooltipValue ? cell.column.tooltipValue : cell.value}
            </span>
          )}
        </>
      )}
      <div
        className={`table__content-td ${getContentTdClassName({ cell })} ${
          cell.column.id === 'action' && 'table__content-td--action'
        }`}
      >
        <span
          className={
            cell.column.id === 'action'
              ? 'table__value-actions'
              : `table__value table__value--${
                  cell.column.columnType === 'component' ? 'component' : 'text'
                }`
          }
        >
          <span>{cell.render('Cell')}</span>
        </span>
      </div>
    </td>
  );
};
