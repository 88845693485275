// React
import { useEffect, useState } from 'react';

// Router
import { useSearchParams } from 'react-router-dom';

// React Query
import { useQuery } from 'react-query';

// API
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridValueGetterParams,
  GridRenderCellParams,
  GridSortModel
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';

import { GET_MICRO } from '../../services/api/api';

// Utils
import { objectsEqual } from '../../utils/utils';

// Interfaces
import { ICouponProgram } from '../../interfaces/coupon.interface';

// Material UI

// Components
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import CustomSearchToolbar from '../../components/CustomSearchToolbar/CustomSearchToolbar';
import CustomLoadingOverlay from '../../components/CustomLoadingOverlay/CustomLoadingOverlay';
import ModalEditCoupon from './ModalEditCoupon/ModalEditCoupon';
import ModalCreateCoupon from './ModalCreateCoupon/ModalCreateCoupon';
import DeleteProgramCoupon from './DeleteProgramCoupon/DeleteProgramCoupon';

interface Props {}

const ProgramCoupons: React.FC<Props> = () => {
  // Auto Open Modal
  const [params] = useSearchParams();
  const [couponProgramId] = useState(
    params.get('id') && parseInt(params.get('id')!)
  );
  const [searchText, setSearchText] = useState<string>('');
  const [rows, setRows] = useState<any[]>([]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc'
    }
  ]);

  const {
    data: couponPrograms,
    error,
    isError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    ['programs-coupon'],
    () => GET_MICRO<ICouponProgram[]>(`/program/search?code=${searchText}`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    couponPrograms && setRows(couponPrograms);
  }, [couponPrograms]);

  if (isLoading || !couponPrograms) return <ScreenLoader />;
  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  const currentlySelected = (model: GridSortModel) => {
    !objectsEqual(model, sortModel) && setSortModel(model);
  };

  const requestSearch = () => {
    refetch();
  };

  return (
    <Grid container rowGap={2} style={{ overflowY: 'scroll', height: '100%' }}>
      <Grid item xs={12}>
        <ModalCreateCoupon refetch={refetch} />
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          autoHeight
          style={{ fontSize: 12 }}
          loading={isFetching}
          sortModel={sortModel}
          onSortModelChange={model => currentlySelected(model)}
          rows={rows}
          columns={generateColumnsByCoupons()}
          pageSize={20}
          rowsPerPageOptions={[20]}
          components={{
            Toolbar: CustomSearchToolbar,
            LoadingOverlay: CustomLoadingOverlay
          }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchText(event.target.value),
              handleSearch: () => requestSearch()
            }
          }}
        />
      </Grid>
    </Grid>
  );

  function generateColumnsByCoupons(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'edit',
        headerName: 'Edit',
        width: 85,
        minWidth: 85,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <ModalEditCoupon
                couponProgramId={params.row.id}
                refetch={refetch}
              />
            </Stack>
          );
        }
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 150,
        editable: false,
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.name;
        }
      },
      {
        field: 'delete',
        headerName: 'Delete',
        width: 85,
        minWidth: 85,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <DeleteProgramCoupon programCouponId={params.row.id} />
            </Stack>
          );
        }
      }
    ];
    return columns;
  }
};

export default ProgramCoupons;
