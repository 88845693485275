import { useEffect, useMemo, useState } from 'react';

import './tableLanding.scss';
import Table from '../../molecules/Table';
import useLandings from '../../../hooks/useLandings';
import Link from '../../../components/molecules/Link';
import ButtonLineal from '../../molecules/ButtonLineal';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';

const TableLanding = ({
  shouldRefresh,
  generalFilter,
  setGeneralFilter
}: {
  shouldRefresh: any;
  generalFilter?: string;
  setGeneralFilter?: any;
}) => {
  const { data: dataFromApi, isLoading } = useLandings(shouldRefresh);

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (dataFromApi) {
      setData(dataFromApi);
    }
  }, [dataFromApi]);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        widthColumn: 80,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'URL',
        accessor: 'url',
        widthColumn: 500,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => {
          return (
            <span>
              {`https://www.crackthecode.la/cursos?alianza=${cell.row.values.name}`}
            </span>
          );
        }
      },
      {
        Header: 'Empresa',
        accessor: 'name',
        widthColumn: 200,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => {
          return (
            <span>
              {' '}
              <ButtonLineal
                size="small"
                className="table-landings__button_view_landing"
                onClick={() => {
                  console.log('Ver detalle');
                }}
              >
                <span className="table-landings__text-container">
                  <Link
                    className="table-landings__link"
                    to={`/dashboard/landings/${cell.row.values.id}`}
                  >
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      bold={true}
                    >
                      Ver detalle
                    </Typography>
                  </Link>
                </span>
              </ButtonLineal>
            </span>
          );
        }
      }
    ],
    []
  );

  if (isLoading) return <ScreenLoader />;

  return (
    <>
      <Table data={data} columns={columns} />
    </>
  );
};

export default TableLanding;
