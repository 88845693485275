import React from 'react';

import colors from '../Colors';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

const IconAlertCircleOutlined: React.FC<Props> = ({
  className,
  fill = colors.gris07,
  dataTestid = 'icon-alert',
  width = '40',
  height = '40',
  viewBox = '0 0 40 40'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_351_21483"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_351_21483)">
        <path
          d="M19.9997 28.333C20.4719 28.333 20.8677 28.1733 21.1872 27.8538C21.5066 27.5344 21.6663 27.1386 21.6663 26.6663C21.6663 26.1941 21.5066 25.7983 21.1872 25.4788C20.8677 25.1594 20.4719 24.9997 19.9997 24.9997C19.5275 24.9997 19.1316 25.1594 18.8122 25.4788C18.4927 25.7983 18.333 26.1941 18.333 26.6663C18.333 27.1386 18.4927 27.5344 18.8122 27.8538C19.1316 28.1733 19.5275 28.333 19.9997 28.333ZM18.333 21.6663H21.6663V11.6663H18.333V21.6663ZM19.9997 36.6663C17.6941 36.6663 15.5275 36.2288 13.4997 35.3538C11.4719 34.4788 9.70801 33.2913 8.20801 31.7913C6.70801 30.2913 5.52051 28.5275 4.64551 26.4997C3.77051 24.4719 3.33301 22.3052 3.33301 19.9997C3.33301 17.6941 3.77051 15.5275 4.64551 13.4997C5.52051 11.4719 6.70801 9.70801 8.20801 8.20801C9.70801 6.70801 11.4719 5.52051 13.4997 4.64551C15.5275 3.77051 17.6941 3.33301 19.9997 3.33301C22.3052 3.33301 24.4719 3.77051 26.4997 4.64551C28.5275 5.52051 30.2913 6.70801 31.7913 8.20801C33.2913 9.70801 34.4788 11.4719 35.3538 13.4997C36.2288 15.5275 36.6663 17.6941 36.6663 19.9997C36.6663 22.3052 36.2288 24.4719 35.3538 26.4997C34.4788 28.5275 33.2913 30.2913 31.7913 31.7913C30.2913 33.2913 28.5275 34.4788 26.4997 35.3538C24.4719 36.2288 22.3052 36.6663 19.9997 36.6663ZM19.9997 33.333C23.7219 33.333 26.8747 32.0413 29.458 29.458C32.0413 26.8747 33.333 23.7219 33.333 19.9997C33.333 16.2775 32.0413 13.1247 29.458 10.5413C26.8747 7.95801 23.7219 6.66634 19.9997 6.66634C16.2775 6.66634 13.1247 7.95801 10.5413 10.5413C7.95801 13.1247 6.66634 16.2775 6.66634 19.9997C6.66634 23.7219 7.95801 26.8747 10.5413 29.458C13.1247 32.0413 16.2775 33.333 19.9997 33.333Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconAlertCircleOutlined;
