/* eslint-disable complexity */
import React from 'react';

import Input from '../../../../components/molecules/Input';
import Paragraph from '../../../../components/common/Paragraph/Paragraph';
import Typography from '../../../../components/atoms/Typography/Typography';

export const StudentCredentials: React.FC<{
  isDetail?: boolean;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  noSyncAlert?: boolean;
}> = ({
 isDetail, values, errors, touched, handleChange, noSyncAlert 
}) => {
  const credentialsToCopy = `
  Correo: ${values?.email}
  Contraseña: ${values?.password_google}
  `;
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <Typography type="h3" variation="mobile" bold>
          Credenciales
        </Typography>
        {isDetail && (
          <div style={{ marginLeft: '10px' }}>
            <Paragraph
              title="Copiar credenciales"
              text={credentialsToCopy}
              copyable
              hideText
              direction="right"
              disabled={noSyncAlert}
            />
          </div>
        )}
      </div>
      <br />
      <br />
      <div className="detail-edit-students__content-body">
        <div className="detail-edit-students__student-information">
          <Typography bold type="paragraph4" variation="mobile">
            Correo
          </Typography>
          {isDetail ? (
            <Typography type="paragraph4" variation="mobile">
              {noSyncAlert ? '-' : values?.email || '-'}
            </Typography>
          ) : (
            <Input
              disabled={true}
              value={values?.email}
              onChange={handleChange}
              placeholder="-"
              name="email"
              required
              error={errors?.email && touched?.email ? errors?.email : ''}
            />
          )}
        </div>
        <div className="detail-edit-students__student-information">
          <Typography bold type="paragraph4" variation="mobile">
            Contraseña plataformas
          </Typography>
          {isDetail ? (
            <Typography type="paragraph4" variation="mobile">
              {noSyncAlert ? '-' : values?.password_google || '-'}
            </Typography>
          ) : (
            <Input
              disabled={true}
              value={values?.password_google}
              onChange={handleChange}
              placeholder="-"
              name="password_google"
              required
              error={
                errors?.password_google && touched?.password_google
                  ? errors?.password_google
                  : ''
              }
            />
          )}
          {isDetail ?? <br />}
        </div>
      </div>
    </>
  );
};
