import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridSortModel,
  GridRenderCellParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import 'moment/locale/es';
import moment from 'moment';
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import { Chip, IconButton, Stack } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { objectsEqual } from '../../../../../utils/utils';
import ModalEditInvoice from './ModalEditInvoice/ModalEditInvoice';
import { Invoice } from '../../../../../interfaces/guardian.interface';
import CustomLoadingOverlay from '../../../../../components/CustomLoadingOverlay/CustomLoadingOverlay';

interface Props {
  invoices: Invoice[];
  guardianId: number;
  isFetching: boolean;
  //refetch: () => {};
}

const DataGridInvoices: React.FC<Props> = ({
  invoices,
  guardianId,
  isFetching
}) => {
  console.log('AQUI', invoices);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc'
    }
  ]);

  function currentlySelected(model: GridSortModel) {
    if (!objectsEqual(model, sortModel)) setSortModel(model);
  }

  return (
    <DataGrid
      autoHeight
      style={{ fontSize: 12 }}
      loading={isFetching}
      sortModel={sortModel}
      onSortModelChange={model => currentlySelected(model)}
      rows={invoices}
      columns={generateColumnsByInvoices()}
      pageSize={20}
      rowsPerPageOptions={[20]}
      components={{ LoadingOverlay: CustomLoadingOverlay }}
    />
  );

  function generateColumnsByInvoices(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'actions',
        headerName: 'Acciones',
        minWidth: 50,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              columnGap={1}
              alignItems="center"
              justifyContent="center"
            >
              <ModalEditInvoice
                guardianId={guardianId}
                invoiceId={params.row.id}
                invoice={params.row}
                //refetch={refetch}
              />
            </Stack>
          );
        }
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'learning_path_name',
        headerName: 'Nombre de ruta',
        minWidth: 200,
        flex: 1,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.learning_path && params.row.learning_path.name;
        }
      },
      {
        field: 'course_name',
        headerName: 'Nombre de curso',
        minWidth: 200,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.enrolments && params.row.enrolments[0].course.name;
        }
      },
      {
        field: 'group_name',
        headerName: 'Nombre de grupo',
        minWidth: 330,
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.enrolments &&
            params.row.enrolments[0].group.name ? (
            <Stack
              direction="row"
              columnGap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                  maxWidth: 250
                }}
              >
                {params.row.enrolments[0].group.name}
              </span>
              <Link
                to={`/dashboard/groups/${params.row.enrolments[0].group.id}/rooms`}
                style={{ textDecoration: 'none' }}
              >
                <IconButton color="primary">
                  <RemoveRedEyeIcon />
                </IconButton>
              </Link>
            </Stack>
          ) : null;
        }
      },
      {
        field: 'payment_gateway',
        headerName: 'Metodo de pago',
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.payment_gateway;
        }
      },
      {
        field: 'amount',
        headerName: 'Monto',
        minWidth: 100,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.amount;
        }
      },
      {
        field: 'currency',
        headerName: 'Divisa',
        minWidth: 100,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.currency;
        }
      },
      {
        field: 'is_verified',
        headerName: 'Pago verificado',
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.is_verified;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <>
              {params.row.is_verified ? (
                <Chip
                  icon={<VerifiedIcon />}
                  label="Verificado"
                  variant="outlined"
                  color="success"
                />
              ) : (
                <Chip
                  icon={<HelpIcon />}
                  label="Sin confirmar"
                  variant="outlined"
                  color="warning"
                />
              )}
            </>
          );
        }
      },
      {
        field: 'source',
        headerName: 'Fuente de pago',
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          if (params.row.source == 'payment_web') {
            return 'WEB';
          } else if (params.row.source == 'payment_inscription') {
            return 'Inscripción Interna';
          } else if (params.row.source == 'backoffice_form') {
            return 'BO';
          } else if (params.row.source == 'batch_load') {
            return 'Carga Lote';
          } else {
            return params.row.source;
          }
        }
      },
      {
        field: 'created_at',
        headerName: 'Fecha creación',
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.created_at && moment(params.row.created_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.created_at &&
            moment(params.row.created_at).format('YYYY MMM D')
          );
        }
      },
      {
        field: 'payment_at',
        headerName: 'Fecha pago',
        minWidth: 180,
        disableColumnMenu: true,
        editable: false,
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.payment_at && moment(params.row.payment_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.payment_at &&
            moment(params.row.payment_at).format('YYYY MMM D')
          );
        }
      }
    ];

    return columns;
  }
};

export default memo(DataGridInvoices);
