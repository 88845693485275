// React
import { useState } from 'react';
import { AxiosError } from 'axios';

// React Query
import toast from 'react-hot-toast';

// Material UI
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Alert,
  Button,
  Typography,
  CardMedia
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { ICourseProjectSliders } from '../../../interfaces/course.interface';
import {
  deleteProjectImagesByCourse,
  patchProjectImagesByCourse,
  postProjectImagesByCourse
} from '../../../services/courses';
import { confirmDialog } from '../../ConfirmDialog/ConfirmDialog';

interface Props {
  sliders: ICourseProjectSliders[];
  courseId: number;
  refetch: any;
  refetchCourse: any;
}

const CourseProjectImages: React.FC<Props> = ({
  sliders,
  courseId,
  refetch,
  refetchCourse
}) => {
  const [benefitId, setBenefitId] = useState<number>();
  const [benefit, setBenefit] = useState<string>();
  const [hability, setHability] = useState<Partial<{ description: string }>>({description: ''});
  const [project, setProject] = useState<
    Partial<{ description?: any; multimedia?: any }>
  >({ description: '', multimedia: null });
  const [idSession, setIdSession] = useState<any>(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [imageToEdit, setImageToEdit] = useState<
    Partial<{ id?: any; multimedia?: any; name?: string | null }>
  >({
    id: null,
    multimedia: null,
    name: null
  });
  const [alert, setAlert] = useState<Partial<{ type?: any; message?: any }>>({
    type: '',
    message: ''
  });

  const [sliderId, setSliderId] = useState<number>();
  const [slider, setSlider] = useState<any>();
  const [edit, setEdit] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isLoadingProjectBenefits, setIsLoadingProjectBenefits] =
    useState<boolean>(false);

  const handleCreateImagesProject = async (courseId: number) => {
    if (slider && courseId) {
      const data = new FormData();
      data.append('slider', slider);
      data.append('course', `${courseId}`);

      setIsLoadingProjectBenefits(true);
      try {
        const response = await postProjectImagesByCourse(data);
        refetchCourse(courseId);
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo guardar la información - ${error}`);
          setIsLoadingProjectBenefits(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  const handleEditImagesProject = async (courseId: number) => {
    if (slider && courseId && sliderId) {
      const data = new FormData();
      data.append('slider', slider);
      data.append('course', `${courseId}`);

      setIsLoadingProjectBenefits(true);
      try {
        const response = await patchProjectImagesByCourse(data, sliderId);
        refetchCourse(courseId);
        toast.success('¡Se guardó la información correctamente!');
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo actualizar la información - ${error}`);
          setIsLoadingProjectBenefits(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  const handleDeleteImagesProject = async (
    courseId: number,
    sliderId: number
  ) => {
    if (courseId && sliderId) {
      const data = new FormData();
      data.append('slider', slider);
      data.append('course', `${courseId}`);

      setIsLoadingProjectBenefits(true);
      try {
        const response = await deleteProjectImagesByCourse(sliderId);
        refetchCourse(courseId);
        toast.success('¡Se guardó la información correctamente!');
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo actualizar la información - ${error}`);
          setIsLoadingProjectBenefits(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  const handleFileChange = (event: any) => {
    event.preventDefault();
    const input = event.target;
    if (input.files && input.files[0]) {
      setSlider(input.files[0]);
    }
  };

  return (
    <form>
      <Grid container rowSpacing={2} columnSpacing={3} sx={{ padding: 3 }}>
        {/* Main */}
        {sliders?.length > 0 &&
          sliders
            ?.sort((a: any, b: any) => a.id - b.id)
            .map((item: any, idx: any) => (
              <Grid item xs={12} md={4} key={idx}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">
                          {`Imagen Proyecto ${idx + 1}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Stack direction="row" columnGap={0}>
                          <IconButton
                            color="secondary"
                            aria-label="edit"
                            onClick={() => {
                              setSlider(item?.slider);
                              setSliderId(item?.id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            aria-label="remove"
                            onClick={e => {
                              e.stopPropagation();
                              confirmDialog(
                                `¿Realmente quieres eliminar la imagen:${idx + 1}?`,
                                () =>
                                  handleDeleteImagesProject(courseId, item.id)
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {item?.slider ? (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={item?.slider}
                        sx={{
                          objectFit: 'contain',
                          borderRadius: '8px'
                        }}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            ))}

        {alert.message && (
          <Grid item xs={12}>
            <Alert severity={alert.type}>{alert.message}</Alert>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <Stack direction="column" rowGap={3}>
            <Chip
              label="Cambiar Imagen Proyecto"
              variant="outlined"
              color={slider ? 'primary' : 'default'}
            />
            <Stack
              direction="column"
              rowGap={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: 148 }}
            >
              <input
                id="image-project"
                name="image-project"
                type="file"
                onChange={handleFileChange}
              />
              {slider ? (
                <>
                  <CardMedia
                    component="img"
                    height="100"
                    width="100"
                    image={
                      typeof slider === 'object'
                        ? URL.createObjectURL(slider)
                        : slider
                    }
                    sx={{ objectFit: 'contain', borderRadius: '8px' }}
                  />
                </>
              ) : null}
            </Stack>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setEdit(false);
                  setSlider(null);
                }}
                color="secondary"
                sx={{ width: '50%' }}
              >
                Cancelar
              </Button>
              <LoadingButton
                sx={{ width: '50%' }}
                variant="contained"
                onClick={
                  sliderId
                    ? () => handleEditImagesProject(courseId)
                    : () => handleCreateImagesProject(courseId)
                }
              >
                {sliderId ? 'Actualizar' : 'Aregar'}
              </LoadingButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default CourseProjectImages;
