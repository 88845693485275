/* eslint-disable complexity */
import moment from 'moment';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import './modalCreateProject.scss';
import FirstForm from './forms/FirstForm';
import SecondForm from './forms/SecondForm';
import {
  CreateProject,
  ModalCreateProjectProps,
  CreateProjectInitialValues
} from '../../../interfaces/projects.interface';
import Modal from '../../../components/atoms/Modal';
import {
  addProject,
  updateProject
} from '../../../slices/CTCB2B/projectSlice/projectSlice';
import {
  getCountries,
  selectStateCountries
} from '../../../slices/commons/countrySlice/contrySlice';
import { CreateProjectSchema } from './validation.schema';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import FormConfirm from '../../../components/organism/FormConfirm';
import {
  getOrganizationFromApi,
  selectStateOrganizations
} from '../../../slices/CTCB2B/organizationSlice/organizationSlice';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';

const ModalCreateProject: React.FC<ModalCreateProjectProps> = ({
  setShouldRefresh,
  isOpenModal,
  closeModal,
  openModal,
  isEdit,
  data,
  projectId
}) => {
  const size = 100;
  const dispatch: AppDispatch = useAppDispatch();
  const { countries } = useSelector(selectStateCountries);
  const { organizations } = useSelector(selectStateOrganizations);

  const [showConfirm, setShowConfirm] = useState(false);

  const [alertSelectGeneral, setAlertSelectGeneral] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [projectName, setProjectName] = useState('');
  const [startDateAgreement, setStartDateAgreement] = useState<any>();
  const [startDateOperative, setStartDateOperative] = useState<any>();

  // Select init
  const [alertProjectOrganization, setAlertProjectOrganization] =
    useState(false);

  const [isOpenSelectProjectOrganization, setIsOpenSelectProjectOrganization] =
    useState(false);
  const [selectOptionProjectOrganization, setSelectOptionProjectOrganization] =
    useState<any>(
      isEdit ? { id: data?.organization_id, name: data?.organization_name } : ''
    );
  const [projectOrganization, setProjectOrganization] = useState(
    isEdit ? data?.organization_name : null
  );
  const [projectOrganizationId, setProjectOrganizationId] = useState(
    isEdit ? data?.organization_id : ''
  );

  const optionSelectionProjectOrganization = (option: any): void => {
    setSelectOptionProjectOrganization(option);
    setProjectOrganization(option.name);
    setProjectOrganizationId(option.id);
  };

  const [isOpenSelectProjectCountry, setIsOpenSelectProjectCountry] =
    useState(false);

  const initialCountries = isEdit ? data.countries : [{ id: 0, name: '' }];

  const [projectCountry, setProjectCountry] = useState<string[]>(
    initialCountries.map((country: { name: string }) => country.name)
  );
  const [projectCountryId, setProjectCountryId] = useState<number[]>(
    initialCountries.map((country: { id: number }) => country.id)
  );

  console.log(projectCountryId);
  const optionSelectionProjectCountry = (option: any): void => {};
  // Select end

  // Select init
  const [isOpenSelectProjectType, setIsOpenSelectProjectType] = useState(false);
  const [selectOptionProjectType, setSelectOptionProjectType] = useState<any>(
    isEdit ? { id: data?.type, name: data?.type } : ''
  );
  const [projectType, setProjectType] = useState(isEdit ? data?.type : '');
  const [projectTypeId, setProjectTypeId] = useState(isEdit ? data?.type : '');

  const optionSelectionProjectType = (option: any): void => {
    setSelectOptionProjectType(option);
    setProjectType(option.name);
    setProjectTypeId(option.id);
  };

  const [isOpenSelectProjectMode, setIsOpenSelectProjectMode] = useState(false);
  const [selectOptionProjectMode, setSelectOptionProjectMode] = useState<any>(
    isEdit ? { id: data?.mode, name: data?.mode } : ''
  );
  const [projectMode, setProjectMode] = useState(isEdit ? data?.mode : '');
  const [projectModeId, setProjectModeId] = useState(isEdit ? data?.mode : '');

  const optionSelectionProjectMode = (option: any): void => {
    setSelectOptionProjectMode(option);
    setProjectMode(option.name);
    setProjectModeId(option.id);
  };

  const [
    isOpenSelectProjectOperationType,
    setIsOpenSelectProjectOperationType
  ] = useState(false);
  const [
    selectOptionProjectOperationType,
    setSelectOptionProjectOperationType
  ] = useState<any>(
    isEdit ? { id: data?.operation_type, name: data?.operation_type } : ''
  );
  const [projectOperationType, setProjectOperationType] = useState(
    isEdit ? data?.operation_type : ''
  );
  const [projectOperationTypeId, setProjectOperationTypeId] = useState(
    isEdit ? data?.operation_type : ''
  );

  const optionSelectionProjectOperationType = (option: any): void => {
    setSelectOptionProjectOperationType(option);
    setProjectOperationType(option.name);
    setProjectOperationTypeId(option.id);
  };

  const [isOpenSelectProjectComplexity, setIsOpenSelectProjectComplexity] =
    useState(false);
  const [selectOptionProjectComplexity, setSelectOptionProjectComplexity] =
    useState<any>(
      isEdit ? { id: data?.complex_level, name: data?.complex_level } : ''
    );
  const [projectComplexity, setProjectComplexity] = useState(
    isEdit ? data?.complex_level : ''
  );
  const [projectComplexityId, setProjectComplexityId] = useState(
    isEdit ? data?.complex_level : ''
  );

  const optionSelectionProjectComplexity = (option: any): void => {
    setSelectOptionProjectComplexity(option);
    setProjectComplexity(option.name);
    setProjectComplexityId(option.id);
  };

  const [selectOptionContactCountry, setSelectOptionContactCountry] =
    useState<any>('');
  const [contactCountryId, setContactCountryId] = useState('');

  useEffect(() => {
    if (selectOptionProjectOrganization && selectOptionContactCountry) {
      setAlertSelectGeneral(false);
    }
  }, [selectOptionProjectOrganization, selectOptionContactCountry]);

  const [isLoadingCreateOrganization, setIsLoadingCreateOrganization] =
    useState(false);

  useEffect(() => {
    if (isOpenModal) {
      dispatch(getOrganizationFromApi({ page: 1, size }));
      dispatch(getCountries({ page: 1, size }));
    }
    setShowConfirm(false);
  }, [isOpenModal]);

  const initialValues: CreateProjectInitialValues = {
    project_name: isEdit ? data?.name : '',
    academic_owner: isEdit ? data?.academic_owner : '',
    operative_owner: isEdit ? data?.operative_owner : '',
    seat_sold: isEdit ? data?.seat_sold : 0,
    amount_usd: isEdit ? data?.amount_usd : 0,
    folder_link: isEdit ? data?.folder_link : '',
    start_date_agreement: isEdit ? data?.winning_date : null,
    start_date_operative: isEdit ? data?.operative_start_date : null,
    start_date_alliance: isEdit ? data?.alliance_start_date : null,
    end_date_operative: isEdit ? data?.operative_end_date : null,
    end_date_alliance: isEdit ? data?.alliance_end_date : null,
    has_hackaton: isEdit ? data?.has_hackaton : false,
    has_course: isEdit ? data?.has_course : false,
    has_bootcamp: isEdit ? data?.has_bootcamp : false,
    has_game_jam: isEdit ? data?.has_game_jam : false,
    has_personalization: isEdit ? data?.has_personalization : false,
    has_other: isEdit ? data?.has_other : false,
    project_description: isEdit ? data?.description : '',
    project_comments: isEdit ? data?.comment : ''
  };

  const projTitle = isEdit
    ? 'Edita los datos del proyecto'
    : 'Ingresa los datos del proyecto';

  const proyectFormTitle = !showConfirm ? projTitle : '';

  const getButtonText = isEdit ? 'Guardar cambios' : 'Crear proyecto';

  const createProject = async (payload: CreateProject) => {
    try {
      const response: any = await dispatch(addProject(payload));
      if (!response?.error?.message) {
        setHasError(false);
        setShowConfirm(true);
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const editProject = async (id: number, payload: CreateProject) => {
    try {
      const response: any = await dispatch(updateProject({ id, payload }));
      if (!response?.error?.message) {
        setHasError(false);
        setShowConfirm(true);
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const cleanSelectors = () => {
    setProjectOrganization('');
    setProjectOrganizationId('');
    setProjectCountry(['']);
    setProjectCountryId([0]);
    setProjectType('');
    setProjectTypeId('');
    setProjectMode('');
    setProjectModeId('');
    setProjectOperationType('');
    setProjectOperationTypeId('');
    setProjectComplexity('');
    setProjectComplexityId('');
  };

  const handleCloseModal = () => {
    cleanSelectors();
    closeModal();
  };

  return (
    <Modal
      width="xxxl"
      isOpen={isOpenModal}
      closeModal={closeModal}
      title={isLoading ? '' : proyectFormTitle}
      body={
        isLoading ? (
          <ScreenLoader
            fullWidth={false}
            customStyles={{ flexDirection: 'column' }}
          />
        ) : !showConfirm ? (
          <Formik
            initialValues={initialValues}
            validationSchema={CreateProjectSchema}
            onSubmit={async (values, { resetForm }) => {
              setIsLoading(true);

              if (alertSelectGeneral) {
                return;
              }

              const payload = {
                ...values,
                organization_id: projectOrganizationId,
                project_complexity: projectComplexityId,
                project_operation_type: projectOperationTypeId,
                project_mode: projectModeId,
                project_type: projectTypeId,
                project_country: projectCountryId,
                contact_country: contactCountryId
              };

              setProjectName(values.project_name);
              setStartDateAgreement(
                moment(values.start_date_agreement).format('YYYY-MM-DD')
              );
              if (values.start_date_operative) {
                setStartDateOperative(
                  moment(values.start_date_operative).format('YYYY-MM-DD')
                );
              }

              if (isEdit) {
                setShouldRefresh(false);
                await editProject(Number(projectId), payload);
                setIsLoading(false);
                if (hasError) {
                  return;
                }
                resetForm();
                return;
              }

              await createProject(payload);
              setIsLoading(false);
              if (hasError) {
                return;
              }
              resetForm();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
              submitForm
            }) => {
              const inputsToValidate = {
                project_name: values.project_name,
                academic_owner: values.academic_owner,
                operative_owner: values.operative_owner,
                amount_usd: values.amount_usd,
                start_date_agreement: values.start_date_agreement
              };

              const hasEmptyData = Object.values(inputsToValidate).some(
                val => val === ''
              );

              const hasErrors = Object.keys(errors).length > 0;

              return (
                <Form>
                  <div className="modal-create-project__content-body">
                    <div className="modal-create-project__content-body--forms">
                      <FirstForm
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        optionSelection={optionSelectionProjectOrganization}
                        setIsOpenSelect={setIsOpenSelectProjectOrganization}
                        isOpenSelect={isOpenSelectProjectOrganization}
                        setAlertSelect={setAlertProjectOrganization}
                        alertSelect={alertProjectOrganization}
                        typeName={projectOrganization}
                        setTypeName={setProjectOrganization}
                        organizationOptions={organizations}
                      />
                      <SecondForm
                        data={data}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        setIsOpenSelectProjectCountry={
                          setIsOpenSelectProjectCountry
                        }
                        isOpenSelectProjectCountry={isOpenSelectProjectCountry}
                        optionSelectionProjectCountry={
                          optionSelectionProjectCountry
                        }
                        projectCountry={projectCountry}
                        setProjectCountry={setProjectCountry}
                        setIsOpenSelectProjectType={setIsOpenSelectProjectType}
                        isOpenSelectProjectType={isOpenSelectProjectType}
                        optionSelectionProjectType={optionSelectionProjectType}
                        projectType={projectType}
                        setProjectType={setProjectType}
                        setIsOpenSelectProjectMode={setIsOpenSelectProjectMode}
                        isOpenSelectProjectMode={isOpenSelectProjectMode}
                        optionSelectionProjectMode={optionSelectionProjectMode}
                        projectMode={projectMode}
                        setProjectMode={setProjectMode}
                        setIsOpenSelectProjectOperationType={
                          setIsOpenSelectProjectOperationType
                        }
                        isOpenSelectProjectOperationType={
                          isOpenSelectProjectOperationType
                        }
                        optionSelectionProjectOperationType={
                          optionSelectionProjectOperationType
                        }
                        projectOperationType={projectOperationType}
                        setProjectOperationType={setProjectOperationType}
                        setIsOpenSelectProjectComplexity={
                          setIsOpenSelectProjectComplexity
                        }
                        isOpenSelectProjectComplexity={
                          isOpenSelectProjectComplexity
                        }
                        optionSelectionProjectComplexity={
                          optionSelectionProjectComplexity
                        }
                        projectComplexity={projectComplexity}
                        setProjectComplexity={setProjectComplexity}
                        countries={countries}
                        projectCountryId={projectCountryId}
                        setProjectCountryId={setProjectCountryId}
                      />
                    </div>
                    <section className="modal-create-project__container-buttons">
                      <ButtonOutline
                        type="reset"
                        size="default"
                        disabled={isLoadingCreateOrganization}
                        onClick={handleCloseModal}
                      >
                        Cancelar
                      </ButtonOutline>
                      <ButtonFilled
                        type={'button'}
                        size="default"
                        disabled={hasErrors || hasEmptyData}
                        onClick={submitForm}
                      >
                        {getButtonText}
                      </ButtonFilled>
                    </section>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div>
            <FormConfirm
              title={
                isEdit ? '¡Cambios guardados' : '¡Proyecto creado con éxito!'
              }
              body={{
                Organización: projectOrganization,
                'Nombre del proyecto': projectName,
                'Fecha de acuerdo ganado': startDateAgreement,
                'Fecha de inicio operativo': startDateOperative
              }}
              closeModal={() => {
                handleCloseModal();
              }}
            />
          </div>
        )
      }
    />
  );
};

export default ModalCreateProject;
