// React Query
import { useQuery } from 'react-query';

// Router
import { Link } from 'react-router-dom';

// Constants

// API

// Material UI
import {
  DataGrid,
  GridColumns,
  GridValueGetterParams,
  GridRenderCellParams
} from '@mui/x-data-grid';
import { IconButton, Stack } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { darken, lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';

import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import { ILive, IZoom } from '../../interfaces/liverooms.interface';
import { GET, GET_MEETING } from '../../services/api/api';

const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
const localTime = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);

//onsole.log(localTime) ; // => '2022-03-30'

function Live() {
  const {
    data: rooms, //nombre de la data proveniente del endpoint (array de objetos)
    error: errorR,
    isError: isErrorR,
    isLoading: isLoadingR,
    isFetching: isFetchingR
  } = useQuery(
    ['DailyMeetings'],
    () => GET<ILive[]>('/meeting/?start_time=' + localTime), //endpoint. Se le asigna una clave (en este caso DailyMeetings) a la data
    {
      retry: false,
      refetchOnWindowFocus: true, //actualizar la data al regresar a la pestaña del bo.
      refetchInterval: 30000 //actualizar automaticamente cada 30 segundos
    }
  );

  const {
    data: zooms, //nombre de la data proveniente del endpoint (array de objetos)
    error: errorZ,
    isError: isErrorZ,
    isLoading: isLoadingZ,
    isFetching: isFetchingZ
  } = useQuery(
    ['LiveZooms'],
    () =>
      GET_MEETING<IZoom[]>(
        'zoom/event-meeting-start/?start_time=' + localTime + 'T00:00:00'
      ), //endpoint. Se le asigna una clave (en este caso LiveZooms) a la data
    {
      retry: false,
      refetchOnWindowFocus: true, //actualizar la data al regresar a la pestaña del bo.
      refetchInterval: 30000 //actualizar automaticamente cada 30 segundos
    }
  );

  ///zoom/event-meeting-start/?start_time="+localTime+"T00:00:00

  if (isLoadingR || !rooms) return <ScreenLoader />;
  if (isErrorR) return <div>Error! {JSON.stringify(errorR)}</div>;
  if (isLoadingZ || !zooms) return <ScreenLoader />;
  if (isErrorZ) return <div>Error! {JSON.stringify(errorZ)}</div>;

  const columns: GridColumns = [
    {
      field: 'inicio',
      headerName: 'Inicio Programado', //hora de inicio programada para la clase
      width: 120,
      editable: false
    },
    {
      field: 'started', //comprobar si la clase inició
      headerName: '¿Inició?',
      width: 120,
      editable: false
    },
    {
      field: 'start_time', //de haber iniciado, hora a la que inició la clase
      headerName: 'Hora de inicio',
      width: 120,
      editable: false
    },
    {
      field: 'zoom_id', //id de zoom de la clase
      headerName: 'ZOOM ID',
      width: 140,
      editable: false
    },
    {
      field: 'clase', //nombre de la clase (usualmente tiene el nombre del profesor también)
      headerName: 'Clase',
      width: 300,
      editable: false
    },
    {
      field: 'group', //nombre del grupo (clase + fecha de inicio + horario)
      headerName: 'Grupo',
      width: 400,
      editable: false
    },
    {
      field: 'room_id', //id de zoom de la clase
      headerName: 'ROOM ID',
      width: 140,
      editable: false
    },
    {
      field: 'group_info',
      headerName: ' ',
      width: 10,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.group.name;
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Tooltip title="Mas Información" arrow placement="left">
              <Link
                to={`/dashboard/rooms/${params.row.room_id}`}
                style={{ textDecoration: 'none' }}
              >
                <IconButton aria-label="delete" color="primary" size="small">
                  <VisibilityRoundedIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Stack>
        );
      }
    }
  ];

  const rows: any = []; //las filas son arreglos con varias opciones.
  let idCounter = -1; //indice del array de objetos "rooms"

  const createRow = () => {
    //añadir una nueva fila al array rows (se toma la info de rooms pero se añade manualmente un id ya que es necesario.)

    idCounter += 1;
    let inicioClase = 'No'; //comprobar si la clase inició
    let horaInicio = ''; //hora de inicio

    for (let j = 0; j < zooms.length; j++) {
      //vamos a comprobar respecto a los zooms ya iniciados

      if (rooms[idCounter].zoom_id.replace(/ /g, '') == zooms[j].host_id_zoom) {
        //para una clase especifica comprobamos si el zoom id corresponde con un meeting ya iniciado

        inicioClase = 'Si'; //cambiamos el valor de inicioClase
        horaInicio = zooms[j].start_time.slice(11, 19); //colocamos la hora de inicio de la clase
      }
    }
    //creamos la fila poniendo sus características
    return rows.push({
      id: idCounter + 1,
      zoom_id: rooms[idCounter].zoom_id,
      room_id: rooms[idCounter].room.id,
      clase: rooms[idCounter].room.name,
      group: rooms[idCounter].group.name,
      inicio: rooms[idCounter].start_time.slice(11, 19),
      started: inicioClase,
      start_time: horaInicio
    });
  };

  //creamos todas las filas de las clases programadas
  for (let i = 0; i < rooms.length; i++) {
    createRow();
  }

  const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
  return (
    <Box
      sx={{
        height: 800,
        width: 1,
        overflowY: 'scroll',
        '& .super-app-theme--Si': {
          bgcolor: theme =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          '&:hover': {
            bgcolor: theme =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              )
          }
        },
        '& .super-app-theme--No': {
          bgcolor: theme =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          '&:hover': {
            bgcolor: theme =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode
              )
          }
        }
      }}
    >
      <DataGrid
        autoHeight
        style={{ fontSize: 12 }}
        loading={isFetchingR}
        rows={rows}
        columns={columns}
        pageSize={20}
        getRowClassName={params => `super-app-theme--${params.row.started}`}
      />
    </Box>
  );
}

export default Live;
