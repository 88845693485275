import React from 'react';

// Components
import SelectOptions from '../SelectOptions';
import ItemFloatingMenu from '../ItemFloatingMenu';

// Redux

interface Props {
  dataSort: Array<any>;
  onClickHandler: Function;
  onClickDropdown?: Function;
  dataTestid?: string;
}

const DropdownList: React.FC<Props> = ({
  dataSort,
  onClickHandler,
  onClickDropdown,
  dataTestid = 'select-options-test-id'
}) => {
  return (
    <SelectOptions
      dataTestid={dataTestid}
      className="default-dropdown"
      isScroll={dataSort?.length > 6 ? true : false}
      size="default"
      options={dataSort.map((option: any, index: number) => (
        <ItemFloatingMenu
          key={option?.id}
          dividerItems
          dataTestid={`select-option-${index}`}
        >
          <div className="default-dropdown__content-item">
            <div
              className="default-dropdown__identifier-item"
              onClick={() => {
                onClickDropdown && onClickDropdown(option?.id);
                const dataDetail = {
                  name: option?.name,
                  id: option?.id
                };
                onClickHandler(dataDetail);
              }}
            >
              {option?.name}
            </div>
          </div>
        </ItemFloatingMenu>
      ))}
    />
  );
};

export default DropdownList;
