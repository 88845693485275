import React, { useEffect, useRef, useState } from 'react';

import './SelectGeneral.scss';
import colors from '../../atoms/Colors';
import Typography from '../../atoms/Typography/Typography';
import IconArrowUp from '../../atoms/icons/IconArrowUp';
import IconArrowDown from '../../atoms/icons/IconArrowDown';
import DropdownList from '../../../components/molecules/DropdownList';

interface Props {
  size?: string;
  options?: any;
  label: string;
  alert: boolean;
  isOpen: boolean;
  typeName?: string;
  typeId?: string;
  className?: string;
  disabled?: boolean;
  dataTestid?: string;
  onclickDefaultValue: Function;
  onClickOutside?: () => void;
  setAlert: (e: boolean) => void;
  setIsOpen: (e: boolean) => void;
  setTypeId?: Function;
  setTypeName: (e: string) => void;
  index?: number;
}

const SelectGeneral: React.FC<Props> = ({
  label,
  isOpen,
  options,
  disabled,
  index,
  typeId,
  typeName,
  className,
  alert = false,
  size = 'default',
  dataTestid = 'select-general-test-id',
  setAlert,
  setIsOpen,
  setTypeId,
  setTypeName,
  onClickOutside,
  onclickDefaultValue
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState();
  const [optionsFiltered, setOptionsFiltered] = useState(options);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside, setIsOpen]);

  useEffect(() => {
    if (value) {
      setAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (typeName && typeName?.length > 0) {
      const optionsFilteredByName = options.filter((option: { name: string }) =>
        option.name.toLowerCase().includes(typeName.toLowerCase())
      );

      setOptionsFiltered(optionsFilteredByName);
    } else {
      setOptionsFiltered(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeName, options]);

  const onClickHandler = (option: any): void => {
    onclickDefaultValue(option);
    setTypeName(option.name);
    setValue(option);
    setTypeId?.(option?.id);
    setIsOpen(!isOpen);
  };

  return (
    <div className="select-general" data-testid={dataTestid}>
      <div
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
          }
        }}
        className={`select-general__container ${
          disabled ? 'select-general__disabled' : 'select-general__active'
        } ${alert ? 'select-general__alert' : ''} ${className ? className : ''}`}
      >
        <input
          placeholder={label}
          className="select-general__input"
          type="text"
          value={typeName}
          disabled={disabled}
          onChange={e => setTypeName(e.target.value)}
        />
        {disabled ? (
          <div
            className={`select-general__icon ${
              isOpen ? 'select-general--active' : 'select-general--inactive'
            } select-general__icon--${size} ${
              disabled ? 'select-general__icon--disabled' : ''
            }`}
          >
            <IconArrowDown
              fill={colors.gris03}
              dataTestid={`icon-down-${dataTestid}`}
            />
          </div>
        ) : (
          <div
            className={`select-general__icon ${
              isOpen ? 'select-general--active' : 'select-general--inactive'
            } select-general__icon--${size}`}
          >
            {isOpen ? (
              <IconArrowUp dataTestid={`icon-up-${dataTestid}`} />
            ) : (
              <IconArrowDown dataTestid={`icon-down-${dataTestid}`} />
            )}
          </div>
        )}
      </div>
      {isOpen && (
        <div ref={ref} className="select-general__dropdown">
          <DropdownList
            dataSort={optionsFiltered}
            onClickHandler={onClickHandler}
            dataTestid={`dropdown-${dataTestid}`}
          />
        </div>
      )}
      {alert && (
        <Typography
          type="paragraph2"
          variation="mobile"
          className="select-general__alert-message"
          color={colors.warningColor}
          dataTestid={`text-${dataTestid}`}
        >
          Requerido
        </Typography>
      )}
    </div>
  );
};

export default SelectGeneral;
