import React from 'react';

import colors from '../Colors';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
}

const IconCopyText: React.FC<Props> = ({
  className,
  fill = colors.gris05,
  dataTestid = 'icon-copy-text'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4408 9.12109H6.24078C5.44662 9.12109 4.80078 9.76695 4.80078 10.5611V17.7613C4.80078 18.5554 5.44662 19.2013 6.24078 19.2013H13.4408C14.2349 19.2013 14.8808 18.5554 14.8808 17.7613V10.5611C14.8808 9.76695 14.2349 9.12109 13.4408 9.12109Z"
        fill={fill}
      />
      <path
        d="M17.7611 4.80078H10.5611C10.1792 4.80078 9.81291 4.9525 9.54286 5.22256C9.27281 5.49261 9.12109 5.85889 9.12109 6.24081V7.68084H14.8811C15.263 7.68084 15.6293 7.83255 15.8993 8.10261C16.1694 8.37267 16.3211 8.73895 16.3211 9.12087V14.881H17.7611C18.143 14.881 18.5093 14.7293 18.7793 14.4592C19.0494 14.1891 19.2011 13.8229 19.2011 13.4409V6.24081C19.2011 5.85889 19.0494 5.49261 18.7793 5.22256C18.5093 4.9525 18.143 4.80078 17.7611 4.80078Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCopyText;
