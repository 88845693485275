import React, { useState } from 'react';

import { useModal } from '../../hooks/useModal';
import ButtonFilled from '../../components/molecules/ButtonFilled';
import TableLanding from '../../components/organism/TableLanding/TableLanding';
import LandingsLayout from '../../components/templates/LandingsLayout/LandingsLayout';
import ModalCreateLanding from '../../pages/Landings/ModalCreateLanding';

const Landings: React.FC<{}> = ({}) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const {
    openModal: openModalCreateOrganization,
    closeModal: closeModalCreateOrganization,
    isOpenModal: isOpenModalCreateOrganization
  } = useModal();

  return (
    <>
      <LandingsLayout
        buttonAdd={
          <ButtonFilled
            onClick={() => {
              setShouldRefresh(false);
              openModalCreateOrganization();
            }}
            className={'landings-layout__button-add-group'}
          >
            {'Crear nueva landing +'}
          </ButtonFilled>
        }
        table={TableLanding}
        shouldRefresh={shouldRefresh}
      />
      <ModalCreateLanding
        setShouldRefresh={setShouldRefresh}
        isOpenModal={isOpenModalCreateOrganization}
        openModal={closeModalCreateOrganization}
        closeModal={closeModalCreateOrganization}
      />
    </>
  );
};

export default Landings;
