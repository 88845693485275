import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getRooms,
  removeRoom,
  patchEditRoom,
  postCreateRoom,
  updateZoomLink,
  postCreateRoomWithManualLinkZoom
} from '../../services/rooms';
import { clearString } from '../../utils/utils';
import { ICreateRoom } from '../../interfaces/group.interface';
import { IRoom, IRoomDetail } from '../../interfaces/room.interface';

export interface IAuthError {
  msg: string;
}

export interface IRoomState {
  rooms: IRoom[];
  room: IRoomDetail | null;
  isLoading: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
  totalPages: number;
  count: number;
}

const initialState: IRoomState = {
  rooms: [],
  room: null,
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  count: 0
};

export const getAllRooms = createAsyncThunk(
  'rooms/getAllRooms',
  async (
    {
      userId,
      currentPage,
      size,
      roomId,
      project,
      group,
      teacher,
      end_date_evaluation,
      status_evaluation,
      sort,
      direction,
      fetchAll,
      daySession
    }: {
      userId: number;
      currentPage: number;
      size: number;
      roomId?: string;
      project?: string;
      group?: string;
      teacher?: string;
      end_date_evaluation?: string;
      status_evaluation?: string;
      sort?: string;
      direction?: string;
      fetchAll?: boolean;
      daySession?: string;
    },
    { getState, rejectWithValue }: any
  ) => {
    try {
      /* const { auth: user } = getState() as RootState; */
      const response = await getRooms(
        userId,
        currentPage,
        size,
        roomId,
        project,
        group,
        teacher,
        end_date_evaluation,
        status_evaluation,
        sort,
        direction,
        fetchAll,
        daySession
      );
      return response.data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addRoom = createAsyncThunk(
  'rooms/addRoom',
  async (
    newRoom: ICreateRoom,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await postCreateRoom(newRoom);
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addRoomManualLinkZoom = createAsyncThunk(
  'rooms/addRoomManualLinkZoom',
  async (
    roomWithManualLinkZoom: ICreateRoom,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await postCreateRoomWithManualLinkZoom(
        roomWithManualLinkZoom
      );
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateRoom = createAsyncThunk(
  'rooms/updateRoom',
  async (
    { roomId, room }: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const data = await patchEditRoom(roomId, room);
      if (data) {
        toast.success('Room editado correctamente!');
      } else {
        toast.error('Ha ocurrido un error al editar el room.');
      }
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateRoomZoomLink = createAsyncThunk(
  'rooms/updateRoomZoomLink',
  async (
    zoomId: string,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const { start_url } = await updateZoomLink(clearString(zoomId));
      if (start_url) {
        toast.success('Vamos a Zoom!');
        setTimeout(() => {
          window.open(start_url, '_blank');
        }, 1000);
      } else {
        toast.error('Ha ocurrido un error.');
      }
    } catch (err) {
      toast.error(
        'Ha ocurrido un error. Inicie la clase con la cuenta correcta'
      );
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteRoomById = createAsyncThunk(
  'rooms/deleteRoomById',
  async (
    roomId: number,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await removeRoom(roomId);
      if (response?.status === 204) {
        toast.success('Room eliminado correctamente!');
        return response;
      }
    } catch (err) {
      toast.error('Ha ocurrido un error al eliminar el room.');
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getAllRooms */
    builder.addCase(getAllRooms.pending, (state, _) => {
      state.rooms = initialState.rooms;
      state.isLoading = true;
    });
    builder.addCase(getAllRooms.fulfilled, (state, action) => {
      state.rooms = action.payload.results;
      state.totalPages = action.payload.total_pages;
      state.count = action.payload.count;
      state.isLoading = false;
      state.error = null;
      state.isCompleted = false;
    });
    builder.addCase(getAllRooms.rejected, (state, action) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
      state.error = (action.payload as any)?.message || 'Error Server';
    });
    /** addRoom */
    builder.addCase(addRoom.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addRoom.fulfilled, (state, action) => {
      state?.rooms?.push(action.payload);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addRoom.rejected, (state, action) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
      state.error = { msg: (action.payload as any)?.message || 'Error Server' };
    });
    /** addRoomManualLinkZoom */
    builder.addCase(addRoomManualLinkZoom.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addRoomManualLinkZoom.fulfilled, (state, action) => {
      state?.rooms?.push(action.payload);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addRoomManualLinkZoom.rejected, (state, action) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
      state.error = { msg: (action.payload as any)?.message || 'Error Server' };
    });
    /** updateRoom */
    builder.addCase(updateRoom.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updateRoom.fulfilled, (state, action) => {
      const payload = action.payload;
      const rooms = state.rooms.filter(room => room.id !== payload.id);
      state.rooms = [...rooms, action.payload];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateRoom.rejected, (state, action) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
      state.error = (action.payload as any)?.message || 'Error Server';
    });
    /** deleteRoomById */
    builder.addCase(deleteRoomById.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(deleteRoomById.fulfilled, (state, action) => {
      const idRoomDeleted = action.payload;
      const rooms = state.rooms.filter(room => room.id !== idRoomDeleted);
      state.rooms = rooms;
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(deleteRoomById.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateRooms = (state: any) => state.rooms;
