// IGuardian
export interface IGuardian {
  id?: number;
  username?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  role?: IRole;
  doc_type: string;
  doc_number: string;
  country?: ICountry;
  awareness_about_CTC?: IAwarenessAboutCTC;
  optional_email?: string;
  password?: string;
}

export interface IGuardianInitialValues {
  id?: string;
  username?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  role: IRole;
  doc_type: string;
  doc_number: string;
  country?: ICountry;
  awareness_about_CTC?: IAwarenessAboutCTC;
  optional_email?: string;
  password?: string;
}

export enum IAwarenessAboutCTC {
  AlreadyInCTC = 'already_in_CTC',
  Company = 'company',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Mailing = 'mailing',
  None = 'none',
  Referral = 'referral',
  School = 'school',
  Web = 'web'
}

export enum ICountry {
  AF = 'AF',
  Ar = 'AR',
  Bo = 'BO',
  Bs = 'BS',
  CA = 'CA',
  CR = 'CR',
  Cl = 'CL',
  Co = 'CO',
  De = 'DE',
  Dm = 'DM',
  Do = 'DO',
  Ec = 'EC',
  Empty = '',
  Gt = 'GT',
  Hn = 'HN',
  In = 'IN',
  MX = 'MX',
  NI = 'NI',
  PE = 'PE',
  Pa = 'PA',
  Pt = 'PT',
  Ru = 'RU',
  Sv = 'SV',
  Us = 'US',
  Uy = 'UY',
  Ve = 'VE'
}

export enum IRole {
  Father = 'father',
  Mother = 'mother',
  None = 'none',
  Tutor = 'tutor'
}

// IGuardianDetails
export interface IGuardianDetails {
  awareness_about_CTC: string;
  country: string;
  doc_number: string;
  doc_type: string;
  email: string;
  first_name: string;
  id: number;
  invoices: Invoice[];
  last_name: string;
  phone_number: string;
  role: string;
  students: Student[];
  username: string;
}

export interface Invoice {
  id: number;
  enrolments: Enrolment[];
  learning_path: LearningPath;
  payment_gateway: 'niubiz' | 'stripe' | 'dlocal';
  amount: number;
  currency: 'USD' | 'PEN' | 'MXN' | 'COP';
  promotion_code: string;
  transaction_id: string;
  stripe_subscription_schedule_id: string;
  created_at: Date;
  user: number;
  is_verified: boolean;
  invoiced_at: Date;
  payment_at: Date;
  source:
    | 'backoffice_form'
    | 'django_form'
    | 'enrollment_form_campaigns'
    | 'enrollment_form_courses_paths'
    | 'manual'
    | 'payment_gateway'
    | 'payment_web'
    | 'payment_inscription'
    | 'batch_load';
}

export interface UpdateInvoice {
  is_verified: boolean;
  source:
    | 'backoffice_form'
    | 'django_form'
    | 'enrollment_form_campaigns'
    | 'enrollment_form_courses_paths'
    | 'manual'
    | 'payment_gateway'
    | 'payment_web'
    | 'payment_inscription'
    | 'batch_load';
  amount: number;
  currency: 'USD' | 'PEN' | 'MXN' | 'COP';
  payment_gateway: 'niubiz' | 'stripe' | 'dlocal';
  invoiced_at: Date;
  payment_at: Date;
}

export interface Enrolment {
  id: number;
  course: LearningPath;
  group: LearningPath | null;
  student: Student | null;
  created_at: Date;
  invoice: number;
  variation: null;
}

export interface LearningPath {
  id: number;
  name: string;
}

export interface Student {
  id: number;
  first_name: string;
  last_name: string;
  birthdate: Date;
  school_name: string;
  gender: string;
  device_experience: string;
  operative_system: string;
  email: string;
  password: string;
  guardian: number;
}
