import { useSelector } from 'react-redux';

import { RootState } from '../../store/store';
import NextClass from '../../components/organism/NextClass';
import TableRoom from '../../components/organism/TableRoom';
import RoomsLayout from '../../components/templates/RoomsLayout';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import {
  TEACHER_AUXILIARY,
  TEACHER_CTC
} from '../../constants/usersType.constants';

function Rooms() {
  const teacherRoles = [TEACHER_CTC, TEACHER_AUXILIARY];
  const user = useSelector((state: RootState) => state.auth.user);

  if (!user) return <ScreenLoader />;

  return (
    <RoomsLayout
      nextClass={
        teacherRoles.includes(user?.permission?.name) ? (
          <NextClass user={user} />
        ) : null
      }
      table={<TableRoom user={user} />}
    />
  );
}

export default Rooms;
