// Axios
import axios from 'axios';

const instanceBaseURL = process.env.REACT_APP_CTC_MICRO_URL;
const token_micro = process.env.REACT_APP_CTC_MICRO_TOKEN;

//? Coupon State

export const patchCouponCumulativeState = async (
  data: any,
  couponId: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/coupon/cumulative/${couponId}`,
    data,
    { headers: { Authorization: `Bearer ${token_micro}` } }
  );
  return response;
};
