// React Query
import { QueryObserver, useMutation, useQueryClient } from 'react-query';

// API

// Hot Toast
import { toast } from 'react-hot-toast';

// Material UI
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';

import { DELETE_MICRO } from '../../../services/api/api';

interface Props {
  programCouponId: number;
}

const DeleteProgramCoupon: React.FC<Props> = ({ programCouponId }) => {
  const deleteProgramCoupon = useMutation(
    () => DELETE_MICRO(`/program/delete/${programCouponId}`),
    { retry: false }
  );

  const queryClient = useQueryClient();
  const { refetch: refetchCouponProgram } = new QueryObserver(queryClient, {queryKey: 'programs-coupon'});

  const handleDeleteProgramCoupon = async () => {
    if (!deleteProgramCoupon.isSuccess) {
      await toast.promise(
        deleteProgramCoupon.mutateAsync(),
        {
          loading: 'Cargando...',
          success: 'Programa eliminado',
          error: 'Error'
        },
        { success: { icon: <DeleteIcon color="error" /> } }
      );

      refetchCouponProgram();
    }
  };

  return (
    <Tooltip title="Eliminar" arrow placement="right">
      <IconButton
        aria-label="delete"
        color={deleteProgramCoupon.isSuccess ? 'success' : 'error'}
        size="small"
        onClick={e => {
          e.stopPropagation();
          handleDeleteProgramCoupon();
        }}
      >
        {deleteProgramCoupon.isSuccess ? <CheckIcon /> : <DeleteIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default DeleteProgramCoupon;
