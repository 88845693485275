import './organizationsLayaout.scss';

interface Props {
  table: any;
  buttonAdd: any;
  setGeneralFilter: any;
  shouldRefresh: boolean;
  setCurrentPosition: any;
}

const OrganizationsLayout: React.FC<Props> = ({
  table,
  buttonAdd,
  shouldRefresh,
  setGeneralFilter,
  setCurrentPosition
}) => {
  return (
    <main className="organizations-layout">
      <div>{buttonAdd}</div>
      <div></div>
      <div className="organizations-layout__table">
        {table(shouldRefresh, setCurrentPosition, setGeneralFilter)}
      </div>
    </main>
  );
};

export default OrganizationsLayout;
