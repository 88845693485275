// React Query
import { useMutation } from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// API

// Hot Toast
import { toast } from 'react-hot-toast';

// Utils

// Material UI
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { countries } from '../../../../constants/countries';
import { POST } from '../../../../services/api/api';
import { getErrorMessage } from '../../../../utils/utils';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('Nombre requerido'),
  last_name: Yup.string().required('Apellido requerido'),
  username: Yup.string(),
  phone_number: Yup.string().required('Teléfono requerido'),
  email: Yup.string()
    .email('Email invalido!')
    .min(2, 'Muy corto!')
    .required('Email requerido'),
  password: Yup.string().min(2, 'Muy corto!').required('Contraseña requerido'),
  TyC_accepted: Yup.boolean()
    .required('Debe de aceptar los términos y condiciones')
    .oneOf([true], 'Debe de aceptar los términos y condiciones'),
  modality_CTC: Yup.string().required('Modalidad requerido'),
  country: Yup.string().required('Pais requerido'),
  doc_type: Yup.string().required('Tipo de documento requerido'),
  doc_number: Yup.string().required('Número de documento requerido'),
  status: Yup.string().required('Status requerido')
});

interface Props {
  refetch: () => {};
  onClose: () => void;
}

const CreateTeacher: React.FC<Props> = ({ refetch, onClose }) => {
  const createTeacher = useMutation(
    (teacher: any) => POST(`/account/register-teacher/`, teacher),
    { retry: false }
  );

  const initialValues = {
    first_name: '',
    last_name: '',
    username: '',
    phone_number: '',
    email: '',
    password: '',
    modality_CTC: '',
    country: '',
    doc_type: '',
    doc_number: '',
    status: '',
    TyC_accepted: true
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async values => {
        const data = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,

          username: values.email,
          email: values.email,

          password: values.password,
          TyC_accepted: values.TyC_accepted,

          modality_CTC: values.modality_CTC,
          country: values.country,
          doc_type: values.doc_type,
          doc_number: values.doc_number,
          status: values.status
        };

        await toast.promise(
          createTeacher.mutateAsync(data!),
          {
            loading: 'Cargando...',
            success: `${data.first_name} Creado`,
            error: 'Error'
          },
          { success: { icon: <AddCircleIcon color="primary" /> } }
        );

        refetch();
        onClose();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowGap={2}>
            {/* Details */}
            <Grid item container columnSpacing={2} rowGap={3} xs={12}>
              {/* First_name */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="first_name"
                  label="Nombres"
                  variant="outlined"
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.first_name)}
                  </Alert>
                )}
              </Grid>

              {/* Last_name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="Apellidos"
                  variant="outlined"
                  value={values.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && touched.last_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.last_name)}
                  </Alert>
                )}
              </Grid>

              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Correo CTC"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Alert severity="error">
                    {getErrorMessage(errors.email)}
                  </Alert>
                )}
              </Grid>

              {/* Password */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password"
                  label="Contraseña"
                  variant="outlined"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && touched.password && (
                  <Alert severity="error">
                    {getErrorMessage(errors.password)}
                  </Alert>
                )}
              </Grid>

              {/* Phone_number */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone_number"
                  label="Teléfono"
                  variant="outlined"
                  value={values.phone_number}
                  onChange={handleChange}
                />
                {errors.phone_number && touched.phone_number && (
                  <Alert severity="error">
                    {getErrorMessage(errors.phone_number)}
                  </Alert>
                )}
              </Grid>
              {/* modality_CTC */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="modality_CTC">Tipo de contrato</InputLabel>
                  <Select
                    fullWidth
                    labelId="modality_CTC"
                    id="modality_CTC"
                    value={values.modality_CTC}
                    label="modality_CTC"
                    onChange={(value: any) =>
                      setFieldValue('modality_CTC', value.target.value)
                    }
                    name="modality_CTC"
                  >
                    <MenuItem value="locator">Locador</MenuItem>
                    <MenuItem value="self">Por horas</MenuItem>
                    <MenuItem value="part_time">Part Time</MenuItem>
                  </Select>
                  {errors.modality_CTC && touched.modality_CTC && (
                    <Alert severity="error">{errors.modality_CTC}</Alert>
                  )}
                </FormControl>
              </Grid>
              {/* Country */}
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="country"
                  options={countries}
                  autoHighlight
                  defaultValue={countries.find(
                    country => country.code === values.country
                  )}
                  onChange={(_e, value: any) =>
                    setFieldValue('country', value?.code || '')
                  }
                  getOptionLabel={option => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="País"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.country && touched.country && (
                  <Alert severity="error">
                    {getErrorMessage(errors.country)}
                  </Alert>
                )}
              </Grid>
              {/* document_type */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="doc_type">Tipo de documento</InputLabel>
                  <Select
                    fullWidth
                    labelId="doc_type"
                    id="doc_type"
                    value={values.doc_type}
                    label="Tipo de documento"
                    onChange={(value: any) =>
                      setFieldValue('doc_type', value.target.value)
                    }
                    name="doc_type"
                  >
                    <MenuItem value="DNI">DNI</MenuItem>
                    <MenuItem value="cedula">Cédula</MenuItem>
                    <MenuItem value="CPP">CPP</MenuItem>
                    <MenuItem value="passport">Pasaporte</MenuItem>
                    <MenuItem value="CURP">CURP</MenuItem>
                    <MenuItem value="alien-certificate">
                      Carnet de Extranjería
                    </MenuItem>
                  </Select>
                  {errors.doc_type && touched.doc_type && (
                    <Alert severity="error">
                      {getErrorMessage(errors.doc_type)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>
              {/* document_number */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="doc_number"
                  label="Número de documento"
                  variant="outlined"
                  value={values.doc_number}
                  onChange={handleChange}
                />
                {errors.doc_number && touched.doc_number && (
                  <Alert severity="error">
                    {getErrorMessage(errors.doc_number)}
                  </Alert>
                )}
              </Grid>
              {/* status */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    fullWidth
                    labelId="status"
                    id="status"
                    value={values.status}
                    label="Status"
                    onChange={(value: any) =>
                      setFieldValue('status', value.target.value)
                    }
                    name="status"
                  >
                    <MenuItem value="activo">Activo</MenuItem>
                    <MenuItem value="desactivando">Desactivando</MenuItem>
                    <MenuItem value="inactivo">Inactivo</MenuItem>
                  </Select>
                  {errors.status && touched.status && (
                    <Alert severity="error">{errors.status}</Alert>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Create */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={createTeacher.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<AddCircleIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTeacher;
