import EvaluationStudent from './EvaluationStudent';
import { IRoomDetailSimplified } from '../../../../../interfaces/room.interface';

import './../../../rooms.scss';

interface Props {
  refetch: () => void;
  room: IRoomDetailSimplified;
}

const Evaluations: React.FC<Props> = ({ refetch, room }) => {
  return <EvaluationStudent roomInfo={room} refetch={refetch} />;
};

export default Evaluations;
