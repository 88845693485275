// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? Qualifications
export const getQualificationForTeacher = async (teacherID: any) => {
  const response = await axios.get(
    `${instanceBaseURL}/backoffice/qualification/teacher-evaluation/pending/${teacherID}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
