// React
import React, { useState } from 'react';

// Yup
import * as Yup from 'yup';

// React Query
import { QueryObserver, useMutation, useQueryClient } from 'react-query';

// Interface

// API

// Utils

// Components

// Material UI
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridValueGetterParams,
  GridRenderCellParams,
  GridSortModel
} from '@mui/x-data-grid';
import {
  Grid,
  IconButton,
  Stack,
  InputLabel,
  MenuItem,
  Box,
  FormControl,
  Input,
  Alert
} from '@mui/material';
import Select from '@mui/material/Select';
import UpdateIcon from '@mui/icons-material/Update';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import { Formik, Form } from 'formik';

import CustomLoadingOverlay from '../../components/CustomLoadingOverlay/CustomLoadingOverlay';
import { getErrorMessage, objectsEqual } from '../../utils/utils';
import { POST_MICRO, DELETE_MICRO } from '../../services/api/api';
import { IDiscount } from '../../interfaces/coupon.interface';

interface Props {
  discounts?: IDiscount[];
  couponProgramId: number;
}

interface IDiscountForm {
  id?: number;
  type: string;
  currency: string;
  amount: number;
  program_id: number;
}

const CreateDiscount: React.FC<Props> = ({ discounts, couponProgramId }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'asc'
    }
  ]);

  const createDiscount = useMutation(
    (data: IDiscountForm) =>
      POST_MICRO(`/discount/${data.type}/${data.currency}`, data),
    { retry: false }
  );

  const deleteDiscount = useMutation(
    (dataId: number) => DELETE_MICRO(`/discount/delete/${dataId}`),
    { retry: false }
  );

  const queryClient = useQueryClient();
  const { refetch: refetchCouponProgram } = new QueryObserver(queryClient, {queryKey: 'coupon-program'});

  const handleRemoveDiscount = async (discountId: any) => {
    await deleteDiscount.mutateAsync(discountId);

    refetchCouponProgram();
  };

  const DiscountDataSchema = Yup.object().shape({
    amount: Yup.number()
      .test('Is positive?', 'Ingrese un valor', value => value! > 0)
      .required('Requerido'),
    currency: Yup.string().required('Divisa es requerido'),
    type: Yup.string().required('Este campo es requerido')
  });

  const initialValues = {
    type: '',
    currency: '',
    amount: 0,
    program_id: couponProgramId
  };

  function generateColumnsByCoupons(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'currency',
        headerName: 'Currency',
        width: 200,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.currency;
        }
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 200,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return `${params.row.type === 'percentage' ? 'Porcentaje' : 'Fijo'}`;
        }
      },
      {
        field: 'value',
        headerName: 'Value',
        width: 200,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.amount;
        }
      },
      {
        field: 'delete',
        headerName: 'Delete',
        width: 85,
        minWidth: 85,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => handleRemoveDiscount(params.row.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        }
      }
    ];
    return columns;
  }

  const currentlySelected = (model: GridSortModel) => {
    !objectsEqual(model, sortModel) && setSortModel(model);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={DiscountDataSchema}
        onSubmit={async (values, { resetForm }) => {
          const data = {
            type: values.type,
            currency: values.currency,
            amount: values.amount,
            program_id: values.program_id
          };
          await toast.promise(
            createDiscount.mutateAsync(data!),
            {
              loading: 'Cargando...',
              success: 'Descuento creado',
              error: 'Error'
            },
            { success: { icon: <UpdateIcon color="primary" /> } }
          );
          refetchCouponProgram();
          resetForm();
        }}
      >
        {({
 errors, touched, values, handleChange 
}) => (
          <Form>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={1.5}
                  justifyContent="space-between"
                >
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                      <InputLabel id="type-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="type-simple-select-label"
                        id="type"
                        value={values.type}
                        label="Type"
                        onChange={handleChange}
                        name="type"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="fixed">Fijo</MenuItem>
                        <MenuItem value="percentage">Porcentaje</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.type && touched.type && (
                      <Alert severity="error">{errors.type}</Alert>
                    )}
                  </Box>
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                      <InputLabel id="currency-simple-select-label">
                        Currency
                      </InputLabel>
                      <Select
                        labelId="currency-simple-select-label"
                        id="currency"
                        value={values.currency}
                        label="Currency"
                        onChange={handleChange}
                        name="currency"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="usd">usd</MenuItem>
                        <MenuItem value="mxn">mxn</MenuItem>
                        <MenuItem value="pen">pen</MenuItem>
                        <MenuItem value="cop">cop</MenuItem>
                        <MenuItem value="ars">ars</MenuItem>
                        <MenuItem value="uyu">uyu</MenuItem>
                        <MenuItem value="clp">clp</MenuItem>
                        <MenuItem value="crc">crc</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.currency && touched.currency && (
                      <Alert severity="error">{errors.currency}</Alert>
                    )}
                  </Box>

                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                      <InputLabel id="amount-simple-select-label">
                        {values.type == 'fixed' ? 'Amount' : 'Percentage'}
                      </InputLabel>
                      <Input
                        id="amount"
                        value={values.amount}
                        onChange={handleChange}
                        name={'amount'}
                        type="number"
                      />
                    </FormControl>
                    {errors.amount && touched.amount && (
                      <Alert severity="error">
                        {getErrorMessage(errors.amount)}
                      </Alert>
                    )}
                  </Box>

                  <LoadingButton
                    sx={{ minWidth: 150 }}
                    loading={createDiscount.isLoading}
                    variant="contained"
                    type="submit"
                    startIcon={<AddCircleIcon />}
                  >
                    Add
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid xs={12}>
        <DataGrid
          autoHeight
          style={{ fontSize: 12, height: 200 }}
          sortModel={sortModel}
          onSortModelChange={model => currentlySelected(model)}
          rows={discounts!}
          columns={generateColumnsByCoupons()}
          pageSize={20}
          rowsPerPageOptions={[20]}
          components={{ LoadingOverlay: CustomLoadingOverlay }}
        />
      </Grid>
    </>
  );
};

export default CreateDiscount;
