import { Formik, Form, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './modalCreateRoom.scss';
import {
  addRoom,
  addRoomManualLinkZoom,
  getAllRooms,
  selectStateRooms
} from '../../../slices/roomSlice/roomsSlice';
import { clearString } from '../../../utils/utils';
import { useModal } from '../../../hooks/useModal';
import Modal from '../../../components/atoms/Modal';
import colors from '../../../components/atoms/Colors';
import {
  listRoomsByGroupId,
  selectStateRoomsByGroupId
} from '../../../slices/roomSlice/roomsByGroupIdSlice';
import Input from '../../../components/molecules/Input';
import PERMISSIONS from '../../../constants/permission.constants';
import IconDiscord from '../../../components/atoms/icons/IconDiscord';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import ButtonLineal from '../../../components/molecules/ButtonLineal';
import { ITeacherProfile } from '../../../interfaces/teacher.interface';
import IconWhatsapp from '../../../components/atoms/icons/IconWhatsapp';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import Typography from '../../../components/atoms/Typography/Typography';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import { ICreateRoom, ITeacher } from '../../../interfaces/group.interface';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import SelectFunctional from '../../../components/molecules/SelectFunctional';
import ModalMessageAlert from '../../../components/molecules/ModalMessageAlert';
import {
  CreateRoomSchema,
  CreateRoomWithManualLinkZoom
} from './validation.schema';
import CollapseWrapper from '../../../components/molecules/CollapseWrapper/CollapseWrapper';

interface Props {
  teachers: ITeacher[];
  groupId: number;
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
}

interface ZoomFieldProps {
  label: string;
  placeholder: string;
  name: string;
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: any;
  touched: any;
  isRequired?: boolean;
}

interface ITeacherSelect {
  email: string;
  full_name: string;
  id: number;
}

const ModalCreateRoom: React.FC<Props> = ({
  teachers,
  groupId,
  isOpenModal,
  closeModal
}) => {
  const {
    openModal: openModalAlertCreateRoom,
    closeModal: closeModalAlertCreateRoom,
    isOpenModal: isOpenModalAlertCreateRoom
  } = useModal();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const { error: errorCreateRoom } = useSelector(selectStateRooms);
  const { rooms, isLoading } = useSelector(selectStateRoomsByGroupId);
  const [teacherName, setTeacherName] = useState<string>();
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [selectOption1, setSelectOption1] = useState<any>();
  const [teacherProfile, setTeacherProfile] = useState<ITeacherProfile>();
  const [alertSelectFunctional, setAlertSelectFunctional] = useState(false);
  const [isLoadingCreateRoom, setIsLoadingCreateRoom] = useState(false);

  const userId = Number(
    user?.permission[PERMISSIONS.roomListAll] ? 0 : user?.id
  );

  const initialValues: ICreateRoom = {
    name: '',
    topic: '',
    teacher: -1,
    group: groupId,

    zoom_join_url: '',
    zoom_license: '',
    zoom_password: '',

    meet_id: '',
    meet_password: ''
  };

  const valuesRoom = [
    {
      id: 'name',
      label: 'Nombre',
      placeholder: 'Nombre de salón'
    },
    {
      id: 'topic',
      isRequired: true,
      label: 'Asunto',
      placeholder: 'Asunto de salón'
    },
    {
      id: 'zoom_join_url',
      label: 'Enlace',
      placeholder: 'Enlace de Zoom del estudiante'
    },
    {
      id: 'zoom_license',
      label: 'Licencia',
      placeholder: 'Licencia de Zoom'
    },
    {
      id: 'zoom_password',
      label: 'Contraseña',
      placeholder: 'Contraseña de Zoom'
    },
    {
      id: 'meet_id',
      isRequired: false,
      label: 'ID meet',
      placeholder: 'ID meet'
    },
    {
      id: 'meet_password',
      label: 'Contraseña',
      placeholder: 'Contraseña de meet'
    }
  ];

  const optionSelection1 = (option: ITeacherSelect): void => {
    setTeacherName(option.full_name);
    setSelectOption1(option);
  };

  const RenderContactButton = ({
    label,
    contactType,
    iconColor
  }: {
    label: string;
    contactType: string;
    iconColor: string;
  }) => {
    const validationTeacher =
      teacherProfile && teacherProfile[contactType as keyof ITeacherProfile];

    const link =
      contactType === 'discord'
        ? `https://discord.com/users/${teacherProfile?.discord}`
        : `https://api.whatsapp.com/send/?phone=${teacherProfile?.phone_number}`;

    const color = validationTeacher ? iconColor : colors.gris03;

    return (
      <ButtonLineal
        className="modal-create-room__button-contact"
        size="small"
        external
        underline={false}
        disabled={validationTeacher ? false : true}
        to={link}
      >
        <section
          className={`modal-create-room__container-${
            contactType === 'discord' ? 'discord' : 'whatsapp'
          }`}
        >
          {contactType === 'discord' ? (
            <IconDiscord fill={color} />
          ) : (
            <IconWhatsapp fill={color} />
          )}
          <Typography type="paragraph2" variation="mobile" bold color={color}>
            {label}
          </Typography>
        </section>
      </ButtonLineal>
    );
  };

  const ZoomField: React.FC<ZoomFieldProps> = ({
    label,
    placeholder,
    name,
    value,
    onChange,
    error,
    touched,
    isRequired = false
  }) => (
    <>
      <Typography bold type="paragraph2" variation="mobile">
        {label}
      </Typography>
      <Input
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        required={isRequired}
        error={error && touched ? error : ''}
      />
    </>
  );

  const commonCreateRoomLogic = async (
    typeResponse: string,
    resetForm: FormikHelpers<ICreateRoom>['resetForm']
  ) => {
    const rejectedTypes = [
      'rooms/addRoomManualLinkZoom/rejected',
      'rooms/addRoom/rejected'
    ];

    if (rejectedTypes.includes(typeResponse)) {
      setIsLoadingCreateRoom(false);
      openModalAlertCreateRoom();
    } else {
      resetForm();
      setSelectOption1(null);
      closeModal();
      setIsLoadingCreateRoom(false);
      await dispatch(listRoomsByGroupId(Number(groupId)));
      await dispatch(getAllRooms({ userId, currentPage: 1, size: 100 }));
    }
  };

  const createRoomWithManualLinkZoom = async (
    values: ICreateRoom,
    resetForm: FormikHelpers<ICreateRoom>['resetForm']
  ) => {
    setIsLoadingCreateRoom(true);
    const data = {
      name: values.name.trim(),
      topic: values.topic.trim(),
      teacher: selectOption1?.id && teacherName ? selectOption1?.id : 0,
      group: values.group,

      zoom_join_url: values.zoom_join_url!,
      zoom_license: values.zoom_license!,
      zoom_password: values.zoom_password!.trim(),

      meet_id: clearString(values.meet_id!),
      meet_password: values.meet_password!.trim()
    };
    const response = await dispatch(addRoomManualLinkZoom(data));

    await commonCreateRoomLogic(response.type, resetForm);
  };

  const createRoom = async (
    values: ICreateRoom,
    resetForm: FormikHelpers<ICreateRoom>['resetForm']
  ) => {
    setIsLoadingCreateRoom(true);
    const onlyNecessaryData = {
      name: values.name.trim(),
      topic: values.topic.trim(),
      teacher:
        selectOption1 && selectOption1?.id && teacherName && selectOption1?.id,
      group: values.group
    };
    const response = await dispatch(addRoom(onlyNecessaryData));

    await commonCreateRoomLogic(response.type, resetForm);
  };

  if (isLoading || !rooms) return <ScreenLoader />;

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={
          isOpenCollapse ? CreateRoomWithManualLinkZoom : CreateRoomSchema
        }
        onSubmit={async (values, { resetForm }): Promise<void> => {
          try {
            if (selectOption1?.id >= 0 && !alertSelectFunctional) {
              isOpenCollapse
                ? createRoomWithManualLinkZoom(values, resetForm)
                : createRoom(values, resetForm);
            }
          } catch (error) {
            setIsLoadingCreateRoom(false);
            resetForm();
            setSelectOption1(null);
          }
        }}
      >
        {({
 errors, touched, values, handleChange 
}) => (
          <Form>
            <Modal
              width="xl"
              isOpen={isOpenModal}
              closeModal={closeModal}
              title="Crear Salón"
              body={
                <div className="modal-create-room__content-body">
                  <div className="modal-create-room__room-information">
                    {valuesRoom
                      .map(field => (
                        <ZoomField
                          key={field.id}
                          label={field.label}
                          placeholder={field.placeholder}
                          name={field.id}
                          value={String(values[field.id as keyof ICreateRoom])}
                          onChange={handleChange}
                          isRequired={true}
                          error={errors[field.id as keyof ICreateRoom]}
                          touched={touched[field.id as keyof ICreateRoom]}
                        />
                      ))
                      .slice(0, 2)}

                    <Typography bold type="paragraph2" variation="mobile">
                      Profesor
                    </Typography>
                    <div className="modal-create-room__container-select">
                      <SelectFunctional
                        setIsOpen={setIsOpenSelect}
                        isOpen={isOpenSelect}
                        size="default"
                        onclickDefaultValue={optionSelection1}
                        identifierSort="full_name"
                        options={teachers}
                        disabled={!teachers}
                        alert={alertSelectFunctional}
                        setAlert={setAlertSelectFunctional}
                        teacherProfile={teacherProfile}
                        setTeacherProfile={setTeacherProfile}
                        teacherName={teacherName}
                        setTeacherName={setTeacherName}
                      />
                    </div>
                    <Typography bold type="paragraph2" variation="mobile">
                      Contactar
                    </Typography>
                    <section className="modal-create-room__section-contact">
                      <RenderContactButton
                        label="Discord"
                        contactType="discord"
                        iconColor={colors.discord}
                      />
                      <RenderContactButton
                        label="WhatsApp"
                        contactType="phone_number"
                        iconColor={colors.whatsapp}
                      />
                    </section>
                  </div>

                  <div className="modal-create-room__zoom-information">
                    <CollapseWrapper
                      isOpen={isOpenCollapse}
                      setIsOpen={setIsOpenCollapse}
                      title="Asignar enlace de Zoom"
                      body={
                        <div className="modal-create-room__content-zoom-information">
                          {valuesRoom
                            .map(field => (
                              <ZoomField
                                key={field.id}
                                label={field.label}
                                placeholder={field.placeholder}
                                name={field.id}
                                value={String(
                                  values[field.id as keyof ICreateRoom]
                                )}
                                onChange={handleChange}
                                error={errors[field.id as keyof ICreateRoom]}
                                touched={touched[field.id as keyof ICreateRoom]}
                              />
                            ))
                            .slice(2, 7)}
                        </div>
                      }
                    />
                  </div>
                  <section className="modal-create-room__container-buttons">
                    <ButtonOutline
                      type="reset"
                      size="default"
                      disabled={isLoadingCreateRoom}
                      onClick={() => {
                        closeModal();
                        setSelectOption1(null);
                        setTeacherName('');
                      }}
                    >
                      Cancelar
                    </ButtonOutline>
                    <ButtonFilled
                      type="submit"
                      size="default"
                      disabled={isLoadingCreateRoom}
                      onClick={
                        !selectOption1?.id ||
                        !teacherName ||
                        teacherName.length < 8
                          ? () => setAlertSelectFunctional(true)
                          : () => setAlertSelectFunctional(false)
                      }
                    >
                      Crear
                    </ButtonFilled>
                  </section>
                </div>
              }
            />
          </Form>
        )}
      </Formik>
      {errorCreateRoom && (
        <ModalMessageAlert
          width="xs"
          title={
            <Typography bold type="paragraph1" variation="desktop">
              Algo salió mal...
            </Typography>
          }
          message="Parece que has añadido un valor incorrecto o que ya expiró. Revisa y corrije el espacio marcado en rojo para continuar."
          isOpenModal={isOpenModalAlertCreateRoom}
          openModal={openModalAlertCreateRoom}
          closeModal={closeModalAlertCreateRoom}
          button1={
            <ButtonFilled size="default" onClick={closeModalAlertCreateRoom}>
              Aceptar
            </ButtonFilled>
          }
        />
      )}
    </div>
  );
};

export default ModalCreateRoom;
