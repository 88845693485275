import { Tooltip } from '@mui/material';

import './tableCTCB2BOrganizations.scss';
import Table from '../../../molecules/Table';
import TagCourses from '../../../molecules/TagCourses';
import Link from '../../../../components/molecules/Link';
import ButtonLineal from '../../../molecules/ButtonLineal';
import ButtonOutline from '../../../molecules/ButtonOutline';
import Typography from '../../../atoms/Typography/Typography';
import ScreenLoader from '../../../ScreenLoader/ScreenLoader';
import IconArrowRight from '../../../atoms/icons/IconArrowRight';
import useOrganizations from '../../../../hooks/useOrganizations';
import { getOrganizationsSerializer } from '../../../../services/serializer/ctcbtb.serializer';
import { FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS } from '../../../../constants/ctcb2b.constants';

const TableCTCB2BOrganizations = (
  shouldRefresh: boolean,
  setCurrentPosition: any,
  setGeneralFilter: any
) => {
  const {
    size,
    isLoading,
    totalCount,
    totalPages,
    hasFilters,
    currentPage,
    resetFilter,
    organizations,
    setCurrentPage,
    currentFilters,
    setCurrentFilters
  } = useOrganizations(shouldRefresh);

  const handleClick = (organization: string) => {
    setCurrentPosition('0');
    setGeneralFilter(organization);
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      disableSortBy: true,
      widthColumn: 60
    },
    {
      Header: 'Organización',
      accessor: 'name',
      disableSortBy: true,
      widthColumn: 200,
      Cell: ({ cell }: any) => (
        <Tooltip title={cell.value} placement="top">
          <span>{cell.value}</span>
        </Tooltip>
      )
    },
    {
      Header: 'Tipo de organización',
      accessor: 'organization_type',
      disableSortBy: true,
      widthColumn: 180
    },
    {
      Header: 'País',
      accessor: 'country_id',
      disableSortBy: true,
      widthColumn: 120
    },
    {
      Header: 'Razón social',
      accessor: 'business_name',
      disableSortBy: true,
      widthColumn: 300,
      Cell: ({ cell }: any) => (
        <Tooltip title={cell.value} placement="top">
          <span>{cell.value}</span>
        </Tooltip>
      )
    },
    {
      Header: 'Owner comercial',
      accessor: 'business_owner',
      disableSortBy: true,
      widthColumn: 180
    },
    {
      Header: '',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ cell }: { cell: any }) => (
        <span style={{ marginLeft: '20px' }}>
          {' '}
          <Link
            className="table-organizations__link"
            to={`/dashboard/ctcb2b/${cell.row.values.id}/organizations`}
          >
            <ButtonOutline
              className="table-organizations__button_view_organization"
              type="button"
              size="default"
            >
              <span>
                <Typography type="paragraph2" variation="mobile" bold={true}>
                  Ver organización
                </Typography>
              </span>

              <span className="table-organizations__icon-container">
                <IconArrowRight width="22" height="24" />
              </span>
            </ButtonOutline>
          </Link>
          <ButtonLineal
            size="small"
            className="table-organizations__button_view_projects"
            onClick={() => handleClick(cell.row.values.name)}
          >
            <Typography type="paragraph2" variation="mobile" bold={true}>
              Ver proyectos
            </Typography>
          </ButtonLineal>
        </span>
      )
    }
  ];

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters,
    totalRows: totalCount,
    totalPages,
    pageSize: size
  };

  if (isLoading) return <ScreenLoader />;

  return (
    <>
      {hasFilters && (
        <section className="guardians_filter__container_courses">
          <p className="typography typography__paragraph2 typography--undefined typography__paragraph2--mobile typography__bold  teacher-profile__typography">
            Filtro Actual:{' '}
          </p>
          {Object.keys(currentFilters).map((key: string, index) => (
            <TagCourses className="guardians_filter__tag" key={index}>
              <Typography type="paragraph2" variation="mobile" bold>
                {FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS.get(key) +
                  ': ' +
                  currentFilters[key]}
              </Typography>
              <ButtonLineal
                size="small"
                className="guardians_filter__button_removal"
                onClick={() => resetFilter(key)}
              >
                &#10005;
              </ButtonLineal>
            </TagCourses>
          ))}
        </section>
      )}
      <Table
        data={getOrganizationsSerializer(organizations)}
        columns={columns}
        paginationState={paginationState}
      />
    </>
  );
};

export default TableCTCB2BOrganizations;
