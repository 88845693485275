import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IGuardian } from '../../interfaces/guardian.interface';
import { getGuardians, postCreateGuardian } from '../../services/guardians';

export interface IAuthError {
  msg: string;
}

export interface IGuardianState {
  guardians: IGuardian[];
  guardian: IGuardian | null;
  isLoading: boolean;
  completed: boolean;
  error: IAuthError | null;
  rejected: boolean;
  totalPages: number;
  totalCount: number;
}

const initialState: IGuardianState = {
  guardians: [],
  guardian: null,
  isLoading: false,
  completed: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const getGuardiansFromAPI = createAsyncThunk(
  'guardians/getAllGuardians',
  async (guardianInfo: any, { rejectWithValue }: any) => {
    const { currentPage, filters } = guardianInfo;
    try {
      const data = await getGuardians(currentPage, filters);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const postGuardian = createAsyncThunk(
  'guardians/postGuardian',
  async (
    newGuardian: IGuardian,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await postCreateGuardian(newGuardian);
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const guardiansSlice = createSlice({
  name: 'guardians',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getAllGuardians */
    builder.addCase(getGuardiansFromAPI.pending, (state, _) => {
      state.guardians = initialState.guardians;
      state.isLoading = true;
    });
    builder.addCase(getGuardiansFromAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.guardians = action.payload.results;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.count;
      state.error = null;
    });
    builder.addCase(getGuardiansFromAPI.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** postGuardian */
    builder.addCase(postGuardian.pending, (state, _) => {
      state.isLoading = true;
      state.completed = false;
    });
    builder.addCase(postGuardian.fulfilled, (state, action) => {
      state?.guardians?.push(action.payload);
      state.isLoading = false;
      state.completed = true;
      state.error = null;
    });
    builder.addCase(postGuardian.rejected, (state, action) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
      state.completed = false;
      state.error = { msg: (action.payload as any).message || 'Error Server' };
    });
  }
});

export const selectStateGuardians = (state: any) => state.guardians;
