import TagStatus from '../../atoms/TagStatus/Tag';

import './tagStatusReport.scss';

interface Props {
  status?: 'pending' | 'done';
  children: any;
}

const TagStatusReport: React.FC<Props> = ({ children, status }) => {
  return (
    <TagStatus className={`tag-status-report tag-status-report--${status}`}>
      {children === 'pending'
        ? 'Pendiente'
        : children === 'done'
          ? 'Resuelto'
          : children}
    </TagStatus>
  );
};

export default TagStatusReport;
