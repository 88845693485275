import { Formik, Form } from 'formik';
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import './modalCreateLanding.scss';
import Modal from '../../components/atoms/Modal';
import Input from '../../components/molecules/Input';
import { CreateLandingSchema } from './validation.schema';
import { AppDispatch, useAppDispatch } from '../../store/store';
import FormConfirm from '../../components/organism/FormConfirm';
import ButtonFilled from '../../components/molecules/ButtonFilled';
import ButtonOutline from '../../components/molecules/ButtonOutline';
import Typography from '../../components/atoms/Typography/Typography';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import {
  addLanding,
  updateLanding
} from '../../slices/landingsSlice/landingSlice';

const pricesTemplate = process.env.REACT_APP_CTC_LANDINGS_PRICES_TEMPLATE;

const ModalCreateLanding: React.FC<{
  setShouldRefresh: (e: boolean) => void;
  isOpenModal: any;
  closeModal: any;
  openModal: any;
  isEdit?: boolean;
  data?: any;
  landingId?: number;
}> = ({
  setShouldRefresh,
  isOpenModal,
  closeModal,
  openModal,
  isEdit,
  data,
  landingId
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const [showConfirm, setShowConfirm] = useState(false);

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  useEffect(() => {
    setShowConfirm(false);
  }, [isOpenModal]);

  const initialValues: {
    id?: number;
    name: string;
    title: string;
    subtitle: string;
    color: string;
    image: string;
    file?: any;
  } = {
    name: isEdit ? data.name : '',
    title: isEdit ? data.title : '',
    subtitle: isEdit ? data.subtitle : '',
    color: isEdit ? data.color : '',
    image: isEdit ? data.image : '',
    file: isEdit ? data.file : 'xxxxxx'
  };

  const validateSelects = () => {};

  const orgTitle = isEdit
    ? 'Edita los datos de la landing'
    : 'Ingresa los datos de la landing';

  const landingFormTitle = !showConfirm ? orgTitle : '';

  const getButtonText = isEdit ? 'Guardar cambios' : 'Crear Landing';

  const tooltipText = `- El template tiene dos hojas, una para cursos y otra para rutas.
- Si se deja una columna de precio vacía, no aparecerán en la landing los precios en esa moneda.
- El usuario siempre verá el precio en la moneda de su geolocalización, siempre que los precios en la misma estén cargados. Sino, verá el precio en USD.
  `;

  const openGoogleDriveLink = () => {
    const googleDriveURL = `${pricesTemplate}`;

    window.open(googleDriveURL, '_blank');
  };

  const createLanding = async (payload: any) => {
    try {
      const response: any = await dispatch(addLanding(payload));
      if (!response?.error?.message) {
        setHasError(false);
        setShowConfirm(true);
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const editLanding = async (id: number, payload: any) => {
    try {
      const response: any = await dispatch(updateLanding({ id, payload }));
      if (!response?.error?.message) {
        setHasError(false);
        setShowConfirm(true);
        return;
      }
      setHasError(true);
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const FileInput = ({ onChange }: { onChange: any }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          className="file-input-container"
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: '#313131',
            overflow: 'hidden',
            borderRadius: '0.375rem',
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
            paddingLeft: '2rem',
            paddingRight: '2rem'
          }}
        >
          <input
            type="file"
            id="file"
            className="file-input"
            onChange={onChange}
            style={{
              width: '0.1px',
              height: '0.1px',
              opacity: '0',
              position: 'absolute',
              zIndex: -1
            }}
          />
          <label
            htmlFor="file"
            className="file-label"
            style={{
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              userSelect: 'none',
              background: 'none',
              border: 'none',
              fontSize: '16px',
              lineHeight: '21px',
              color: '#313131',
              fontFamily: 'sofia pro',
              fontWeight: 'bold'
            }}
          >
            Subir archivo .xlsx
          </label>
        </div>
        {selectedFile && (
          <div className="selected-file">{selectedFile?.name}</div>
        )}
      </div>
    );
  };

  return (
    <Modal
      width="xl"
      isOpen={isOpenModal}
      closeModal={closeModal}
      title={isLoading ? '' : landingFormTitle}
      body={
        isLoading ? (
          <ScreenLoader
            fullWidth={false}
            customStyles={{ flexDirection: 'column' }}
          />
        ) : !showConfirm ? (
          <Formik
            initialValues={initialValues}
            validationSchema={CreateLandingSchema}
            onSubmit={async (values, { resetForm }) => {
              setIsLoading(true);
              const bodyFormData = new FormData();
              bodyFormData.append('file', values.file);

              const payload = {
                ...values,
                file: bodyFormData
              };

              if (isEdit) {
                setShouldRefresh(false);
                await editLanding(Number(landingId), payload);
                setIsLoading(false);
                if (hasError) {
                  return;
                }
                setSelectedFile(null);
                resetForm();
                return;
              }

              setIsLoading(false);
              setShowConfirm(true);
              await createLanding(payload);
              setIsLoading(false);
              if (hasError) {
                setSelectedFile(null);
                return;
              }
              setSelectedFile(null);
              resetForm();
            }}
          >
            {({
 errors, touched, values, setFieldValue, handleChange 
}) => (
              <Form>
                <div className="modal-create-landing__content-body">
                  <Typography type="paragraph2" variation="mobile">
                    Todos los campos son obligatorios
                  </Typography>
                  <div className="modal-create-landing__landing-information">
                    <div>
                      <Typography bold type="paragraph3" variation="mobile">
                        Nombre de la alianza
                      </Typography>
                      <Typography type="small-12" variation="mobile">
                        Se mostrará en la url
                      </Typography>
                    </div>
                    <Input
                      placeholder="Añade el nombre"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      required
                      error={errors.name && touched.name ? errors.name : ''}
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Título Header
                    </Typography>
                    <Input
                      placeholder="Añade el título"
                      name="title"
                      onChange={handleChange}
                      value={values.title}
                      required
                      error={errors.title && touched.title ? errors.title : ''}
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Subtítulo Header
                    </Typography>
                    <Input
                      placeholder="Añade el subtítulo"
                      name="subtitle"
                      onChange={handleChange}
                      value={values.subtitle}
                      required
                      error={
                        errors.subtitle && touched.subtitle
                          ? errors.subtitle
                          : ''
                      }
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Código de color hexadecimal del header
                    </Typography>
                    <Input
                      placeholder="Añade el color"
                      name="color"
                      onChange={handleChange}
                      value={values.color}
                      required
                      error={errors.color && touched.color ? errors.color : ''}
                    />
                    <Typography bold type="paragraph3" variation="mobile">
                      Subir logo
                    </Typography>
                    <Input
                      placeholder="Añade logo"
                      name="image"
                      onChange={handleChange}
                      value={values.image}
                      required
                      error={errors.image && touched.image ? errors.image : ''}
                    />
                  </div>

                  <section className="modal-create-landing__container-buttons">
                    <Typography bold type="paragraph3" variation="mobile">
                      Subir archivo con cursos, rutas y sus precios
                    </Typography>

                    <Tooltip title={tooltipText} arrow placement={'right'}>
                      <IconButton
                        aria-label="more-info"
                        color="inherit"
                        size="small"
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </section>

                  <section className="modal-create-landing__container-buttons">
                    <ButtonOutline
                      type="reset"
                      size="medium"
                      disabled={isLoadingCreate}
                      onClick={openGoogleDriveLink}
                    >
                      Descargar template
                    </ButtonOutline>
                    <FileInput
                      onChange={(e: any) => {
                        setFieldValue('file', e.target.files[0]);
                        setSelectedFile(e.target.files[0]);
                      }}
                    />
                  </section>

                  <section className="modal-create-landing__container-buttons">
                    <ButtonOutline
                      type="reset"
                      size="default"
                      disabled={isLoadingCreate}
                      onClick={() => {
                        setSelectedFile(null);
                        closeModal();
                      }}
                    >
                      Cancelar
                    </ButtonOutline>
                    <ButtonFilled
                      type="submit"
                      size="default"
                      disabled={isLoadingCreate}
                      onClick={validateSelects}
                    >
                      {getButtonText}
                    </ButtonFilled>
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div>
            <FormConfirm
              title={
                isEdit ? '¡Cambios guardados' : 'Landing creada con éxito!'
              }
              body={{
                'URL de catálogo de cursos':
                  'www.crackthecode.la/cursos?alianza=alianza',
                'URL de catálogo de rutas':
                  'www.crackthecode.la/rutas?alianza=alianza'
              }}
              closeModal={() => {
                closeModal();
                setShouldRefresh(true);
              }}
            />
          </div>
        )
      }
    />
  );
};

export default ModalCreateLanding;
