import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getSubknowledgesFromApi } from '../slices/teacherSlice/teacherSlice';

const useGetSubknowledges = ({shouldRefetch}: {
  shouldRefetch?: boolean;
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const getSubknowledges = async () => {
    try {
      return await dispatch(getSubknowledgesFromApi({}));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSubknowledges();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching Subknowledges:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  return { data, isLoading };
};

export default useGetSubknowledges;
