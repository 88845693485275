// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? Evaluations
export const getStudentEvaluation = async (roomID: any) => {
  const response = await axios.get(
    `${instanceBaseURL}/backoffice/qualification/student-evaluation/list/${roomID}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

//? Evaluations
export const postCreateBulkStudentEvaluation = async (
  roomID: any,
  evaluation: any
) => {
  const response = await axios.post(
    `${instanceBaseURL}/backoffice/qualification/student-evaluation-bulk/${roomID}/`,
    evaluation,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postCreateStudentEvaluation = async (
  roomID: any,
  evaluation: any
) => {
  const response = await axios.post(
    `${instanceBaseURL}/backoffice/qualification/student-evaluation/${roomID}/`,
    evaluation,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

//? Evaluations
export const patchStudentEvaluation = async (evaluation: any) => {
  const response = await axios.patch(
    `${instanceBaseURL}/backoffice/qualification/student-evaluation/update/`,
    evaluation,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
