// React
import { useEffect, useState } from 'react';

// Router
import { useSearchParams } from 'react-router-dom';

// React Query
import { useQuery } from 'react-query';

// API

// Utils

// Interfaces

// Material UI
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridValueGetterParams,
  GridRenderCellParams,
  GridSortModel
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { Chip, Stack } from '@mui/material';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery80Icon from '@mui/icons-material/Battery80';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';

import { ICourseList } from '../../interfaces/course.interface';
import { objectsEqual } from '../../utils/utils';
import { GET } from '../../services/api/api';

// Components
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import CustomLoadingOverlay from '../../components/CustomLoadingOverlay/CustomLoadingOverlay';
import ModalCreateCourse from './ModalCreateCourse/ModalCreateCourse';
import ModalEditCourse from './ModalEditCourse/ModalEditCourse';

interface Props {}

const Courses: React.FC<Props> = () => {
  // Auto Open Modal
  const [params] = useSearchParams();
  const [courseId] = useState(params.get('id') && parseInt(params.get('id')!));

  const [rows, setRows] = useState<any[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc'
    }
  ]);

  const {
    data: courses,
    error,
    isError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    ['section-courses'],
    () => GET<ICourseList[]>(`/backoffice/v2/courses`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    courses && setRows(courses);
  }, [courses]);

  if (isLoading || !courses) return <ScreenLoader />;
  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  const currentlySelected = (model: GridSortModel) => {
    !objectsEqual(model, sortModel) && setSortModel(model);
  };

  return (
    <Grid container rowGap={2} style={{ overflowY: 'scroll', height: '100%' }}>
      {/* Auto Open Modal */}
      {courseId && (
        <ModalEditCourse
          courseId={courseId}
          autoOpen={true}
          refetch={refetch}
        />
      )}

      {/* Create New Course */}
      <Grid item xs={12}>
        <ModalCreateCourse refetch={refetch} />
      </Grid>

      <DataGrid
        autoHeight
        style={{ fontSize: 12 }}
        loading={isFetching}
        sortModel={sortModel}
        onSortModelChange={model => currentlySelected(model)}
        rows={rows}
        columns={generateColumnsByCourses()}
        pageSize={20}
        rowsPerPageOptions={[20]}
        components={{ LoadingOverlay: CustomLoadingOverlay }}
      />
    </Grid>
  );

  function generateColumnsByCourses(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'actions',
        headerName: 'Acciones',
        width: 85,
        minWidth: 85,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <ModalEditCourse courseId={params.row.id} refetch={refetch} />
            </Stack>
          );
        }
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'name',
        headerName: 'Nombre',
        minWidth: 150,
        editable: false,
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.name;
        }
      },
      {
        field: 'is_published',
        headerName: 'Estatus',
        minWidth: 130,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.is_published;
        },
        renderCell: (params: GridRenderCellParams) => {
          switch (params.row.is_published) {
            case false:
              return (
                <Chip
                  icon={<VpnLockIcon />}
                  label="Privado"
                  variant="outlined"
                  color="warning"
                  sx={{ paddingLeft: '5px' }}
                />
              );
            case true:
              return (
                <Chip
                  icon={<PublicIcon />}
                  label="Publico"
                  variant="outlined"
                  color="success"
                  sx={{ paddingLeft: '5px' }}
                />
              );
          }
        }
      },
      {
        field: 'age_range',
        headerName: 'Rango de edad',
        minWidth: 150,
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.age_range;
        },
        renderCell: (params: GridRenderCellParams) => {
          switch (params.row.age_range) {
            case '5_to_7':
              return (
                <Chip
                  icon={<Battery20Icon />}
                  label="5 TO 7"
                  variant="outlined"
                  color="error"
                />
              );
            case '8_plus':
              return (
                <Chip
                  icon={<Battery50Icon />}
                  label="8 PLUS"
                  variant="outlined"
                  color="warning"
                />
              );
            case '9_plus':
              return (
                <Chip
                  icon={<Battery80Icon />}
                  label="9 PLUS"
                  variant="outlined"
                  color="primary"
                />
              );
            case '12_plus':
              return (
                <Chip
                  icon={<BatteryFullIcon />}
                  label="12 PLUS"
                  variant="outlined"
                  color="success"
                />
              );
            default:
              return (
                <Chip
                  icon={<BatteryUnknownIcon />}
                  label="NONE"
                  variant="filled"
                  color="error"
                />
              );
          }
        }
      },
      {
        field: 'amount_usd',
        headerName: 'Precio USD',
        minWidth: 130,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.amount_usd;
        },
        renderCell: (params: GridRenderCellParams) => {
          switch (params.row.amount_usd) {
            case '0.00':
              return (
                <Chip
                  icon={<CardGiftcardIcon />}
                  label="FREE"
                  variant="outlined"
                  color="success"
                  size="small"
                  sx={{ paddingLeft: '5px' }}
                />
              );
            default:
              return '$ ' + params.row.amount_usd;
          }
        }
      },
      {
        field: 'amount_pen',
        headerName: 'Precio PEN',
        minWidth: 130,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.amount_pen;
        },
        renderCell: (params: GridRenderCellParams) => {
          switch (params.row.amount_pen) {
            case '0.00':
              return (
                <Chip
                  icon={<CardGiftcardIcon />}
                  label="FREE"
                  variant="outlined"
                  color="success"
                  size="small"
                  sx={{ paddingLeft: '5px' }}
                />
              );
            default:
              return 's/ ' + params.row.amount_pen;
          }
        }
      },
      {
        field: 'has_discount',
        headerName: 'Descuento',
        minWidth: 150,
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.has_discount;
        },
        renderCell: (params: GridRenderCellParams) => {
          switch (params.row.has_discount) {
            case false:
              return (
                <Chip
                  icon={<NotInterestedIcon />}
                  label="NOT DISCOUNT"
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ paddingLeft: '5px' }}
                />
              );
            case true:
              return (
                <Chip
                  icon={<LocalOfferIcon />}
                  label="HAS DISCOUNT"
                  variant="outlined"
                  color="success"
                  size="small"
                  sx={{ paddingLeft: '5px' }}
                />
              );
            default:
              return (
                <Chip
                  icon={<BatteryUnknownIcon />}
                  label="NONE"
                  variant="filled"
                  color="error"
                />
              );
          }
        }
      }
    ];

    return columns;
  }
};

export default Courses;
