// React Query
import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryObserver
} from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Form, Formik } from 'formik';

// API

// Hot Toast
import { toast } from 'react-hot-toast';

// Interfaces

// Material UI
import {
  Alert,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Chip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { IFullCourse } from '../../../../../interfaces/course.interface';
import { GET, PATCH } from '../../../../../services/api/api';

// Components
import ToDo from '../../../../../components/ToDo/ToDo';
import SuperToDo from '../../../../../components/SuperToDo/SuperToDo';
import RangeAge from '../../../../../components/RangeAge/RangeAge';
import { getErrorMessage } from '../../../../../utils/utils';

const TechnicalDataSchema = Yup.object().shape({
  order_in_list: Yup.number().min(0, 'Muy pequeño!'),
  order_in_learning_path: Yup.number().min(0, 'Muy pequeño!'),
  number_of_sessions: Yup.number().min(0, 'Muy pequeño!'),
  age_range: Yup.string(),
  frequency: Yup.number().min(0, 'Muy pequeño!'),
  duration: Yup.number().min(0, 'Muy pequeño!').max(100, 'Mucho texto!'),
  available_places: Yup.number().min(0, 'Muy pequeño!'),
  main_color: Yup.string(),
  business_type: Yup.string().oneOf(
    ['B2B', 'B2C', 'B2B2C'],
    'Solo son válidas B2B, B2C y B2B2C'
  ),

  course_base_mdl_id: Yup.number().min(1, 'Muy pequeño!').required(),
  category_mdl_id: Yup.number().min(1, 'Muy pequeño!').required(),

  technical_requirements: Yup.array().required().min(1),
  lessons: Yup.array().required().min(1),
  skills_to_develop: Yup.array(),
  learnings: Yup.array()
});

interface Props {
  refetch: () => {};
  courseId: number;
  isTechnicalDataSent: boolean;
  setIsTechnicalDataSent: any;
}

const TechnicalData: React.FC<Props> = ({
  refetch,
  courseId,
  isTechnicalDataSent,
  setIsTechnicalDataSent
}) => {
  const { data: course, remove } = useQuery(
    ['course-technical-data', courseId],
    () => GET<IFullCourse>(`/courses_backoffice/${courseId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!courseId && isTechnicalDataSent
    }
  );

  const updateCourseTechnicalData = useMutation(
    (technicalData: any) =>
      PATCH(`/courses_backoffice/${courseId}/`, technicalData),
    { retry: false }
  );

  const {
    data: groupAgesCourse,
    error: errorAgesCourse,
    isError: isErrorAgesCourse,
    isLoading: isLoadingAgesCourse
  } = useQuery(
    ['group-ages-course', courseId],
    () =>
      GET<{
        model_id: number;
        model_type: string;
        min_age: number;
        max_age: number;
      }>(`/base/group-age/course/${courseId}`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const queryClient = useQueryClient();
  const { refetch: refetchAgeGroup } = new QueryObserver(queryClient, {queryKey: ['group-ages-course', courseId]});

  if (!!courseId && isTechnicalDataSent && !course) return <LinearProgress />;
  if (isLoadingAgesCourse || !groupAgesCourse) return <LinearProgress />;
  if (isErrorAgesCourse)
    return <div>Error! {JSON.stringify(errorAgesCourse)}</div>;

  let initialValues: any = {
    order_in_list: 0,
    order_in_learning_path: 0,
    number_of_sessions: 0,
    age_range: 'none',
    frequency: 0,
    duration: 0,
    available_places: 0,
    main_color: '#000000',
    business_type: 'none',

    course_base_mdl_id: 0,
    category_mdl_id: 0,

    technical_requirements: [],
    lessons: [],

    skills_to_develop: [],
    learnings: []
  };

  if (isTechnicalDataSent && course) {
    initialValues = {
      order_in_list: course.order_in_list,
      order_in_learning_path: course.order_in_learning_path,
      number_of_sessions: course.number_of_sessions,
      age_range: course.age_range,
      frequency: course.frequency,
      duration: course.duration,
      available_places: course.available_places,
      main_color: course.main_color,
      business_type: course.business_type,

      technical_requirements: course.technical_requirements,
      lessons: course.lessons,

      course_base_mdl_id: course.course_base_mdl_id,
      category_mdl_id: course.category_mdl_id,

      skills_to_develop: course.skills_to_develop,
      learnings: course.learnings,
      benefits: course.benefits
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TechnicalDataSchema}
      onSubmit={async values => {
        await toast.promise(
          updateCourseTechnicalData
            .mutateAsync(values)
            .then(() => setIsTechnicalDataSent(true)),
          {
            loading: 'Cargando...',
            success: 'Datos Técnicos creado',
            error: 'Error'
          },
          { success: { icon: <AddCircleIcon color="primary" /> } }
        );

        remove();
        refetch();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              {/* Course_base_mdl_id */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="course_base_mdl_id"
                  label="Base course moodle ID"
                  placeholder="Base course moodle ID"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.course_base_mdl_id}
                  onChange={handleChange}
                />
                {errors.course_base_mdl_id && touched.course_base_mdl_id && (
                  <Alert severity="error">
                    {getErrorMessage(errors.course_base_mdl_id)}
                  </Alert>
                )}
              </Grid>

              {/* Category_mdl_id */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="category_mdl_id"
                  label="Category moodle ID"
                  placeholder="Category moodle ID"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.category_mdl_id}
                  onChange={handleChange}
                />
                {errors.category_mdl_id && touched.category_mdl_id && (
                  <Alert severity="error">
                    {getErrorMessage(errors.category_mdl_id)}
                  </Alert>
                )}
              </Grid>

              {/* Order_in_list */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="order_in_list"
                  label="Order in list"
                  placeholder="Order in list"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.order_in_list}
                  onChange={handleChange}
                />
                {errors.order_in_list && touched.order_in_list && (
                  <Alert severity="error">
                    {getErrorMessage(errors.order_in_list)}
                  </Alert>
                )}
              </Grid>

              {/* Order_in_learning_path */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="order_in_learning_path"
                  label="Order in learning path"
                  placeholder="Order in learning path"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.order_in_learning_path}
                  onChange={handleChange}
                />
                {errors.order_in_learning_path &&
                  touched.order_in_learning_path && (
                    <Alert severity="error">
                      {getErrorMessage(errors.order_in_learning_path)}
                    </Alert>
                  )}
              </Grid>

              {/* Number_of_sessions */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="number_of_sessions"
                  label="Number of sessions"
                  placeholder="Number of sessions"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.number_of_sessions}
                  onChange={handleChange}
                />
                {errors.number_of_sessions && touched.number_of_sessions && (
                  <Alert severity="error">
                    {getErrorMessage(errors.number_of_sessions)}
                  </Alert>
                )}
              </Grid>

              {/* Age_range */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="age_range">Age range</InputLabel>
                  <Select
                    labelId="age_range"
                    id="age_range"
                    label="age_range"
                    value={values.age_range}
                    disabled={isTechnicalDataSent}
                    onChange={(value: any) =>
                      setFieldValue('age_range', value.target.value)
                    }
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="5_to_7">5 to 7</MenuItem>
                    <MenuItem value="8_plus">8 plus</MenuItem>
                    <MenuItem value="9_plus">9 plus</MenuItem>
                    <MenuItem value="10_plus">10 plus</MenuItem>
                    <MenuItem value="12_plus">12 plus</MenuItem>
                  </Select>
                  {errors.age_range && touched.age_range && (
                    <Alert severity="error">
                      {getErrorMessage(errors.age_range)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Frecuency */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="frequency"
                  label="Frecuency"
                  placeholder="Frecuency"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.frequency}
                  onChange={handleChange}
                />
                {errors.frequency && touched.frequency && (
                  <Alert severity="error">
                    {getErrorMessage(errors.frequency)}
                  </Alert>
                )}
              </Grid>

              {/* Duration */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="duration"
                  label="Duration"
                  placeholder="Duration"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.duration}
                  onChange={handleChange}
                />
                {errors.duration && touched.duration && (
                  <Alert severity="error">
                    {getErrorMessage(errors.duration)}
                  </Alert>
                )}
              </Grid>

              {/* Available_places */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="available_places"
                  label="Available places"
                  placeholder="Available places"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.available_places}
                  onChange={handleChange}
                />
                {errors.available_places && touched.available_places && (
                  <Alert severity="error">
                    {getErrorMessage(errors.available_places)}
                  </Alert>
                )}
              </Grid>

              {/* Main_color */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="main_color"
                  label="Main color"
                  placeholder="Main color"
                  variant="outlined"
                  type="color"
                  disabled={isTechnicalDataSent}
                  value={values.main_color}
                  onChange={handleChange}
                />
                {errors.main_color && touched.main_color && (
                  <Alert severity="error">
                    {getErrorMessage(errors.main_color)}
                  </Alert>
                )}
              </Grid>

              {/* Business_type */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="business_type">Business type</InputLabel>
                  <Select
                    labelId="business_type"
                    id="business_type"
                    label="business_type"
                    value={values.business_type}
                    disabled={isTechnicalDataSent}
                    onChange={(value: any) =>
                      setFieldValue('business_type', value.target.value)
                    }
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="B2C">B2C</MenuItem>
                    <MenuItem value="B2B">B2B</MenuItem>
                    <MenuItem value="B2B2C">B2B2C</MenuItem>
                  </Select>
                  {errors.business_type && touched.business_type && (
                    <Alert severity="error">
                      {getErrorMessage(errors.business_type)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Age Range */}
              <Grid item xs={12}>
                <Divider textAlign="left">
                  <Chip label="Age Range" />
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <RangeAge
                  model_id={groupAgesCourse.model_id}
                  model_type={groupAgesCourse.model_type}
                  name={groupAgesCourse.model_type}
                  min_age={groupAgesCourse.min_age}
                  max_age={groupAgesCourse.max_age}
                  refetch={refetchAgeGroup}
                />
              </Grid>

              {/* Technical_requirements */}
              <Grid item xs={6}>
                <ToDo
                  label="Technical requirements"
                  placeholder="Add technical requirements"
                  values={values.technical_requirements}
                  enabled={!!courseId && isTechnicalDataSent}
                  setFieldValue={setFieldValue}
                  to="technical_requirements"
                />
                {errors.technical_requirements &&
                  touched.technical_requirements && (
                    <Alert severity="error">
                      {getErrorMessage(errors.technical_requirements)}
                    </Alert>
                  )}
              </Grid>

              {/* Lessons */}
              <Grid item xs={6}>
                <ToDo
                  label="Lessons"
                  placeholder="Add lessons"
                  values={values.lessons}
                  enabled={!!courseId && isTechnicalDataSent}
                  setFieldValue={setFieldValue}
                  to="lessons"
                />
                {errors.lessons && touched.lessons && (
                  <Alert severity="error">
                    {getErrorMessage(errors.lessons)}
                  </Alert>
                )}
              </Grid>

              {/* Skills_to_develop */}
              <Grid item xs={6}>
                <ToDo
                  label="Skills to develop"
                  placeholder="Add skill to develop"
                  values={values.skills_to_develop}
                  enabled={!!courseId && isTechnicalDataSent}
                  setFieldValue={setFieldValue}
                  to="skills_to_develop"
                />
              </Grid>

              {/* Learnings */}
              <Grid item xs={6}>
                <SuperToDo
                  label="Learnings"
                  values={values.learnings}
                  enabled={!!courseId && isTechnicalDataSent}
                  setFieldValue={setFieldValue}
                  to="learnings"
                />
              </Grid>
            </Grid>

            {/* Benefits */}
            <Grid item xs={12}>
              <SuperToDo
                label="Benefits"
                values={values.benefits}
                enabled={false}
                setFieldValue={setFieldValue}
                to="benefits"
              />
            </Grid>

            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={updateCourseTechnicalData.isLoading}
                  variant="contained"
                  type="submit"
                  disabled={isTechnicalDataSent}
                  startIcon={<AddCircleIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TechnicalData;
