import React from 'react';

interface Props {
  fillPath?: string;
  fillCircle?: string;
  className?: string;
  dataTestid?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

const IconAddMultipleSelect: React.FC<Props> = ({
  className,
  width = '40',
  height = '32',
  viewBox = '0 0 40 32'
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="38"
        height="30"
        rx="5"
        stroke="#313131"
        strokeWidth="2"
      />
      <mask
        id="mask0_130_8297"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="8"
        y="4"
        width="24"
        height="24"
      >
        <rect x="8" y="4" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_130_8297)">
        <path d="M19 17H13V15H19V9H21V15H27V17H21V23H19V17Z" fill="black" />
      </g>
    </svg>
  );
};

export default IconAddMultipleSelect;
