import {
 Grid, Alert, Divider, TextField 
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import UpdateIcon from '@mui/icons-material/Update';

import PERMISSIONS from '../../../../../../constants/permission.constants';
import {
  getAllRooms,
  updateRoom
} from '../../../../../../slices/roomSlice/roomsSlice';
import {
  AppDispatch,
  RootState,
  useAppDispatch
} from '../../../../../../store/store';
import { listRoomsByGroupId } from '../../../../../../slices/roomSlice/roomsByGroupIdSlice';
import { getErrorMessage } from '../../../../../../utils/utils';

const EditRoomSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  topic: Yup.string().min(2, 'Muy corto!')
});

interface Props {
  groupId: number;
  roomId: number;
  roomName: string;
  roomTopic: string;
  onClose: () => void;
}

const EditRoom: React.FC<Props> = ({
  groupId,
  roomId,
  roomName,
  roomTopic,
  onClose
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const funcUpdateRoom = async (room: any) => {
    try {
      await dispatch(updateRoom({ roomId, room }));
    } catch (error) {
      console.log(error);
    } finally {
      if (user) {
        const userId = user?.permission[PERMISSIONS.roomListAll] ? 0 : user?.id;
        await dispatch(getAllRooms({ userId, currentPage: 1, size: 100 }));
      }
    }
  };

  const initialValues = {
    name: roomName,
    topic: roomTopic,
    group: groupId
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditRoomSchema}
      onSubmit={async values => {
        await funcUpdateRoom({
          name: values.name.trim(),
          topic: values.topic.trim()
        });
        dispatch(listRoomsByGroupId(Number(groupId)));
        onClose();
      }}
    >
      {({
 errors, touched, values, handleChange 
}) => (
        <Form>
          <Grid container rowGap={2}>
            {/* Basic Data */}
            <Grid item container columnSpacing={2} rowGap={2}>
              {/* Name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="name"
                  label="Nombre del salón"
                  variant="outlined"
                  placeholder="Construye con Roblox"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{getErrorMessage(errors.name)}</Alert>
                )}
              </Grid>

              {/* Topic */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="topic"
                  label="Asunto"
                  variant="outlined"
                  placeholder="Profesor John Titor"
                  value={values.topic}
                  onChange={handleChange}
                  disabled
                />
                {errors.topic && touched.topic && (
                  <Alert severity="error">{errors.topic}</Alert>
                )}
              </Grid>
            </Grid>

            {/* Edit Room */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditRoom;
