import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import './dashboardLayout.scss';
import Sidebar from '../../molecules/Sidebar';

const DashboardLayout = () => {
  const [isOpen, setIsOpen] = useState(true);

  const state = isOpen ? 'open' : 'closed';

  return (
    <main className="dashboard-layout">
      <div className={`dashboard-layout__sidebar-${state}`}>
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div className={`dashboard-layout__content-${state}`}>
        <Outlet />
      </div>
    </main>
  );
};

export default DashboardLayout;
