import { useSelector } from 'react-redux';
import { Button, Alert, AlertTitle } from '@material-ui/core';

import { RootState } from '../../../store/store';
import Link from '../../../components/molecules/Link';
import IconArrow from '../../../components/atoms/icons/IconArrow';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import TableExpiredContent from '../../../components/organism/TableExpiredContent';
import ExpiredContentReportLayout from '../../../components/templates/ExpiredContentReportLayout';

function ReporteAtrasos() {
  const isError = false;
  const isLoading = false;
  const user = useSelector((state: RootState) => state.auth.user);

  if (!user) return <ScreenLoader />;

  if (isLoading /* || !dataRoom */) return <ScreenLoader />;
  if (isError) {
    return (
      <>
        <Link
          className="room-detail-header-v2__button-back"
          iconLeft={<IconArrow className="room-detail-header-v2__icon-back" />}
          to="/dashboard/rooms"
        >
          Regresar a Salones
        </Link>

        <Alert
          severity="error"
          variant="outlined"
          action={
            <Button
              color="inherit"
              size="small" /* onClick={refetchDetailRoom} */
            >
              Reintentar
            </Button>
          }
        >
          <AlertTitle>Error</AlertTitle>
          No se pudo obtener la información del salón. Por favor reintente.
        </Alert>
      </>
    );
  }
  return (
    <ExpiredContentReportLayout table={<TableExpiredContent user={user} />} />
  );
}

export default ReporteAtrasos;
