import axios from 'axios';
import moment from 'moment';

import {
  createProjectSerializer,
  createOrganizationSerializer
} from './serializer/ctcbtb.serializer';
import { getAccessCookie } from '../helpers/cookies.helpers';
import { CreateProject } from '../interfaces/projects.interface';
import { CreateOrganization } from '../interfaces/organizations.interface';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL_B2B_MS;

const organizationTypeMapping: Record<string, string> = {
  grant: 'Grant',
  empresa: 'Empresa',
  gobierno: 'Gobierno',
  fundacion: 'Fundación',
  fundación: 'Fundación',
  'institucion educativa': 'Educativa',
  'institución educativa': 'Educativa'
};

const projectStatusMapping: Record<string, string> = {
  completado: 'completed',
  'por empezar': 'to_begin',
  'en progreso': 'in_progress'
};

export const postCreateOrganization = async (payload: CreateOrganization) => {
  try {
    const payloadSerializer = createOrganizationSerializer(payload);

    const token = getAccessCookie();

    const response = await axios.post(
      `${instanceBaseURL}/organization/`,
      payloadSerializer,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const updateOrganization = async (
  id: number,
  payload: CreateOrganization
) => {
  try {
    const payloadSerializer = createOrganizationSerializer(payload);

    const token = getAccessCookie();

    const response = await axios.put(
      `${instanceBaseURL}/organization/${id}`,
      payloadSerializer,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const postCreateProject = async (payload: CreateProject) => {
  try {
    const payloadSerializer = createProjectSerializer(payload);

    const token = getAccessCookie();

    const response = await axios.post(
      `${instanceBaseURL}/project/`,
      payloadSerializer,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const updateProject = async (id: number, payload: CreateProject) => {
  try {
    const payloadSerializer = createProjectSerializer(payload);

    const token = getAccessCookie();

    const response = await axios.put(
      `${instanceBaseURL}/project/${id}`,
      payloadSerializer,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getOrganization = async (
  page: number,
  size: number,
  filters?: any
) => {
  try {
    const token = getAccessCookie();
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('size', size.toString());

    if (filters?.id !== undefined && filters?.id !== '') {
      params.append('id', filters.id.toString());
    }

    if (filters?.name !== undefined && filters?.name !== '') {
      params.append('name', filters.name.toString());
    }

    if (
      filters?.organization_type !== undefined &&
      filters?.organization_type !== ''
    ) {
      const value =
        organizationTypeMapping[filters.organization_type.toLowerCase()] || '';
      if (value !== '') {
        params.append('organization_type', value.toString());
      }
    }

    if (filters?.country_id !== undefined && filters?.country_id !== '') {
      params.append('country', filters.country_id);
    }

    if (filters?.business_name !== undefined && filters?.business_name !== '') {
      params.append('business_name', filters.business_name.toString());
    }

    if (
      filters?.business_owner !== undefined &&
      filters?.business_owner !== ''
    ) {
      params.append('business_owner', filters.business_owner.toString());
    }

    return await axios.get(
      `${instanceBaseURL}/organization/?${params.toString()}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getProject = async (page: number, size: number, filters?: any) => {
  try {
    const token = getAccessCookie();
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('size', size.toString());

    if (filters?.id !== undefined && filters?.id !== '') {
      params.append('id', filters.id.toString());
    }

    if (filters?.name !== undefined && filters?.name !== '') {
      params.append('project_name', filters.name.toString());
    }

    if (
      filters?.organization_name !== undefined &&
      filters?.organization_name !== ''
    ) {
      params.append('organization_name', filters.organization_name.toString());
    }

    if (
      filters?.operative_start_date !== undefined &&
      filters?.operative_start_date !== ''
    ) {
      const startDate = moment(filters.operative_start_date, 'DD-MM-YY').format(
        'YYYY-MM-DD'
      );
      params.append('operative_start_date', startDate.toString());
    }

    if (
      filters?.operative_end_date !== undefined &&
      filters?.operative_end_date !== ''
    ) {
      const endDate = moment(filters.operative_end_date, 'DD-MM-YY').format(
        'YYYY-MM-DD'
      );
      params.append('operative_end_date', endDate.toString());
    }

    if (filters?.status !== undefined && filters?.status !== '') {
      const value = projectStatusMapping[filters.status.toLowerCase()] || '';
      if (value !== '') {
        params.append('status', value.toString());
      }
    }

    if (
      filters?.academic_owner !== undefined &&
      filters?.academic_owner !== ''
    ) {
      params.append('academic_owner', filters.academic_owner.toString());
    }

    if (
      filters?.operative_owner !== undefined &&
      filters?.operative_owner !== ''
    ) {
      params.append('operative_owner', filters.operative_owner.toString());
    }

    return await axios.get(`${instanceBaseURL}/project/?${params.toString()}`, {headers: { Authorization: `Bearer ${token}` }});
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getProjectById = async (id: number) => {
  try {
    const token = getAccessCookie();
    return await axios.get(`${instanceBaseURL}/project/${id}`, {headers: { Authorization: `Bearer ${token}` }});
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getOrganizationById = async (id: number) => {
  try {
    const token = getAccessCookie();
    return await axios.get(`${instanceBaseURL}/organization/${id}`, {headers: { Authorization: `Bearer ${token}` }});
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};
