// React
import { useEffect, useState } from 'react';

// React Query
import toast from 'react-hot-toast';

import {
  patchLearningBoxByCourseId,
  postLearningBoxByCourseId
} from '../../../services/courses';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import Switch from '../../molecules/Switch';
import Textarea from '../../molecules/Textarea/Textarea';

import './courseLearningDetails.scss';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';

interface Props {
  data: any;
  dataLearning: any;
  courseId: number;
  refetch: any;
  refetchCourse: any;
}

const CourseLearningDetails: React.FC<Props> = ({
  data,
  dataLearning,
  courseId,
  refetch,
  refetchCourse
}) => {
  const [isActive, setIsActive] = useState(false);
  const [learningSession, setLearningSession] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeSwitchMail = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (dataLearning?.box_sessions.length > 0) {
      setIsActive(dataLearning?.has_email);

      dataLearning?.box_sessions.map((row: any, indx: number) =>
        dataLearning?.box_sessions.splice(indx, 1, {
          ...row,
          course: courseId
        })
      );

      setLearningSession(dataLearning?.box_sessions);
    }
  }, [dataLearning]);

  const handleChangeLearningBox = async (
    index: number,
    box: any,
    value: string
  ) => {
    const learningSessionAux = JSON.parse(JSON.stringify(box));

    learningSessionAux[index] = {
      id: learningSessionAux[index]?.id,
      sequence: learningSessionAux[index]?.sequence,
      course: courseId,
      mail_description: value
    };
    setLearningSession(learningSessionAux);
  };

  const handlePostLearningBox = (response: any, learningSession: any) => {
    const learningPostSessionAux = JSON.parse(
      JSON.stringify(response.box_sessions)
    );

    learningPostSessionAux.forEach((row: any, indx: number) => {
      row.mail_description = learningSession[indx]?.mail_description || '';
    });

    return learningPostSessionAux;
  };

  const handleSave = async () => {
    try {
      if (dataLearning.box_sessions.length > 0) {
        await patchLearningBoxByCourseId(
          {
            has_email: isActive,
            box_sessions: learningSession
          },
          courseId
        );
      } else {
        const response = await postLearningBoxByCourseId({
          number_sessions: data?.number_of_sessions,
          course_id: courseId
        });
        if (response.status === 200) {
          const newLearningSession = handlePostLearningBox(
            response.data,
            learningSession
          );
          await patchLearningBoxByCourseId(
            {
              has_email: isActive,
              box_sessions: newLearningSession
            },
            courseId
          );
        }
      }
      refetch();
      toast.success('Se guardo correctamente los aprendizajes');
    } catch (err) {
      toast.error('No se pudo guardar los aprendizajes');
    }
  };

  if (isLoading || !dataLearning) return <ScreenLoader />;

  return (
    <>
      <main className="course-learning">
        <div className="course-learning__title">
          <Typography type="paragraph2" variation="mobile" bold>
            Añade detalles de aprendizaje por sesión:
          </Typography>
        </div>
        <div className="course-learning__content">
          <div className="course-learning__switch">
            <Switch
              isActive={isActive}
              setIsActive={setIsActive}
              textRight={
                isActive
                  ? 'Habilitar envío de correos con reporte por sesión'
                  : 'Habilitar envío de correos con reporte por sesión'
              }
              name="mail"
              //disabled={true}
              onClick={() => handleChangeSwitchMail()}
            ></Switch>
          </div>
          <div className="course-learning__textareas">
            {[...Array(Number(data?.number_of_sessions) || 0)].map(
              (_, index) => (
                <Textarea
                  label={`Sesión ${index + 1}`}
                  placeholder="Inserta detalles del aprendizaje de esta sesión..."
                  onChange={(e: any) =>
                    handleChangeLearningBox(
                      index,
                      learningSession,
                      e.target.value
                    )
                  }
                  defaultValue={
                    dataLearning.box_sessions.length > 0
                      ? dataLearning.box_sessions[index].mail_description
                      : ''
                  }
                ></Textarea>
              )
            )}
          </div>
          <div className="course-learning__button">
            <ButtonFilled size="default" onClick={() => handleSave()}>
              Guardar
            </ButtonFilled>
          </div>
        </div>
      </main>
    </>
  );
};

export default CourseLearningDetails;
