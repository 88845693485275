import React from 'react';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
}

const IconHome: React.FC<Props> = ({
  className,
  fill = '#555555',
  dataTestid = 'icon-home'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2 11.1557C17.0895 11.1557 17 11.2452 17 11.3557V18.1408C17 18.2512 16.9105 18.3408 16.8 18.3408H13.7C13.5895 18.3408 13.5 18.2512 13.5 18.1408V13.311C13.5 12.8235 13.1051 12.4286 12.6176 12.4286H9.88241C9.39488 12.4286 9 12.8235 9 13.311C9 13.737 9 17.2591 9 18.1411C9 18.2516 8.91046 18.3408 8.8 18.3408H5.7C5.58954 18.3408 5.5 18.2512 5.5 18.1408V11.3557C5.5 11.2452 5.41046 11.1557 5.3 11.1557H3.48312C3.3049 11.1557 3.21568 10.9402 3.34176 10.8142L11.0209 3.14131C11.099 3.06326 11.2256 3.06328 11.3037 3.14136L11.6719 3.50959L18.9808 10.8142C19.1068 10.9402 19.0176 11.1557 18.8394 11.1557H17.2Z"
        stroke={fill}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconHome;
