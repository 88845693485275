import axios from 'axios';

import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL_B2B_MS;

export const postCreateLanding = async (payload: any) => {
  try {
    const token = getAccessCookie();
    return await axios.post(
      `${instanceBaseURL}/alliance/?name=${payload.name}&title=${payload.title}&subtitle=${payload.subtitle}&color=${payload.color}&image=${payload.image}`,
      payload?.file,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    console.error('e', e);
    throw new Error(`Error: ${e}`);
  }
};

export const updateLanding = async (id: number, payload: any) => {
  try {
    const token = getAccessCookie();

    return await axios.put(
      `${instanceBaseURL}/alliance/${id}?name=${payload.name}&title=${payload.title}&subtitle=${payload.subtitle}&color=${payload.color}&image=${payload.image}`,
      payload?.file,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    console.error('e', e);
    throw new Error(`Error: ${e}`);
  }
};

export const getLandings = async (page: number, size: number) => {
  try {
    const token = getAccessCookie();

    return await axios.get(
      `${instanceBaseURL}/alliance/?page=${page}&size=${size}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    console.error('e', e);
    throw new Error(`Error: ${e}`);
  }
};

export const getLandingById = async (id: number) => {
  try {
    const token = getAccessCookie();
    return await axios.get(`${instanceBaseURL}/alliance/${id}`, {headers: { Authorization: `Bearer ${token}` }});
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};
