import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

import './groupRoomsHeader.scss';
import Link from '../../../../components/molecules/Link';
import Tooltip from '../../../../components/atoms/Tooltip/Tooltip';
import IconArrow from '../../../../components/atoms/icons/IconArrow';
import { IGroupRooms } from '../../../../interfaces/group.interface';
import IconShowText from '../../../../components/atoms/icons/IconShowText';
import Typography from '../../../../components/atoms/Typography/Typography';

interface Props {
  rooms: IGroupRooms;
}

export const GroupRoomsHeader: React.FC<Props> = ({ rooms }) => {
  const navigate = useNavigate();

  const daysOfWeek = [
    { key: 'monday', day: 'lunes' },
    { key: 'tuesday', day: 'martes' },
    { key: 'wednesday', day: 'miercoles' },
    { key: 'thursday', day: 'jueves' },
    { key: 'friday', day: 'viernes' },
    { key: 'saturday', day: 'sabado' }
  ];

  const formatSchedule = (
    day: string,
    time: string,
    duration: string
  ): string | null => {
    // @ts-expect-error Error type
    if (rooms[time] && rooms[duration]) {
      // @ts-expect-error Error type
      const roomTime = new Date(`2002/08/01 ${rooms[time]}`);
      const startTime = moment(roomTime).format('HH:mm A');
      const endTime = moment(roomTime)
        // @ts-expect-error Error type
        .add(rooms[duration], 'minutes')
        .format('HH:mm A');

      return `Cada ${day}, de ${startTime} a ${endTime}`;
    }

    return null;
  };

  const EllipsisParagraph = ({
    description,
    ellipsis = false
  }: {
    description: string | null;
    ellipsis?: boolean;
  }) => {
    if (!description) return null;
    return (
      <>
        {ellipsis ? (
          <Tooltip
            left="1.5rem"
            title={description}
            bg="#595C5C"
            className="group-rooms-header__tooltip"
          >
            <Typography
              type="paragraph2"
              variation="mobile"
              className="group-rooms-header__text-ellipsis"
            >
              {description}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            type="paragraph2"
            variation="mobile"
            className="group-rooms-header__text-ellipsis"
          >
            {description}
          </Typography>
        )}
      </>
    );
  };

  const GridItem = ({
    description,
    information,
    buttonInfo,
    product,
    onClick,
    ellipsis = false
  }: {
    description: string;
    information: (null | string)[] | string;
    buttonInfo?: boolean;
    product?: string;
    onClick?: () => void;
    ellipsis?: boolean;
  }) => {
    return (
      <div className="group-rooms-header__item-detail">
        <Typography type="paragraph2" variation="mobile" bold>
          {description}:
        </Typography>
        <div
          className={`group-rooms-header__width-${
            buttonInfo ? 'w-cta' : 'no-cta'
          } group-rooms-header__description-item`}
        >
          {typeof information === 'string' ? (
            <EllipsisParagraph description={information} ellipsis={ellipsis} />
          ) : (
            <div className="group-rooms-header__description-item-list">
              {information.map((info: string | null) => (
                <EllipsisParagraph description={info} ellipsis={ellipsis} />
              ))}
            </div>
          )}
          {buttonInfo ? (
            <Tooltip
              title={`Ir a editar ${product}`}
              left="1.5rem"
              bg="#595C5C"
            >
              <IconButton
                aria-label="more-info"
                size="small"
                color="primary"
                onClick={onClick}
              >
                <IconShowText />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <section className="group-rooms-header__container">
      <Link
        className="group-rooms-header__button-back"
        children="Regresar a Grupos"
        to="/dashboard/rooms"
        iconLeft={<IconArrow className="group-rooms-header__icon-back" />}
      />
      <Typography
        className="group-rooms-header__title"
        type="h2"
        variation="mobile"
        bold
      >
        {rooms.name}
      </Typography>

      <section className="group-rooms-header__container-details">
        <div className="group-rooms-header__details">
          <GridItem description="Nombre" information={rooms.name} ellipsis />
          <GridItem
            description="Fecha de inicio"
            information={moment(rooms.start_date).format('YYYY MMMM D')}
          />
          <GridItem
            description="Horario"
            information={daysOfWeek.map(item =>
              formatSchedule(
                item.day,
                `time_${item.key}`,
                `duration_${item.key}`
              )
            )}
          />
          <GridItem
            description="Fin de clases"
            information={moment(rooms.end_date).format('YYYY MMMM D')}
          />
          <GridItem
            ellipsis
            description="Curso"
            information={rooms.course.name}
            buttonInfo={true}
            product="curso"
            onClick={() => navigate(`/dashboard/courses?id=${rooms.course.id}`)}
          />
          {rooms.course.first_learning_path && (
            <GridItem
              description="Ruta"
              information={rooms.course.name_of_first_learning_path}
              buttonInfo={true}
              product="ruta"
              onClick={() => {
                navigate(
                  `/dashboard/learning-paths?id=${rooms.course.first_learning_path}`
                );
              }}
            />
          )}
        </div>
      </section>
    </section>
  );
};
