// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';
import { IGroupPag, IGroupDetail } from '../interfaces/group.interface';

export const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

const statusMapping: Record<string, string> = {
  privado: 'false',
  publico: 'true',
  público: 'true'
};

const individualCourses = 'Cursos Individuales';

//? GROUPS
export const getGroups = async (
  page: number,
  size: number,
  id?: string,
  name?: string,
  program?: string,
  course?: string,
  project?: string,
  state?: string,
  business?: string
) => {
  const params = new URLSearchParams();

  params.append('page', page.toString());
  params.append('size', size.toString());

  if (id !== undefined && id !== '') {
    params.append('groupId', id.toString());
  }

  if (name !== undefined && name !== '') {
    params.append('search', name.toString());
  }

  if (program !== undefined && program !== '') {
    if (program.toLowerCase().includes(individualCourses.toLowerCase())) {
      params.append('learningPath', individualCourses.toString());
    } else {
      params.append('learningPath', program.toString());
    }
  }

  if (course !== undefined && course !== '') {
    params.append('course', course.toString());
  }

  if (project !== undefined && project !== '') {
    params.append('projectId', project.toString());
  }

  if (state !== undefined && state !== '') {
    const value = statusMapping[state.toLowerCase()] || '';
    if (value !== '') {
      params.append('status', value.toString());
    }
  }

  if (business !== undefined && business !== '') {
    params.append('business', business.toUpperCase().toString());
  }

  const response = await axios.get<IGroupPag>(
    `${instanceBaseURL}/backoffice/v2/groups?${params.toString()}`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getGroupById = async (groupId: any) => {
  const response = await axios.get<IGroupDetail>(
    `${instanceBaseURL}/groups/${groupId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postCreateGroup = async (newGroup: IGroupDetail) => {
  const response = await axios.post(
    `${instanceBaseURL}/backoffice/v2/create/group`,
    newGroup,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchEditGroup = async (groupId: any, group: IGroupDetail) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/groups/${groupId}/`,
    group,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getCetificateLinks = async (groupId: any) => {
  const data = await axios.get(
    `${instanceBaseURL}/group_certificates/${groupId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postCreateLinksForCetificate = async (links: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/group_certificates/`,
    links,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const deleteGroupById = async (groupId: number) => {
  const response = await axios.delete(
    `${instanceBaseURL}/backoffice/delete/group/${groupId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchLinksForCetificate = async (groupId: any, links: any) => {
  const data = await axios.patch(
    `${instanceBaseURL}/group_certificates/${groupId}/`,
    links,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postSendCetificate = async (params: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/group/send_certificates/`,
    params,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postDriveCetificates = async (params: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/group/drive/upload/certificates/`,
    params,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getComplementStudentsByGroupId = async (groupId: number) => {
  const data = await axios.get(
    `${instanceBaseURL}/groups/${groupId}/complement-students/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};
