import { Cell } from 'react-table';

export const getCellStyle = ({ cell }: { cell: Cell }) => {
  const style = {
    width: cell.column.widthColumn ? cell.column.widthColumn : 'auto',
    minWidth: cell.column.minWidthColumn ? cell.column.minWidthColumn : '',
    maxWidth:
      cell.column.id === 'action'
        ? `${cell.column.actionWidth}px !important`
        : ''
  };

  if (cell.column.id === 'action') {
    style.width = `${cell.column.actionWidth}px !important`;
  }

  return style;
};
