// Hot Toast
import { toast } from 'react-hot-toast';

// Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import { AxiosError } from 'axios';

// Interfaces
import {
  IGroupDetail,
  IGroupDetailPag
} from '../../interfaces/group.interface';

// Services
import {
  getGroups,
  patchEditGroup,
  postCreateGroup
} from '../../services/groups';
import {
  postAddStudentToGroup,
  patchUpdateStudentProjectId
} from '../../services/students';

export interface IAuthError {
  msg: string;
}

export interface IGroupState {
  groups: IGroupDetailPag[];
  isLoading: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
  totalPages: number;
  count: number;
}

const initialState: IGroupState = {
  groups: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  count: 0
};

export const getAllGroups = createAsyncThunk(
  'groups/getAllGroups',
  async (
    {
      currentPage,
      size,
      id,
      name,
      program,
      course,
      project,
      state,
      business
    }: {
      currentPage: number;
      size: number;
      id?: string;
      name?: string;
      program?: string;
      course?: string;
      project?: string;
      state?: string;
      business?: string;
    },
    { getState, rejectWithValue }: any
  ) => {
    try {
      const response = await getGroups(
        currentPage,
        size,
        id,
        name,
        program,
        course,
        project,
        state,
        business
      );
      return response.data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addGroup = createAsyncThunk(
  'groups/addGroup',
  async (
    newGroup: IGroupDetail,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await postCreateGroup(newGroup);
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateGroup = createAsyncThunk(
  'groups/updateGroup',
  async (
    { groupId, group }: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const data = await patchEditGroup(groupId, group);
      if (data) {
        toast.success('Grupo editado correctamente!');
      } else {
        toast.error('Ha ocurrido un error al editar el grupo.');
      }
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addStudentToGroup = createAsyncThunk(
  'groups/addStudentToGroup',
  async (
    { groupId, selectedStudentId, projectId }: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await postAddStudentToGroup(
        groupId,
        selectedStudentId,
        projectId
      );
      if (response) {
        toast.success('Estudiante agregado al grupo!');
      } else {
        toast.error('Ha ocurrido un error al agregar al estudiante al grupo.');
      }
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      toast.error('Ha ocurrido un error al agregar al estudiante al grupo.');
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateStudentProjectId = createAsyncThunk(
  'groups/updateStudentProjectId',
  async (
    { groupId, studentId, projectId }: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await patchUpdateStudentProjectId(
        groupId,
        studentId,
        projectId
      );
      if (response) {
        toast.success('Project ID agregado!');
      } else {
        toast.error('Ha ocurrido un error al agregar el project ID.');
      }
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      toast.error('Ha ocurrido un error al agregar el project ID.');
      return rejectWithValue(error.response?.data);
    }
  }
);

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getAllGroups */
    builder.addCase(getAllGroups.pending, (state, _) => {
      state.groups = initialState.groups;
      state.isLoading = true;
    });
    builder.addCase(getAllGroups.fulfilled, (state, action) => {
      state.groups = action.payload.results;
      state.totalPages = action.payload.total_pages;
      state.count = action.payload.count;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getAllGroups.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    /** addGroup */
    builder.addCase(addGroup.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addGroup.fulfilled, (state, action) => {
      state?.groups?.push(action.payload);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addGroup.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
    builder.addCase(updateGroup.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      const payload = action.payload;
      const groups = state.groups.filter(group => group.id !== payload.id);
      state.groups = [
        ...groups,
        { ...action.payload, course: action.payload.course.id }
      ];
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateGroup.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });

    /** addStudentToGroup */
    builder.addCase(addStudentToGroup.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addStudentToGroup.fulfilled, (state, _) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(addStudentToGroup.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });

    /** updateStudentProjectId */
    builder.addCase(updateStudentProjectId.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updateStudentProjectId.fulfilled, (state, _) => {
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateStudentProjectId.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateGroups = (state: any) => state.groups;
