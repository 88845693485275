// React
import React, { useState } from 'react';

// React Query
import {
  useMutation,
  QueryObserver,
  useQuery,
  useQueryClient
} from 'react-query';

// API

// Material UI
import {
 Alert, Chip, Divider, Grid, LinearProgress 
} from '@mui/material';

// Formik

// Component
import { number } from 'yup/lib/locale';

import CreateRule from '../../../../../components/Rules/CreateRule/CreateRule';
import ListRule from '../../../../../components/Rules/ListRule';
import CouponAll from '../../../../../components/CouponAll/CouponAll';
import CustomDatePicker from '../../../../../components/CustomDatePicker/CustomDatePicker';
import CustomCheck from '../../../../../components/CustomCheck/CustomCheck';

// Interfaces
import { ICouponProgram } from '../../../../../interfaces/coupon.interface';
import { IRule } from '../../../../../interfaces/coupon.interface';
import { ICourse } from '../../../../../interfaces/course.interface';
import { ILearningPath } from '../../../../../interfaces/learningPaths.interface';
import {
  GET,
  PATCH_MICRO,
  POST_MICRO,
  DELETE_MICRO
} from '../../../../../services/api/api';

interface Props {
  rules?: IRule[];
  couponProgramId: number;
  date_limit?: Date;
  description?: string;
  name?: string;
  all_path?: boolean;
  all_course?: boolean;
  check_test_month?: boolean;
  mutation: any;
  refetch: () => {};
}

const Rules: React.FC<Props> = ({
  rules,
  name = '',
  description = '',
  all_path = false,
  all_course = false,
  check_test_month = false,
  date_limit,
  couponProgramId,
  mutation,
  refetch
}) => {
  const [value, setValue] = useState(new Date());

  const createDiscount = useMutation(
    (data: any) => POST_MICRO(`/discount/`, data),
    { retry: false }
  );

  const deleteDiscount = useMutation(
    (dataId: number) => DELETE_MICRO(`/discount/delete/${dataId}`),
    { retry: false }
  );

  const patchProgramDate = useMutation(
    (data: any) => PATCH_MICRO(`/program/date/${data.id}`, data),
    { retry: false }
  );

  const patchProgramTestMonth = useMutation(
    (data: any) => PATCH_MICRO(`/program/test_month/${data.id}`, data),
    { retry: false }
  );

  const couponProgram: ICouponProgram = {
    name: name,
    description: description,
    all_course: all_course,
    all_path: all_path
  };

  const queryClient = useQueryClient();
  const { refetch: refetchCouponProgram } = new QueryObserver(queryClient, {queryKey: 'coupon-program'});

  const {
    data: courses,
    error: errorCourses,
    isError: isErrorCourses,
    isLoading: isLoadingCourses
  } = useQuery(
    ['courses-coupon'],
    () => GET<ICourse[]>(`/backoffice/v2/courses?sync_with_lms=true`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const {
    data: learningPaths,
    error: errorLearningPaths,
    isError: isErrorLearningPaths,
    isLoading: isLoadingLearningPaths
  } = useQuery(
    ['learning-paths-coupon'],
    () => GET<ILearningPath[]>(`/learning_paths_backoffice/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  if (isLoadingLearningPaths || isLoadingCourses || !learningPaths || !courses)
    return <LinearProgress />;
  if (isErrorLearningPaths)
    return <div>Error! {JSON.stringify(errorLearningPaths)}</div>;
  if (isErrorCourses) return <div>Error! {JSON.stringify(errorCourses)}</div>;

  const initialDateValidate = {
    id: number,
    dateValidate: new Date(),
    active: false
  };

  const handleChangeTypeAll = async (
    typeAll: 'all_path' | 'all_course',
    value: boolean
  ) => {
    await mutation.mutateAsync({
      ...couponProgram,
      [typeAll]: value
    });
    refetchCouponProgram();
  };

  return (
    <Grid item container columnSpacing={2} xs={12}>
      <Grid item container rowSpacing={1.5} xs={6}>
        <Grid item xs={12}>
          <Divider textAlign="left">
            <Chip label="Validity" />
          </Divider>
        </Grid>
        <CustomDatePicker
          idParent={couponProgramId}
          nameCustom="Validity Duration"
          dateLimit={date_limit}
          submitAction={patchProgramDate}
          refetch={refetchCouponProgram}
        ></CustomDatePicker>
      </Grid>
      <Grid item container rowSpacing={1.5} xs={6}>
        <Grid item xs={12}>
          <Divider textAlign="left">
            <Chip label="Apply On" />
          </Divider>
        </Grid>
        <CustomCheck
          idParent={couponProgramId}
          options={[{ label: 'TestMonth', value: check_test_month }]}
          submitAction={patchProgramTestMonth}
          refetch={refetchCouponProgram}
        ></CustomCheck>
      </Grid>
      <Grid item container rowSpacing={1.5} xs={6}>
        <Grid item xs={12}>
          <Divider textAlign="left">
            <Chip label="Courses" />
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <CouponAll
            valueAll={all_course}
            typeAll="all_course"
            handleChangeTypeAll={handleChangeTypeAll}
          />
        </Grid>
        {!all_course ? (
          <>
            <Grid item xs={12}>
              <CreateRule
                srcData={courses}
                programId={couponProgramId}
                viewType="course"
                refetch={refetch}
              />
            </Grid>
            <Grid item xs={12}>
              <ListRule
                rules={rules}
                srcData={courses}
                viewType="course"
                refetch={refetch}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Alert variant="standard" severity="success">
              Aplicado a todos los cursos
            </Alert>
          </Grid>
        )}
      </Grid>
      <Grid item container rowSpacing={1.5} xs={6}>
        <Grid item xs={12}>
          <Divider textAlign="left">
            <Chip label="Learning Paths" />
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <CouponAll
            valueAll={all_path}
            typeAll="all_path"
            handleChangeTypeAll={handleChangeTypeAll}
          />
        </Grid>
        {!all_path ? (
          <>
            <Grid item xs={12}>
              <CreateRule
                srcData={learningPaths}
                programId={couponProgramId}
                viewType="learning_path"
                refetch={refetch}
              />
            </Grid>
            <Grid item xs={12}>
              <ListRule
                rules={rules}
                srcData={learningPaths}
                viewType="learning_path"
                refetch={refetch}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Alert variant="standard" severity="success">
              Aplicado a todas las rutas
            </Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Rules;
