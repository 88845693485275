import React, { useEffect, useRef, useState } from 'react';

import './selectComp.scss';
import ItemFloatingMenu from '../ItemFloatingMenu';
import Typography from '../../atoms/Typography/Typography';
import SelectOptions from '../SelectOptions/SelectOptions';
import IconArrowSelect from '../../atoms/icons/IconArrowSelect';

type ObjectNumberString = {
  [clave: string]: number | string;
};

interface Props {
  value: ObjectNumberString;
  size?: any;
  isOpen?: any;
  width?: string;
  children?: any;
  disabled?: any;
  sort?: boolean;
  setIsOpen?: any;
  options?: any[];
  className?: string;
  placeholder: string;
  identifier?: string;
  itemsDropdown?: number;
  dividerItems?: boolean;
  onclickDefaultValue?: Function;
  defaultValue?: ObjectNumberString;
  firstItemMenu?: any;
}

const SelectComp: React.FC<Props> = ({
  children,
  isOpen = false,
  setIsOpen,
  size = 'default',
  className = '',
  disabled,
  options,
  value,
  width = 'auto',
  onclickDefaultValue,
  dividerItems = false,
  identifier = 'name',
  itemsDropdown = 8,
  placeholder = 'Seleccionar',
  sort = false,
  firstItemMenu
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isOpenSelect, setIsOpenSelect] = useState(isOpen);
  const [itemSelected, setItemSelected] = useState<ObjectNumberString>(value);
  const dataToSort = Array.isArray(options) ? [...options] : options;
  const dataSorted = sort
    ? dataToSort
        ?.sort((a: any, b: any) => (a?.[identifier] > b?.[identifier] ? 1 : -1))
        .map((option: any) => option)
    : dataToSort?.map((option: any) => option);

  useEffect(() => {
    setItemSelected(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpenSelect(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onClickHandler = (option: any): void => {
    if (option) {
      onclickDefaultValue && onclickDefaultValue(option);
      setIsOpenSelect(!isOpenSelect);
      setItemSelected(option);
    }
  };

  return (
    <>
      <div
        style={{ width: width }}
        className={`select-comp ${disabled ? 'select-comp__disabled' : ''} ${className}`}
        onClick={disabled ? () => {} : () => setIsOpenSelect(!isOpenSelect)}
      >
        <div className={`select-comp__text select-comp__text--${size}`}>
          <Typography
            type="paragraph2"
            variation="mobile"
            className={`select-comp__text--ellipsis select-comp__text--${
              disabled
                ? 'text-disabled'
                : !itemSelected?.[identifier] && placeholder
                  ? 'text-gris'
                  : 'text-black'
            }`}
          >
            {itemSelected?.[identifier] || placeholder}
          </Typography>
        </div>
        {disabled ? (
          <div
            className={`select-comp__icon ${
              isOpenSelect ? 'select--active' : 'select--inactive'
            } select-comp__icon--${size} ${
              disabled ? 'select-comp__icon--disabled' : ''
            }`}
          >
            <IconArrowSelect fill="#BCBCBC" />
          </div>
        ) : (
          <div
            /* onClick={() => setIsOpenSelect(!isOpen)} */
            className={`select-comp__icon ${
              isOpenSelect ? 'select--active' : 'select--inactive'
            } select-comp__icon--${size}`}
          >
            <IconArrowSelect
              className={`select-comp__icon-${isOpenSelect ? 'up' : 'down'}`}
            />
          </div>
        )}
        {isOpenSelect && (
          <div ref={ref} className="select-comp__dropdown">
            <SelectOptions
              isScroll={
                dataSorted && dataSorted.length > itemsDropdown ? true : false
              }
              size={size}
              itemsDropdown={itemsDropdown}
              options={
                firstItemMenu && firstItemMenu.id === 0
                  ? [
                      <ItemFloatingMenu
                        dividerItems={dividerItems}
                        key={firstItemMenu?.id}
                        disabled={firstItemMenu?.isDisabled}
                        onClick={() => onClickHandler(firstItemMenu)}
                      >
                        {firstItemMenu?.[identifier]}
                      </ItemFloatingMenu>,
                      dataSorted?.map((option: any) => (
                        <ItemFloatingMenu
                          dividerItems={dividerItems}
                          key={option?.id}
                          disabled={option?.isDisabled}
                          onClick={() => onClickHandler(option)}
                        >
                          {option?.[identifier]}
                        </ItemFloatingMenu>
                      ))
                    ]
                  : [
                      dataSorted?.map((option: any) => (
                        <ItemFloatingMenu
                          dividerItems={dividerItems}
                          key={option?.id}
                          disabled={option?.isDisabled}
                          onClick={() => onClickHandler(option)}
                        >
                          {option?.[identifier]}
                        </ItemFloatingMenu>
                      ))
                    ]
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectComp;
