/* eslint-disable complexity */
// React
import { useState } from 'react';

// Hot Toast

// Styles

// Material UI
import { IconButton, Tooltip } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Icon } from 'ctc-component-library';

import { Mailto, Text } from './styles';
import { AlertSnackbar } from '../../common/AlertSnackbar/AlertSnackbar';
import Typography from '../../atoms/Typography/Typography';

interface Props {
  title?: string;
  copyable?: boolean;
  mailable?: {
    email: string;
  };
  hideable?: boolean;
  hideText?: boolean;
  disabled?: boolean;
  direction?: 'left' | 'right';
  copyableText?: string;
  text: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

function Paragraph({
  title = 'Copiar',
  copyable = false,
  mailable,
  hideable = false,
  hideText,
  direction,
  copyableText = '',
  text,
  placement = 'right',
  disabled = false
}: Props) {
  const [copied, setCopied] = useState(false);
  const [hided, setHided] = useState(true);

  const handleCopyable = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);

    setTimeout(() => setCopied(false), 1200);
  };
  const handleHideable = () => setHided(!hided);

  return (
    <>
      {copied && (
        <AlertSnackbar severity="success">
          <Typography type="paragraph2" variation="mobile">
            {copyableText ? copyableText.slice(0, 32) : text.slice(0, 32)}{' '}
            Copiado!
          </Typography>
        </AlertSnackbar>
      )}
      {/* Copyable */}
      {copyable &&
        (direction === 'left' ||
          (direction === undefined && (
            <Tooltip
              title={`${copied ? 'Copiado' : 'Copiar'}`}
              arrow
              placement="left"
            >
              <IconButton
                onClick={
                  copyableText
                    ? () => handleCopyable(copyableText)
                    : () => handleCopyable(text)
                }
                component="span"
                size="small"
                color="primary"
              >
                {copied ? <CheckIcon /> : <ContentCopyIcon />}
              </IconButton>
            </Tooltip>
          )))}

      {/* Mailable */}
      {mailable && (
        <Mailto href={`mailto:${mailable.email}`}>
          <Tooltip title="Mailto" arrow placement="top">
            <IconButton component="span" size="small" color="primary">
              <EmailIcon />
            </IconButton>
          </Tooltip>
        </Mailto>
      )}

      {/* Hideable */}
      {hideable && direction !== 'right' && (
        <Tooltip
          title={`${hided ? 'Mostrar' : 'Ocultar'}`}
          arrow
          placement="top"
        >
          <IconButton
            onClick={() => handleHideable()}
            component="span"
            size="small"
            color="primary"
          >
            {hided ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      )}

      {/* Text */}
      {hideable ? (
        <Text>{hided ? '**********' : text}</Text>
      ) : !hideText ? (
        <Text>{text}</Text>
      ) : null}

      {hideable && direction === 'right' && (
        <Tooltip
          title={`${hided ? 'Mostrar' : 'Ocultar'}`}
          arrow
          placement="top"
        >
          <IconButton
            onClick={() => handleHideable()}
            component="span"
            size="small"
            sx={{ color: '#303333' }}
          >
            {hided ? (
              <Icon
                iconName="visibility_off"
                customClassName="icon-visibility"
              />
            ) : (
              <Icon iconName="visibility" customClassName="icon-visibility" />
            )}
          </IconButton>
        </Tooltip>
      )}

      {copyable && direction === 'right' && (
        <Tooltip
          title={`${copied ? 'Copiado' : title}`}
          arrow
          placement={placement}
        >
          <IconButton
            disabled={disabled}
            onClick={() => handleCopyable(text)}
            component="span"
            size="small"
            sx={{ '& svg': { fill: 'red !important' }, color: '#303333' }}
          >
            {copied ? (
              // <IconCopyFilled fill={disabled ? '#BCBCBC' : 'black'} />
              <Icon
                iconName="content_copy"
                customClassName="icon-content_copy"
              />
            ) : (
              // <IconCopyFilled fill={disabled ? '#BCBCBC' : 'black'} />
              <Icon
                iconName="content_copy"
                customClassName="icon-content_copy"
              />
            )}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default Paragraph;
