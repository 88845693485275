// Material UI
import { Chip, Divider, Grid } from '@mui/material';

// Component
import CreateRecommendation from '../../../../../components/Recommendations/CreateRecommendation/CreateRecommendation';
import ListRecommendation from '../../../../../components/Recommendations/ListRecommendation/ListRecommendation';

interface Props {
  learningPathId: number;
  originType: 'learning_path';
  refetch: () => {};
}

const Recommendations: React.FC<Props> = ({
  learningPathId,
  originType,
  refetch
}) => {
  return (
    <Grid item container columnSpacing={2} xs={12}>
      {/* Courses */}
      <Grid item container rowSpacing={1.5} xs={6}>
        {/* Header */}
        <Grid item xs={12}>
          <Divider textAlign="left">
            <Chip label="Courses" />
          </Divider>
        </Grid>

        {/* List */}
        <Grid item xs={12}>
          <ListRecommendation
            originId={learningPathId}
            originType={originType}
            recommendedType="course"
            refetch={refetch}
          />
        </Grid>

        {/* Create */}
        <Grid item xs={12}>
          <CreateRecommendation
            originId={learningPathId}
            originType={originType}
            recommendedType="course"
            refetch={refetch}
          />
        </Grid>
      </Grid>

      {/* Learning Paths */}
      <Grid item container rowSpacing={1.5} xs={6}>
        {/* Header */}
        <Grid item xs={12}>
          <Divider textAlign="left">
            <Chip label="Learning Paths" />
          </Divider>
        </Grid>

        {/* List */}
        <Grid item xs={12}>
          <ListRecommendation
            originId={learningPathId}
            originType={originType}
            recommendedType="learning_path"
            refetch={refetch}
          />
        </Grid>

        {/* Create */}
        <Grid item xs={12}>
          <CreateRecommendation
            originId={learningPathId}
            originType={originType}
            recommendedType="learning_path"
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Recommendations;
