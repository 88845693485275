const colors = {
  white: '#ffffff',
  primary: '#9c56e0',
  gris01: '#f6f6f6',
  gris02: '#e5e5e5',
  gris03: '#bcbcbc',
  gris04: '#555555',
  gris05: '#313131',
  gris06: '#757575',
  gris07: '#171A1A',
  black: '#000000',
  dark: '#001F19',

  discord: '#5865f2',
  whatsapp: '#25d366',

  // hover
  hoverPrimary: '#f7f6fd',

  warningColor: '#ff2c2c',
  warningLight1Color: '#c432571f',

  bgStatusStarted: '#d2f9f0',
  bgStatusToStart: '#fffdcd',
  bgStatusFinished: '#ffe6e0',

  colorStatusStarted: '#259d81',
  colorStatusToStart: '#b9992b',
  colorStatusFinished: '#d84949',

  statusAttended: '#D2F9F0',
  statusDelay: '#FFFDCD',
  statusAbsence: '#FFE6E0',
  statusJustified: '#E5E5E5',
  statusRecovered: '#E5F2FF',
  statusMaterial: '#F7F6FD',
  statusLetter: '#259D81',
  statusSubstitution: '#E5F2FF',

  statusAttendedText: '#259D81',
  statusDelayText: '#B9992B',
  statusAbsenceText: '#D84949',
  statusJustifiedText: '#555555',
  statusRecoveredText: '#2491FD',
  statusMaterialText: '#9C56E0',
  statusSubstitutionText: '#468EFF',

  colorModalityLocador: '#3d8de7',

  colorAlertRed: '#f74949',
  colorAlertPrimary: '#9c56e0',
  colorAlertSuccess: '#0BC79B',

  shadowLarge:
    '0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08)'
};

export default colors;
