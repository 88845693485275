/* eslint-disable complexity */
import React, { useEffect, useState, ChangeEvent } from 'react';

// Components
import Typography from '../../atoms/Typography/Typography';
import Switch from '../../molecules/Switch';
import IconArrowCollapse from '../../atoms/icons/IconArrowCollapse';
import IconAdd from '../../atoms/icons/IconAdd';
import IconDelete from '../../atoms/icons/IconDelete';
import InputScheduleAvailability from '../InputScheduleAvailability';

//helpers
import { renderDay } from '../../../helpers/day.helpers';
import {
  defHourRange,
  hourFormatterSecond,
  hourFromRangeBetween,
  hourToRangeBetween,
  inputHourRange,
  valHourTo,
  valOtherRangesTime
} from '../../../helpers/hour.helpers';
import colors from '../../atoms/Colors';

import './scheduleAvailability.scss';

interface Props {
  isActive?: any;
  setIsActive?: any;
  day: string;
  setSchedule?: any;
  setIsError?: any;
  isError?: any;
  schedule?: any;
  defaultValues?: Array<any>;
  refetch: () => {};
}

const ScheduleAvailability: React.FC<Props> = ({
  isActive,
  setIsActive,
  day,
  setSchedule,
  setIsError,
  isError,
  schedule,
  defaultValues
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionId, setOptionId] = useState();
  const [optionId1, setOptionId1] = useState();
  const [optionId2, setOptionId2] = useState();
  const [addNewHour, setAddNewHour] = useState(0);
  const [deleteHour1, setDeleteHour1] = useState(false);
  const [deleteHour2, setDeleteHour2] = useState(false);

  const [hourOf, setHourOf] = useState('00:00');
  const [hourTo, setHourTo] = useState('00:00');
  const [errorOf, setErrorOf] = useState(false);
  const [errorTo, setErrorTo] = useState(false);

  const [hourOf1, setHourOf1] = useState('00:00');
  const [hourTo1, setHourTo1] = useState('00:00');
  const [errorOf1, setErrorOf1] = useState(false);
  const [errorTo1, setErrorTo1] = useState(false);

  const [hourOf2, setHourOf2] = useState('00:00');
  const [hourTo2, setHourTo2] = useState('00:00');
  const [errorOf2, setErrorOf2] = useState(false);
  const [errorTo2, setErrorTo2] = useState(false);

  /* NOTE: GET values by teacher */
  useEffect(() => {
    if (defaultValues) {
      if (
        defaultValues[0] &&
        defaultValues[0].start_time &&
        defaultValues[0].end_time
      ) {
        setOptionId(defaultValues[0]?.id);
        setHourOf(defaultValues[0]?.start_time);
        setHourTo(defaultValues[0]?.end_time);
        if (
          defaultValues[1] &&
          defaultValues[1].start_time &&
          defaultValues[1].end_time
        ) {
          setOptionId1(defaultValues[1]?.id);
          setAddNewHour(1);
          setHourOf1(defaultValues[1].start_time);
          setHourTo1(defaultValues[1].end_time);
          if (
            defaultValues[2] &&
            defaultValues[2].start_time &&
            defaultValues[2].end_time
          ) {
            setOptionId2(defaultValues[2]?.id);
            setAddNewHour(2);
            setHourOf2(defaultValues[2].start_time);
            setHourTo2(defaultValues[2].end_time);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isActive) {
      if (hourOf === '00:00' && hourTo === '00:00') {
        setErrorOf(false);
        setErrorTo(false);
        setIsError(false);
        setHourOf('06:00');
        setHourTo('22:00');
      } else if (hourOf === '00:00') {
        setErrorOf(true);
        setIsError(true);
      } else if (hourTo === '00:00') {
        setErrorTo(true);
        setIsError(true);
      } else if (
        hourOf1 === '00:00' &&
        hourTo1 === '00:00' &&
        addNewHour >= 1
      ) {
        setErrorOf1(true);
        setErrorTo1(true);
        setIsError(true);
      } else if (hourOf1 === '00:00' && addNewHour >= 1) {
        setErrorOf1(true);
        setIsError(true);
      } else if (hourTo1 === '00:00' && addNewHour >= 1) {
        setErrorTo1(true);
        setIsError(true);
      } else if (
        hourOf2 === '00:00' &&
        hourTo2 === '00:00' &&
        addNewHour === 2
      ) {
        setErrorOf2(true);
        setErrorTo2(true);
        setIsError(true);
      } else if (hourOf2 === '00:00' && addNewHour === 2) {
        setErrorOf2(true);
        setIsError(true);
      } else if (hourTo2 === '00:00' && addNewHour === 2) {
        setErrorTo2(true);
        setIsError(true);
      } else if (
        !errorOf &&
        !errorTo &&
        !errorOf1 &&
        !errorTo1 &&
        !errorOf2 &&
        !errorTo2
      ) {
        setIsError(false);
      }
    } else {
      setSchedule([]);
    }
  }, [isActive, errorOf, errorTo, errorOf1, errorTo1, errorOf2, errorTo2]);

  useEffect(() => {
    handleSchedule();

    /* if (isActive) {
      handleSchedule();
    } */
  }, [
    isActive,
    hourOf,
    hourTo,
    hourOf1,
    hourTo1,
    hourOf2,
    hourTo2,
    addNewHour
  ]);

  const handleAddNewSchedule = async () => {
    switch (addNewHour) {
      case 0:
        if (!errorOf && !errorTo) {
          setAddNewHour(addNewHour + 1);
          setErrorOf1(true);
          setErrorTo1(true);
        }
        return hourOf;
      case 1:
        if (!errorOf && !errorTo) {
          if (!errorOf1 && !errorTo1) {
            setAddNewHour(addNewHour + 1);
            setErrorOf2(true);
            setErrorTo2(true);
          }
        }
        return hourOf1;
      case 2:
        if (!errorOf && !errorTo) {
          if (!errorOf1 && !errorTo1) {
            if (!errorOf2 && !errorTo2) {
              setAddNewHour(2);
            }
          }
        }
        return hourOf2;
    }
  };

  const handleSchedule = async () => {
    const modelData: any = [];
    // if (isActive) {
    switch (addNewHour) {
      case 0:
        if (hourOf !== '00:00' && hourTo !== '00:00') {
          modelData.push({
            ...(optionId ? { id: optionId } : {}),
            day: renderDay(day),
            available: isActive,
            start_time: hourFormatterSecond(hourOf),
            end_time: hourFormatterSecond(hourTo)
          });
        }
        setSchedule(modelData);
        return hourOf;
      case 1:
        if (hourOf1 !== '00:00' && hourTo1 !== '00:00') {
          modelData.push(
            {
              ...(optionId1 ? { id: optionId1 } : {}),
              day: renderDay(day),
              available: isActive,
              start_time: hourFormatterSecond(hourOf),
              end_time: hourFormatterSecond(hourTo)
            },
            {
              ...(optionId2 ? { id: optionId2 } : {}),
              day: renderDay(day),
              available: isActive,
              start_time: hourFormatterSecond(hourOf1),
              end_time: hourFormatterSecond(hourTo1)
            }
          );
        } else {
          modelData.push({
            ...(optionId ? { id: optionId } : {}),
            day: renderDay(day),
            available: isActive,
            start_time: hourFormatterSecond(hourOf),
            end_time: hourFormatterSecond(hourTo)
          });
        }
        setSchedule(modelData);
        return hourOf1;
      case 2:
        if (hourOf2 !== '00:00' && hourTo2 !== '00:00') {
          modelData.push(
            {
              ...(optionId ? { id: optionId } : {}),
              day: renderDay(day),
              available: isActive,
              start_time: hourFormatterSecond(hourOf),
              end_time: hourFormatterSecond(hourTo)
            },
            {
              ...(optionId1 ? { id: optionId1 } : {}),
              day: renderDay(day),
              available: isActive,
              start_time: hourFormatterSecond(hourOf1),
              end_time: hourFormatterSecond(hourTo1)
            },
            {
              ...(optionId2 ? { id: optionId2 } : {}),
              day: renderDay(day),
              available: isActive,
              start_time: hourFormatterSecond(hourOf2),
              end_time: hourFormatterSecond(hourTo2)
            }
          );
        } else {
          modelData.push(
            {
              ...(optionId ? { id: optionId } : {}),
              day: renderDay(day),
              available: isActive,
              start_time: hourFormatterSecond(hourOf),
              end_time: hourFormatterSecond(hourTo)
            },
            {
              ...(optionId1 ? { id: optionId1 } : {}),
              day: renderDay(day),
              available: isActive,
              start_time: hourFormatterSecond(hourOf1),
              end_time: hourFormatterSecond(hourTo1)
            }
          );
        }
        setSchedule(modelData);
        return hourOf2;
    }
    /*  } else {
      modelData.filter((item: any) => item.available === true).map((item: any) => item);
      setSchedule(modelData);
    } */
  };

  const handleDeleteHourRange = async (index: number) => {
    switch (index) {
      case 1:
        setDeleteHour1(true);
        setAddNewHour(addNewHour - 1);
        setHourOf1('00:00');
        setHourTo1('00:00');

        setErrorOf1(false);
        setErrorTo1(false);

        return deleteHour1;
      case 2:
        setDeleteHour2(true);
        setAddNewHour(addNewHour - 1);
        setHourOf2('00:00');
        setHourTo2('00:00');
        setErrorOf2(false);
        setErrorTo2(false);
        return deleteHour2;
    }
  };

  const handleBlurOf = (event: ChangeEvent<HTMLInputElement>) => {
    if (defHourRange(event.target.value)) {
      setErrorOf(true);
      setIsError(true);
    } else {
      setErrorOf(false);
    }
  };

  const handleBlurTo = (event: ChangeEvent<HTMLInputElement>) => {
    if (defHourRange(event.target.value)) {
      setErrorTo(true);
      setIsError(true);
    } else if (valHourTo(event.target.value, hourOf)) {
      setErrorTo(true);
      setIsError(true);
    } else {
      setErrorTo(false);
      setHourTo(hourTo);
    }
  };

  const handleBlurOf1 = (event: ChangeEvent<HTMLInputElement>) => {
    if (defHourRange(event.target.value)) {
      setErrorOf1(true);
      setIsError(true);
    } else if (hourFromRangeBetween(event.target.value, hourOf, hourTo)) {
      setErrorOf1(true);
      setIsError(true);
    } else {
      setErrorOf1(false);
    }
  };

  const handleBlurTo1 = (event: ChangeEvent<HTMLInputElement>) => {
    if (defHourRange(event.target.value)) {
      setErrorTo1(true);
      setIsError(true);
    } else if (hourToRangeBetween(event.target.value, hourOf, hourTo)) {
      setErrorTo1(true);
      setIsError(true);
    } else if (valHourTo(event.target.value, hourOf1)) {
      setErrorTo1(true);
      setIsError(true);
    } else if (
      valOtherRangesTime(hourOf, hourTo, hourOf1, event.target.value)
    ) {
      setErrorTo1(true);
      setIsError(true);
    } else {
      setErrorTo1(false);
    }
  };

  const handleBlurOf2 = (event: ChangeEvent<HTMLInputElement>) => {
    if (defHourRange(event.target.value)) {
      setErrorOf2(true);
      setIsError(true);
    } else if (hourFromRangeBetween(event.target.value, hourOf1, hourTo1)) {
      setErrorOf2(true);
      setIsError(true);
    } else if (hourFromRangeBetween(event.target.value, hourOf, hourTo)) {
      setErrorOf2(true);
      setIsError(true);
    } else {
      setErrorOf2(false);
    }
  };

  const handleBlurTo2 = (event: ChangeEvent<HTMLInputElement>) => {
    if (defHourRange(event.target.value)) {
      setErrorTo2(true);
      setIsError(true);
    } else if (hourToRangeBetween(event.target.value, hourOf, hourTo)) {
      setErrorTo2(true);
      setIsError(true);
    } else if (hourToRangeBetween(event.target.value, hourOf1, hourTo1)) {
      setErrorTo2(true);
      setIsError(true);
    } else if (valHourTo(event.target.value, hourOf2)) {
      setErrorTo2(true);
      setIsError(true);
    } else if (
      valOtherRangesTime(hourOf, hourTo, hourOf2, event.target.value)
    ) {
      setErrorTo2(true);
      setIsError(true);
    } else if (
      valOtherRangesTime(hourOf1, hourTo1, hourOf2, event.target.value)
    ) {
      setErrorTo2(true);
      setIsError(true);
    } else {
      setErrorTo2(false);
    }
  };

  const handleChangeOf = (event: ChangeEvent<HTMLInputElement>) => {
    setHourOf(event.target.value);
  };
  const handleChangeOf1 = (event: ChangeEvent<HTMLInputElement>) => {
    setHourOf1(event.target.value);
  };
  const handleChangeOf2 = (event: ChangeEvent<HTMLInputElement>) => {
    setHourOf2(event.target.value);
  };

  const handleChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
    setHourTo(event.target.value);
  };
  const handleChangeTo1 = (event: ChangeEvent<HTMLInputElement>) => {
    setHourTo1(event.target.value);
  };
  const handleChangeTo2 = (event: ChangeEvent<HTMLInputElement>) => {
    setHourTo2(event.target.value);
  };

  const handleChangeSwitch = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      className={`
            schedule-availability 
				schedule-availability--${
          isOpen && isActive
            ? 'open-active'
            : isActive && !isOpen
              ? 'close-active'
              : 'close-inactive'
        }
        `}
    >
      <section className="schedule-availability__content">
        <div className="schedule-availability__content--day">
          <Typography
            type="paragraph2"
            variation="mobile"
            className="schedule-availability__content--day"
          >
            {day}
          </Typography>
        </div>
        <div
          className="schedule-availability__container-icon-arrow"
          onClick={() => setIsOpen(isActive ? !isOpen : false)}
        >
          <IconArrowCollapse
            fill={isActive ? colors.black : colors.gris05}
            className={`
						schedule-availability__icon-arrow schedule-availability__icon-arrow--${
              isActive ? 'active' : 'disabled'
            }
                        schedule-availability__icon-arrow--${isOpen ? 'open' : 'close'}`}
          />
        </div>
        <div className="schedule-availability__switch">
          <Switch
            isActive={isActive}
            setIsActive={setIsActive}
            textLeft={isActive ? 'Activo' : 'Inactivo'}
            name={day}
            onClick={() => handleChangeSwitch()}
          ></Switch>
        </div>
      </section>
      <section
        className={`schedule-availability__sub-content schedule-availability__sub-content--${
          isActive && isOpen ? 'open' : 'hidden'
        }`}
      >
        {isOpen ? (
          <div className="schedule-availability__sub-content--container">
            {/* TODO: FIRST RANGE */}
            <section className="schedule-availability__sub-content--item">
              <div className="schedule-availability__sub-content--times">
                <Typography type="paragraph2" variation="mobile">
                  De:
                </Typography>
                <InputScheduleAvailability
                  isError={errorOf}
                  onChange={handleChangeOf}
                  onBlur={handleBlurOf}
                  idList="listalimitestiempo1"
                  datalist={inputHourRange}
                  value={hourOf}
                  defaultValue={hourOf}
                  required
                />
                <Typography type="paragraph2" variation="mobile">
                  a
                </Typography>
                <InputScheduleAvailability
                  isError={errorTo}
                  onChange={handleChangeTo}
                  onBlur={handleBlurTo}
                  idList="listalimitestiempo2"
                  datalist={inputHourRange}
                  value={hourTo}
                  defaultValue={hourTo}
                  required
                />
              </div>
              <div
                className="schedule-availability__icon-add"
                onClick={() => handleAddNewSchedule()}
              >
                <IconAdd />
              </div>
            </section>
            {/* TODO: SECOND RANGE */}
            {addNewHour > 0 ? (
              <section className="schedule-availability__sub-content--item">
                <div className="schedule-availability__sub-content--times">
                  <Typography type="paragraph2" variation="mobile">
                    De:
                  </Typography>
                  <InputScheduleAvailability
                    isError={errorOf1}
                    onChange={handleChangeOf1}
                    onBlur={handleBlurOf1}
                    idList="listalimitestiempo3"
                    datalist={inputHourRange}
                    value={hourOf1}
                    defaultValue={hourOf1}
                    required
                  />
                  <Typography type="paragraph2" variation="mobile">
                    a
                  </Typography>
                  <InputScheduleAvailability
                    isError={errorTo1}
                    onChange={handleChangeTo1}
                    onBlur={handleBlurTo1}
                    idList="listalimitestiempo4"
                    datalist={inputHourRange}
                    value={hourTo1}
                    defaultValue={hourTo1}
                    required
                  />
                </div>
                <div
                  className={`
                                        schedule-availability__sub-content--iconDelete 
                                        schedule-availability__sub-content--${
                                          deleteHour1 ? 'hidden' : ''
                                        }`}
                  onClick={() => handleDeleteHourRange(1)}
                >
                  <IconDelete />
                </div>
              </section>
            ) : (
              <div></div>
            )}
            {/* TODO: THIRD RANGE */}
            {addNewHour > 1 ? (
              <section className="schedule-availability__sub-content--item">
                <div className="schedule-availability__sub-content--times">
                  <Typography type="paragraph2" variation="mobile">
                    De:
                  </Typography>
                  <InputScheduleAvailability
                    isError={errorOf2}
                    onChange={handleChangeOf2}
                    onBlur={handleBlurOf2}
                    idList="listalimitestiempo4"
                    datalist={inputHourRange}
                    value={hourOf2}
                    defaultValue={hourOf2}
                    required
                  />
                  <Typography type="paragraph2" variation="mobile">
                    a
                  </Typography>
                  <InputScheduleAvailability
                    isError={errorTo2}
                    onChange={handleChangeTo2}
                    onBlur={handleBlurTo2}
                    idList="listalimitestiempo4"
                    datalist={inputHourRange}
                    value={hourTo2}
                    defaultValue={hourTo2}
                    required
                  />
                </div>
                <div
                  className={`
                                        schedule-availability__sub-content--iconDelete 
                                        schedule-availability__sub-content--${
                                          deleteHour2 ? 'hidden' : ''
                                        }`}
                  onClick={() => handleDeleteHourRange(2)}
                >
                  <IconDelete />
                </div>
              </section>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </section>
    </div>
  );
};

export default ScheduleAvailability;
