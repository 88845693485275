// Material UI
import { Avatar, Typography } from '@material-ui/core';

import colors from '../../atoms/Colors';

// Interfaces
interface AttendanceIndicatorProps {
  message: string;
  status:
    | 'undefined'
    | 'attended'
    | 'delay'
    | 'absence'
    | 'justified'
    | 'recovered'
    | 'material'
    | 'N/A'
    | 'substitution';
}

const AttendanceIndicator = ({ message, status }: AttendanceIndicatorProps) => {
  return (
    <Avatar
      variant="square"
      sx={{
        bgcolor: 'transparent',
        color:
          status === 'attended'
            ? colors.statusAttendedText
            : status === 'delay'
              ? colors.statusDelayText
              : status === 'absence'
                ? colors.statusAbsenceText
                : status === 'justified'
                  ? colors.statusJustifiedText
                  : status === 'recovered'
                    ? colors.statusRecoveredText
                    : status === 'material'
                      ? colors.statusMaterialText
                      : status === 'N/A'
                        ? colors.statusJustifiedText
                        : status === 'substitution'
                          ? colors.statusSubstitutionText
                          : colors.gris03,
        height: 24
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'Sofia Pro',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 14
        }}
      >
        {message}
      </Typography>
    </Avatar>
  );
};

export default AttendanceIndicator;
