// React
import { useState } from 'react';

// Components
import CreateGuardian from './CreateGuardian/CreateGuardian';
import OpenModal from '../../../components/Modal/OpenModal/OpenModal';
import Modal from '../../../components/Modal/Modal';

interface Props {
  refetch?: () => {};
}

const ModalCreateGuardian: React.FC<Props> = ({ refetch }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal
        option="create"
        title="Crear nuevo Guardian"
        handleOpenModal={handleOpenModal}
      />

      {/* Modal Create Teacher */}
      <Modal
        title="Crear Guardian"
        fullWidth
        maxWidth="md"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <CreateGuardian onClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default ModalCreateGuardian;
