import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getOrganizationByIdFromApi } from '../slices/CTCB2B/organizationSlice/organizationSlice';

const useOrganizationById = ({
  organizationId,
  shouldRefetch
}: {
  organizationId: number;
  shouldRefetch?: boolean;
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const getOrganization = async () => {
    try {
      return await dispatch(
        getOrganizationByIdFromApi({ id: Number(organizationId) })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrganization();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching organization:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, shouldRefetch]);

  return { data, isLoading };
};

export default useOrganizationById;
