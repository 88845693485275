import Tag from '../../atoms/TagStatus/Tag';
import './tagCourses.scss';

interface Props {
  children: any;
  className?: string;
  color?: string;
}

const TagCourses: React.FC<Props> = ({ children, className = '', color }) => {
  return (
    <Tag className={`tag-courses ${className}`} color={color}>
      {children}
    </Tag>
  );
};

export default TagCourses;
