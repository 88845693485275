/* eslint-disable complexity */
import { Formik, Form } from 'formik';
import React, { useEffect } from 'react';

import './modalAddAssistantTeacher.scss';

import Modal from '../../atoms/Modal';
import Input from '../../molecules/Input';
import colors from '../../../components/atoms/Colors';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonLineal from '../../molecules/ButtonLineal';
import Typography from '../../atoms/Typography/Typography';
import IconAddSymbol from '../../atoms/icons/IconAddSymbol';
import { CreateExternalTeacherSchema } from './validation.schema';
import Paragraph from '../../../components/common/Paragraph/Paragraph';

interface ICreateExternalTeacherInitialValues {
  name: string;
  last_name: string;
  email: string;
  password: string;
}

interface Props {
  isOpen: boolean;
  disabledInputs: boolean;
  isDelete?: boolean;
  closeModal: () => void;
  externalTeacher: ICreateExternalTeacherInitialValues;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  handleOnclick: any;
  setDisabledInputs: any;
  debouncedCallback?: any;
}

const ModalAddAssistantTeacher: React.FC<Props> = ({
  isOpen,
  disabledInputs,
  isDelete,
  closeModal,
  width = 'lg',
  externalTeacher,
  handleOnclick,
  debouncedCallback,
  setDisabledInputs
}) => {
  const handleSaveModal = (values: any) => {
    handleOnclick(values);
    closeModal();
  };
  const initialValuesLocal: ICreateExternalTeacherInitialValues = {
    name: '',
    last_name: '',
    email: '',
    password: ''
  };

  const generatePassword = ({
    firstName,
    lastName
  }: {
    firstName: string;
    lastName: string;
  }) => {
    const firstLetters = firstName.slice(0, 2).toLowerCase();

    const randomNumber = Math.floor(Math.random() * 9999) + 1;

    const password = `${firstLetters}${lastName.toLowerCase()}${String(
      randomNumber
    ).padStart(4, '0')}`;

    return password.replace(/\s/g, '');
  };

  const RenderIcon = ({ disabled }: { disabled: any }) => {
    if (isDelete) {
      return <span>Eliminar profesor</span>;
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <span style={{ marginRight: '5px' }}>Agregar</span>
        <span style={{ marginTop: '3px' }}>
          <IconAddSymbol
            fill={!disabled ? '#fff' : '#bcbcbc'}
            width={12}
            height={12}
            viewBox={'0 0 12 12'}
            className="attendance-teacher-v2__attendance-outline-button"
          />
        </span>
      </div>
    );
  };

  return (
    <>
      <Modal
        className="modal-add-teacher-etariox"
        width={width}
        isOpen={isOpen}
        closeModal={closeModal}
        title={
          <Typography bold type="paragraph1" variation="desktop">
            Agregar profesor externo
          </Typography>
        }
        subtitle={
          <Typography type="paragraph2" variation="mobile">
            Rol en Moodle: Profesor sin permiso de edición. Si el profesor ya
            existe, solo completar el mail y el resto de los campos se
            autocompletarán
          </Typography>
        }
        body={
          <Formik
            initialValues={initialValuesLocal}
            validationSchema={CreateExternalTeacherSchema}
            onSubmit={async (values, { resetForm }) => {
              handleSaveModal(values);
              setDisabledInputs(false);
              resetForm();
            }}
          >
            {({
              errors,
              values,
              handleChange,
              setFieldValue,
              submitForm,
              isValid,
              resetForm
            }) => {
              useEffect(() => {
                if (externalTeacher) {
                  resetForm({ values: externalTeacher });
                }
              }, [externalTeacher]);

              return (
                <Form>
                  <div className="modal-add-assistant-teacher__container-body">
                    <div className="modal-add-assistant-teacher__container-namesx">
                      <Input
                        placeholder="Correo Personal*"
                        name="email"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChange(event);
                          debouncedCallback?.(event.target.value);
                        }}
                        value={values.email}
                        required
                        error={errors.email ? errors.email : ''}
                        disabled={disabledInputs}
                      />
                      <div className="modal-add-assistant-teacher__container-names">
                        <Input
                          placeholder="Nombre*"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          required
                          error={errors.name ? errors.name : ''}
                          disabled={disabledInputs}
                        />
                        <Input
                          placeholder="Apellido*"
                          name="last_name"
                          onChange={handleChange}
                          value={values.last_name}
                          required
                          error={errors.last_name ? errors.last_name : ''}
                          disabled={disabledInputs}
                        />
                      </div>
                    </div>

                    <div className="modal-add-assistant-teacher__container-pass">
                      <div className="modal-add-assistant-teacher__container-pass-text">
                        <Typography type="paragraph1" variation="mobile">
                          Contraseña plataformas
                        </Typography>
                        <Typography type="paragraph2" variation="mobile">
                          Compartir con el profesor de forma privada
                        </Typography>
                      </div>

                      {!values.password ? (
                        <div className="modal-add-assistant-teacher__container-pass-button">
                          <ButtonLineal
                            disabled={!values.name || !values.last_name}
                            onClick={() => {
                              const pass = generatePassword({
                                firstName: values.name,
                                lastName: values.last_name
                              });
                              setFieldValue('password', pass);
                            }}
                            customStyles={{ fontSize: '16px' }}
                          >
                            Generar contraseña
                          </ButtonLineal>
                          <Typography
                            type="paragraph2"
                            variation="mobile"
                            color={colors.warningColor}
                          >
                            {errors.password}
                          </Typography>
                        </div>
                      ) : (
                        <div className="modal-add-assistant-teacher__container-pass-g-text">
                          <Typography bold type="paragraph2" variation="mobile">
                            {values.password}
                          </Typography>
                          <Paragraph
                            text={values.password}
                            copyable
                            hideText
                            direction="right"
                          />
                          <Typography
                            type="paragraph2"
                            variation="mobile"
                            color={colors.warningColor}
                          >
                            {errors.password}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className="modal-add-assistant-teacher__container-button">
                      <ButtonFilled
                        customStyles={{ padding: '5px 0px' }}
                        disabled={
                          !isValid ||
                          !values.name ||
                          !values.last_name ||
                          !values.password
                        }
                        size="medium"
                        fullWidth={true}
                        onClick={submitForm}
                      >
                        <RenderIcon
                          disabled={
                            !isValid ||
                            !values.name ||
                            !values.last_name ||
                            !values.password
                          }
                        />
                      </ButtonFilled>
                      <ButtonLineal
                        customStyles={{ padding: '5px 0px', fontSize: '16px' }}
                        onClick={() => {
                          resetForm();
                          closeModal();
                          setDisabledInputs(false);
                        }}
                      >
                        Cancelar
                      </ButtonLineal>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    </>
  );
};

export default ModalAddAssistantTeacher;
