// React
import { useState } from 'react';

// Components
import DetailsSlider from './DetailsSlider/DetailsSlider';
import OpenModal from '../../../components/Modal/OpenModal/OpenModal';
import Modal from '../../../components/Modal/Modal';

interface Props {
  sliderId: number;
}

const ModalDetailsSlider: React.FC<Props> = ({ sliderId }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal
        option="more-info"
        placement="left"
        handleOpenModal={handleOpenModal}
      />

      {/* Modal Details Slider */}
      <Modal
        id={sliderId}
        title="Más Información"
        fullWidth
        maxWidth="md"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <DetailsSlider sliderId={sliderId} />
      </Modal>
    </>
  );
};

export default ModalDetailsSlider;
