// Interfaces
import Currencies from '../../../../../components/LearningPaths/Currencies/Currencies';

interface Props {
  refetch: () => {};
  learningPathId: number;
}

const Prices: React.FC<Props> = ({ refetch, learningPathId }) => {
  return (
    <Currencies
      learningPathId={learningPathId}
      currencies={['PEN', 'USD', 'MXN', 'COP', 'ARS', 'CLP', 'CRC', 'UYU']}
      refetch={refetch}
    />
  );
};

export default Prices;
