import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';

import { IGuardian } from '../../interfaces/guardian.interface';
import { getGuardianForId, patchEditGuardian } from '../../services/guardians';
// Hot Toast

export interface IAuthError {
  msg: string;
}

export interface IGuardianByIdState {
  guardians: IGuardian[];
  guardian: IGuardian | null | {};
  isLoadingGuardianForId: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
}

const initialState: IGuardianByIdState = {
  guardians: [],
  guardian: {},
  isLoadingGuardianForId: false,
  isCompleted: false,
  error: null,
  rejected: false
};

export const getGuardianById = createAsyncThunk(
  'guardiansById/getGuardianById',
  async (guardianId: number | null, { rejectWithValue }: any) => {
    try {
      const data = await getGuardianForId(guardianId);
      return data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateGuardianById = createAsyncThunk(
  'guardiansById/updateGuardianById',
  async (
    { guardianId, guardian }: any,
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      const response = await patchEditGuardian(guardian, guardianId);
      if (response) {
        toast.success('Guardian editado correctamente!');
      } else {
        toast.error('Ha ocurrido un error al editar el guardian.');
      }
      return response;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const guardiansByIdSlice = createSlice({
  name: 'guardiansById',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getGuardianById */
    builder.addCase(getGuardianById.pending, (state, _) => {
      state.guardian = initialState.guardian;
      state.isLoadingGuardianForId = true;
    });
    builder.addCase(getGuardianById.fulfilled, (state, action) => {
      state.guardian = action.payload;
      state.isLoadingGuardianForId = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(getGuardianById.rejected, (state, _) => {
      state.isLoadingGuardianForId = initialState.isLoadingGuardianForId;
      state.rejected = true;
    });
    /** updateGuardianById */
    builder.addCase(updateGuardianById.pending, (state, _) => {
      state.isLoadingGuardianForId = true;
    });
    builder.addCase(updateGuardianById.fulfilled, (state, action) => {
      state.guardian = action.payload;
      state.isLoadingGuardianForId = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(updateGuardianById.rejected, (state, _) => {
      state.isLoadingGuardianForId = initialState.isLoadingGuardianForId;
      state.rejected = true;
    });
  }
});

export const selectStateGuardianForId = (state: any) => state.guardiansById;
