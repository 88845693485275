import './guardianLayout.scss';

interface Props {
  table: any;
}

const GuardianLayout: React.FC<Props> = ({ table }) => {
  return (
    <main className="guardian-layout">
      <div className="guardian-layout__table">{table}</div>
    </main>
  );
};

export default GuardianLayout;
