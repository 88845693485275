// zustand
import create from 'zustand';

// Material UI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@mui/material';

type ConfirmDialogStore = {
  message: string;
  onSubmit?: () => void;
  close: () => void;
};

export const useConfirmDialogStore = create<ConfirmDialogStore>(set => ({
  message: '',
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined })
}));

export const confirmDialog = (message: string, onSubmit: () => void) => {
  useConfirmDialogStore.setState({
    message,
    onSubmit
  });
};

const ConfirmDialog = () => {
  const { message, onSubmit, close } = useConfirmDialogStore();

  return (
    <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth="xs" fullWidth>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '0 5px 0 0' }}
      >
        <DialogTitle>Confirmar acción</DialogTitle>
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
        <Alert severity="error">{message}</Alert>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button color="error" variant="contained" onClick={close}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
