import {
  Box,
  Grid,
  Alert,
  Button,
  Divider,
  MenuItem,
  TextField,
  InputLabel,
  AlertTitle,
  FormControl,
  Select as SelectMui,
  Typography as MaterialTypo
} from '@material-ui/core';
import * as Yup from 'yup';
import moment from 'moment';
import {
 Form, Field, Formik, ErrorMessage 
} from 'formik';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import SendIcon from '@material-ui/icons/Send';
import InboxRoundedIcon from '@material-ui/icons/InboxRounded';
import TouchAppRoundedIcon from '@material-ui/icons/TouchAppRounded';

import './commentsBySession.scss';
import {
  ICommentSession,
  IRoomDetailSimplified
} from '../../../../../interfaces/room.interface';
import { useModal } from '../../../../../hooks/useModal';
import { GET, POST } from '../../../../../services/api/api';
import colors from '../../../../../components/atoms/Colors';
import { dict } from '../../../../../types/generics/generics';
import Select from '../../../../../components/molecules/Select';
import { CardStudentsComments, ListComments, ListStudents } from './styles';
import Typography from '../../../../../components/atoms/Typography/Typography';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import {
  AppDispatch,
  RootState,
  useAppDispatch
} from '../../../../../store/store';
import CommentListItem from './../../Students/Comments/CommentListItem/CommentListItem';
import ButtonOutline from '../../../../../components/molecules/ButtonOutline/ButtonOutline';
import { AlertSnackbar } from '../../../../../components/common/AlertSnackbar/AlertSnackbar';
import ModalMessageAlert from '../../../../../components/molecules/ModalMessageAlert/ModalMessageAlert';

interface Props {
  room: IRoomDetailSimplified;
  refetch: () => void;
  setActiveTabMain: React.Dispatch<React.SetStateAction<number>>;
  setIndexPanelTypeComment: React.Dispatch<React.SetStateAction<number>>;
}

const CommentSchema = Yup.object().shape({comment: Yup.string().max(500).required('Required')});

const EmptyAdvice = ({ message }: { message: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <MaterialTypo variant="h6" gutterBottom component="div">
          {message}
        </MaterialTypo>
      </Box>
      <InboxRoundedIcon fontSize="large" color="disabled" />
    </Box>
  );
};

const SelectAdvice = ({ message }: { message: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <MaterialTypo variant="h6" gutterBottom component="div">
          {message}
        </MaterialTypo>
      </Box>
      <TouchAppRoundedIcon fontSize="large" color="disabled" />
    </Box>
  );
};

const CommentsBySession: React.FC<Props> = ({
  room,
  refetch,
  setActiveTabMain,
  setIndexPanelTypeComment
}) => {
  const [sessionSelected, setSessionSelected] = useState<any>('');
  const [isOpenSelect1, setIsOpenSelect1] = useState(false);
  const {
    openModal: openModalAlertAttendanceTaken,
    closeModal: closeModalAlertAttendanceTaken,
    isOpenModal: isOpenModalAlertAttendanceTaken
  } = useModal();

  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [isAvailable, setIsAvailable] = useState(false);

  const commentMutation = useMutation((comment: dict) =>
    POST(`/comment-session/`, comment)
  );

  const roomId = room.id;
  const {
    data: comments,
    refetch: refetchCommentInfo,
    isLoading,
    isError
  } = useQuery(
    ['sessionComments', roomId],
    () =>
      GET<ICommentSession[]>(
        `/backoffice/v2/rooms/${roomId}/comments?type=session`
      ),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const getLastSession = function () {
    let lastSession: any;
    let lastSessionByDate: any;
    const today = moment();
    if (room && room?.date_session && room?.date_session.length > 0) {
      try {
        room.date_session.forEach(session => {
          if (today.isAfter(moment(session.start_date).format('YYYY-MM-DD'))) {
            lastSessionByDate = session;
          } else {
            throw new Error();
          }
        });
      } catch (e) {
        //Do Nothing
      }

      lastSession =
        lastSessionByDate !== undefined
          ? lastSessionByDate
          : room?.date_session.find(session => session.session_number === 1);
    }
    return lastSession;
  };

  const lastSession = getLastSession();

  useEffect(() => {
    setSessionSelected(lastSession);
  }, [room]);

  useEffect(() => {
    if (
      user?.permission.name === 'Teacher' &&
      lastSession !== undefined &&
      lastSession.session_number < sessionSelected.session_number
    ) {
      openModalAlertAttendanceTaken();
      setIsAvailable(false);
    } else {
      setIsAvailable(true);
    }
  }, [user, room, sessionSelected]);

  const handleSendComment = async (values: dict) => {
    values.room_session = sessionSelected?.id || 0;
    try {
      const result = await commentMutation.mutateAsync(values);
      const sessionSelect = sessionSelected!;

      values.comment = '';
      values.subject = 'general';

      refetchCommentInfo();
      setActiveTabMain(2);
      setIndexPanelTypeComment(0);
      setSessionSelected(sessionSelect);
    } catch (error) {
      console.error(error);
    }
  };

  // ? UPDATED
  const optionSelection1 = (option: any): void => {
    setSessionSelected(option);
  };

  if (isLoading || !room) return <ScreenLoader />;

  if (isError) {
    return (
      <Alert
        severity="error"
        variant="outlined"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              refetchCommentInfo();
            }}
          >
            Reintentar
          </Button>
        }
      >
        <AlertTitle>Error</AlertTitle>
        No se pudo obtener la información de los comentarios de las sesiones.
        Por favor reintente.
      </Alert>
    );
  }

  return (
    <>
      <CardStudentsComments container>
        {/* Students list */}
        <Grid item xs={3}>
          <ListStudents>
            <section className="comments-by-sessions">
              <Typography
                className="comments-by-sessions__text-select"
                type="paragraph2"
                variation="mobile"
                bold
              >
                Sesión
              </Typography>
              <div>
                <Select
                  className="comments-by-sessions__select"
                  setIsOpen={setIsOpenSelect1}
                  isOpen={isOpenSelect1}
                  options={room?.date_session}
                  disabled={!room?.date_session}
                  identifierSort="session_number"
                  onclickDefaultValue={optionSelection1}
                >
                  {sessionSelected
                    ? sessionSelected.session_number
                    : 'Seleccionar'}
                </Select>
              </div>
            </section>
          </ListStudents>
        </Grid>

        {/* Comments list */}
        <Grid item xs={9} display="flex" flexDirection="column">
          <Box
            flexGrow={1}
            position="relative"
            overflow="auto"
            width="100%"
            style={{
              maxHeight: '330px',
              height: '330px',
              overflow: 'hidden'
            }}
          >
            <Box
              position="relative"
              overflow="auto"
              width="100%"
              style={{
                height:
                  user?.permission?.name === 'Teacher'
                    ? 'calc(100% - 45px)'
                    : '100%',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              {comments ? (
                comments.length > 0 ? (
                  <>
                    <Box>
                      <ListComments>
                        {comments
                          .filter(session => {
                            return session.room_session === sessionSelected.id;
                          })
                          .map((comment: any, idx: number) => (
                            <CommentListItem
                              key={idx}
                              commentBody={comment.comment}
                              commentCreatedAt={comment.created_at}
                              commentFromFullName={comment?.user?.full_name}
                              commentSubject={comment.subject}
                              isReaded={true}
                            />
                          ))}
                      </ListComments>
                    </Box>
                  </>
                ) : (
                  <EmptyAdvice message="La sesión aún no tiene comentarios" />
                )
              ) : (
                <SelectAdvice message="Seleccione la sesión para ver sus comentarios" />
              )}
            </Box>
          </Box>

          <Box flexGrow={1} position="relative" overflow="auto" width="100%">
            {sessionSelected && (
              <>
                <Divider />
                <Grid container style={{ padding: '20px' }}>
                  {/* Form */}
                  <>
                    <Formik
                      initialValues={{
                        comment: '',
                        user: user?.id || 0,
                        is_public: false,
                        room: room?.id || 0,
                        subject: 'general',
                        to_student: 0
                      }}
                      validationSchema={CommentSchema}
                      onSubmit={handleSendComment}
                    >
                      <Form style={{ width: '100%' }}>
                        {user?.permission.name === 'Teacher' ? (
                          <>
                            {isAvailable ? (
                              <>
                                <Grid container>
                                  <Grid item xs={9} gap={5}>
                                    <Box marginBottom={1}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Asunto
                                        </InputLabel>
                                        <Field
                                          as={SelectMui}
                                          labelId="demo-simple-select-label"
                                          id="subject"
                                          name="subject"
                                          label="Asunto"
                                        >
                                          <MenuItem value="general" selected>
                                            General
                                          </MenuItem>
                                          <MenuItem value="behavior">
                                            Comportamiento
                                          </MenuItem>
                                          <MenuItem value="credentials">
                                            Credenciales
                                          </MenuItem>
                                          <MenuItem value="sac">
                                            Comentarios SAC
                                          </MenuItem>
                                        </Field>
                                      </FormControl>
                                    </Box>
                                    <Field
                                      id="body-input"
                                      label="Comentario"
                                      name="comment"
                                      variant="outlined"
                                      as={TextField}
                                      multiline
                                      rows={3}
                                      fullWidth
                                    />
                                    <ErrorMessage
                                      name="comment"
                                      // render={() => <>Este campo es requerido</>}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        disableElevation
                                        disabled={commentMutation.isLoading}
                                        startIcon={<SendIcon />}
                                      >
                                        {commentMutation.isLoading
                                          ? 'Cargando...'
                                          : 'Comentar'}
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              <Typography
                                bold
                                type="small-12"
                                variation="mobile"
                                color={colors.colorAlertRed}
                              >
                                Para añadir comentarios debes completar la toma
                                de asistencia de tus clases concluidas.
                              </Typography>
                            )}
                          </>
                        ) : (
                          <>
                            <Grid container>
                              <Grid item xs={9} gap={5}>
                                <Box marginBottom={1}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Asunto
                                    </InputLabel>
                                    <Field
                                      as={SelectMui}
                                      labelId="demo-simple-select-label"
                                      id="subject"
                                      name="subject"
                                      label="Asunto"
                                    >
                                      <MenuItem value="general" selected>
                                        General
                                      </MenuItem>
                                      <MenuItem value="behavior">
                                        Comportamiento
                                      </MenuItem>
                                      <MenuItem value="credentials">
                                        Credenciales
                                      </MenuItem>
                                      <MenuItem value="sac">
                                        Comentarios SAC
                                      </MenuItem>
                                    </Field>
                                  </FormControl>
                                </Box>
                                <Field
                                  id="body-input"
                                  label="Comentario"
                                  name="comment"
                                  variant="outlined"
                                  as={TextField}
                                  multiline
                                  rows={3}
                                  fullWidth
                                />
                                <ErrorMessage
                                  name="comment"
                                  // render={() => <>Este campo es requerido</>}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    disabled={commentMutation.isLoading}
                                    startIcon={<SendIcon />}
                                  >
                                    {commentMutation.isLoading
                                      ? 'Cargando...'
                                      : 'Comentar'}
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Form>
                    </Formik>
                    {/* Alerts */}
                    {!commentMutation.data || (
                      <AlertSnackbar
                        message="Comentario creado"
                        severity="success"
                      />
                    )}
                    {!commentMutation.error || (
                      <AlertSnackbar
                        message="Error al crear comentario"
                        severity="error"
                      />
                    )}
                  </>
                </Grid>
              </>
            )}
          </Box>
        </Grid>
      </CardStudentsComments>
      <ModalMessageAlert
        width="sm"
        title={
          <Typography bold type="paragraph1" variation="desktop">
            ¿Completaste la toma de asistencia?
          </Typography>
        }
        message="Sólo podrás añadir comentarios si completaste la toma de asistencia de tus clases concluidas."
        align="center"
        isOpenModal={isOpenModalAlertAttendanceTaken}
        openModal={openModalAlertAttendanceTaken}
        closeModal={closeModalAlertAttendanceTaken}
        button1={
          <ButtonOutline width="8rem" onClick={closeModalAlertAttendanceTaken}>
            Cerrar
          </ButtonOutline>
        }
      />
    </>
  );
};

export default CommentsBySession;
