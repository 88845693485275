import React from 'react';

import Button from '../Button';

import './buttonFilled.scss';

interface Props {
  children: any;
  onClick?: any;
  className?: string;
  size?: any;
  iconLeft?: any;
  iconRight?: any;
  hasHoverColor?: boolean;
  disabled?: boolean;
  type?: any;
  fullWidth?: boolean | string | number;
  width?: string | number;
  dataTestid?: string;
  customStyles?: React.CSSProperties;
  href?: string;
  target?: string;
}

const ButtonFilled: React.FC<Props> = ({
  children,
  onClick,
  className,
  hasHoverColor,
  disabled,
  type = 'button',
  width,
  fullWidth,
  dataTestid = 'button-filled-test-id',
  customStyles,
  href,
  target,
  ...otherProps
}) => {
  const hoverType = hasHoverColor ? 'hover-color' : 'hover-no-color';

  return (
    <>
      <Button
        customStyles={customStyles}
        data-testid={dataTestid}
        {...otherProps}
        disabled={disabled}
        className={`button-filled button-filled--${hoverType} ${
          disabled ? 'button-filled__disabled' : ''
        } ${className ? className : ''}`}
        onClick={disabled ? () => {} : onClick}
        type={type}
        fullWidth={fullWidth}
        width={width}
        href={href}
        target={target}
      >
        {children}
      </Button>
    </>
  );
};

export default ButtonFilled;
