// React
import { useState } from 'react';
import { AxiosError } from 'axios';

// React Query
import toast from 'react-hot-toast';

// Material UI
import {
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  LinearProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import {
  patchProjectInfoByLearning,
  postProjectInfoBylearningPathId
} from '../../../services/learnings';

interface Props {
  projectTitle: string;
  projectDescription: string;
  projectId: number;
  learningPathId: number;
  refetch: any;
  refetchLearningPath: any;
}

const LearningPathProjectDetails: React.FC<Props> = ({
  projectTitle,
  projectDescription,
  projectId,
  learningPathId,
  refetchLearningPath
}) => {
  const [title, setTitle] = useState<string>(projectTitle);
  const [description, setDescription] = useState<string>(projectDescription);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreateDetailsProject = async () => {
    if (title && description && learningPathId) {
      try {
        const response = await postProjectInfoBylearningPathId(
          title,
          description,
          learningPathId
        );
        toast.success('¡Se guardo la información correctamente!');
        refetchLearningPath(learningPathId);
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo guardar la información - ${error}`);
          setIsLoading(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  const handleEditDetailsProject = async () => {
    if (title && description && learningPathId && projectId) {
      setIsLoading(true);
      try {
        const response = await patchProjectInfoByLearning(
          title,
          description,
          projectId
        );
        refetchLearningPath(learningPathId);
        toast.success('¡Se guardo la información correctamente!');
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error) {
          toast.error(`No se pudo actualizar la información - ${error}`);
          setIsLoading(false);
        }
      }
    } else {
      toast.error('La información es requerida');
    }
  };

  if (isLoading) return <LinearProgress />;

  return (
    <form>
      <Grid container rowSpacing={1} columnSpacing={1} sx={{ padding: 3 }}>
        {/* Main */}
        {projectTitle && !edit && (
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle2">
                  Información del proyecto
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    setEdit(true);
                  }}
                  color="secondary"
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} md={8}>
              <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
                Titulo
              </Typography>
              <Typography variant="body2">{title}</Typography>
              <br />
              <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
                Descripción
              </Typography>
              <Typography variant="body2">{description}</Typography>
            </Grid>
          </Grid>
        )}

        {/* Btn Add ToDo */}
        {(!projectTitle || edit) && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {title
                    ? 'Actualizar información del proyecto'
                    : 'Agregar información del proyecto'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  label="Titulo"
                  placeholder="Titulo"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  variant="outlined"
                  size="small"
                  type="text"
                  onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                  multiline
                  rows={1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  label="Descripción"
                  placeholder="Descripción"
                  minRows={2}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  style={{
                    width: '100%',
                    minWidth: '100%',
                    maxWidth: '100%'
                  }}
                  onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                  multiline
                  rows={2}
                />
              </Grid>
              {projectTitle && (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEdit(false);
                      setTitle(projectTitle);
                      setDescription(projectDescription);
                    }}
                    color="secondary"
                    sx={{
                      width: '100%',
                      minWidth: '100%',
                      maxWidth: '100%'
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              )}
              <Grid item xs={projectTitle ? 6 : 12}>
                <LoadingButton
                  variant="contained"
                  onClick={
                    projectTitle
                      ? () => handleEditDetailsProject()
                      : () => handleCreateDetailsProject()
                  }
                  startIcon={projectTitle ? <UpdateIcon /> : <AddCircleIcon />}
                  color={projectTitle ? 'secondary' : 'primary'}
                  sx={{
                    width: '100%',
                    minWidth: '100%',
                    maxWidth: '100%'
                  }}
                >
                  {projectTitle ? 'Editar' : 'Agregar'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default LearningPathProjectDetails;
