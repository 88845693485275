import axios from 'axios';

import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

export const getCoursePrice = async (payload: any) => {
  try {
    const payloadSerializer = { course_id: payload.id };

    const token = getAccessCookie();

    const response = await axios.post(
      `${instanceBaseURL}/prices/course/`,
      payloadSerializer,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const getLearningPathPrice = async (payload: any) => {
  try {
    const payloadSerializer = { learning_path_id: payload.id };

    const token = getAccessCookie();

    const response = await axios.post(
      `${instanceBaseURL}/prices/learning-path/`,
      payloadSerializer,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};
