/* eslint-disable complexity */
import moment from 'moment';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { RootState } from '../../../store/store';
import colors from '../../../components/atoms/Colors';
import { countries } from '../../../constants/countries';
import Select from '../../../components/molecules/Select/Select';
import PERMISSIONS from '../../../constants/permission.constants';
import IconClose from '../../../components/atoms/icons/IconClose';
import IconCalendar from '../../../components/atoms/icons/IconCalendar';
import Typography from '../../../components/atoms/Typography/Typography';
import IconArrowDown from '../../../components/atoms/icons/IconArrowDown';

export const PaymentInfo: React.FC<{
  values: any;
  handleChange: any;
  setFieldValue: any;
  teacherId?: any;
  selectPaymentCurrency: any;
  selectPaymentFrequency: any;
  errors?: any;
  touched?: any;
  isProfile?: boolean;
}> = ({
  values,
  handleChange,
  setFieldValue,
  teacherId,
  selectPaymentCurrency,
  selectPaymentFrequency,
  errors,
  touched,
  isProfile
}) => {
  const userAdmin = useSelector((state: RootState) => state.auth.user);

  const objPaymentCurrency = [
    { id: 'USD', name: 'USD' },
    { id: 'PEN', name: 'PEN' },
    { id: 'MXN', name: 'MXN' },
    { id: 'COP', name: 'COP' }
  ];

  const objPaymentrequency = [
    { id: 'mensual', name: 'Mensual' },
    { id: 'quincenal', name: 'Quincenal' }
  ];

  const {
    optionSelectionPaymentCurrency,
    isOpenSelectPaymentCurrency,
    setIsOpenSelectPaymentCurrency,
    selectOptionPaymentCurrency,
    setSelectOptionPaymentCurrency
  } = selectPaymentCurrency;

  const {
    optionSelectionPaymentFrequency,
    isOpenSelectPaymentFrequency,
    setIsOpenSelectPaymentFrequency,
    selectOptionPaymentFrequency,
    setSelectOptionPaymentFrequency
  } = selectPaymentFrequency;

  const RenderError = ({ error }: { error: any }) => {
    return (
      <Typography
        type="paragraph2"
        variation="mobile"
        className="input__alert"
        color={colors.warningColor}
      >
        {error}
      </Typography>
    );
  };

  const addPaymentFrequencySelectClass =
    selectOptionPaymentFrequency?.name || values.payment_frequency !== 'none'
      ? 'teacher-profile__select'
      : 'teacher-profile__select-placeholder';

  const addPaymentFrequencySelectErrorClass =
    errors.payment_frequency && touched.payment_frequency
      ? 'select__error'
      : '';

  const addPaymentCurrencySelectClass =
    selectOptionPaymentCurrency?.name || values.payment_currency
      ? 'teacher-profile__select'
      : 'teacher-profile__select-placeholder';

  const addPaymentCurrencySelectErrorClass =
    errors.payment_currency && touched.payment_currency ? 'select__error' : '';

  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Pagos
      </Typography>
      <br />
      <br />
      <div className="create-teacher-tabs__content-body">
        <div className="create-teacher-tabs__organization-information">
          <Typography bold type="paragraph3" variation="mobile">
            País de pago*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {countries.find(
                country => country.code === values?.payment_country
              )?.label || '-'}
            </Typography>
          ) : (
            <div>
              <Autocomplete
                fullWidth
                id="payment_country"
                options={countries}
                autoHighlight
                defaultValue={countries.find(
                  country => country.code === values?.payment_country
                )}
                onChange={(_e, value: any) =>
                  setFieldValue('payment_country', value?.code || '')
                }
                getOptionLabel={option => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code})
                  </Box>
                )}
                renderInput={(params: any) => (
                  <TextField
                    placeholder="Elige el país de pago"
                    {...params}
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                    sx={{
                      input: {
                        padding: '0 !important',
                        paddingLeft: '8px !important',
                        fontFamily: 'Sofia Pro',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px'
                      },
                      '.MuiOutlinedInput-root': { borderRadius: '8px' },
                      fieldset: {
                        border:
                          errors.payment_country && touched.payment_country
                            ? '1px solid red !important'
                            : '1px solid black !important'
                      }
                    }}
                  />
                )}
                popupIcon={<IconArrowDown />}
                clearIcon={<IconClose />}
                sx={{
                  '.MuiAutocomplete-popupIndicator[title=Open]': {
                    marginTop: '7px',
                    marginRight: '7px'
                  },
                  '.MuiAutocomplete-popupIndicator[title=Open]:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-popupIndicator[title=Close]': {
                    marginTop: '-7px',
                    marginRight: '7px'
                  },
                  '.MuiAutocomplete-popupIndicator[title=Close]:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-clearIndicator': { marginRight: '7px' },
                  '.MuiAutocomplete-clearIndicator:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-endAdornment': {
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center'
                  },
                  '.Mui-disabled': {'.MuiAutocomplete-endAdornment': {svg: { path: { fill: '#bcbcbc !important' } }}}
                }}
              />
              {errors.payment_country && touched.payment_country && (
                <RenderError error={errors.payment_country} />
              )}
            </div>
          )}

          <Typography bold type="paragraph3" variation="mobile">
            Fin del Primer Periodo de Pago*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.end_date_first_payment_period || '-'}
            </Typography>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="teacher-profile__group-errorz">
                <DatePicker
                  disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                  format="dd-MM-yyyy"
                  value={moment(values?.end_date_first_payment_period).toDate()}
                  onChange={(value: any) =>
                    setFieldValue('end_date_first_payment_period', value)
                  }
                  sx={{
                    width: '100%',
                    input: {
                      padding: 0,
                      paddingLeft: '15px',
                      height: '40px'
                    },
                    '.MuiOutlinedInput-root': { borderRadius: '8px' },
                    fieldset: {
                      border:
                        errors.end_date_first_payment_period &&
                        touched.end_date_first_payment_period
                          ? '1px solid red !important'
                          : '1px solid black !important'
                    }
                  }}
                  slots={{ openPickerIcon: IconCalendar }}
                />
                {errors.end_date_first_payment_period &&
                  touched.end_date_first_payment_period && (
                    <RenderError error={errors.end_date_first_payment_period} />
                  )}
              </div>
            </LocalizationProvider>
          )}
        </div>
        <div className="create-teacher-tabs__organization-information">
          <Typography bold type="paragraph3" variation="mobile">
            Moneda de pago*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.payment_currency || '-'}
            </Typography>
          ) : (
            <div>
              <Select
                disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                className={`${addPaymentCurrencySelectClass} ${addPaymentCurrencySelectErrorClass}`}
                setIsOpen={setIsOpenSelectPaymentCurrency}
                isOpen={isOpenSelectPaymentCurrency}
                size="default"
                dividerItems
                // alert={alertSelectPaymentCurrency}
                onclickDefaultValue={(event: any) => {
                  optionSelectionPaymentCurrency(event);
                  setFieldValue('payment_currency', event.id);
                }}
                options={objPaymentCurrency}
                children={
                  values.payment_currency
                    ? values.payment_currency === 'USD'
                      ? 'USD'
                      : values.payment_currency === 'MXN'
                        ? 'MXN'
                        : values.payment_currency === 'COP'
                          ? 'COP'
                          : 'PEN'
                    : selectOptionPaymentCurrency?.name
                      ? selectOptionPaymentCurrency?.name
                      : 'Elige la moneda de pago...'
                }
              />
              {errors.payment_currency && touched.payment_currency && (
                <RenderError error={errors.payment_currency} />
              )}
            </div>
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Periodicidad de pago*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.payment_frequency === 'mensual' ||
              values.payment_frequency === 'monthly'
                ? 'Mensual'
                : values.payment_frequency === 'quincenal'
                  ? 'Quincenal'
                  : '-'}
            </Typography>
          ) : (
            <div>
              <Select
                disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                className={`${addPaymentFrequencySelectClass} ${addPaymentFrequencySelectErrorClass}`}
                setIsOpen={setIsOpenSelectPaymentFrequency}
                isOpen={isOpenSelectPaymentFrequency}
                size="default"
                dividerItems
                onclickDefaultValue={(event: any) => {
                  optionSelectionPaymentFrequency(event);
                  setFieldValue('payment_frequency', event.id);
                }}
                options={objPaymentrequency}
                children={
                  values.payment_frequency
                    ? values.payment_frequency === 'mensual' ||
                      values.payment_frequency === 'monthly'
                      ? 'Mensual'
                      : 'Quincenal'
                    : selectOptionPaymentFrequency?.name
                      ? selectOptionPaymentFrequency?.name
                      : 'Elige la perioricidad...'
                }
              />
              {errors.payment_frequency && touched.payment_frequency && (
                <RenderError error={errors.payment_frequency} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
