// Material UI
import { Grid } from '@mui/material';

// Interfaces
import { ILearningPathProjectInfo } from '../../../interfaces/learningPaths.interface';
import LearningPathProjectDetails from './LearningPathProjectDetails';
import LearningPathProjectImages from './LearningPathProjectImages';
import LearningPathProjecsBenefits from './LearningPathProjecsBenefits';

interface Props {
  projectInfo: ILearningPathProjectInfo;
  learningPathId: number;
  refetch: any;
  refetchLearningPath: any;
}

const LearningPathProject: React.FC<Props> = ({
  projectInfo,
  learningPathId,
  refetch,
  refetchLearningPath
}) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={2} sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <LearningPathProjectDetails
          projectId={projectInfo.project_id}
          projectTitle={projectInfo.name}
          projectDescription={projectInfo.description}
          learningPathId={learningPathId}
          refetch={refetch}
          refetchLearningPath={refetchLearningPath}
        />
      </Grid>

      <Grid item xs={12}>
        <LearningPathProjecsBenefits
          benefits={projectInfo.benefits}
          learningPathId={learningPathId}
          refetch={refetch}
          refetchLearningPath={refetchLearningPath}
        />
      </Grid>

      <Grid item xs={12}>
        <LearningPathProjectImages
          sliders={projectInfo.sliders}
          learningPathId={learningPathId}
          refetch={refetch}
          refetchLearningPath={refetchLearningPath}
        />
      </Grid>
    </Grid>
  );
};

export default LearningPathProject;
