import React from 'react';

interface Props {
  fill?: string;
  className?: string;
  dataTestid?: string;
}

const IconUploadFile: React.FC<Props> = ({
  className,
  fill = '#313131',
  dataTestid = 'icon-upload-file'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 15H1.5C0.947715 15 0.5 15.4477 0.5 16C0.5 16.5523 0.947715 17 1.5 17H15.5C16.0523 17 16.5 16.5523 16.5 16C16.5 15.4477 16.0523 15 15.5 15Z"
        fill={fill}
      />
      <path
        d="M0.5 14L0.5 16C0.5 16.5523 0.947715 17 1.5 17C2.05228 17 2.5 16.5523 2.5 16V14C2.5 13.4477 2.05228 13 1.5 13C0.947715 13 0.5 13.4477 0.5 14Z"
        fill={fill}
      />
      <path
        d="M14.5 14V16C14.5 16.5523 14.9477 17 15.5 17C16.0523 17 16.5 16.5523 16.5 16V14C16.5 13.4477 16.0523 13 15.5 13C14.9477 13 14.5 13.4477 14.5 14Z"
        fill={fill}
      />
      <path
        d="M8.50005 -4.25193e-06C8.70737 -0.00157875 8.91005 0.0613228 9.08005 0.179996L13.08 3C13.2956 3.15293 13.4419 3.385 13.4869 3.64547C13.5319 3.90594 13.4719 4.17362 13.32 4.39C13.2443 4.49815 13.1478 4.59022 13.0362 4.66089C12.9247 4.73156 12.8002 4.77944 12.6701 4.80175C12.5399 4.82406 12.4066 4.82038 12.2779 4.7909C12.1491 4.76142 12.0275 4.70673 11.92 4.63L8.50005 2.24L5.10005 4.8C4.88788 4.95913 4.62118 5.02745 4.35863 4.98994C4.09608 4.95244 3.85918 4.81217 3.70005 4.6C3.54092 4.38782 3.47259 4.12112 3.5101 3.85857C3.54761 3.59602 3.68788 3.35912 3.90005 3.2L7.90005 0.199996C8.07315 0.0701733 8.28368 -4.27085e-06 8.50005 -4.25193e-06Z"
        fill={fill}
      />
      <path
        d="M8.50147 2C8.76668 2 9.02104 2.10536 9.20857 2.29289C9.39611 2.48043 9.50147 2.73478 9.50147 3L9.50146 11C9.50146 11.2652 9.39611 11.5196 9.20857 11.7071C9.02104 11.8946 8.76668 12 8.50146 12C8.23625 12 7.98189 11.8946 7.79436 11.7071C7.60682 11.5196 7.50146 11.2652 7.50146 11L7.50147 3C7.50147 2.73478 7.60682 2.48043 7.79436 2.29289C7.9819 2.10536 8.23625 2 8.50147 2Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconUploadFile;
