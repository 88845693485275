import React from 'react';

import './fileInput.scss';

interface Props {
  children: any;
  id: string;
  disabled?: boolean;
  className?: string;
  onChange?: any;
  onClick?: any;
  ref?: any;
  datatestid?: string;
}

const FileInput: React.FC<Props> = ({
  id,
  children,
  disabled = false,
  className,
  onChange,
  onClick,
  ref,
  datatestid = 'file-input-test-id'
}) => {
  return (
    <div
      data-testid={datatestid}
      className={`file-input-component ${className ? className : ''}`}
    >
      <label htmlFor={id}>{children}</label>
      <input
        data-testid={`${datatestid}-input`}
        className="file-input-component__upload"
        id={id}
        type="file"
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        ref={ref}
      />
    </div>
  );
};

export default FileInput;
