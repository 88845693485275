// React Query
import { useMutation } from 'react-query';

// API

// Yup
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

// Formik
import { Form, Formik } from 'formik';

// Material UI
import {
  Alert,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UpdateIcon from '@mui/icons-material/Update';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import bgLocale from 'date-fns/locale/bg';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { PATCH_SERVERLESS } from '../../../../services/api/api';
import { currencies } from '../../../../interfaces/currency.interface';
import { getErrorMessage } from '../../../../utils/utils';

interface UpdateInvoice {
  payment_at: Date | null;
  invoiced_at: Date | null;
  created_at: Date | null;
  coupon_code: string;
  coupon_discount: string;
  payment_gateway: 'niubiz' | 'stripe' | 'dlocal';
  source:
    | 'backoffice_form'
    | 'django_form'
    | 'enrollment_form_campaigns'
    | 'enrollment_form_courses_paths'
    | 'manual'
    | 'payment_gateway'
    | 'payment_web'
    | 'payment_inscription'
    | 'batch_load';
  is_verified: boolean;
  currency: currencies;
  amount: number;
}

interface InvoiceServerless {
  id: number;
  username: string;
  payment_at: Date | null;
  invoiced_at: Date | null;
  created_at: Date | null;
  coupon_code: string;
  coupon_discount: string;
  transaction_id: string;
  payment_gateway: 'niubiz' | 'stripe' | 'dlocal';
  source:
    | 'backoffice_form'
    | 'django_form'
    | 'enrollment_form_campaigns'
    | 'enrollment_form_courses_paths'
    | 'manual'
    | 'payment_gateway'
    | 'payment_web'
    | 'payment_inscription'
    | 'batch_load';
  is_verified: boolean;
  currency: currencies;
  amount: number;
}

interface Props {
  invoiceId: number;
  invoice: InvoiceServerless;
  refetch: () => {};
  onClose: () => void;
}

const UpdateInvoiceSchema = Yup.object().shape({
  is_verified: Yup.boolean().required('Requerido'),
  amount: Yup.number().required('Requerido'),
  source: Yup.string().required('Requerido'),
  invoiced_at: Yup.date()
    .typeError('please enter valid date')
    .required('Requerido fecha de facturación'),
  payment_at: Yup.date()
    .typeError('please enter valid date')
    .required('Requerido fecha de pago')
});

const EditInvoice: React.FC<Props> = ({
  invoiceId,
  invoice,
  refetch,
  onClose
}) => {
  const updateInvoice = useMutation(
    (data: UpdateInvoice) => PATCH_SERVERLESS(`/invoice/${invoiceId}/`, data),
    { retry: false }
  );

  const initialValues: InvoiceServerless = {
    id: invoice.id,
    username: invoice.username,
    is_verified: invoice.is_verified,
    source: invoice.source,
    amount: invoice.amount,
    currency: invoice.currency,
    payment_gateway: invoice.payment_gateway,
    invoiced_at: invoice.invoiced_at,
    payment_at: invoice.payment_at,
    coupon_code: invoice.coupon_code,
    coupon_discount: invoice.coupon_discount,
    transaction_id: invoice.transaction_id,
    created_at: invoice.created_at
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateInvoiceSchema}
      onSubmit={async values => {
        await toast.promise(
          updateInvoice.mutateAsync(values),
          {
            loading: 'Cargando...',
            success: 'Factura editada',
            error: 'Error'
          },
          { success: { icon: <UpdateIcon color="primary" /> } }
        );

        refetch();
        onClose();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowGap={2}>
            <Grid item container columnSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={1.5}
                  justifyContent="space-between"
                >
                  <h2>Transaction: {values.transaction_id}</h2>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container columnSpacing={2} rowGap={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="username"
                  label="Cliente"
                  variant="outlined"
                  value={values.username}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item container rowGap={3} xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={bgLocale}
                >
                  <DateTimePicker
                    disabled
                    label="Fecha Creación"
                    format="dd/MM/yyyy (p)"
                    value={values.created_at}
                    onChange={(value: any) =>
                      setFieldValue('created_at', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField fullWidth {...params} />
                      )
                    }}
                  />
                </LocalizationProvider>
                {errors.created_at && touched.created_at && (
                  <Alert severity="error">{errors.source}</Alert>
                )}

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={bgLocale}
                >
                  <DateTimePicker
                    label="Fecha Pago"
                    format="dd/MM/yyyy (p)"
                    value={values.payment_at}
                    onChange={(value: any) =>
                      setFieldValue('payment_at', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField fullWidth {...params} />
                      )
                    }}
                  />
                </LocalizationProvider>
                {errors.payment_at && touched.payment_at && (
                  <Alert severity="error">{errors.payment_at}</Alert>
                )}

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={bgLocale}
                >
                  <DateTimePicker
                    label="Fecha Facturación"
                    format="dd/MM/yyyy (p)"
                    value={values.invoiced_at}
                    onChange={(value: any) =>
                      setFieldValue('invoiced_at', value)
                    }
                    slots={{
                      textField: (params: any) => (
                        <TextField fullWidth {...params} />
                      )
                    }}
                  />
                </LocalizationProvider>
                {errors.invoiced_at && touched.invoiced_at && (
                  <Alert severity="error">{errors.invoiced_at}</Alert>
                )}
              </Grid>
            </Grid>
            {/* Basic Data */}
            <Grid item container columnSpacing={2} xs={12}>
              {/* Is_verified */}
              <Grid item container rowGap={3} xs={6}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    fullWidth
                    id="coupon_code"
                    label="Cupón"
                    value={values.coupon_code}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="coupon_discount"
                    label="Cupón Descuento aplicado"
                    variant="outlined"
                    type="number"
                    value={values.coupon_discount}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item container rowGap={3} xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="source">Fuente de pago</InputLabel>
                  <Select
                    autoFocus
                    id="source"
                    labelId="source"
                    value={values.source}
                    label="Fuente de pago"
                    placeholder="Fuente de pago"
                    onChange={e => setFieldValue('source', e.target.value)}
                  >
                    <MenuItem value={'undefined'}>Undefined</MenuItem>
                    <MenuItem value={'payment_web'}>Web</MenuItem>
                    <MenuItem value={'payment_inscription'}>
                      Inscripción Interna
                    </MenuItem>
                    <MenuItem value={'backoffice_form'}>BO</MenuItem>
                    <MenuItem value={'batch_load'}>Carga Lote</MenuItem>
                  </Select>
                </FormControl>
                {errors.source && touched.source && (
                  <Alert severity="error">{errors.source}</Alert>
                )}

                <FormControl fullWidth>
                  <InputLabel id="payment_gateway">Método de pago</InputLabel>
                  <Select
                    autoFocus
                    id="payment_gateway"
                    labelId="Método de pago"
                    value={values.payment_gateway}
                    label="Método de pago"
                    placeholder="Método de pago"
                    onChange={e =>
                      setFieldValue('payment_gateway', e.target.value)
                    }
                  >
                    <MenuItem value={'dlocal'}>dlocal</MenuItem>
                    <MenuItem value={'stripe'}>stripe</MenuItem>
                    <MenuItem value={'niubiz'}>niubiz</MenuItem>
                  </Select>
                </FormControl>
                {errors.payment_gateway && touched.payment_gateway && (
                  <Alert severity="error">{errors.payment_gateway}</Alert>
                )}

                <FormControl fullWidth>
                  <InputLabel id="is_verified">Pago verificado</InputLabel>
                  <Select
                    autoFocus
                    id="is_verified"
                    labelId="is_verified"
                    value={values.is_verified ? 0 : 1}
                    label="Pago verificado"
                    placeholder="Pago verificado"
                    onChange={e =>
                      setFieldValue('is_verified', e.target.value === 0)
                    }
                  >
                    <MenuItem value={0}>Verificado</MenuItem>
                    <MenuItem value={1}>Sin confirmar</MenuItem>
                  </Select>
                </FormControl>
                {errors.is_verified && touched.is_verified && (
                  <Alert severity="error">{errors.is_verified}</Alert>
                )}
              </Grid>
            </Grid>

            <Grid item container columnSpacing={2} xs={12}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="amount"
                    label="Monto"
                    variant="outlined"
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                  />
                </FormControl>
                {errors.amount && touched.amount && (
                  <Alert severity="error">
                    {getErrorMessage(errors.amount)}
                  </Alert>
                )}
              </Grid>
              {/* Source */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="currency">Divisa</InputLabel>
                  <Select
                    autoFocus
                    id="currency"
                    labelId="Divisa"
                    value={values.currency}
                    label="Divisa"
                    placeholder="Divisa"
                    onChange={e => setFieldValue('currency', e.target.value)}
                  >
                    <MenuItem value={'PEN'}>PEN</MenuItem>
                    <MenuItem value={'USD'}>USD</MenuItem>
                    <MenuItem value={'MXN'}>MXN</MenuItem>
                    <MenuItem value={'COP'}>COP</MenuItem>
                    <MenuItem value={'CRC'}>CRC</MenuItem>
                    <MenuItem value={'CLP'}>CLP</MenuItem>
                    <MenuItem value={'UYU'}>UYU</MenuItem>
                    <MenuItem value={'ARS'}>ARS</MenuItem>
                  </Select>
                </FormControl>
                {errors.currency && touched.currency && (
                  <Alert severity="error">{errors.currency}</Alert>
                )}
              </Grid>
            </Grid>

            {/* Edit Invoice */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={updateInvoice.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditInvoice;
