/* eslint-disable complexity */
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';

import {
  IRoom,
  IReasonList,
  IRoomDetailV2,
  IRoomDetailSimplified,
  IRoomDetailHeaderSimplified
} from '../interfaces/room.interface';
import { getAccessCookie } from '../helpers/cookies.helpers';
import { ICreateRoom, IGroupRooms } from '../interfaces/group.interface';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

const statusMapping: Record<string, string> = {
  pendiente: 'pending',
  completado: 'done',
  incompleto: 'incomplete',
  admin: 'complete_by_admin',
  vencida: 'expired_evaluation',
  '-': 'none'
};

export const getRooms = async (
  userId: number,
  page: number,
  size: number,
  roomId?: string,
  project?: string,
  group?: string,
  teacher?: string,
  end_date_evaluation?: string,
  status_evaluation?: string,
  sort?: string,
  direction?: string,
  fetchAll?: boolean,
  daySession?: string
) => {
  const params = new URLSearchParams();

  params.append('page', page.toString());
  params.append('size', size.toString());

  if (roomId !== undefined && roomId !== '') {
    params.append('room', roomId.toString());
  }

  if (group !== undefined && group !== '') {
    params.append('group', group.toString());
  }

  if (project !== undefined && project !== '') {
    params.append('projectId', project.toString());
    if (fetchAll !== undefined) {
      params.append('fetchAll', fetchAll.toString());
    }
  }

  if (daySession !== undefined && daySession !== '') {
    params.append('session_day', daySession.toString());
    if (fetchAll !== undefined) {
      params.append('fetchAll', fetchAll.toString());
    }
  }

  if (teacher !== undefined && teacher !== '') {
    params.append('teacher', teacher.toString());
  }

  if (end_date_evaluation !== undefined && end_date_evaluation !== '') {
    const endDate = moment(end_date_evaluation, 'DD-MM-YY').format(
      'YYYY-MM-DD'
    );
    params.append('end_date_evaluation', endDate.toString());
  }

  if (status_evaluation !== undefined && status_evaluation !== '') {
    const value = statusMapping[status_evaluation.toLowerCase()] || '';

    if (value !== '') {
      params.append('status_evaluation', value.toString());
    }
  }

  if (sort !== undefined && sort !== '') {
    params.append('sort', sort.toString());
  }

  if (direction !== undefined && direction !== '') {
    params.append('direction', direction.toString());
  }

  const url = `${instanceBaseURL}/backoffice/v2/teachers/${userId}/rooms?${params.toString()}`;

  const response = await axios.get<IRoom[]>(url, {headers: { Authorization: `Bearer ${getAccessCookie()}` }});
  return response;
};

export const getRoomsByGroupId = async (groupId: any) => {
  const response = await axios.get<IGroupRooms>(
    `${instanceBaseURL}/groups/${groupId}/rooms/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getRoomByRoomId = async (roomId: number) => {
  const response = await axios.get<IRoomDetailSimplified>(
    `${instanceBaseURL}/backoffice/v2/rooms/${roomId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getRoomHeaderDetailByRoomId = async (roomId: number) => {
  const response = await axios.get<IRoomDetailHeaderSimplified>(
    `${instanceBaseURL}/backoffice/detail/rooms/${roomId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const updateZoomLink = async (zoom_meeting_id: string) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/meeting/${zoom_meeting_id}/refresh-start/`,
    { zoom_meeting_id },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};
//? mostrar respuesta 404 no hay licencia http://localhost:3000/dashboard/groups/1719/rooms
export const removeRoom = async (roomId: any) => {
  const response = await axios.delete(
    `${instanceBaseURL}/rooms/delete/${roomId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postCreateRoom = async <T>(
  newRoom: ICreateRoom
): Promise<AxiosResponse<T>> => {
  const response: AxiosResponse = await axios.post<T>(
    `${instanceBaseURL}/rooms/`,
    newRoom,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postCreateRoomWithManualLinkZoom = async (
  newRoom: ICreateRoom
) => {
  const response = await axios.post(
    `${instanceBaseURL}/rooms/manual/`,
    newRoom,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchEditRoom = async (roomId: any, room: any) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/rooms/${roomId}/`,
    room,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const updateAttendanceDate = async (
  sessionId: number,
  start_date: string
) => {
  const response = await axios.put(
    `${instanceBaseURL}/room-sessions/${sessionId}/`,
    { start_date },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const addSesion = async (roomId: number) => {
  const data = await axios.post(
    `${instanceBaseURL}/rooms/add-session/${roomId}`,
    {},
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const disableSesion = async (sesion: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/rooms/session-state/`,
    sesion,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

//? Change Teacher Modal
export const getReasonList = async () => {
  const { data } = await axios.get<IReasonList[]>(
    `${instanceBaseURL}/activity/reason-list/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getReasonListDetail = async (reasonId: number) => {
  const { data } = await axios.get<IReasonList[]>(
    `${instanceBaseURL}/activity/reason-detail/${reasonId}`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getRoomByIdV2 = async (roomId: number) => {
  const { data } = await axios.get<IRoomDetailV2>(
    `${instanceBaseURL}/v2/rooms/${roomId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postChangeTeacherStable = async (fields: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/activity/room/change-teacher-stable/`,
    fields,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postChangeTeacherTemporal = async (fields: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/activity/room/change-teacher-temporal/`,
    fields,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postChangeTeacherTemporalRange = async (fields: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/activity/room/change-teacher-temporal-range/`,
    fields,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getTeacherList = async (fields: any) => {
  const { data } = await axios.post(
    `${instanceBaseURL}/teacher/find-available/`,
    fields,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};
