export default {
  menuRoom: 'menu_room',
  menuComment: 'menu_comment',
  menuAbsences: 'menu_absences',
  menuAttendance: 'menu_attendance',

  roomCreate: 'room_create',
  roomDelete: 'room_delete',
  roomListAll: 'room_list_all',
  roomView: 'room_view',
  newViewTeachers: 'new_view_teachers',

  attendanceCreate: 'attendance_create',
  attendanceList: 'attendance_list',
  attendanceView: 'attendance_view',

  absencesList: 'absences_list',

  commentCreate: 'comment_create'
} as const;
