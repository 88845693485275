import React, { ChangeEvent } from 'react';

import './inputScheduleAvailability.scss';

interface Props {
  isError: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  idList: string;
  datalist: string[];
}

const InputScheduleAvailability: React.FC<Props> = ({
  isError,
  onChange,
  onBlur,
  value,
  defaultValue,
  disabled = false,
  required = false,
  idList,
  datalist
}) => {
  return (
    <>
      <input
        className={`input-schedule-availability input-schedule-availability${
          isError ? '--error' : '--success'
        } input-schedule-availability--${disabled ? 'disabled' : 'active'}`}
        type="time"
        name="time-de"
        onChange={onChange}
        onBlur={onBlur}
        list={idList}
        value={value}
        defaultValue={defaultValue}
        required={required}
        disabled={!datalist || disabled}
        pattern="[9-20]{2}:[0-9]{2}"
      />
      <datalist id={idList}>
        {datalist.map((hour: string) => (
          <option value={hour} key={hour}></option>
        ))}
      </datalist>
    </>
  );
};

export default InputScheduleAvailability;
