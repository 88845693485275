import { useState } from 'react';

export const useModal = (modalDefaultState = false) => {
  const [isOpenModal, setIsOpenModal] = useState(modalDefaultState);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  return {
    openModal,
    closeModal,
    isOpenModal
  };
};
