/* eslint-disable react/display-name */
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';

import { useModal } from '../../../../hooks/useModal';
import Modal from '../../../../components/Modal/Modal';
import colors from '../../../../components/atoms/Colors';
import EditRoom from './ModalEditRoom/EditRoom/EditRoom';
import Table from '../../../../components/molecules/Table';
import PERMISSIONS from '../../../../constants/permission.constants';
import ButtonFilled from '../../../../components/molecules/ButtonFilled';
import { IRoom, ITeacher } from '../../../../interfaces/group.interface';
import FloatingMenu from '../../../../components/molecules/FloatingMenu';
import {
  AppDispatch,
  RootState,
  useAppDispatch
} from '../../../../store/store';
import ItemFloatingMenu from '../../../../components/molecules/ItemFloatingMenu';
import {
  deleteRoomById,
  getAllRooms
} from '../../../../slices/roomSlice/roomsSlice';
import { listRoomsByGroupId } from '../../../../slices/roomSlice/roomsByGroupIdSlice';
import ModalCreateRoom from '../../../../pages/Groups/ModalCreateRoom/ModalCreateRoom';
import ParagraphHideCopy from '../../../../components/common/ParagraphHideCopy/ParagraphHideCopy';
import Typography from '../../../../components/atoms/Typography/Typography';
import ModalMessageAlert from '../../../../components/molecules/ModalMessageAlert';
import ButtonLineal from '../../../../components/molecules/ButtonLineal';

interface Props {
  rooms: IRoom[];
  teachers: ITeacher[];
  groupId: number;
  refetch?: () => void;
  isFetching: boolean;
}
interface IRoomModalEdit {
  id: number | string | null;
  name: string;
  topic: string;
}

const Rooms: React.FC<Props> = ({
 rooms, teachers, groupId, isFetching 
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [idRoom, setIdRoom] = useState<null | number>();
  const [isLoadingDeleteRoom, setIsLoadingDeleteRoom] =
    useState<boolean>(false);

  const [currentInfoRoom, setCurrentInfoRoom] = useState<IRoomModalEdit>({
    id: null,
    name: '',
    topic: ''
  });

  const {
    openModal: openModalCreateRoom,
    closeModal: closeModalCreateRoom,
    isOpenModal: isOpenModalCreateRoom
  } = useModal();

  const {
    openModal: openModalEditRoom,
    closeModal: closeModalEditRoom,
    isOpenModal: isOpenModalEditRoom
  } = useModal();

  const {
    openModal: openModalDeleteRoom,
    closeModal: closeModalDeleteRoom,
    isOpenModal: isOpenModalDeleteRoom
  } = useModal();

  const handleDeleteRoom = async (
    groupId: number | string,
    roomId: number | string
  ) => {
    try {
      setIsLoadingDeleteRoom(true);
      await dispatch(deleteRoomById(Number(roomId)));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDeleteRoom(false);
      if (user) {
        const userId = user?.permission[PERMISSIONS.roomListAll] ? 0 : user?.id;
        await dispatch(getAllRooms({ userId, currentPage: 1, size: 100 }));
      }
      dispatch(listRoomsByGroupId(Number(groupId)));
    }
  };

  const customCellGoTo = (props: any) => {
    return (
      <FloatingMenu
        openDirection="right"
        items={[
          <ItemFloatingMenu key="item-floating-menu-1">
            <LinkRouter to={`/dashboard/rooms/${props.row.original.id}`}>
              Ver salón
            </LinkRouter>
          </ItemFloatingMenu>,
          <ItemFloatingMenu
            key="item-floating-menu-2"
            onClick={() => {
              setCurrentInfoRoom({
                id: props.row.original.id,
                name: props.row.original.name,
                topic: props.row.original.topic
              });
              openModalEditRoom();
            }}
          >
            Editar salón
          </ItemFloatingMenu>,
          <ItemFloatingMenu
            key="item-floating-menu-3"
            color={colors.colorAlertRed}
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              setIdRoom(props.row.original.id);
              openModalDeleteRoom();
            }}
          >
            Eliminar salón
          </ItemFloatingMenu>
        ]}
      />
    );
  };

  const customCellDefault = ({ cell }: any) => <>{cell.value || '-'}</>;

  const customCellCopyHide = ({ cell }: any) => (
    <>{<ParagraphHideCopy text={cell.value} hideable copyable /> || '-'}</>
  );

  const customCellCopyLink = ({ cell }: any) => (
    <>
      {(
        <ParagraphHideCopy
          text={cell.value}
          copyable
          type="link"
          externalLink
        />
      ) || '-'}
    </>
  );
  const customCellCopy = ({ cell }: any) => (
    <>{<ParagraphHideCopy text={cell.value} copyable /> || '-'}</>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Acciones',
        accessor: 'action',
        actionWidth: 90,
        hasTooltip: true,
        align: 'center',
        tooltipValue: 'Ir a Zoom',
        tooltipValue1: 'Ir a Wiki',
        Cell: customCellGoTo,
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: customCellDefault,
        widthColumn: 65,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Nombre del salón',
        accessor: 'name',
        classColumn: 'name-room',
        Cell: customCellDefault,
        widthColumn: 290,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Asunto',
        accessor: 'topic',
        classColumn: 'topic-room',
        widthColumn: 200,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellDefault
      },
      {
        Header: 'Profesor',
        accessor: 'teacher.full_name',
        classColumn: 'teacher-room',
        widthColumn: 250,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellDefault
      },
      {
        Header: 'Link a zoom del profesor',
        accessor: 'zoom_teacher_url',
        classColumn: 'status-evaluation',
        widthColumn: 240,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellCopyLink
      },
      {
        Header: 'Link a zoom del estudiante',
        accessor: 'zoom_url',
        classColumn: 'status-evaluation',
        widthColumn: 240,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellCopyLink
      },
      {
        Header: 'Contraseña',
        accessor: 'licensed_user_password',
        classColumn: 'end-date-evaluation',
        widthColumn: 170,
        disableSortBy: true,
        disableFilters: true,
        columnType: 'component',
        Cell: customCellCopyHide
      },
      {
        Header: 'ID Meet',
        accessor: 'zoom_id',
        classColumn: 'end-date-evaluation',
        widthColumn: 140,
        disableSortBy: true,
        disableFilters: true,
        columnType: 'component',
        Cell: customCellCopy
      },
      {
        Header: 'Contraseña meet',
        accessor: 'password',
        classColumn: 'end-date-evaluation',
        widthColumn: 170,
        disableSortBy: true,
        disableFilters: true,
        columnType: 'component',
        Cell: customCellCopyHide
      }
    ],
    []
  );

  return (
    <div>
      {/* Button create New Room */}
      <ButtonFilled fullWidth size="small" onClick={openModalCreateRoom}>
        Crear nuevo salón
      </ButtonFilled>
      <br />
      {/* Table */}
      <Table data={rooms} columns={columns} />
      {/* Modals */}
      <Modal
        id={Number(currentInfoRoom?.id)}
        title="Editar Salón"
        fullWidth
        maxWidth="sm"
        openModal={isOpenModalEditRoom}
        handleCloseModal={closeModalEditRoom}
      >
        <EditRoom
          groupId={groupId}
          roomId={Number(currentInfoRoom?.id)}
          roomName={currentInfoRoom?.name}
          roomTopic={currentInfoRoom?.topic}
          onClose={closeModalEditRoom}
        />
      </Modal>
      <ModalCreateRoom
        teachers={teachers}
        groupId={groupId}
        isOpenModal={isOpenModalCreateRoom}
        openModal={closeModalCreateRoom}
        closeModal={closeModalCreateRoom}
      />
      {idRoom ? (
        <ModalMessageAlert
          className="teacher-profile__modal-alert"
          width="sm"
          align="center"
          title={
            <Typography bold type="h1" variation="mobile" align="center">
              ¿Quieres eliminar el salón {idRoom}?
            </Typography>
          }
          isOpenModal={isOpenModalDeleteRoom}
          openModal={openModalDeleteRoom}
          closeModal={closeModalDeleteRoom}
          body={
            <Typography
              type="paragraph2"
              variation="mobile"
              color={colors.gris05}
              align="center"
            >
              Al eliminarlo no se podrá volver a recuperar la información del
              salón {idRoom}
            </Typography>
          }
          button1={
            <ButtonFilled
              fullWidth
              disabled={isLoadingDeleteRoom}
              onClick={() => handleDeleteRoom(Number(groupId), Number(idRoom))}
            >
              Eliminar
            </ButtonFilled>
          }
          button2={
            <ButtonLineal
              disabled={isLoadingDeleteRoom}
              onClick={closeModalDeleteRoom}
            >
              Cancelar
            </ButtonLineal>
          }
        />
      ) : null}
    </div>
  );
};

export default Rooms;
