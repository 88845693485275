/* eslint-disable complexity */
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import '../modalCreateProject.scss';

import colors from '../../../../components/atoms/Colors';
import Input from '../../../../components/molecules/Input';
import Typography from '../../../../components/atoms/Typography/Typography';
import SelectGeneral from '../../../../components/molecules/SelectGeneral';
import IconCalendar from '../../../../components/atoms/icons/IconCalendar';

const FirstForm: React.FC<{
  handleChange: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  alertSelect: any;
  setAlertSelect: any;
  setIsOpenSelect: any;
  isOpenSelect: any;
  optionSelection: any;
  typeName: any;
  setTypeName: any;
  organizationOptions: any;
}> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  alertSelect,
  setAlertSelect,
  setIsOpenSelect,
  isOpenSelect,
  optionSelection,
  typeName,
  setTypeName,
  organizationOptions
}) => {
  return (
    <div className="modal-create-project__project-information">
      <Typography bold type="paragraph3" variation="mobile">
        Organización*
      </Typography>
      <div className="modal-create-project__container-select">
        <SelectGeneral
          setIsOpen={setIsOpenSelect}
          isOpen={isOpenSelect}
          size="default"
          onclickDefaultValue={optionSelection}
          disabled={false}
          alert={alertSelect}
          setAlert={setAlertSelect}
          typeName={typeName}
          setTypeName={setTypeName}
          label={'Selecciona una empresa'}
          options={organizationOptions}
        />
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Nombre del <br /> proyecto*
      </Typography>
      <Input
        placeholder="Dale un nombre al proyecto"
        name="project_name"
        onChange={handleChange}
        value={values.project_name}
        required
        error={
          errors.project_name && touched.project_name ? errors.project_name : ''
        }
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography bold type="paragraph3" variation="mobile">
          Fecha de <br />
          acuerdo ganado*
        </Typography>
        <div className="modal-create-project__group-error">
          <DatePicker
            format="yyyy-MM-dd"
            value={values.start_date_agreement}
            onChange={(value: any) =>
              setFieldValue('start_date_agreement', value)
            }
            sx={{
              input: {
                padding: 0,
                paddingLeft: '15px',
                height: '40px'
              },
              '.MuiOutlinedInput-root': { borderRadius: '8px' },
              fieldset: { border: '1px solid black' }
            }}
            slots={{ openPickerIcon: IconCalendar }}
          />
          {errors.start_date_agreement && touched.start_date_agreement && (
            <Typography
              type="paragraph2"
              variation="mobile"
              className="modal-create-group__alert"
              color={colors.warningColor}
            >
              {'Ingrese una fecha válida'}
            </Typography>
          )}
        </div>
        <Typography bold type="paragraph3" variation="mobile">
          Fecha de <br />
          inicio operativo*
        </Typography>
        <div className="modal-create-project__group-error">
          <DatePicker
            format="yyyy-MM-dd"
            value={values.start_date_operative}
            onChange={(value: any) => {
              setFieldValue('start_date_operative', value);
            }}
            sx={{
              input: {
                padding: 0,
                paddingLeft: '15px',
                height: '40px'
              },
              '.MuiOutlinedInput-root': { borderRadius: '8px' },
              fieldset: { border: '1px solid black' }
            }}
            slots={{ openPickerIcon: IconCalendar }}
          />
          {errors.start_date_operative && touched.start_date_operative && (
            <Typography
              type="paragraph2"
              variation="mobile"
              className="modal-create-group__alert"
              color={colors.warningColor}
            >
              {'Ingrese una fecha válida'}
            </Typography>
          )}
        </div>
        <Typography bold type="paragraph3" variation="mobile">
          Fecha de <br />
          inicio alianza*
        </Typography>
        <div className="modal-create-project__group-error">
          <DatePicker
            format="yyyy-MM-dd"
            value={values.start_date_alliance}
            onChange={(value: any) =>
              setFieldValue('start_date_alliance', value)
            }
            sx={{
              input: {
                padding: 0,
                paddingLeft: '15px',
                height: '40px'
              },
              '.MuiOutlinedInput-root': { borderRadius: '8px' },
              fieldset: { border: '1px solid black' }
            }}
            slots={{ openPickerIcon: IconCalendar }}
          />
          {errors.start_date_alliance && touched.start_date_alliance && (
            <Typography
              type="paragraph2"
              variation="mobile"
              className="modal-create-group__alert"
              color={colors.warningColor}
            >
              {'Ingrese una fecha válida'}
            </Typography>
          )}
        </div>
        <Typography bold type="paragraph3" variation="mobile">
          Fecha de <br />
          fin operativo*
        </Typography>
        <div className="modal-create-project__group-error">
          <DatePicker
            format="yyyy-MM-dd"
            value={values.end_date_operative}
            onChange={(value: any) =>
              setFieldValue('end_date_operative', value)
            }
            sx={{
              input: {
                padding: 0,
                paddingLeft: '15px',
                height: '40px'
              },
              '.MuiOutlinedInput-root': { borderRadius: '8px' },
              fieldset: { border: '1px solid black' }
            }}
            slots={{ openPickerIcon: IconCalendar }}
          />
          {errors.end_date_operative && touched.end_date_operative && (
            <Typography
              type="paragraph2"
              variation="mobile"
              className="modal-create-group__alert"
              color={colors.warningColor}
            >
              {'Ingrese una fecha válida'}
            </Typography>
          )}
        </div>
        <Typography bold type="paragraph3" variation="mobile">
          Fecha de <br />
          fin alianza*
        </Typography>
        <div className="modal-create-project__group-error">
          <DatePicker
            format="yyyy-MM-dd"
            value={values.end_date_alliance}
            onChange={(value: any) => setFieldValue('end_date_alliance', value)}
            sx={{
              input: {
                padding: 0,
                paddingLeft: '15px',
                height: '40px'
              },
              '.MuiOutlinedInput-root': { borderRadius: '8px' },
              fieldset: { border: '1px solid black' }
            }}
            slots={{ openPickerIcon: IconCalendar }}
          />
          {errors.end_date_alliance && touched.end_date_alliance && (
            <Typography
              type="paragraph2"
              variation="mobile"
              className="modal-create-group__alert"
              color={colors.warningColor}
            >
              {'Ingrese una fecha válida'}
            </Typography>
          )}
        </div>
      </LocalizationProvider>
      <Typography bold type="paragraph3" variation="mobile">
        Owner academico*
      </Typography>
      <Input
        placeholder="Nombre y apellido"
        name="academic_owner"
        onChange={handleChange}
        value={values.academic_owner}
        required
        error={
          errors.academic_owner && touched.academic_owner
            ? errors.academic_owner
            : ''
        }
      />
      <Typography bold type="paragraph3" variation="mobile">
        Owner operativo*
      </Typography>
      <Input
        placeholder="Nombre y apellido"
        name="operative_owner"
        onChange={handleChange}
        value={values.operative_owner}
        required
        error={
          errors.operative_owner && touched.operative_owner
            ? errors.operative_owner
            : ''
        }
      />
      <Typography bold type="paragraph3" variation="mobile">
        Vacantes vendidas
      </Typography>
      <div className="modal-create-project__small-input">
        <Input
          placeholder="-"
          name="seat_sold"
          type="number"
          onChange={handleChange}
          value={values.seat_sold}
          required
          error={errors.seat_sold && touched.seat_sold ? errors.seat_sold : ''}
        />
      </div>
      <Typography bold type="paragraph3" variation="mobile">
        Monto USD*
      </Typography>
      <div className="modal-create-project__small-input">
        <Input
          placeholder="-"
          name="amount_usd"
          type="number"
          onChange={handleChange}
          value={values.amount_usd}
          required
          error={
            errors.amount_usd && touched.amount_usd ? errors.amount_usd : ''
          }
        />
      </div>
    </div>
  );
};

export default FirstForm;
