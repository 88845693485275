// React
import { useState } from 'react';

// Components
import CreateSlider from './CreateSlider/CreateSlider';
import OpenModal from '../../../components/Modal/OpenModal/OpenModal';
import Modal from '../../../components/Modal/Modal';

interface Props {
  refetch: () => {};
}

const ModalCreateSlider: React.FC<Props> = ({ refetch }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal
        option="create"
        title="Crear nuevo slider"
        handleOpenModal={handleOpenModal}
      />

      {/* Modal Create Group */}
      <Modal
        title="Crear Slider"
        fullWidth
        maxWidth="md"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <CreateSlider refetch={refetch} onClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default ModalCreateSlider;
