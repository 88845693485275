import React from 'react';
import { ColumnInstance } from 'react-table';

import IconSort from '../../atoms/icons/IconSort';
import IconSearch from '../../atoms/icons/IconSearch';
import IconResetFilter from '../../atoms/icons/IconResetFilter';

interface IProps {
  column: ColumnInstance;
  isVisible: string | undefined;
  setIsVisible: (e: any) => void;
}

const renderSortIcon = ({ column }: { column: ColumnInstance }) => {
  if (column.isSorted) {
    return column.isSortedDesc ? (
      <IconSort top="#313131" bottom="#BCBCBC" />
    ) : (
      <IconSort top="#BCBCBC" bottom="#313131" />
    );
  }
  return <IconSort top="#313131" bottom="#313131" />;
};

export const RenderColumnActions: React.FC<IProps> = ({
  column,
  isVisible,
  setIsVisible
}) => {
  return (
    <section className="table__column-actions">
      {column.canSort ? (
        <div
          className="table__button-sort"
          {...column.getHeaderProps(column.getSortByToggleProps())}
        >
          {renderSortIcon({ column })}
        </div>
      ) : (
        <></>
      )}
      {column.canFilter ? (
        <div className="table__input-filter">
          {column.filterValue ? (
            <button
              className="table__button-filter"
              onClick={() => column.setFilter(undefined)}
            >
              <IconResetFilter />
            </button>
          ) : (
            <button
              className="table__button-filter"
              onClick={() => setIsVisible(column.id)}
            >
              <IconSearch />
            </button>
          )}
          {column.id === isVisible ? <>{column.render('Filter')}</> : ''}
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};
