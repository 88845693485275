// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';
import { IGroupDetailPag } from '../interfaces/group.interface';
import { ILearningPath } from '../interfaces/learningPaths.interface';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? LEARNINGS
export const getLearningsForGroups = async () => {
  const response = await axios.get<IGroupDetailPag[]>(
    `${instanceBaseURL}/learning_paths_backoffice_groups/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// get information individual learning path by ID
export const getInfoByLearningPathId = async (idCourse: number) => {
  const response = await axios.get<ILearningPath>(
    `${instanceBaseURL}/learning_paths_backoffice/${idCourse}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// Post project title and project description by learningPathId
export const postProjectInfoBylearningPathId = async (
  name: string,
  description: string,
  learningPathId: number
) => {
  const response = await axios.post(
    `${instanceBaseURL}/learning_path_final_project/`,
    { name: name, description: description, path: learningPathId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// patch project title and project description by projectId
export const patchProjectInfoByLearning = async (
  name: string,
  description: string,
  projectId: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/learning_path_final_project/${projectId}/`,
    { name: name, description: description },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// post project benefits
export const postProjectBenefitsByLearningPath = async (
  benefit: string,
  learningPathId: number
) => {
  const response = await axios.post(
    `${instanceBaseURL}/learning_path_final_project_benefits/`,
    { benefit: benefit, learning_path: learningPathId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// patch project benefits
export const patchProjectBenefitsByBenefitId = async (
  benefit: string,
  learningPathId: number,
  benefitId: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/learning_path_final_project_benefits/${benefitId}/`,
    { benefit: benefit, learning_path: learningPathId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

// delete project benefits
export const deleteProjectBenefitsByBenefitId = async (benefitId: number) => {
  const response = await axios.delete(
    `${instanceBaseURL}/learning_path_final_project_benefits/${benefitId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const postProjectImagesByLearningPath = async (data: any) => {
  const response = await axios.post(
    `${instanceBaseURL}/learning_path_final_project_sliders/`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchProjectImagesBySliderId = async (
  data: any,
  sliderId: number
) => {
  const response = await axios.patch(
    `${instanceBaseURL}/learning_path_final_project_sliders/${sliderId}/`,
    data,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const deleteProjectImagesBySliderId = async (sliderId: number) => {
  const response = await axios.delete(
    `${instanceBaseURL}/learning_path_final_project_sliders/${sliderId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};
