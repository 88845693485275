import { useEffect, useState } from 'react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { IGroupRooms, IStudent } from '../../../../interfaces/group.interface';
import CertificateDesign from '../../../../components/organism/CertificateDesign';
import CertificateSending from '../../../../components/organism/CertificateSending';
import { getCetificateLinks } from '../../../../services/groups';

interface Props {
  rooms: IGroupRooms;
  students: IStudent[];
}

const Certificates: React.FC<Props> = ({ rooms, students }) => {
  const baseUrlBackground = '/multimedia/images/template_certificate/';

  const [checkedStudents, setCheckedStudents] = useState<any[]>([]);
  const [hasSavedCertificate, setHasSavedCertificate] = useState(true);
  const [initialValues, setInitialValues] = useState<any>();
  const [selectedBackground, setSelectedBackground] = useState<any>(null);
  const [hasChangedInitialValues, setHasChangedInitialValues] =
    useState<boolean>(false);
  const [courseName, setCourseName] = useState(rooms.course.name || '');
  const [startDate, setStartDate] = useState<string>(
    rooms.start_date.toString() || ''
  );
  const [endDate, setEndDate] = useState(rooms.end_date || '');
  const [colorName, setColorName] = useState<string>('#000000');
  const [linkDrive, setLinkDrive] = useState<string>('');
  const [hasCreatedCertificate, setHasCreatedCertificate] =
    useState<boolean>(false);

  useEffect(() => {
    getGroupCertificates();
  }, []);

  const getGroupCertificates = async () => {
    try {
      const { data } = await getCetificateLinks(rooms.id);
      setLinkDrive(data.drive_certificates_url);
      setSelectedBackground({
        file: null,
        name: data?.background?.split(baseUrlBackground)[1] || '',
        url: data.background || '',
        base64: data.background_base64 ? data.background_base64 : '',
        type: data.background_type ? data.background_type : ''
      });
      setCourseName(data.name ? data.name : rooms.course.name);
      setStartDate(data.start_date ? data.start_date : rooms.start_date);
      setEndDate(data.end_date ? data.end_date : rooms.end_date);
      setColorName(data.color ? data.color : '#000000');
      setHasCreatedCertificate(data.background ? true : false);
      setInitialValues({
        backgroundName: data?.background?.split(baseUrlBackground)[1] || '',
        backgroundUrl: data.background || '',
        courseName: data.name ? data.name : rooms.course.name,
        startDate: data.start_date ? data.start_date : rooms.start_date,
        endDate: data.end_date ? data.end_date : rooms.end_date,
        colorName: data.color ? `${data.color}` : '#000000',
        backgroundBase64: data.background_base64 ? data.background_base64 : '',
        backgroundType: data.background_type ? data.background_type : ''
      });
      setHasChangedInitialValues(false);
    } catch (error) {
      console.log('error: ', error);
      setInitialValues({
        backgroundName: '',
        backgroundUrl: '',
        colorName: '#000000',
        ...initialValues
      });
      setHasChangedInitialValues(false);
    }
  };

  if (!rooms || !students) return <ScreenLoader />;

  return (
    <>
      <CertificateDesign
        rooms={rooms}
        setHasSavedCertificate={setHasSavedCertificate}
        setCheckedStudents={setCheckedStudents}
        hasChangedInitialValues={hasChangedInitialValues}
        setHasChangedInitialValues={setHasChangedInitialValues}
        initialValues={initialValues}
        selectedBackground={selectedBackground}
        setSelectedBackground={setSelectedBackground}
        getGroupCertificates={getGroupCertificates}
        startDate={startDate}
        endDate={endDate}
        courseName={courseName}
        colorName={colorName}
        setColorName={setColorName}
        setCourseName={setCourseName}
        hasCreatedCertificate={hasCreatedCertificate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      <CertificateSending
        rooms={rooms.rooms}
        course={rooms.course.name}
        students={students}
        hasSavedCertificate={hasSavedCertificate}
        checkedStudents={checkedStudents}
        setCheckedStudents={setCheckedStudents}
        initialValues={initialValues}
        selectedBackground={selectedBackground}
        linkDrive={linkDrive}
        getGroupCertificates={getGroupCertificates}
      />
    </>
  );
};

export default Certificates;
