import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { postBulkEnrollment } from '../../services/bulkEnrollment';

interface IAuthError {
  msg: string;
}

export const addStudents = createAsyncThunk(
  'bulk/addStudents',
  async (
    response: {},
    {
 getState, rejectWithValue, dispatch, ...others 
}: any
  ) => {
    try {
      return await postBulkEnrollment(response);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response);
    }
  }
);

const initialState: {
  bulk: any[];
  isLoading: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
  totalPages: number;
  totalCount: number;
} = {
  bulk: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false,
  totalPages: 0,
  totalCount: 0
};

export const bulkEnrollmentSlice = createSlice({
  name: 'bulk',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** addStudents */
    builder.addCase(addStudents.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(addStudents.fulfilled, (state, action) => {
      state?.bulk?.push(action.payload.data);
      state.isLoading = false;
      state.isCompleted = true;
      state.error = null;
    });
    builder.addCase(addStudents.rejected, (state, action) => {
      state.error = { msg: (action.payload as any)?.data?.results_fail };
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateBulkEnrollment = (state: any) => state.bulk;
