// Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import { AxiosError } from 'axios';

// Interfaces
import { IGroupDetailPag } from '../../interfaces/group.interface';

// Services
import { getLearningsForGroups } from '../../services/learnings';

export interface IAuthError {
  msg: string;
}

export interface ILearningState {
  learnings: IGroupDetailPag[];
  isLoading: boolean;
  isCompleted: boolean;
  error: IAuthError | null;
  rejected: boolean;
}

const initialState: ILearningState = {
  learnings: [],
  isLoading: false,
  isCompleted: false,
  error: null,
  rejected: false
};

export const getAllLearnings = createAsyncThunk(
  'learnings/getAllLearnings',
  async (_, { getState, rejectWithValue }: any) => {
    try {
      /* const { auth: user } = getState() as RootState; */
      const response = await getLearningsForGroups();
      return response.data;
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const learningsSlice = createSlice({
  name: 'learnings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** getAllLearnings */
    builder.addCase(getAllLearnings.pending, (state, _) => {
      state.learnings = initialState.learnings;
      state.isLoading = true;
    });
    builder.addCase(getAllLearnings.fulfilled, (state, action) => {
      state.learnings = action.payload;
      state.learnings = [
        ...action.payload,
        { id: 0, name: 'Cursos Individuales', courses: [] }
      ];
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getAllLearnings.rejected, (state, _) => {
      state.isLoading = initialState.isLoading;
      state.rejected = true;
    });
  }
});

export const selectStateLearnings = (state: any) => state.learnings;
