import { Cell } from 'react-table';

export const getCellClassName = ({ cell }: { cell: Cell }) => {
  const classNames = ['table__td'];

  if (cell.column.isSorted) {
    classNames.push('table__td--sorted');
  }
  if (cell.column.filterValue) {
    classNames.push('table__td--filtered');
  }
  if (cell.column.classColumn) {
    classNames.push(`table__td--${cell.column.classColumn}`);
  }

  return classNames.join(' ');
};

export const getContentTdClassName = ({ cell }: { cell: Cell }) => {
  const classNames = ['table__content-td'];

  switch (cell.column.align) {
    case 'center':
      classNames.push('table__content-td--center');
      break;
    case 'right':
      classNames.push('table__content-td--right');
      break;
    default:
      classNames.push('table__content-td--left');
      break;
  }

  if (cell.column.id === 'action') {
    classNames.push('table__content-td--action');
  }

  return classNames.join(' ');
};
