import React from 'react';

import './selectOptions.scss';

interface Props {
  size: any;
  isScroll?: boolean;
  options: any;
  className?: string;
  itemsDropdown?: number;
  dataTestid?: string;
  noOptionsText?: string;
  addIsScroll?: string;
}

const SelectOptions: React.FC<Props> = ({
  options,
  size,
  isScroll,
  addIsScroll = 'isScroll',
  className,
  itemsDropdown = 8,
  dataTestid = 'select-options-test-id',
  noOptionsText = 'Se encontraron 0 coincidencias'
}) => {
  return (
    <>
      <div
        data-testid={dataTestid}
        className={`select-options__modal select-options__modal--${size} ${
          isScroll
            ? `select-options__modal--${addIsScroll} select-options__modal-qty-items${itemsDropdown}`
            : ''
        }  ${className ? className : ''}`}
      >
        <>
          {options && options.length > 0 ? (
            <ul className={`select-options__modal-content`}>
              {options?.map((option: any) => option)}
            </ul>
          ) : (
            <ul className="select-options__sin-contenido">{noOptionsText}</ul>
          )}
        </>
      </div>
    </>
  );
};

export default SelectOptions;
