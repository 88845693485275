/* eslint-disable complexity */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { matchSorter } from 'match-sorter';
import { useTable, useSortBy, useFilters } from 'react-table';

import './table.scss';
import FooterPagination from '../FooterPagination';
import { RenderTableCell } from './RenderTableCell';
import Typography from '../../atoms/Typography/Typography';
import { RenderColumnActions } from './RenderColumnActions';
import IconResetFilter from '../../atoms/icons/IconResetFilter';

interface Props {
  data: any;
  columns: any;
  title?: string;
  filterTag?: any;
  paginationState?: any;
  childSectionHeader?: any;
  hidePagination?: boolean;
}

type FilterState = {
  [key: string]: string;
};

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, {keys: [(row: any) => row.values[id]]});
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

const Table: React.FC<Props> = ({
  title,
  data,
  columns,
  filterTag,
  paginationState,
  childSectionHeader,
  hidePagination
}) => {
  const [isVisible, setIsVisible] = useState('');

  //TODO: To be removed when all the Tables use paginationState. Keeping it now to make both models to work independently.
  const [internalCurrentPage, setInternalCurrentPage] = useState(
    paginationState ? paginationState.currentPage : 1
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  // Define a default UI for filtering
  function DefaultColumnFilterContainer({ column }: any) {
    return (
      <>
        <section className="container-filter">
          {column.filterFields
            ? column.filterFields.map((filterField: string) => {
                return DefaultColumnFilter({ column }, filterField);
              })
            : DefaultColumnFilter({ column }, '')}
          <div
            className="container-filter__button-close"
            onClick={() => setIsVisible('')}
          >
            <IconResetFilter />
          </div>
        </section>
      </>
    );
  }

  function DefaultColumnFilter({ column }: any, filterField: any) {
    //const count = column.preFilteredRows.length;

    const columnId =
      column.id + (filterField.key ? '__' + filterField.key : '');
    const columnLabel = filterField.label
      ? filterField.label
      : column.Header.toLowerCase
        ? column.Header.toLowerCase()
        : '';
    const [customFiltering, setCustomFiltering] = useState('');

    const placeholder = column.placeholder
      ? column.placeholder
      : `Buscar por ${columnLabel}`;

    return (
      <input
        className="container-filter__input"
        value={paginationState ? customFiltering : column.filterValue}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            setIsVisible('');
            const newState: FilterState = {};
            newState[columnId] = customFiltering;

            if (paginationState) {
              if (paginationState.setLocalCurrentFilters) {
                paginationState.setLocalCurrentFilters([
                  ...paginationState?.localCurrentFilters,
                  newState
                ]);
              }
              paginationState.currentFiltersChangeHandler(newState);
            }
          }
        }}
        onChange={e => {
          setCustomFiltering(e.target.value);
          if (!paginationState) {
            column.setFilter(e.target.value);
          }
        }}
        placeholder={placeholder}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilterContainer
    }),
    []
  );

  const {
 getTableProps, getTableBodyProps, headerGroups, rows, prepareRow 
} =
    useTable(
      {
        columns,
        data,
        defaultColumn // Be sure to pass the defaultColumn option
      },
      useFilters, // useFilters!
      useSortBy
    );

  const currentPage = internalCurrentPage;
  const pageSize = paginationState ? paginationState.pageSize : 100;
  const pageChangeHandler = (page: number) => {
    setInternalCurrentPage(page);
    if (paginationState) {
      paginationState.pageChangeHandler(page);
    }
  };

  const totalRows = paginationState
    ? paginationState.totalRows
    : rows
      ? rows.length
      : 0;
  const totalPages = paginationState
    ? paginationState.totalPages
    : Math.floor(rows.length / pageSize) + 1;

  const startIndex = currentPage > 1 ? (currentPage - 1) * pageSize : 0;
  const endIndex = startIndex + pageSize;
  const pageRows = paginationState ? rows : rows.slice(startIndex, endIndex);

  const columns_len = columns.length;

  const getWidthStyle: any = ({ column }: { column: any }) => {
    if (column.id === 'action') {
      return { width: column.actionWidth };
    }
    return { width: column.widthColumn ? column.widthColumn : 'auto' };
  };

  const getAlignClass = ({ align }: { align: any }) => {
    switch (align) {
      case 'center':
        return 'table__content-th--center';
      case 'right':
        return 'table__content-th--right';
      default:
        return 'table__content-th--left';
    }
  };

  return (
    <>
      {!hidePagination && (
        <div className="table__footer">
          {childSectionHeader}
          <FooterPagination
            currentPage={currentPage}
            pageSize={pageSize}
            rowsInPage={pageRows.length}
            totalPages={totalPages}
            totalRows={totalRows}
            paginate={pageChangeHandler}
          />
        </div>
      )}
      {title && (
        <Typography type="paragraph1" variation="desktop" bold>
          {title}
        </Typography>
      )}
      <div className={`table ${totalPages > 1 ? '' : 'table--padding'}`}>
        {/* FILTERS */}
        <div className="table table--filter-section">
          {filterTag ? (
            <>
              <Typography type="paragraph2" variation="mobile" bold>
                Filtro Actual:{' '}
              </Typography>

              {filterTag}
            </>
          ) : null}
        </div>

        <table className="table__container" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr
                key={index}
                className="table__head-row"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any, index: number) => {
                  if (column.hideColumn) {
                    return;
                  }
                  return (
                    <th
                      key={index}
                      className={`table__th ${
                        column.isSorted
                          ? 'table__th--sorted'
                          : 'table__th--notsorted'
                      }`}
                      style={{ width: getWidthStyle({ column }) }}
                    >
                      <div
                        className={`table__content-th ${getAlignClass({ align: column.align })}`}
                      >
                        {
                          <p style={{ textAlign: column.align || 'left' }}>
                            {column.render('Header')}
                          </p>
                        }
                        {!column.disableFilters || !column.disableSortBy ? (
                          <RenderColumnActions
                            column={column}
                            setIsVisible={setIsVisible}
                            isVisible={isVisible}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {rows.length > 0 && (
            <tbody className="table__tbody" {...getTableBodyProps()}>
              {pageRows.map((row: any, index: number) => {
                prepareRow(row);
                return (
                  <>
                    <tr
                      key={index}
                      className="table__body-row"
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell: any, index: number) => {
                        return <RenderTableCell key={index} cell={cell} />;
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default Table;
