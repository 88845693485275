import { toast } from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';

import './modalAddMultipleOptions.scss';

import Modal from '../../atoms/Modal';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonOutline from '../../molecules/ButtonOutline';
import Typography from '../../atoms/Typography/Typography';
import IconClose from '../../../components/atoms/icons/IconClose';
import IconArrowDown from '../../../components/atoms/icons/IconArrowDown';

interface Props {
  id?: string;
  title?: string;
  subtitle?: string;
  label?: string;
  placeholder: string;
  isOpen: boolean;
  closeModal: () => void;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  etario: any[];
  teacherId?: number;
  setTeacherProfile?: any;
  flagUpdate?: boolean;
  setflagUpdate: Function;
  setOptions: any;
  options?: any;
  showIdOption?: any;
}

const ModalAddMultipleOptions: React.FC<Props> = ({
  id = 'option-field-id',
  title,
  subtitle,
  label,
  placeholder,
  isOpen,
  closeModal,
  width,
  etario,
  teacherId,
  setTeacherProfile,
  flagUpdate = false,
  setflagUpdate,
  setOptions,
  options,
  showIdOption
}) => {
  const [isOpenSelectEtario, setIsOpenSelectEtario] = useState(false);

  const [skillToFilter1, setSkillToFilter1] = useState<any>('');
  const [etarioTeacher, setEtarioTeacher] = useState<any>();

  const handleClickValueSelected1 = (option: any): void => {
    setSkillToFilter1(option);
  };

  const handleCloseModal = () => {
    closeModal();
    setSkillToFilter1('');
  };

  const handleSaveModal = () => {
    const optionFilter = options.filter(
      (option: any) => option.name === skillToFilter1.name
    );

    if (optionFilter.length > 0) {
      toast.error('La opción ya fue agregada');
      return;
    }

    if (skillToFilter1 && skillToFilter1.name !== '') {
      save();
      closeModal();
      setSkillToFilter1('');
    } else {
      toast.error('Debe completar el grupo etario para continuar');
    }
  };

  const save = async () => {
    setOptions((prev: any) => [...prev, skillToFilter1]);
  };

  useEffect(() => {
    setEtarioTeacher(
      etario
        ?.sort((a, b) => a.id - b.id)
        ?.map((et: any, ind: any) => ({
          id: et.id,
          name: et.name
        }))
    );
  }, [etario]);

  const RenderSubtitle = () => {
    if (!subtitle) return <div />;

    return (
      <Typography type="paragraph2" variation="mobile">
        {subtitle}
      </Typography>
    );
  };

  return (
    <>
      <Modal
        className="modal-add-multiple-options"
        width={width}
        isOpen={isOpen}
        closeModal={closeModal}
        title={
          <Typography bold type="paragraph1" variation="desktop">
            {title}
          </Typography>
        }
        subtitle={<RenderSubtitle />}
        body={
          <div className="modal-add-multiple-options__container-body">
            <section>
              <Typography
                className="modal-add-multiple-options__label-habilities"
                bold
                type="paragraph2"
                variation="mobile"
              >
                {label}
              </Typography>
            </section>
            <section className="modal-add-multiple-options__habilities">
              <Autocomplete
                fullWidth
                id={id}
                key={`${id}-${options?.length}`}
                clearOnBlur={true}
                clearOnEscape={true}
                options={etarioTeacher}
                autoHighlight
                onChange={(_e, value: any) => handleClickValueSelected1(value)}
                getOptionLabel={(option: any) => option?.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      '& > img': { mr: 2, flexShrink: 0 },
                      borderBottom: '1px solid rgb(229, 229, 229)',
                      fontFamily: 'Sofia Pro',
                      fontStyle: 'normal',
                      fontSize: '15px'
                    }}
                    {...props}
                  >
                    {showIdOption ? `${option?.id} | ` : ''}
                    {option?.name}
                  </Box>
                )}
                renderInput={(params: any) => {
                  return (
                    <TextField
                      itemID={id}
                      placeholder={placeholder}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: id // disable autocomplete and autofill
                      }}
                      sx={{
                        input: {
                          padding: '0 !important',
                          paddingLeft: '8px !important',
                          fontFamily: 'Sofia Pro',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px'
                        },
                        '.MuiOutlinedInput-root': { borderRadius: '8px' },
                        fieldset: { border: '1px solid black !important' }
                      }}
                    />
                  );
                }}
                popupIcon={<IconArrowDown />}
                clearIcon={<IconClose />}
                sx={{
                  '.MuiAutocomplete-popupIndicator[title=Open]': {
                    marginTop: '7px',
                    marginRight: '7px'
                  },
                  '.MuiAutocomplete-popupIndicator[title=Open]:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-popupIndicator[title=Close]': {
                    marginTop: '-7px',
                    marginRight: '7px'
                  },
                  '.MuiAutocomplete-popupIndicator[title=Close]:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-clearIndicator': { marginRight: '7px' },
                  '.MuiAutocomplete-clearIndicator:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-endAdornment': {
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center'
                  },
                  '.Mui-disabled': {'.MuiAutocomplete-endAdornment': {svg: { path: { fill: '#bcbcbc !important' } }}}
                }}
              />
            </section>
          </div>
        }
        buttons={
          <>
            <ButtonOutline
              size="default"
              width="8rem"
              onClick={handleCloseModal}
            >
              Cancelar
            </ButtonOutline>
            <ButtonFilled size="default" width="8rem" onClick={handleSaveModal}>
              Añadir
            </ButtonFilled>
          </>
        }
      />
    </>
  );
};

export default ModalAddMultipleOptions;
