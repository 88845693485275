import React from 'react';

// Components
import Modal from '../../atoms/Modal';
import Typography from '../../atoms/Typography/Typography';

import './modalPreview.scss';

interface Props {
  title: any;
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  button1?: any;
  button2?: any;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  body?: any;
  message?: string;
  className?: string;
  iconAlert?: any;
  align?: 'center' | 'left';
}

const ModalPreview: React.FC<Props> = ({
  title,
  isOpenModal,
  openModal,
  closeModal,
  className = '',
  width,
  body,
  message,
  button1,
  button2,
  iconAlert,
  align = 'left'
}) => {
  return (
    <div>
      <Modal
        className={`modal-preview ${className}`}
        width={width}
        isOpen={isOpenModal}
        closeModal={closeModal}
        icon={iconAlert}
        title={
          <Typography align={align} type="h2" variation="mobile" bold>
            {title}
          </Typography>
        }
        align={align}
        body={
          body ||
          (message && (
            <div className="modal-preview__content-body">
              <Typography type="paragraph2" variation="mobile" align={align}>
                {message}
              </Typography>
            </div>
          ))
        }
        buttons={
          <section
            className={`modal-preview__container-buttons modal-preview__container-buttons--${
              button2 ? 'end' : 'center'
            }`}
          >
            {button1} {button2}
          </section>
        }
      />
    </div>
  );
};

export default ModalPreview;
