import colors from '../../atoms/Colors';

import './itemFloatingMenu.scss';

interface Props {
  children: any;
  onClick?: any;
  disabled?: boolean;
  dividerItems?: boolean;
  dataTestid?: string;
  color?: string;
}

const ItemFloatingMenu: React.FC<Props> = ({
  children,
  onClick,
  disabled = false,
  dividerItems = false,
  dataTestid = 'Item-floating-menu-test-id',
  color = colors.gris04
}) => {
  return (
    <li
      data-testid={dataTestid}
      className={`item-floating-menu item-floating-menu--${
        disabled ? 'disabled' : 'active'
      }`}
      onClick={disabled ? () => {} : onClick}
      style={{
        borderBottom: dividerItems ? `1px solid ${colors.gris02}` : 'none',
        color: color
      }}
    >
      {children}
    </li>
  );
};

export default ItemFloatingMenu;
