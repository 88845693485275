//React
import { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { RootState } from '../../store/store';

// Components
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import DiscountsLayout from '../../components/templates/DiscountsLayout/DiscountsLayout';
import TableDiscounts from '../../components/organism/TableDiscounts/TableDiscounts';
import ButtonFilled from '../../components/molecules/ButtonFilled/ButtonFilled';
import { GET } from '../../services/api/api';

function Discounts() {
  const user = useSelector((state: RootState) => state.auth.user);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [flagCreate, setFlagCreate] = useState(false);

  const {
    data: discounts,
    error,
    isError,
    isLoading,
    isFetching,
    refetch
  } = useQuery([], () => GET<any>(`/discount-products/`), {
    retry: false,
    refetchOnWindowFocus: false
  });

  if (!discounts || isLoading) return <ScreenLoader />;

  return (
    <DiscountsLayout
      buttonAdd={
        <ButtonFilled
          fullWidth
          onClick={() => {
            setOpenModalCreate(true);
            setFlagCreate(true);
          }}
          className={'groups-layout__button-add-group'}
        >
          {'+ Crear nuevo descuento'}
        </ButtonFilled>
      }
      table={
        <TableDiscounts
          flagCreate={flagCreate}
          setFlagCreate={setFlagCreate}
          discounts={discounts?.response?.discounts}
          courses={discounts?.response?.courses_web}
          paths={discounts?.response?.learning_paths_web}
          openModalCreate={openModalCreate}
          setOpenModalCreate={setOpenModalCreate}
          refetch={refetch}
        />
      }
    />
  );
}

export default Discounts;
