// React
import { useMemo, useState } from 'react';

// Moment
import moment from 'moment';
import 'moment/locale/es';

// Hot Toast
import { toast } from 'react-hot-toast';

//Hooks
import { useModal } from '../../../hooks/useModal';

//Services
import { deleteDiscounts } from '../../../services/discounts';

//Axios

//Components
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import Table from '../../molecules/Table';
import Typography from '../../atoms/Typography/Typography';
import FloatingMenu from '../../molecules/FloatingMenu';
import ItemFloatingMenu from '../../molecules/ItemFloatingMenu';
import ModalMessageAlert from '../../molecules/ModalMessageAlert/ModalMessageAlert';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';
import ModalCreateDiscount from '../../../pages/Discounts/ModalCreateDiscount/ModalCreateDiscount';
import IconDelete from '../../atoms/icons/IconDelete';
import ButtonOutline from '../../molecules/ButtonOutline/ButtonOutline';
import colors from '../../atoms/Colors';

import './tableDiscounts.scss';

interface Props {
  flagCreate: boolean;
  setFlagCreate: Function;
  discounts: any;
  courses: any;
  paths: any;
  openModalCreate: any;
  setOpenModalCreate: any;
  refetch: () => {};
}

const TableDiscounts: React.FC<Props> = ({
  flagCreate,
  setFlagCreate,
  discounts,
  courses,
  paths,
  openModalCreate,
  setOpenModalCreate,
  refetch
}) => {
  const {
    openModal: openModalCreateDiscount,
    closeModal: closeModalCreateDiscount,
    isOpenModal: isOpenModalCreateDiscount
  } = useModal();

  const {
    openModal: openModalAlertDeleteDiscount,
    closeModal: closeModalAlertDeleteDiscount,
    isOpenModal: isOpenModalAlertDeleteDiscount
  } = useModal();

  const [discountById, setDiscountById] = useState<any>();
  const [isLoadingDeleteDiscount, setIsLoadingDeleteDiscount] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const deleteDiscount = async (id: any) => {
    try {
      setIsLoadingDeleteDiscount(true);
      const response = await deleteDiscounts(id);
      if (response?.status === 200) {
        setIsLoadingDeleteDiscount(false);
        toast.success('¡Se elimino correctamente es descuento!');
      } else {
        toast.error('No se pudo eliminar el descuento');
      }
    } catch (error) {
      setIsLoadingDeleteDiscount(false);
      toast.error('Ha ocurrido un error al eliminar el descuento');
    } finally {
      refetch();
    }
  };

  const handleDeleteDiscount = (id: any) => {
    deleteDiscount(id);
    closeModalAlertDeleteDiscount();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        minWidthColumn: 42,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => <>{cell.value}</>
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        classColumn: 'name-discount',
        Cell: ({ cell }: any) => <>{cell.value}</>,
        hasTooltip: true,
        disableSortBy: true
      },
      {
        Header: 'Paises',
        classColumn: 'products_discount',
        hasTooltip: true,
        disableSortBy: true,
        Cell: (props: any) => {
          const newCountries: any = [];
          let countries = props.row.original.products_discount;
          const hashCountries: any = {};
          countries = countries?.filter((o: any) =>
            hashCountries[o.currency]
              ? false
              : (hashCountries[o.currency] = true)
          );
          countries.map((country: any, idx: any) => {
            switch (country.currency) {
              case 'PEN':
                newCountries[0] = 'PEN';
                break;
              case 'COP':
                newCountries[1] = 'COP';
                break;
              case 'MXN':
                newCountries[2] = 'MXN';
                break;
              case 'USD':
                newCountries[3] = 'USD';
                break;
              default:
                break;
            }
          });
          return (
            <>
              <div>
                {newCountries
                  .filter((country: any) => country != '')
                  .join(', ')}
              </div>
            </>
          );
        }
      },
      {
        Header: 'Tipo de producto',
        accessor: 'product_type',
        classColumn: 'product-type',
        widthColumn: 152,
        hasTooltip: true,
        disableSortBy: true,
        Cell: ({ cell }: any) => <>{cell.value}</>
      },
      {
        Header: 'Descuento',
        accessor: 'discount_percentage',
        classColumn: 'sdiscount-percentage',
        widthColumn: 182,
        disableFilters: true,
        disableSortBy: true,
        align: 'center',
        Cell: ({ cell }: any) => <>{Number(cell.value)}</>
      },
      {
        Header: 'Inicio',
        accessor: 'start_date',
        align: 'center',
        Cell: ({ cell }: any) => (
          <>{cell.value ? moment(cell.value).format('DD-MM-YY') : ''}</>
        ),
        disableFilters: true
        //disableSortBy: true,
      },
      {
        Header: 'Fin',
        accessor: 'end_date',
        align: 'center',
        Cell: ({ cell }: any) => (
          <>{cell.value ? moment(cell.value).format('DD-MM-YY') : ''}</>
        ),
        disableFilters: true
        //disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        actionWidth: 160,
        hasTooltip: true,
        tooltipValue: 'Eliminar descuento',
        Cell: (props: any) => {
          return (
            <>
              <div
                className="table-discount__button table-discount__button--hover-lineal"
                onClick={() => {
                  setDiscountById(props.row.original);
                  setFlagCreate(false);
                  setOpenModalCreate(true);
                }}
              >
                Ver Descuento
              </div>
              <div
                className="table-discount__container-tooltip"
                onClick={() => {
                  openModalAlertDeleteDiscount();
                  setDeleteId(Number(props.row.original.id));
                }}
              >
                <IconDelete
                  className={`table-discount__button`}
                  fill={colors.colorAlertRed}
                />
                <section className="table-discount__tooltip table-discount__tooltip--discount">
                  {props.column.tooltipValue}
                </section>
              </div>
              <FloatingMenu
                items={[
                  <ItemFloatingMenu
                    onClick={() => {
                      setDiscountById(props.row.original);
                      setFlagCreate(false);
                      setOpenModalCreate(true);
                    }}
                  >
                    Editar
                  </ItemFloatingMenu>
                ]}
              />
            </>
          );
        },
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      }
    ],
    []
  );

  if (!discounts) return <ScreenLoader />;

  return (
    <>
      <Table data={discounts} columns={columns} />
      <ModalCreateDiscount
        flagCreate={flagCreate}
        setFlagCreate={setFlagCreate}
        isOpenModal={openModalCreate}
        openModal={openModalCreate}
        closeModal={() => {
          setOpenModalCreate(false);
          closeModalCreateDiscount();
        }}
        discountEdit={discountById}
        courses={courses}
        paths={paths}
        refetch={refetch}
      />
      <ModalMessageAlert
        width="xs"
        align="center"
        title={
          <Typography bold type="paragraph1" variation="desktop">
            ¿Estás seguro que deseas eliminar el descuento?
          </Typography>
        }
        isOpenModal={isOpenModalAlertDeleteDiscount}
        openModal={openModalAlertDeleteDiscount}
        closeModal={closeModalAlertDeleteDiscount}
        button1={
          <ButtonOutline
            size="default"
            onClick={() => closeModalAlertDeleteDiscount()}
          >
            Cancelar
          </ButtonOutline>
        }
        button2={
          <ButtonFilled
            size="default"
            onClick={() => handleDeleteDiscount(deleteId)}
          >
            Aceptar
          </ButtonFilled>
        }
      />
    </>
  );
};

export default TableDiscounts;
