// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// Hot Toast
import { toast } from 'react-hot-toast';

// Moment
import moment from 'moment';

//Redux
import { useSelector } from 'react-redux';

// Material UI
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { Box } from '@material-ui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import {
  getStudentsFromAPI,
  postCreateStudents,
  selectStateStudents
} from '../../../../slices/studentSlice/studentSlice';
import { AppDispatch, useAppDispatch } from '../../../../store/store';
import { selectStateGuardians } from '../../../../slices/guardianSlice/guardianSlice';
import { selectStateStudentForId } from '../../../../slices/studentSlice/studentByIdSlice';
import { getErrorMessage } from '../../../../utils/utils';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('Requerido'),
  last_name: Yup.string().required('Requerido'),
  birthdate: Yup.date().nullable(true),
  school_name: Yup.string().min(2, 'Muy pequeño!').max(500, 'Mucho texto!'),
  gender: Yup.string().required('Requerido'),
  device_experience: Yup.string().required('Requerido'),
  operative_system: Yup.string().required('Requerido'),
  email: Yup.string().email('Email invalido!').min(2, 'Muy corto!'),
  password: Yup.string(),
  password_google: Yup.string(),
  guardian: Yup.object()
});

interface Props {
  studentId?: number;
  guardianSelected?: any;
  onClose: () => void;
}

const CreateStudent: React.FC<Props> = ({
  studentId,
  onClose,
  guardianSelected
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const { student, isLoadingEditStudent } = useSelector(
    selectStateStudentForId
  );
  const { guardians, isLoading } = useSelector(selectStateGuardians);
  const { isLoadingUpdateStudent, completed } =
    useSelector(selectStateStudents);

  const funcCreateStudent = async (student: any) => {
    try {
      await dispatch(postCreateStudents(student));
      if (completed) toast.success('Estudiante creado...');
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getStudentsFromAPI({ currentPage: 1, filters: null }));
    }
  };

  if (
    isLoadingUpdateStudent ||
    isLoadingEditStudent ||
    isLoading ||
    !student ||
    !guardians ||
    !guardianSelected
  )
    return <LinearProgress />;

  const initialValues = {
    first_name: '',
    last_name: '',
    birthdate: '',
    /* school_name: "", */
    gender: '',
    device_experience: '',
    operative_system: '',
    email: '',
    password: '',
    password_google: '',
    guardian: guardianSelected
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async values => {
        const data = {
          first_name: values.first_name,
          last_name: values.last_name,
          birthdate:
            values.birthdate && moment(values.birthdate).format('YYYY-MM-DD'),
          /* school_name: values.school_name, */
          gender: values.gender,
          device_experience: values.device_experience,
          operative_system: values.operative_system,
          email: values.email,
          password: values.password,
          password_google: values.password_google,
          guardian: guardianSelected.id
        };

        await funcCreateStudent(data!);
        onClose();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowGap={2}>
            {/* Details */}
            <Grid item container columnSpacing={2} rowGap={3} xs={12}>
              {/* First_name */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="first_name"
                  label="Nombre"
                  variant="outlined"
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.first_name)}
                  </Alert>
                )}
              </Grid>

              {/* Last_name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="Apellido"
                  variant="outlined"
                  value={values.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && touched.last_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.last_name)}
                  </Alert>
                )}
              </Grid>

              {/* School_name */}
              {/* <Grid item xs={12}>
                        <TextField
                           fullWidth
                           id="school_name"
                           label="Nombre del colegio"
                           variant="outlined"
									
                           value={values.school_name}
                           onChange={handleChange}
                        />
                        {errors.school_name && touched.school_name && (
                           <Alert severity="error">{getErrorMessage(errors.school_name)}</Alert>
                        )}
                     </Grid> */}

              {/* Birthdate */}
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Cumpleaños"
                    format="yyyy-MM-dd"
                    value={moment(values.birthdate, 'YYYY-MM-DD').toDate()}
                    onChange={(value: any) => setFieldValue('birthdate', value)}
                    slots={{
                      textField: (params: any) => (
                        <TextField fullWidth {...params} />
                      )
                    }}
                  />
                  {errors.birthdate && touched.birthdate && (
                    <Alert severity="error">
                      {getErrorMessage(errors.birthdate)}
                    </Alert>
                  )}
                </LocalizationProvider>
              </Grid>

              {/* Gender */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="gender">Género</InputLabel>
                  <Select
                    labelId="gender"
                    id="gender"
                    label="Género"
                    value={values.gender}
                    onChange={(value: any) =>
                      setFieldValue('gender', value.target.value)
                    }
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="unspecified">Unspecified</MenuItem>
                  </Select>
                  {errors.gender && touched.gender && (
                    <Alert severity="error">
                      {getErrorMessage(errors.gender)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Device_experience */}
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="device_experience">
                    Experiencia tecnológica
                  </InputLabel>
                  <Select
                    labelId="device_experience"
                    id="device_experience"
                    label="Experiencia tecnológica"
                    value={values.device_experience}
                    onChange={(value: any) =>
                      setFieldValue('device_experience', value.target.value)
                    }
                  >
                    <MenuItem value="notexperience">notexperience</MenuItem>
                    <MenuItem value="basic">basic</MenuItem>
                    <MenuItem value="intermediate">intermediate</MenuItem>
                    <MenuItem value="advance">advance</MenuItem>
                  </Select>
                  {errors.device_experience && touched.device_experience && (
                    <Alert severity="error">
                      {getErrorMessage(errors.device_experience)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Operative_system */}
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="operative_system">
                    Sistema operativo
                  </InputLabel>
                  <Select
                    labelId="operative_system"
                    id="operative_system"
                    label="Experiencia tecnologica"
                    value={values.operative_system}
                    onChange={(value: any) =>
                      setFieldValue('operative_system', value.target.value)
                    }
                  >
                    <MenuItem value="none">none</MenuItem>
                    <MenuItem value="windows">windows</MenuItem>
                    <MenuItem value="macos">macos</MenuItem>
                    <MenuItem value="linux">linux</MenuItem>
                    <MenuItem value="other">other</MenuItem>
                  </Select>
                  {errors.operative_system && touched.operative_system && (
                    <Alert severity="error">
                      {getErrorMessage(errors.operative_system)}
                    </Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Correo"
                  variant="outlined"
                  disabled={false}
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Alert severity="error">
                    {getErrorMessage(errors.email)}
                  </Alert>
                )}
              </Grid>

              {/* Password */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="password"
                  label="Contraseña"
                  variant="outlined"
                  value={values.password}
                  disabled={true}
                  onChange={handleChange}
                />
                {errors.password && touched.password && (
                  <Alert severity="error">
                    {getErrorMessage(errors.password)}
                  </Alert>
                )}
              </Grid>

              {/* Password Google */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="password_google"
                  label="Contraseña Google"
                  variant="outlined"
                  value={values.password_google}
                  disabled={true}
                  onChange={handleChange}
                />
                {errors.password_google && touched.password_google && (
                  <Alert severity="error">
                    {getErrorMessage(errors.password_google)}
                  </Alert>
                )}
              </Grid>

              {/* Guardian */}
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="guardian"
                  options={guardians}
                  autoHighlight
                  defaultValue={guardianSelected}
                  onChange={(_e, value: any) =>
                    setFieldValue('guardian', value)
                  }
                  getOptionLabel={option =>
                    `${option.first_name} ${option.last_name} (${option.id})`
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.first_name} {option.last_name} ({option.id})
                    </Box>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Tutor / Madre / Padre"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.guardian && touched.guardian && (
                  <Alert severity="error">
                    {getErrorMessage(errors.guardian)}
                  </Alert>
                )}
              </Grid>
            </Grid>

            {/* Edit */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={isLoadingUpdateStudent}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateStudent;
