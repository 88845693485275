// Interfaces
import { currencies } from '../interfaces/currency.interface';

// Course //
export const initialValueByCurrencyCourse = (
  currency: currencies,
  prices: any
) => {
  return {
    show_as_free_course: prices[currency]?.show_as_free_course,
    has_discount: prices[currency]?.has_discount,
    discount_percentage: prices[currency]?.discount_percentage,
    has_test_month: prices[currency]?.has_test_month,
    test_month_duration: prices[currency]?.test_month_duration,
    test_month_classes: prices[currency]?.test_month_classes,
    amount: prices[currency]?.amount,
    amount_after_discount: prices[currency]?.amount_after_discount,
    test_month_amount: prices[currency]?.test_month_amount
  };
};

export const submitByCurrencyCourse = (currency: currencies, values: any) => {
  return {
    currency: currency,
    amount: `${values.amount}`,
    has_discount: values.has_discount,
    discount_percentage: `${values.discount_percentage}`,
    amount_after_discount: `${values.amount_after_discount}`,
    available_to_purchase: values.show_as_free_course
  };
};

// Learning path //
export const initialValueByCurrency = (currency: currencies, prices: any) => {
  return {
    amount: prices[currency]?.amount,
    amount_after_discount: prices[currency]?.amount_after_discount,
    amount_full_course_by_course:
      prices[currency]?.amount_full_course_by_course,
    amount_per_month: prices[currency]?.amount_per_month,
    available_to_purchase: prices[currency]?.available_to_purchase,
    discount_percentage: prices[currency]?.discount_percentage,
    has_discount: prices[currency]?.has_discount,
    show_as_free_course: prices[currency]?.show_as_free_course,
    amount_after_discount_per_month:
      prices[currency]?.amount_after_discount_per_month
  };
};

export const submitByCurrency = (currency: currencies, values: any) => {
  return {
    currency: currency,
    amount: values.amount,
    has_discount: values.has_discount,
    discount_percentage: values.discount_percentage,
    amount_after_discount: values.amount_after_discount,
    available_to_purchase: values.available_to_purchase,
    amount_per_month: values.amount_per_month,
    amount_full_course_by_course: values.amount_full_course_by_course
  };
};
