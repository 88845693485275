/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import {
 useEffect, useMemo, useState, useRef 
} from 'react';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link as LinkRouter
} from 'react-router-dom';

import './tableStudent.scss';
import Modal from '../../Modal/Modal';
import Link from '../../molecules/Link';
import Table from '../../molecules/Table';
import { useModal } from '../../../hooks/useModal';
import TagCourses from '../../molecules/TagCourses';
import IconEnroll from '../../atoms/icons/IconEnroll';
import FloatingMenu from '../../molecules/FloatingMenu';
import ButtonLineal from '../../molecules/ButtonLineal';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonOutline from '../../molecules/ButtonOutline';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import { addParams } from '../../../helpers/router.helpers';
import ModalMessageAlert from '../../molecules/ModalMessageAlert';
import { confirmDialog } from '../../ConfirmDialog/ConfirmDialog';
import ItemFloatingMenu from '../../molecules/ItemFloatingMenu';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import { getStudentsFromAPI } from '../../../slices/studentSlice/studentSlice';
import { getStudentById } from '../../../slices/studentSlice/studentByIdSlice';
import { selectStateStudents } from '../../../slices/studentSlice/studentSlice';
import {
  deleteStudent,
  syncStudentPlatforms,
  syncStudentWithMoodle
} from '../../../services/students';
import { FILTERS_FIELD_NAMES_TRANSLATIONS } from '../../../constants/studentList.constants';
import EnrollStudent from '../../../views/Students/ModalEnrollStudent/EnrollStudent/EnrollStudent';
import DetailsStudent from '../../../views/Students/ModalDetailsStudent/DetailsStudent/DetailsStudent';

const TableStudentV2 = () => {
  const [params, setParams] = useSearchParams();
  const {
 students, isLoading, totalCount, totalPages 
} =
    useSelector(selectStateStudents);
  const dispatch: AppDispatch = useAppDispatch();
  const location = useLocation();
  const [idStudent, setIdStudent] = useState<number | null>(null);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalEnroll, setOpenModalEnroll] = useState(false);
  const [disableButtonSyncPlatform, setDisableButtonSyncPlatform] =
    useState(false);
  const {
    openModal: openModalSycnPlatforms,
    closeModal: closeModalSycnPlatforms,
    isOpenModal: isOpenModalSycnPlatforms
  } = useModal();
  const [currentPage, setCurrentPage] = useState(1);
  type FilterState = {
    [key: string]: string;
  };
  const currentFiltersInitialState: FilterState = {};
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );
  const prevFilterStateRef = useRef<FilterState>(currentFiltersInitialState);
  const syncStudentAccountMoodle = async (studentId: number) => {
    try {
      await syncStudentWithMoodle(studentId);
      setCurrentFilters({});
      toast.success('Se sincronizó correctamente el estudiante');
      dispatch(
        getStudentsFromAPI({
          currentPage: currentPage,
          filters: currentFilters
        })
      );
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error.response?.status === 417) {
        toast.error(error.response?.data?.error);
      } else {
        toast.error(error.response?.data?.error);
      }
    }
  };
  const syncStudentAccountsPlatforms = async () => {
    try {
      setDisableButtonSyncPlatform(true);
      await syncStudentPlatforms();
      setCurrentFilters({});
      toast.success(
        'Proceso en curso. Notificaremos el status por el canal de Discord.'
      );
      closeModalSycnPlatforms();
      dispatch(
        getStudentsFromAPI({
          currentPage: currentPage,
          filters: currentFilters
        })
      );
    } catch (err) {
      toast.error(
        'Ocurrió un error al realizar la sincronización. Vuelve a intentar.'
      );
    }
  };
  const deleteStudentFromApi = async (studentId: number) => {
    try {
      await deleteStudent(studentId);
      toast.success('Se eliminó correctamente el estudiante');
      setCurrentFilters({});
      dispatch(
        getStudentsFromAPI({
          currentPage: currentPage,
          filters: currentFilters
        })
      );
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      toast.error(error.response?.data?.error);
    }
  };
  useEffect(() => {
    if (idStudent) {
      dispatch(getStudentById(idStudent));
    }
  }, [dispatch, idStudent]);

  useEffect(() => {
    if (location.search.includes('-edit')) {
      setIdStudent(parseInt(location.search.split('?id=')[1]));
    } else if (parseInt(location.search.split('?id=')[1])) {
      setIdStudent(parseInt(location.search.split('?id=')[1]));
      setOpenModalDetails(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else if (prevFilterStateRef.current != currentFiltersInitialState) {
      dispatch(
        getStudentsFromAPI({
          currentPage: currentPage,
          filters: currentFilters
        })
      );
    }
    prevFilterStateRef.current = currentFilters;
  }, [currentFilters]);

  useEffect(() => {
    dispatch(
      getStudentsFromAPI({ currentPage: currentPage, filters: currentFilters })
    );
  }, [currentPage]);

  const resetFilter = (value: string) => {
    const newState = { ...currentFilters };
    delete newState[value];
    setCurrentFilters(newState);
  };

  const navigate = useNavigate();

  const goToStudentEdit = (studentId: any) => {
    navigate(`/dashboard/students/${studentId}/edit`);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        widthColumn: 100,
        disableSortBy: true
      },
      {
        Header: 'Nombre',
        widthColumn: 201,
        accessor: 'full_name',
        classColumn: 'name-student',
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Edad',
        accessor: 'age',
        align: 'center',
        minWidthColumn: 122,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => <span>{cell.value}</span>
      },
      {
        Header: 'Moodle ID',
        accessor: 'moodle_id',
        align: 'center',
        minWidthColumn: 122,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }: any) => <span>{cell.value}</span>
      },
      {
        Header: 'Tutor',
        accessor: 'guardian.full_name',
        widthColumn: 187,
        classColumn: 'name-tutor',
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Tutor',
        accessor: 'guardian.id',
        hideColumn: true,
        disableSortBy: true,
        Cell: ({ cell }: any) => <span>{cell.value}</span>
      },
      {
        Header: 'Correo de tutor',
        widthColumn: 209,
        accessor: 'guardian.email',
        classColumn: 'name-email',
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'DNI',
        minWidthColumn: 129,
        accessor: 'guardian.doc_number',
        classColumn: 'name-dni',
        disableSortBy: true,
        Cell: ({ cell }: any) => <span>{cell.value}</span>
      },
      {
        Header: '',
        accessor: 'action',
        actionWidth: 156,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <>
              <div
                className="table-student__button table-student__button--hover-lineal"
                onClick={() => {
                  setIdStudent(props.row.values.id);
                  dispatch(getStudentById(props.row.values.id));
                }}
              >
                <Link to={`/dashboard/students/${props.row.values.id}`}>
                  Ver más
                </Link>
              </div>
              {props.row.values.id ? (
                <Tooltip title="Inscribir" arrow placement="top">
                  <div
                    className="table-student__container-tooltip"
                    onClick={() => {
                      setOpenModalEnroll(true);
                      setIdStudent(props.row.values.id);
                    }}
                  >
                    <IconEnroll
                      className={`table-student__button table-student__button--enabled`}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Inscribir" arrow placement="top">
                  <IconEnroll
                    className={`table-student__button table-student__button--disabled`}
                    fill="#bcbcbc"
                  />
                </Tooltip>
              )}
              <FloatingMenu
                items={[
                  <ItemFloatingMenu
                    onClick={() => {
                      addParams(
                        params,
                        setParams,
                        'id',
                        `${props.row.values.id.toString()}-edit`
                      );
                      setIdStudent(props.row.values.id);
                      dispatch(getStudentById(props.row.values.id));
                      goToStudentEdit(props.row.values.id);
                    }}
                  >
                    Editar
                  </ItemFloatingMenu>,
                  <ItemFloatingMenu
                    disabled={props.row.original.guardian ? false : true}
                  >
                    {props.row.original.guardian ? (
                      <LinkRouter
                        to={`/dashboard/guardians?id=${props.row.original.guardian.id}`}
                      >
                        Ver Tutor
                      </LinkRouter>
                    ) : (
                      <>Ver Tutor</>
                    )}
                  </ItemFloatingMenu>,
                  <ItemFloatingMenu
                    onClick={async () => {
                      await syncStudentAccountMoodle(props.row.values.id);
                    }}
                  >
                    Sincronizar plataformas
                  </ItemFloatingMenu>,
                  <ItemFloatingMenu
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                      confirmDialog(
                        `¿Realmente quieres eliminar al estudiante: ${props.row.values.id}?`,
                        () => deleteStudentFromApi(props.row.values.id)
                      );
                    }}
                  >
                    Eliminar
                  </ItemFloatingMenu>
                ]}
              />
            </>
          );
        }
      }
    ],
    []
  );

  if (isLoading || !students) return <ScreenLoader />;

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage: currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters: currentFilters,
    totalRows: totalCount,
    totalPages: totalPages,
    pageSize: 100
  };

  return (
    <>
      {Object.keys(currentFilters).length > 0 ? (
        <>
          <section className="students_filter__container_courses">
            <p className="typography typography__paragraph2 typography--undefined typography__paragraph2--mobile typography__bold  teacher-profile__typography">
              Filtro Actual:{' '}
            </p>
            {Object.keys(currentFilters).map((key: string, index, test) => {
              return (
                <>
                  <TagCourses className="students_filter__tag">
                    <Typography type="paragraph2" variation="mobile" bold>
                      {FILTERS_FIELD_NAMES_TRANSLATIONS.get(key) +
                        ': ' +
                        currentFilters[key]}
                    </Typography>
                    <ButtonLineal
                      size="small"
                      className="students_filter__button_removal"
                      onClick={() => resetFilter(key)}
                    >
                      &#10005;
                    </ButtonLineal>
                  </TagCourses>
                </>
              );
            })}
          </section>
        </>
      ) : null}
      <ModalMessageAlert
        width="sm"
        align="center"
        title={
          <Typography align="center" bold type="paragraph6" variation="desktop">
            Sincronizar plataformas
          </Typography>
        }
        isOpenModal={isOpenModalSycnPlatforms}
        openModal={openModalSycnPlatforms}
        closeModal={closeModalSycnPlatforms}
        body={
          <>
            <Typography align="left" type="paragraph2" variation="desktop">
              Solo se procesarán los estudiantes con problemas de sincronización
              ocurridos en los últimos 5 días. Este proceso realizará las
              siguientes acciones:
            </Typography>
            <ul>
              <li>
                <Typography align="left" type="paragraph2" variation="desktop">
                  Creación de cuenta de Google
                </Typography>
              </li>
              <li>
                <Typography align="left" type="paragraph2" variation="desktop">
                  Creación de cuenta de Moodle
                </Typography>
              </li>
              <li>
                <Typography align="left" type="paragraph2" variation="desktop">
                  Inscripción del estudiante en el salón de Moodle
                </Typography>
              </li>
            </ul>
            <Typography align="left" type="paragraph2" variation="desktop">
              El proceso es asincrónico y puede tardar hasta 40 minutos en
              completarse.{' '}
              <strong>
                Recibirás una notificación en el canal de Discord{' '}
                {process.env.REACT_APP_DISCORD_NOTIFICATION_BULK_LOAD}
              </strong>{' '}
              cuando el proceso haya finalizado.
            </Typography>
          </>
        }
        button1={
          <ButtonFilled
            onClick={async () => {
              await syncStudentAccountsPlatforms();
              setDisableButtonSyncPlatform(false);
            }}
            disabled={disableButtonSyncPlatform}
          >
            Confirmar sincronización
          </ButtonFilled>
        }
        button2={
          <section className="table-student__button">
            <ButtonOutline onClick={closeModalSycnPlatforms}>
              Cancelar
            </ButtonOutline>
          </section>
        }
      />
      {students.length < 1 ? (
        <>
          <div className="table__caption">No se encontraron registros.</div>
        </>
      ) : (
        <>
          <Table
            data={students}
            columns={columns}
            paginationState={paginationState}
            childSectionHeader={
              <ButtonOutline size="small" onClick={openModalSycnPlatforms}>
                Sincronizar plataformas
              </ButtonOutline>
            }
          />
        </>
      )}
      {idStudent ? (
        <>
          <Modal
            id={idStudent}
            title="Mas Información"
            fullWidth
            copyUrl
            maxWidth="md"
            openModal={openModalDetails}
            handleCloseModal={() => setOpenModalDetails(false)}
          >
            <DetailsStudent studentId={idStudent} />
          </Modal>
          <Modal
            id={idStudent}
            title="Inscribir Estudiante"
            fullWidth
            maxWidth="md"
            openModal={openModalEnroll}
            handleCloseModal={() => setOpenModalEnroll(false)}
          >
            <EnrollStudent
              studentId={idStudent}
              setCurrentFilters={setCurrentFilters}
              onClose={() => setOpenModalEnroll(false)}
            />
          </Modal>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default TableStudentV2;
