// React
import React, { useState } from 'react';
import { QueryObserver, useMutation, useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import {
 Grid, IconButton, Stack, Switch 
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  GridValueGetterParams
} from '@mui/x-data-grid';

import CustomLoadingOverlay from '../../components/CustomLoadingOverlay/CustomLoadingOverlay';
import { PATCH_MICRO } from '../../services/api/api';

// Interfaces
import { ICoupon } from '../../interfaces/coupon.interface';

// Utils
import { patchCouponCumulativeState } from '../../services/coupon';
import { objectsEqual } from '../../utils/utils';

interface Props {
  coupons?: ICoupon[];
  couponProgramId: number;
  refetch: () => {};
}

export const handleChangeCumulative: Function = async (
  value: boolean,
  couponId: number,
  refetch: Function,
  refetchCoupon: Function
) => {
  try {
    await patchCouponCumulativeState({ cumulative: value }, couponId);
    refetch();
    refetchCoupon();
  } catch (error) {
    console.log(`ocurrió un error al cambiar estado de cupon ${error}`);
  }
};

export const handleChangRedeemableOnlyOnce: Function = async (
  value: boolean,
  couponId: number,
  refetch: Function,
  refetchCoupon: Function
) => {
  try {
    await patchCouponCumulativeState({ redeemable_only_once: value }, couponId);
    refetch();
    refetchCoupon();
  } catch (error) {
    console.log(`ocurrió un error al cambiar estado de cupon ${error}`);
  }
};

const ListCoupon: React.FC<Props> = ({ coupons, refetch }) => {
  const queryClient = useQueryClient();
  const { refetch: refetchCoupon } = new QueryObserver(queryClient, {queryKey: 'coupon-program'});

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc'
    }
  ]);

  const patchCoupon = useMutation((id: number) => PATCH_MICRO(`/coupon/${id}`));

  const handleCancelCoupon = async (id: number) => {
    await patchCoupon.mutateAsync(id);
    refetch();
    refetchCoupon();
  };

  function generateColumnsByCoupons(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        }
      },
      {
        field: 'code',
        headerName: 'Code',
        width: 200,
        minWidth: 200,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.code;
        }
      },
      {
        field: 'state',
        headerName: 'State',
        width: 120,
        minWidth: 110,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.state;
        }
      },
      {
        field: 'cumulative',
        headerName: 'Cumulative',
        width: 130,
        minWidth: 120,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Switch
                checked={params.row.cumulative}
                color="success"
                onChange={e =>
                  handleChangeCumulative(
                    e.target.checked,
                    params.row.id,
                    refetch,
                    refetchCoupon
                  )
                }
              />
            </Stack>
          );
        }
      },
      {
        field: 'redeemable_only_once',
        headerName: 'Single use',
        width: 190,
        minWidth: 190,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Switch
                checked={params.row.redeemable_only_once}
                color="success"
                onChange={e =>
                  handleChangRedeemableOnlyOnce(
                    e.target.checked,
                    params.row.id,
                    refetch,
                    refetchCoupon
                  )
                }
              />
            </Stack>
          );
        }
      },
      {
        field: 'delete',
        headerName: 'Delete',
        width: 100,
        minWidth: 85,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => handleCancelCoupon(params.row.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        }
      }
    ];
    return columns;
  }

  const currentlySelected = (model: GridSortModel) => {
    !objectsEqual(model, sortModel) && setSortModel(model);
  };

  return (
    <Grid xs={12}>
      {coupons?.filter(coupon => coupon.state == 'active').length ? (
        <DataGrid
          autoHeight
          style={{ fontSize: 12, height: 200 }}
          sortModel={sortModel}
          onSortModelChange={model => currentlySelected(model)}
          rows={coupons?.filter(coupon => coupon.state == 'active')}
          columns={generateColumnsByCoupons()}
          pageSize={20}
          rowsPerPageOptions={[20]}
          components={{ LoadingOverlay: CustomLoadingOverlay }}
        />
      ) : null}
    </Grid>
  );
};

export default ListCoupon;
