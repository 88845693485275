/* eslint-disable react/jsx-key */
/* eslint-disable max-lines */
/* eslint-disable react/no-children-prop */
/* eslint-disable complexity */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/store';
import { useModal } from '../../../hooks/useModal';
import colors from '../../../components/atoms/Colors';
import Input from '../../../components/molecules/Input';
import Switch from '../../../components/molecules/Switch/Switch';
import Select from '../../../components/molecules/Select/Select';
import PERMISSIONS from '../../../constants/permission.constants';
import { ITeacherSchedule } from '../../../interfaces/teacher.interface';
import Typography from '../../../components/atoms/Typography/Typography';
import ButtonLineal from '../../../components/molecules/ButtonLineal/ButtonLineal';
import ScheduleAvailability from '../../../components/organism/ScheduleAvailability';
import IconAddMultipleSelect from '../../../components/atoms/icons/IconAddMultipleSelect';
import ModalAddMultipleOptions from '../../../components/organism/ModalAddMultipleOptions/ModalAddMultipleOptions';

export const CTCInfo: React.FC<{
  values: any;
  handleChange: any;
  ageGroupData: any;
  courseTrainedData: any;
  knowledgesData: any;
  subknowledgesData: any;
  teacherId?: any;
  availability: any;
  activeToggle: any;
  selectStatus: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  setAgeGroups: any;
  ageGroups: any;
  setTeacherCourses: any;
  teacherCourses: any;
  setTeacherKnowledges: any;
  teacherKnowledges: any;
  setTeacherSubknowledges: any;
  teacherSubknowledges: any;
  isProfile?: boolean;
  teacherProfileById: any;
  teacherProfileV2: any;
}> = ({
  values,
  ageGroupData,
  courseTrainedData,
  knowledgesData,
  subknowledgesData,
  handleChange,
  teacherId,
  availability,
  activeToggle,
  selectStatus,
  setFieldValue,
  errors,
  touched,
  setAgeGroups,
  ageGroups,
  setTeacherCourses,
  teacherCourses,
  setTeacherKnowledges,
  teacherKnowledges,
  setTeacherSubknowledges,
  teacherSubknowledges,
  isProfile,
  teacherProfileById,
  teacherProfileV2
}) => {
  const {
    isActive1,
    setIsActive1,
    schedule1,
    setSchedule1,
    isError1,
    setIsError1,
    isActive2,
    setIsActive2,
    schedule2,
    setSchedule2,
    isError2,
    setIsError2,
    isActive3,
    setIsActive3,
    schedule3,
    setSchedule3,
    isError3,
    setIsError3,
    isActive4,
    setIsActive4,
    schedule4,
    setSchedule4,
    isError4,
    setIsError4,
    isActive5,
    setIsActive5,
    schedule5,
    setSchedule5,
    isError5,
    setIsError5,
    isActive6,
    setIsActive6,
    schedule6,
    setSchedule6,
    isError6,
    setIsError6
  } = availability;

  const {
    isActiveSwPedagogica,
    setIsActiveSwPedagogica,
    isActiveSwOperativa,
    setIsActiveSwOperativa,
    isActiveSwExperienceLargeGroups,
    setIsActiveSwExperienceLargeGroups,
    isActiveSwExperience25Years,
    setIsActiveSwExperience25Years,
    isActiveSwExperienceVulnerableGroups,
    setIsActiveSwExperienceVulnerableGroups,
    isActiveSwPresencial,
    setIsActiveSwPresencials
  } = activeToggle;

  const {
    isOpenSelectStatus,
    setIsOpenSelectStatus,
    selectOptionStatus,
    setSelectOptionStatus,
    optionSelectionStatus
  } = selectStatus;

  const [flagUpdate, setflagUpdate] = useState(false);

  const handleChangeSwitchPedagogica = (): void => {
    setIsActiveSwPedagogica(!isActiveSwPedagogica);
  };

  const handleChangeSwitchOperativa = () => {
    setIsActiveSwOperativa(!isActiveSwOperativa);
  };

  const handleChangeSwitchExperienceLargeGroups = (): void => {
    setIsActiveSwExperienceLargeGroups(!isActiveSwExperienceLargeGroups);
  };

  const handleChangeSwitchExperience25Years = (): void => {
    setIsActiveSwExperience25Years(!isActiveSwExperience25Years);
  };

  const handleChangeSwitchExperienceVulnerableGroups = (): void => {
    setIsActiveSwExperienceVulnerableGroups(
      !isActiveSwExperienceVulnerableGroups
    );
  };

  const handleChangeSwitchPresencial = (): void => {
    setIsActiveSwPresencials(!isActiveSwPresencial);
  };

  const userAdmin = useSelector((state: RootState) => state.auth.user);

  const objStatus = [
    { id: 'activo', name: 'Activo' },
    { id: 'desactivando', name: 'Desactivando' },
    { id: 'inactivo', name: 'Inactivo' }
  ];

  const {
    openModal: openModalAddTeacherEtario,
    closeModal: closeModalAddTeacherEtario,
    isOpenModal: isOpenModalAddTeacherEtario
  } = useModal();

  const {
    openModal: openModalAddTeacherCourseTrained,
    closeModal: closeModalAddTeacherCourseTrained,
    isOpenModal: isOpenModalAddTeacherCourseTrained
  } = useModal();

  const {
    openModal: openModalAddTeacherKnowledges,
    closeModal: closeModalAddTeacherKnowledges,
    isOpenModal: isOpenModalAddTeacherKnowledges
  } = useModal();

  const {
    openModal: openModalAddTeacherSubknowledges,
    closeModal: closeModalAddTeacherSubknowledges,
    isOpenModal: isOpenModalAddTeacherSubknowledges
  } = useModal();

  const capitalizeWords = (str: any) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const RenderError = ({ error }: { error: any }) => {
    return (
      <Typography
        type="paragraph2"
        variation="mobile"
        className="input__alert"
        color={colors.warningColor}
      >
        {error}
      </Typography>
    );
  };

  useEffect(() => {
    setFieldValue('teacher_age_groups', ageGroups);
  }, [ageGroups]);

  useEffect(() => {
    setFieldValue('teacher_courses', teacherCourses);
  }, [teacherCourses]);

  useEffect(() => {
    setFieldValue('teacher_knowledges', teacherKnowledges);
  }, [teacherKnowledges]);

  useEffect(() => {
    setFieldValue('teacher_subknowledges', teacherSubknowledges);
  }, [teacherSubknowledges]);

  const removeAgeGroups = (id: any) => {
    const response = ageGroups.filter((item: any) => item.id !== id);
    setFieldValue('teacher_age_groups', response);
    setAgeGroups(response);
  };

  const removeTeacherCourses = (id: any) => {
    const response = teacherCourses.filter((item: any) => item.id !== id);
    setFieldValue('teacher_courses', response);
    setTeacherCourses(response);
  };

  const removeTeacherKnowledges = (id: any) => {
    const response = teacherKnowledges.filter((item: any) => item.id !== id);
    setFieldValue('teacher_knowledges', response);
    setTeacherKnowledges(response);
  };

  const removeTeacherSubknowledges = (id: any) => {
    const response = teacherSubknowledges.filter((item: any) => item.id !== id);
    setFieldValue('teacher_subknowledges', response);
    setTeacherSubknowledges(response);
  };

  const addEllipsis = ({
    str,
    lenght = 5
  }: {
    str: string;
    lenght?: number;
  }) => {
    if (str.length > lenght) {
      return str.substring(0, lenght) + '...';
    }
    return str;
  };

  const addStatusSelectClass =
    selectOptionStatus?.name || values.status !== 'none'
      ? 'teacher-profile__select-status'
      : 'teacher-profile__select-placeholder';

  const addStatusSelectErrorClass =
    errors.status && touched.status ? 'select__error' : '';
  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Información Crack the Code
      </Typography>
      <br />
      <br />
      <div className="create-teacher-tabs__content-body">
        <div className="create-teacher-tabs__organization-information">
          {isProfile ? (
            <></>
          ) : (
            <>
              <Typography bold type="paragraph3" variation="mobile">
                Status*
              </Typography>

              <div>
                <Select
                  className={`${addStatusSelectClass} ${addStatusSelectErrorClass}`}
                  setIsOpen={setIsOpenSelectStatus}
                  isOpen={isOpenSelectStatus}
                  size="default"
                  dividerItems
                  onclickDefaultValue={(event: any) => {
                    optionSelectionStatus(event);
                    setFieldValue('status', event.id);
                  }}
                  options={objStatus}
                  children={
                    values.status
                      ? values.status === 'activo'
                        ? 'Activo'
                        : values.status === 'desactivando'
                          ? 'Desactivando'
                          : 'Inactivo'
                      : 'Elige el status...'
                  }
                />
                {errors.status && touched.status && (
                  <RenderError error={errors.status} />
                )}
              </div>
            </>
          )}

          <Typography bold type="paragraph3" variation="mobile">
            Correo CTC*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.email || '-'}
            </Typography>
          ) : (
            <Input
              value={values.email}
              onChange={handleChange}
              placeholder="Ingresar correo de CTC"
              name="email"
              required
              error={errors.email && touched.email ? errors.email : ''}
            />
          )}

          {isProfile || teacherId ? (
            <></>
          ) : (
            <>
              <Typography bold type="paragraph3" variation="mobile">
                Contraseña*
              </Typography>
              <Input
                type={teacherId ? 'password' : 'string'}
                value={values.password}
                onChange={handleChange}
                placeholder="Ingresar contraseña"
                name="password"
                required
                error={
                  errors.password && touched.password ? errors.password : ''
                }
              />
            </>
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Discord
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.discord || '-'}
            </Typography>
          ) : (
            <Input
              value={values.discord}
              onChange={handleChange}
              placeholder="Ingresar nombre de usuario de Discord"
              name="discord"
              required
            />
          )}
          <Typography
            type="paragraph3"
            variation="mobile"
            bold
            className="teacher-profile__typography"
          >
            Disponibilidad
          </Typography>
          <section className="teacher-profile__container-availability">
            <ScheduleAvailability
              key={`${teacherProfileV2?.id}-1`}
              day="Lunes"
              isActive={isActive1}
              setIsActive={setIsActive1}
              schedule={schedule1}
              setSchedule={setSchedule1}
              setIsError={setIsError1}
              defaultValues={teacherProfileV2?.teacher_schedules?.day_available
                .filter(
                  (schedule: ITeacherSchedule) => schedule.day === 'monday'
                )
                .map((schedule: ITeacherSchedule) => schedule)}
              refetch={teacherProfileById}
            />
            <ScheduleAvailability
              key={`${teacherProfileV2?.id}-2`}
              day="Martes"
              isActive={isActive2}
              setIsActive={setIsActive2}
              schedule={schedule2}
              setSchedule={setSchedule2}
              setIsError={setIsError2}
              defaultValues={teacherProfileV2?.teacher_schedules?.day_available
                .filter(
                  (schedule: ITeacherSchedule) => schedule.day === 'tuesday'
                )
                .map((schedule: ITeacherSchedule) => schedule)}
              refetch={teacherProfileById}
            />
            <ScheduleAvailability
              key={`${teacherProfileV2?.id}-3`}
              day="Miércoles"
              isActive={isActive3}
              setIsActive={setIsActive3}
              schedule={schedule3}
              setSchedule={setSchedule3}
              setIsError={setIsError3}
              defaultValues={teacherProfileV2?.teacher_schedules?.day_available
                .filter(
                  (schedule: ITeacherSchedule) => schedule.day === 'wednesday'
                )
                .map((schedule: ITeacherSchedule) => schedule)}
              refetch={teacherProfileById}
            />
            <ScheduleAvailability
              key={`${teacherProfileV2?.id}-4`}
              day="Jueves"
              isActive={isActive4}
              setIsActive={setIsActive4}
              schedule={schedule4}
              setSchedule={setSchedule4}
              setIsError={setIsError4}
              defaultValues={teacherProfileV2?.teacher_schedules?.day_available
                .filter(
                  (schedule: ITeacherSchedule) => schedule.day === 'thursday'
                )
                .map((schedule: ITeacherSchedule) => schedule)}
              refetch={teacherProfileById}
            />
            <ScheduleAvailability
              key={`${teacherProfileV2?.id}-5`}
              day="Viernes"
              isActive={isActive5}
              setIsActive={setIsActive5}
              schedule={schedule5}
              setSchedule={setSchedule5}
              setIsError={setIsError5}
              defaultValues={teacherProfileV2?.teacher_schedules?.day_available
                .filter(
                  (schedule: ITeacherSchedule) => schedule.day === 'friday'
                )
                .map((schedule: ITeacherSchedule) => schedule)}
              refetch={teacherProfileById}
            />
            <ScheduleAvailability
              key={`${teacherProfileV2?.id}-6`}
              day="Sábado"
              isActive={isActive6}
              setIsActive={setIsActive6}
              schedule={schedule6}
              setSchedule={setSchedule6}
              setIsError={setIsError6}
              defaultValues={teacherProfileV2?.teacher_schedules?.day_available
                .filter(
                  (schedule: ITeacherSchedule) => schedule.day === 'saturday'
                )
                .map((schedule: ITeacherSchedule) => schedule)}
              refetch={teacherProfileById}
            />
          </section>
        </div>
        <div className="create-teacher-tabs__organization-information-switch">
          <Typography type="paragraph3" variation="mobile" bold>
            Experiencia en grupos grandes*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {isActiveSwExperienceLargeGroups ? 'Sí' : 'No'}
            </Typography>
          ) : (
            <Switch
              isActive={isActiveSwExperienceLargeGroups}
              setIsActive={setIsActiveSwExperienceLargeGroups}
              name={'has_large_groups'}
              onClick={() => {
                handleChangeSwitchExperienceLargeGroups();
              }}
            />
          )}
          <Typography type="paragraph3" variation="mobile" bold>
            Experiencia en adultos +25 años*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {isActiveSwExperience25Years ? 'Sí' : 'No'}
            </Typography>
          ) : (
            <Switch
              isActive={isActiveSwExperience25Years}
              setIsActive={setIsActiveSwExperience25Years}
              name={'has_25_years'}
              onClick={() => {
                handleChangeSwitchExperience25Years();
              }}
            />
          )}
          <Typography type="paragraph3" variation="mobile" bold>
            Experiencia en grupos vulnerables*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {isActiveSwExperienceVulnerableGroups ? 'Sí' : 'No'}
            </Typography>
          ) : (
            <Switch
              isActive={isActiveSwExperienceVulnerableGroups}
              setIsActive={setIsActiveSwExperienceVulnerableGroups}
              name={'has_vulnerable_groups'}
              onClick={() => {
                handleChangeSwitchExperienceVulnerableGroups();
              }}
            />
          )}
          <Typography type="paragraph3" variation="mobile" bold>
            Diponibilidad presencial*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {isActiveSwPresencial ? 'Sí' : 'No'}
            </Typography>
          ) : (
            <Switch
              isActive={isActiveSwPresencial}
              setIsActive={setIsActiveSwPresencials}
              name={'has_presencial'}
              onClick={() => {
                handleChangeSwitchPresencial();
              }}
            />
          )}
          <Typography type="paragraph3" variation="mobile" bold>
            Capacitación pedagógica
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {isActiveSwPedagogica ? 'Sí' : 'No'}
            </Typography>
          ) : (
            <Switch
              isActive={isActiveSwPedagogica}
              setIsActive={setIsActiveSwPedagogica}
              name={'has_pedagogical'}
              onClick={() => {
                handleChangeSwitchPedagogica();
              }}
            />
          )}
          <Typography type="paragraph3" variation="mobile" bold>
            Capacitación operativa
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {isActiveSwOperativa ? 'Sí' : 'No'}
            </Typography>
          ) : (
            <Switch
              isActive={isActiveSwOperativa}
              setIsActive={setIsActiveSwOperativa}
              name={'has_operational'}
              onClick={() => {
                handleChangeSwitchOperativa();
              }}
            />
          )}
          <Typography
            type="paragraph3"
            variation="mobile"
            bold
            className="teacher-profile__typography"
          >
            Grupo etario
          </Typography>
          <div className="create-teacher-tabs__content-tags">
            {ageGroups?.length < 1 && !isProfile && (
              <ButtonLineal
                size="small"
                className={`teacher-profile__button-text-add-habilities 
                                             ${
                                               !userAdmin?.permission[
                                                 PERMISSIONS.roomListAll
                                               ]
                                                 ? 'teacher-profile__disabled'
                                                 : ''
                                             }`}
                disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherEtario()
                }
                customStyles={{ fontSize: '16px' }}
              >
                Añadir grupo etario +
              </ButtonLineal>
            )}
            {ageGroups.map((val: any) => {
              return (
                <div
                  style={{
                    backgroundColor: '#e5e5e5',
                    borderRadius: '5px',
                    width: 'fit-content',
                    padding: '9px',
                    margin: '2px',
                    display: 'flex'
                  }}
                >
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="teacher-profile__typography"
                  >
                    {addEllipsis({ str: val?.name, lenght: 32 })}
                  </Typography>
                  {!isProfile && (
                    <button
                      type="button"
                      style={{
                        marginLeft: '5px',
                        border: '0px',
                        backgroundColor: 'inherit',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        removeAgeGroups(val.id);
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
              );
            })}
            {ageGroups?.length > 0 && !isProfile && (
              <button
                type="button"
                style={{
                  border: '0px',
                  backgroundColor: 'inherit',
                  cursor: 'pointer'
                }}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherEtario()
                }
              >
                <IconAddMultipleSelect />
              </button>
            )}
            {isProfile && ageGroups?.length <= 0 && `-`}
          </div>
          <ModalAddMultipleOptions
            id={'teacher_age_groups'}
            title="Añadir grupo etario"
            subtitle="Cada grupo etario corresponde a un curso suelto o de ruta"
            label="Grupo etario"
            placeholder="Elegir grupo etario"
            width="lg"
            isOpen={isOpenModalAddTeacherEtario}
            closeModal={closeModalAddTeacherEtario}
            etario={ageGroupData}
            // setTeacherProfile={setTeacherProfileV2}
            flagUpdate={flagUpdate}
            setflagUpdate={setflagUpdate}
            setOptions={setAgeGroups}
            options={ageGroups}
          />
          <Typography
            type="paragraph3"
            variation="mobile"
            bold
            className="teacher-profile__typography"
          >
            Cursos capacitados
          </Typography>
          <div className="create-teacher-tabs__content-tags">
            {teacherCourses?.length < 1 && !isProfile && (
              <ButtonLineal
                size="small"
                className={`teacher-profile__button-text-add-habilities 
                                             ${
                                               !userAdmin?.permission[
                                                 PERMISSIONS.roomListAll
                                               ]
                                                 ? 'teacher-profile__disabled'
                                                 : ''
                                             }`}
                disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherCourseTrained()
                }
                customStyles={{ fontSize: '16px' }}
              >
                Añadir +
              </ButtonLineal>
            )}

            {teacherCourses?.map((val: any) => {
              return (
                <div
                  style={{
                    backgroundColor: '#e5e5e5',
                    borderRadius: '5px',
                    width: 'fit-content',
                    padding: '9px',
                    margin: '2px',
                    display: 'flex'
                  }}
                >
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="teacher-profile__typography"
                  >
                    {addEllipsis({ str: val?.name, lenght: 32 })}
                  </Typography>
                  {!isProfile && (
                    <button
                      type="button"
                      style={{
                        marginLeft: '5px',
                        border: '0px',
                        backgroundColor: 'inherit',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        removeTeacherCourses(val.id);
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
              );
            })}
            {teacherCourses?.length > 0 && !isProfile && (
              <button
                type="button"
                style={{
                  marginLeft: '5px',
                  border: '0px',
                  backgroundColor: 'inherit',
                  cursor: 'pointer'
                }}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherCourseTrained()
                }
              >
                <IconAddMultipleSelect />
              </button>
            )}
            {isProfile && teacherCourses?.length <= 0 && `-`}
          </div>
          <ModalAddMultipleOptions
            id={'teacher_courses'}
            title="Añadir cursos capacitados"
            label="Curso"
            placeholder="Elegir curso"
            width="lg"
            isOpen={isOpenModalAddTeacherCourseTrained}
            closeModal={closeModalAddTeacherCourseTrained}
            etario={courseTrainedData}
            // setTeacherProfile={setTeacherProfileV2}
            flagUpdate={flagUpdate}
            setflagUpdate={setflagUpdate}
            setOptions={setTeacherCourses}
            showIdOption={true}
            options={teacherCourses}
          />
          <Typography
            type="paragraph3"
            variation="mobile"
            bold
            className="teacher-profile__typography"
          >
            Conocimientos - Categorías
          </Typography>
          <div className="create-teacher-tabs__content-tags">
            {teacherKnowledges?.length < 1 && !isProfile && (
              <ButtonLineal
                size="small"
                className={`teacher-profile__button-text-add-habilities 
                                             ${
                                               !userAdmin?.permission[
                                                 PERMISSIONS.roomListAll
                                               ]
                                                 ? 'teacher-profile__disabled'
                                                 : ''
                                             }`}
                disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherKnowledges()
                }
                customStyles={{ fontSize: '16px' }}
              >
                Añadir +
              </ButtonLineal>
            )}
            {teacherKnowledges?.map((val: any) => {
              return (
                <div
                  style={{
                    backgroundColor: '#e5e5e5',
                    borderRadius: '5px',
                    width: 'fit-content',
                    padding: '9px',
                    margin: '2px',
                    display: 'flex'
                  }}
                >
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="teacher-profile__typography"
                  >
                    {addEllipsis({ str: val?.name, lenght: 32 })}
                  </Typography>
                  {!isProfile && (
                    <button
                      type="button"
                      style={{
                        marginLeft: '5px',
                        border: '0px',
                        backgroundColor: 'inherit',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        removeTeacherKnowledges(val.id);
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
              );
            })}
            {teacherKnowledges?.length > 0 && !isProfile && (
              <button
                type="button"
                style={{
                  border: '0px',
                  backgroundColor: 'inherit',
                  cursor: 'pointer'
                }}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherKnowledges()
                }
              >
                <IconAddMultipleSelect />
              </button>
            )}
            {isProfile && teacherKnowledges?.length <= 0 && `-`}
          </div>
          <ModalAddMultipleOptions
            id={'teacher_knowledges'}
            title="Conocimientos - Categorías"
            label="Categorías"
            placeholder="Elegir categoría"
            width="lg"
            isOpen={isOpenModalAddTeacherKnowledges}
            closeModal={closeModalAddTeacherKnowledges}
            etario={knowledgesData}
            // setTeacherProfile={setTeacherProfileV2}
            flagUpdate={flagUpdate}
            setflagUpdate={setflagUpdate}
            setOptions={setTeacherKnowledges}
            options={teacherKnowledges}
          />
          <Typography
            type="paragraph3"
            variation="mobile"
            bold
            className="teacher-profile__typography"
          >
            Conocimientos - Subategorías
          </Typography>
          <div className="create-teacher-tabs__content-tags">
            {teacherSubknowledges?.length < 1 && !isProfile && (
              <ButtonLineal
                size="small"
                className={`teacher-profile__button-text-add-habilities 
                                             ${
                                               !userAdmin?.permission[
                                                 PERMISSIONS.roomListAll
                                               ]
                                                 ? 'teacher-profile__disabled'
                                                 : ''
                                             }`}
                disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherSubknowledges()
                }
                customStyles={{ fontSize: '16px' }}
              >
                Añadir +
              </ButtonLineal>
            )}
            {teacherSubknowledges?.map((val: any) => {
              return (
                <div
                  style={{
                    backgroundColor: '#e5e5e5',
                    borderRadius: '5px',
                    width: 'fit-content',
                    padding: '9px',
                    margin: '2px',
                    display: 'flex'
                  }}
                >
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="teacher-profile__typography"
                  >
                    {addEllipsis({ str: val?.name, lenght: 32 })}
                  </Typography>
                  {!isProfile && (
                    <button
                      type="button"
                      style={{
                        marginLeft: '5px',
                        border: '0px',
                        backgroundColor: 'inherit',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        removeTeacherSubknowledges(val.id);
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
              );
            })}
            {teacherSubknowledges?.length > 0 && !isProfile && (
              <button
                type="button"
                style={{
                  border: '0px',
                  backgroundColor: 'inherit',
                  cursor: 'pointer'
                }}
                onClick={
                  !userAdmin?.permission[PERMISSIONS.roomListAll]
                    ? () => {}
                    : () => openModalAddTeacherSubknowledges()
                }
              >
                <IconAddMultipleSelect />
              </button>
            )}
            {isProfile && teacherSubknowledges?.length <= 0 && `-`}
          </div>
          <ModalAddMultipleOptions
            id={'teacher_subknowledges'}
            title="Conocimientos - Subcategorías"
            label="Subcategorías"
            placeholder="Elegir subcategoría"
            width="lg"
            isOpen={isOpenModalAddTeacherSubknowledges}
            closeModal={closeModalAddTeacherSubknowledges}
            etario={subknowledgesData}
            // setTeacherProfile={setTeacherProfileV2}
            flagUpdate={flagUpdate}
            setflagUpdate={setflagUpdate}
            setOptions={setTeacherSubknowledges}
            options={teacherSubknowledges}
          />
        </div>
      </div>
    </>
  );
};
