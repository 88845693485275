const FILTERS_FIELD_NAMES_TRANSLATIONS = new Map<string, string>();
FILTERS_FIELD_NAMES_TRANSLATIONS.set('full_name', 'Nombre');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('id', 'ID');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('guardian.email', 'Correo de tutor');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('guardian.full_name', 'Tutor');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('guardian.doc_number', 'DNI');

const FILTERS_TO_QUERY_PARAMS_TRANSLATION = new Map<string, string>();
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set('id', 'id');
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set('guardian.email', 'guardian_email');
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set(
  'guardian.full_name',
  'guardian_full_name'
);
FILTERS_TO_QUERY_PARAMS_TRANSLATION.set(
  'guardian.doc_number',
  'guardian_doc_number'
);

export {
  FILTERS_FIELD_NAMES_TRANSLATIONS,
  FILTERS_TO_QUERY_PARAMS_TRANSLATION
};
