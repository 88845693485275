import Tag from '../../atoms/TagStatus/Tag';
import Typography from '../../atoms/Typography/Typography';
import ButtonLineal from '../ButtonLineal';
import './tagFilter.scss';

interface Props {
  keyFilter: any;
  valueFilter: string;
  onClickResetFilter: React.MouseEventHandler<HTMLButtonElement>;
  color?: string;
  className?: string;
}

const TagFilter: React.FC<Props> = ({
  keyFilter,
  valueFilter,
  onClickResetFilter,
  color,
  className = ''
}) => {
  return (
    <Tag className={`tag-filter ${className}`} color={color}>
      <Typography type="paragraph2" variation="mobile" bold>
        {keyFilter + ': ' + valueFilter}
      </Typography>
      <ButtonLineal size="small" underline={false} onClick={onClickResetFilter}>
        &#10005;
      </ButtonLineal>
    </Tag>
  );
};

export default TagFilter;
