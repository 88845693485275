import {
  Box,
  Grid,
  Divider,
  TextField,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Alert,
  AlertTitle
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
 Form, Field, Formik, ErrorMessage 
} from 'formik';
import * as Yup from 'yup';
import SendIcon from '@material-ui/icons/Send';
import InboxRoundedIcon from '@material-ui/icons/InboxRounded';
import TouchAppRoundedIcon from '@material-ui/icons/TouchAppRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { INewComment } from './interfaces';
import {
  IComment,
  IRoomDetailSimplified,
  IStudent
} from '../../../../../interfaces/room.interface';
import { RootState } from '../../../../../store/store';
import { dict } from '../../../../../types/generics/generics';
import { POST, GET, PATCH } from '../../../../../services/api/api';
import { CardStudentsComments, ListComments, ListStudents } from './styles';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import CommentListItem from './../../Students/Comments/CommentListItem/CommentListItem';
import StudentListItem from './../../Students/Comments/StudentListItem/StudentListItem';
import { AlertSnackbar } from '../../../../../components/common/AlertSnackbar/AlertSnackbar';

interface Props {
  room: IRoomDetailSimplified;
  refetch: () => void;
  setActiveTabMain: React.Dispatch<React.SetStateAction<number>>;
  setIndexPanelTypeComment: React.Dispatch<React.SetStateAction<number>>;
}

const CommentSchema = Yup.object().shape({body: Yup.string().max(500).required('Required')});

const EmptyAdvice = ({ message }: { message: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <Typography variant="h6" gutterBottom component="div">
          {message}
        </Typography>
      </Box>
      <InboxRoundedIcon fontSize="large" color="disabled" />
    </Box>
  );
};

const SelectAdvice = ({ message }: { message: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <Typography variant="h6" gutterBottom component="div">
          {message}
        </Typography>
      </Box>
      <TouchAppRoundedIcon fontSize="large" color="disabled" />
    </Box>
  );
};

const Comments: React.FC<Props> = ({
  room,
  refetch,
  setActiveTabMain,
  setIndexPanelTypeComment
}) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const [studentSelected, setStudentSelected] = useState<IStudent | null>();
  const [commentsForStudentSelected, setCommentsForStudentSelected] = useState<
    IComment[] | null
  >();

  const commentMutation = useMutation((comment: dict) =>
    POST(`/comments/`, comment)
  );

  const commentStateMutation = useMutation((state: dict) =>
    //TODO: Get comment ID to update properly
    PATCH(
      `/comments/${
        commentsForStudentSelected
          ? commentsForStudentSelected[commentsForStudentSelected?.length - 1]
              .id
          : null
      }/`,
      state
    )
  );

  const roomId = room.id;
  const {
    data: comments,
    refetch: refetchCommentInfo,
    isLoading,
    isError
  } = useQuery(
    ['studentComments', roomId],
    () =>
      GET<IComment[]>(`/backoffice/v2/rooms/${roomId}/comments?type=students`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setCommentsForStudentSelected(getUserComments(studentSelected));
  }, [comments]);

  const handleSendComment = async (values: dict) => {
    values.to_student = studentSelected?.id || 0;
    try {
      const result = await commentMutation.mutateAsync(values);
      const studentSelect: IStudent = studentSelected!;

      values.body = '';
      values.subject = 'general';

      refetchCommentInfo();
      setActiveTabMain(2);
      setIndexPanelTypeComment(1);
      setStudentSelected(studentSelect);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateStateComment = async () => {
    try {
      const result = await commentStateMutation.mutateAsync({read_by_teacher: true});
      refetchCommentInfo();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleStudentSelected = (student: IStudent) => {
    setStudentSelected(student);
    setCommentsForStudentSelected(getUserComments(student));
  };

  const hasUnreadComments = (student: IStudent) => {
    let response = false;
    if (student) {
      const student_comments = getUserComments(student);
      response =
        student_comments.length > 0
          ? student_comments[student_comments.length - 1].read_by_teacher ===
            false
          : false;
    }
    return response;
  };

  const getUserComments = (student: IStudent | undefined | null) => {
    let response: any[] = [];
    if (student) {
      const student_comments = comments?.filter(comment => {
        return comment.to_student == student.id;
      });
      response = student_comments ? student_comments : [];
    }
    return response;
  };

  if (isLoading || !room) return <ScreenLoader />;

  if (isError) {
    return (
      <Alert
        severity="error"
        variant="outlined"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              refetchCommentInfo();
            }}
          >
            Reintentar
          </Button>
        }
      >
        <AlertTitle>Error</AlertTitle>
        No se pudo obtener la información de los comentarios de los estudiantes.
        Por favor reintente.
      </Alert>
    );
  }

  return (
    <CardStudentsComments container>
      {/* Students list */}
      <Grid item xs={3}>
        <ListStudents>
          <>
            {room?.students.length > 0
              ? room.students.map((student: IStudent) => {
                  return (
                    <StudentListItem
                      hasComments={hasUnreadComments(student)}
                      key={student.id}
                      studentAge={student.age}
                      studentCountry={student.country ? student.country : 'PE'}
                      studentFullName={student.full_name}
                      selected={student.id === studentSelected?.id}
                      onClick={() => handleStudentSelected(student)}
                    />
                  );
                })
              : null}
          </>
        </ListStudents>
      </Grid>

      {/* Comments list */}
      <Grid item xs={9} display="flex" flexDirection="column">
        <Box
          flexGrow={1}
          position="relative"
          overflow="auto"
          width="100%"
          style={{
            maxHeight: '330px',
            height: '330px',
            overflow: 'hidden'
          }}
        >
          <Box
            position="relative"
            overflow="auto"
            width="100%"
            style={{
              height:
                user?.permission?.name === 'Teacher'
                  ? 'calc(100% - 45px)'
                  : '100%',
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            {studentSelected && commentsForStudentSelected ? (
              commentsForStudentSelected.length > 0 ? (
                <>
                  <Box>
                    <ListComments>
                      {commentsForStudentSelected.map((comment, idx) => {
                        return (
                          <CommentListItem
                            key={idx}
                            commentBody={comment.body}
                            commentCreatedAt={comment.created_at}
                            commentFromFullName={comment.from_user.full_name}
                            commentSubject={comment.subject}
                            isReaded={comment?.read_by_teacher}
                          />
                        );
                      })}
                    </ListComments>
                  </Box>
                </>
              ) : (
                <EmptyAdvice message="El estudiante aún no tiene comentarios" />
              )
            ) : (
              <SelectAdvice message="Seleccione a un estudiante para ver sus comentarios" />
            )}
          </Box>
          {user?.permission?.name === 'Teacher' && studentSelected && (
            <Box
              position="relative"
              overflow="auto"
              width="100%"
              style={{
                minHeight: '45px',
                background: '#F6F6F6',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center'
              }}
            >
              {hasUnreadComments(studentSelected) ? (
                <Button
                  style={{
                    color: '#313131',
                    marginRight: '8px',
                    borderRadius: '10px',
                    padding: '3px 16px'
                  }}
                  variant="text"
                  onClick={handleUpdateStateComment}
                  startIcon={<CheckCircleRoundedIcon />}
                >
                  Marcar como leído
                </Button>
              ) : (
                <Typography
                  style={{
                    fontStyle: 'italic',
                    color: '#555555',
                    marginRight: '14px'
                  }}
                >
                  Todos los comentarios leídos.
                </Typography>
              )}
            </Box>
          )}
        </Box>

        <Box flexGrow={1} position="relative" overflow="auto" width="100%">
          {studentSelected && (
            <>
              <Divider />
              <Grid container style={{ padding: '20px' }}>
                {/* Form */}
                <Formik
                  initialValues={
                    {
                      body: '',
                      from_user: user?.id || 0,
                      is_public: false,
                      room: room?.id || 0,
                      subject: 'general',
                      to_student: 0
                    } as INewComment
                  }
                  validationSchema={CommentSchema}
                  onSubmit={handleSendComment}
                >
                  <Form style={{ width: '100%' }}>
                    <Grid container>
                      <Grid item xs={9} gap={5}>
                        <Box marginBottom={1}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Asunto
                            </InputLabel>
                            <Field
                              as={Select}
                              labelId="demo-simple-select-label"
                              id="subject"
                              name="subject"
                              label="Asunto"
                            >
                              <MenuItem value="general" selected>
                                General
                              </MenuItem>
                              <MenuItem value="behavior">
                                Comportamiento
                              </MenuItem>
                              <MenuItem value="credentials">
                                Credenciales
                              </MenuItem>
                              <MenuItem value="sac">Comentarios SAC</MenuItem>
                            </Field>
                          </FormControl>
                        </Box>
                        <Field
                          id="body-input"
                          label="Comentario"
                          name="body"
                          variant="outlined"
                          as={TextField}
                          multiline
                          rows={3}
                          fullWidth
                        />
                        <ErrorMessage
                          name="body"
                          // render={() => <>Este campo es requerido</>}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disableElevation
                            disabled={commentMutation.isLoading}
                            startIcon={<SendIcon />}
                          >
                            {commentMutation.isLoading
                              ? 'Cargando...'
                              : 'Comentar'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                </Formik>

                {/* Alerts */}
                {!commentMutation.data || (
                  <AlertSnackbar
                    message="Comentario creado"
                    severity="success"
                  />
                )}
                {!commentMutation.error || (
                  <AlertSnackbar
                    message="Error al crear comentario"
                    severity="error"
                  />
                )}
              </Grid>
            </>
          )}
        </Box>
      </Grid>
    </CardStudentsComments>
  );
};

export default Comments;
