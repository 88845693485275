import React from 'react';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
  dataTestid?: string;
}

const IconArrowSelect: React.FC<Props> = ({
  className,
  width = '24',
  height = '24',
  fill = '#313131',
  viewBox = '0 0 24 24',
  dataTestid = 'icon-arrow-down'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconArrowSelect;
