const FILTERS_FIELD_NAMES_TRANSLATIONS = new Map<string, string>();
FILTERS_FIELD_NAMES_TRANSLATIONS.set('id', 'ID');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('group.name', 'Grupo');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('teacher.full_name', 'Profesor');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('b2b_project', 'Id proyecto B2B');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('day_sessions', 'Día');
FILTERS_FIELD_NAMES_TRANSLATIONS.set(
  'end_date_evaluation',
  'Fecha final de evaluación'
);
FILTERS_FIELD_NAMES_TRANSLATIONS.set(
  'status_evaluation',
  'Estado de evaluación'
);
FILTERS_FIELD_NAMES_TRANSLATIONS.set('sort', 'Orden');
FILTERS_FIELD_NAMES_TRANSLATIONS.set('direction', 'Dirección');

export { FILTERS_FIELD_NAMES_TRANSLATIONS };
