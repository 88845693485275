import React from 'react';

interface Props {
  fillPath?: string;
  fillCircle?: string;
  className?: string;
  dataTestid?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

const IconAvatarProfile: React.FC<Props> = ({
  className,
  width = '60',
  height = '60',
  viewBox = '0 0 60 60',
  fillPath = 'white',
  fillCircle = '#BCBCBC',
  dataTestid = 'icon-avatar-profile'
}) => {
  return (
    <svg
      data-testid={dataTestid}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill={fillCircle} />
      <path
        d="M29.9911 24.6435C32.0797 24.6435 34.0827 23.8139 35.5596 22.337C37.0364 20.8602 37.8661 18.8571 37.8661 16.7685C37.8661 14.68 37.0364 12.6769 35.5596 11.2001C34.0827 9.72324 32.0797 8.89355 29.9911 8.89355C27.9025 8.89355 25.8995 9.72324 24.4226 11.2001C22.9458 12.6769 22.1161 14.68 22.1161 16.7685C22.1161 18.8571 22.9458 20.8602 24.4226 22.337C25.8995 23.8139 27.9025 24.6435 29.9911 24.6435ZM12.8367 41.6903C12.5943 42.3409 12.5681 43.0524 12.762 43.7191C12.9558 44.3859 13.3595 44.9723 13.913 45.3915C18.5099 48.9652 24.1685 50.9016 29.9911 50.8935C36.0549 50.8935 41.6409 48.8355 46.085 45.381C47.2137 44.5069 47.6705 43.0107 47.156 41.6798C45.8231 38.2096 43.4693 35.2251 40.4053 33.1203C37.3413 31.0154 33.711 29.889 29.9937 29.8899C26.2763 29.8907 22.6466 31.0187 19.5835 33.125C16.5204 35.2313 14.168 38.2169 12.8367 41.6877V41.6903Z"
        fill={fillPath}
      />
    </svg>
  );
};

export default IconAvatarProfile;
