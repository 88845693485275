// React Query
import { useMutation, useQuery } from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// API

// Hot Toast
import { toast } from 'react-hot-toast';

// Interfaces

// Material UI
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import {
 Alert, Divider, LinearProgress, TextField 
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

import { ITeacher } from '../../../../interfaces/teacher.interface';
import { GET, PATCH } from '../../../../services/api/api';
import { getErrorMessage } from '../../../../utils/utils';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  phone_number: Yup.string().required('Teléfono requerido'),
  email: Yup.string()
    .email('Email invalido!')
    .min(2, 'Muy corto!')
    .required('Email requerido'),
  user_mdl_id: Yup.number()
});

interface Props {
  teacherId: number;
  refetch: () => {};
  onClose: () => void;
}

const EditTeacher: React.FC<Props> = ({ teacherId, refetch, onClose }) => {
  const {
    data: teacher,
    error: errorTeacher,
    isError: isErrorTeacher,
    isLoading: isLoadingTeacher,
    refetch: refetchTeacher
  } = useQuery(
    ['edit-teacher', teacherId.toString()],
    () => GET<ITeacher>(`/teacher/${teacherId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const { mutateAsync: editTeacher, isLoading } = useMutation(
    (teacher: any) => PATCH(`/teacher/${teacherId}/`, teacher),
    {
      retry: false,
      onError(err) {
        console.log(err);
      }
    }
  );

  if (isLoadingTeacher || !teacher) return <LinearProgress />;
  if (isErrorTeacher) return <div>Error! {JSON.stringify(errorTeacher)}</div>;

  const initialValues = {
    first_name: teacher.first_name,
    last_name: teacher.last_name,
    phone_number: teacher.phone_number,
    email: teacher.email,
    user_mdl_id: teacher.user_mdl_id
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async values => {
        const data = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,

          username: values.email,
          email: values.email,

          user_mdl_id: values.user_mdl_id
        };

        await toast.promise(
          editTeacher(data!),
          {
            loading: 'Cargando...',
            success: `${data.first_name} Editado`,
            error: 'Error'
          },
          { success: { icon: <UpdateIcon color="primary" /> } }
        );

        refetchTeacher();
        refetch();
        onClose();
      }}
    >
      {({
 errors, touched, values, handleChange 
}) => (
        <Form>
          <Grid container rowGap={2}>
            {/* Details */}
            <Grid item container columnSpacing={2} rowGap={3} xs={12}>
              {/* First_name */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  autoFocus
                  id="first_name"
                  label="Nombre"
                  variant="outlined"
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.first_name)}
                  </Alert>
                )}
              </Grid>

              {/* Last_name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="Apellido"
                  variant="outlined"
                  value={values.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && touched.last_name && (
                  <Alert severity="error">
                    {getErrorMessage(errors.last_name)}
                  </Alert>
                )}
              </Grid>

              {/* User_mdl_id */}
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="user_mdl_id"
                  label="ID moodle"
                  variant="outlined"
                  type="number"
                  disabled={true}
                  value={values.user_mdl_id}
                  onChange={handleChange}
                />
                {errors.user_mdl_id && touched.user_mdl_id && (
                  <Alert severity="error">
                    {getErrorMessage(errors.user_mdl_id)}
                  </Alert>
                )}
              </Grid>

              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Correo"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Alert severity="error">
                    {getErrorMessage(errors.email)}
                  </Alert>
                )}
              </Grid>

              {/* Phone_number */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone_number"
                  label="Teléfono"
                  variant="outlined"
                  value={values.phone_number}
                  onChange={handleChange}
                />
                {errors.phone_number && touched.phone_number && (
                  <Alert severity="error">
                    {getErrorMessage(errors.phone_number)}
                  </Alert>
                )}
              </Grid>
            </Grid>

            {/* Edit */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditTeacher;
