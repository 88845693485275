import * as Yup from 'yup';

export const CreateOrganizationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  companyName: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  taxCode: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  address: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  website: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  comments: Yup.string().min(2, 'Muy corto!').required('Requerido'),
  commercialOwner: Yup.string().min(2, 'Muy corto!').required('Requerido')
});
