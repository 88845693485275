const FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS = new Map<
  string,
  string
>();

FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS.set('name', 'Organización');
FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS.set('id', 'ID');
FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS.set(
  'organization_type',
  'Tipo de organización'
);
FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS.set('country_id', 'País');
FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS.set(
  'business_name',
  'Razón social'
);
FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS.set(
  'business_owner',
  'Owner comercial'
);

const FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS = new Map<string, string>();

FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set('id', 'ID');
FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set('name', 'Proyecto');
FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set(
  'organization_name',
  'Organización'
);
FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set(
  'operative_start_date',
  'Inicio de clases'
);
FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set(
  'operative_end_date',
  'Fin de clases'
);
FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set('status', 'Estatus operativo');
FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set(
  'operative_owner',
  'Owner operativo'
);

FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS.set(
  'academic_owner',
  'Owner académico'
);

export {
  FILTERS_ORGANIZATIONS_FIELD_NAMES_TRANSLATIONS,
  FILTERS_PROJECTS_FIELD_NAMES_TRANSLATIONS
};
