import * as Yup from 'yup';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const EditStudentSchema = Yup.object().shape({
  first_name: Yup.string().required('Requerido'),
  last_name: Yup.string().required('Requerido'),
  gender: Yup.string().required('Requerido'),
  country_student: Yup.string().required('Requerido'),
  personal_email: Yup.string().matches(
    emailRegex,
    'Formato de correo electrónico no válido'
  )
});
