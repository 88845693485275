import { useState } from 'react';
import { Box } from '@material-ui/system';
import { TextField, Autocomplete } from '@mui/material';

import './addStudentToGroup.scss';
import Input from '../../../../../../components/molecules/Input';
import { IStudent } from '../../../../../../interfaces/group.interface';
import IconClose from '../../../../../../components/atoms/icons/IconClose';
import { AppDispatch, useAppDispatch } from '../../../../../../store/store';
import ButtonFilled from '../../../../../../components/molecules/ButtonFilled';
import Typography from '../../../../../../components/atoms/Typography/Typography';
import IconArrowDown from '../../../../../../components/atoms/icons/IconArrowDown';
import { addStudentToGroup } from '../../../../../../slices/groupSlice/groupsSlice';
import { listRoomsByGroupId } from '../../../../../../slices/roomSlice/roomsByGroupIdSlice';
interface Props {
  complementStudents: IStudent[];
  groupId: number;
  onClose: () => void;
}

const AddStudentToGroup: React.FC<Props> = ({
  complementStudents,
  groupId,
  onClose
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [selectedStudentId, setSelectedStudentId] = useState<number>();
  const [projectId, setProjectId] = useState();

  const handleListItemClick = (index: number) => setSelectedStudentId(index);

  const handleAutocompleteOnchange = (_: any, value: any) => {
    handleListItemClick(value?.id);
    setProjectId(value?.project_id);
  };

  const handleAddStudentToGroup = async () => {
    try {
      await dispatch(
        addStudentToGroup({
          groupId,
          selectedStudentId,
          projectId: projectId || null
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(listRoomsByGroupId(Number(groupId)));
      onClose();
    }
  };

  return (
    <div className="modal-message-alert__content-body">
      <div>
        <Typography type="paragraph3" variation="mobile" bold>
          Seleccionar estudiante
        </Typography>
        <br />
        <Autocomplete
          fullWidth
          id="student"
          options={complementStudents}
          autoHighlight
          onChange={handleAutocompleteOnchange}
          getOptionLabel={option => `${option?.id} ${option?.full_name}`}
          filterOptions={(options, { inputValue }) => {
            return options.filter(
              option =>
                option?.full_name
                  ?.toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option?.id.toString().includes(inputValue)
            );
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                '& > img': { mr: 2, flexShrink: 0 },
                borderBottom: '1px solid rgb(229, 229, 229)',
                fontFamily: 'Sofia Pro',
                fontStyle: 'normal',
                fontSize: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                '& > :first-child': { width: '50px', marginRight: '10px' }
              }}
              {...props}
            >
              <Typography type="paragraph3" variation="mobile" bold>
                {option?.id}
              </Typography>
              <Typography type="paragraph3" variation="mobile">
                {option?.full_name}
              </Typography>
            </Box>
          )}
          renderInput={(params: any) => (
            <TextField
              placeholder={
                'Buscar estudiantes por student ID, nombre o apellido'
              }
              {...params}
              required
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-student'
              }}
              sx={{
                input: {
                  padding: '0 !important',
                  paddingLeft: '8px !important',
                  fontFamily: 'Sofia Pro',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px'
                },
                '.MuiOutlinedInput-root': { borderRadius: '8px' },
                fieldset: { border: '1px solid black !important' }
              }}
            />
          )}
          popupIcon={<IconArrowDown />}
          clearIcon={<IconClose />}
          sx={{
            '.MuiAutocomplete-popupIndicator[title=Open]': {
              marginTop: '7px',
              marginRight: '7px'
            },
            '.MuiAutocomplete-popupIndicator[title=Open]:hover': {backgroundColor: 'transparent'},
            '.MuiAutocomplete-popupIndicator[title=Close]': {
              marginTop: '-7px',
              marginRight: '7px'
            },
            '.MuiAutocomplete-popupIndicator[title=Close]:hover': {backgroundColor: 'transparent'},
            '.MuiAutocomplete-clearIndicator': { marginRight: '7px' },
            '.MuiAutocomplete-clearIndicator:hover': {backgroundColor: 'transparent'},
            '.MuiAutocomplete-endAdornment': {
              height: '28px',
              display: 'flex',
              alignItems: 'center'
            },
            '.Mui-disabled': {'.MuiAutocomplete-endAdornment': {svg: { path: { fill: '#bcbcbc !important' } }}}
          }}
        />
      </div>
      <div>
        <Typography type="paragraph3" variation="mobile" bold>
          Project ID (para estudiantes B2B)
        </Typography>
        <br />
        <Input
          key={`key-projectId-${selectedStudentId}`}
          value={projectId}
          onChange={(e: any) => {
            setProjectId(e.target.value);
          }}
          placeholder="Agregar project ID (si aplica)"
          name="first_name"
          required
        />
      </div>
      <div>
        <ButtonFilled
          disabled={!selectedStudentId}
          fullWidth={true}
          size="default"
          onClick={handleAddStudentToGroup}
        >
          Agregar estudiante
        </ButtonFilled>
      </div>
    </div>
  );
};

export default AddStudentToGroup;
