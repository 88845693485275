import React from 'react';

import './modalMessageAlert.scss';
import Modal from '../../atoms/Modal';
import IconAlert from '../../atoms/icons/IconAlert';
import Typography from '../../atoms/Typography/Typography';

interface Props {
  title: any;
  isOpenModal: boolean;
  buttonColumns?: boolean;
  openModal: () => void;
  closeModal: () => void;
  button1?: any;
  button2?: any;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  body?: any;
  message?: string | React.ReactNode;
  className?: string;
  iconAlert?: any;
  align?: 'center' | 'left';
  dataTestid?: string;
}

const ModalMessageAlert: React.FC<Props> = ({
  title,
  isOpenModal,
  buttonColumns = false,
  openModal,
  closeModal,
  className = '',
  width,
  body,
  message,
  button1,
  button2,
  iconAlert,
  align = 'left',
  dataTestid = 'modal-message-alert-test-id'
}) => {
  return (
    <div>
      <Modal
        className={`modal-message-alert ${className}`}
        width={width}
        isOpen={isOpenModal}
        closeModal={closeModal}
        icon={
          iconAlert === 'alert' ? <IconAlert /> : !iconAlert ? null : iconAlert
        }
        title={
          <Typography align={align} type="h2" variation="mobile" bold>
            {title}
          </Typography>
        }
        align={align}
        body={
          body ||
          (message && (
            <div className="modal-message-alert__content-body">
              <Typography type="paragraph2" variation="mobile" align={align}>
                {message}
              </Typography>
            </div>
          ))
        }
        buttons={
          <section
            className={`modal-message-alert__container-buttons modal-message-alert__container-buttons--${
              button2 ? 'justify' : 'center'
            } modal-message-alert__container-buttons--${
              buttonColumns ? 'buttons-column' : 'buttons-row'
            }`}
          >
            {button1} {button2}
          </section>
        }
      />
    </div>
  );
};

export default ModalMessageAlert;
