/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@material-ui/system';

import colors from '../../../components/atoms/Colors';
import Input from '../../../components/molecules/Input';
import { countries } from '../../../constants/countries';
import IconArrowDown from '../../../components/atoms/icons/IconArrowDown';
import IconClose from '../../../components/atoms/icons/IconClose';
import Typography from '../../../components/atoms/Typography/Typography';
import Select from '../../../components/molecules/Select/Select';

export const PersonalInfo: React.FC<{
  teacherId?: any;
  values: any;
  handleChange: any;
  setFieldValue: any;
  selectDocType: any;
  errors: any;
  touched: any;
  defaultCountry?: any;
  isProfile?: boolean;
}> = ({
  values,
  handleChange,
  setFieldValue,
  teacherId,
  selectDocType,
  errors,
  touched,
  defaultCountry,
  isProfile
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const objDocumentType = [
    { id: 'cedula', name: 'Cédula' },
    { id: 'CPP', name: 'CPP' },
    { id: 'DNI', name: 'DNI' },
    { id: 'passport', name: 'Pasaporte' },
    { id: 'CURP', name: 'CURP' },
    { id: 'alien-certificate', name: 'Carnet de Extranjería' },
    { id: 'CE', name: 'Cédula de Extranjería' }
  ];

  const {
    selectOptionDocumentType,
    setSelectOptionDocumentType,
    isOpenSelectDocumentType,
    setIsOpenSelectDocumentType,
    optionSelectionDocumentType
  } = selectDocType;

  const RenderError = ({ error }: { error: any }) => {
    return (
      <Typography
        type="paragraph2"
        variation="mobile"
        className="input__alert"
        color={colors.warningColor}
      >
        {error}
      </Typography>
    );
  };

  useEffect(() => {
    if (values?.country) {
      const country = countries.find(item => item.code === values.country);
      setSelectedCountry(country || null);
    } else {
      setSelectedCountry(null);
    }
  }, [values, countries]);

  const addDocTypeSelectClass =
    selectOptionDocumentType?.name || values.doc_type !== 'none'
      ? 'teacher-profile__select'
      : 'teacher-profile__select-placeholder';

  const addDocTypeSelectErrorClass =
    errors.doc_type && touched.doc_type ? 'select__error' : '';

  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Información general
      </Typography>
      <br />
      <br />
      <div className="create-teacher-tabs__content-body">
        <div className="create-teacher-tabs__organization-information">
          <Typography bold type="paragraph3" variation="mobile">
            Nombres*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.first_name || '-'}
            </Typography>
          ) : (
            <Input
              value={values.first_name}
              onChange={handleChange}
              placeholder="Ingresar nombres"
              name="first_name"
              required
              error={
                errors.first_name && touched.first_name ? errors.first_name : ''
              }
            />
          )}

          <Typography bold type="paragraph3" variation="mobile">
            Apellidos*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.last_name || '-'}
            </Typography>
          ) : (
            <Input
              value={values.last_name}
              onChange={handleChange}
              placeholder="Ingresar apellidos"
              name="last_name"
              required
              error={
                errors.last_name && touched.last_name ? errors.last_name : ''
              }
            />
          )}
          <Typography bold type="paragraph3" variation="mobile">
            País de residencia*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {defaultCountry?.label || '-'}
            </Typography>
          ) : (
            <div>
              <Autocomplete
                fullWidth
                id="country"
                options={countries}
                autoHighlight
                value={selectedCountry}
                onChange={(_e, value: any) => {
                  setSelectedCountry(value);
                  setFieldValue('country', value?.code || '');
                }}
                getOptionLabel={option => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code})
                  </Box>
                )}
                renderInput={(params: any) => (
                  <TextField
                    placeholder="Elige el país de residencia"
                    {...params}
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                    sx={{
                      input: {
                        padding: '0 !important',
                        paddingLeft: '8px !important',
                        fontFamily: 'Sofia Pro',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px'
                      },
                      '.MuiOutlinedInput-root': { borderRadius: '8px' },
                      fieldset: {
                        border:
                          errors.country && touched.country
                            ? '1px solid red !important'
                            : '1px solid black !important'
                      }
                    }}
                  />
                )}
                popupIcon={<IconArrowDown />}
                clearIcon={<IconClose />}
                sx={{
                  '.MuiAutocomplete-popupIndicator[title=Open]': {
                    marginTop: '7px',
                    marginRight: '7px'
                  },
                  '.MuiAutocomplete-popupIndicator[title=Open]:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-popupIndicator[title=Close]': {
                    marginTop: '-7px',
                    marginRight: '7px'
                  },
                  '.MuiAutocomplete-popupIndicator[title=Close]:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-clearIndicator': { marginRight: '7px' },
                  '.MuiAutocomplete-clearIndicator:hover': {backgroundColor: 'transparent'},
                  '.MuiAutocomplete-endAdornment': {
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center'
                  },
                  '.Mui-disabled': {'.MuiAutocomplete-endAdornment': {svg: { path: { fill: '#bcbcbc !important' } }}}
                }}
              />
              {errors.country && touched.country && (
                <RenderError error={errors.country} />
              )}
            </div>
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Ciudad de residencia*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.city || '-'}
            </Typography>
          ) : (
            <Input
              value={values.city}
              onChange={handleChange}
              placeholder="Ingresar ciudad de residencia"
              name="city"
              required
              error={errors.city && touched.city ? errors.city : ''}
            />
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Teléfono*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.phone_number || '-'}
            </Typography>
          ) : (
            <Input
              value={values.phone_number}
              onChange={handleChange}
              placeholder="Ingresar teléfono"
              name="phone_number"
              required
              error={
                errors.phone_number && touched.phone_number
                  ? errors.phone_number
                  : ''
              }
            />
          )}
        </div>
        <div className="create-teacher-tabs__organization-information">
          <Typography bold type="paragraph3" variation="mobile">
            Tipo de documento*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.doc_type}
            </Typography>
          ) : (
            <div>
              <Select
                className={`${addDocTypeSelectClass} ${addDocTypeSelectErrorClass}`}
                setIsOpen={setIsOpenSelectDocumentType}
                isOpen={isOpenSelectDocumentType}
                size="default"
                dividerItems
                onclickDefaultValue={(event: any) => {
                  optionSelectionDocumentType(event);
                  setFieldValue('doc_type', event.id);
                }}
                options={objDocumentType}
                children={
                  values.doc_type
                    ? values.doc_type === 'DNI'
                      ? 'DNI'
                      : values.doc_type === 'cedula'
                        ? 'Cédula'
                        : values.doc_type === 'passport'
                          ? 'Pasaporte'
                          : values.doc_type === 'CURP'
                            ? 'CURP'
                            : values.doc_type === 'alien-certificate'
                              ? 'Carnet de Extranjería'
                              : values.doc_type === 'CPP'
                                ? 'CPP'
                                : values.doc_type === 'CE'
                                  ? 'Cédula de Extranjería'
                                  : 'Elige el tipo de documento...'
                    : selectOptionDocumentType?.name
                      ? selectOptionDocumentType?.name
                      : 'Elige el tipo de documento...'
                }
              />
              {errors.doc_type && touched.doc_type && (
                <RenderError error={errors.doc_type} />
              )}
            </div>
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Número de documento*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.doc_number || '-'}
            </Typography>
          ) : (
            <Input
              value={values.doc_number}
              onChange={handleChange}
              placeholder="Ingresar número de documento"
              name="doc_number"
              required
              error={
                errors.doc_number && touched.doc_number ? errors.doc_number : ''
              }
            />
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Correo personal
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.optional_email || '-'}
            </Typography>
          ) : (
            <Input
              value={values.optional_email}
              onChange={handleChange}
              placeholder="Ingresar correo personal"
              name="optional_email"
              required
              error={
                errors.optional_email && touched.optional_email
                  ? errors.optional_email
                  : ''
              }
            />
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Profesión
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.profession || '-'}
            </Typography>
          ) : (
            <Input
              value={values.profession}
              onChange={handleChange}
              placeholder="Ingresar profesión"
              name="profession"
              required
            />
          )}
          <Typography bold type="paragraph3" variation="mobile">
            Grado de estudio
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.grade || '-'}
            </Typography>
          ) : (
            <Input
              value={values.grade}
              onChange={handleChange}
              placeholder="Ingresar grado de estudio"
              name="grade"
              required
            />
          )}
        </div>
      </div>
    </>
  );
};
