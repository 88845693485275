import React, { SyntheticEvent, useState } from 'react';

// Material UI
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useLocation } from 'react-router-dom';

import { useModal } from '../../hooks/useModal';
import ButtonFilled from '../../components/molecules/ButtonFilled';
import TableCTCB2BProjects from '../../components/organism/TableCTCB2B/Projects';
import ProjectsLayout from '../../components/templates/CTCB2BLayout/ProjectsLayout';
import TableCTCB2BOrganizations from '../../components/organism/TableCTCB2B/Organizations';
import OrganizationsLayout from '../../components/templates/CTCB2BLayout/OrganizationsLayout';
import ModalCreateProject from '../../pages/CTCB2B/Projects/ModalCreateProject';
import ModalCreateOrganization from '../../pages/CTCB2B/Organizations/ModalCreateOrganization';

const CTCB2B: React.FC<{}> = ({}) => {
  const location = useLocation();
  const paramsURL = new URLSearchParams(location.search);
  const tabParam = paramsURL.get('tab');
  const organizationParam = paramsURL.get('organization');
  const [currentPosition, setCurrentPosition] = useState<string>(
    tabParam ? tabParam : '0'
  );
  const [generalFilter, setGeneralFilter] = useState<string>(
    organizationParam ? organizationParam : ''
  );
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  const {
    openModal: openModalCreateOrganization,
    closeModal: closeModalCreateOrganization,
    isOpenModal: isOpenModalCreateOrganization
  } = useModal();

  return (
    <Grid item container rowGap={1.5} xs={12}>
      <TabContext value={currentPosition}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%'
          }}
        >
          <TabList
            onChange={handleChangePosition}
            aria-label="organization actions"
          >
            <Tab label="Proyectos" value="0" sx={{ textTransform: 'none' }} />
            <Tab
              label="Organizaciones"
              value="1"
              sx={{ textTransform: 'none' }}
            />
          </TabList>
        </Box>

        <TabPanel value="0" sx={{ p: 0, width: '100%' }}>
          <ProjectsLayout
            buttonAdd={
              <ButtonFilled
                onClick={() => {
                  setShouldRefresh(false);
                  openModalCreateOrganization();
                }}
                className={'projects-layout__button-add-group'}
              >
                {'Crear nuevo proyecto +'}
              </ButtonFilled>
            }
            table={TableCTCB2BProjects}
            shouldRefresh={shouldRefresh}
            generalFilter={generalFilter}
            setGeneralFilter={setGeneralFilter}
          />
          <ModalCreateProject
            setShouldRefresh={setShouldRefresh}
            isOpenModal={isOpenModalCreateOrganization}
            openModal={closeModalCreateOrganization}
            closeModal={closeModalCreateOrganization}
          />
        </TabPanel>

        <TabPanel value="1" sx={{ p: 0, width: '100%' }}>
          <OrganizationsLayout
            buttonAdd={
              <ButtonFilled
                onClick={() => {
                  setShouldRefresh(false);
                  openModalCreateOrganization();
                }}
                className={'organizations-layout__button-add-group'}
              >
                {'Añadir nueva organización +'}
              </ButtonFilled>
            }
            table={TableCTCB2BOrganizations}
            shouldRefresh={shouldRefresh}
            setCurrentPosition={setCurrentPosition}
            setGeneralFilter={setGeneralFilter}
          />
          <ModalCreateOrganization
            setShouldRefresh={setShouldRefresh}
            isOpenModal={isOpenModalCreateOrganization}
            openModal={closeModalCreateOrganization}
            closeModal={closeModalCreateOrganization}
          />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default CTCB2B;
