import axios from 'axios';

import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL_B2B_MS;

export const getCountry = async () => {
  try {
    const token = getAccessCookie();
    return await axios.get(`${instanceBaseURL}/country/`, {headers: { Authorization: `Bearer ${token}` }});
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};
